import { memo, useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { assetsAssetV2List } from '@goldcast/api/content';
import SavedSearchDetailLayout from './SavedSearchDetailLayout';
import { overwriteAllClips } from '@/stores/clip';
import { initUnseenClipIds } from '@/stores/unseenClips';
import { Clip } from '@/domains/asset';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import useAnalytics from '@/hooks/useAnalytics';

function SavedSearch() {
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams<{ id: string }>();
  const { trackFetchedClips } = useAnalytics();

  const loadSavedSearchClips = useCallback(() => {
    return assetsAssetV2List({
      queryParams: {
        saved_search: id,
        types: 'CLIP',
        limit: 1000,
        ordering: '-created_at'
      }
    }).then(({ results }) => results as unknown as Clip[]);
  }, [id]);

  const { refetch: refetchSavedSearchClips } = useQuery({
    queryKey: ['clips', id],
    queryFn: loadSavedSearchClips,
    initialData: [],
    onSuccess: clips => {
      overwriteAllClips(clips);
      initUnseenClipIds(clips);
      trackFetchedClips({ count: (clips || []).length });
    },
    onSettled: () => {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.ReloadAllClips, refetchSavedSearchClips);
    return () => {
      EventBus.off(CustomEvents.ReloadAllClips, eventListener);
    };
  });

  return <SavedSearchDetailLayout isLoading={isLoading} />;
}

export default memo(SavedSearch);
