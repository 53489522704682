import React, { ReactNode } from 'react';
import { BadgeVariation } from './types';
import classes from './Badge.module.scss';

export default function Badge({ variation, children }: { variation: BadgeVariation; children: ReactNode }) {
  return (
    <span
      className={`inline-flex items-center rounded-full px-2.5 py-1 text-center text-2xs font-medium
        ${variation === 'default' ? classes['default'] : ''}
        ${variation === 'outline' ? classes['outline'] : ''}
        ${variation === 'gray' ? classes['gray'] : ''}
        ${variation === 'warning' ? classes['warning'] : ''}
        ${variation === 'rose100' ? classes['rose100'] : ''}
        ${variation === 'rose400' ? classes['rose400'] : ''}
        ${variation === 'orange200' ? classes['orange200'] : ''}
        ${variation === 'orange400' ? classes['orange400'] : ''}
        ${variation === 'orange600' ? classes['orange600'] : ''}`}
      data-testid="badge-wrapper"
    >
      {children}
    </span>
  );
}
