import { IconLoader2, IconSparkles } from '@tabler/icons-react';

export enum ContentStatesEnum {
  Uploading,
  Processing,
  Generating,
  Cancelled,
  GeneratingCompleted,
  ContentReadyForPreview
}

export const IconMap = {
  [ContentStatesEnum.Processing]: IconLoader2,
  [ContentStatesEnum.Generating]: IconSparkles
};

export const ASSETS_FILE_PATH = 'content-lab/filestack/custom_assets';

export enum ContentError {
  UPLOAD_FAILED = 'UPLOAD_FAILED',
  PROCESSING_FAILED = 'PROCESSING_FAILED',
  GENERATING_FAILED = 'GENERATING_FAILED'
}

export const ContentErrorTypeMap = {
  [ContentError.UPLOAD_FAILED]: 'Video Uploading Failed',
  [ContentError.PROCESSING_FAILED]: 'Video Processing Failed',
  [ContentError.GENERATING_FAILED]: 'Video Generating failed'
};

export const NavigationLabelMap = {
  clips: 'clips',
  transcript: 'transcript',
  social: 'social posts',
  email: 'emails',
  blog: 'blogs',
  takeaways: 'takeaways'
};

export const HeadingMap = {
  clips: 'Clips',
  social: 'Social',
  blog: 'Blog',
  email: 'Email',
  takeaways: 'Takeaways',
  custom: 'Custom',
  transcript: 'Transcript'
};
