import createStore from './store';

export interface FeatureFlagStore {
  [key: string]: boolean;
  areFlagsLoaded: boolean;
}

const featureFlagStore = createStore<FeatureFlagStore>({
  areFlagsLoaded: false
});

export default featureFlagStore;
