import { MediaContent, GetRelevantSourcesResponse } from '@goldcast/api/content';
import { IconHeadphones } from '@tabler/icons-react';
import { getBroadcastBackgroundImageUrl } from '../utils';
import { PreviewImageSize } from '../constants';
import ProgressiveImage from '@/components/atoms/ProgressiveImage/ProgressiveImage';

export default function SessionThumbnail({
  session,
  iconSize
}: {
  session: MediaContent | GetRelevantSourcesResponse;
  iconSize: number;
}) {
  return session.media_type === 'AUDIO' ? (
    <IconHeadphones size={iconSize} stroke={1.5} className="text-slate-700" data-testid="session-audio-icon" />
  ) : (
    <ProgressiveImage
      src={`${getBroadcastBackgroundImageUrl(session, PreviewImageSize.Large)}`}
      placeholderSrc={`${getBroadcastBackgroundImageUrl(session)}`}
    />
  );
}
