import { memo, useMemo } from 'react';
import { convertSecondsToTimeString } from '../../SideBar/ClipDuration/utils';
import { classnames } from '@/libs/utils';

interface DurationDisplayProps {
  duration: number;
  shouldFloatOnTop: boolean;
  floatX: 'start' | 'end' | null;
  durationFormat?: string;
}

const DurationDisplay: React.FC<DurationDisplayProps> = ({ duration, shouldFloatOnTop, floatX, durationFormat }) => {
  const containerClassName = useMemo(
    () =>
      classnames('absolute z-40 rounded bg-black px-1 py-0.5 text-xs font-medium tabular-nums text-white', {
        [`top-[-32px] ${floatX === 'start' ? 'left-0 ' : 'right-0'}`]: shouldFloatOnTop,
        'bottom-1.5 left-4': !shouldFloatOnTop
      }),
    [shouldFloatOnTop, floatX]
  );

  const floaterClassName = useMemo(
    () =>
      classnames('absolute -bottom-0.5 h-1.5 w-1.5 rotate-45 bg-black', {
        'left-1': floatX === 'start',
        'right-1': floatX === 'end'
      }),
    [floatX]
  );

  const durationToDisplay = useMemo(
    () => convertSecondsToTimeString(duration, durationFormat || 'HH:mm:ss.SS'),
    [duration, durationFormat]
  );

  return (
    <div className="relative h-full w-full rounded-lg">
      <div className={containerClassName}>
        {durationToDisplay}
        {shouldFloatOnTop && <div className={floaterClassName} />}
      </div>
    </div>
  );
};

export default memo(DurationDisplay);
