import { IconAlertTriangle } from '@tabler/icons-react';

export default function TemplatesErrorState() {
  return (
    <div className="flex w-full flex-col items-center justify-center p-8 text-slate-500">
      <IconAlertTriangle size={24} stroke={2} className="h-6 w-6" />
      <div className="mt-2 w-48 text-center text-xs">
        <p>Struggling to load templates. </p>
        <p>A quick refresh might just do the trick!</p>
      </div>
    </div>
  );
}
