import { brandTemplateDestroy } from '@goldcast/api/content';
import { Menu, Transition } from '@headlessui/react';
import { IconDotsVertical, IconEdit, IconTrash } from '@tabler/icons-react';
import { Fragment } from 'react';
import NewTemplateModal from '../TemplatesSideBar/NewTemplateModal';
import { PreviewTemplate } from '../types';
import useDialog from '@/components/organisms/useDialog';
import ConfirmationDialog from '@/components/organisms/ConfirmationDialog';
import ContentDialog from '@/Pages/Sessions/uiComponents/ContentDialog/ContentDialog';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';

export default function TemplateMenu({ template }: { template: PreviewTemplate }) {
  const {
    isOpen: isDeleteConfirmationOpen,
    openDialog: openDeleteConfirmationDialog,
    closeDialog: closeDeleteConfirmationDialog
  } = useDialog();

  const { isOpen: isEditDialogOpen, openDialog: openEditDialog, closeDialog: closeEditDialog } = useDialog();

  let contextMenu = [
    {
      Icon: IconEdit,
      label: 'Edit',
      action: event => {
        event.stopPropagation();
        openEditDialog();
      }
    },
    {
      Icon: IconTrash,
      label: 'Delete',
      action: event => {
        event.stopPropagation();
        openDeleteConfirmationDialog();
      }
    }
  ];

  function deleteTemplate() {
    brandTemplateDestroy({ id: template.id as string }).then(() => {
      closeDeleteConfirmationDialog();
      EventBus.dispatch(CustomEvents.ReloadTemplates);
    });
  }

  return (
    <>
      <Menu as="div" className="absolute right-1 top-1">
        <Menu.Button
          data-testid="clip-menu-trigger"
          as="div"
          onClick={e => e.stopPropagation()}
          className="group flex h-6 w-6 shrink-0 cursor-pointer items-center justify-center hover:bg-slate-200"
        >
          <IconDotsVertical className="h-5 w-5 text-slate-400 group-hover:text-slate-600" size={16} />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 top-0 z-50 w-32 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {contextMenu.map(menuItem => {
              const { Icon: MenuItemIcon, label, action } = menuItem;

              return (
                <Menu.Item key={label}>
                  <button
                    onClick={action}
                    className="flex w-full cursor-pointer items-center space-x-2.5 truncate border-b px-3 py-2.5 last:border-none hover:bg-slate-100 hover:text-deep-orange-600"
                  >
                    <MenuItemIcon className="h-4 w-4" />
                    <span className="text-xs font-medium">{label}</span>
                  </button>
                </Menu.Item>
              );
            })}
          </Menu.Items>
        </Transition>
      </Menu>
      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        onClose={closeDeleteConfirmationDialog}
        onConfirm={deleteTemplate}
        title="Delete Template"
        content="Are you sure you want to delete this template and make it unavailable for all future clips?"
        confirmLabel="Yes"
      />
      <ContentDialog isOpen={isEditDialogOpen} setIsOpen={closeEditDialog} title="Edit Template" size="small">
        <NewTemplateModal onSave={closeEditDialog} templateToEdit={template} />
      </ContentDialog>
    </>
  );
}
