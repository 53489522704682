/**
 * Remotion Constants
 */

import { SizeType } from '@/domains/asset';

/** Frame rate of 24 frames per second */
export const FPS_24 = 24;

/** Frame rate of 30 frames per second */
export const FPS_30 = 30;

/** Frame rate of 60 frames per second */
export const FPS_60 = 60;

/** Width of HD resolution (1280x720) */
export const WIDTH_HD = 1280;

/** Height of HD resolution (1280x720) */
export const HEIGHT_HD = 720;

/** Width of Full HD resolution (1920x1080) */
export const WIDTH_FULL_HD = 1920;

/** Height of Full HD resolution (1920x1080) */
export const HEIGHT_FULL_HD = 1080;

/** Width of Quad HD resolution (2560x1440) */
export const WIDTH_QUAD_HD = 2560;

/** Height of Quad HD resolution (2560x1440) */
export const HEIGHT_QUAD_HD = 1440;

/** Width of 4K resolution (3840x2160) */
export const WIDTH_4K = 3840;

/** Height of 4K resolution (3840x2160) */
export const HEIGHT_4K = 2160;

/** Minimum duration (in seconds) for a video */
export const MINIMUM_VIDEO_DURATION = 5;

export enum VIDEO_QUALITY {
  HD = 'HD',
  FULL_HD = 'FULL_HD',
  QUAD_HD = 'QUAD_HD',
  '4K' = '4K'
}

export interface VIDEO_DIMENSIONS {
  HEIGHT: number;
  WIDTH: number;
}

export const COMPOSITION_DIMENSIONS: Record<SizeType, Record<VIDEO_QUALITY, VIDEO_DIMENSIONS>> = {
  LANDSCAPE: {
    HD: {
      HEIGHT: HEIGHT_HD,
      WIDTH: WIDTH_HD
    },
    FULL_HD: {
      HEIGHT: HEIGHT_FULL_HD,
      WIDTH: WIDTH_FULL_HD
    },
    QUAD_HD: {
      HEIGHT: HEIGHT_QUAD_HD,
      WIDTH: WIDTH_QUAD_HD
    },
    '4K': {
      HEIGHT: HEIGHT_4K,
      WIDTH: WIDTH_4K
    }
  },
  PORTRAIT: {
    HD: {
      HEIGHT: WIDTH_HD,
      WIDTH: HEIGHT_HD
    },
    FULL_HD: {
      HEIGHT: WIDTH_FULL_HD,
      WIDTH: HEIGHT_FULL_HD
    },
    QUAD_HD: {
      HEIGHT: WIDTH_QUAD_HD,
      WIDTH: HEIGHT_QUAD_HD
    },
    '4K': {
      HEIGHT: WIDTH_4K,
      WIDTH: HEIGHT_4K
    }
  },
  SQUARE: {
    HD: {
      HEIGHT: HEIGHT_HD,
      WIDTH: HEIGHT_HD
    },
    FULL_HD: {
      HEIGHT: HEIGHT_FULL_HD,
      WIDTH: HEIGHT_FULL_HD
    },
    QUAD_HD: {
      HEIGHT: HEIGHT_QUAD_HD,
      WIDTH: HEIGHT_QUAD_HD
    },
    '4K': {
      HEIGHT: HEIGHT_4K,
      WIDTH: HEIGHT_4K
    }
  }
};
