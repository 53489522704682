import { useMemo } from 'react';
import { getActiveCaptionsBackgroundColor, getInactiveCaptionsOpacityClass } from '@/stores/clip';
import { ClipMetadata, HighlightType } from '@/domains/asset';
import { PreviewTemplate } from '@/Pages/Clip/SideBar/types';

export default function useCaptionsStylesHook(
  clipMetadata: PreviewTemplate | ClipMetadata,
  highlightType?: HighlightType,
  backgroundColor?: string
) {
  const { activeWordStyles, inactiveWordStyles } = useMemo(() => {
    return {
      activeWordStyles: {
        backgroundColor: getActiveCaptionsBackgroundColor(clipMetadata, highlightType, backgroundColor),
        opacity: 1
      },
      inactiveWordStyles: {
        backgroundColor: 'transparent',
        opacity: getInactiveCaptionsOpacityClass(clipMetadata, highlightType)
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clipMetadata]);

  return {
    activeWordStyles,
    inactiveWordStyles
  };
}
