export default function PostClipEmptyState({ importClip }) {
  return (
    <button
      data-testid="import-clip-button"
      className="relative flex w-full max-w-3xl flex-col items-center justify-center py-2.5 pl-14"
      onClick={importClip}
    >
      <div className="flex aspect-video w-full flex-col items-center justify-center space-y-1.5 rounded-lg border border-dashed border-slate-300">
        <div className="text flex h-10 shrink-0 items-center justify-center rounded-lg px-6 py-2 text-sm font-medium tabular-nums hover:text-deep-orange-600 focus:outline-none focus:ring-deep-orange-600">
          Import Clip
        </div>
      </div>
    </button>
  );
}
