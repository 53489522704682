import { memo } from 'react';

/**
 * SectionTimeIndicator - Set group in parent classname
 */
function SectionTimeIndicator({ progressPercent }) {
  return (
    <div
      className="pointer-events-none invisible absolute top-0 z-30 h-full w-0.5 bg-black group-hover:visible"
      style={{ left: progressPercent + '%' }}
    />
  );
}

export default memo(SectionTimeIndicator);
