import { CSSProperties } from 'react';

export const PORTRAIT_DEFAULT_LAYOUT_VIDEO_BOTTOM_PADDING = 800;
export const SQUARE_DEFAULT_LAYOUT_VIDEO_BOTTOM_PADDING = 320;

export const NAME_TO_TITLE_FONT_SIZE_RATIO = 1.25;
export const TITLE_OPACITY = 0.6;

export const truncateStyles: CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
};

export const nameTitleContainerStyles: CSSProperties = {
  ...truncateStyles,
  lineHeight: 'normal',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'absolute',
  width: 'fit-content',
  borderRadius: '100px'
};

export const commonNameTitleStyles: CSSProperties = {
  ...truncateStyles,
  lineHeight: 'normal',
  width: 'min-content',
  maxWidth: '100%',
  WebkitFontSmoothing: 'antialiased'
};
