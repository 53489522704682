import { useEffect, useRef } from 'react';

const usePreviousEffect = <T>(value: T, callback: (prev: T | undefined, current: T) => void) => {
  const prevValue = useRef(value);

  useEffect(() => {
    if (prevValue.current !== value) {
      callback(prevValue.current, value);
    }
    prevValue.current = value;
  }, [value, callback]);
};

export default usePreviousEffect;
