import IconButton from '@/components/atoms/Button/IconButton';
import Icon from '@/components/atoms/Icon';
import ConfirmationDialog from '@/components/organisms/ConfirmationDialog';
import useDialog from '@/components/organisms/useDialog';
import { getFileNameFromPath } from '@/libs/file';

export default function FontPreview({ font, onDelete }: { font: string; onDelete: (font: string) => void }) {
  const {
    isOpen: isDeleteConfirmationOpen,
    openDialog: openDeleteConfirmationDialog,
    closeDialog: closeDeleteConfirmationDialog
  } = useDialog();
  return (
    <div className="flex aspect-[16/11] w-56 flex-col items-center justify-center overflow-hidden rounded-lg border border-slate-200">
      <div className="flex w-full grow items-center justify-center bg-slate-200">
        <Icon name="IconTypography" size={20} className="text-slate-500" />
      </div>
      <div className="flex w-full items-center justify-between space-x-1.5 px-2.5">
        <div className="grow items-center truncate py-3 text-xs">{getFileNameFromPath(font)}</div>
        <div className="shrink-0">
          <IconButton
            icon="IconTrash"
            size="small"
            variation="text"
            onClick={openDeleteConfirmationDialog}
            trackingId="delete-font-button"
          />
        </div>
      </div>
      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        onClose={closeDeleteConfirmationDialog}
        onConfirm={() => onDelete(font)}
        title="Delete Brand Font?"
        content="You're about to remove a brand font you've chosen. Don't worry, this won't change any of the fonts you've already used around the platform."
        confirmLabel="Yes"
      />
    </div>
  );
}
