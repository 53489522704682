function normalize(strArray: string[]): string {
  let resultArray: string[] = [];
  if (strArray.length === 0) {
    return '';
  }

  if (typeof strArray[0] !== 'string') {
    throw new TypeError('Url must be a string. Received ' + strArray[0]);
  }

  if (strArray[0].match(/^[^/:]+:\/*$/) && strArray.length > 1) {
    let first = strArray.shift();
    strArray[0] = first + strArray[0];
  }

  if (strArray[0].match(/^file:\/\/\//)) {
    strArray[0] = strArray[0].replace(/^([^/:]+):\/*/, '$1:///');
  } else {
    strArray[0] = strArray[0].replace(/^([^/:]+):\/*/, '$1://');
  }

  for (let i = 0; i < strArray.length; i++) {
    let component = strArray[i];

    if (typeof component !== 'string') {
      throw new TypeError('Url must be a string. Received ' + component);
    }

    if (component === '') {
      continue;
    }

    if (i > 0) {
      component = component.replace(/^[/]+/, '');
    }
    if (i < strArray.length - 1) {
      component = component.replace(/[/]+$/, '');
    } else {
      component = component.replace(/[/]+$/, '/');
    }

    resultArray.push(component);
  }

  let str = resultArray.join('/');

  str = str.replace(/\/(\?|&|#[^!])/g, '$1');

  let parts = str.split('?');
  str = parts.shift() + (parts.length > 0 ? '?' : '') + parts.join('&');

  return str;
}

function urlJoin(...args): string {
  let input;

  if (typeof args[0] === 'object') {
    input = args[0];
  } else {
    input = [].slice.call(args);
  }

  return normalize(input);
}

const APP_ROOT_URL = '/content';

export function getBaseUrlPath() {
  const currentPath = document.location.pathname || APP_ROOT_URL;
  const startOfAppBase = currentPath.indexOf(APP_ROOT_URL);

  let base = currentPath;

  if (startOfAppBase !== -1) {
    base = currentPath.substring(0, startOfAppBase);
  }

  base = urlJoin(base, APP_ROOT_URL);

  if (base === 'content') {
    return '/content';
  }
  return base;
}
