import React, { useMemo } from 'react';
import Toggle from '@/components/atoms/Toggle';
import useFreeTrialHook from '@/hooks/useFreeTrialHook';
import { useAppContext } from '@/context/AppContext/AppContext';

const BrandingToggle = () => {
  const { isFreemiumUser, isFreeTrialEnabled, openNonBlockingPricingPaywall } = useFreeTrialHook();
  const {
    adminAppStore: { user: { is_content_lab_standalone } = { is_content_lab_standalone: false } }
  } = useAppContext();

  const isNonPaidUser = useMemo(() => {
    return isFreeTrialEnabled || isFreemiumUser;
  }, [isFreeTrialEnabled, isFreemiumUser]);

  const onBrandingToggle = () => {
    if (isNonPaidUser) {
      openNonBlockingPricingPaywall();
    }
  };

  if (!is_content_lab_standalone || !isNonPaidUser) {
    return null;
  }

  return (
    <>
      <div className="col-span-2 flex items-center">
        Goldcast Branding
        <div className="ml-1 flex h-6 w-6 shrink-0 scale-[0.8] items-center justify-center rounded-lg bg-[#5449D9] text-white group-hover:bg-[#1B279B]">
          <svg
            className="h-4 w-4"
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            strokeWidth="0.5"
            stroke="currentColor"
            fill="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M13 3l0 7l6 0l-8 11l0 -7l-6 0l8 -11"></path>
          </svg>
        </div>
      </div>
      <div className="col-span-1">
        <div className="w-full rounded-md py-2 text-right focus:outline-none focus:ring-2 focus:ring-slate-950">
          <Toggle on={isNonPaidUser} onToggle={onBrandingToggle} disabled={!isNonPaidUser} />
        </div>
      </div>
    </>
  );
};

export default BrandingToggle;
