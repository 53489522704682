import { useCallback, useMemo, useRef } from 'react';
import { useExtendedCanvasProps, useExtendedCanvasReturn } from '../ClipTimelineTypes';
import { getBounds } from '../ClipTimelineUtils';

const useExtendedCanvas = ({ isDragging, sectionRef }: useExtendedCanvasProps): useExtendedCanvasReturn => {
  const extendedCanvasLeft = useRef(0);
  const extendedCanvasRight = useRef(0);

  const extendedCanvasStyles = useMemo(
    () => ({
      top: 0,
      left: isDragging ? extendedCanvasLeft.current : 0,
      right: isDragging ? extendedCanvasRight.current : 0
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragging]
  );

  const setExtendedCanvasParams = useCallback(() => {
    const { sectionBounds, clipTimelineBounds } = getBounds(sectionRef.current);

    if (!sectionBounds || !clipTimelineBounds) {
      return;
    }

    const { left: buttonLeft, right: buttonRight } = sectionBounds;
    const { left: boundaryLeft, right: boundaryRight } = clipTimelineBounds;

    extendedCanvasLeft.current = boundaryLeft - buttonLeft;
    extendedCanvasRight.current = buttonRight - boundaryRight;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  return {
    extendedCanvasStyles,
    setExtendedCanvasParams
  };
};

export default useExtendedCanvas;
