const DIALOG_ROOT_ID = 'headlessui-portal-root';

export function isAnyDialogOpened(): boolean {
  return !!document.getElementById(DIALOG_ROOT_ID);
}

export function propagateCustomEvent(event: MouseEvent, ref?: React.RefObject<HTMLElement>) {
  if (!ref || (ref.current && ref.current.contains(event.target as Node))) {
    const clonedEvent = new MouseEvent(event.type, {
      bubbles: event.bubbles,
      cancelable: event.cancelable,
      view: event.view || window,
      button: event.button,
      buttons: event.buttons
    });

    Object.defineProperty(clonedEvent, 'isClonedEvent', {
      value: true,
      configurable: false,
      enumerable: true
    });

    Object.defineProperty(clonedEvent, 'target', {
      value: event.target,
      configurable: false
    });

    window.dispatchEvent(clonedEvent);
  }
}
