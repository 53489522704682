import React, { useEffect, useRef, useState } from 'react';
import { classnames } from '@/libs/utils';
import classes from './Marquee.module.scss';

export default function Marquee({
  content,
  isActive,
  offset = 0
}: {
  content: string;
  isActive: boolean;
  offset?: number;
}) {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const overflowingWidth = useRef(0);
  const isMarqueeDisabled = useRef(false);
  const [marginLeft, setMarginLeft] = useState<string>('0px');
  const [isGradientVisible, setIsGradientVisible] = useState(false);

  useEffect(() => {
    initStates();
  }, []);

  function initStates() {
    const containerWidth = contentRef.current!.parentElement?.offsetWidth || 0;
    const elementWidth = contentRef.current!.offsetWidth;

    overflowingWidth.current = containerWidth - elementWidth;
    isMarqueeDisabled.current = elementWidth < containerWidth - offset;
    setIsGradientVisible(!isMarqueeDisabled.current);
  }

  useEffect(() => {
    if (isMarqueeDisabled.current) return;
    setMarginLeft(isActive ? `${overflowingWidth.current}px` : '0px');
    let timeout = setTimeout(() => {
      setIsGradientVisible(!isActive);
    }, 1500);

    return () => {
      clearTimeout(timeout);
    };
  }, [isActive]);
  return (
    <div className="relative overflow-hidden">
      <div
        className={classnames('inline-block whitespace-nowrap', classes['marquee-content'])}
        style={{ marginLeft: marginLeft }}
        ref={contentRef}
      >
        {content}
      </div>
      {isGradientVisible && (
        <div className="absolute bottom-0 right-0 h-3 w-12 bg-gradient-to-l from-white to-white/0 opacity-100 transition-all duration-[4s] ease-in-out"></div>
      )}
    </div>
  );
}
