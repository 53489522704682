import { memo } from 'react';

const DragOverlay: React.FC<{
  type: 'start' | 'end';
  overlayWidth: number;
}> = ({ type, overlayWidth }) => {
  return (
    <div
      className="absolute z-[32] h-full bg-gray-400/50"
      style={{
        width: overlayWidth + 'px',
        [type === 'end' ? 'right' : 'left']: 0
      }}
    />
  );
};

export default memo(DragOverlay);
