import createStore from './store';
import { TextAsset } from '@/Pages/PostsListPage/types';

export interface PostState {
  selectedPost: TextAsset | null;
  clips: { [clipId: string]: ClipAssetMetadata };
  isReplaceClipDialogOpen: boolean;
  selectedBlockId: string;
}

export interface ClipAssetMetadata {
  activeClipIndex: number;
  clip_asset_ids: string[];
  currentClipIndex: number;
  next_clip_info?: number[][];
}

export const postState = createStore<PostState>({
  selectedPost: null,
  clips: {},
  isReplaceClipDialogOpen: false,
  selectedBlockId: ''
});
