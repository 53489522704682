import { Popover, Transition } from '@headlessui/react';
import { useCallback, useEffect, useRef, useState, useSyncExternalStore } from 'react';
import { CombinedDownload } from '@goldcast/api/content';
import DownloadsPopupTab from './DownloadsPopupTab';
import IconButton from '@/components/atoms/Button/IconButton';
import { downloads, loadContentDownloadsList, setDownloadsPopupOpen } from '@/stores/downloadsStore';
import { classnames } from '@/libs/utils';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { savedAISearch } from '@/stores/savedAISearch';

const tabs = [
  {
    key: 'processing',
    title: 'Download Status'
  },
  {
    key: 'recent-downloads',
    title: 'Recent Downloads'
  }
];

export default function DownloadsPopup({ contentId }: { contentId: string }) {
  const downloadPopupButtonRef = useRef<HTMLButtonElement>(null);

  const [initialLoading, setInitialLoading] = useState(false);
  const [unseenCount, setUnseenCount] = useState(0);
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const [processingDownloads, setProcessingDownloads] = useState<CombinedDownload[]>([]);
  const [recentDownloads, setRecentDownloads] = useState<CombinedDownload[]>([]);
  const savedAISearchStore = useSyncExternalStore(savedAISearch.subscribe, savedAISearch.getSnapshot);
  const downloadsList = useSyncExternalStore(downloads.subscribe, downloads.getSnapshot)[
    savedAISearchStore?.id || contentId
  ];

  useEffect(() => {
    setDownloadsPopupOpen(false);
  }, []);

  useEffect(() => {
    if (!contentId) {
      return;
    }
    setInitialLoading(true);
    loadContentDownloadsList(contentId, savedAISearchStore?.id).finally(() => {
      setInitialLoading(false);
    });
  }, [contentId, savedAISearchStore?.id]);

  useEffect(() => {
    if (!downloadsList) return;

    setProcessingDownloads(prev => {
      const justFinished = prev.filter(prevDownload =>
        downloadsList.find(
          download => download.id === prevDownload.id && (download.status === 'DONE' || download.status === 'FAILED')
        )
      );
      return downloadsList.filter(
        download => download.status === 'PROCESSING' || !download.status || justFinished.find(d => d.id === download.id)
      );
    });
  }, [downloadsList]);

  useEffect(() => {
    if (!downloadsList) return;

    setRecentDownloads(
      downloadsList.filter(
        download =>
          (download.status === 'DONE' || download.status === 'FAILED') &&
          processingDownloads.findIndex(processing => processing.id === download.id) === -1
      )
    );
  }, [downloadsList, processingDownloads]);

  const openDownloadsPopup = useCallback(() => {
    if (downloadPopupButtonRef.current) {
      downloadPopupButtonRef.current.click();
      setDownloadsPopupOpen(true);
    }
  }, []);

  const onDownloadStarted = useCallback(() => {
    setUnseenCount(unseenCount + 1);
    openDownloadsPopup();
  }, [unseenCount, openDownloadsPopup]);

  const beforeEnter = useCallback(() => {
    setUnseenCount(0);
    setDownloadsPopupOpen(true);

    if (processingDownloads.length > 0 || recentDownloads.length === 0) {
      setCurrentTab(tabs[0]);
    } else {
      setCurrentTab(tabs[1]);
    }
  }, [processingDownloads, recentDownloads]);

  const beforeLeave = useCallback(() => {
    setDownloadsPopupOpen(false);
  }, []);

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.DownloadTriggerSuccess, onDownloadStarted);
    return () => {
      EventBus.off(CustomEvents.DownloadTriggerSuccess, eventListener);
    };
  }, [onDownloadStarted]);

  return (
    <Popover className="relative z-40">
      <Popover.Button as="div" ref={downloadPopupButtonRef}>
        <div className="relative">
          <IconButton icon="IconDownload" title="Downloads" variation="text" trackingId="downloads-popup-button" />
          {unseenCount > 0 ? (
            <div className="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-deep-orange">
              <span className="text-2xs font-medium text-white">{unseenCount}</span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Popover.Button>
      <Transition
        appear
        beforeEnter={beforeEnter}
        beforeLeave={beforeLeave}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0 origin-bottom-right"
        enterTo="transform scale-100 opacity-100 origin-bottom-right"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100 origin-bottom-right"
        leaveTo="transform scale-95 opacity-0 origin-bottom-right"
      >
        <Popover.Panel>
          <div className="absolute right-0 w-[28rem] translate-y-2 rounded-xl border bg-white shadow-lg">
            <div className="flex w-full justify-between px-3 pb-1 pt-3">
              {tabs.map(tab => (
                <button
                  key={tab.key}
                  onClick={() => setCurrentTab(tab)}
                  className={classnames('w-1/2 border-b-2 px-2 py-1 text-sm font-medium', {
                    'border-b-black': currentTab.key === tab.key,
                    'border-b-transparent text-slate-600': currentTab.key !== tab.key
                  })}
                >
                  {tab.title}
                </button>
              ))}
            </div>
            <DownloadsPopupTab
              loading={initialLoading}
              downloads={currentTab.key === 'processing' ? processingDownloads : recentDownloads}
              emptyStateSubtext={
                currentTab.key === 'processing'
                  ? 'Click on "Share" to start downloading your asset.'
                  : 'Files available for download will appear here'
              }
            />
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
