import React from 'react';
import { IconSquareRoundedXFilled } from '@tabler/icons-react';
import { getFilesProgress } from '@/libs/file';

export default function ProgressToast({
  progressPercentage,
  progressBytes,
  totalSize,
  onCancel,
  isProgressPercentageHidden = false,
  message = 'Uploading'
}: {
  progressPercentage: number;
  progressBytes: number;
  onCancel?: () => void;
  totalSize: string;
  isProgressPercentageHidden?: boolean;
  message?: string;
}) {
  return (
    <div className="tw-flex tw-flex-row tw-items-center">
      <div className="tw-flex tw-flex-col tw-text-sm">
        <div>
          {message}
          {isProgressPercentageHidden ? '' : ` ${progressPercentage}%`}
        </div>
        <div className="tw-text-xs tw-text-slate-500">{getFilesProgress(progressBytes, totalSize)}</div>
      </div>
      {onCancel && (
        <IconSquareRoundedXFilled
          data-testid="cancel-upload-icon"
          size={25}
          className="tw-text-rose-500 tw-ml-auto tw-cursor-pointer"
          onClick={onCancel}
        />
      )}
    </div>
  );
}
