import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState, useSyncExternalStore } from 'react';
import { dmsOrganizationPreSeedDataStandaloneCreate } from '@goldcast/api/content';
import { HOMEPAGE_SUB_ROUTES } from './constants';
import FreeTrialUsageSummaryCard from '@/components/molecules/FreeTrial/FreeTrialUsageSummaryCard';
import useHomepageNavigationHook from '@/hooks/useHomepageNavigationHook';
import CheckoutStatusDialog from '@/Pages/Checkout/CheckoutStatusDialog';
import { useQueryParams } from '@/hooks/url/useQueryParams';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';
import { currentUser } from '@/stores/user';
import { useAppContext } from '@/context/AppContext/AppContext';
import useFreeTrialHook from '@/hooks/useFreeTrialHook';

export default function Homepage(): JSX.Element {
  const [showCheckoutStatusDialog, SetShowCheckoutStatusDialog] = useState(false);
  const { shouldNavigateToSplashPage, shouldNavigateToHomepage } = useHomepageNavigationHook();
  const { hasUploadedVideos } = useFreeTrialHook();
  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const user = useSyncExternalStore(currentUser.subscribe, currentUser.getSnapshot);

  const { adminAppStore } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = useQueryParams<{ redirect: string }>();

  useEffect(() => {
    const importEventListener = EventBus.on(CustomEvents.NavigateToContentLabImport, () => navigate('/import'));
    return () => {
      EventBus.off(CustomEvents.NavigateToContentLabImport, importEventListener);
    };
  }, []);

  useEffect(() => {
    if (queryParams?.redirect) {
      navigate(queryParams.redirect, { replace: true });
    }
  }, [queryParams]);

  useEffect(() => {
    if (featureFlags[FeatureFlagKeys.Use_CL_FTUX_Clip_Templates]) {
      return;
    }

    if (!user?.is_content_lab_standalone) {
      return;
    }

    if (!adminAppStore.currentOrg?.newbie_content_user) {
      return;
    }

    if (hasUploadedVideos) {
      return;
    }

    dmsOrganizationPreSeedDataStandaloneCreate({
      body: { organization_id: user.organization as string }
    });
  }, [featureFlags, user, adminAppStore.currentOrg?.newbie_content_user, hasUploadedVideos]);

  useEffect(() => {
    if (featureFlags[FeatureFlagKeys.Use_CL_FTUX_Clip_Templates]) {
      if (shouldNavigateToSplashPage && !location.pathname.startsWith('/import')) {
        navigate('/import');
      }
    } else {
      if (shouldNavigateToHomepage && location.pathname === '/welcome') {
        navigate('/');
        return;
      }

      if (shouldNavigateToSplashPage && location.pathname !== '/welcome') {
        navigate('/welcome');
      }
    }
  }, [location.pathname, shouldNavigateToHomepage, shouldNavigateToSplashPage, featureFlags]);

  useEffect(() => {
    if (location.search.includes('checkout_session_id')) {
      SetShowCheckoutStatusDialog(true);
    }
  }, [location.search]);

  if (shouldNavigateToSplashPage) {
    return <Outlet />;
  }

  return (
    <div className="flex flex-row">
      <div className="w-56 shrink-0 p-4">
        <div className="fixed flex w-[12rem] flex-col space-y-3">
          {HOMEPAGE_SUB_ROUTES.map(route => (
            <NavLink
              key={route.title}
              to={route.url}
              className={({ isActive }) =>
                (isActive ? 'rounded bg-slate-100 font-semibold' : '') + ' px-2 py-1 text-sm'
              }
            >
              {route.title}
            </NavLink>
          ))}
        </div>
        <FreeTrialUsageSummaryCard />
        {showCheckoutStatusDialog && (
          <CheckoutStatusDialog
            isOpen={showCheckoutStatusDialog}
            onClose={() => {
              SetShowCheckoutStatusDialog(false);
            }}
          />
        )}
      </div>
      <Outlet />
    </div>
  );
}
