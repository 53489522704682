import React, { useState } from 'react';
import { IconInfoCircle } from '@tabler/icons-react';
import TooltipV2 from '../TooltipV2';
import { ExternalTooltipV2Props } from './TooltipV2/TooltipV2Types';

export interface Option {
  label: string;
  value: string;
}

type InlineSelectProps = ExternalTooltipV2Props & {
  value: string;
  options: Option[];
  onChange: (value: string | null) => void;
  classNames?: string;
  label?: string;
  isRequired?: boolean;
};

export default function InlineSelect({
  value,
  options,
  onChange,
  classNames = '',
  label = '',
  isRequired = false,
  tooltipId,
  tooltipContent,
  ...props
}: InlineSelectProps): JSX.Element {
  const [selectedValue, setSelectedValue] = useState(value);

  const onOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const currentValue = event.target.value;
    setSelectedValue(currentValue);
    onChange(currentValue || null);
  };

  return (
    <div className={classNames}>
      <div className={'flex items-center text-xs text-slate-600'}>
        {label}
        {isRequired ? <sup>*</sup> : ''}
        &nbsp;
        {tooltipContent && (
          <TooltipV2
            tooltipId={tooltipId}
            tooltipElement={<IconInfoCircle size={16} />}
            tooltipContent={tooltipContent}
          />
        )}
      </div>
      <select
        className="truncate rounded-md !border bg-white py-2 pl-3 pr-10 text-base hover:!border-deep-orange-600 focus:!border-deep-orange-600 sm:text-xs"
        value={selectedValue}
        onChange={onOptionChange}
        {...props}
      >
        {options.map(option => {
          return (
            <option
              key={option.value}
              value={option.value}
              className={`${value === option.value ? 'font-semibold' : ''}`}
            >
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
}
