import createStore from './store';

export interface ClipPlayer {
  id: string;
  video: HTMLVideoElement;
  currentTime: number;
  duration: number;
  paused: boolean;
  currentSrtIndex: number;
}

export const createPlayer = (id: string, currentTime = 0, duration = 0) =>
  createStore<ClipPlayer>({
    id,
    currentTime,
    video: document.createElement('video'),
    duration,
    paused: true,
    currentSrtIndex: 0
  });

export const player = createPlayer('main');

let timeout: ReturnType<typeof setTimeout>;
let animationFrame: number;

export function play(begin?: number, stop?: number) {
  const store = player.getSnapshot();
  const start = begin ?? 0;
  const end = stop ?? store.duration;
  clearTimeout(timeout);
  cancelAnimationFrame(animationFrame);
  function watch() {
    const tryPlaying = () => {
      if (!store.video) return;
      const { paused, currentTime } = store.video;
      if (currentTime > end || paused) {
        store.video.pause();
        return;
      }

      animationFrame = requestAnimationFrame(tryPlaying);
    };

    timeout = setTimeout(tryPlaying, 100);
  }

  if (store.currentTime > end || store.currentTime < start) {
    player.update(data => ({ ...data, currentTime: start }));
  }
  store.video?.play();
  watch();
}

export function pause() {
  clearTimeout(timeout);
  cancelAnimationFrame(animationFrame);
  const store = player.getSnapshot();
  player.update(data => ({ ...data, paused: true }));
  store.video?.pause();
}

export function updatePlayerTime(time: number) {
  const mainVideoPlayer = document.getElementById('mainVideoPlayer') as HTMLVideoElement;
  mainVideoPlayer.currentTime = time;
  player.update(data => ({
    ...data,
    currentTime: time
  }));
}
