import * as filestack from 'filestack-js';
import { ContentValidationErrors, CustomFile } from './types';
import { FILES_LIMIT, MAX_FILE_SIZE_IN_GB } from './constants';
import { getEnvConfig } from '@/constants';
import { getFileExtension, getFileKey, getFileSizeInGb } from '@/libs/file';

export function getContentStoreConfig(
  filePath: string,
  filesIds: Record<string, CustomFile>
): filestack.StoreUploadOptions {
  return {
    location: 's3',
    container: getEnvConfig('S3_STATIC_ASSETS_BUCKET'),
    path: `/${filePath}`,
    region: getEnvConfig('AWS_STORE_REGION'),
    disableStorageKey: true,
    filename: file => getFileName(file, filesIds)
  };
}

export function handleBeforeUnload(): string {
  return 'Changes that you made may not be saved.';
}

export function getFilesValidationErrors(uploadedFiles: File[]): ContentValidationErrors {
  return {
    files: uploadedFiles.reduce((acc, el: File) => {
      if (getFileSizeInGb(el.size) > MAX_FILE_SIZE_IN_GB) {
        acc[el.name] = `Max file size is ${MAX_FILE_SIZE_IN_GB}GB`;
      }
      return acc;
    }, {}),
    global: uploadedFiles.length > FILES_LIMIT ? [`Please select up to ${FILES_LIMIT} recordings`] : undefined
  };
}

function getFileName(file: File, filesIds: Record<string, CustomFile>): string {
  const fileKey = getFileKey(file.name);
  let extension = getFileExtension(file.name);
  // Convert .mov to .mp4
  if (extension.toLowerCase() === '.mov') extension = '.mp4';
  return `${filesIds[fileKey].projectId}/${filesIds[fileKey].contentId}${extension}`;
}
