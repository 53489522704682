import React, { useCallback, useState } from 'react';
import { IconSearch, IconX } from '@tabler/icons-react';
import useDebounce from '@/hooks/useDebounce';
import { classnames } from '@/libs/utils';

export default function SearchExpandableInput({
  searchTerm,
  onUpdate,
  placeholder = 'Search...',
  isAlwaysExpanded = false,
  disabled = false
}) {
  const [isOpen, setIsOpen] = useState(isAlwaysExpanded || searchTerm);
  const onSearchClick = useCallback(() => {
    if (!isAlwaysExpanded) {
      setIsOpen(o => !o);
    }
  }, [isAlwaysExpanded, setIsOpen]);

  const [value, setValue] = useState(searchTerm);

  const debouncedSearchTermUpdate = useCallback(
    (valueOverride?: string) => {
      onUpdate(valueOverride || value);
    },
    [onUpdate, value]
  );

  const debouncedOnChange = useDebounce(debouncedSearchTermUpdate);

  const onValueChange = (e: any) => {
    debouncedOnChange();
    setValue(e.target.value);
  };

  const clearSearch = useCallback(() => {
    debouncedOnChange('');
    setValue('');
  }, [debouncedOnChange]);

  return (
    <div
      className={classnames(
        'flex h-8 items-center rounded-lg transition-all',
        isAlwaysExpanded ? 'w-full max-w-xs' : isOpen ? 'w-[240px]' : 'w-8',
        isOpen
          ? 'border bg-white focus-within:border-deep-orange-600'
          : 'items-center justify-center border hover:border-deep-orange-50 hover:bg-deep-orange-50 hover:text-deep-orange-600',
        disabled ? 'cursor-not-allowed border-slate-200 bg-slate-50 text-slate-400' : ''
      )}
    >
      <button
        className={classnames(
          'inline-flex h-8 w-8 flex-none items-center justify-center rounded-lg hover:text-deep-orange-600',
          isOpen ? ' hover:border-deep-orange-50' : 'hover:bg-deep-orange-50',
          disabled
            ? 'pointer-events-none !cursor-not-allowed border-slate-200 bg-slate-50 text-slate-400 hover:text-slate-400'
            : ''
        )}
        data-testid="search-expandable-input-search-button"
        onClick={onSearchClick}
        disabled={disabled}
      >
        <IconSearch className="mt-0 h-5 w-5" />
      </button>
      {isOpen && (
        <input
          type="text"
          className="h-8 w-full max-w-[75%] self-stretch overflow-visible truncate rounded-full border-none bg-transparent px-1 text-sm leading-4 focus:border-none focus:outline-none focus:ring-0"
          placeholder={placeholder}
          onChange={onValueChange}
          value={value}
          autoFocus
          disabled={disabled}
        />
      )}
      {value && (
        <button onClick={clearSearch} className="absolute right-5 rounded p-1">
          <IconX size={14} stroke={1.9} className="" />
        </button>
      )}
    </div>
  );
}
