import {
  BrandKitCreateOkResponse,
  BrandKitListOkResponse,
  BrandKitUpdateOkResponse,
  brandKitCreate,
  brandKitList,
  brandKitUpdate
} from '@goldcast/api/content';
import createStore from './store';
import { BrandKitCreateResponse, BrandKitStore, ClipsBrandKitStore } from './types';
import { currentClip } from './clip';
import { loadVoiceProfileList } from './voiceProfile';
import { fontsMap } from '@/Pages/Clip/SideBar/FontSelector/constants';
import { getFileNameFromPath, getFileNameWithoutExtension } from '@/libs/file';
import { getBrandKitUrl, loadBrandKitFonts } from '@/Pages/BrandKit/components/utils';

const EMPTY_BRAND_KIT = {
  background: [],
  intro_outro: [],
  fonts: [],
  colors: []
};

export const brandKit = createStore<BrandKitStore>({
  id: null,
  ...EMPTY_BRAND_KIT
});

export const clipsBrandKit = createStore<ClipsBrandKitStore>({});

export function loadBrandKitAndVoiceProfile(): Promise<void> {
  const brandKitPromise = loadBrandKitData();
  const voiceProfilePromise = loadVoiceProfileList();

  return Promise.allSettled([brandKitPromise, voiceProfilePromise]).then(results => {
    const errors = results.filter(result => result.status === 'rejected');
    if (errors.length > 0) {
      return Promise.reject(errors);
    }
  });
}

export function loadClipBrandKit(clipId: string) {
  return loadBrandKitData().then(() => {
    return loadBrandKitData(clipId);
  });
}

export function loadBrandKitData(clipId?: string): Promise<void> {
  const libraryType = clipId ? 'CLIP' : 'ORGANIZATION';
  if (libraryType === 'ORGANIZATION' && brandKit.getSnapshot().id) {
    return Promise.resolve();
  }
  return brandKitList({
    queryParams: {
      library_type: libraryType,
      limit: 1,
      clip_id: clipId
    }
  })
    .then((response: BrandKitListOkResponse) => {
      if (!response.results?.length) {
        createInitialBrandKit(!!clipId).then((res: BrandKitCreateOkResponse) => {
          initBrandKitData(res, clipId);
        });
      } else {
        initBrandKitData(response.results[0], clipId);
      }
    })
    .catch(err => Promise.reject(err));
}

export function loadSharedBrandKitData(clipId: string, fontLocation?: string) {
  if (!fontLocation) {
    return;
  }

  const fontName = decodeURI(getFileNameWithoutExtension(getFileNameFromPath(fontLocation)));

  clipsBrandKit.update(data => {
    const clipBrandKit = data[clipId];
    return {
      ...data,
      [clipId]: {
        ...clipBrandKit,
        fontsMap: {
          brandKit: {
            [fontLocation]: fontName
          }
        }
      }
    };
  });

  const fontFace = new FontFace(fontName, `url(${fontLocation})`);
  fontFace.load().then(loadedFont => {
    document.fonts.add(loadedFont);
  });
}

export function updateIndividualBrandKitConfig(key: string, value: string[], clipId?: string) {
  !!clipId
    ? clipsBrandKit.update(data => {
        const clipBrandKit = data[clipId];
        return {
          ...data,
          [clipId]: {
            ...clipBrandKit,
            [key]: value
          }
        };
      })
    : brandKit.update(data => ({ ...data, [key]: value }));
}

export function storeBrandKitChanges(clipId?: string): Promise<BrandKitUpdateOkResponse> {
  const currentBrandKit = clipId ? clipsBrandKit.getSnapshot()[clipId] : brandKit.getSnapshot();
  window.analytics?.track('ContentLabEvent', {
    name: 'BrandKitEdited',
    brandKitId: currentBrandKit.id
  });
  // If there is first change to clip brand kit - create it
  if (clipId && !currentBrandKit.id) {
    return createClipBradKit(clipId, currentBrandKit).then(brandKit => {
      clipsBrandKit.update(data => {
        return {
          ...data,
          [clipId]: brandKit
        };
      });
      return brandKit;
    });
  }
  return brandKitUpdate({
    id: currentBrandKit.id as string,
    body: {
      ...currentBrandKit,
      library_type: clipId ? 'CLIP' : 'ORGANIZATION',
      clip: clipId
    }
  }).then(response => {
    initBrandKitData(response, clipId);
    return response;
  });
}

function createClipBradKit(clipId: string, brandKitState: BrandKitStore) {
  return brandKitCreate({
    body: {
      ...brandKitState,
      library_type: 'CLIP',
      clip: clipId
    }
  }).then(res => {
    clipsBrandKit.update(data => {
      const id = (res as BrandKitCreateResponse).id;
      const clipBrandKit = data[id];
      return {
        ...data,
        [id]: {
          ...clipBrandKit,
          id
        }
      };
    });
    return res;
  });
}

export function getCurrentClipFontName(clipId: string): string | null {
  const currentFont = currentClip.getSnapshot()[clipId]?.asset_metadata?.font_location;
  const clipBrandKitStore = clipsBrandKit.getSnapshot()[clipId];
  return currentFont && clipBrandKitStore
    ? clipBrandKitStore.fontsMap.brandKit[currentFont] || clipBrandKitStore.fontsMap?.predefined?.[currentFont] || null
    : null;
}

export function getFontNameFromBrandKit(fontUrl?: string): string {
  if (!fontUrl) {
    return '';
  }

  const brandKitStore = brandKit.getSnapshot();
  return brandKitStore?.fontsMap?.[fontUrl] || '';
}

function initBrandKitData(res, clipId: string | undefined) {
  const storeValue = {
    colors: res.colors,
    fonts: res.fonts,
    fontsMap: res.fonts.reduce((acc, el) => {
      return {
        ...acc,
        [getBrandKitUrl(el)]: getFileNameWithoutExtension(getFileNameFromPath(el))
      };
    }, {}),
    background: res.background,
    intro_outro: res.intro_outro,
    id: res.id
  };
  if (clipId) {
    clipsBrandKit.update(data => ({ ...data, [clipId]: storeValue }));
    initClipBrandKit(clipId);
  } else {
    brandKit.set(() => storeValue);
  }
}

function initClipBrandKit(clipId: string) {
  const brandKitFontsMap = {
    brandKit: (brandKit.getSnapshot().fonts || []).reduce((acc, el) => {
      return {
        ...acc,
        [getBrandKitUrl(el)]: getFileNameWithoutExtension(getFileNameFromPath(el))
      };
    }, {}),
    predefined: fontsMap()
  };
  loadBrandKitFonts(brandKitFontsMap.brandKit);
  loadBrandKitFonts(brandKitFontsMap.predefined);
  clipsBrandKit.update(data => {
    const clipBrandKit = data[clipId];
    return {
      ...data,
      [clipId]: {
        ...clipBrandKit,
        fontsMap: brandKitFontsMap
      }
    };
  });
}

function createInitialBrandKit(isClip: boolean): Promise<BrandKitCreateOkResponse> {
  return isClip ? createInitialClipBrandKit() : createInitialOrgBrandKit();
}

function createInitialClipBrandKit(): Promise<BrandKitCreateOkResponse> {
  return Promise.resolve({
    ...EMPTY_BRAND_KIT,
    library_type: 'CLIP'
  });
}

function createInitialOrgBrandKit(): Promise<BrandKitCreateOkResponse> {
  return brandKitCreate({
    body: {
      ...EMPTY_BRAND_KIT,
      library_type: 'ORGANIZATION'
    }
  });
}
