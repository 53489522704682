import { SetupContentStep } from '../types';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { speakersAnalysisStore } from '@/stores/speakersAnalysis/speakersAnalysis';
import { isCustomUpload } from '@/libs/clipContentUtil';

export function mapSpeakerToRequest(speaker: SpeakerWithDetails, contentId: string) {
  return {
    id: speaker.id,
    first_name: speaker.first_name,
    last_name: speaker.last_name,
    title: speaker.title,
    company: speaker.company,
    profile_picture_url: speaker.profile_picture_url,
    content: contentId,
    label: speaker.key,
    key: speaker.key,
    pronouns: speaker.pronouns
  };
}

function moveIdentifiedSpeakersToTheEnd(speakerA: SpeakerWithDetails, speakerB: SpeakerWithDetails, clipId?: string) {
  if (!clipId) {
    return 0;
  }

  const speakersAnalysis = speakersAnalysisStore.getSnapshot();
  const speakerAnalysisForClip = speakersAnalysis[clipId].speaker_mapping;

  const isSpeakerAIdentified = speakerAnalysisForClip[speakerA.id]?.length > 0;
  const isSpeakerBIdentified = speakerAnalysisForClip[speakerB.id]?.length > 0;

  if (isSpeakerAIdentified && !isSpeakerBIdentified) {
    return 1;
  }

  if (!isSpeakerAIdentified && isSpeakerBIdentified) {
    return -1;
  }

  return 0;
}

export function getSetupContentSteps(
  speakers: SpeakerWithDetails[],
  clipId?: string,
  isInlineIdentification?: boolean
): SetupContentStep[] {
  const numberOfSpeakers = speakers.length;
  let newSpeakers: SpeakerWithDetails[] = [...speakers];
  if (clipId && isCustomUpload()) {
    newSpeakers = newSpeakers.sort((speakerA, speakerB) => moveIdentifiedSpeakersToTheEnd(speakerA, speakerB, clipId));
  }

  return [
    ...newSpeakers.map((speaker, index) => {
      return {
        title: `Who is speaking${isInlineIdentification ? ' in this Clip' : ''}? (${index + 1} of ${numberOfSpeakers})`,
        key: index,
        speakerData: getContentSpeakerInfo(speaker, clipId),
        showSaveButton: index + 1 === numberOfSpeakers
      };
    })
  ];
}

function getContentSpeakerInfo(speaker: SpeakerWithDetails, clipId?: string): SpeakerWithDetails {
  let speakerAnalysisForClip = {};
  if (clipId) {
    const speakerAnalysis = speakersAnalysisStore.getSnapshot();
    speakerAnalysisForClip = speakerAnalysis[clipId].speaker_mapping;
  }
  const { face_ids, ...speakerWithoutFaceIds } = speaker;

  return { ...speakerWithoutFaceIds, face_ids: speakerAnalysisForClip[speaker.id] || [] };
}
