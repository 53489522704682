import { IconCategory2 } from '@tabler/icons-react';
import React from 'react';
import Button from '@/components/atoms/Button/Button';

export default function NoMatch({ clearAllFilters }) {
  return (
    <div className="flex w-full items-center justify-center py-32">
      <div className="flex flex-col items-center">
        <IconCategory2 size={32} stroke={1.5} className="text-slate-400" />
        <div className="mt-2 max-w-[20rem] text-center text-sm leading-tight text-slate-400">
          No results found matching your criteria.
        </div>
        <Button onClick={clearAllFilters} variation="outline" className="mt-3" trackingId="clear-all-filters-button">
          Clear All
        </Button>
      </div>
    </div>
  );
}
