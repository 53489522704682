import IconButton from '@/components/atoms/Button/IconButton';
import ConfirmationDialog from '@/components/organisms/ConfirmationDialog';
import useDialog from '@/components/organisms/useDialog';

export default function ColorPreview({ hex, onDelete }: { hex: string; onDelete: (hex: string) => void }) {
  const {
    isOpen: isDeleteConfirmationOpen,
    openDialog: openDeleteConfirmationDialog,
    closeDialog: closeDeleteConfirmationDialog
  } = useDialog();

  function deleteColor() {
    onDelete(hex);
    closeDeleteConfirmationDialog();
  }
  return (
    <div className="flex aspect-[16/11] w-56 flex-col items-center justify-center overflow-hidden rounded-lg border border-slate-200">
      <div className="w-full grow border-b" style={{ backgroundColor: hex }} />
      <div className="flex w-full items-center justify-between space-x-1.5 px-2.5">
        <div className="grow items-center truncate py-3 text-xs">{hex}</div>
        <div className="shrink-0">
          <IconButton
            icon="IconTrash"
            size="small"
            variation="text"
            onClick={openDeleteConfirmationDialog}
            trackingId="delete-color-button"
          />
        </div>
      </div>
      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        onClose={closeDeleteConfirmationDialog}
        onConfirm={deleteColor}
        title="Delete Brand Color?"
        content="You're about to remove a brand color you've chosen. Don't worry, this won't change any of the colors you've already used around the platform."
        confirmLabel="Delete"
      />
    </div>
  );
}
