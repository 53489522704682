import { useSyncExternalStore } from 'react';
import ContentDialog from '@/Pages/Sessions/uiComponents/ContentDialog/ContentDialog';
import { allClips } from '@/stores/clip';
import ClipsListItem from '@/Pages/ClipsListPage/ClipsListItem';
import { Clip } from '@/domains/asset';

export default function ClipListModal({
  isOpen,
  onClose,
  onClipSelection
}: {
  isOpen: boolean;
  onClose: () => void;
  onClipSelection: (clip: Clip) => void;
}) {
  const clips = useSyncExternalStore(allClips.subscribe, allClips.getSnapshot);

  return (
    <ContentDialog isOpen={isOpen} size="medium" setIsOpen={onClose} disableBackdropClose={true}>
      {clips.map((clip: Clip) => (
        <div key={clip.asset_metadata.id}>
          <ClipsListItem clip={clip} onClipSelect={onClipSelection} isCollapsed={false} />
        </div>
      ))}
    </ContentDialog>
  );
}
