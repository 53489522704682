import { CustomEvents } from './constants';

const EventBus = {
  on(event: CustomEvents, callback) {
    const eventListener = (e: CustomEventInit) => callback(e.detail);
    document.addEventListener(event, eventListener);
    return eventListener;
  },
  dispatch(event: CustomEvents, data?) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  off(event: CustomEvents, callback) {
    document.removeEventListener(event, callback);
  }
};

export default EventBus;
