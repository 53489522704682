import { IconBrandVimeo, IconBrandYoutube, IconBrandZoom, IconLink } from '@tabler/icons-react';
import { memo } from 'react';
import Button from '@/components/atoms/Button/Button';
import { useUploadContent } from '@/hooks/useUploadContent';
import Loader from '@/components/atoms/Loader';
import { ReactComponent as WistiaIcon } from '@/assets/icons/wistia.svg';
import FilesInput from '@/components/atoms/inputs/FilesInput/FilesInput';
import { CONTENT_FORMATS } from '@/Pages/Sessions/NewContentModal/constants';

const ContentUpload = () => {
  const { urlInputValue, isImporting, isValidLink, handleUrlInputChange, onFilesUpload, startImport } =
    useUploadContent();

  return (
    <>
      <div className="relative flex w-full items-center text-slate-700 focus-within:text-deep-orange-600">
        <IconLink className="absolute left-3" size={20} />
        <input
          type="text"
          data-testid="import-url-input"
          onChange={handleUrlInputChange}
          className="w-full rounded-lg border py-3.5 pl-10 pr-28 text-sm text-black focus:ring-deep-orange-600"
          placeholder="Drop a video link…"
        />
        <Button
          className="absolute right-1 m-1"
          variation="filled"
          trackingId="import-video-button"
          buttonSize="large"
          disabled={isImporting || !urlInputValue}
          onClick={startImport}
        >
          {isImporting && <Loader size="xs" />}
          Import Video
        </Button>
      </div>
      <div className="mt-1.5 flex text-xs text-slate-500">
        {isValidLink ? (
          <div className="flex whitespace-nowrap">
            Use a
            <div className="ml-1 flex space-x-1 whitespace-nowrap">
              <span className="inline-flex gap-1 whitespace-nowrap">
                <IconBrandYoutube size={16} /> YouTube,
              </span>
              <span className="inline-flex gap-1 whitespace-nowrap">
                <IconBrandZoom size={16} /> Zoom,
              </span>
              <span className="inline-flex gap-1 whitespace-nowrap">
                <WistiaIcon className="h-3.5 w-3 stroke-slate-500" />
                Wistia, or
              </span>
              <span className="inline-flex gap-1 whitespace-nowrap">
                <IconBrandVimeo size={16} /> Vimeo or direct link to a video file.
              </span>
            </div>
          </div>
        ) : (
          <span className="text-deep-orange-600">
            Oops! It looks like the URL you entered isn't quite right. Please double-check it and try again.
          </span>
        )}
      </div>
      <div className="mb-1.5 mt-4 flex w-full items-center">
        <div className="h-[1px] grow bg-slate-300"></div> <div className="shrink-0 px-4 text-xs text-slate-500">OR</div>
        <div className="h-[1px] grow bg-slate-300"></div>
      </div>
      <div className="grow">
        <FilesInput onFilesUpload={onFilesUpload} accept={CONTENT_FORMATS} title="Upload a file" multiple={false} />
      </div>
    </>
  );
};

export default memo(ContentUpload);
