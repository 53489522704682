import { Popover } from '@headlessui/react';
import { memo } from 'react';
import ClipBackgroundSelector from '../../SideBar/ClipBackgroundSelector/ClipBackgroundSelector';

function ClipCustomizerBackgroundPopup() {
  return (
    <Popover className="absolute right-20 flex max-h-[54vh] w-[23rem] flex-col overflow-y-auto rounded-lg border bg-white shadow">
      <div className="sticky top-0 z-10 flex items-center justify-between bg-white px-4 py-3">
        <div className="text-lg font-medium">Background</div>
      </div>
      <div className="relative min-h-[10rem]">
        <div className="fixed w-[23rem] px-4 py-3">
          <ClipBackgroundSelector hideTitle={true} />
        </div>
      </div>
    </Popover>
  );
}

export default memo(ClipCustomizerBackgroundPopup);
