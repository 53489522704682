import React, { Fragment, useMemo, useState } from 'react';
import moment from 'moment';
import {
  IconDotsVertical,
  IconTrash,
  IconMicrophone2,
  IconRuler3,
  IconPrompt,
  IconLink,
  IconDownload
} from '@tabler/icons-react';
import { Menu, Transition } from '@headlessui/react';
import { PostListItemProps } from '../types';
import { capitalizeFirstLetter, copyTextToClipboard } from '../utils';
import { useAppContext } from '@/context/AppContext/AppContext';
import { classnames } from '@/libs/utils';
import { getTitleFromMarkdownString, parseTextTitle } from '@/Pages/Sessions/utils';
import TextPreview from '@/Pages/Sessions/AssetsTable/TextPreview';
import useAnalytics from '@/hooks/useAnalytics';
import useDownloadAsset from '@/hooks/useDownloadAsset';
import AssetRating from '@/components/molecules/AssetRating/AssetRating';

export default function PostListItem({ post, selected, isCollapsed, handleSelectedPost, onDelete }: PostListItemProps) {
  const { logger } = useAppContext();
  const [copyPromptText, setCopyPromptText] = useState('Copy Prompt');
  const { trackTextCopiedToClipboard } = useAnalytics();
  const { onDownloadTextAsset } = useDownloadAsset({ type: 'TEXT', textAsset: post });

  const formattedSummary = useMemo(() => {
    return {
      title: parseTextTitle(post.title) || getTitleFromMarkdownString(post?.asset_metadata?.output),
      type: post?.asset_metadata?.type,
      output: post?.asset_metadata?.output,
      createdAt: moment.utc(post?.asset_metadata?.created_at).fromNow()
    };
  }, [post]);

  let contextMenu = useMemo(
    () => [
      {
        Icon: IconDownload,
        label: 'Download',
        action: () => {
          onDownloadTextAsset();
        }
      },
      {
        Icon: IconTrash,
        label: 'Delete',
        action: () => {
          onDelete(post.id);
        }
      }
    ],
    [post, onDelete, onDownloadTextAsset]
  );

  const copyPrompt = () => {
    setCopyPromptText('Copied!');
    copyTextToClipboard(post.asset_metadata.prompt, logger);
    trackTextCopiedToClipboard(post);
    setTimeout(() => {
      setCopyPromptText('Copy Prompt');
    }, 2000);
  };

  return (
    <div
      role="button"
      onClick={() => handleSelectedPost(post)}
      className={classnames('group flex w-full cursor-pointer items-stretch space-x-4 px-5 py-3.5 pr-3 text-left', {
        'bg-slate-100': selected
      })}
    >
      <Transition
        show={!isCollapsed}
        appear
        enter="transition-all ease-in-out duration-400"
        enterFrom="opacity-0 translate-x-[-2rem]"
        enterTo="opacity-100 translate-x-0"
        leave="transition-all ease-in-out duration-400"
        leaveFrom="translate-x-0 opacity-100"
        leaveTo="translate-x-[-10px] opacity-0"
      >
        <div className="aspect-square h-full w-28 overflow-hidden rounded-lg">
          <TextPreview type={formattedSummary.type} output={formattedSummary.output} />
        </div>
      </Transition>
      <div className="flex min-h-[108px] grow flex-col justify-between pt-0.5">
        <div className="flex w-full items-center justify-between space-x-1">
          <div className="flex grow justify-between space-x-1">
            <div className="line-clamp-3 w-[250px] max-w-[250px] grow overflow-hidden text-ellipsis text-sm font-medium leading-tight">
              {formattedSummary.title}
            </div>
            <div onClick={e => e.stopPropagation()}>
              <Menu as="div" className="relative inline-block text-left">
                <Menu.Button
                  data-testid="post-list-item-menu-trigger"
                  as="div"
                  className={classnames(
                    '-mt-0.5 flex h-8 w-8 shrink-0 items-center justify-center rounded-md opacity-0 transition-all duration-100 ease-out hover:bg-slate-200/50 group-hover:opacity-100',
                    { 'opacity-100': selected }
                  )}
                >
                  <IconDotsVertical className="h-5 w-5 text-slate-600" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 w-48 rounded-lg border bg-white p-1 shadow-lg">
                    {contextMenu.map(menuItem => {
                      const { Icon: MenuItemIcon, label, action } = menuItem;
                      return (
                        <Menu.Item key={label}>
                          <div
                            onClick={action}
                            className="flex items-center space-x-2 rounded-md px-2 py-2 text-sm hover:bg-slate-100"
                          >
                            <MenuItemIcon className="h-4 w-4" />
                            <span className="text-xs font-medium">{label}</span>
                          </div>
                        </Menu.Item>
                      );
                    })}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        {selected && (
          <div className="flex items-center justify-end">
            <AssetRating assetId={post.id} rating={post.rating} assetType="TEXT" />
          </div>
        )}
        <div className="flex items-center justify-between">
          {!selected ? (
            <>
              <div className="text-xs text-slate-500">{formattedSummary.createdAt}</div>
              <div>
                <AssetRating assetId={post.id} rating={post.rating} assetType="TEXT" />
              </div>
            </>
          ) : (
            <div className="flex w-full items-center justify-between space-x-1">
              <div className="flex w-full space-x-1">
                {post.voice_profile?.name ? (
                  <div className="flex w-full items-center space-x-1 rounded-full bg-slate-200 px-2 py-1 text-2xs">
                    <IconMicrophone2 className="h-3.5 w-3.5 shrink-0" />
                    <span className="line-clamp-1">{capitalizeFirstLetter(post.voice_profile?.name)}</span>
                  </div>
                ) : (
                  post.asset_metadata?.tone && (
                    <div className="flex items-center space-x-1 rounded-full bg-slate-200 px-2 py-1 text-2xs">
                      <IconMicrophone2 className="h-3.5 w-3.5" />
                      <span>{capitalizeFirstLetter(post.asset_metadata.tone)}</span>
                    </div>
                  )
                )}
                <div className="flex items-center space-x-1 rounded-full bg-slate-200 px-2 py-1 text-2xs">
                  <IconRuler3 className="h-3.5 w-3.5" />
                  <span>{capitalizeFirstLetter(post.asset_metadata.length)}</span>
                </div>
                {post.asset_metadata.prompt ? (
                  <div className="group/prompt flex w-full grow items-center">
                    <div className="group-hover/prompt-pr-0 flex items-center space-x-1 rounded-l-full rounded-r-full bg-slate-200 px-2 py-1 text-2xs group-hover/prompt:hidden group-hover/prompt:rounded-r-none group-hover/prompt:bg-slate-300">
                      <IconPrompt className="h-3.5 w-3.5 shrink-0" />
                      <span className="line-clamp-1">{post.asset_metadata.prompt}</span>
                    </div>
                    <div
                      onClick={copyPrompt}
                      title={post.asset_metadata.prompt}
                      className="hidden w-full grow justify-center space-x-1 whitespace-nowrap rounded-full bg-slate-200 px-2 py-1 pr-2 text-2xs font-medium group-hover/prompt:flex group-hover/prompt:bg-slate-300"
                    >
                      <IconLink className="mr-1 h-3.5 w-3.5" />
                      {copyPromptText}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
