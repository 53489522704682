import { userSpeakerPartialUpdate } from '@goldcast/api/content';
import { useRef } from 'react';
import IconButton from '../Button/IconButton';
import Avatar from '../Avatar/Avatar';
import { getSpeakerImage } from '@/libs/utils';
import FilestackUploader from '@/Pages/Clip/SideBar/BumperUploader/FilestackUploader';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { useAppContext } from '@/context/AppContext/AppContext';
import { showErrorToast } from '@/libs/toast/toast';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';

export function AvatarUpload({
  speaker,
  onAvatarChange
}: {
  speaker: SpeakerWithDetails;
  onAvatarChange?: (url: string) => void;
}) {
  const { logger } = useAppContext();
  const transcriptStore = useTranscriptContext();
  const speakerImageRef = useRef<HTMLImageElement>(null);
  const updateImageMapOnLoad = useRef(false);

  const updateSpeakerProfilePictureUrl = async (uploadUrl: string) => {
    updateImageMapOnLoad.current = true;
    try {
      await userSpeakerPartialUpdate({
        id: speaker.id,
        body: { profile_picture_url: uploadUrl }
      });
      updateSpeaker(uploadUrl, false, false);
    } catch (error) {
      logger.error(error);
      showErrorToast('Looks like there was a hiccup updating the avatar. Double-check your changes and retry.');
    }
  };

  const updateSpeaker = (avatarUrl: string, shouldUpdateSpeakerImageMap = true, shouldUpdateClip = true) => {
    if (!updateImageMapOnLoad.current) return;

    if (transcriptStore) {
      EventBus.dispatch(CustomEvents.SpeakersChanged, {
        updatedSpeakers: {
          ...transcriptStore?.speakersWithDetails,
          [speaker.key]: {
            ...transcriptStore.speakersWithDetails[speaker.id],
            ...speaker,
            profile_picture_url: avatarUrl
          }
        },
        shouldUpdateClip,
        ...(shouldUpdateSpeakerImageMap && {
          speakerKey: speaker.key,
          speakerImage: speakerImageRef.current
        })
      });
    }

    if (onAvatarChange) {
      onAvatarChange(avatarUrl);
    }
  };

  const deleteAvatar = async () => {
    updateSpeakerProfilePictureUrl('');
    updateSpeaker('', false);
  };

  return (
    <div className="flex items-center space-x-2 rounded-md border border-slate-200 p-1 pr-4">
      <Avatar
        className="shrink-0"
        imageClassName="rounded"
        style={{
          height: '3.5rem',
          width: '6rem',
          flexShrink: 0
        }}
        name={`${speaker.first_name ?? ''} ${speaker.last_name ?? ''}`}
        imageUrl={getSpeakerImage(speaker.profile_picture_url)}
        imageRef={speakerImageRef}
        onImageLoad={updateSpeaker}
      />
      <div className="ml-auto flex">
        <FilestackUploader acceptedTypes={['image/jpeg', 'image/png']} onUpload={updateSpeakerProfilePictureUrl}>
          <IconButton
            icon="IconUpload"
            buttonClassName="rounded-e-none"
            size="small"
            content="Upload"
            trackingId="upload-avatar-button"
          />
        </FilestackUploader>
        <IconButton
          icon="IconTrash"
          size="small"
          buttonClassName="rounded-s-none"
          content="Delete"
          disabled={!speaker.profile_picture_url}
          onClick={deleteAvatar}
          trackingId="delete-avatar-button"
        />
      </div>
    </div>
  );
}
