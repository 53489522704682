import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';

export function isSpeakerChanged(newCurrentSpeaker: SpeakerWithDetails, currentSpeaker: SpeakerWithDetails) {
  return (
    newCurrentSpeaker &&
    (newCurrentSpeaker.key !== currentSpeaker?.key ||
      newCurrentSpeaker.first_name !== currentSpeaker?.first_name ||
      newCurrentSpeaker.last_name !== currentSpeaker?.last_name ||
      newCurrentSpeaker.company !== currentSpeaker?.company ||
      newCurrentSpeaker.title !== currentSpeaker?.title ||
      newCurrentSpeaker.profile_picture_url !== currentSpeaker?.profile_picture_url)
  );
}

export function isIosBrowser() {
  return /iPhone|iPad/i.test(navigator.userAgent);
}

export function getClipPlayerElementId(clipId: string) {
  return `clip-${clipId}`;
}
