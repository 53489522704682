import createStore from './store';

export interface ClipPlayerStore {
  video: HTMLVideoElement;
  currentTime: number;
  duration: number;
  paused: boolean;
  currentSrtIndex: number;
}

export const defaultClipPlayerStore: ClipPlayerStore = {
  currentTime: 0,
  video: document.createElement('video'),
  duration: 0,
  paused: true,
  currentSrtIndex: 1
};

export function initPlayerV2(clipId: string) {
  playerV2.update(state => ({
    ...state,
    [clipId]: defaultClipPlayerStore
  }));
}

export const playerV2 = createStore<Record<string, ClipPlayerStore>>({});

export function updatePlayerStoreWithId(clipId: string, data: Partial<ClipPlayerStore>) {
  playerV2.update(state => ({
    ...state,
    [clipId]: {
      ...state[clipId],
      ...data
    }
  }));
}
