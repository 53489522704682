import { memo, useMemo } from 'react';
import { BrandToneFile } from '../types';
import IconButton from '@/components/atoms/Button/IconButton';
import Icon from '@/components/atoms/Icon';

const ImportedFileItem = ({
  importedFileDetails,
  removeImportedItem
}: {
  importedFileDetails: BrandToneFile;
  removeImportedItem: () => void;
}) => {
  const fileItemName = useMemo(() => {
    const { fileType, fileName, sampleCopy } = importedFileDetails;
    if (fileType === 'pdf') {
      return fileName;
    }
    return sampleCopy?.substring(0, 30) + (sampleCopy && sampleCopy?.length > 30 ? '…' : '');
  }, [importedFileDetails]);

  return (
    <div className="mt-4">
      <h3 className="mb-2 text-sm font-medium text-slate-700">Imported Items:</h3>
      <div className="flex items-center justify-between rounded-md bg-slate-50 p-2">
        <div className="flex items-center">
          <Icon
            name={importedFileDetails.fileType === 'pdf' ? 'IconFileTypePdf' : 'IconFileText'}
            className="mr-2 h-5 w-5 text-slate-500"
          />
          <div className="text-sm text-slate-600">{fileItemName}</div>
        </div>
        <IconButton
          icon="IconTrash"
          variation="text"
          onClick={removeImportedItem}
          buttonClassName="h-6 w-6 !text-slate-400 hover:!text-red-500 transition-colors !p-0"
          trackingId="delete-imported-item-click"
        />
      </div>
    </div>
  );
};

export default memo(ImportedFileItem);
