import React from 'react';
import EmptyProfilePicturePlaceHolder from './EmptyProfilePicturePlaceHolder';
import AudiogramImage from './AudiogramImage';
import { AudiogramCommonProps } from './types';
import AudiogramCaptions from './AudiogramCaptions';
import Waveform from '@/components/atoms/Waveform/Waveform';
import Captions from '@/components/molecules/Captions';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function Square({
  height,
  width,
  sizeMultiplier = 1,
  backgroundColor,
  textColor,
  activeSpeaker,
  truncatedName,
  truncatedTitle,
  fontFamilyStyle,
  isTemplatePreview,
  backgroundImage
}: AudiogramCommonProps) {
  const { playerStore } = useClipsContext();

  const templatePreviewStyles = isTemplatePreview ? { height: height * 0.8 + 'px' } : {};

  const backgroundStyle = !backgroundImage ? { backgroundColor } : { backgroundImage: `url(${backgroundImage})` };

  return (
    <div
      className="absolute left-0 top-0 flex h-full w-full flex-col overflow-hidden rounded bg-cover bg-center"
      style={{ color: textColor, ...backgroundStyle }}
    >
      <div className="flex">
        <div
          className="w-full bg-cover bg-center"
          data-testid="speaker-image"
          style={{
            height: height * 0.835 + 'px'
          }}
        >
          {activeSpeaker.id && !activeSpeaker.profile_picture_url ? (
            <EmptyProfilePicturePlaceHolder previewOnly={isTemplatePreview} />
          ) : (
            <AudiogramImage activeSpeaker={activeSpeaker} />
          )}
        </div>
        <div
          className={`items-center; margin-top: flex h-full justify-center ${width * 0.185}px`}
          style={{ width: width * 0.15 + 'px', paddingTop: width * 0.03 + 'px', ...templatePreviewStyles }}
        >
          <div
            className="truncate text-left"
            style={{
              writingMode: 'vertical-rl',
              textOrientation: 'sideways',
              lineHeight: width * 0.02 + 'px',
              fontSize: width * 0.027 + 'px',
              ...fontFamilyStyle
            }}
          >
            {truncatedTitle}
          </div>
          <div
            className="truncate text-left"
            style={{
              writingMode: 'vertical-rl',
              textOrientation: 'sideways',
              fontSize: width * 0.027 + 'px',
              ...fontFamilyStyle
            }}
          >
            {truncatedName}
          </div>
        </div>
      </div>
      <div className="flex grow items-center justify-between">
        <div
          style={{
            width: width * 0.835 + 'px',
            paddingLeft: width * 0.035 + 'px',
            fontSize: width * 0.04 + 'px',
            lineHeight: width * 0.043 + 'px',
            textAlign: 'left'
          }}
        >
          <Captions previewOnly={isTemplatePreview}>
            <AudiogramCaptions
              width={width}
              fontFamilyStyle={fontFamilyStyle}
              sizeMultiplier={sizeMultiplier}
              textColor={textColor}
              fontSizeCorrectionFactor={0.09}
            />
          </Captions>
        </div>
        {textColor && (
          <div className="flex items-center justify-center" style={{ width: width * 0.14 + 'px' }}>
            <Waveform textColor={textColor} paused={playerStore.paused} height={height * 0.075} />
          </div>
        )}
      </div>
    </div>
  );
}
