import { memo } from 'react';
import { useRemarkSync } from 'react-remark';
import { gradientTextAssetMapping } from '../utils';

export default memo(function TextPreview({ type, output }: { type: string; output: string }) {
  const previewOutput = useRemarkSync(output || '');

  return (
    <div className="flex h-full items-center justify-center overflow-hidden bg-slate-100 group-hover:border-slate-200">
      <div
        className={`${gradientTextAssetMapping[type]} relative flex h-full w-full items-center justify-center rounded-lg pt-1 before:absolute before:left-0 before:top-1 before:-z-10 before:h-full before:w-full before:bg-gradient-to-r before:opacity-0 before:blur-[5px] before:transition-all before:duration-200 before:ease-in-out group-hover:before:opacity-30`}
      >
        <div
          className={`${gradientTextAssetMapping[type]} h-[90%] w-full overflow-hidden bg-clip-text px-2 text-[0.7rem] font-medium leading-[0.96rem] text-transparent mix-blend-screen`}
        >
          {previewOutput}
        </div>
      </div>
    </div>
  );
});
