import { SpeakerWithDetails } from '../TranscriptContext/TranscriptContextTypes';
import type { Word } from '@/domains/transcript';

const DEFAULT_SEGMENT_SPEAKER: SpeakerWithDetails = {
  key: 'spk_0',
  id: 'spk_0',
  first_name: '',
  last_name: '',
  color: '',
  bgColor: '',
  speakingSlots: {},
  company: '',
  profile_picture_url: '',
  title: '',
  video: {
    start: 0,
    url: ''
  },
  face_ids: [],
  parent_id: null,
  pronouns: 'he'
};

export function getSegmentWordsAndStartSegment(transcript: Word[]) {
  return transcript.reduce(
    (acc, word) => {
      if (acc.startSegment === -1) {
        acc.startSegment = word.srt_index;
      }
      acc.segmentWords[word.srt_index] = [...(acc.segmentWords[word.srt_index] || []), word];
      return acc;
    },
    { startSegment: -1, segmentWords: {} as Record<number, Word[]> }
  );
}

export function getSegmentSpeaker(
  segmentWords: Record<number, Word[]>,
  speakersData: Record<string, SpeakerWithDetails>
) {
  return Object.keys(segmentWords).reduce((acc, key) => {
    const index = +key;
    const mostRepeatedSpeaker = findMostRepeatedSpeaker(segmentWords[index]);
    const speaker = speakersData[mostRepeatedSpeaker as string];
    acc[index] = speaker ? { ...speaker, key: mostRepeatedSpeaker as string } : DEFAULT_SEGMENT_SPEAKER;
    return acc;
  }, {} as Record<string, SpeakerWithDetails>);
}

function findMostRepeatedSpeaker(segmentWords: Pick<Word, 'speaker_label'>[]) {
  const speakerFrequency: Record<string, number> = {};

  segmentWords.forEach(obj => {
    const speaker = obj.speaker_label;
    if (speaker in speakerFrequency) {
      speakerFrequency[speaker]++;
    } else {
      speakerFrequency[speaker] = 1;
    }
  });

  let mostRepeatedSpeaker;
  let highestFrequency = 0;

  for (const speaker in speakerFrequency) {
    if (speakerFrequency[speaker] > highestFrequency) {
      highestFrequency = speakerFrequency[speaker];
      mostRepeatedSpeaker = speaker;
    }
  }

  return mostRepeatedSpeaker;
}
