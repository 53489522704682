import { Downloads } from '@goldcast/api/content';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import UpscaledVideoToggle from './UpscaledVideoToggle';
import BrandingToggle from '../BrandingToggle';
import useDownloadAsset from '@/hooks/useDownloadAsset';
import ApiButton from '@/components/atoms/ApiButton/ApiButton';
import { DOWNLOADING_LABELS } from '@/Pages/Clip/constants';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

const DOWNLOAD_SUCCESS_STATE_DURATION = 5000;

export default function ClipShareDownloadTab() {
  const { clipData } = useClipsContext();

  const { isDownloadProcessed, isUpscaleVideoEnabled, setShouldUpscaleVideo, onDownloadClip, updateDownloadState } =
    useDownloadAsset({ type: 'CLIP', clip: clipData });

  const location = useLocation();

  const isRecordingsPage = useMemo(() => {
    return location.pathname.includes('/recordings');
  }, [location.pathname]);

  const isFailedDownloadStatus = (downloadsData: Downloads) => {
    return downloadsData.status === 'FAILED';
  };

  return (
    <div className="mt-2 p-2">
      <div className="mb-5 grid grid-cols-3 items-center gap-y-2.5 px-1.5 text-sm">
        <div>Resolution</div>
        <div className="col-span-2">
          <div className="w-full rounded-md py-2 text-right focus:outline-none focus:ring-2 focus:ring-slate-950">
            1080p
          </div>
        </div>
        <div>Format</div>
        <div className="col-span-2">
          <div className="w-full rounded-md py-2 text-right focus:outline-none focus:ring-2 focus:ring-slate-950">
            MP4
          </div>
        </div>
        <BrandingToggle />
      </div>
      {isUpscaleVideoEnabled && !isRecordingsPage && (
        <UpscaledVideoToggle onUpscaleChange={val => setShouldUpscaleVideo(val)} />
      )}
      <ApiButton
        action={onDownloadClip}
        disabled={!clipData.asset_metadata?.config}
        title="Start Download"
        successMessage={!isDownloadProcessed ? 'Processing Started' : 'Success'}
        variation="filled"
        trackingId="download-button"
        buttonClassNames="rounded-lg h-12 text-white mt-3"
        buttonSize="large"
        failMessage="Download failed"
        fullWidth
        isFailedFn={isFailedDownloadStatus}
        inProgressLabels={DOWNLOADING_LABELS}
        onStatusChanged={updateDownloadState}
        completedStateDuration={DOWNLOAD_SUCCESS_STATE_DURATION}
      />
    </div>
  );
}
