import { IconPhoto } from '@tabler/icons-react';
import { CustomEvents } from '@/libs/eventBus/constants';
import EventBus from '@/libs/eventBus/eventBus';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';

export default function EmptyProfilePicturePlaceHolder({
  previewOnly = false,
  speaker
}: {
  previewOnly?: boolean;
  speaker?: SpeakerWithDetails;
}) {
  function openContentSettings() {
    if (!previewOnly) EventBus.dispatch(CustomEvents.OpenContentSettings, { speakerId: speaker?.id });
  }
  return (
    <div
      className="flex h-full w-full flex-col items-center justify-center gap-2 bg-slate-200 text-center text-sm text-slate-500"
      data-testid="placeholder-container"
    >
      <IconPhoto size={previewOnly ? 8 : 28} />
      {!previewOnly && (
        <p className="max-w-[9rem]">
          Add a speaker image in the
          <span className="cursor-pointer underline" onClick={openContentSettings}>
            {' '}
            settings{' '}
          </span>
          menu
        </p>
      )}
    </div>
  );
}
