import { SidebarContext } from './SideBarUtils';

export function shouldRenderMainSideBar(sideBarContext: SidebarContext): boolean {
  return (
    sideBarContext === SidebarContext.Main ||
    [
      SidebarContext.Audiogram,
      SidebarContext.ActiveSpeaker,
      SidebarContext.SpeakerGrid,
      SidebarContext.Default
    ].includes(sideBarContext)
  );
}
