import { memo, useEffect } from 'react';
import { Listbox } from '@headlessui/react';
import { classnames, isEmptyObject } from '@/libs/utils';
import { loadBrandKitFonts } from '@/Pages/BrandKit/components/utils';
import Icon from '@/components/atoms/Icon';

const BrandingFontSelectList = ({ fontsMap }: { fontsMap: Record<string, string> }) => {
  useEffect(() => {
    if (isEmptyObject(fontsMap)) {
      return;
    }

    loadBrandKitFonts(fontsMap);
  }, [fontsMap]);

  return (
    <>
      {Object.keys(fontsMap).map(fontUrl => {
        const fontName = fontsMap[fontUrl];
        return (
          <Listbox.Option key={fontName} value={fontUrl}>
            {({ active, selected }) => (
              <div
                className={classnames(
                  'relative cursor-default select-none py-2.5 pl-3 pr-9 text-lg',
                  active ? 'bg-slate-100' : 'bg-white',
                  selected ? 'font-semibold' : 'font-normal'
                )}
                style={{ fontFamily: fontsMap[fontUrl] }}
              >
                {fontsMap[fontUrl]}
                {selected && (
                  <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                    <Icon name="IconCheck" size={20} />
                  </span>
                )}
              </div>
            )}
          </Listbox.Option>
        );
      })}
    </>
  );
};

export default memo(BrandingFontSelectList);
