import React, { useCallback, useState } from 'react';
import NoData from './NoData';
import useDebounce from '@/hooks/useDebounce';

export default function MultiSelect({ options, selected, onSelect, itemRenderer, searchValue }) {
  const [values, setValues] = useState(selected);

  const onSelectionUpdate = useCallback(() => {
    onSelect(values);
  }, [onSelect, values]);

  const debouncedOnSelect = useDebounce(onSelectionUpdate, 500);

  const onSelectionChange = value => {
    debouncedOnSelect();
    if (values.includes(value)) {
      debouncedOnSelect();
      setValues(values.filter(v => v !== value));
    } else {
      debouncedOnSelect();
      setValues([...values, value]);
    }
  };

  return options.length === 0 ? (
    <NoData noMatch={!!searchValue} />
  ) : (
    options.map((option: any) => {
      return (
        <div className="flex cursor-pointer rounded p-1.5 px-3 hover:bg-slate-100" key={option.value}>
          <input
            type="checkbox"
            id={option.value}
            title={option.label}
            checked={values.includes(option.value)}
            onChange={() => onSelectionChange(option.value)}
            className="mt-[1px] h-4 w-4 cursor-pointer rounded border border-solid border-slate-300 text-deep-orange focus:ring-deep-orange"
          />
          <label className="w-full cursor-pointer truncate pl-2" htmlFor={option.value}>
            <div className="truncate leading-tight">{option.label}</div>
            {itemRenderer?.(option)}
          </label>
        </div>
      );
    })
  );
}
