import { memo, useMemo, useSyncExternalStore } from 'react';
import ClipSpeakersTimelineDuration from './ClipSpeakersTimelineDuration';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';

function ClipSpeakersTimelineSpeakers({
  fullRecordingDuration,
  startDragHandleX
}: {
  fullRecordingDuration: number;
  startDragHandleX: number;
}) {
  const transcriptStore = useTranscriptContext();

  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const isFullRecordingTrimEnabled = featureFlags[FeatureFlagKeys.Use_CL_Edit_Full_Recording_Trim];

  const speakers = useMemo(
    () => Object.values(transcriptStore?.speakersWithDetails || []).filter(speaker => !speaker.parent_id),
    [transcriptStore?.speakersWithDetails]
  );

  return (
    <>
      {speakers.map(speaker => (
        <div className="pointer-events-none relative mt-2 h-5 w-full" key={speaker.key}>
          <div
            className={`sticky z-50 w-fit shrink-0 text-[10px] font-medium uppercase tracking-wider ${speaker.color} ml-0.5 select-none`}
            style={{
              // 5 as a left padding from drag handles
              left: isFullRecordingTrimEnabled ? `${Math.max(startDragHandleX, 10) + 5}px` : 0
            }}
          >
            <span>{`${speaker.first_name} ${speaker.last_name}`}</span>
          </div>
          <div className="absolute bottom-0 mt-0.5 h-1.5 w-full grow rounded-md bg-slate-200 bg-opacity-75">
            {Object.keys(speaker.speakingSlots).map(key => {
              return (
                <span
                  className={`absolute ${speaker.bgColor} h-full rounded-full`}
                  style={{
                    width: ((speaker.speakingSlots[key] - +key) / fullRecordingDuration) * 100 + '%',
                    left: (+key / fullRecordingDuration) * 100 + '%'
                  }}
                  key={key}
                />
              );
            })}
          </div>
        </div>
      ))}
      <ClipSpeakersTimelineDuration />
    </>
  );
}

export default memo(ClipSpeakersTimelineSpeakers);
