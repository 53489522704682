import { Clip } from '@/domains/asset';

export function getAnalyticsClipEditType(entityUpdated: string): string {
  switch (entityUpdated) {
    case 'DELETE_WORDS':
    case 'EDIT_WORD':
    case 'CAPTIONS_ON':
    case 'CAPTIONS_OFF':
    case 'UPDATE_HIGHLIGHT_TYPE':
    case 'UPDATE_HIGHLIGHT_COLOR':
      return 'Captions';
    case 'TOGGLE_SPEAKER_LABELS':
    case 'UPDATE_VISIBLE_FACE_IDS':
    case 'UPDATE_VISIBLE_SPEAKERS':
      return 'Speakers';
    case 'SELECT_MAGIC_LAYOUT':
    case 'CHANGE_SIZE':
    case 'UPDATE_MAGIC_LAYOUT':
    case 'LAYOUT_CHAGE':
      return 'Layout';
    case 'DURATION_CHANGED':
      return 'Duration';
    default:
      return entityUpdated;
  }
}

export function getClipAnalyticsPayload(clip: Clip) {
  return {
    id: clip.id,
    title: clip.title,
    is_sample_upload: clip.content?.is_sample_upload,
    isCustomUpload: clip.content?.media_source_type === 'UPLOAD',
    generatedBy: clip.asset_metadata.source,
    contentType: 'CLIP',
    templateType: clip.asset_metadata.layout,
    size: clip.asset_metadata.size,
    captions: clip.asset_metadata.subtitle ? 'On' : 'Off'
  };
}
