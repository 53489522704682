import { useMemo, useSyncExternalStore } from 'react';
import { getListOfVisibleSpeakers } from '../../CompositePlayer/CompositePlayerUtils';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import { core } from '@/stores/core';

export const useActiveSpeakerMatchCondition = () => {
  const { clipData, speakersAnalysis, layoutStatus } = useClipsContext();
  const transcriptStore = useTranscriptContext();
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);

  const [isNoFaceAtEveryAnalysisPoint, isOnlyOneFaceAtEveryAnalysisPoint, isOneOrNoFaceAtEveryAnalysisPoint] = useMemo(
    () => [
      speakersAnalysis.face_ids.length === 0,
      speakersAnalysis.time_analysis.every(analysisPoint => analysisPoint.face_positions.length === 1),
      speakersAnalysis.time_analysis.every(analysisPoint => analysisPoint.face_positions.length <= 1)
    ],
    [speakersAnalysis.time_analysis]
  );

  // these are the number of speakers talking in the clip
  const speakersInClip = useMemo(() => {
    return getListOfVisibleSpeakers(
      transcriptStore.speakersWithDetails,
      clipData.asset_metadata.start,
      clipData.asset_metadata.end,
      true,
      true
    ).map(speaker => speaker.id);
  }, [transcriptStore.speakersWithDetails, clipData.asset_metadata.start, clipData.asset_metadata.end]);

  // determines if there are unidentified speakers in the clip
  // unidentified speakers are those speakers to whom faces are not mapped
  const containsUnidentifiedSpeakers = useMemo(() => {
    if (coreStore.content?.media_source_type !== 'UPLOAD') return false;

    if (layoutStatus !== 'DONE') return false;

    const areAllSpeakersIdentified = speakersInClip.every(
      speakerId => speakersAnalysis.speaker_mapping[speakerId]?.length
    );

    return !areAllSpeakersIdentified;
  }, [layoutStatus, coreStore.content?.media_source_type, speakersAnalysis.speaker_mapping, speakersInClip]);

  // Is used to bypass the condition when there are unidentified speakers in the clip but the faces need not be mapped to speakers
  const isSpeakerIdentificationMandatory = useMemo(() => {
    if (isNoFaceAtEveryAnalysisPoint || isOnlyOneFaceAtEveryAnalysisPoint || isOneOrNoFaceAtEveryAnalysisPoint)
      return false;

    return containsUnidentifiedSpeakers;
  }, [
    isNoFaceAtEveryAnalysisPoint,
    isOnlyOneFaceAtEveryAnalysisPoint,
    isOneOrNoFaceAtEveryAnalysisPoint,
    containsUnidentifiedSpeakers
  ]);

  // used to make identify speakers behave like active speaker when using speaker grid and there is only one speaker in the clip
  const shouldBehaveLikeActiveSpeaker = useMemo(
    () => isOneOrNoFaceAtEveryAnalysisPoint && speakersInClip.length === 1,
    [isOneOrNoFaceAtEveryAnalysisPoint, speakersInClip]
  );

  return {
    isSpeakerIdentificationMandatory,
    containsUnidentifiedSpeakers,
    isNoFaceAtEveryAnalysisPoint,
    shouldBehaveLikeActiveSpeaker
  };
};
