import moment from 'moment';
import { MediaContent, assetsMediaContentList } from '@goldcast/api/content';
import { useState, useEffect } from 'react';
import useContentUploadHook from '@/hooks/useContentUploadHook';
import { ContentStatesEnum } from '@/Pages/GenerateContent/constants';
import { currentUser } from '@/stores/user';

export default function useMediaContent() {
  const [mediaContent, setMediaContent] = useState<MediaContent[]>([]);
  const [showErrorState, setShowErrorState] = useState(false);
  const { contentUploadList } = useContentUploadHook();

  useEffect(() => {
    if (!mediaContent.length) {
      return;
    }
    const contentUploadsInProgress = Object.values(contentUploadList).filter(content => {
      const isUploadInProgress =
        content.contentState === ContentStatesEnum.Uploading ||
        content.contentState === ContentStatesEnum.Processing ||
        content.contentState === ContentStatesEnum.Generating;
      return isUploadInProgress && !mediaContent.some(mc => mc.id === content.contentId);
    });

    if (!contentUploadsInProgress.length) {
      return;
    }

    const contentUpload = contentUploadsInProgress.map(content => {
      return {
        id: content.contentId,
        project_id: content.projectId,
        media_source_type: 'UPLOAD',
        batch_status: 'UPLOADING',
        organization: currentUser.getSnapshot()?.organization as string,
        av_type: content.av_type,
        recording_date: moment().format('YYYY-MM-DD'),
        title: content.title,
        broadcast_type: 'SIMULIVE',
        reason: null,
        allow_reset_speakers: false,
        project: {},
        end_time: moment().format()
      } as any;
    });
    setMediaContent(prevState => [...contentUpload, ...prevState]);
  }, [contentUploadList, mediaContent]);

  function fetchMediaContentList({
    organization,
    limit,
    selectedEvents = [],
    searchTerm,
    idsArray
  }: {
    organization: string;
    limit: number;
    selectedEvents?: string[];
    searchTerm?: string | undefined;
    idsArray?: string[];
  }): Promise<void> {
    return assetsMediaContentList({
      queryParams: {
        organization: organization,
        limit,
        search: searchTerm,
        ...(idsArray && { ids: idsArray.join(',') }),
        ...(selectedEvents.length && { projects: selectedEvents.join(',') })
      }
    })
      .then(data => {
        if (idsArray?.length && data.results?.length) {
          setMediaContent(mc =>
            mc.map(m => (idsArray.includes(m.id) ? data.results?.find(d => idsArray.includes(d.id)) ?? m : m))
          );
        } else {
          setMediaContent(data?.count && data?.results ? data.results : []);
        }
      })
      .catch(() => {
        setShowErrorState(true);
      });
  }

  return {
    fetchMediaContentList,
    mediaContent,
    showErrorState,
    setMediaContent
  };
}
