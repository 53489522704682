import { useState, useEffect, memo, useMemo, useCallback } from 'react';
import { IconCategory2, IconCheck } from '@tabler/icons-react';
import { GetRelevantSourcesResponse } from '@goldcast/api/content';
import SessionThumbnail from '../../uiComponents/SessionThumbnail';
import SourceListLoader from './SourceListLoader';
import { classnames } from '@/libs/utils';
import ContentDialog from '@/Pages/Sessions/uiComponents/ContentDialog/ContentDialog';
import Button from '@/components/atoms/Button/Button';
import IconButton from '@/components/atoms/Button/IconButton';
import Badge from '@/components/atoms/Badge/Badge';

function SourceSelectionDialog({
  isOpen,
  isLoading,
  isSearching,
  closeDialog,
  sourceList,
  onSourceChanged
}: {
  isOpen: boolean;
  isLoading?: boolean;
  isSearching?: boolean;
  closeDialog: () => void;
  sourceList: GetRelevantSourcesResponse[];
  onSourceChanged: (selectedSource: string[]) => void;
}) {
  const [selectedItems, setSelectedItems] = useState<
    Record<string, { selected: boolean; mediaContent: GetRelevantSourcesResponse }>
  >({});
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [filterQuery, setFilterQuery] = useState('');

  useEffect(() => {
    setFilterQuery('');
    setIsAllSelected(true);
    setSelectedItems(
      sourceList.reduce((acc, item) => {
        acc[item.id] = { selected: true, mediaContent: item };
        return acc;
      }, {})
    );
  }, [sourceList]);

  const selectedCount = useMemo(() => {
    return Object.keys(selectedItems).filter(key => selectedItems[key].selected).length;
  }, [selectedItems]);

  const filteredSourceList = useMemo(() => {
    if (!filterQuery) return sourceList;
    return sourceList.filter(content => content.title.toLowerCase().includes(filterQuery.toLowerCase()));
  }, [sourceList, filterQuery]);

  const toggleItemSelection = useCallback((id: string) => {
    setSelectedItems(prevState => {
      const newState = { ...prevState };
      newState[id].selected = !newState[id].selected;
      const allSelected = Object.values(newState).every(item => item.selected);
      setIsAllSelected(allSelected);
      return newState;
    });
  }, []);

  const toggleSelectAllItems = useCallback((selectAll: boolean) => {
    setIsAllSelected(selectAll);
    setSelectedItems(prevState => {
      const newState = { ...prevState };
      Object.keys(newState).forEach(key => {
        newState[key].selected = selectAll;
      });
      return newState;
    });
  }, []);

  const handleSearch = useCallback((search: string) => {
    setFilterQuery(search);
  }, []);

  const handleSearchSelectedRecordings = useCallback(() => {
    const selectedSource = Object.keys(selectedItems).filter(key => selectedItems[key].selected);
    onSourceChanged(selectedSource);
  }, [selectedItems]);

  const onDialogClose = useCallback(() => {
    closeDialog();
  }, []);

  return (
    <ContentDialog
      isOpen={isOpen}
      size="xlarge"
      hideCloseIcon={isSearching}
      setIsOpen={onDialogClose}
      disableBackdropClose={true}
    >
      {isLoading ? (
        <SourceListLoader />
      ) : (
        <>
          <div className="flex items-center px-6 pb-4 leading-tight">
            <div className="grow pt-0.5">
              <h1 className="text-2xl font-semibold">Refine Content Sources</h1>
              <h2 className="mt-1 max-w-xl leading-snug text-slate-600">
                Based on your search term, we found {sourceList.length} relevant recordings to derive content from.
                Would you like to narrow it down?
              </h2>
              <div className="ml-0 mt-6 w-full text-sm leading-tight text-slate-600">
                <div className="mb-4 flex h-8 items-center justify-between gap-5">
                  <div className="flex grow space-x-3">
                    <button
                      disabled={filteredSourceList.length === 0}
                      onClick={() => toggleSelectAllItems(!isAllSelected)}
                      className="flex items-center gap-2"
                    >
                      <div
                        data-testid="select-all-checkbox"
                        className={classnames(
                          'z-20 flex h-5 w-5 items-center justify-center rounded-sm border bg-white shadow-sm duration-150 ease-in-out',
                          isAllSelected ? 'border-slate-400 opacity-100' : 'border-slate-300 group-hover:opacity-100'
                        )}
                      >
                        {isAllSelected && <IconCheck stroke={2.5} size={15} />}
                      </div>
                      <div>Select all</div>
                    </button>
                    {!!selectedCount && (
                      <div>
                        <Badge variation="gray">
                          {`${selectedCount} recording${selectedCount === 1 ? '' : 's'} selected`}
                        </Badge>
                      </div>
                    )}
                  </div>
                  <input
                    data-testid="source-selection-search-input"
                    type="text"
                    placeholder="Search within results"
                    className="w-64 rounded-lg px-2.5 py-1.5 text-sm ring-1 ring-slate-300 focus:ring-black"
                    onChange={e => handleSearch(e.target.value)}
                  />
                </div>
                {filteredSourceList.length > 0 ? (
                  <div className="grid h-full w-full grid-cols-3 gap-6 pb-6">
                    {filteredSourceList.map(content => (
                      <div className="group relative" key={content.id}>
                        <button
                          onClick={() => toggleItemSelection(content.id)}
                          className="w-full"
                          data-testid="source-selected-clip"
                        >
                          <div className="absolute left-2 top-2">
                            <div
                              className={classnames(
                                selectedItems[content.id]?.selected
                                  ? 'border-slate-400 opacity-100'
                                  : 'border-slate-300 group-hover:opacity-100',
                                'z-20 flex h-5 w-5 items-center justify-center rounded-sm border bg-white opacity-0 shadow-sm duration-150 ease-in-out'
                              )}
                            >
                              {selectedItems[content.id]?.selected && <IconCheck stroke={2.5} size={15} />}
                            </div>
                          </div>
                          <div
                            className={classnames(
                              'flex aspect-[16/9] w-full shrink-0 items-center justify-center rounded-lg bg-slate-100 p-8 pb-4 ring-2 transition-all duration-150 ease-in-out focus:ring-2',
                              selectedItems[content.id]?.selected
                                ? 'ring-2 ring-black'
                                : 'ring-transparent group-hover:bg-slate-200'
                            )}
                          >
                            <SessionThumbnail iconSize={30} session={content} />
                          </div>
                          <div className="w-full text-left">
                            <div
                              data-testid="content-title-source"
                              className="mt-1.5 line-clamp-2 text-sm tracking-tight text-slate-900"
                            >
                              {content.title}
                            </div>
                          </div>
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex w-full items-center justify-center py-10">
                    <div className="flex flex-col items-center">
                      <IconCategory2 size={32} stroke={1.5} className="text-slate-400" />
                      <div className="mt-2 max-w-[20rem] text-center text-sm leading-tight text-slate-400">
                        No results found matching your criteria.
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="sticky bottom-0 flex w-full justify-end border-t border-slate-200 bg-white px-5 pb-3 pt-2.5">
            {isSearching ? (
              <IconButton
                icon="IconLoader2"
                content="Searching over selected recordings"
                variation="filled"
                disabled={true}
                iconClassName="animate-spin"
                trackingId="searching-selected-recordings"
              />
            ) : (
              <Button
                trackingId="search-select-source"
                variation="filled"
                onClick={handleSearchSelectedRecordings}
                disabled={!selectedCount || isSearching}
              >
                Generate from {selectedCount} recordings
              </Button>
            )}
          </div>
        </>
      )}
    </ContentDialog>
  );
}

export default memo(SourceSelectionDialog);
