import { createRef, useEffect, useState } from 'react';
import HlsPlayerV2 from '../ClipPlayer/HlsPlayerV2';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { updateSpeakerVideoMap } from '@/stores/sharedAPI/sharedAPI';

export default function CompositePlayerV2({
  secondaryPlayers,
  updateSecondaryPlayerTime,
  setSecondaryPlayers,
  allAvailableSpeakers,
  passiveLoadCompositePlayer,
  onLoadFailed
}: {
  secondaryPlayers: React.MutableRefObject<HTMLVideoElement | null>[];
  updateSecondaryPlayerTime: (player: React.RefObject<HTMLVideoElement>) => void;
  setSecondaryPlayers: React.Dispatch<React.SetStateAction<React.RefObject<HTMLVideoElement>[]>>;
  allAvailableSpeakers: SpeakerWithDetails[];
  passiveLoadCompositePlayer: boolean;
  onLoadFailed: () => void;
}) {
  const [readyToRender, setReadyToRender] = useState(false);
  const { clipId } = useClipsContext();

  useEffect(() => {
    if (allAvailableSpeakers.length === 0 || secondaryPlayers.length > 0) return;
    const players: React.RefObject<HTMLVideoElement>[] = [];
    allAvailableSpeakers.forEach(() => {
      const newRef = createRef<HTMLVideoElement>();
      players.push(newRef);
    });
    setSecondaryPlayers(players);
  }, [allAvailableSpeakers]);

  useEffect(() => {
    if (secondaryPlayers.length === allAvailableSpeakers.length && allAvailableSpeakers.length > 0) {
      setReadyToRender(true);
    }
  }, [secondaryPlayers]);

  if (!readyToRender) {
    return null;
  }

  return (
    passiveLoadCompositePlayer &&
    secondaryPlayers.map((player, i) => {
      const speaker = allAvailableSpeakers[i];
      function onMediaAttached() {
        updateSecondaryPlayerTime(player);
      }

      function onLoaded() {
        if (player.current) {
          updateSpeakerVideoMap(clipId, speaker.key, player.current!);
        }
      }

      return (
        <div className="absolute top-0 z-[-10] h-full w-full" key={i}>
          <HlsPlayerV2
            videoRef={player}
            url={speaker.video.url}
            id={speaker.key}
            videoProps={{
              className: 'h-2 w-2 object-cover object-center box-border overflow-hidden hidden'
            }}
            onError={onLoadFailed}
            onMediaAttached={onMediaAttached}
            onLoaded={onLoaded}
          />
        </div>
      );
    })
  );
}
