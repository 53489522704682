import React from 'react';
import { FontItem } from '../../SideBar/FontSelector/types';
import { CAPTIONS_PLACEMENT_OPTIONS } from './CaptionsMenu/constants';
import { CaptionStyle, CaptionsPlacement } from '@/domains/asset';

export function getCaptionStyles(
  animation: CaptionStyle | undefined,
  scale: number | undefined,
  fontItem?: FontItem,
  outlineColor: string = '#000'
) {
  let strokeWidth = (scale || 1) / 2;
  if (fontItem?.size === 'xs') {
    strokeWidth /= 2;
  }
  switch (animation) {
    case CaptionStyle.Outline:
      return {
        textShadow: `-${strokeWidth}px -${strokeWidth}px 0.7px ${outlineColor}, ${strokeWidth}px -${strokeWidth}px 0.7px ${outlineColor}, -${strokeWidth}px ${strokeWidth}px 0.7px ${outlineColor}, ${strokeWidth}px ${strokeWidth}px 0.7px ${outlineColor}, -${strokeWidth}px -${strokeWidth}px 0.7px ${outlineColor}, ${strokeWidth}px -${strokeWidth}px 0.7px ${outlineColor}, -${strokeWidth}px ${strokeWidth}px 0.7px ${outlineColor}, ${strokeWidth}px ${strokeWidth}px 0.7px ${outlineColor}`
      } as React.CSSProperties;
    default:
      return {};
  }
}

export function getNextCaptionsPlacement(current: CaptionsPlacement | undefined): CaptionsPlacement {
  const currentPlacement = CAPTIONS_PLACEMENT_OPTIONS.find(o => o.value === current);
  return currentPlacement?.next || CaptionsPlacement.Top;
}

export function getCaptionsPlacementMargin(
  playerHeight: number,
  captionsHeight: number,
  placement?: CaptionsPlacement
): number {
  switch (placement) {
    case CaptionsPlacement.Top:
      return playerHeight - captionsHeight;
    case CaptionsPlacement.Middle:
      return (playerHeight - captionsHeight) / 2;
    default:
      return 0;
  }
}

export function getCaptionsLineHeight(captionsFontSize: number): number {
  return (5 * captionsFontSize) / 2.8;
}
