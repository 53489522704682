import { useCallback, useEffect, useState, useSyncExternalStore } from 'react';
import { assetsSavedSearchPartialUpdate } from '@goldcast/api/content';
import { savedAISearch } from '@/stores/savedAISearch';
import TitlePopup from '@/components/atoms/TitlePopup';

export default function SavedSearchTitlePopup() {
  const savedAISearchStore = useSyncExternalStore(savedAISearch.subscribe, savedAISearch.getSnapshot);
  const initialTitle = savedAISearchStore!.title;

  const [title, setTitle] = useState(initialTitle);

  useEffect(() => {
    if (savedAISearchStore!.title !== title) {
      setTitle(savedAISearchStore!.title);
    }
  }, [savedAISearchStore!.title]);

  const handleChangeTitle = useCallback(e => {
    setTitle(e.target.value);
  }, []);

  const handleRemoteUpdate = useCallback(() => {
    const hasChanged = title !== initialTitle;

    if (!hasChanged) return;

    if (!title?.trim()) {
      setTitle(initialTitle);
    }

    assetsSavedSearchPartialUpdate({
      id: savedAISearchStore!.id,
      body: {
        ...savedAISearchStore,
        title
      }
    }).then(() => {
      savedAISearch.update(val => ({
        ...val,
        title
      }));
    });
  }, [initialTitle, savedAISearchStore, title]);

  return <TitlePopup title={title} onUpdate={handleRemoteUpdate} onTitleChange={handleChangeTitle} />;
}
