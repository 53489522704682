import { memo, useCallback, useMemo, useState } from 'react';
import { IconCheck, IconChevronDown } from '@tabler/icons-react';
import { Listbox } from '@headlessui/react';
import { classnames } from '@/libs/utils';

export interface MultiselectOption {
  value: string;
  label: string;
}

interface MultiselectProps {
  options: MultiselectOption[];
  allSelectedLabel: string;
  variant: 'multi' | 'single';
  onChange: (selectedOptions: MultiselectOption[]) => void;
}

const Multiselect = ({ options, allSelectedLabel, variant, onChange }: MultiselectProps) => {
  const allOption = useMemo(() => ({ value: 'all', label: allSelectedLabel }), [allSelectedLabel]);

  const [selectedOptions, setSelectedOptions] = useState<MultiselectOption[]>([allOption]);

  const optionsWithAll = useMemo(
    () => [{ value: 'all', label: allSelectedLabel }, ...options],
    [options, allSelectedLabel]
  );

  const title = useMemo(() => {
    if (selectedOptions.find(option => option.value === 'all')) {
      return allSelectedLabel;
    }

    if (selectedOptions.length === options.length) {
      return allSelectedLabel;
    }

    return selectedOptions.map(value => value.label).join(', ');
  }, [selectedOptions, options, allSelectedLabel]);

  const handleOptionClick = useCallback(
    (option: MultiselectOption) => {
      let newSelectedOptions: MultiselectOption[] = [];

      if (variant === 'single') {
        newSelectedOptions = selectedOptions.includes(option) ? [] : [option];
      } else if (option.value === allOption.value) {
        newSelectedOptions = [allOption];
      } else {
        newSelectedOptions = selectedOptions.filter(option => option.value !== allOption.value);
        if (newSelectedOptions.includes(option)) {
          newSelectedOptions = newSelectedOptions.filter(o => o.value !== option.value);
        } else {
          newSelectedOptions.push(option);
        }
        if (newSelectedOptions.length === 0 || newSelectedOptions.length === options.length) {
          newSelectedOptions = [allOption];
        }
      }

      setSelectedOptions(newSelectedOptions);
      onChange(newSelectedOptions);
    },
    [allOption, onChange, options.length, selectedOptions, variant]
  );

  return (
    <Listbox>
      <div className="relative w-56">
        <Listbox.Button
          title={title}
          className="flex h-10 w-56 items-center justify-between space-x-1 truncate rounded-md border px-3 hover:bg-slate-50"
        >
          <div className="flex w-full items-center space-x-3 truncate">
            <span className="truncate">{title}</span>
          </div>
          <IconChevronDown className="h-4 w-4 shrink-0" />
        </Listbox.Button>
        <Listbox.Options className="absolute z-10 mt-1 w-full rounded-md border border-gray-300 bg-white shadow-lg">
          {optionsWithAll.map(option => (
            <Listbox.Option
              key={option.value}
              value={option.value}
              data-testid={`multiselect-option-${option.value}`}
              className="flex w-full cursor-pointer items-center gap-2 p-2 text-sm hover:bg-gray-100"
              onClick={() => handleOptionClick(option)}
            >
              <div
                className={classnames({
                  'flex h-4 w-4 items-center justify-center rounded-sm border': true,
                  'border-slate-200': !selectedOptions.find(o => o.value === option.value),
                  'border-transparent bg-black text-white': selectedOptions.find(o => o.value === option.value)
                })}
              >
                {selectedOptions.find(o => o.value === option.value) && <IconCheck size={14} stroke={2.5} />}
              </div>
              {option.label}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  );
};

export default memo(Multiselect);
