import { Clip } from '@/domains/asset';
import { showErrorToast } from '@/libs/toast/toast';

export function handleClipLockedError(clip: Clip) {
  if (!clip.locked) return;

  const eventId = clip.content.project.id;
  const eventTitle = clip.content.project.title;

  showErrorToast(
    <p className="tw-text-xs">
      <span>{'Clip is published in '}</span>
      <a
        href={`/webinar/${eventId}/details/videos`}
        target="_blank"
        rel="noreferrer"
        className="!text-deep-orange-600 !underline"
      >
        {`${eventTitle}'s`}
      </a>
      <span>{' on-demand page and cannot be edited. Please un-publish to continue editing.'}</span>
    </p>
  );
}
