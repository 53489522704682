import { memo, useSyncExternalStore } from 'react';
import IconButton from '@/components/atoms/Button/IconButton';
import { toggleTimelineCollapse, userPreferencesStore } from '@/stores/userPreferencesStore';

function PlayerToggle() {
  const { isTimelineCollapsed } = useSyncExternalStore(
    userPreferencesStore.subscribe,
    userPreferencesStore.getSnapshot
  );

  return (
    <IconButton
      icon={isTimelineCollapsed ? 'IconArrowBarUp' : 'IconArrowBarDown'}
      title={isTimelineCollapsed ? 'Expand' : 'Collapse'}
      buttonClassName="w-9 h-9"
      variation="checkbox"
      trackingId="timeline-collapse-button"
      onClick={toggleTimelineCollapse}
    />
  );
}

export default memo(PlayerToggle);
