import { useState } from 'react';

export default function useElementScrolledHook() {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleOnScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;

    if (!isScrolled && target.scrollTop > 0) {
      setIsScrolled(true);
    } else if (isScrolled && target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };

  return { isScrolled, handleOnScroll };
}
