const DEFAULT_ERROR_MESSAGES = {
  403: 'User is not authorized to view this content',
  404: 'Page not found. If the issue persists, contact our support team and they will be happy to help.',
  DEFAULT: 'If the issue persists, contact our support team and they will be happy to help.'
};

const getErrorMessage = (status: number | string) => DEFAULT_ERROR_MESSAGES[status] || DEFAULT_ERROR_MESSAGES.DEFAULT;

/**
 *
 * @param code error code - This can be a status code or a custom error code
 * @param inlineError - This is true if the error is caught by error boundary and no manual error handling is done
 * @returns
 */
export const getErrorDisplayCodeAndMessage = (code: string, inlineError: boolean) => {
  if (code === '403') {
    return {
      errorDisplayCode: 403,
      errorMessage: getErrorMessage(403)
    };
  }

  if (code === '404' || !inlineError) {
    return {
      errorDisplayCode: 404,
      errorMessage: getErrorMessage(404)
    };
  }

  return {
    errorDisplayCode: 'Something Went Wrong',
    errorMessage: getErrorMessage('DEFAULT')
  };
};
