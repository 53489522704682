import { memo, useCallback, useMemo, useSyncExternalStore } from 'react';
import { MAGIC_LAYOUT_OPTIONS } from './constants';
import ClipCustomizerLayoutIconButton from './ClipCustomizerLayoutIconButton';
import { useActiveSpeakerMatchCondition } from '../useActiveSpeakerMatchCondition';
import { selectMagicLayout, updateClipWithId } from '@/stores/clip';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { LayoutType } from '@/domains/asset';
import { getClipProcessingState, getClipUnavailableState } from '@/Pages/Clip/MagicLayout/magicLayoutUtils';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import useDialog from '@/components/organisms/useDialog';
import ConfirmationDialog from '@/components/organisms/ConfirmationDialog';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';
import { core } from '@/stores/core';

function ClipCustomizerMagicLayoutOption({
  magicLayoutOption
}: {
  magicLayoutOption: Extract<LayoutType, 'SPEAKER' | 'GRID'>;
}) {
  const { clipId, clipData } = useClipsContext();
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);

  const clipLayout = useMemo(() => {
    return clipData.asset_metadata.layout;
  }, [clipData.asset_metadata.layout]);

  const [isUnavailable, unavailableReason] = useMemo(() => {
    return getClipUnavailableState(magicLayoutOption, clipData);
  }, [clipData, magicLayoutOption]);

  const isProcessing = useMemo(() => {
    return getClipProcessingState(magicLayoutOption, clipData);
  }, [clipData, magicLayoutOption]);

  const disabledReason = useMemo(() => {
    if (isUnavailable) return unavailableReason;
    if (isProcessing) return 'Processing';
  }, [isUnavailable, unavailableReason, isProcessing]);

  const { isSpeakerIdentificationMandatory } = useActiveSpeakerMatchCondition();

  const shouldOpenSpeakerIdentification = useMemo(() => {
    const isCustomUpload = coreStore.content?.media_source_type === 'UPLOAD';
    const isActiveSpeaker = magicLayoutOption === 'SPEAKER';

    const isFacialRecABIncrement = featureFlagStore.getSnapshot()[FeatureFlagKeys.Use_CL_Facial_Rec_Increment_AB];

    return (
      isCustomUpload &&
      isActiveSpeaker &&
      (isFacialRecABIncrement
        ? isSpeakerIdentificationMandatory
        : // TODO: @AshwinBhatkal When removing FF, remove isCustomUpload and isActiveSpeaker checks. It is present inside cotnainsUnidentifiedSpeakers
          isUnavailable && clipData.layout_status.SPEAKER.reason === 'MORE_THAN_ONE_SPEAKER_DETECTED')
    );
  }, [
    coreStore.content?.media_source_type,
    magicLayoutOption,
    isSpeakerIdentificationMandatory,
    isUnavailable,
    clipData.layout_status.SPEAKER.reason
  ]);

  const selectSpeakerLayout = useCallback(() => {
    selectMagicLayout(clipId, 'SPEAKER');
    updateClipWithId(clipId, {
      layout_status: {
        ...clipData.layout_status,
        SPEAKER: {
          status: 'DONE',
          reason: '',
          accurate: clipData.layout_status.SPEAKER.accurate
        }
      }
    });
  }, []);

  const openSpeakersIdentificationDialog = useCallback(() => {
    EventBus.dispatch(CustomEvents.OpenSpeakersIdentification, {
      postIdentifyCallback: selectSpeakerLayout,
      layout: 'SPEAKER',
      isInlineIdentification: true
    });
  }, [selectSpeakerLayout]);

  const {
    isOpen: isActiveSpeakerIdentificationRequiredDialogOpen,
    openDialog: openActiveSpeakerIdentificationRequiredDialog,
    closeDialog: closeActiveSpeakerIdentificationRequiredDialog
  } = useDialog();

  const updateMagicLayout = useCallback(() => {
    if (shouldOpenSpeakerIdentification) {
      openActiveSpeakerIdentificationRequiredDialog();
    } else {
      selectMagicLayout(clipId, clipLayout === magicLayoutOption ? 'DEFAULT' : magicLayoutOption);
    }
  }, [
    clipId,
    clipLayout,
    magicLayoutOption,
    shouldOpenSpeakerIdentification,
    openActiveSpeakerIdentificationRequiredDialog
  ]);

  const isDisabled = useMemo(() => {
    // TODO: @AshwinBhatkal When removing FF, incorporate upload check in getClipProcessingState
    return (
      (!(
        featureFlagStore.getSnapshot()[FeatureFlagKeys.Use_CL_Facial_Rec_Increment_AB] &&
        core.getSnapshot().content?.media_source_type === 'UPLOAD'
      ) &&
        isProcessing) ||
      (isUnavailable && !shouldOpenSpeakerIdentification)
    );
  }, [isProcessing, isUnavailable, shouldOpenSpeakerIdentification]);

  return (
    <>
      <ClipCustomizerLayoutIconButton
        optionDetails={MAGIC_LAYOUT_OPTIONS[magicLayoutOption]}
        trackingId={`customizer-magic-layout-${magicLayoutOption}`}
        onClick={updateMagicLayout}
        isDisabled={isDisabled}
        disabledReason={shouldOpenSpeakerIdentification ? '' : disabledReason}
        isActive={clipLayout === magicLayoutOption}
        tooltipPosition={magicLayoutOption === 'SPEAKER' ? 'right' : 'left'}
      />
      <ConfirmationDialog
        isOpen={isActiveSpeakerIdentificationRequiredDialogOpen}
        onClose={closeActiveSpeakerIdentificationRequiredDialog}
        onConfirm={openSpeakersIdentificationDialog}
        title="Identify speakers to use this template"
        content={'Match speakers to faces in the video to use the Active Speaker template.'}
        confirmLabel="Next"
      />
    </>
  );
}

export default memo(ClipCustomizerMagicLayoutOption);
