import React, { memo, useCallback } from 'react';
import './SpeakersTimelineZoom.css';
import IconButton from '@/components/atoms/Button/IconButton';

const ZOOM_STEP = 1;
const ZOOM_MIN_VALUE = 1;
const ZOOM_MAX_VALUE = 10;

function SpeakersTimelineZoom({ zoomValue, onChange }: { zoomValue: number; onChange: (value: number) => void }) {
  const onZoomChange = useCallback(e => onChange(+e.target.value), [onChange]);

  const onZoomIncrease = useCallback(() => {
    onChange(Math.min(ZOOM_MAX_VALUE, zoomValue + ZOOM_STEP));
  }, [onChange, zoomValue]);

  const onZoomDecrease = useCallback(() => {
    onChange(Math.max(ZOOM_MIN_VALUE, zoomValue - ZOOM_STEP));
  }, [onChange, zoomValue]);

  return (
    <div className="flex items-center justify-center gap-2">
      <IconButton
        icon="IconZoomOut"
        onClick={onZoomDecrease}
        iconClassName="cursor-pointer"
        buttonClassName="w-9 h-9 border-none"
        variation="checkbox"
        trackingId="timeline-zoom-out"
      />
      <input
        type="range"
        min={ZOOM_MIN_VALUE}
        max={ZOOM_MAX_VALUE}
        value={zoomValue}
        step={ZOOM_STEP}
        onChange={onZoomChange}
        className="h-1 w-24 cursor-pointer appearance-none rounded-xl bg-slate-300"
      />
      <IconButton
        icon="IconZoomIn"
        onClick={onZoomIncrease}
        iconClassName="cursor-pointer"
        buttonClassName="w-9 h-9 border-none"
        variation="checkbox"
        trackingId="timeline-zoom-in"
      />
    </div>
  );
}

export default memo(SpeakersTimelineZoom);
