import React, { useMemo } from 'react';
import AudiogramPreview from '../SideBar/AudiogramPreview';
import ClipInterimThumbnailCanvasPreview from './ClipInterimThumbnailCanvasPreview';
import { Clip } from '@/domains/asset';
import { ClipsContext } from '@/context/ClipsContext/ClipsContext';
import { SpeakerSegmentContext } from '@/context/SpeakerSegmentContext/SpeakerSegmentContext';
import { SpeakersAnalysis } from '@/stores/speakersAnalysis/speakersAnalysisTypes';
import { roundToNDecimalPlaces, timeStringToSeconds } from '@/libs/utils';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { Word } from '@/domains/transcript';
import { findAdjustedBoxBoundaries } from '@/stores/speakersAnalysis/speakersAnalysisUtils';

/**
 * Interim thumbnail for clips without a download config. Using the clip's asset metadata, this component
 * will render a preview of the clip. Once the clip's download config is available, this will be replaced
 * with the actual thumbnail.
 */
export default function ClipInterimThumbnail({
  clip,
  firstSpeaker,
  imageLoaded,
  clipThumbnailRef,
  captions,
  children
}: {
  clip: Clip;
  firstSpeaker?: SpeakerWithDetails;
  imageLoaded: boolean;
  clipThumbnailRef: React.RefObject<HTMLImageElement>;
  captions: Word[];
  children: React.ReactNode;
}) {
  const isUpload = useMemo(() => {
    return clip.content.media_source_type === 'UPLOAD';
  }, [clip.content.media_source_type]);

  const firstFrameSpeakerAnalysis: SpeakersAnalysis = useMemo(() => {
    if (!isUpload || !clip.first_frame_analysis) {
      return {
        face_ids: [],
        speaker_mapping: {},
        time_analysis: [],
        loading: false,
        times: [],
        visible_face_ids: [],
        accurate: false,
        adjustedBoxBoundaries: []
      };
    }

    const timeAnalysis = clip.first_frame_analysis.time_analysis || [];

    const adjustedBoxBoundaries = findAdjustedBoxBoundaries(timeAnalysis);

    return {
      face_ids: clip.first_frame_analysis.face_ids || [],
      speaker_mapping: clip.first_frame_analysis.speaker_mapping || {},
      time_analysis: timeAnalysis,
      loading: false,
      times: timeAnalysis?.map(t => roundToNDecimalPlaces(timeStringToSeconds(t.time) + clip.asset_metadata.start, 4)),
      visible_face_ids: clip.first_frame_analysis.face_ids?.slice(0, 4) || [],
      accurate: clip.first_frame_analysis.accurate || false,
      adjustedBoxBoundaries
    };
  }, [clip.first_frame_analysis, clip.asset_metadata.start, isUpload]);

  return (
    <div className="absolute top-0 h-full w-full overflow-hidden rounded-md bg-cover bg-center">
      <div className="relative grid h-full items-start" data-testid="composite-player">
        {children}
        {firstSpeaker && clip.asset_metadata.layout === 'AUDIOGRAM' && (
          <ClipsContext.Provider
            value={
              {
                clipId: clip.id,
                clipData: clip,
                playerStore: {}
              } as any
            }
          >
            <SpeakerSegmentContext.Provider
              value={{
                captions,
                segmentSpeaker: { [firstSpeaker.key]: firstSpeaker },
                segmentWords: {},
                srtCaptions: { 0: captions },
                startSegment: 0
              }}
            >
              <AudiogramPreview clipTemplate={clip.asset_metadata} currentSpeaker={firstSpeaker} />
            </SpeakerSegmentContext.Provider>
          </ClipsContext.Provider>
        )}
        {imageLoaded &&
          !!clipThumbnailRef.current &&
          firstSpeaker &&
          ['SPEAKER', 'GRID', 'DEFAULT'].includes(clip.asset_metadata.layout) && (
            <ClipsContext.Provider
              value={
                {
                  clipId: clip.id,
                  clipData: clip,
                  speakersAnalysis: firstFrameSpeakerAnalysis,
                  playerStore: {}
                } as any
              }
            >
              <SpeakerSegmentContext.Provider
                value={{
                  captions,
                  segmentSpeaker: { [firstSpeaker.key]: firstSpeaker },
                  segmentWords: {},
                  srtCaptions: { 0: captions },
                  startSegment: 0
                }}
              >
                <ClipInterimThumbnailCanvasPreview
                  clip={clip}
                  clipThumbnailRef={clipThumbnailRef}
                  firstSpeaker={firstSpeaker}
                />
              </SpeakerSegmentContext.Provider>
            </ClipsContext.Provider>
          )}
      </div>
    </div>
  );
}
