import { useMemo, useSyncExternalStore } from 'react';
import FaceToggler from '@/Pages/Clip/FaceSpeakerToggler/FaceToggler';
import SpeakerToggler from '@/Pages/Clip/FaceSpeakerToggler/SpeakerToggler';
import { core } from '@/stores/core';

export default function FaceSpeakerToggler() {
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);

  const isUpload = useMemo(() => {
    return coreStore.content?.media_source_type === 'UPLOAD';
  }, [coreStore.content?.media_source_type]);

  return (
    <div className="mr-auto flex w-full flex-col">
      {isUpload ? <FaceToggler isInline={true} /> : <SpeakerToggler isInline={true} />}
    </div>
  );
}
