export enum SidebarContext {
  Main = 'Main',
  Default = 'Default',
  Audiogram = 'Audiogram',
  ActiveSpeaker = 'ActiveSpeaker',
  SpeakerGrid = 'SpeakerGrid',
  Transcript = 'Transcript',
  IntroOutro = 'IntroOutro',
  Templates = 'Templates',
  EditCaptions = 'EditCaptions',
  EditDuration = 'EditDuration'
}

export interface SidebarStore {
  context: SidebarContext;
}

export const titles: Record<string, string> = {
  [SidebarContext.ActiveSpeaker]: 'Active Speaker',
  [SidebarContext.Default]: 'Default',
  [SidebarContext.SpeakerGrid]: 'Speaker Grid',
  [SidebarContext.Audiogram]: 'Audiogram',
  [SidebarContext.Transcript]: 'Transcript',
  [SidebarContext.IntroOutro]: 'Intro / Outro',
  [SidebarContext.Templates]: 'Templates',
  [SidebarContext.EditCaptions]: 'Edit Captions',
  [SidebarContext.EditDuration]: 'Edit Duration'
};

type PossibleExcludedFields = 'bg-color' | 'speaker-labels';

const excludedFields = {
  [SidebarContext.ActiveSpeaker]: [],
  [SidebarContext.SpeakerGrid]: [],
  [SidebarContext.Audiogram]: ['speaker-labels'],
  [SidebarContext.Default]: ['speaker-labels']
};

export function isFieldVisible(sideBarContext: SidebarContext, field: PossibleExcludedFields) {
  return !excludedFields[sideBarContext]?.includes(field);
}
