import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App/App';
import './index.css';
import { AppContextProvider } from './context/AppContext/AppContext';
import { PusherContextProvider } from './context/PusherContext/PusherContext';

const renderApp = (rootId: string, props = { logger: {}, getters: {} }) => {
  const root = createRoot(document.getElementById(rootId));

  root.render(
    <AppContextProvider {...props}>
      <PusherContextProvider
        logger={props.logger}
        organizationId={props.getters?.['org/getCurrentOrgId']}
        userId={props.getters?.['user/getCurrentUserId']}
      >
        <App />
      </PusherContextProvider>
    </AppContextProvider>
  );

  return () => {
    root.unmount();
  };
};

export default renderApp;
