import { IconClockHour3 } from '@tabler/icons-react';
import { useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { TIME_INPUT_MASK, convertSecondsToTimeString, convertTimeStringToSeconds } from './utils';

export default function TimeInput({
  initialValue,
  validatorFn,
  title,
  onTimeChange
}: {
  initialValue: number;
  validatorFn: (val: number) => boolean;
  title: string;
  onTimeChange: (value: number) => void;
}) {
  const [value, setValue] = useState<string>(convertSecondsToTimeString(initialValue));
  const [isEditInput, setIsEditInput] = useState(false);
  const [error, setError] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  function onInputChange(e) {
    const inputValue = e.target.value;
    setValue(inputValue);
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      setValue(event.target.value);
      ref.current?.blur();
    }
  }

  function saveOnBlur() {
    setIsEditInput(false);
    const numberValue = convertTimeStringToSeconds(value);

    if (isNaN(numberValue) || validatorFn(numberValue)) {
      setError(true);
      ref.current?.focus();
      setValue(convertSecondsToTimeString(initialValue));
      return;
    }

    setError(false);
    onTimeChange(numberValue);
  }

  return (
    <div className="flex items-center space-x-1.5 text-xs">
      <IconClockHour3 className="h-4 w-4 text-slate-500" />
      <span>{title}</span>
      <InputMask
        mask={TIME_INPUT_MASK}
        maskChar={null}
        value={value}
        formatChars={{
          '9': '[0-9]',
          '*': '[0-9 ]',
          '6': '[0-6]'
        }}
        onChange={onInputChange}
        onBlur={saveOnBlur}
        onKeyDown={handleKeyDown}
      >
        {inputProps => (
          <input
            {...inputProps}
            className={`min-content w-full truncate rounded border-solid text-sm text-black focus:border focus:ring-0 ${
              error ? 'focus:border-red-500' : 'focus:border-deep-orange-600'
            } p-1.5 ${isEditInput ? '' : 'border-transparent hover:bg-slate-100'}`}
            type="text"
            ref={ref}
          />
        )}
      </InputMask>
    </div>
  );
}
