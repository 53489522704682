import {
  CLIP_MAX_DURATION_ERROR,
  CLIP_MIN_LENGTH,
  CLIP_MAX_DURATION,
  CLIP_MIN_DURATION,
  CLIP_MIN_DURATION_ERROR
} from '../constants';
import { copyTextToClipboard } from '@/Pages/PostsListPage/utils';
import { Word } from '@/domains/transcript';

export function getTrimPosition(
  trimming: boolean,
  queryKey: 'data-word-start-time' | 'data-word-end-time',
  word_time: number,
  container: HTMLDivElement | null,
  considerWidth?: boolean
) {
  let positions;
  if (trimming) {
    const rect = document.querySelector(`[${queryKey}="${word_time}"]`)?.getBoundingClientRect();
    if (rect) {
      positions = {
        top: rect.top - container!.getBoundingClientRect().top,
        left: (considerWidth ? rect.width : 0) + rect.left - container!.getBoundingClientRect().left
      };
    }
  }

  return positions;
}

export function setupCopyListeners(text: string, logger) {
  const handle = (e: KeyboardEvent) => {
    if (!text) return;
    if (e.key !== 'c' || !(e.ctrlKey || e.metaKey)) return;
    copyTextToClipboard(text, logger);
  };
  document.addEventListener('keydown', handle);

  return () => document.removeEventListener('keydown', handle);
}

export function getTrimmingError(
  transcript: Word[],
  highlightStartTime: number,
  highlightEndTime: number
): string | null {
  const trimmingWords = transcript.filter(w => w.start_time >= highlightStartTime && w.end_time <= highlightEndTime);
  if (trimmingWords && trimmingWords.length < CLIP_MIN_LENGTH) {
    return `Please select at least ${CLIP_MIN_LENGTH} words.`;
  }

  const duration = highlightEndTime - highlightStartTime;

  if (duration < CLIP_MIN_DURATION) {
    return CLIP_MIN_DURATION_ERROR;
  } else if (duration > CLIP_MAX_DURATION) {
    return CLIP_MAX_DURATION_ERROR;
  }

  return null;
}
