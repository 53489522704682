import { COMPOSITION_DIMENSIONS, FPS_24, VIDEO_QUALITY } from '../constants';
import { getFontFamilyStyle } from './font';
import { getElementsTimeline } from './timeline';
import { RemotionConfig, TimelineElement, TrackType } from '../types';
import { currentClip } from '@/stores/clip';
import { sharedAPI } from '@/stores/sharedAPI/sharedAPI';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';

export function getRemotionConfig(clipId: string): RemotionConfig {
  const clip = currentClip.getSnapshot()[clipId];

  const videoQuality = VIDEO_QUALITY.FULL_HD;
  const height = COMPOSITION_DIMENSIONS[clip.asset_metadata.size][videoQuality].HEIGHT;
  const width = COMPOSITION_DIMENSIONS[clip.asset_metadata.size][videoQuality].WIDTH;

  const fps = FPS_24;
  const durationInFrames = Math.ceil((clip.asset_metadata.config?.duration || 0) * fps);

  const fontLocation = clip.asset_metadata.font_location;
  const fontName = getFontFamilyStyle(clipId, fontLocation);

  const elements: TimelineElement[] = getElementsTimeline(clip);

  const sharedAPIStoreForClip = sharedAPI.getSnapshot()[clipId];
  const introVideoSource = clip.asset_metadata.intro;
  const drift = Math.ceil((introVideoSource ? sharedAPIStoreForClip.introPlayer?.duration || 0 : 0) * FPS_24);

  const useRemotionCaptions = featureFlagStore.getSnapshot()[FeatureFlagKeys.Use_CL_Remotion_Captions];

  return {
    height,
    width,
    fps,
    durationInFrames,
    version: useRemotionCaptions ? '0.0.2' : '0.0.1',
    tracks: useRemotionCaptions
      ? [
          {
            name: 'Captions',
            id: 'captions',
            type: TrackType.CAPTIONS,
            isTrackVisible: true,
            elements: []
          },
          {
            name: 'Speakers',
            id: 'speakers',
            type: TrackType.SPEAKER_LABELS,
            isTrackVisible: true,
            elements
          }
        ]
      : [],
    elements,
    drift,
    font: {
      location: fontLocation,
      name: fontName
    }
  };
}
