import { CSSProperties } from 'react';

type CompositionOverrideProps = {
  durationInFrames?: number;
  fps?: number;
  height?: number;
  width?: number;
};

export type RemotionConfig = CompositionOverrideProps & {
  version: string;
  tracks: Track[];
  elements: TimelineElement[];
  drift: number;
  font?: {
    location?: string;
    name?: string;
  };
};

export enum TrackType {
  SPEAKER_LABELS = 'SPEAKER_LABELS',
  CAPTIONS = 'CAPTIONS'
}

interface Track {
  name: string;
  id: string;
  type: TrackType;
  isTrackVisible: boolean;
  elements: TimelineElement[];
}

export enum TimelineElementType {
  TEXT = 'TEXT',
  CONTAINER = 'CONTAINER'
}

export interface BaseTimelineElement {
  fromFrame: number;
  toFrame: number;
  style?: CSSProperties;
  type: TimelineElementType;
}

export interface TextTimelineElement extends BaseTimelineElement {
  type: TimelineElementType.TEXT;
  content: string;
}

export interface ContainerTimelineElement extends BaseTimelineElement {
  type: TimelineElementType.CONTAINER;
  elements?: TimelineElement[];
}

export type TimelineElement = TextTimelineElement | ContainerTimelineElement;
