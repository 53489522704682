import React from 'react';
import { Link } from 'react-router-dom';
import { IconChevronRight } from '@tabler/icons-react';
import type { Breadcrumb } from './types';
import EditableBreadcrumb from './EditableBreadcrumb';

interface BreadcrumbsProps {
  links: Breadcrumb[];
  onEditInputBlur: ((title: string) => Promise<void>) | undefined;
}

export default function Breadcrumbs({ links, onEditInputBlur }: BreadcrumbsProps): JSX.Element {
  return (
    <div className="flex items-center">
      {links.map(({ url, title, isEdit }, index) => {
        const isLastIndex = index === links.length - 1;

        return !isEdit ? (
          <div
            className={`flex items-center text-sm ${isLastIndex ? 'text-black' : 'text-slate-400'}`}
            title={title}
            key={url}
          >
            <Link
              to={url}
              className={`rounded px-1.5 py-1 ${isLastIndex ? 'max-w-[40rem]' : 'max-w-[8rem]'} block truncate ${
                isLastIndex && !isEdit ? '' : 'hover:bg-slate-100'
              }`}
            >
              {title}
            </Link>
            {!isLastIndex && (
              <div>
                <IconChevronRight className="h-4 w-4" />
              </div>
            )}
          </div>
        ) : (
          <EditableBreadcrumb title={title} onEditInputBlur={onEditInputBlur} key={url} />
        );
      })}
    </div>
  );
}
