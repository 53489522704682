import { getEnvConfig } from '@/constants';

const DEFAULT_FONT_REGULAR = 'Inter-Regular.ttf';
const DEFAULT_FONT_MEDIUM = 'Inter-Medium.ttf';

export function getDefaultFontProperties(isRegular: boolean = true): {
  font: string;
  font_location: string;
} {
  const font = isRegular ? DEFAULT_FONT_REGULAR : DEFAULT_FONT_MEDIUM;
  return {
    font,
    font_location: `${getEnvConfig('CLOUDFRONT_STATIC_ASSET_URL')}content-lab/fonts/${font}`
  };
}
