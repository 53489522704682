import { IconLoader2 } from '@tabler/icons-react';
import { Id, toast } from 'react-toastify';
import * as filestack from 'filestack-js';
import { brandKitVerifyFontSupportCreate } from '@goldcast/api/content';
import {
  BRAND_KIT_MAX_FILE_SIZE,
  BRAND_KIT_PATH,
  FONT_VALIDATION_ERROR_OTF,
  FONT_VALIDATION_ERROR_TTF
} from '../constants';
import ProgressToast from '@/Pages/Sessions/NewContentModal/ProgressToast';
import { getFileExtension, getFileSize, getFileSizeInMb } from '@/libs/file';
import { currentUser } from '@/stores/user';
import { getEnvConfig } from '@/constants';
import { PROGRESS_TOAST_PARAMS } from '@/libs/toast/constants';

export function getUploadConfig(
  uploadToastId: React.MutableRefObject<Id | undefined>,
  totalSize: number
): filestack.UploadOptions {
  const filesSize = getFileSize(totalSize);
  return {
    retry: 3,
    timeout: 60000,
    onProgress: p => {
      if (uploadToastId.current) {
        toast.update(uploadToastId.current, {
          render: getUploadToastContent(p.totalPercent, p.totalBytes, filesSize)
        });
      } else {
        uploadToastId.current = toast.info(getUploadToastContent(0, 0, filesSize), {
          ...PROGRESS_TOAST_PARAMS,
          icon: (
            <IconLoader2 className="tw-animate-spin" style={{ animationDuration: '2000ms' }} size={17} stroke={1.5} />
          )
        });
      }
    }
  };
}

export function getUploadToastContent(progress: number, bytes: number, filesSize: string): JSX.Element {
  return <ProgressToast progressPercentage={progress} progressBytes={bytes} totalSize={filesSize} />;
}

export function getFilesForUpload(key: string, files: File[]): File[] {
  return files
    .filter(file => getFileSizeInMb(file.size) < BRAND_KIT_MAX_FILE_SIZE)
    .map(file => {
      // We are using timestamp to prevent overriding of brand kit files by having same name files
      const timestamp = new Date().getTime();
      const fileName = filestack.sanitizeName(`${key}/${timestamp}/${file.name}`);
      var blob = file.slice(0, file.size, file.type);
      return new File([blob], fileName, { type: file.type });
    });
}

export function getBrandKitUrl(fileName: string, clipId?: string): string {
  const baseFilePath = clipId
    ? `${currentUser.getSnapshot()?.organization}/clips/${clipId}`
    : `${currentUser.getSnapshot()?.organization}`;
  const encodedFileName = encodeBrandKitFileName(fileName);
  return `${getEnvConfig('CLOUDFRONT_STATIC_ASSET_URL')}${BRAND_KIT_PATH}/${baseFilePath}/${encodedFileName}`;
}

export function loadBrandKitFonts(fontsMap: Record<string, string>) {
  Object.keys(fontsMap).forEach(fontUrl => {
    const fontFace = new FontFace(fontsMap[fontUrl], `url(${fontUrl})`);
    // Load the font dynamically
    fontFace
      .load()
      .then(loadedFont => {
        document.fonts.add(loadedFont);
      })
      .catch(err => {
        console.error(`Error loading font ${fontUrl}`, err);
      });
  });
}

export function verifyFontFile(fontFileName: string): Promise<any> {
  return brandKitVerifyFontSupportCreate({
    body: { font_location: getBrandKitUrl(fontFileName) }
  })
    .then(res => {
      if (!res?.font_supported) {
        const errorMessage =
          getFileExtension(fontFileName) === '.ttf' ? FONT_VALIDATION_ERROR_TTF : FONT_VALIDATION_ERROR_OTF;
        return Promise.reject({
          message: errorMessage
        });
      }
      return Promise.resolve();
    })
    .catch(err => Promise.reject(err?.message));
}

function encodeBrandKitFileName(fileName: string): string {
  return encodeURI(fileName).replace('(', '%28').replace(')', '%29');
}
