import { memo } from 'react';
import { MAX_SAMPLE_COPY_LENGTH } from '../constants';

const SampleCopySection = ({
  sampleCopy,
  onSampleCopyChange
}: {
  sampleCopy: string;
  onSampleCopyChange: (value: string) => void;
}) => (
  <div className="w-full">
    <div className="flex w-full flex-col items-center justify-center pt-3">
      <textarea
        className="h-56 w-full resize-none rounded-lg border border-slate-300 p-3 text-sm focus:border-black focus:outline-none focus:ring-black"
        placeholder="Paste your sample copy here"
        value={sampleCopy}
        onChange={e => onSampleCopyChange(e.target.value.trimStart())}
      />
      <div className="mt-2 flex w-full justify-between text-xs tabular-nums text-slate-500">
        {sampleCopy.length}/{MAX_SAMPLE_COPY_LENGTH.toLocaleString()} characters
      </div>
    </div>
  </div>
);

export default memo(SampleCopySection);
