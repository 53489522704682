import { AvatarSize } from './AvatarTypes';

export const AVATAR_SIZE_CLASSES: Record<AvatarSize, string> = {
  xs: 'w-4 h-4 text-[7px]',
  sm: 'w-6 h-6 text-2xs',
  md: 'w-8 h-8 text-xs',
  lg: 'w-10 h-10 text-sm',
  xl: 'w-12 h-12 text-sm'
};

export function getSize(size: AvatarSize): string {
  return AVATAR_SIZE_CLASSES[size];
}

export function getInitials(name: string) {
  return name
    .trim()
    .split(' ')
    .slice(0, 2)
    .map(a => a[0])
    .filter(Boolean)
    .join('');
}
