import React, { createContext, useContext, useEffect, useState } from 'react';
import { getSegmentSpeaker, getSegmentWordsAndStartSegment } from './utils';
import { useTranscriptContext } from '../TranscriptContext/TranscriptContext';
import { SpeakerSegmentContextType } from './SpeakerSegmentContextTypes';
import { useClipsContext } from '../ClipsContext/ClipsContext';
import { updateSharedAPIWithId } from '@/stores/sharedAPI/sharedAPI';

export const SpeakerSegmentContext = createContext<SpeakerSegmentContextType | undefined>(undefined);

export function SpeakerSegmentContextProvider({ children }) {
  const transcriptStore = useTranscriptContext();
  const [speakerSegmentContextData, setSpeakerSegmentContextData] = useState<SpeakerSegmentContextType>();
  const { clipId, clipData } = useClipsContext();

  useEffect(() => {
    if (transcriptStore && clipData?.asset_metadata?.captions) {
      const captions = clipData?.asset_metadata?.captions;
      //should calculate by backend
      const { startSegment, segmentWords } = getSegmentWordsAndStartSegment(captions);
      const segmentSpeaker = getSegmentSpeaker(segmentWords, transcriptStore.speakersWithDetails);

      const srtCaptions = captions.reduce((acc, caption) => {
        if (!caption.hidden) {
          acc[caption.srt_index] = [...(acc[caption.srt_index] || []), caption];
        }
        return acc;
      }, {});

      setSpeakerSegmentContextData({
        segmentWords,
        startSegment,
        segmentSpeaker,
        captions,
        srtCaptions
      });
    }
  }, [transcriptStore, clipData.asset_metadata?.captions]);

  useEffect(() => {
    updateSharedAPIWithId(clipId, { speakerSegmentStore: speakerSegmentContextData });
  }, [speakerSegmentContextData]);

  return <SpeakerSegmentContext.Provider value={speakerSegmentContextData}>{children}</SpeakerSegmentContext.Provider>;
}

export function useSpeakerSegmentContext() {
  return useContext(SpeakerSegmentContext);
}
