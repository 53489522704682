import { useEffect, useRef, useState } from 'react';
import { useOutroRefReturn } from './ClipPlayerSectionsTypes';
import { sharedAPI, updateClipConfig, updateSharedAPIWithId } from '@/stores/sharedAPI/sharedAPI';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function useOutroRef(setCurrentSectionAndPlay, clipMetadata): useOutroRefReturn {
  const { clipId } = useClipsContext();
  const outroRef = useRef<HTMLVideoElement>(null);
  const [outroCurrentTime, setOutroCurrentTime] = useState(0);
  const [outroDuration, setOutroDuration] = useState(0);
  const [isOutroPaused, setIsOutroPaused] = useState(true);

  const playOutroSection = () => {
    outroRef.current?.play();
    setIsOutroPaused(false);
  };

  const pauseOutroSection = () => {
    outroRef.current?.pause();
    setIsOutroPaused(true);
  };

  const onOutroTimeUpdate = () => {
    setOutroCurrentTime(outroRef.current!.currentTime);
  };

  const onOutroEnd = () => {
    setCurrentSectionAndPlay(clipMetadata.intro ? 'intro' : 'main', false);
    setIsOutroPaused(true);
  };

  const onLoadedOutroData = () => {
    setOutroDuration(outroRef.current!.duration);
    setOutroCurrentTime(outroRef.current!.currentTime);
    updateSharedAPIWithId(clipId, { outroPlayer: outroRef.current });
    if (sharedAPI.getSnapshot()[clipId].mainPlayerRef) {
      updateClipConfig(clipId);
    }
  };

  useEffect(() => {
    if (!outroRef.current) setOutroDuration(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outroRef.current]);

  return {
    outroRef,
    outroCurrentTime,
    outroDuration,
    isOutroPaused,
    playOutroSection,
    pauseOutroSection,
    onOutroTimeUpdate,
    onOutroEnd,
    onLoadedOutroData
  };
}
