import { useState, useMemo, useEffect, memo, useCallback, useSyncExternalStore, useRef } from 'react';
import ToneRuleList from './ToneRuleList';
import { arraysEqual } from './utils';
import { ToneRuleCategory, ToneRuleInputsState } from './types';
import { VoiceProfileSteps } from './constants';
import ContentDialog from '@/Pages/Sessions/uiComponents/ContentDialog/ContentDialog';
import Button from '@/components/atoms/Button/Button';
import IconButton from '@/components/atoms/Button/IconButton';
import useVoiceProfile from '@/hooks/useVoiceProfile';
import { showErrorToast } from '@/libs/toast/toast';
import {
  updateVoiceProfile,
  createVoiceProfile,
  updateActiveStep,
  resetSelectedVoiceProfile,
  applyVoice,
  getDefaultVoiceOutput
} from '@/stores/voiceProfile';
import { currentUser } from '@/stores/user';
import { useAppContext } from '@/context/AppContext/AppContext';

const TuneVoiceProfile = ({ isOpen, closeDialog }: { isOpen: boolean; closeDialog: () => void }): JSX.Element => {
  const [inputs, setInputs] = useState<ToneRuleInputsState>({
    traits: [],
    writingRules: []
  });
  const [voiceOutput, setVoiceOutput] = useState<string>('');
  const [isStreamingVoice, setIsStreamingVoice] = useState<boolean>(false);
  const [isCreatingVoiceProfile, setIsCreatingVoiceProfile] = useState<boolean>(false);

  const [initialInputValues, setInitialInputValues] = useState<ToneRuleInputsState>({
    traits: [],
    writingRules: []
  });
  const abortController = useRef<AbortController | null>(null);

  const user = useSyncExternalStore(currentUser.subscribe, currentUser.getSnapshot);
  const { logger } = useAppContext();

  const { selectedVoiceProfile } = useVoiceProfile();

  useEffect(() => {
    if (!selectedVoiceProfile?.voice_output) {
      getDefaultVoiceOutput();
    }
  }, []);

  useEffect(() => {
    const inputs = {
      traits: selectedVoiceProfile?.tones || [],
      writingRules: selectedVoiceProfile?.custom_rules || []
    };
    setInputs(inputs);
    setInitialInputValues(inputs);
    setVoiceOutput(selectedVoiceProfile?.voice_output || '');
  }, [selectedVoiceProfile]);

  const editedInputs = useMemo(() => {
    return Object.entries(initialInputValues)
      .filter(([key, value]) => !arraysEqual(inputs[key], value))
      .map(([key]) => ({ key, value: inputs[key] }));
  }, [inputs, initialInputValues]);

  const onUpdateItem = useCallback((index: number, newValue: string, category: ToneRuleCategory) => {
    setInputs(prevInputs => ({
      ...prevInputs,
      [category]: prevInputs[category].map((item, i) => (i === index ? newValue : item))
    }));
  }, []);

  const onAddItem = useCallback((newItem: string, category: ToneRuleCategory) => {
    setInputs(prevInputs => ({
      ...prevInputs,
      [category]: [newItem, ...prevInputs[category]]
    }));
  }, []);

  const onRemoveItem = useCallback((index: number, category: ToneRuleCategory) => {
    setInputs(prevInputs => ({
      ...prevInputs,
      [category]: prevInputs[category].filter((_, i) => i !== index)
    }));
  }, []);

  const handleVoiceOutputStream = useCallback((chunk: BufferSource | undefined) => {
    const decoder = new TextDecoder();
    if (!chunk) {
      setIsStreamingVoice(false);
      return;
    }
    const decodedChunk = decoder.decode(chunk);
    setVoiceOutput(prevVoiceOutput => prevVoiceOutput + decodedChunk);
  }, []);

  const handleApplyVoice = useCallback(() => {
    if (inputs.traits.length === 0) {
      showErrorToast('Please add at least one trait');
      return;
    }
    abortController.current = new AbortController();
    setVoiceOutput('');
    setIsStreamingVoice(true);
    applyVoice(
      {
        tone_of_voice: inputs.traits,
        custom_rules: inputs.writingRules,
        text: voiceOutput
      },
      abortController!.current.signal,
      (voiceOutputChunk: BufferSource | undefined) => {
        handleVoiceOutputStream(voiceOutputChunk);
      }
    );
  }, [inputs, voiceOutput]);

  const onSuccess = useCallback(() => {
    setIsCreatingVoiceProfile(false);
    updateActiveStep(VoiceProfileSteps.SaveVoiceProfile);
  }, []);

  const onError = useCallback(
    (action, err) => {
      setIsCreatingVoiceProfile(false);
      const message = `Failed to ${action} voice profile`;
      showErrorToast(message);
      logger.error(message, err);
    },
    [logger]
  );

  const handleNext = useCallback(() => {
    if (inputs.traits.length === 0) {
      showErrorToast('Please add at least one trait');
      return;
    }

    if (editedInputs.length === 0) {
      updateActiveStep(VoiceProfileSteps.SaveVoiceProfile);
      return;
    }

    setIsCreatingVoiceProfile(true);

    const payload = {
      tones: inputs.traits,
      custom_rules: inputs.writingRules,
      voice_output: voiceOutput
    };

    if (selectedVoiceProfile?.id) {
      updateVoiceProfile({ id: selectedVoiceProfile.id, payload })
        .then(onSuccess)
        .catch(err => onError('update', err));
    } else {
      createVoiceProfile({ ...payload, organization: user!.organization, source: 'MANUAL' })
        .then(onSuccess)
        .catch(err => onError('create', err));
    }
  }, [inputs, editedInputs, selectedVoiceProfile, onSuccess, onError]);

  const handleDialogClose = useCallback(() => {
    resetSelectedVoiceProfile();
    closeDialog();
  }, [closeDialog]);

  return (
    <ContentDialog
      isOpen={isOpen}
      size="xlarge"
      setIsOpen={handleDialogClose}
      disableBackdropClose={true}
      panelClassNames="!max-w-5xl !overflow-hidden !max-h-[90vh]"
    >
      <>
        <div className="absolute top-0 z-50 flex items-center justify-between pl-5 pt-5">
          <div className="text-2xl font-semibold">Tune your Voice Profile</div>
        </div>
        <div className="mt-7 max-h-[65vh] overflow-y-auto px-5">
          <div className="flex space-x-8">
            <div className="w-[51%] shrink-0">
              <div className="relative">
                <h3 className="sticky z-10 bg-white text-lg font-semibold">Voice and Tone</h3>
                <p className="mt-2 text-sm text-slate-600">
                  Define the personality and style of your brand's communication. These traits will guide how you
                  express your message across all channels.
                </p>
                <ToneRuleList
                  category="traits"
                  items={inputs.traits}
                  onUpdateItem={onUpdateItem}
                  onAddItem={onAddItem}
                  onRemoveItem={onRemoveItem}
                />
              </div>
              <div className="relative mt-8">
                <h3 className="sticky top-0 z-10 bg-white text-lg font-semibold">Writing Rules</h3>
                <p className="mt-2 text-sm text-slate-600">
                  Establish guidelines for consistent and effective writing. These rules ensure clarity, coherence, and
                  alignment with your brand's style across all content.
                </p>
                <ToneRuleList
                  category="writingRules"
                  items={inputs.writingRules}
                  onUpdateItem={onUpdateItem}
                  onAddItem={onAddItem}
                  onRemoveItem={onRemoveItem}
                />
              </div>
            </div>
            <div className="sticky top-0 self-start">
              <div className="mb-2 text-lg font-medium">Try out your voice</div>
              <div className="mb-4 text-sm text-slate-600">
                Once you're happy with your choices, see how they sound in the examples below.
              </div>
              <div className="mt-4">
                <textarea
                  className="h-72 w-full rounded-lg border bg-gray-50 p-4 text-sm focus:border-black focus:outline-none focus:ring-black"
                  placeholder="Applying Voice..."
                  disabled={true}
                  value={voiceOutput}
                />
                <IconButton
                  icon="IconReload"
                  buttonClassName="h-10 rounded-lg px-6 py-2 font-medium mt-4"
                  trackingId="apply-voice-click"
                  content="Apply Voice"
                  disabled={editedInputs.length === 0 || isStreamingVoice}
                  onClick={handleApplyVoice}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex px-5 py-4">
          <div className="flex w-full justify-end pt-5">
            <Button
              className="h-10 rounded-lg px-6 py-2 text-sm"
              buttonSize="large"
              variation="filled"
              disabled={isCreatingVoiceProfile}
              trackingId="save-voice-profile-next-click"
              onClick={handleNext}
            >
              Next
            </Button>
          </div>
        </div>
      </>
    </ContentDialog>
  );
};

export default memo(TuneVoiceProfile);
