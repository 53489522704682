import { useState, memo, useCallback } from 'react';
import ContentDialog from '@/Pages/Sessions/uiComponents/ContentDialog/ContentDialog';
import Button from '@/components/atoms/Button/Button';
import useAssetRating from '@/hooks/useAssetRating';

const AssetRatingDialog = () => {
  const { isAssetRatingDialogOpen, closeAssetRatingDialog, handleSubmit } = useAssetRating();
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false);

  const handleFeedbackSubmit = useCallback(
    async (skip: boolean) => {
      setIsSubmittingFeedback(true);
      handleSubmit(skip ? '' : feedbackMessage).finally(() => {
        setFeedbackMessage('');
        setIsSubmittingFeedback(false);
      });
    },
    [feedbackMessage, handleSubmit]
  );

  return (
    <ContentDialog
      isOpen={isAssetRatingDialogOpen}
      hideCloseIcon={true}
      size="medium"
      setIsOpen={closeAssetRatingDialog}
      disableBackdropClose={true}
    >
      <div className="flex items-center space-x-5 px-5 py-4 leading-tight">
        <div className="grow pt-0.5">
          <h1 className="font-medium">Thank you for your Feedback!</h1>
          <div className="ml-0 mt-1.5 w-full text-sm leading-tight text-slate-600">
            <textarea
              value={feedbackMessage}
              onChange={e => setFeedbackMessage(e.target.value)}
              placeholder="Your feedback helps us improve. Share any additional thoughts here."
              className="-mb-1 mt-1.5 h-24 w-full rounded-md border p-2.5 ring-2 ring-transparent focus:border-transparent focus:outline-none focus:ring-2 focus:ring-black"
            />
          </div>
        </div>
      </div>
      <div className="flex w-full justify-end space-x-2 border-t border-slate-200 px-5 pb-3 pt-2.5">
        <Button
          trackingId="skip-feedback-dialog-secondary-action"
          variation="outline"
          disabled={isSubmittingFeedback}
          onClick={() => handleFeedbackSubmit(true)}
        >
          Skip
        </Button>
        <Button
          trackingId="skip-feedback-dialog-primary-action"
          variation="filled"
          className="ml-2"
          disabled={!feedbackMessage?.length || isSubmittingFeedback}
          onClick={() => handleFeedbackSubmit(false)}
        >
          Send
        </Button>
      </div>
    </ContentDialog>
  );
};

export default memo(AssetRatingDialog);
