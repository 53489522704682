import React, { useEffect, useState } from 'react';
import { SidebarContext, titles } from './SideBarUtils';
import MainSideBar from './MainSideBar';
import { shouldRenderMainSideBar } from './utils';
import TemplatesSideBar from './TemplatesSideBar/TemplatesSideBar';
import IntroOutroPopover from '../ClipPlayer/ClipTimeline/IntroOutroPopover/IntroOutroPopover';
import Loader from '@/components/atoms/Loader';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import ClipTranscript from '@/components/molecules/Transcript/ClipTranscript/ClipTranscript';

export default function SideBar() {
  const [sideBarContext, setSideBarContext] = useState(SidebarContext.Main);
  const title = titles[sideBarContext];
  const { clipVideosLoading, clipCaptionsLoading } = useClipsContext();

  const isTranscriptSidebar = [SidebarContext.EditCaptions, SidebarContext.EditDuration].includes(sideBarContext);

  function showEditCaptions() {
    setSideBarContext(SidebarContext.EditCaptions);
  }

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.OpenEditCaptions, showEditCaptions);
    return () => {
      EventBus.off(CustomEvents.OpenEditCaptions, eventListener);
    };
  }, []);

  return (
    <div
      className={`relative border-r border-r-slate-200 bg-white transition-all ${
        isTranscriptSidebar ? 'w-[38rem] min-w-[38rem]' : 'w-[26rem] min-w-[26rem]'
      }
    `}
    >
      {((clipCaptionsLoading && isTranscriptSidebar) || clipVideosLoading) && (
        <div className="absolute inset-0 z-50 flex items-center justify-center bg-white/80">
          <Loader size="small" />
        </div>
      )}
      <div className="relative h-full overflow-hidden">
        {shouldRenderMainSideBar(sideBarContext) ? (
          <MainSideBar setSideBarContext={setSideBarContext} />
        ) : isTranscriptSidebar ? (
          <ClipTranscript setSideBarContext={setSideBarContext} title={title} sideBarContext={sideBarContext} />
        ) : sideBarContext === SidebarContext.IntroOutro ? (
          <IntroOutroPopover setSideBarContext={setSideBarContext} title={title} />
        ) : sideBarContext === SidebarContext.Templates ? (
          <TemplatesSideBar setSideBarContext={setSideBarContext} title={title} />
        ) : null}
      </div>
    </div>
  );
}
