import { getEnvConfig } from '@/constants';

const CUSTOM_FONT_NAME = 'Custom Font';

export const fonts = [
  {
    path: 'Alpino_Complete/Fonts/OTF/Alpino-Regular.otf',
    name: 'Alpino'
  },
  {
    path: 'Amulya_Complete/Fonts/OTF/Amulya-Regular.otf',
    name: 'Amulya'
  },
  {
    path: 'BebasNeue_Complete/Fonts/OTF/BebasNeue-Regular.otf',
    name: 'Bebas Neue'
  },
  {
    path: 'BespokeSlab_Complete/Fonts/OTF/BespokeSlab-Regular.otf',
    name: 'Bespoke Slab'
  },
  {
    path: 'ClashDisplay_Complete/Fonts/OTF/ClashDisplay-Regular.otf',
    name: 'Clash Display'
  },
  {
    path: 'ClashGrotesk_Complete/Fonts/OTF/ClashGrotesk-Regular.otf',
    name: 'Clash Grotesk'
  },
  {
    path: 'Epilogue_Complete/Fonts/OTF/Epilogue-Regular.otf',
    name: 'Epilogue'
  },
  {
    path: 'Erode_Complete/Fonts/OTF/Erode-Regular.otf',
    name: 'Erode'
  },
  {
    path: 'Expose_Complete/Fonts/OTF/Expose-Regular.otf',
    name: 'Expose'
  },
  {
    path: 'FamiljenGrotesk_Complete/Fonts/OTF/FamiljenGrotesk-Regular.otf',
    name: 'Familjen Grotesk'
  },
  {
    path: 'Gambetta_Complete/Fonts/OTF/Gambetta-Regular.otf',
    name: 'Gambetta'
  },
  {
    path: 'Lora_Complete/Fonts/OTF/Lora-Regular.otf',
    name: 'Lora'
  },
  {
    path: 'Melodrama_Complete/Fonts/OTF/Melodrama-Regular.otf',
    name: 'Melodrama'
  },
  {
    path: 'MerriweatherSans_Complete/Fonts/OTF/MerriweatherSans-Regular.otf',
    name: 'Merriweather Sans'
  },
  {
    path: 'Nunito_Complete/Fonts/OTF/Nunito-Regular.otf',
    name: 'Nunito'
  },
  {
    path: 'Pally_Complete/Fonts/OTF/Pally-Regular.otf',
    name: 'Pally'
  },
  {
    path: 'Poppins_Complete/Fonts/OTF/Poppins-Regular.otf',
    name: 'Poppins'
  },
  {
    path: 'Rowan_Complete/Fonts/OTF/Rowan-Regular.otf',
    name: 'Rowan'
  },
  {
    path: 'SpaceGrotesk_Complete/Fonts/OTF/SpaceGrotesk-Regular.otf',
    name: 'Space Grotesk'
  },
  {
    path: 'Supreme_Complete/Fonts/OTF/Supreme-Regular.otf',
    name: 'Supreme'
  },
  {
    path: 'Switzer_Complete/Fonts/OTF/Switzer-Regular.otf',
    name: 'Switzer'
  },
  {
    path: 'Telma_Complete/Fonts/OTF/Telma-Regular.otf',
    name: 'Telma'
  },
  {
    path: 'WorkSans_Complete/Fonts/OTF/WorkSans-Regular.otf',
    name: 'Work Sans'
  },
  {
    path: 'Zodiak_Complete/Fonts/OTF/Zodiak-Regular.otf',
    name: 'Zodiak'
  }
];

export const load = () =>
  fonts.forEach(({ name, path }) => loadFont(`${getEnvConfig('FONTS_S3_URL')}${path.split('/').pop()}`, name));

export function loadFont(theme_font_src: string, name = CUSTOM_FONT_NAME): Promise<string> {
  document.fonts.forEach(face => {
    if (face.family === name) {
      document.fonts.delete(face);
    }
  });
  const font = new FontFace(name, `url(${theme_font_src})`);
  return font.load().then(face => {
    document['fonts'].add(face);
    return name;
  });
}
