import React, { useEffect, useMemo, useRef, useState } from 'react';
import Portrait from './Portrait';
import Landscape from './Landscape';
import Square from './Square';
import { getTruncatedName, getTruncatedTitle } from '../../CompositePlayer/SpeakerVideoUtils';
import { AudiogramCommonProps } from './types';
import { useSpeakerSegmentContext } from '@/context/SpeakerSegmentContext/SpeakerSegmentContext';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { getCurrentClipFontName } from '@/stores/brandKit';
import { isNumber } from '@/libs/utils';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function Audiogram({ previewOnly = false, isMinimized = false }) {
  const { clipId, clipData, playerStore } = useClipsContext();
  const [truncatedTitle, setTruncatedTitle] = useState('');
  const [truncatedName, setTruncatedName] = useState('');

  const fontFamilyStyle = useMemo(() => {
    const fontName = getCurrentClipFontName(clipId);
    if (fontName) return { fontFamily: fontName };
    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clipData.asset_metadata.font_location]);

  const size = clipData.asset_metadata.size;
  const backgroundColor = clipData.asset_metadata.magicLayout?.backgroundColor!;
  const backgroundImage = clipData.asset_metadata.magicLayout?.backgroundImage;
  const textColor = clipData.asset_metadata.magicLayout?.textColor!;

  const audiogramContainer = useRef<HTMLDivElement>(null);
  let magicLayoutTileContainer = useRef<HTMLDivElement | null>(null);

  const speakerSegmentContextData = useSpeakerSegmentContext();
  const [currentSegment, setCurrentSegment] = useState(speakerSegmentContextData?.startSegment);
  const [activeSpeaker, setActiveSpeaker] = useState({
    first_name: '',
    last_name: '',
    title: '',
    company: ''
  } as SpeakerWithDetails);

  useEffect(() => {
    const playerIndex = playerStore.currentSrtIndex;
    if (!previewOnly && isNumber(playerIndex)) {
      setCurrentSegment(playerIndex >= 0 ? playerIndex : speakerSegmentContextData?.startSegment);
    }
  }, [playerStore.currentSrtIndex]);

  useEffect(() => {
    const speaker =
      speakerSegmentContextData?.segmentSpeaker[
        previewOnly || currentSegment === -1 ? speakerSegmentContextData?.startSegment : currentSegment!
      ] ?? activeSpeaker;
    setActiveSpeaker(speaker);
    setTruncatedTitle(getTruncatedTitle(speaker, 'AUDIOGRAM', size));
    setTruncatedName(getTruncatedName(`${speaker.first_name} ${speaker.last_name}`, 'AUDIOGRAM', size));
  }, [currentSegment, size, speakerSegmentContextData, previewOnly]);

  const [width, setWidth] = useState(audiogramContainer.current?.getBoundingClientRect().width ?? 0);
  const [height, setHeight] = useState(audiogramContainer.current?.getBoundingClientRect().height ?? 0);

  useEffect(() => {
    const audiogramResizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        setWidth(entry.contentRect.width);
        setHeight(entry.contentRect.height);
      }
    });

    const magicLayoutTileResizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        setWidth(entry.contentRect.width);
        setHeight(entry.contentRect.height);
      }
    });
    if (audiogramContainer.current) {
      audiogramResizeObserver.observe(audiogramContainer.current);
    }

    magicLayoutTileContainer.current = document.getElementById(`magic-layout-tile__AUDIOGRAM`) as HTMLDivElement;
    if (magicLayoutTileContainer.current && previewOnly) {
      magicLayoutTileResizeObserver.observe(magicLayoutTileContainer.current);
    }

    return () => {
      audiogramResizeObserver.disconnect();
      magicLayoutTileResizeObserver.disconnect();
    };
  }, []);

  const commonProps: AudiogramCommonProps = {
    backgroundColor,
    textColor,
    width,
    height,
    activeSpeaker,
    truncatedTitle,
    truncatedName,
    fontFamilyStyle,
    backgroundImage
  };

  const isSquareAudiogram = size === 'SQUARE' && !previewOnly;
  const isPortraitAudiogram = size === 'PORTRAIT' && !previewOnly;

  return (
    <>
      {!previewOnly && (
        <div
          className="absolute left-0 top-0 h-full w-full rounded bg-white"
          ref={audiogramContainer}
          data-testid="audiogram-container"
        />
      )}

      {isSquareAudiogram && <Square sizeMultiplier={isMinimized ? 0.95 : 1} {...commonProps} />}

      {isPortraitAudiogram && <Portrait sizeMultiplier={isMinimized ? 0.9 : 1} {...commonProps} />}

      {!isSquareAudiogram && !isPortraitAudiogram && (
        <Landscape previewOnly={previewOnly} sizeMultiplier={isMinimized ? 0.8 : 1} {...commonProps} />
      )}
    </>
  );
}
