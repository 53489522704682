import { memo, useMemo, useRef } from 'react';
import {
  getGreaterMultipleOfN,
  getLesserMultipleOfN,
  roundToNearestHigherHalf,
  roundToNearestLowerHalf
} from '../ClipTimeline/ClipTimelineUtils';
import {
  FRAME_HEIGHT_IN_PX,
  LANDSCAPE_WIDTH_IN_PX,
  NUMBER_OF_COLUMNS_IN_FILMSTRIP,
  NUMBER_OF_ROWS_IN_FILMSTRIP
} from '../ClipTimeline/ClipTimelineConstants';
import Loader from '@/components/atoms/Loader';

const Filmstrip = ({
  filmstripFilenames,
  singleFrameWidth,
  start,
  end,
  sectionWidth
}: {
  filmstripFilenames: string[];
  singleFrameWidth: number;
  start: number;
  end: number;
  sectionWidth: number;
}) => {
  const frameCounterRef = useRef(0);

  const greatestPossibleStart = useMemo(() => {
    return getLesserMultipleOfN(start, 15);
  }, [start]);

  const lowestPossibleEnd = useMemo(() => {
    return getGreaterMultipleOfN(end, 15);
  }, [end]);

  const filmstripImagePathsToLoad = useMemo(() => {
    return filmstripFilenames
      .map((imagePath, index) => ({
        imagePath,
        index
      }))
      .filter((_, index) => {
        const time = index * 15;

        return time >= greatestPossibleStart && time <= lowestPossibleEnd;
      });
    // do not include filmstripFilenames here, include everything else
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [greatestPossibleStart, lowestPossibleEnd]);

  const getFramesFromImage = ({
    imagePath,
    index,
    timeFrames,
    frameCount
  }: {
    imagePath: string;
    index: number;
    timeFrames: number;
    frameCount: number;
  }) => {
    const frames: {
      imagePath: string;
      row: number;
      col: number;
      left: number;
      time: number;
    }[] = [];

    const startValue = index * 15;

    const actualStartForFrame = roundToNearestLowerHalf(start);

    for (let row = 0; row < NUMBER_OF_ROWS_IN_FILMSTRIP; row++) {
      for (let col = 0; col < NUMBER_OF_COLUMNS_IN_FILMSTRIP; col++) {
        if (frameCounterRef.current === 0) continue;

        const relativeValue = 0.5 * col + 2.5 * row;
        const absoluteValue = startValue + relativeValue;

        const diff =
          roundToNearestHigherHalf(
            actualStartForFrame + timeFrames * roundToNearestLowerHalf(frameCount - frameCounterRef.current)
          ) === roundToNearestHigherHalf(absoluteValue);

        if (diff) {
          frames.push({
            imagePath,
            row,
            col,
            time: absoluteValue,
            left: 0
          });

          frameCounterRef.current -= 1;
        }
      }
    }
    return frames;
  };

  const frames = useMemo(() => {
    const frameCount = Math.ceil(sectionWidth / singleFrameWidth);
    const timeFrames = 5;
    frameCounterRef.current = frameCount;

    return filmstripImagePathsToLoad.flatMap(obj => getFramesFromImage({ ...obj, timeFrames, frameCount }));
  }, [filmstripImagePathsToLoad, end, start, singleFrameWidth, sectionWidth]);

  return (
    <div className="no-scrollbar flex h-full overflow-hidden">
      <div className="flex">
        {frames.map(frame => (
          <div
            className="relative flex h-full items-center justify-center overflow-hidden"
            style={{ width: singleFrameWidth + 'px', left: frame.left + 'px' }}
            key={frame.time}
          >
            <div className="absolute inset-0 flex items-center justify-center bg-black opacity-20">
              <Loader size="small" />
            </div>
            <div
              style={{
                position: 'absolute',
                height: FRAME_HEIGHT_IN_PX + 'px',
                width: LANDSCAPE_WIDTH_IN_PX + 'px',
                backgroundSize: `${LANDSCAPE_WIDTH_IN_PX * NUMBER_OF_COLUMNS_IN_FILMSTRIP}px ${
                  FRAME_HEIGHT_IN_PX * NUMBER_OF_ROWS_IN_FILMSTRIP
                }px`,
                backgroundImage: `url(${frame.imagePath})`,
                backgroundPosition: `-${frame.col * LANDSCAPE_WIDTH_IN_PX}px -${frame.row * FRAME_HEIGHT_IN_PX}px`,
                backgroundRepeat: 'no-repeat'
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(Filmstrip);
