import { produce } from 'immer';
type ListenerFn = () => void;

export default function createStore<T>(init: T) {
  let listeners: ListenerFn[] = [];
  let state = init;

  function emitChange() {
    for (let listener of listeners) {
      listener();
    }
  }

  function set(setter) {
    state = produce(state, setter);
    emitChange();
  }

  function update(updater: (draft: T) => void) {
    state = produce(state, updater);
    emitChange();
  }

  return {
    set,
    update,
    subscribe(listener: ListenerFn) {
      listeners = [...listeners, listener];
      return () => {
        listeners = listeners.filter(l => l !== listener);
      };
    },
    getSnapshot() {
      return state;
    }
  };
}
