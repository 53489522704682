import React from 'react';
import { IconCalendarEvent } from '@tabler/icons-react';
import { useAppContext } from '@/context/AppContext/AppContext';

export default function NoData(props: React.PropsWithChildren<{}>) {
  const {
    adminAppStore: { user: { is_content_lab_standalone } = { is_content_lab_standalone: false } }
  } = useAppContext();

  return (
    <div className="flex h-[calc(20rem-0.5px)] w-full items-center justify-center">
      <div className="flex flex-col items-center">
        <IconCalendarEvent size={32} stroke={1.5} className="text-slate-400" />
        <div
          className={`mt-2 text-center text-sm leading-tight text-slate-400 ${
            is_content_lab_standalone ? 'max-w-[14rem]' : 'max-w-[10rem]'
          }`}
        >
          {is_content_lab_standalone
            ? "It looks like you haven't uploaded any recordings yet."
            : "It looks like you haven't run any events yet."}
        </div>
        {props.children}
      </div>
    </div>
  );
}
