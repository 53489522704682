import React from 'react';
import MultiSelectDropdown from '../uiComponents/MultiSelectDropdown/MultiSelectDropdown';
import { typeOptions } from '../utils';

export default function TypeFilter({ selected, onSelect, onClear, disabled }) {
  return (
    <MultiSelectDropdown
      label={'Type'}
      joinSelected
      selected={selected}
      onSelect={onSelect}
      onClear={onClear}
      options={typeOptions}
      disabled={disabled}
    />
  );
}
