import { useMemo } from 'react';
import { assetsTextUpdate } from '@goldcast/api/content';
import { showErrorToast } from '@/libs/toast/toast';
import { TextAsset } from '@/Pages/PostsListPage/types';
import useAnalytics from '@/hooks/useAnalytics';

export default function usePostShareHook(selectedPost: TextAsset | null): {
  setPostAsShared: () => Promise<void> | undefined;
  postShareLink: string;
} {
  const { trackContentShare } = useAnalytics();

  const postShareLink = useMemo(() => {
    return `${window.location.origin}/shared/post/${selectedPost?.id}`;
  }, [selectedPost?.id]);

  function trackOpenPopup(isSuccess: boolean = true) {
    trackContentShare({
      contentType: selectedPost?.asset_metadata?.type || 'TEXT',
      source: 'EditorPage',
      shareType: 'OpenPopup',
      isSuccess,
      asset: selectedPost
    });
  }

  function setPostAsShared() {
    if (!selectedPost) {
      return;
    }

    return assetsTextUpdate({ id: selectedPost.id, body: { ...selectedPost, shared: true } as any })
      .then(() => trackOpenPopup())
      .catch(() => {
        trackOpenPopup(false);
        showErrorToast('Something went wrong while sharing the post. Please try again later.');
      });
  }

  return {
    setPostAsShared,
    postShareLink
  };
}
