import { memo } from 'react';
import IconButton from '@/components/atoms/Button/IconButton';
import { classnames } from '@/libs/utils';

export type PromptConstants = {
  [key: string]: {
    initialValue: string;
    overriddenValue: string;
    type: string;
  };
};

function AIDebugToolsOverrides({
  promptConstants,
  handleUpdatePromptConstant
}: {
  promptConstants: PromptConstants;
  handleUpdatePromptConstant: (value: string, key: string) => void;
}) {
  return (
    <div className="py-2">
      <div className="flex w-full justify-center p-2 font-mono text-xs font-semibold">OVERRIDES</div>
      {Object.entries(promptConstants).map(([key, constant]) => (
        <div key={key} className="border-b border-dashed p-2 text-xs">
          <div className="flex w-full items-center justify-between">
            <div className="grow font-mono font-medium" title={constant.type}>
              {key}
              {constant.initialValue !== constant.overriddenValue && '*'}
            </div>
            <IconButton
              icon="IconRefresh"
              size="small"
              disabled={constant.initialValue === constant.overriddenValue}
              variation="text"
              iconClassName={classnames({
                'text-gray-400 hover:text-gray-400': constant.initialValue === constant.overriddenValue
              })}
              trackingId="ai-debug-tools-reset-button"
              onClick={() => handleUpdatePromptConstant(constant.initialValue, key)}
            />
          </div>
          {constant.type === 'number' ? (
            <input
              type="number"
              className="w-full rounded border p-2"
              value={constant.overriddenValue}
              onInput={e => handleUpdatePromptConstant((e.target as any)?.value, key)}
            />
          ) : (
            <textarea
              className="h-[6rem] w-full rounded border p-2"
              value={constant.overriddenValue}
              onInput={e => handleUpdatePromptConstant((e.target as any)?.value, key)}
            />
          )}
        </div>
      ))}
    </div>
  );
}

export default memo(AIDebugToolsOverrides);
