import { IconAlertTriangle, IconCircleCheckFilled, IconExclamationCircle } from '@tabler/icons-react';
import { TypeOptions, toast } from 'react-toastify';
import { COMMON_TOAST_PARAMS, ACTION_TOAST_CONFIG } from './constants';
import { ActionToastPayload } from './types';

export function showSuccessToast(content: string | JSX.Element) {
  const toastContent = typeof content === 'string' ? <div className="tw-flex tw-text-xs">{content}</div> : content;
  toast.success(toastContent, {
    icon: <IconCircleCheckFilled size={24} className="tw-text-green-500" />,
    ...COMMON_TOAST_PARAMS
  });
}

export function showErrorToast(content: string | JSX.Element) {
  const toastContent = typeof content === 'string' ? <div className="tw-flex tw-text-xs">{content}</div> : content;
  toast.error(toastContent, {
    icon: <IconExclamationCircle size={24} className="tw-text-rose-500" />,
    ...COMMON_TOAST_PARAMS
  });
}

export function showWarningToast(content: string | JSX.Element) {
  const toastContent = typeof content === 'string' ? <div className="tw-flex tw-text-xs">{content}</div> : content;
  toast.warning(toastContent, {
    icon: <IconAlertTriangle size={24} className="tw-text-deep-orange-400" />,
    ...COMMON_TOAST_PARAMS
  });
}

export function showActionToast(type: TypeOptions, payload: ActionToastPayload) {
  const { fn: toastFunction, icon } = ACTION_TOAST_CONFIG[type];
  const { title, content, actionButtonCb, actionButtonContent } = payload;
  toastFunction(
    <div>
      <div className="tw-flex">{title}</div>
      <div className="tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-1">
        <div className="tw-flex tw-text-2xs">{content}</div>
        {!!actionButtonContent && !!actionButtonCb && (
          <button
            className="tw-btn-outline tw-flex tw-h-9 tw-w-12 tw-ml-auto tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-slate-300 tw-p-1 tw-px-3 tw-py-2 tw-text-xs tw-font-medium hover:tw-bg-deep-orange-50 hover:tw-text-deep-orange-600 hover:tw-border-deep-orange-50"
            onClick={actionButtonCb}
          >
            {actionButtonContent}
          </button>
        )}
      </div>
    </div>,
    {
      icon,
      ...COMMON_TOAST_PARAMS,
      autoClose: payload.duration || COMMON_TOAST_PARAMS.autoClose
    }
  );
}
