import React, { useEffect, useRef, useState, useSyncExternalStore } from 'react';
import Player from '../SessionDetail/Player';
import SessionTitlePopup from '../SessionDetail/SessionTitlePopup';
import useTranscriptPage from './useTranscriptPage';
import PlayerControls from './PlayerControls';
import FullTranscript from '@/components/molecules/Transcript/FullTranscript';
import { core } from '@/stores/core';
import { player } from '@/stores/player';
import { classnames } from '@/libs/utils';
import FreeTrialUpgradeNag from '@/components/molecules/FreeTrial/FreeTrialUpgradeNag';
import useAnalytics from '@/hooks/useAnalytics';

export default function TranscriptPage() {
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);
  const playerStore = useSyncExternalStore(player.subscribe, player.getSnapshot);

  const containerRef = useRef<HTMLDivElement>(null);
  const [isSampleVideoPlayedEventTriggered, setIsSampleVideoPlayedEventTriggered] = useState<boolean>(false);
  const { trackSampleAssetPlayed } = useAnalytics();

  useEffect(() => {
    const mainVideoPlayer = document.getElementById('mainVideoPlayer') as HTMLVideoElement;
    if (mainVideoPlayer) {
      mainVideoPlayer.currentTime = 0;
    }
  }, []);

  const { createClip, isAudio } = useTranscriptPage();

  useEffect(() => {
    if (!isSampleVideoPlayedEventTriggered && !playerStore.paused) {
      trackSampleAssetPlayed();
      setIsSampleVideoPlayedEventTriggered(true);
    }
  }, [playerStore.paused, coreStore, isSampleVideoPlayedEventTriggered]);

  return (
    <div className="grid h-full w-full grid-cols-[1fr] grid-rows-[auto,max-content] pb-4 pr-4">
      <div className="relative grid w-full grid-cols-[max-content,1fr] grid-rows-[1fr] gap-4" ref={containerRef}>
        <FullTranscript onCreateClip={createClip} playerStore={playerStore} />
        <div
          className={classnames('flex grow flex-col transition-all duration-[400ms] ease-in-out', {
            hidden: isAudio
          })}
        >
          <div className="flex w-full items-center justify-between py-4">
            <SessionTitlePopup />
            <FreeTrialUpgradeNag />
          </div>
          <Player hidden={isAudio} />
        </div>
      </div>
      <PlayerControls clips={[]} />
    </div>
  );
}
