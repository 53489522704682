type PollingOptions<T> = {
  pollFunction: () => Promise<T | null>;
  onSuccess: (result: T) => void;
  onError?: (error: any) => void;
  interval?: number;
  timeout?: number;
};

const poll = async <T>({ pollFunction, onSuccess, onError, interval = 5000, timeout = 60000 }: PollingOptions<T>) => {
  const pollingTimer = setInterval(async () => {
    try {
      const result = await pollFunction();
      if (result) {
        clearInterval(pollingTimer);
        clearTimeout(timeoutTimer);
        onSuccess(result);
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }
  }, interval);

  const timeoutTimer = setTimeout(() => {
    clearInterval(pollingTimer);
    if (onError) {
      onError(new Error('Polling timed out'));
    }
  }, timeout);
};

export default poll;
