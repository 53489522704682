import { IconChevronDown, IconChevronRight, IconPlayerPauseFilled, IconPlayerPlayFilled } from '@tabler/icons-react';
import { useCallback, useEffect, useMemo, useRef, useState, useSyncExternalStore } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { getLongestSpeakerSlotStart } from '../utils';
import FacesGrid from './FacesGrid';
import HlsPlayerV2 from '@/Pages/Clip/ClipPlayer/HlsPlayerV2';
import LabeledInput from '@/components/atoms/LabeledInput';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import Loader from '@/components/atoms/Loader';
import { core, getVideoManifestUrl } from '@/stores/core';
import Waveform from '@/components/atoms/Waveform/Waveform';
import { classnames } from '@/libs/utils';
import { AvatarUpload } from '@/components/atoms/SpeakersList/AvatarUpload';
import InlineSelect from '@/components/atoms/InlineSelect';
import { PronounOptions } from '@/components/atoms/SpeakersList/SpeakersListUtils';

export default function IdentifySpeakerStep({
  speaker,
  isActive,
  isInvalid,
  isInlineIdentification = false,
  onUpdate
}: {
  speaker: SpeakerWithDetails;
  isActive: boolean;
  isInvalid: boolean;
  isInlineIdentification?: boolean;
  onUpdate: (value: SpeakerWithDetails) => void;
}) {
  const [searchParams] = useSearchParams();
  const { eventId, broadcastId } = useParams<{ eventId: string; broadcastId: string; clipId: string }>();

  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);

  const clipId = useMemo(() => {
    return searchParams.get('activeClipId');
  }, [searchParams]);

  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPortrait, setIsPortrait] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [speakerInfo, setSpeakerInfo] = useState<SpeakerWithDetails>(speaker);
  const [showPause, setShowPause] = useState(false);
  const [areSpeakerDetailsOpen, setAreSpeakerDetailsOpen] = useState(
    () => !isInlineIdentification || speaker.first_name.match(/Speaker \d+/i) === null
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const url = useMemo(() => getVideoManifestUrl(), [eventId, broadcastId]);

  const longestSpeakingSlot = parseFloat(getLongestSpeakerSlotStart(speaker) || '1');
  const longestSpeakingSlotDuration = Math.min(speaker.speakingSlots[longestSpeakingSlot] - longestSpeakingSlot, 10);
  let playingTimeout;

  useEffect(() => {
    if (!isActive) {
      clearTimeout(playingTimeout);
      pauseVideo();
    }
  }, [isActive, playingTimeout]);

  function playVideo() {
    videoRef.current?.play();
    setIsPlaying(true);
    playingTimeout = setTimeout(() => {
      pauseVideo();
      if (videoRef.current) {
        // Adding in a 1 second buffer to the start time. 1st Occurrence
        videoRef.current.currentTime = longestSpeakingSlot + 1;
      }
    }, longestSpeakingSlotDuration * 1000);
  }

  function pauseVideo() {
    videoRef.current?.pause();
    setIsPlaying(false);
  }

  function onSpeakerUpdate(field: string, value: string | number[] | null) {
    const newSpeakerInfo = {
      ...speakerInfo,
      [field]: value
    };
    setSpeakerInfo(newSpeakerInfo);
    onUpdate(newSpeakerInfo);
  }

  function displayPause() {
    setShowPause(true);
  }

  function hidePause() {
    setShowPause(false);
  }

  function onAvatarChange(url: string) {
    onSpeakerUpdate('profile_picture_url', url);
  }

  const isAudio = useMemo(() => {
    return coreStore.content?.media_type === 'AUDIO';
  }, [coreStore.content?.media_type]);

  const onSpeakerDetailsToggle = useCallback(() => {
    setAreSpeakerDetailsOpen(a => !a);
  }, []);

  return (
    <>
      {isLoading && isActive && (
        <div className="flex h-[10rem] items-center justify-center">
          <Loader></Loader>
        </div>
      )}

      <div className={`select-none ${!isActive || isLoading ? 'hidden' : 'flex flex-col'}`}>
        <div className="mb-3 text-sm font-medium">
          {isInlineIdentification ? 'Match Speakers' : 'Identify Speakers'}
        </div>
        <div className="flex h-48 w-full items-center justify-center overflow-hidden rounded-xl bg-slate-200">
          <div className="flex aspect-video w-1/2 items-center justify-center overflow-hidden rounded-lg">
            <div className="relative flex justify-center" onMouseEnter={displayPause} onMouseLeave={hidePause}>
              {videoRef && (
                <HlsPlayerV2
                  url={url}
                  videoRef={videoRef}
                  videoProps={{
                    className: classnames(
                      'rounded-md',
                      isPlaying ? 'opacity-70' : '',
                      isAudio ? 'w-full bg-slate-400' : '',
                      isPortrait ? 'max-w-[13rem] py-3' : 'w-full'
                    )
                  }}
                  onMediaAttached={() => {
                    if (videoRef.current) {
                      // Adding in a 1 second buffer to the start time. 2nd Occurrence
                      videoRef.current.currentTime = longestSpeakingSlot + 1;
                    }
                  }}
                  onLoaded={() => {
                    setIsLoading(false);
                    setIsPortrait(!!videoRef.current && videoRef.current.videoHeight > videoRef.current.videoWidth);
                  }}
                />
              )}
              {!isPlaying && (
                <div className="absolute flex h-full w-full items-center justify-center rounded-xl bg-black/30">
                  <IconPlayerPlayFilled className="cursor-pointer text-white" size={45} onClick={playVideo} />
                </div>
              )}
              {isPlaying && showPause && (
                <div className="absolute z-10 flex h-full w-full items-center justify-center rounded-xl hover:bg-black/30">
                  <IconPlayerPauseFilled className="cursor-pointer text-white" size={45} onClick={pauseVideo} />
                </div>
              )}
              {isPlaying && isAudio && (
                <div className="absolute self-center">
                  <Waveform textColor="white" paused={false} height={80} />
                </div>
              )}
            </div>
          </div>
        </div>

        {clipId && isInlineIdentification && (
          <FacesGrid speakerInfo={speakerInfo} onSpeakerUpdate={onSpeakerUpdate} clipId={clipId} />
        )}

        <div className="pt-4">
          <div className="flex cursor-pointer justify-between px-2" onClick={onSpeakerDetailsToggle}>
            <div className="text-sm font-medium">Speaker Details</div>
            {areSpeakerDetailsOpen ? (
              <IconChevronDown className="text-slate-600" size={20} />
            ) : (
              <IconChevronRight className="text-slate-600" size={20} />
            )}
          </div>
          <Transition
            appear
            show={areSpeakerDetailsOpen}
            className="w-full overflow-hidden transition-all duration-500"
            enterFrom="transform opacity-0 max-h-0"
            enterTo="transform opacity-100 max-h-96"
            leaveFrom="transform opacity-100 max-h-96"
            leaveTo="transform opacity-0 max-h-0"
          >
            <div>
              <div className="mt-4 flex flex-row">
                <LabeledInput
                  classNames="p-2 grow"
                  label="First Name"
                  isRequired
                  isInvalid={isInvalid && !speakerInfo.first_name}
                  data-testid={`speaker-first-name-${speakerInfo.key}`}
                  value={speakerInfo.first_name}
                  onChange={value => onSpeakerUpdate('first_name', value)}
                />
                <LabeledInput
                  classNames="p-2 grow"
                  label="Last Name"
                  data-testid={`speaker-last-name-${speakerInfo.key}`}
                  value={speakerInfo.last_name}
                  onChange={value => onSpeakerUpdate('last_name', value)}
                />
              </div>
              <div className="flex flex-row">
                <LabeledInput
                  classNames="p-2 grow"
                  label="Title"
                  data-testid="speaker-title"
                  value={speakerInfo.title}
                  onChange={value => onSpeakerUpdate('title', value)}
                />
                <LabeledInput
                  classNames="p-2 grow"
                  label="Company"
                  data-testid="speaker-company"
                  value={speakerInfo.company}
                  onChange={value => onSpeakerUpdate('company', value)}
                />
              </div>

              <div className="p-2">
                <InlineSelect
                  label="Preferred Pronouns"
                  classNames="w-full"
                  value={speaker.pronouns || ''}
                  options={PronounOptions}
                  onChange={value => onSpeakerUpdate('pronouns', value)}
                  data-testid={`pronouns-input-${speaker.id}`}
                  tooltipId={`pronouns-tooltip-${speaker.id}`}
                  tooltipContent="We use this to help inform our AI which pronouns to use when generating text content"
                />
              </div>

              <div className="p-2">
                <div className="text-xs text-slate-600">Avatar</div>
                <AvatarUpload speaker={speakerInfo} onAvatarChange={onAvatarChange} />
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
