import { useCallback, useState } from 'react';

export default function useTimelineSectionHover({
  sectionRef,
  disabled,
  onSeeked
}: {
  sectionRef: React.RefObject<HTMLDivElement | HTMLButtonElement>;
  disabled: boolean;
  onSeeked: (unitRelativeToStart: number) => void;
}) {
  const [unitRelativeToStart, setUnitRelativeToStart] = useState<number>(0);

  const onMoveOverSection = useCallback(
    e => {
      if (disabled) return;

      const sectionBounds = sectionRef.current?.getBoundingClientRect();
      if (!sectionBounds) return;

      const { left: sectionLeftBound, right: sectionRightBound } = sectionBounds;

      const sectionWidth = sectionRightBound - sectionLeftBound;

      const pointerCurrentX = e.clientX;

      const distanceFromStart = pointerCurrentX - sectionLeftBound;

      setUnitRelativeToStart(distanceFromStart / sectionWidth);
    },
    [disabled]
  );

  const onPointerUpInSection = useCallback(() => {
    if (disabled) return;

    onSeeked?.(unitRelativeToStart);
  }, [disabled, unitRelativeToStart, onSeeked]);

  return {
    unitRelativeToStart,
    onMoveOverSection,
    onPointerUpInSection
  };
}
