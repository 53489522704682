import { memo, useCallback, useEffect, useMemo, useSyncExternalStore } from 'react';
import {
  ClipPlayerSection,
  useIntroRefReturn,
  useMainSectionRefReturn,
  useOutroRefReturn
} from '../ClipPlayerSections/ClipPlayerSectionsTypes';
import ClipTimelineActionBar from '../ClipTimeline/ClipTimelineActionBar';
import useClipTranscriptPlayPause from '../ClipPlayerControls/useClipTranscriptPlayPause';
import { getMainPlayerTime } from '../ClipPlayerControls/utils';
import AddNewSection from './AddNewSection';
import ClipTimelineSection from './ClipTimelineSection';
import { LANDSCAPE_WIDTH_IN_PX } from '../ClipTimeline/ClipTimelineConstants';
import VideoSection from './VideoSection';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { sharedAPI } from '@/stores/sharedAPI/sharedAPI';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { updateIntroOutro } from '@/stores/clip';
import Loader from '@/components/atoms/Loader';
import { roundToNDecimalPlaces } from '@/libs/utils';

function ClipTimelineWithActionBar({
  sections,
  currentSection,
  disabled,
  introPlayerProps,
  mainPlayerProps,
  outroPlayerProps,
  setCurrentSectionAndPlay
}: {
  sections: ClipPlayerSection[];
  currentSection: ClipPlayerSection;
  setCurrentSectionAndPlay: (section: ClipPlayerSection, shouldPlay: boolean, time?: number) => void;
  disabled: boolean;
  introPlayerProps: useIntroRefReturn;
  mainPlayerProps: useMainSectionRefReturn;
  outroPlayerProps: useOutroRefReturn;
}) {
  const { clipId, clipData, clipCaptionsLoading } = useClipsContext();

  const { isPaused, playCurrentSection, pauseCurrentSection } = useClipTranscriptPlayPause({
    currentSection,
    introPlayerProps,
    mainPlayerProps,
    outroPlayerProps
  });

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.OpenSpeakersIdentification, pauseCurrentSection);
    return () => {
      EventBus.off(CustomEvents.OpenSpeakersIdentification, eventListener);
    };
  }, [currentSection]);

  const sharedAPIStore = useSyncExternalStore(sharedAPI.subscribe, sharedAPI.getSnapshot);

  const sessionEndTime = useMemo(() => {
    return sharedAPIStore[clipId].mainPlayerRef?.duration || 0;
  }, [clipId, sharedAPIStore]);

  const { outroDuration, outroCurrentTime } = outroPlayerProps;
  const { mainSectionCurrentTime, mainSectionRef } = mainPlayerProps;
  const { introDuration, introCurrentTime } = introPlayerProps;

  const removeSection = useCallback(
    (section: 'intro' | 'outro') => {
      updateIntroOutro(clipId, section, {
        url: undefined,
        duration: 0
      });
    },
    [clipId]
  );

  const [totalDuration, durations] = useMemo(() => {
    const totalDuration = roundToNDecimalPlaces(
      (clipData.asset_metadata.duration || 0) + (introDuration || 0) + (outroDuration || 0),
      3
    );
    const durations = {
      intro: clipData.asset_metadata.intro ? introDuration : 0,
      main: clipData.asset_metadata.duration || 0,
      outro: clipData.asset_metadata.outro ? outroDuration : 0
    };
    return [totalDuration, durations];
  }, [
    clipData.asset_metadata.duration,
    clipData.asset_metadata.intro,
    clipData.asset_metadata.outro,
    introDuration,
    outroDuration
  ]);

  const currentTimes = useMemo(() => {
    return {
      intro: introCurrentTime,
      main: getMainPlayerTime(mainSectionRef.current, mainSectionCurrentTime, clipData.asset_metadata.start, clipId),
      outro: outroCurrentTime
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clipData.asset_metadata.start,
    introCurrentTime,
    mainSectionCurrentTime,
    outroCurrentTime,
    mainSectionRef,
    mainSectionRef.current
  ]);

  const currentAbsoluteTime = useMemo(() => {
    if (currentSection === 'intro') {
      return introCurrentTime;
    } else if (currentSection === 'main') {
      return roundToNDecimalPlaces(currentTimes.main + durations.intro, 3);
    } else {
      return roundToNDecimalPlaces(durations.main + durations.intro + outroCurrentTime, 3);
    }
  }, [introCurrentTime, outroCurrentTime, currentSection, durations.intro, durations.main, currentTimes]);

  const progressPercent = useMemo(() => {
    return roundToNDecimalPlaces(
      currentTimes[currentSection] ? (currentTimes[currentSection] * 100) / durations[currentSection] : 0,
      3
    );
  }, [currentTimes, durations, currentSection]);

  return (
    <div className="self-stretch overscroll-none">
      <div className="relative m-4 mt-0">
        <div className="w-full rounded-2xl border border-gray-200 bg-white py-2 shadow-xl" id="timeline-main-boundary">
          <ClipTimelineActionBar
            isPaused={isPaused}
            playCurrentSection={playCurrentSection}
            pauseCurrentSection={pauseCurrentSection}
            currentAbsoluteTime={currentAbsoluteTime}
            totalDuration={totalDuration}
            disabled={disabled}
          />

          <div className="relative flex w-0 min-w-full items-center gap-x-2.5 overflow-x-scroll px-2.5 pt-8">
            {!clipData.asset_metadata.intro && <AddNewSection position="left" />}
            {sections.map((section, index) => {
              const showProgress = currentSection === section;

              const onSeeked = t => {
                setCurrentSectionAndPlay(section, !isPaused, t * durations[section]);
              };

              // ! This is to be changed to type CLIP later
              if (section === 'main')
                return (
                  <ClipTimelineSection
                    key={index}
                    duration={totalDuration}
                    clipData={clipData}
                    singleFrameWidth={LANDSCAPE_WIDTH_IN_PX}
                    sessionStartTime={0}
                    sessionEndTime={sessionEndTime}
                    disabled={disabled}
                    progressPercent={progressPercent}
                    showProgress={showProgress}
                    onSeeked={onSeeked}
                  />
                );

              // ! This is to be changed to type VIDEO later
              return (
                <VideoSection
                  key={index}
                  section={section}
                  removeSection={removeSection}
                  progressPercent={progressPercent}
                  showProgress={showProgress}
                  onSeeked={onSeeked}
                  duration={durations[section]}
                />
              );
            })}
            {!clipData.asset_metadata.outro && <AddNewSection position={'right'} />}
          </div>
        </div>
        {clipCaptionsLoading && (
          <div className="absolute inset-0 z-[70] flex items-center justify-center bg-white opacity-70">
            <Loader size="small" />
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(ClipTimelineWithActionBar);
