import { TextSelectionType } from './types';

export const tones: TextSelectionType[] = [
  {
    icon: '👔',
    label: 'Professional',
    value: 'professional'
  },
  {
    icon: '🙏',
    label: 'Helpful',
    value: 'helpful'
  },
  {
    icon: '💬',
    label: 'Conversational',
    value: 'conversational'
  },
  {
    icon: '💡',
    label: 'Informative',
    value: 'informative'
  },
  {
    icon: '😊',
    label: 'Friendly',
    value: 'friendly'
  },
  {
    icon: '😜',
    label: 'Playful',
    value: 'playful'
  }
];

export const textLengthOptions: TextSelectionType[] = [
  {
    icon: '🤏',
    label: 'Short',
    value: 'short'
  },
  {
    icon: '🤙',
    label: 'Medium',
    value: 'medium'
  },
  {
    icon: '🤌',
    label: 'Long',
    value: 'long'
  }
];

export const eventPrompts: { [key in POST_TYPES]: string } = {
  SOCIAL: `Write a linkedin post that summarizes the event and asks people to watch on-demand.`,
  EMAIL: `Write an email to attendees of the event that gives 5 takeaways from the event and asks them to view the webinar on demand. It should include [Attendee Name] as the name.`,
  BLOG: `Write a blog post about the session that highlights all key points which someone needs to know to make the best out of the event.`,
  CUSTOM: '',
  TAKEAWAYS: ''
};

export const contentPrompts: { [key in POST_TYPES]: string } = {
  SOCIAL: `Write a linkedin post that summarizes the video and asks people to watch the full video.`,
  EMAIL: `Write an email addressing attendees that gives 5 takeaways from the video and asks them to watch the full video. It should include ##attendee_name as the name.`,
  BLOG: `Write a blog post about the video that highlights all key points which someone needs to know to make the best out of the video.`,
  CUSTOM: '',
  TAKEAWAYS: ''
};

export const TEXT_QUEUE_INTERVAL = 15;
export const TEXT_QUEUE_CHUNK_SIZE = 10;

export const POST_TO_NAV_MAP = {
  LINKEDIN: 'social',
  BLOG: 'blog',
  EMAIL: 'email',
  TAKEAWAYS: 'takeaways',
  CUSTOM: 'custom'
};

export enum POST_TYPES {
  SOCIAL = 'SOCIAL',
  EMAIL = 'EMAIL',
  BLOG = 'BLOG',
  TAKEAWAYS = 'TAKEAWAYS',
  CUSTOM = 'CUSTOM'
}

export enum POST_TYPE_LABELS {
  SOCIAL = 'Social',
  EMAIL = 'Email',
  BLOG = 'Blog',
  TAKEAWAYS = 'Takeaways',
  CUSTOM = 'Custom'
}

export const POST_TYPE_ICONS = {
  SOCIAL: 'IconThumbUp',
  EMAIL: 'IconMail',
  BLOG: 'IconArticle',
  TAKEAWAYS: 'IconStar',
  CUSTOM: 'IconArtboard'
};

export const PostLabelMap = {
  social: 'SOCIAL',
  email: 'EMAIL',
  blog: 'BLOG',
  takeaways: 'TAKEAWAYS',
  custom: 'CUSTOM'
};

export const LENGTH_HINTS = {
  SOCIAL: {
    SHORT: '50 - 100',
    MEDIUM: '100 - 150',
    LONG: '150 - 200'
  },
  EMAIL: {
    SHORT: '200 - 250',
    MEDIUM: '300 - 400',
    LONG: '450 - 550'
  },
  BLOG: {
    SHORT: '450 - 550',
    MEDIUM: '700 - 800',
    LONG: '1000 - 1200'
  },
  TAKEAWAYS: {
    SHORT: '200 - 250',
    MEDIUM: '300 - 400',
    LONG: '450 - 550'
  },
  CUSTOM: {
    SHORT: '200 - 250',
    MEDIUM: '300 - 400',
    LONG: '450 - 550'
  }
};
