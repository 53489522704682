import { useEffect, useRef, useState } from 'react';
import { useIntroRefReturn } from './ClipPlayerSectionsTypes';
import { sharedAPI, updateClipConfig, updateSharedAPIWithId } from '@/stores/sharedAPI/sharedAPI';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function useIntroRef(setCurrentSectionAndPlay): useIntroRefReturn {
  const { clipId } = useClipsContext();
  const introRef = useRef<HTMLVideoElement>(null);
  const [introCurrentTime, setIntroCurrentTime] = useState(0);
  const [introDuration, setIntroDuration] = useState(0);
  const [isIntroPaused, setIsIntroPaused] = useState(true);

  const playIntroSection = () => {
    introRef.current?.play();
    setIsIntroPaused(false);
  };

  const pauseIntroSection = () => {
    introRef.current?.pause();
    setIsIntroPaused(true);
  };

  const onIntroTimeUpdate = () => {
    setIntroCurrentTime(introRef.current!.currentTime);
  };

  const onIntroEnd = () => {
    setIsIntroPaused(true);
    setCurrentSectionAndPlay('main', true);
  };

  const onLoadedIntroData = () => {
    setIntroDuration(introRef.current!.duration);
    setIntroCurrentTime(introRef.current!.currentTime);
    updateSharedAPIWithId(clipId, { introPlayer: introRef.current });
    if (sharedAPI.getSnapshot()[clipId].mainPlayerRef) {
      updateClipConfig(clipId);
    }
  };

  useEffect(() => {
    if (!introRef.current) setIntroDuration(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [introRef.current]);

  return {
    introRef,
    introCurrentTime,
    introDuration,
    isIntroPaused,
    playIntroSection,
    pauseIntroSection,
    onIntroTimeUpdate,
    onIntroEnd,
    onLoadedIntroData
  };
}
