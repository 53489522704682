import React, { useEffect, useMemo, useRef } from 'react';
import {
  drawSpeakerNameTitlePillForGridRecording,
  drawSpeakerNameTitlePillForSpeakerRecording,
  drawSpeakerNameTitlePillForUpload
} from '../StaticCanvasUtils';
import { getFacePositionsForGrid } from '../CanvasPlayerUtils';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import { getSizeConfig } from '@/libs/sharedAPI/sizeConfig/SizeConfigFactory';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function StaticCanvasPreview({ mainPlayer, secondaryPlayers, currentSpeaker, clipMetadata }) {
  const staticCanvasRef = useRef<HTMLCanvasElement>(null);

  const { clipId, speakersAnalysis } = useClipsContext();

  const showCaption = !!clipMetadata.subtitle;

  const clipSize = clipMetadata.size;
  const clipLayout = clipMetadata.layout;
  const showSpeakerLabels = clipMetadata.magicLayout?.showSpeakerLabels && clipLayout !== 'DEFAULT';

  // config used for static canvas
  const staticSizeConfig = useMemo(
    () => getSizeConfig(clipSize, clipId, clipLayout, 2),
    [clipSize, clipId, clipLayout]
  );

  const transcriptStore = useTranscriptContext();

  useEffect(() => {
    const staticCanvas = staticCanvasRef.current;
    if (!staticCanvas) return;

    const { width: staticCanvasWidth, height: staticCanvasHeight } = staticSizeConfig.getHeightAndWidth();

    staticCanvas.width = staticCanvasWidth;
    staticCanvas.height = staticCanvasHeight;
  }, [staticSizeConfig]);

  useEffect(() => {
    if (!currentSpeaker) return;

    const staticCanvasContext = staticCanvasRef.current?.getContext('2d');
    if (!staticCanvasContext) return;

    staticCanvasContext.clearRect(0, 0, staticSizeConfig.getWidth(), staticSizeConfig.getHeight());
    if (showSpeakerLabels) {
      const index = speakersAnalysis.times.length ? 0 : -1;

      if (clipLayout === 'SPEAKER') {
        if (index === -1) {
          drawSpeakerNameTitlePillForSpeakerRecording(
            staticCanvasContext,
            secondaryPlayers,
            currentSpeaker,
            showCaption,
            staticSizeConfig,
            clipLayout,
            clipSize,
            { asset_metadata: clipMetadata },
            transcriptStore
          );
          return;
        } else {
          let facePositions = speakersAnalysis.time_analysis[index].face_positions;

          if (facePositions.length > 1) {
            facePositions = facePositions.filter(facePosition =>
              speakersAnalysis.speaker_mapping?.[currentSpeaker?.id]?.includes(facePosition.face_id)
            );
          }
          drawSpeakerNameTitlePillForUpload(
            staticCanvasContext,
            mainPlayer,
            showCaption,
            staticSizeConfig,
            { id: clipId, asset_metadata: clipMetadata },
            transcriptStore,
            facePositions
          );
          return;
        }
      } else if (clipLayout === 'GRID') {
        if (index === -1) {
          drawSpeakerNameTitlePillForGridRecording(
            staticCanvasContext,
            secondaryPlayers,
            showCaption,
            staticSizeConfig,
            clipLayout,
            clipSize,
            { asset_metadata: clipMetadata },
            clipMetadata.visible_speakers
          );
          return;
        } else {
          drawSpeakerNameTitlePillForUpload(
            staticCanvasContext,
            mainPlayer,
            showCaption,
            staticSizeConfig,
            { id: clipId, asset_metadata: clipMetadata },
            transcriptStore,
            getFacePositionsForGrid(index, speakersAnalysis)
          );
          return;
        }
      }
    }
  }, [
    currentSpeaker,
    secondaryPlayers,
    clipLayout,
    clipSize,
    showSpeakerLabels,
    showCaption,
    speakersAnalysis,
    transcriptStore.speakersWithDetails,
    clipMetadata.magicLayout?.textColor,
    clipMetadata.magicLayout?.backgroundColor,
    clipMetadata.visible_speakers,
    mainPlayer
  ]);

  return (
    <canvas
      id="static-canvas"
      ref={staticCanvasRef}
      className={`absolute top-0 h-full w-full ${showSpeakerLabels ? 'block' : 'hidden'}`}
    />
  );
}
