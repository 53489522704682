import React, { useMemo } from 'react';
import { convertSecondsToHoursAndMinutes, formatTime } from './utils';
import classes from './FreeTrialUpgradeNag.module.scss';

function ProgressRing({
  videoUploadLeft,
  totalVideoUpload
}: {
  videoUploadLeft: number;
  totalVideoUpload: number;
}): JSX.Element {
  const { hours, minutes } = useMemo(() => {
    return videoUploadLeft > 0 ? convertSecondsToHoursAndMinutes(videoUploadLeft) : { hours: 0, minutes: 0 };
  }, [videoUploadLeft]);

  const timeLeft = useMemo(() => {
    return formatTime(hours, minutes);
  }, [hours, minutes]);

  return (
    <div className="group relative my-auto mr-0.5 flex items-center space-x-1.5 rounded-full py-1 pl-1 pr-3">
      <div className="relative h-4 w-4">
        <svg className="h-full w-full" viewBox="0 0 100 100">
          <circle
            className="stroke-current text-slate-300"
            strokeWidth="13"
            cx="50"
            cy="50"
            r="40"
            fill="transparent"
          ></circle>
          <circle
            className={`${classes['progress-ring__circle']} stroke-current text-[#5449D9]`}
            strokeWidth="13"
            strokeLinecap="round"
            cx="50"
            cy="50"
            r="40"
            fill="transparent"
            strokeDashoffset={`calc(400 - (400 * 60 * ${videoUploadLeft / totalVideoUpload}) / 100)`}
          ></circle>
        </svg>
      </div>
      <div>
        <div className="text-xs font-medium text-[#5449D9]">{timeLeft}</div>
      </div>
    </div>
  );
}

export default ProgressRing;
