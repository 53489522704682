import { memo } from 'react';
import PlaybackControls from './PlaybackControls';
import PlayerToggle from './PlayerToggle';
import usePlayerControls from './usePlayerControls';
import Timeline from '@/components/molecules/Timeline';
import { Clip } from '@/domains/asset';

function PlayerControls({ clips }: { clips: Clip[] }) {
  const { isTimelineCollapsed } = usePlayerControls();

  return (
    <div className="w-full rounded-2xl bg-white p-3 shadow-lg">
      <div className="flex justify-between px-4">
        <PlaybackControls />
        <PlayerToggle />
      </div>
      <Timeline clips={clips} visible={!isTimelineCollapsed} />
    </div>
  );
}

export default memo(PlayerControls);
