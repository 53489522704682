import { memo } from 'react';
import { ContainerTimelineElement, TextTimelineElement, TimelineElementType } from '../types';
import TextElement from './TextElement';

const ContainerElement = ({ element }: { element: ContainerTimelineElement }) => {
  const { style, elements } = element;

  return (
    <div style={style}>
      {elements?.map((child, index) => {
        if (child.type === TimelineElementType.TEXT && child.content) {
          return <TextElement element={child as TextTimelineElement} key={index} />;
        }

        return null;
      })}
    </div>
  );
};

export default memo(ContainerElement);
