import { Fragment, useState } from 'react';
import Loader from '@/components/atoms/Loader';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { getSpeakerImage } from '@/libs/utils';

export default function AudiogramImage({ activeSpeaker }: { activeSpeaker: SpeakerWithDetails }) {
  const [showLoader, setShowLoader] = useState(true);
  return (
    <Fragment>
      <img
        className="h-full w-full object-cover"
        hidden={showLoader}
        src={getSpeakerImage(activeSpeaker.profile_picture_url)}
        alt={activeSpeaker.first_name}
        onLoad={() => setShowLoader(false)}
      />
      {showLoader && (
        <div className="absolute inset-1/2">
          <Loader />
        </div>
      )}
    </Fragment>
  );
}
