import { SpeakerSegmentContextType } from '@/context/SpeakerSegmentContext/SpeakerSegmentContextTypes';
import { TranscriptContextData } from '@/context/TranscriptContext/TranscriptContextTypes';

export const defaultSharedAPIStore = {
  transcriptStore: {} as TranscriptContextData,
  speakerSegmentStore: {} as SpeakerSegmentContextType,
  mainPlayerRef: null,
  speakerMap: {},
  speakerImageMap: {},
  introPlayer: null,
  outroPlayer: null
};
