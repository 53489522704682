import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { IconArrowLeft } from '@tabler/icons-react';
import { useAppContext } from '@/context/AppContext/AppContext';

export default function SessionDetailBackButton({ disabled = false }: { disabled?: boolean }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { adminAppRouter } = useAppContext();

  const handleOnClick = useCallback(() => {
    const contentLabLoadedFrom = sessionStorage.getItem('contentLabLoadedFrom');

    if (contentLabLoadedFrom) {
      adminAppRouter.replace({ path: contentLabLoadedFrom });
    } else {
      navigate('/');
    }
  }, [navigate]);

  if (location.pathname.includes('/import')) {
    return (
      <button
        data-testid="session-detail-back-button"
        onClick={handleOnClick}
        disabled={disabled}
        className="pointer-events-auto flex h-10 w-[52px] items-center justify-center rounded-lg border !border-slate-200 bg-white px-3 py-2 text-xs font-medium disabled:pointer-events-none disabled:bg-slate-100 disabled:text-slate-400"
      >
        <IconArrowLeft className="h-5 w-5" />
      </button>
    );
  }

  return (
    <button
      data-testid="session-detail-back-button"
      disabled={disabled}
      onClick={handleOnClick}
      className="group pointer-events-auto flex flex-col items-center pb-4 text-slate-600 hover:text-black disabled:pointer-events-none disabled:bg-slate-100 disabled:text-slate-400"
    >
      <div className="flex h-9 w-9 items-center justify-center rounded-xl group-hover:bg-slate-200">
        <IconArrowLeft className="h-5 w-5" />
      </div>
    </button>
  );
}
