import { registerInterceptor, FetcherInterceptor } from '@goldcast/api/backend';
import { registerInterceptor as registerVideoInterceptor } from '@goldcast/api/content';
import { getEnvConfig } from '@/constants';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';

type Register = typeof registerInterceptor;

type CustomError = Error & { statusCode?: number; apiErrorCode?: string };

function registerBeforeInterceptor(register: Register, baseUrl: string, store) {
  register(FetcherInterceptor.PRE_PROCESS_OPTIONS, ({ options }) => {
    const jwtToken = store.getters?.['user/getJwtAccessToken'];
    window.accessToken = jwtToken;
    options.headers = {
      ...options.headers,
      ...(!!jwtToken && { Authorization: `Bearer ${jwtToken}` })
    };
    options.url = options.url.startsWith(baseUrl || '') ? options.url : `${baseUrl}${options.url}`;
    return options;
  });
}

function registerErrorLogger(register: Register) {
  register(FetcherInterceptor.ON_ERROR, ({ error }) => {
    console.error(error as Error);
  });
}

function registerAfterInterceptor(register: Register, adminAppStore) {
  register(FetcherInterceptor.POST_PROCESS_RESPONSE, async ({ response, options, retryFn }) => {
    if (response?.status === 401 && !options?.unAuthTries) {
      return adminAppStore.dispatch('user/getNewAccessToken', null, { root: true }).then(() => {
        const newJwtToken = adminAppStore.getters?.['user/getJwtAccessToken'];
        window.accessToken = newJwtToken;
        options.unAuthTries = 1;
        return retryFn?.(options);
      });
    } else if (response?.status === 401 && options.unAuthTries && options.unAuthTries >= 1) {
      const error: CustomError = new Error(JSON.stringify(await response.json()));
      error.name = '';
      error.statusCode = 401;
      EventBus.dispatch(CustomEvents.SignOut);
      throw error;
    } else if (response?.status && response?.status >= 500) {
      let error: null | Error = null;
      try {
        error = new Error(JSON.stringify(await response.json()));
      } catch {
        error = new Error('Internal server error');
      }
      error.name = '';
      (error as any).statusCode = response.status;
      throw error;
    } else if (response && response?.status >= 400) {
      let error: null | CustomError = null;

      const data = await response?.json();
      error = new Error(data?.message ?? data?.detail ?? JSON.stringify(data || {}));
      error.name = '';
      error.statusCode = response.status;
      if (data && data['code']) {
        error.apiErrorCode = data['code'];
      }
      throw error;
    }
    return response;
  });
}

function initializeBackendFetcher(adminAppStore): void {
  const baseUrl = getEnvConfig('APP_BACKEND_SERVER');
  registerBeforeInterceptor(registerInterceptor, baseUrl, adminAppStore);
  registerErrorLogger(registerInterceptor);
  registerAfterInterceptor(registerInterceptor, adminAppStore);
}

function initializeVideoBackendFetcher(adminAppStore) {
  const baseUrl = getEnvConfig('API_URL');
  registerBeforeInterceptor(registerVideoInterceptor, baseUrl, adminAppStore);
  registerErrorLogger(registerVideoInterceptor);
  registerAfterInterceptor(registerVideoInterceptor, adminAppStore);
}

export function initializeFetchers(adminAppStore): void {
  initializeBackendFetcher(adminAppStore);
  initializeVideoBackendFetcher(adminAppStore);
}
