import * as filestack from 'filestack-js';
import * as htmlToImage from 'html-to-image';
import { CustomEvents } from './eventBus/constants';
import EventBus from './eventBus/eventBus';
import { Clip, ClipMetadata } from '@/domains/asset';
import { getEnvConfig } from '@/constants';
import { currentClip } from '@/stores/clip';
import { getClipPlayerElementId } from '@/Pages/Clip/ClipPlayer/ClipPlayerUtils';

const UPLOAD_CONFIG: filestack.UploadOptions = {
  retry: 3,
  timeout: 60000
};

export function generateThumbnail(clipId: string, retryCount = 0) {
  function retry() {
    if (retryCount < 5) {
      setTimeout(() => generateThumbnail(clipId, retryCount + 1), 1000);
    } else {
      console.error('Failed to generate clip thumbnail');
    }
  }

  const clipData = currentClip.getSnapshot()[clipId];
  const playerElementId = getClipPlayerElementId(clipId);
  const playerContainer = document.getElementById(playerElementId);
  const loader = playerContainer?.querySelector('#composite-player-loader');
  const captionsMenu = playerContainer?.querySelector('#captions-menu');
  const videoCanvas = playerContainer?.querySelector('#video-canvas');

  if (!!loader || !!captionsMenu || !videoCanvas) {
    retry();
    return;
  }

  const thumbnailContainer = getThumbnailContainer(clipData.asset_metadata);
  if (!thumbnailContainer) {
    retry();
    return;
  }

  htmlToImage
    .toPng(thumbnailContainer, {
      cacheBust: false,
      skipFonts: true,
      includeQueryParams: true,
      filter: domNode => {
        const thumbnailSkipClass = domNode.dataset?.thumbnailSkipClass;
        if (!!thumbnailSkipClass) {
          domNode.classList.remove(thumbnailSkipClass);
          setTimeout(() => {
            domNode.classList.add(thumbnailSkipClass);
          }, 50);
        }
        return true;
      }
    })
    .then(dataUrl => storeThumbnail(clipData, dataUrl))
    .then(() => {
      EventBus.dispatch(CustomEvents.ThumbnailGenerated, { clip: clipData });
    })
    .catch(err => {
      window.analytics?.track('ContentLabEvent', {
        name: 'GenerateThumbnailFailed',
        error: err
      });
    });
}

function getThumbnailContainer(clipMetadata: ClipMetadata): HTMLElement | null {
  const visionAnalysisProcessingOverlay = document.getElementById('clip-vision-analysis-processing-overlay');
  if (!!visionAnalysisProcessingOverlay) return visionAnalysisProcessingOverlay;

  const visionAnalysisFailedOverlay = document.getElementById('clip-vision-analysis-failed-overlay');
  if (!!visionAnalysisFailedOverlay) return visionAnalysisFailedOverlay;

  if (!!clipMetadata.intro) return document.getElementById('player-intro-section');
  return document.getElementById('player-container');
}

function storeThumbnail(clip: Clip, canvasUrl: string) {
  const filePath = `${getEnvConfig('THUMBNAIL_ROOT_FOLDER')}${clip.event}/${clip.broadcast}/thumbnails/${
    clip.asset_metadata.id
  }.png`;
  const storeConfig: filestack.StoreUploadOptions = {
    location: 's3',
    container: getEnvConfig('S3_STATIC_ASSETS_BUCKET'),
    path: `/${filePath}`,
    region: getEnvConfig('AWS_STORE_REGION')
  };
  const client = filestack.init(getEnvConfig('FILESTACK_API_KEY'));
  return client.upload(canvasUrl, UPLOAD_CONFIG, storeConfig);
}
