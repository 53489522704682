export const BROADCAST_UNAVAILABLE_REASONS = {
  LANGUAGE_NOT_SUPPORTED: 'The language of this session is not supported, yet.',
  TRANSCRIPT_NOT_AVAILABLE: 'Something went wrong on our end.',
  INVALID_AUDIO: `Upload failed. Looks like the audio's unclear. Please upload a file with audible speech.`,
  NO_AUDIOSTREAM: `Upload failed. It looks like this video doesn't have any audio. Please upload a file with sound.`
};

export enum PreviewImageSize {
  Small = 'small',
  Large = 'large'
}

export const PREVIEW_IMAGE_SIZES: Record<PreviewImageSize, string> = {
  [PreviewImageSize.Small]: '320x180',
  [PreviewImageSize.Large]: '1280x720'
};

export const SESSION_PREVIEW_STATUS_MAP = {
  PROCESSING: 'Processing',
  UPLOADING: 'Uploading',
  GENERATING: 'Generating Clips'
};
