import { MediaTypeEnum } from '@goldcast/api/content';
import { SidebarContext } from '../SideBar/SideBarUtils';
import DefaultPreview from './DefaultPreview';
import SpeakerGridTile from './SpeakerGridTile';
import ActiveSpeakerTile from './ActiveSpeakerTile';
import Audiogram from './Audiogram/Audiogram';
import { CLIP_UNAVAILABLE_MESSAGES, ClipLayoutStatus, DEFAULT_REASON } from './constants';
import type { Clip, LayoutType } from '@/domains/asset';
import { FeatureFlagKeys } from '@/services/featureFlag';
import { getLayoutAvailabilityStatus } from '@/context/ClipsContext/ClipsContextUtils';
import featureFlagStore from '@/stores/featureFlagStore';

export interface MagicLayoutTileType {
  tileTitle: string;
  tileContent: (any) => React.ReactElement;
  tileContentProps?: Record<string, unknown>;
  sideBarContextOnSelect: SidebarContext;
  magicLayoutType: LayoutType;
  visible: boolean;
}

export const tiles: Record<MediaTypeEnum, MagicLayoutTileType[]> = {
  VIDEO: [
    {
      tileTitle: 'Default',
      tileContent: DefaultPreview,
      sideBarContextOnSelect: SidebarContext.Default,
      magicLayoutType: 'DEFAULT',
      visible: true
    },
    {
      tileTitle: 'Audiogram',
      tileContent: Audiogram,
      tileContentProps: { previewOnly: true, isMinimized: true },
      sideBarContextOnSelect: SidebarContext.Audiogram,
      magicLayoutType: 'AUDIOGRAM',
      visible: true
    },
    {
      tileTitle: 'Active Speaker',
      tileContent: ActiveSpeakerTile,
      sideBarContextOnSelect: SidebarContext.ActiveSpeaker,
      magicLayoutType: 'SPEAKER',
      visible: true
    },
    {
      tileTitle: 'Speaker Grid',
      tileContent: SpeakerGridTile,
      sideBarContextOnSelect: SidebarContext.SpeakerGrid,
      magicLayoutType: 'GRID',
      visible: true
    }
  ],
  AUDIO: [
    {
      tileTitle: 'Audiogram',
      tileContent: Audiogram,
      tileContentProps: { previewOnly: true, isMinimized: true },
      sideBarContextOnSelect: SidebarContext.Audiogram,
      magicLayoutType: 'AUDIOGRAM',
      visible: true
    }
  ]
};

function isSpeakerLayoutTile(layout: LayoutType): boolean {
  return layout === 'GRID' || layout === 'SPEAKER';
}

export function getClipProcessingState(layout: LayoutType, clip: Clip): boolean {
  const isFacialRecABIncrementEnabled = featureFlagStore.getSnapshot()[FeatureFlagKeys.Use_CL_Facial_Rec_Increment_AB];

  if (isFacialRecABIncrementEnabled) {
    return getLayoutAvailabilityStatus(layout, clip.layout_status) === 'PROCESSING';
  }

  return isSpeakerLayoutTile(layout) && clip.layout_status?.[layout].status === ClipLayoutStatus.Processing;
}

export function getClipUnavailableState(layout: LayoutType, clip: Clip): [boolean, string, string] {
  if (!isSpeakerLayoutTile(layout)) return [false, '', ''];
  const { status, reason } = clip.layout_status?.[layout];

  if ([ClipLayoutStatus.Failed, ClipLayoutStatus.Unavailable].includes(status)) {
    return [true, CLIP_UNAVAILABLE_MESSAGES[layout]?.[reason] || DEFAULT_REASON, reason];
  }
  return [false, '', reason];
}
