import { memo, useCallback, useMemo } from 'react';
import { IconCheck } from '@tabler/icons-react';
import { useVideoImportContext } from '../../VideoImportContext';
import TemplateSelectListItemThumbnail from './TemplateSelectListItemThumbnail';
import { TEMPLATE_SELECT_MAX_COUNT } from './constants';
import { classnames } from '@/libs/utils';
import { PreviewTemplate } from '@/Pages/Clip/SideBar/types';

const TemplateSelectListItem = ({ template }: { template: PreviewTemplate }) => {
  const { selectedBrandTemplateIds, setSelectedPresetTemplateIds, setSelectedSavedTemplateIds } =
    useVideoImportContext();

  const isSelected = useMemo(() => {
    return (
      selectedBrandTemplateIds.preset.includes(template.id as string) ||
      selectedBrandTemplateIds.saved.includes(template.id as string)
    );
  }, [selectedBrandTemplateIds, template.id]);

  const selectedTemplatesCount = useMemo(() => {
    return selectedBrandTemplateIds.preset.length + selectedBrandTemplateIds.saved.length;
  }, [selectedBrandTemplateIds.preset.length, selectedBrandTemplateIds.saved.length]);

  const isDisabled = useMemo(() => {
    return selectedTemplatesCount >= 5 && !isSelected;
  }, [isSelected, selectedTemplatesCount]);

  const unselectTemplate = useCallback(
    (template: PreviewTemplate) => {
      if (template.isDefault) {
        setSelectedPresetTemplateIds(selectedBrandTemplateIds.preset.filter(id => id !== template.id));
      } else {
        setSelectedSavedTemplateIds(selectedBrandTemplateIds.saved.filter(id => id !== template.id));
      }
    },
    [
      selectedBrandTemplateIds.preset,
      selectedBrandTemplateIds.saved,
      setSelectedPresetTemplateIds,
      setSelectedSavedTemplateIds
    ]
  );

  const selectTemplate = useCallback(
    (template: PreviewTemplate) => {
      if (selectedTemplatesCount >= TEMPLATE_SELECT_MAX_COUNT) {
        return;
      }

      if (template.isDefault) {
        setSelectedPresetTemplateIds([...selectedBrandTemplateIds.preset, template.id as string]);
      } else {
        setSelectedSavedTemplateIds([...selectedBrandTemplateIds.saved, template.id as string]);
      }
    },
    [
      selectedBrandTemplateIds.preset,
      selectedBrandTemplateIds.saved,
      selectedTemplatesCount,
      setSelectedPresetTemplateIds,
      setSelectedSavedTemplateIds
    ]
  );

  const onClickTemplate = useCallback(() => {
    if (isSelected) {
      unselectTemplate(template);
    } else if (selectedTemplatesCount < TEMPLATE_SELECT_MAX_COUNT) {
      selectTemplate(template);
    }
  }, [isSelected, selectedTemplatesCount, template, unselectTemplate, selectTemplate]);

  return (
    <div className="group relative">
      <button onClick={onClickTemplate} key={template.id} className="w-full" disabled={isDisabled}>
        <div className="absolute left-3 top-3">
          <div
            className={classnames(
              isSelected ? 'border-slate-400 opacity-100' : 'border-slate-300 group-hover:opacity-100',
              isDisabled ? 'hidden' : '',
              'z-20 flex h-5 w-5 items-center justify-center rounded-sm border bg-white opacity-0 shadow-sm duration-150 ease-in-out'
            )}
          >
            {isSelected && <IconCheck stroke={2.5} size={15} />}
          </div>
        </div>
        <div
          className={classnames(
            'flex aspect-[4/3] w-full shrink-0 items-center justify-center rounded-lg bg-slate-100 p-7 pb-4 ring-2 transition-all duration-150 ease-in-out focus:ring-2',
            isSelected ? 'ring-2 ring-black' : 'ring-transparent group-hover:bg-slate-200',
            isDisabled ? 'cursor-default opacity-50 ring-transparent saturate-[0.5]' : ''
          )}
        >
          <TemplateSelectListItemThumbnail template={template} />
        </div>
        <div className="w-full text-left">
          <div className="mt-2 text-sm font-medium text-slate-600">{template.title}</div>
        </div>
      </button>
    </div>
  );
};

export default memo(TemplateSelectListItem);
