import { Fragment, memo, useCallback, useEffect, useMemo, useState, useSyncExternalStore } from 'react';
import { Transition } from '@headlessui/react';
import { useLocation } from 'react-router-dom';
import { userPromptConstantOverridesGetPromptConstantsRetrieve } from '@goldcast/api/content';
import AIDebugToolsInternalMetadata from './AIDebugToolsInternalMetadata';
import AIDebugToolsOverrides, { PromptConstants } from './AIDebugToolsOverrides';
import { addPromptOverride, promptOverrides, internalMetadata } from '@/stores/debugToolsStores';
import IconButton from '@/components/atoms/Button/IconButton';
import Loader from '@/components/atoms/Loader';
import { classnames } from '@/libs/utils';

const AIDebugTools = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [promptConstants, setPromptConstants] = useState<PromptConstants>({});
  const promptOverridesStore = useSyncExternalStore(promptOverrides.subscribe, promptOverrides.getSnapshot);
  const internalMetadataStore = useSyncExternalStore(internalMetadata.subscribe, internalMetadata.getSnapshot);

  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const currentWorkflow = useMemo(() => {
    const pathName = location.pathname.split('/')[3];

    if (pathName === 'clips' || pathName === 'recordings') return 'text_generation,multimodal_clip_generation';
    if (pathName === 'social' || pathName === 'blog') return 'text_generation,multimodal_clip_generation';
    if (pathName === 'email' || pathName === 'custom' || pathName === 'takeaways') return 'text_generation';

    return '';
  }, [location.pathname]);

  useEffect(() => {
    if (!currentWorkflow) return;

    setLoading(true);
    setError(null);
    userPromptConstantOverridesGetPromptConstantsRetrieve({
      queryParams: {
        workflows: currentWorkflow as any
      }
    })
      .then(response => {
        const res = response as { [key: string]: string };

        const promptConstants = Object.entries(res).reduce((acc, [key, value]) => {
          const transformedValue = typeof value === 'object' ? JSON.stringify(value) : value;
          acc[key] = {
            initialValue: transformedValue,
            overriddenValue: promptOverridesStore[key] || transformedValue,
            type: typeof transformedValue
          };
          return acc;
        }, {} as PromptConstants);

        setPromptConstants(promptConstants);
      })
      .catch(error => {
        setError(JSON.stringify(error));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [location.pathname, currentWorkflow]);

  const handleUpdatePromptConstant = useCallback((value: string, key: string) => {
    addPromptOverride(key, value);
    setPromptConstants(prev => ({
      ...prev,
      [key]: {
        ...prev[key],
        overriddenValue: value
      }
    }));
  }, []);

  if (!currentWorkflow) return null;

  return (
    <>
      <button
        className={classnames('h-full text-white', {
          'bg-red-800 hover:bg-red-700': error,
          'bg-gray-800 hover:bg-gray-700': loading,
          'bg-green-800 hover:bg-green-700': !error && !loading
        })}
        data-testid="ai-debug-tools-button"
        disabled={loading}
        onClick={toggleOpen}
      >
        <div className="text-xs [writing-mode:vertical-lr]">AI Overrides - {currentWorkflow}</div>
      </button>
      <Transition
        appear
        show={isOpen}
        as={Fragment}
        enter="transition ease duration-100 transform"
        enterFrom="opacity-0 translate-x-200"
        enterTo="opacity-100 translate-x-0"
        leave="transition ease duration-100 transform"
        leaveFrom="opacity-100 translate-x-0"
        leaveTo="opacity-0 translate-x-200"
      >
        <div className="absolute z-[999] m-2 ml-6 mr-auto flex h-full w-[28rem] max-w-xl flex-col overflow-y-hidden rounded-md border bg-white shadow-[0_0_5px_5px_rgba(0,0,0,0.05)]">
          <div className="flex items-center justify-between border-b p-2">
            <div className="ml-2 font-mono text-sm text-slate-700">AI Debugging</div>
            <IconButton icon="IconX" onClick={toggleOpen} trackingId="ai-debug-tools-close-button" variation="text" />
          </div>
          <div className="h-full overflow-hidden overflow-y-scroll bg-slate-50 pb-2">
            {loading ? (
              <div className="flex h-full w-full items-center justify-center">
                <Loader />
              </div>
            ) : error ? (
              <div className="flex h-full w-full items-center justify-center">
                <div className="p-2 text-red-500">{error}</div>
              </div>
            ) : promptConstants && Object.keys(promptConstants).length > 0 ? (
              <>
                <AIDebugToolsInternalMetadata metadata={internalMetadataStore} />
                <AIDebugToolsOverrides
                  promptConstants={promptConstants}
                  handleUpdatePromptConstant={handleUpdatePromptConstant}
                />
              </>
            ) : (
              <div className="p-2 text-center">No prompt constants found</div>
            )}
          </div>
        </div>
      </Transition>
      {isOpen && <div className="fixed inset-0 z-[998] bg-black opacity-50" onClick={toggleOpen} />}
    </>
  );
};

export default memo(AIDebugTools);
