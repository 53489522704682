import { memo, useCallback, useState, useMemo } from 'react';
import { VoiceProfile } from '@goldcast/api/content';
import { Transition } from '@headlessui/react';
import { tones } from '../constants';
import { SelectedVoiceProfile, TextSelectionType } from '../types';
import IconButton from '@/components/atoms/Button/IconButton';
import useVoiceProfile from '@/hooks/useVoiceProfile';
import { VoiceProfileSteps } from '@/Pages/BrandKit/components/BrandTone/constants';
import { updateActiveStep } from '@/stores/voiceProfile';
import TooltipV2 from '@/components/TooltipV2';
import Icon from '@/components/atoms/Icon';
import { useAppContext } from '@/context/AppContext/AppContext';
import { getVoiceProfileLimitMessage } from '@/Pages/BrandKit/components/BrandTone/utils';

function ChangeVoice({
  onSelectedVoiceProfile,
  postType,
  isRewriting
}: {
  onSelectedVoiceProfile: ({ value, type }: SelectedVoiceProfile) => void;
  postType: string;
  isRewriting: boolean;
}): JSX.Element {
  const { adminAppStore } = useAppContext();
  const voiceProfileCount = adminAppStore.getters?.['orgPricingConfig/getVoiceProfileCount'];

  const { voiceProfiles, canCreateVoiceProfile } = useVoiceProfile();
  const [isOpen, setIsOpen] = useState(false);

  const voiceProfileLimitMessage = useMemo(() => getVoiceProfileLimitMessage(voiceProfileCount), [voiceProfileCount]);

  const toggleChangeVoice = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  const handleChangeVoice = useCallback(() => {
    toggleChangeVoice();
    if (!canCreateVoiceProfile) {
      updateActiveStep(VoiceProfileSteps.LimitReached);
      return;
    }
    updateActiveStep(VoiceProfileSteps.CreateVoiceProfile);
  }, [canCreateVoiceProfile]);

  const handleSelectedVoiceProfile = useCallback(
    ({ value, type, isActive }: SelectedVoiceProfile) => {
      if (type === 'custom' && !isActive) {
        updateActiveStep(VoiceProfileSteps.LimitReached);
        toggleChangeVoice();
        return;
      }
      onSelectedVoiceProfile({ value, type });
      toggleChangeVoice();
    },
    [onSelectedVoiceProfile, toggleChangeVoice]
  );

  const closeChangeVoicePopup = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <div>
      <IconButton
        icon="IconEditCircle"
        content="Change Voice"
        buttonClassName="h-10 px-6 py-2 hover:!bg-slate-100 hover:!text-slate-950 !ring-transparent hover:!ring-slate-100"
        trackingId="change-voice-button"
        disabled={isRewriting}
        onClick={toggleChangeVoice}
      />

      {isOpen && <div className="fixed inset-0" onClick={closeChangeVoicePopup} />}
      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0 origin-bottom-right"
        enterTo="transform scale-100 opacity-100 origin-bottom-right"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100 origin-bottom-right"
        leaveTo="transform scale-95 opacity-0 origin-bottom-right"
      >
        <div className="absolute top-full z-10 mt-2 w-72 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="h-96 overflow-auto p-1">
            <div className="pb-2 pl-4 pr-2 pt-2 text-sm text-slate-900">
              <span>Select a voice profile to create a new {postType.toLocaleLowerCase()} post</span>
            </div>
            <div className="flex items-center justify-between pb-2 pl-4 pr-2 pt-2 text-xs text-slate-600">
              <span>Saved Voice Profiles</span>
              <IconButton
                icon="IconPlus"
                content="New Profile"
                buttonClassName="h-6 px-2 !text-2xs hover:!bg-slate-100 hover:!text-slate-950 !ring-transparent hover:!ring-slate-100"
                iconClassName="h-4 w-4"
                trackingId="change-voice-button"
                onClick={handleChangeVoice}
              />
            </div>
            <>
              {voiceProfiles.length === 0 ? (
                <p className="w-full px-4 py-2 text-xs italic text-gray-500">No saved profiles yet</p>
              ) : (
                <>
                  {voiceProfiles.map((voiceProfile: VoiceProfile) => (
                    <button
                      key={voiceProfile.id}
                      onClick={() =>
                        handleSelectedVoiceProfile({
                          value: voiceProfile.id,
                          type: 'custom',
                          isActive: voiceProfile?.is_active
                        })
                      }
                      className="flex w-full items-center space-x-2 rounded-md px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    >
                      <div className="flex w-full items-center text-sm">
                        {!voiceProfile.is_active && (
                          <TooltipV2
                            tooltipId={`voice-profile-${voiceProfile.id}`}
                            tooltipContent={voiceProfileLimitMessage}
                            tooltipElement={<Icon name="IconLockSquareRoundedFilled" className="mr-2 text-slate-400" />}
                          />
                        )}
                        <span>{voiceProfile.name}</span>
                      </div>
                    </button>
                  ))}
                </>
              )}
            </>
            <div className="mt-2 pb-2 pl-4 pt-3 text-xs text-slate-600">
              <span>Default Voice Profiles</span>
            </div>
            <>
              {tones.map((tone: TextSelectionType) => (
                <button
                  key={tone.value}
                  onClick={() => handleSelectedVoiceProfile({ value: tone.value, type: 'default' })}
                  className="flex w-full items-center space-x-2 rounded-md px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                >
                  <div className="text-sm">{tone.icon}</div>
                  <div className="text-sm">{tone.value}</div>
                </button>
              ))}
            </>
          </div>
        </div>
      </Transition>
    </div>
  );
}

export default memo(ChangeVoice);
