import React, { memo, useCallback, useMemo, useState } from 'react';
import * as filestack from 'filestack-js';
import { v6 as uuidv6 } from 'uuid';
import {
  BRAND_GUIDELINE_CONFIG,
  UPLOAD_CONFIG,
  BRAND_TONE_GUIDELINE_FILE_SIZE,
  MAX_SAMPLE_COPY_LENGTH,
  BRAND_TONE_FILE_LIMIT,
  VoiceProfileSteps,
  MIN_SAMPLE_COPY_LENGTH
} from '../constants';
import { getContentStoreConfig } from '../utils';
import { NewVoiceProfileProps, BrandToneFile } from '../types';
import SampleCopySection from './SampleCopySection';
import UploadBrandGuideline from './UploadBrandGuideline';
import ImportedFileItem from './ImportedFileItem';
import VoiceProfileAnalyzer from './VoiceProfileAnalyzer';
import ContentDialog from '@/Pages/Sessions/uiComponents/ContentDialog/ContentDialog';
import Button from '@/components/atoms/Button/Button';
import Icon from '@/components/atoms/Icon';
import { getEnvConfig } from '@/constants';
import { classnames } from '@/libs/utils';
import { BRAND_KIT_PATH } from '@/Pages/BrandKit/constants';
import { currentUser } from '@/stores/user';
import { showErrorToast } from '@/libs/toast/toast';
import { useAppContext } from '@/context/AppContext/AppContext';
import { updateActiveStep, analyseVoiceProfile } from '@/stores/voiceProfile';

const NewVoiceProfile = ({ isOpen, closeDialog }: NewVoiceProfileProps) => {
  const [selectedSection, setSelectedSection] = useState<string>('');
  const [sampleCopy, setSampleCopy] = useState<string>('');
  const [importedFileDetails, setImportedFileDetails] = useState<BrandToneFile>({
    fileId: '',
    fileName: '',
    fileType: ''
  });
  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);
  const [isAnalyzing, setIsAnalyzing] = useState<boolean>(false);

  const { logger } = useAppContext();

  const client = filestack.init(getEnvConfig('FILESTACK_API_KEY'));
  const organization = currentUser.getSnapshot()?.organization;
  const baseFilePath = useMemo(() => `${organization}/brand_tone_assets`, [organization]);

  const handleGuidelineSelection = useCallback((key: string) => setSelectedSection(key), []);

  const handleNext = useCallback(() => {
    if (!organization) {
      return;
    }

    if (!importedFileDetails.fileId) {
      updateActiveStep(VoiceProfileSteps.TuneVoiceProfile);
      return;
    }

    const documentUploadPath = `${importedFileDetails.fileId}.${importedFileDetails.fileType}`;
    const requestBody = {
      document_upload_paths: [documentUploadPath],
      organization
    };

    setIsAnalyzing(true);

    analyseVoiceProfile(requestBody)
      .catch(err => {
        showErrorToast('Failed to create voice profile');
        logger.error('Failed to create voice profile', err);
      })
      .finally(() => {
        setIsAnalyzing(false);
      });
  }, [importedFileDetails, logger, organization]);

  const removeImportedItem = useCallback(() => {
    setImportedFileDetails({
      fileId: '',
      fileName: '',
      fileType: '',
      sampleCopy: ''
    });
  }, []);

  const onSampleCopyChange = useCallback((value: string) => {
    setSampleCopy(value);
  }, []);

  const handleSampleCopySave = useCallback(() => {
    if (sampleCopy.length > MAX_SAMPLE_COPY_LENGTH) {
      showErrorToast(`Maximum allowed characters in sample text is ${MAX_SAMPLE_COPY_LENGTH.toLocaleString()}`);
      return;
    }

    if (sampleCopy.length < MIN_SAMPLE_COPY_LENGTH) {
      showErrorToast(`Sample text should have more than ${MIN_SAMPLE_COPY_LENGTH.toLocaleString()} characters`);
      return;
    }

    const blob = new Blob([sampleCopy], { type: 'text/plain' });
    const file = new File([blob], 'sample.txt', { type: 'text/plain' });
    onFilesUpload([file], 'txt');
  }, [sampleCopy]);

  const onFilesUpload = useCallback(
    async (files: File[], fileType = 'pdf') => {
      const selectedFile = files[0];
      if (!selectedFile) {
        return;
      }

      setIsUploadingFile(true);

      if (selectedFile.size > BRAND_TONE_GUIDELINE_FILE_SIZE) {
        showErrorToast(`File size should be less than ${BRAND_TONE_FILE_LIMIT}MB`);
        setIsUploadingFile(false);
        return;
      }

      const fileId: string = uuidv6({ msecs: new Date().getTime() });
      await client.upload(selectedFile, UPLOAD_CONFIG, getContentStoreConfig(BRAND_KIT_PATH, fileId, baseFilePath));
      const fileDetails = {
        fileId,
        fileName: selectedFile.name,
        fileType,
        sampleCopy
      };
      setImportedFileDetails(fileDetails);
      setSelectedSection('');
      setSampleCopy('');
      setIsUploadingFile(false);
    },
    [client, baseFilePath]
  );

  const createManualProfile = useCallback(() => {
    updateActiveStep(VoiceProfileSteps.TuneVoiceProfile);
  }, []);

  if (isAnalyzing) {
    return <VoiceProfileAnalyzer isOpen={isAnalyzing} closeDialog={() => setIsAnalyzing(false)} />;
  }

  return (
    <ContentDialog
      isOpen={isOpen}
      size="large"
      setIsOpen={closeDialog}
      disableBackdropClose={true}
      panelClassNames="text-md"
    >
      <div className="absolute top-0 z-50 flex items-center justify-between pl-5 pt-5">
        <div className="text-2xl font-semibold">Create a New Voice Profile</div>
      </div>
      <div className="mt-7 px-5">
        <p>
          Import your brand guidelines or sample copy (like an article or blog post) and we will analyze it to build a
          voice profile for your written outputs.
        </p>
        <div className="mt-4 flex w-full items-center gap-4">
          {Object.values(BRAND_GUIDELINE_CONFIG).map(config => (
            <button
              key={config.key}
              data-testid={`brand-guideline-${config.key}`}
              className={classnames(
                'group flex w-1/2 cursor-pointer flex-col items-center justify-center rounded-lg p-4 py-7 ring-2 ring-slate-200 transition-all duration-150 ease-in-out hover:bg-slate-50 hover:ring-slate-300 focus:ring-2',
                selectedSection === config.key ? 'bg-slate-50 !ring-black' : ''
              )}
              onClick={() => handleGuidelineSelection(config.key)}
            >
              <Icon name={config.icon} className="group-hover:hidden" />
              <Icon name={config.hoverIcon} className="hidden group-hover:block" />
              <div className="mt-2 text-sm font-medium">{config.label}</div>
              <div className="mt-1 text-center text-xs text-slate-600">{config.description}</div>
            </button>
          ))}
        </div>

        {selectedSection === 'sampleCopy' && (
          <SampleCopySection sampleCopy={sampleCopy} onSampleCopyChange={onSampleCopyChange} />
        )}
        {selectedSection === 'brandGuidelines' && (
          <UploadBrandGuideline onFilesUpload={onFilesUpload} isUploading={isUploadingFile} />
        )}
        {importedFileDetails.fileName && (
          <ImportedFileItem importedFileDetails={importedFileDetails} removeImportedItem={removeImportedItem} />
        )}

        <div className="mt-6 flex justify-end space-x-2 pb-4">
          {selectedSection === 'sampleCopy' ? (
            <Button
              className="h-10 rounded-lg px-6 py-2 text-sm"
              buttonSize="large"
              variation="filled"
              trackingId="save-sample-copy-click"
              onClick={handleSampleCopySave}
              disabled={!sampleCopy || isUploadingFile}
            >
              Save
            </Button>
          ) : (
            <>
              <Button
                buttonSize="large"
                variation="outline"
                onClick={createManualProfile}
                className="h-10 rounded-lg px-6 py-2 text-sm"
                trackingId="create-profile-manual-click"
              >
                Create Manually
              </Button>
              <Button
                className="h-10 rounded-lg px-6 py-2 text-sm"
                buttonSize="large"
                variation="filled"
                trackingId="create-profile-next-click"
                disabled={isUploadingFile}
                onClick={handleNext}
              >
                Next
              </Button>
            </>
          )}
        </div>
      </div>
    </ContentDialog>
  );
};

export default memo(NewVoiceProfile);
