import { useSyncExternalStore, useMemo } from 'react';
import { VoiceProfile } from '@goldcast/api/content';
import { voiceProfileStore } from '@/stores/voiceProfile';
import { useAppContext } from '@/context/AppContext/AppContext';

export default function useVoiceProfile(): {
  voiceProfiles: VoiceProfile[];
  activeStep: number;
  canCreateVoiceProfile: boolean;
  selectedVoiceProfile: VoiceProfile | null;
} {
  const { adminAppStore } = useAppContext();
  const voiceProfileCount = adminAppStore.getters?.['orgPricingConfig/getVoiceProfileCount'];

  const voiceProfileState = useSyncExternalStore(voiceProfileStore.subscribe, voiceProfileStore.getSnapshot);
  const voiceProfiles = useMemo(() => voiceProfileState.voiceProfiles, [voiceProfileState.voiceProfiles]);
  const canCreateVoiceProfile = useMemo(
    () => voiceProfileCount === -1 || voiceProfiles.length < voiceProfileCount,
    [voiceProfileCount, voiceProfiles]
  );

  return {
    voiceProfiles,
    canCreateVoiceProfile,
    activeStep: voiceProfileState.activeStep,
    selectedVoiceProfile: voiceProfileState.selectedVoiceProfile
  };
}
