function PostOutputSkeletonLoader() {
  return (
    <div className="w-full animate-pulse px-7">
      <div className="mb-4 h-8 w-3/4 rounded bg-slate-200"></div>
      {Array(6)
        .fill(0)
        .map((_, i) => (
          <div key={i} className="py-4">
            <div className="mb-2 h-4 w-full rounded bg-slate-200"></div>
            <div className="mb-2 h-4 w-11/12 rounded bg-slate-200"></div>
            <div className="h-4 w-4/5 rounded bg-slate-200"></div>
          </div>
        ))}
    </div>
  );
}

export default PostOutputSkeletonLoader;
