import { memo } from 'react';
import { IconLoader2, IconSparkles } from '@tabler/icons-react';

function SourceListLoader() {
  return (
    <div className="flex flex-col items-center justify-center py-10 pb-14">
      <div className="relative flex items-center justify-center">
        <IconSparkles className="absolute" />
        <IconLoader2 stroke={0.5} className="h-20 w-20 animate-spin" />
      </div>
      <div className="mt-5 text-lg font-medium">Analyzing your videos</div>
      <div className="mt-2.5 w-80 text-center text-sm text-slate-600">
        We're scanning your video library to find the best moments.
      </div>
    </div>
  );
}

export default memo(SourceListLoader);
