import React, { useEffect, useState, Fragment } from 'react';
import { WordType, type Word } from '@/domains/transcript';
import { useSpeakerSegmentContext } from '@/context/SpeakerSegmentContext/SpeakerSegmentContext';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function Captions({ children, previewOnly }: { children: React.ReactElement; previewOnly?: boolean }) {
  const [words, setWords] = useState<Word[]>([]);
  const speakerSegmentContextData = useSpeakerSegmentContext();
  const { playerStore } = useClipsContext();

  useEffect(() => {
    let index = -1;
    if (!previewOnly && speakerSegmentContextData?.segmentWords[playerStore.currentSrtIndex]) {
      index = playerStore.currentSrtIndex;
    } else if (previewOnly && !words.length) {
      index = speakerSegmentContextData?.startSegment ?? -1;
    }
    const captions = speakerSegmentContextData?.srtCaptions?.[index];
    if (captions?.some(({ type }) => type === WordType.Pronunciation)) {
      setWords(captions);
    }
  }, [speakerSegmentContextData, playerStore.currentSrtIndex, previewOnly, words.length]);

  return words.map((word, index) => {
    if (word.type !== WordType.Punctuation) {
      const nextWordPunctuation = words[index + 1]?.type === WordType.Punctuation ? words[index + 1]?.content : '';
      const isActive = previewOnly
        ? index === 0
        : word.start_time <= playerStore.currentTime && word.end_time >= playerStore.currentTime;
      return (
        <Fragment key={word.index}>
          {React.cloneElement(children, {
            index,
            word,
            text: word.content + nextWordPunctuation + ' ',
            isActive
          })}
        </Fragment>
      );
    }
    return <Fragment key={word.index}></Fragment>;
  });
}
