export const sizes = {
  small: 'sm',
  base: 'md',
  large: 'lg'
};

const sides = {
  left: 'l',
  right: 'r'
};

export function getRoundedValue(size: keyof typeof sizes = 'base', side?: keyof typeof sides) {
  return 'rounded' + (side ? `-${sides[side]}` : '') + `-${sizes[size]}`;
}
