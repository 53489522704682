import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { IconVideo } from '@tabler/icons-react';
import { PREVIEW_VIDEO_POSTER } from '../../MockTemplateBackgroundTransition/constants';
import {
  TEMPLATE_SELECT_PREVIEW_MOCK_CLIP,
  TEMPLATE_SELECT_PREVIEW_FIRST_SPEAKER,
  INTERIM_THUMBNAIL_PREVIEW_WORDS
} from './constants';
import ClipInterimThumbnail from '@/Pages/Clip/ClipInterimThumbnail/ClipInterimThumbnail';
import { classnames } from '@/libs/utils';
import { PreviewTemplate } from '@/Pages/Clip/SideBar/types';

const TemplateSelectListItemThumbnail = ({ template }: { template: PreviewTemplate }) => {
  const [showThumbnail, setShowThumbnail] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowThumbnail(true);
    }, 1000);
  }, []);

  const mockImageRef = useRef<HTMLImageElement>(null);

  const onLoaded = useCallback(() => {
    setImageLoaded(true);
  }, []);

  const onError = useCallback(() => {
    setError(true);
  }, []);

  return (
    <div
      className={classnames(
        'relative inset-0 flex items-center justify-center overflow-hidden rounded-md bg-cover bg-center bg-no-repeat shadow-lg',
        {
          'aspect-square w-[62%]': template.size === 'SQUARE',
          'aspect-[9/16] w-[35%]': template.size === 'PORTRAIT',
          'aspect-video w-[85%]': template.size === 'LANDSCAPE'
        }
      )}
    >
      {showThumbnail && (
        <ClipInterimThumbnail
          clip={
            {
              ...TEMPLATE_SELECT_PREVIEW_MOCK_CLIP,
              asset_metadata: { ...template, start: 0 }
            } as any
          }
          firstSpeaker={TEMPLATE_SELECT_PREVIEW_FIRST_SPEAKER}
          imageLoaded={imageLoaded}
          clipThumbnailRef={mockImageRef}
          captions={INTERIM_THUMBNAIL_PREVIEW_WORDS}
        >
          {!error ? (
            <img
              alt="Thumbnail"
              className="h-full w-full object-cover"
              ref={mockImageRef}
              src={PREVIEW_VIDEO_POSTER}
              onLoad={onLoaded}
              onError={onError}
            />
          ) : (
            <div className="absolute inset-0 flex items-center justify-center bg-slate-200/60">
              <IconVideo className="h-5 w-5 text-slate-400" />
            </div>
          )}
        </ClipInterimThumbnail>
      )}
    </div>
  );
};

export default memo(TemplateSelectListItemThumbnail);
