import { pause, play } from '@/stores/player';

export default function useTranscriptPlayPause() {
  function onPlay() {
    play();
  }

  function onPause() {
    pause();
  }

  return {
    play: onPlay,
    pause: onPause
  };
}
