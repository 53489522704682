import { getEnvConfig } from '@/constants';
import { SizeType } from '@/domains/asset';
import { roundToNDecimalPlaces } from '@/libs/utils';

export function fetchFilmstripIndex(projectId: string, contentId: string): Promise<string[]> {
  return fetch(
    `${getEnvConfig('STATIC_ASSETS_CDN')}/content-lab/filmstrip/${projectId}/${contentId}/filmstrip_index.json`
  )
    .then(response => response.json())
    .then(data => {
      if (data?.filmstrip_file_names?.length === 0) return [];

      return data.filmstrip_file_names.map(file_name => `${getEnvConfig('STATIC_ASSETS_CDN')}/${file_name}`);
    })
    .catch(e => {
      console.error('Error fetching filmstrip index', e);
      return [];
    });
}

export function getLesserMultipleOfN(num: number, N: number): number {
  // Find the largest integer less than or equal to num
  let flooredNum = Math.floor(num);
  let remainder = flooredNum % N;
  let result = flooredNum - remainder;

  // Ensure the result is strictly lesser than the given number
  if (result >= num) {
    result -= N;
  }

  return result;
}

export function getGreaterMultipleOfN(num: number, N: number): number {
  // Find the smallest integer greater than or equal to num
  let ceiledNum = Math.ceil(num);
  let remainder = ceiledNum % N;
  let result = ceiledNum + (N - remainder);

  return result;
}

export function getAspectRatio(size: SizeType): number {
  switch (size) {
    case 'LANDSCAPE':
      return 16 / 9;
    case 'PORTRAIT':
      return 9 / 16;
    case 'SQUARE':
      return 1;
    default:
      return 1;
  }
}

export function roundToNearestHalf(num: number): number {
  return Math.round(num * 10) / 10;
}

export function roundToNearestLowerHalf(num: number): number {
  var wholePart = Math.floor(num);
  var decimalPart = num - wholePart;

  if (decimalPart < 0.5) {
    return wholePart;
  } else if (decimalPart > 0.5) {
    return wholePart + 0.5;
  }

  return wholePart;
}

export function roundToNearestHigherHalf(num: number): number {
  var wholePart = Math.floor(num);
  var decimalPart = num - wholePart;

  if (decimalPart < 0.5) {
    return wholePart + 0.5;
  } else if (decimalPart > 0.5) {
    return wholePart + 1.0;
  }

  return wholePart;
}

export function getBounds(section: HTMLElement | null): {
  clipTimelineBounds: DOMRect | undefined;
  sectionBounds: DOMRect | undefined;
} {
  // get the bounds of the parent of the filmstrip
  const clipTimeline = document.getElementById('timeline-main-boundary');
  const clipTimelineBounds = clipTimeline?.getBoundingClientRect();

  // get the bounds of the section in question
  const sectionBounds = section?.getBoundingClientRect();

  return {
    clipTimelineBounds,
    sectionBounds
  };
}

export function getNewTimeAfterMove(oldTime: number, pixelsMoved: number, singleFrameWidth: number): number {
  const pixelTime = pixelsMoved / singleFrameWidth;

  return roundToNDecimalPlaces(oldTime + pixelTime, 3);
}

export function getPositionForDuration(
  section: HTMLButtonElement | null,
  durationToDisplay: number,
  singleFrameWidth: number
): number {
  const { sectionBounds } = getBounds(section);
  if (!sectionBounds) return 0;

  const { left: sectionLeftBound, right: sectionRightBound } = sectionBounds;

  const widthForDuration = durationToDisplay * singleFrameWidth;

  // sectionRightBound - sectionLeftBound width of the section and we need to offset that for end handle position
  const positionX = sectionRightBound - sectionLeftBound - widthForDuration;

  return positionX;
}
