import React, { useState } from 'react';

export default function LabeledInput({
  value,
  onChange,
  classNames = '',
  label = '',
  isRequired = false,
  isInvalid = false,
  ...restProps
}) {
  const [inputValue, setInputValue] = useState(value);

  const handleInputChange = e => {
    setInputValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div className={classNames}>
      <div className={`mb-1 text-xs ${isInvalid ? '!text-deep-orange-600' : 'text-slate-600'}`}>
        {label}
        {isRequired ? <sup>*</sup> : ''}
      </div>
      <input
        {...restProps}
        value={inputValue}
        onChange={handleInputChange}
        className={`placeholder:text-text-slate-400 focus-ring-2 block w-full appearance-none rounded-md !border !border-solid bg-white px-3 py-2 hover:border-deep-orange-600 focus:border-deep-orange-600 focus:outline-none focus:ring-deep-orange-700 sm:text-sm ${
          isInvalid ? '!border-deep-orange-600' : 'border-slate-200'
        }`}
      />
    </div>
  );
}
