import { SavedSearches } from '@goldcast/api/content';
import createStore from './store';

export const savedAISearch = createStore<SavedSearches | null>(null);

export function setSavedAISearch(search: SavedSearches) {
  savedAISearch.set(() => search);
}

export function clearSavedAISearch() {
  savedAISearch.set(() => null);
}
