import { LandscapeSizeConfig } from './LandscapeSizeConfig';
import { SizeConfig } from './SizeConfig';
import { SquareSizeConfig } from './SquareSizeConfig';
import { PortraitSizeConfig } from './PortraitSizeConfig';
import { LayoutType, SizeType } from '@/domains/asset';

export function getSizeConfig(
  size: SizeType,
  clipId: string,
  clipLayout: LayoutType,
  devicePixelRatio?: number
): SizeConfig {
  switch (size) {
    case 'LANDSCAPE':
      return new LandscapeSizeConfig(clipId, clipLayout, devicePixelRatio);
    case 'SQUARE':
      return new SquareSizeConfig(clipId, clipLayout, devicePixelRatio);
    case 'PORTRAIT':
      return new PortraitSizeConfig(clipId, clipLayout, devicePixelRatio);
    default:
      throw new Error('Invalid video size');
  }
}
