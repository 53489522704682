import React, { useMemo, Fragment } from 'react';
import { getCaptionStyles } from './CanvasPlayerDraggableCaptions/utils';
import { getFontByLocation } from '../SideBar/FontSelector/constants';
import { INACTIVE_WORD_OPACITY } from './constants';
import { useSpeakerSegmentContext } from '@/context/SpeakerSegmentContext/SpeakerSegmentContext';
import { getCurrentClipFontName } from '@/stores/brandKit';
import useCaptionsStylesHook from '@/components/molecules/useCaptionsStylesHook';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { getColorWithOpacity } from '@/components/atoms/ColorPicker/utils';
import { CaptionStyle } from '@/domains/asset';

export default function CanvasPlayerCaptions2() {
  const { clipId, clipData, playerStore } = useClipsContext();

  const clipMetadata = useMemo(() => {
    return clipData.asset_metadata;
  }, [clipData.asset_metadata]);

  const speakerSegmentContextData = useSpeakerSegmentContext();
  const words = useMemo(() => {
    return speakerSegmentContextData?.srtCaptions?.[playerStore.currentSrtIndex] || [];
  }, [playerStore.currentSrtIndex, speakerSegmentContextData?.srtCaptions]);

  const textColor = useMemo(() => clipMetadata?.magicLayout?.textColor, [clipMetadata?.magicLayout?.textColor]);

  const fontFamilyStyle = useMemo(() => {
    const fontName = getCurrentClipFontName(clipId);
    if (fontName) return { fontFamily: fontName };
    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clipMetadata.font_location]);

  const captionsStyles = useMemo(() => {
    return getCaptionStyles(
      clipData.asset_metadata.caption_styles?.animation,
      clipData.asset_metadata.caption_styles?.scale,
      getFontByLocation(clipMetadata.font_location)
    );
  }, [
    clipData.asset_metadata.caption_styles?.animation,
    clipData.asset_metadata.caption_styles?.scale,
    clipMetadata.font_location
  ]);

  const activeTextColor = useMemo(() => {
    return clipMetadata.highlight_type !== 'none' && !!clipMetadata.text_highlight_color
      ? clipMetadata.text_highlight_color
      : clipMetadata?.magicLayout?.textColor;
  }, [clipMetadata?.magicLayout?.textColor, clipMetadata.highlight_type, clipMetadata.text_highlight_color]);

  const captionsBackgroundColor = useMemo(() => {
    return clipData.asset_metadata.caption_styles?.animation !== CaptionStyle.Background
      ? null
      : getColorWithOpacity(clipData.asset_metadata.caption_styles?.background_style_color, INACTIVE_WORD_OPACITY);
  }, [clipData.asset_metadata.caption_styles]);

  const { activeWordStyles, inactiveWordStyles } = useCaptionsStylesHook(clipData.asset_metadata);

  return (
    <div
      style={!!captionsBackgroundColor ? { backgroundColor: captionsBackgroundColor } : {}}
      className="flex flex-wrap gap-[0.15em] whitespace-normal"
    >
      {words.map(word => {
        const isActive = word.start_time <= playerStore.currentTime && word.end_time >= playerStore.currentTime;
        const wordStyles = isActive ? activeWordStyles : inactiveWordStyles;
        const animationStyles = isActive && clipData.asset_metadata.highlight_type === 'box' ? {} : captionsStyles;
        if (clipMetadata.highlight_type === 'text') {
          wordStyles.opacity = !isActive ? 0.6 : 1;
        }
        return (
          <Fragment key={word.index}>
            <span
              className="px-[1px]"
              style={{
                color: isActive ? activeTextColor : textColor,
                backgroundColor: wordStyles.backgroundColor,
                opacity: wordStyles.opacity,
                textTransform: clipMetadata.caption_styles?.caps ? 'uppercase' : 'none',
                ...fontFamilyStyle,
                ...animationStyles
              }}
            >
              {word.content.trim()}
            </span>
          </Fragment>
        );
      })}
    </div>
  );
}
