import { userSpeakerDestroy } from '@goldcast/api/content';
import { useMemo } from 'react';
import Avatar from '../Avatar/Avatar';
import IconButton from '../Button/IconButton';
import useDialog from '@/components/organisms/useDialog';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { getSpeakerImage } from '@/libs/utils';
import ConfirmationDialog from '@/components/organisms/ConfirmationDialog';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { core } from '@/stores/core';
import { getFormattedTitle } from '@/Pages/Clip/CompositePlayer/SpeakerVideoUtils';
import { setNewCacheSuffix } from '@/context/TranscriptContext/TranscriptContextUtils';

export default function SpeakerPreview({
  speaker,
  isEditing,
  canDelete = false,
  onEditStart
}: {
  speaker: SpeakerWithDetails;
  isEditing: boolean;
  canDelete?: boolean;
  onEditStart: () => void;
}) {
  const {
    isOpen: isDeleteConfirmationOpen,
    openDialog: openDeleteConfirmationDialog,
    closeDialog: closeDeleteConfirmationDialog
  } = useDialog();

  function deleteSpeaker() {
    closeDeleteConfirmationDialog();
    userSpeakerDestroy({ id: speaker.id }).then(() => {
      EventBus.dispatch(CustomEvents.ReloadTranscript);
      core.update(data => ({ ...data, content: { ...data.content, allow_reset_speakers: true } }));

      setNewCacheSuffix(Math.random().toString());
    });
  }

  function triggerConfirmationDialog(e: React.MouseEvent) {
    e.stopPropagation();
    openDeleteConfirmationDialog();
  }

  const formattedSpeakerTitle = useMemo(() => {
    return getFormattedTitle(speaker);
  }, [speaker]);

  return (
    <div
      className={`group/speaker w-full cursor-pointer rounded-lg px-1 py-3 ${
        isEditing ? 'pointer-events-none' : 'group-hover:bg-slate-50'
      }`}
      onClick={onEditStart}
    >
      <div className="flex items-center gap-2">
        <Avatar
          className="shrink-0"
          name={`${speaker.first_name ?? ''} ${speaker.last_name ?? ''}`}
          imageUrl={getSpeakerImage(speaker.profile_picture_url)}
        />
        <div className="max-w-[95%] pr-3">
          <p className="truncate text-sm">
            {speaker.first_name} {speaker.last_name}
          </p>
          <p className="flex max-w-xl items-center space-x-1 pr-4 text-xs text-slate-500">
            {(!!speaker.company || !!speaker.title) && (
              <span className={`${speaker.bgColor} h-2 w-2 shrink-0 rounded-sm`}></span>
            )}
            <span className="truncate">{formattedSpeakerTitle}</span>
          </p>
        </div>
      </div>
      <div className="absolute inset-0 mr-3 hidden items-center justify-end text-xs font-medium group-hover/speaker:flex">
        <div className="flex">
          <IconButton
            icon="IconEdit"
            content="Edit"
            variation="outline"
            size="small"
            title="Edit"
            buttonClassName={`flex w-full items-center px-2 py-1 bg-white ${canDelete && 'rounded-e-none'}`}
            trackingId="edit-speaker-button"
            onClick={onEditStart}
          />
          {canDelete && (
            <IconButton
              icon="IconTrash"
              content="Delete"
              variation="outline"
              size="small"
              title="Delete"
              trackingId="delete-speaker-button"
              buttonClassName="flex w-full items-center px-2 py-1 bg-white rounded-s-none"
              onClick={triggerConfirmationDialog}
            />
          )}
        </div>
      </div>
      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        onClose={closeDeleteConfirmationDialog}
        onConfirm={deleteSpeaker}
        title="Delete Speaker"
        content="Are you sure you want to remove this speaker?"
        confirmLabel="Yes"
      />
    </div>
  );
}
