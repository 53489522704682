import { OrganizationUsageSummary, usageSummaryRetrieve } from '@goldcast/api/content';
import createStore from './store';
import { calculateDaysLeft } from '@/components/molecules/FreeTrial/utils';

export enum PlanTypeEnum {
  TRIAL = 'CL_TRIAL',
  FREEMIUM = 'CL_FREEMIUM',
  STARTER_MONTHLY = 'CL_STARTER_MONTHLY',
  STARTER_ANNUALLY = 'CL_STARTER_ANNUALLY'
}

export type PlanType = 'CL_TRIAL' | 'CL_FREEMIUM' | 'CL_STARTER_MONTHLY' | 'CL_STARTER_ANNUALLY';

export type PlanState = {
  expired: boolean;
  daysLeft: number | null;
};

export type OrgUsageSummaryState = {
  freeTrial: { expired: boolean; daysLeft: number | null };
  currentPlan: PlanType;
  usage: {
    clips: { used: number; total: number };
    videoUpload: { used: number; total: number; count: number };
    text: { used: number };
  };
  orgId: string;
  loading: boolean;
  error: Error | null;
};

export const orgUsageSummaryState = createStore<OrgUsageSummaryState>({
  freeTrial: { expired: false, daysLeft: 0 },
  currentPlan: PlanTypeEnum.TRIAL,
  usage: {
    clips: { used: 0, total: 0 },
    videoUpload: { used: 0, total: 0, count: 0 },
    text: { used: 0 }
  },
  orgId: '',
  loading: false,
  error: null
});

export function loadOrgUsageSummary(orgId: string | null | undefined, showLoader?: boolean): Promise<void> {
  if (!orgId) {
    orgUsageSummaryState.update(data => ({ ...data, error: new Error('Organization ID is required') }));
    return Promise.resolve();
  }

  orgUsageSummaryState.update(data => ({ ...data, loading: showLoader ?? true }));

  return usageSummaryRetrieve({ id: orgId })
    .then(summary => {
      orgUsageSummaryState.update(data => ({ ...data, orgId: summary.id }));
      orgUsageSummaryState.update(data => ({ ...data, freeTrial: transformFreeTrialState(summary) }));
      orgUsageSummaryState.update(data => ({ ...data, currentPlan: summary?.plan_type }));
      orgUsageSummaryState.update(data => ({ ...data, usage: transformUsageState(summary) }));
    })
    .catch(error => {
      orgUsageSummaryState.update(data => ({ ...data, error }));
    })
    .finally(() => {
      orgUsageSummaryState.update(data => ({ ...data, loading: false }));
    });
}

function transformFreeTrialState(summary: OrganizationUsageSummary): OrgUsageSummaryState['freeTrial'] {
  const { plan_type, contract_start_date } = summary;
  if (plan_type === PlanTypeEnum.FREEMIUM) {
    if (!contract_start_date) {
      return { expired: false, daysLeft: null };
    }
    const daysLeft = calculateDaysLeft(contract_start_date);
    return { expired: false, daysLeft };
  }

  const contractDate = summary.contract_end_date ? new Date(summary.contract_end_date) : null;
  const today = new Date();

  if (!contractDate) {
    return { expired: false, daysLeft: null };
  }

  return {
    expired: contractDate < today,
    daysLeft: Math.ceil((contractDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24))
  };
}

function transformUsageState(summary: OrganizationUsageSummary): OrgUsageSummaryState['usage'] {
  return {
    clips: {
      used: summary.clips_usage?.used || 0,
      total: summary.clips_usage?.total || 0
    },
    videoUpload: {
      used: summary.content_duration?.used || 0,
      total: summary.content_duration?.total || 0,
      count: summary.content_duration?.count || 0
    },
    text: {
      used: summary.text_usage?.used || 0
    }
  };
}
