export default function SpeakersListSkeleton({ count = 5 }: { count?: number }): JSX.Element {
  return (
    <div className="space-y-0 pt-2">
      <div className="pb-2">
        <div className="mb-1 block w-2 px-1 text-xs"></div>
        {Array(count)
          .fill(0)
          .map((_, i) => (
            <div className="group relative flex flex-col items-center justify-between" key={i}>
              <div className="w-full rounded-lg px-1 py-3">
                <div className="flex items-center gap-2">
                  <div className="h-8 w-8 animate-pulse rounded-full bg-slate-200">
                    <div className="relative flex h-8 w-8 min-w-fit items-center justify-center overflow-hidden rounded-full font-medium leading-none tracking-wider text-white" />
                  </div>
                  <div className="flex w-48 flex-col gap-2">
                    <p className="flex h-3 w-[80%] max-w-xl animate-pulse items-center bg-slate-200 pr-4">
                      <span className="h-2 w-2 animate-pulse rounded-sm bg-slate-200" />
                    </p>
                    <p className="flex max-w-xl animate-pulse items-center bg-slate-200 pr-4">
                      <span className="h-2 w-2 animate-pulse rounded-sm bg-slate-200" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
