import { partialUpdateOrganization } from '@goldcast/api/backend';
import createStore from './store';
import { currentUser } from './user';

export const firstTimeUserStore = createStore<boolean>(false);

export function initFirstTimeUserStore(value: boolean) {
  firstTimeUserStore.update(() => value);
}

export async function disableFirstTimeUser() {
  try {
    await partialUpdateOrganization({
      id: currentUser.getSnapshot()?.organization as string,
      body: { newbie_content_user: false }
    });

    firstTimeUserStore.update(() => false);
    return Promise.resolve();
  } catch (error) {
    console.error('Failed to disable first time user', error);
    return Promise.reject(error);
  }
}
