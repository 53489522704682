import { useCallback, useEffect, useRef, useState } from 'react';
import { getElementMargins } from '@/libs/dom';

export default function useCaptionsDrag({ captionsWrapper, draggableElement, isDisabled, onDragEnd, onDrag }) {
  const dragStartPosition = useRef({ x: 0, y: 0 });
  const dragOffset = useRef({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [positions, setPositions] = useState<{ top: number; left: number }>({ top: 0, left: 0 });

  const handleMouseMove = useCallback((e: MouseEvent) => {
    const playerElement = captionsWrapper.current?.parentElement;
    const element = draggableElement.current;
    if (playerElement && element) {
      const newLeft = e.clientX - dragOffset.current.x;
      const newTop = e.clientY - dragOffset.current.y;

      const playerElementRect = playerElement.getBoundingClientRect();
      const elementRect = element?.getBoundingClientRect();

      // Get element full width/height including margins
      const { x: elementXMargins, y: elementYMargins } = getElementMargins(element);
      const elementWidth = element?.offsetWidth + elementXMargins + 5;
      const elementHeight = element?.offsetHeight + elementYMargins;

      const maxLeft = playerElementRect.width - elementRect.width;
      const maxTop = playerElementRect.height - elementHeight;

      let boundedLeft = Math.max(0, Math.min(newLeft, maxLeft));
      const boundedTop = Math.max(0, Math.min(newTop, maxTop));

      // If handle left out of bounds
      if (boundedLeft + elementWidth > playerElement.offsetWidth) {
        boundedLeft = playerElement.offsetWidth - elementWidth;
      }

      setPositions({
        top: (boundedTop / playerElementRect.height) * 100,
        left: (boundedLeft / playerElementRect.width) * 100
      });
      onDrag();
    }
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    onDragEnd();
  }, [onDragEnd]);

  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (isDisabled) return;
      setIsDragging(true);
      const playerElementRect = captionsWrapper.current?.parentElement?.getBoundingClientRect();
      if (playerElementRect) {
        dragStartPosition.current = { x: e.clientX, y: e.clientY };
        dragOffset.current = {
          x: e.clientX - (positions.left / 100) * playerElementRect.width,
          y: e.clientY - (positions.top / 100) * playerElementRect.height
        };
      }
    },
    [captionsWrapper, isDisabled, positions.left, positions.top]
  );

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [isDragging, handleMouseMove, handleMouseUp]);

  return {
    positions,
    isDragging,
    handleMouseDown,
    setPositions
  };
}
