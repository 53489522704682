import { AppContextState } from './AppContextTypes';

export const defaultState: AppContextState = {
  logger: {
    log(...params) {
      // eslint-disable-next-line no-console
      console.log(...params);
    },
    error(...params) {
      console.error(...params);
    },
    warn(...params) {
      console.warn(...params);
    },
    info(...params) {
      // eslint-disable-next-line no-console
      console.info(...params);
    },
    track(...params) {
      // eslint-disable-next-line no-console
      console.info(...params);
    }
  },
  adminAppStore: {},
  analytics: {
    identify: () => {},
    track: () => {},
    page: () => {}
  } as any,
  adminAppRouter: {},
  triggerAdminAppToast: () => {}
};
