import React, { useMemo } from 'react';
import { IconChevronsLeft, IconChevronsRight } from '@tabler/icons-react';
import { formatNumber } from './ContentPaginationUtils';

export default function ContentPagination({
  itemsCount,
  itemsPerPageCount,
  currentPage,
  onNext,
  onPrevious,
  goToLastPage,
  goToFirstPage
}: {
  itemsCount: number;
  itemsPerPageCount: number;
  currentPage: number;
  onNext: () => void;
  onPrevious: () => void;
  goToLastPage: (lastPage: number) => void;
  goToFirstPage: () => void;
}) {
  const hidePreviousCta = currentPage === 1;

  const hideNextCta = useMemo(() => {
    const numberOfPages = Math.ceil(itemsCount / itemsPerPageCount);
    return currentPage === numberOfPages;
  }, [currentPage, itemsCount, itemsPerPageCount]);

  const paginationInfo = useMemo(() => {
    if (currentPage === 1) {
      return `1 - ${formatNumber(Math.min(itemsCount, itemsPerPageCount))} of ${formatNumber(itemsCount)}`;
    }

    if (hideNextCta) {
      const end = itemsPerPageCount * currentPage;
      const start = end - itemsPerPageCount;
      return `${formatNumber(start)} - ${formatNumber(itemsCount)} of ${formatNumber(itemsCount)}`;
    }

    if (currentPage > 1) {
      const end = itemsPerPageCount * currentPage;
      const start = end - itemsPerPageCount;
      return `${formatNumber(start)} - ${formatNumber(end)} of ${formatNumber(itemsCount)}`;
    }
    return '';
  }, [itemsCount, hideNextCta, itemsPerPageCount, currentPage]);

  return (
    <div className="flex items-center justify-between px-4 py-3">
      <div className="w-32 flex-none">
        {!hidePreviousCta && (
          <div className="cursor-pointer rounded-md">
            <div className="flex rounded-md border">
              <div
                data-testid="first-page-cta"
                id="first-page-cta"
                className="block items-center border-r p-2 text-xs font-medium text-slate-500"
                onClick={goToFirstPage}
              >
                <IconChevronsLeft className="h-4 w-4" />
              </div>
              <div
                id="previous-button"
                className="inline-flex w-24 items-center justify-center p-2 pr-3 text-xs font-medium text-slate-500 hover:border-slate-300"
                onClick={onPrevious}
              >
                Previous
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="flex-1 text-center md:-mt-px md:flex">
        <div className="mx-auto text-xs text-slate-400">{paginationInfo}</div>
      </div>
      <div className="w-32">
        {!hideNextCta && (
          <div className="cursor-pointer rounded-md">
            <div className="flex rounded-md border">
              <div
                id="next-button"
                className="inline-flex w-24 items-center justify-center p-2 pl-3 text-xs font-medium text-slate-500 hover:border-slate-300"
                onClick={onNext}
              >
                Next
              </div>
              <div
                data-testid="last-page-cta"
                id="last-page-cta"
                className="block items-center border-l p-2 text-xs font-medium text-slate-500"
                onClick={() => {
                  const lastPage = Math.ceil(itemsCount / itemsPerPageCount);
                  goToLastPage(lastPage);
                }}
              >
                <IconChevronsRight className="h-4 w-4" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
