import { useCallback, useState } from 'react';
import useDebounce from './useDebounce';

export default function useIsScrollingElement() {
  const [isScrolling, setIsScrolling] = useState(false);

  // Debounced function to reset the scrolling state after the user stops scrolling
  const resetScrolling = useDebounce(() => {
    setIsScrolling(false);
  }, 300);

  const handleOnScroll = useCallback(() => {
    if (!isScrolling) {
      setIsScrolling(true); // Set to true when scrolling starts
    }
    resetScrolling(); // Schedule reset after debounce
  }, [isScrolling, resetScrolling]);

  return { isScrolling, handleOnScroll };
}
