import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import Pusher, { Channel } from 'pusher-js';
import { PUSHER_STATES, PUSHER_STATE_MESSAGES, PusherContextState, defaultState } from './PusherContextConstants';
import { getEnvConfig } from '@/constants';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { showErrorToast, showSuccessToast } from '@/libs/toast/toast';
import useDownloadToasts from '@/hooks/useDownloadToasts';
import { loadContentDownloadsList } from '@/stores/downloadsStore';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';

const PusherContext = createContext<PusherContextState>(defaultState);

// A new context for pusher is required to keep the implementation clean and separate from the main context
export function PusherContextProvider({ children, organizationId, userId, logger, ...restProps }) {
  const orgChannelRef = useRef<Channel | null>(null);
  const userChannelRef = useRef<Channel | null>(null);
  const pusherRef = useRef<Pusher | null>(null);
  const [isPusherConnected, setIsPusherConnected] = useState(false);

  const { showClipDownloadToastFromId, showTextAssetDownloadToast, showTextAssetSocketFailureToast } =
    useDownloadToasts();

  const handleOrgPushMessage = (state, data) => {
    logger.info('Org Pusher message received', state, data);

    // TODO @dusangc: Remove second part of the condition once socket for Saved Search is handled on the BE
    const shouldDisableToasts =
      window.location.pathname.includes(data?.upload_id) || (!data.broadcast_id && !data.upload_id);

    const generationDoneState =
      data.media_type === 'AUDIO'
        ? PUSHER_STATES.CLIP_GENERATION_DONE
        : featureFlagStore.getSnapshot()[FeatureFlagKeys.Use_CL_FTUX_Clip_Templates]
        ? PUSHER_STATES.FTUX_CLIPS_FACIAL_RECOGNITION_DONE
        : PUSHER_STATES.CLIP_GENERATION_DONE;

    if (
      [
        PUSHER_STATES.TRANSCRIPTION_DONE,
        PUSHER_STATES.TRANSCRIPTION_FAILED,
        PUSHER_STATES.TRANSCRIPTION_INVALID_AUDIO,
        PUSHER_STATES.TRANSCRIPTION_NO_AUDIOSTREAM,
        PUSHER_STATES.TRANSCRIPTION_UNAVAILABLE
      ].includes(state)
    ) {
      data.state = state;
      EventBus.dispatch(CustomEvents.TranscriptionStatusUpdated, data);

      if (shouldDisableToasts) {
        return;
      }

      if (state !== PUSHER_STATES.TRANSCRIPTION_DONE) {
        showErrorToast(PUSHER_STATE_MESSAGES[state]);
      }
    } else if (
      [generationDoneState, PUSHER_STATES.CLIP_GENERATION_FAILED, PUSHER_STATES.CLIP_GENERATION_LIMIT_REACHED].includes(
        state
      )
    ) {
      data.state = state;
      EventBus.dispatch(CustomEvents.ClipGenerationStatusUpdated, data);

      if (shouldDisableToasts) {
        return;
      }

      if (state === generationDoneState) {
        showSuccessToast(PUSHER_STATE_MESSAGES[state]);
      } else {
        showErrorToast(PUSHER_STATE_MESSAGES[state]);
      }
    }

    if ([PUSHER_STATES.SOCIAL_POST_GENERATION_DONE].includes(state)) {
      data.state = state;
      EventBus.dispatch(CustomEvents.SocialPostGenerationStatusUpdated, data);
    }

    if ([PUSHER_STATES.BLOG_POST_GENERATION_DONE].includes(state)) {
      data.state = state;
      EventBus.dispatch(CustomEvents.BlogPostGenerationStatusUpdated, data);
    }

    if ([PUSHER_STATES.EMAIL_POST_GENERATION_DONE].includes(state)) {
      data.state = state;
      EventBus.dispatch(CustomEvents.EmailPostGenerationStatusUpdated, data);
    }

    if ([PUSHER_STATES.TAKEAWAY_POST_GENERATION_DONE].includes(state)) {
      data.state = state;
      EventBus.dispatch(CustomEvents.TakeawayPostGenerationStatusUpdated, data);
    }

    if (
      [
        PUSHER_STATES.FACIAL_RECOGNITION_ACCURATE_DONE,
        PUSHER_STATES.FACIAL_RECOGNITION_FAST_DONE,
        PUSHER_STATES.FACIAL_RECOGNITION_ACCURATE_PROCESSING,
        PUSHER_STATES.FACIAL_RECOGNITION_FAST_PROCESSING,
        PUSHER_STATES.FACIAL_RECOGNITION_ACCURATE_FAILED,
        PUSHER_STATES.FACIAL_RECOGNITION_FAST_FAILED
      ].includes(state)
    ) {
      data.state = state;
      EventBus.dispatch(CustomEvents.FacialRecognitionStatusUpdated, data);
    }
  };

  const handleUserPushMessage = (state, data) => {
    logger.info('User Pusher message received', state, data);

    if (state === PUSHER_STATES.DOWNLOAD_DONE) {
      showClipDownloadToastFromId(data.id);
      loadContentDownloadsList(data.broadcast_id || data.upload_id, data.saved_search_id);
    } else if (state === PUSHER_STATES.DOWNLOAD_FAILED) {
      showClipDownloadToastFromId(data.id, false);
      loadContentDownloadsList(data.broadcast_id || data.upload_id, data.saved_search_id);
    } else if (state === PUSHER_STATES.MULTIMODAL_DOWNLOAD_DONE) {
      showTextAssetDownloadToast(data);
      loadContentDownloadsList(data.broadcast_id || data.upload_id, data.saved_search_id);
    } else if (state === PUSHER_STATES.MULTIMODAL_DOWNLOAD_FAILED) {
      showTextAssetSocketFailureToast(data);
      loadContentDownloadsList(data.broadcast_id || data.upload_id, data.saved_search_id);
    } else if (
      [PUSHER_STATES.PUBLISH_STATUS_UPDATED_DONE, PUSHER_STATES.PUBLISH_STATUS_UPDATED_FAILED].includes(state)
    ) {
      data.state = state;
      EventBus.dispatch(CustomEvents.PublishRecordingStatusUpdated, { data });
    }
  };

  useEffect(() => {
    if (!organizationId || !userId || pusherRef.current) {
      return;
    }

    try {
      pusherRef.current = new Pusher(getEnvConfig('PUSHER_APP_KEY'), {
        cluster: getEnvConfig('PUSHER_APP_CLUSTER')
      });
    } catch (error) {
      logger.warn('Error initializing pusher', error);
      return;
    }

    orgChannelRef.current = pusherRef.current.subscribe(organizationId);
    userChannelRef.current = pusherRef.current.subscribe(userId);
    pusherRef.current.connection.bind('state_change', states => {
      setIsPusherConnected(!(states.current === 'failed' || states.current === 'unavailable'));
    });

    orgChannelRef.current.bind_global(handleOrgPushMessage);
    userChannelRef.current.bind_global(handleUserPushMessage);

    return () => {
      pusherRef.current?.unsubscribe(organizationId);
      pusherRef.current?.unsubscribe(userId);
      orgChannelRef.current?.unbind_global();
      userChannelRef.current?.unbind_global();
    };
  }, [organizationId, userId]);

  return (
    <PusherContext.Provider
      value={{
        isPusherConnected
      }}
    >
      {React.cloneElement(children, {
        ...restProps
      })}
    </PusherContext.Provider>
  );
}

export function usePusherContext() {
  return useContext(PusherContext);
}
