import React from 'react';
import { IconAlertTriangle } from '@tabler/icons-react';

export default function ErrorState() {
  return (
    <div className="flex h-[calc(20rem-0.5px)] w-full items-center justify-center">
      <div className="flex flex-col items-center">
        <IconAlertTriangle size={32} stroke={1.5} className="text-slate-400" />
        <div className="mt-2 max-w-[20rem] text-center text-sm leading-tight text-slate-400">
          We're having trouble loading recent events. Please try refreshing the page.
        </div>
      </div>
    </div>
  );
}
