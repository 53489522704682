import { getFontByLocation } from '@/Pages/Clip/SideBar/FontSelector/constants';
import { getCurrentClipFontName, getFontNameFromBrandKit } from '@/stores/brandKit';

// TODO: @AshwinBhatkal to test cases with just extracting from font location
export const getFontFamilyStyle = (clipId: string, fontLocation?: string) => {
  const fontItem = getFontByLocation(fontLocation);
  if (!!fontItem) {
    return fontItem.name;
  }
  // No standard font, get from brand kit
  const currentClipFontName = getCurrentClipFontName(clipId);
  if (currentClipFontName) return currentClipFontName;

  const brandKitFontName = getFontNameFromBrandKit(fontLocation);
  if (brandKitFontName) return brandKitFontName;

  return undefined;
};
