import React, { useMemo, memo, useCallback } from 'react';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { classnames } from '@/libs/utils';

interface CarouselProps {
  clips: string[];
  activeClipIndex: number;
  handleActiveClipChange: (index: number) => void;
}

function ClipCarousel({ clips, activeClipIndex, handleActiveClipChange }: CarouselProps) {
  const isLastClip = useMemo(() => activeClipIndex === clips.length - 1, [activeClipIndex, clips.length]);
  const isFirstClip = useMemo(() => activeClipIndex === 0, [activeClipIndex]);

  const handlePrev = useCallback(() => {
    if (!isFirstClip) {
      handleActiveClipChange(activeClipIndex - 1);
    }
  }, [activeClipIndex, handleActiveClipChange, isFirstClip]);

  const handleNext = useCallback(() => {
    if (!isLastClip) {
      handleActiveClipChange(activeClipIndex + 1);
    }
  }, [activeClipIndex, handleActiveClipChange, isLastClip]);

  return (
    <div className="absolute left-2 top-2 z-30 flex items-center justify-center space-x-1.5 rounded-full border bg-white p-1.5 shadow-md">
      <button data-testid="carousel-prev-button" onClick={handlePrev} disabled={isFirstClip}>
        <IconChevronLeft
          className={classnames('h-4 w-4', {
            'text-slate-300': isFirstClip
          })}
        />
      </button>
      <div className="text-xs tabular-nums tracking-wider">
        {activeClipIndex + 1}/{clips.length}
      </div>
      <button data-testid="carousel-next-button" onClick={handleNext} disabled={isLastClip}>
        <IconChevronRight
          className={classnames('h-4 w-4', {
            'text-slate-300': isLastClip
          })}
        />
      </button>
    </div>
  );
}

export default memo(ClipCarousel);
