import { memo } from 'react';
import { CONTENT_FORMATS, BRAND_TONE_FILE_LIMIT } from '../constants';
import FilesInput from '@/components/atoms/inputs/FilesInput/FilesInput';

const UploadBrandGuideline = ({
  onFilesUpload,
  isUploading
}: {
  onFilesUpload: (files: File[]) => void;
  isUploading: boolean;
}) => {
  const title = isUploading ? 'Uploading...' : 'Upload a file';
  const icon = isUploading ? 'IconLoader2' : 'IconCloudUpload';
  const label = isUploading ? 'Please wait' : 'Click to browse, or drag & drop a file here';

  return (
    <div className="mt-3 w-full">
      <FilesInput
        containerClassNames="!h-56 border-dashed hover:border-deep-orange hover:bg-deep-orange-50 hover:text-deep-orange"
        onFilesUpload={onFilesUpload}
        accept={CONTENT_FORMATS}
        title={title}
        icon={icon}
        label={label}
        multiple={false}
        iconProps={{ className: `${isUploading ? 'animate-spin' : ''}  text-slate-500` }}
      />
      <div className="mt-1.5 flex w-full justify-between">
        <div className="text-xs text-slate-500">Supported file types: PDF</div>
        <div className="text-xs text-slate-500">Max file size: {BRAND_TONE_FILE_LIMIT}MB</div>
      </div>
    </div>
  );
};

export default memo(UploadBrandGuideline);
