import React, { useCallback, useMemo, useSyncExternalStore } from 'react';
import { IconChevronRight, IconColorPicker } from '@tabler/icons-react';
import { Transition } from '@headlessui/react';
import { SidebarContext } from './SideBarUtils';
import ClipBackgroundSelector from './ClipBackgroundSelector/ClipBackgroundSelector';
import FontSelector from './FontSelector/FontSelector';
import TemplatesList from './Templates/TemplatesList';
import SpeakersConfiguration from './SpeakersConfiguration';
import BrandKitInlineEmptyState from './EmptyStates/BrandKitInlineEmptyState';
import ClipDuration from './ClipDuration/ClipDuration';
import {
  changeSize,
  toggleCaptions,
  toggleHideBorders,
  updateHighlightColor,
  updateHighlightType,
  updateKeyInMagicLayout
} from '@/stores/clip';
import Toggle from '@/components/atoms/Toggle';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import Loader from '@/components/atoms/Loader';
import { HighlightType, SizeType } from '@/domains/asset';
import { ColorPicker } from '@/components/atoms/ColorPicker/ColorPicker';
import { brandKit } from '@/stores/brandKit';
import { classnames } from '@/libs/utils';
import Button from '@/components/atoms/Button/Button';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';

export default function MainSideBar({ setSideBarContext }: { setSideBarContext: (context: SidebarContext) => void }) {
  const { clipId, clipData } = useClipsContext();
  const brandKitStore = useSyncExternalStore(brandKit.subscribe, brandKit.getSnapshot);
  const transcriptStore = useTranscriptContext();
  const isLoadingTranscript = useMemo(() => !transcriptStore.transcript?.length, [transcriptStore.transcript]);
  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const isCaptionsOverlayDisabled = featureFlags[FeatureFlagKeys.Use_CL_Captions_Overlay] === false;

  function setTemplatesSideBarContext() {
    setSideBarContext(SidebarContext.Templates);
  }

  function setEditCaptionsSideBarContext() {
    setSideBarContext(SidebarContext.EditCaptions);
  }

  function setEditDurationSideBarContext() {
    setSideBarContext(SidebarContext.EditDuration);
  }

  function setIntroOutroSideBarContext() {
    setSideBarContext(SidebarContext.IntroOutro);
  }

  function updateClipHighlightType(event: React.ChangeEvent) {
    const selectElement = event.currentTarget as HTMLSelectElement;
    updateHighlightType(clipId, selectElement.value as HighlightType);
  }

  const onCaptionsToggle = useCallback(
    (value: boolean) => {
      toggleCaptions(clipId, value);
    },
    [clipId]
  );

  const onBorderToggle = useCallback(
    (value: boolean) => {
      toggleHideBorders(clipId, !value);
    },
    [clipId]
  );

  const onFontColorChange = useCallback(
    (hex: string) => {
      updateKeyInMagicLayout(clipId, 'textColor', hex);
    },
    [clipId]
  );

  const onHighightColorChange = useCallback(
    (hex: string) => {
      updateHighlightColor(clipId, hex);
    },
    [clipId]
  );

  return (
    <Transition
      appear
      show={true}
      as={'div'}
      className={'h-full overflow-y-auto px-4 py-3'}
      enter="duration-300 ease-out"
      enterFrom="translate-x-[-200px]"
      enterTo="translate-x-0"
      leave="duration-300 ease-in"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-[-200px]"
    >
      <div className="my-2 font-semibold">Templates</div>
      <TemplatesList showAll={false} />

      <Button
        onClick={setTemplatesSideBarContext}
        trackingId="all-templates-button"
        variation="outline"
        className="mt-3 flex w-full items-center !justify-between rounded-md !px-4 !py-3.5 text-left font-normal"
      >
        <div className="text-sm">All Templates</div>
        <IconChevronRight size={21} stroke={1.5} />
      </Button>

      <div
        className={classnames('mt-4 flex items-center justify-between rounded-md border border-slate-200 px-4 py-3.5', {
          'rounded-b-none': clipData.asset_metadata.subtitle
        })}
      >
        <div className="text-sm">Captions</div>
        <Toggle
          on={!!clipData.asset_metadata.subtitle}
          onToggle={onCaptionsToggle}
          disabled={clipData.asset_metadata.layout === 'AUDIOGRAM'}
        />
      </div>

      <Transition
        appear
        show={clipData.asset_metadata.subtitle || clipData.asset_metadata.layout === 'AUDIOGRAM'}
        className="transition-all duration-500"
        enterFrom="opacity-0 max-h-0"
        enterTo="opacity-100 max-h-96"
        leaveFrom="opacity-100 max-h-96"
        leaveTo="opacity-0 max-h-0"
      >
        <Button
          onClick={setEditCaptionsSideBarContext}
          variation="active"
          disabled={isLoadingTranscript}
          trackingId="edit-transcript"
          className={`mb-1.5 flex w-full items-center !justify-between rounded-md !rounded-t-none border border-t-0 !border-slate-200 !px-4 !py-3.5 text-left font-normal ${
            isLoadingTranscript ? 'cursor-not-allowed text-slate-400' : 'cursor-pointer'
          }`}
        >
          <div className="text-sm">Edit Captions</div>
          <div>{isLoadingTranscript ? <Loader size="small" /> : <IconChevronRight size={21} stroke={1.5} />}</div>
        </Button>

        {isCaptionsOverlayDisabled && (
          <div className="flex items-center space-x-1.5 pb-3 pt-4">
            <FontSelector />
            <div className="flex w-14 items-center justify-center">
              <div className="flex w-full items-center justify-center rounded-lg border p-2 text-sm">
                <div className="relative flex items-center justify-center">
                  <ColorPicker
                    classNames="rounded-2xl p-2"
                    pickerClassNames="right-0 top-[80%]"
                    defaultColors={brandKitStore.colors}
                    defaultColorsLabel="Brand Colors"
                    onChange={onFontColorChange}
                    hex={clipData.asset_metadata.magicLayout?.textColor as string}
                  >
                    <IconColorPicker size={16} stroke={2} />
                    <BrandKitInlineEmptyState label="colors" />
                  </ColorPicker>
                </div>
              </div>
            </div>
          </div>
        )}
        {isCaptionsOverlayDisabled && (
          <div className="flex items-center space-x-1.5">
            <label htmlFor="size" className="ml-[1px] w-full rounded-md text-sm">
              <select
                data-testid="highlight-select"
                className="mt-0.5 w-full rounded-md border border-slate-200 px-3 py-3.5 text-sm"
                value={clipData.asset_metadata.highlight_type}
                onChange={updateClipHighlightType}
              >
                <option value="none">None</option>
                <option value="text">Text Highlight</option>
                <option value="box">Box Highlight</option>
              </select>
            </label>
            <Transition
              appear
              show={clipData.asset_metadata.highlight_type === 'box'}
              className="transition-all duration-200"
              enterFrom="opacity-0 ml-[-100px]"
              enterTo="opacity-100 ml-1.5"
              leaveFrom="opacity-100 ml-1.5"
              leaveTo="opacity-0 ml-[-100px]"
            >
              <div className="flex w-14 items-center justify-center" data-testid="highlight-color-picker">
                <div className="relative flex w-full items-center justify-center rounded-lg border p-2 text-sm">
                  <div className="relative flex items-center justify-center">
                    <ColorPicker
                      classNames="rounded-2xl p-2"
                      pickerClassNames="right-0 top-[80%]"
                      defaultColors={brandKitStore.colors}
                      defaultColorsLabel="Brand Colors"
                      onChange={onHighightColorChange}
                      hex={clipData.asset_metadata.word_highlight_color as string}
                    >
                      <IconColorPicker size={16} stroke={2} />
                      <BrandKitInlineEmptyState label="colors" />
                    </ColorPicker>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        )}
      </Transition>

      {!isCaptionsOverlayDisabled && (
        <div
          className={classnames(
            'mt-4 flex items-center justify-between rounded-md border border-slate-200 px-4 py-3.5',
            {
              'rounded-b-none': clipData.asset_metadata.subtitle
            }
          )}
        >
          <div className="text-sm">Borders</div>
          <div className="">
            <Toggle
              on={!clipData.asset_metadata.hide_borders}
              onToggle={onBorderToggle}
              disabled={clipData.asset_metadata.layout === 'AUDIOGRAM'}
            />
          </div>
        </div>
      )}

      <Transition
        appear
        show={
          !isLoadingTranscript &&
          (clipData.asset_metadata.layout === 'GRID' || clipData.asset_metadata.layout === 'SPEAKER')
        }
        className="transition-all duration-500"
        enterFrom="opacity-0 max-h-0"
        enterTo="opacity-100 max-h-[500px]"
        leaveFrom="opacity-100 max-h-[500px]"
        leaveTo="opacity-0 max-h-0"
      >
        <SpeakersConfiguration />
      </Transition>

      <div className="mt-5 font-semibold">Aspect Ratio</div>
      <label htmlFor="size" className="text-sm">
        <select
          id="size"
          data-testid="aspect-ratio-select"
          className="mt-0.5 w-full rounded-md border border-slate-200 px-3 py-3.5 text-sm"
          value={clipData.asset_metadata.size}
          onChange={e => changeSize(clipId, e.currentTarget.value as SizeType)}
        >
          <option value="PORTRAIT">Portrait (9:16)</option>
          <option value="LANDSCAPE">Landscape (16:9)</option>
          <option value="SQUARE">Square (1:1)</option>
        </select>
      </label>

      <ClipBackgroundSelector />

      {!featureFlags[FeatureFlagKeys.Use_CL_New_Timeline] && (
        <>
          <div className="mt-5 font-semibold">Media</div>
          <Button
            onClick={setIntroOutroSideBarContext}
            variation="outline"
            trackingId="edit-intro-outro"
            className="mt-1.5 flex w-full items-center !justify-between rounded-md border !px-4 !py-3.5 text-left font-normal"
          >
            <div className="text-sm">Intro & Outro</div>
            <IconChevronRight size={21} stroke={1.5} />
          </Button>
        </>
      )}

      <ClipDuration
        setEditDurationSideBarContext={setEditDurationSideBarContext}
        isLoadingTranscript={isLoadingTranscript}
      />
    </Transition>
  );
}
