import React from 'react';
import qs from 'qs';
import { UseQueryParamsOptions } from './hooksUrlTypes';
export function useQueryParams<T = unknown>(options?: UseQueryParamsOptions<T>): T {
  const queryParams = React.useMemo(() => {
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true, ...options });

    if (typeof options?.processQueryParams === 'function') {
      return options.processQueryParams(params);
    }

    return params;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search, options, options?.processQueryParams]);

  return queryParams as unknown as T;
}
