import { useMemo, useSyncExternalStore } from 'react';
import useFreeTrialHook from '@/hooks/useFreeTrialHook';
import { firstTimeUserStore } from '@/stores/firstTimeUserStore';

export default function useHomepageNavigationHook(): {
  shouldNavigateToSplashPage: boolean;
  shouldNavigateToHomepage: boolean;
} {
  const { hasUploadedVideos, isFreeTrialEnabled, isFreemiumUser } = useFreeTrialHook();
  const isFirstTimeUser = useSyncExternalStore(firstTimeUserStore.subscribe, firstTimeUserStore.getSnapshot);

  const checkNavigationToHomePage = useMemo(() => {
    if (!isFreeTrialEnabled || isFreemiumUser) {
      return true;
    }
    if (hasUploadedVideos) {
      return true;
    }
    if (!isFirstTimeUser) {
      return true;
    }
    return false;
  }, [hasUploadedVideos, isFirstTimeUser, isFreeTrialEnabled, isFreemiumUser]);

  return {
    shouldNavigateToSplashPage: !checkNavigationToHomePage,
    shouldNavigateToHomepage: checkNavigationToHomePage
  };
}
