import {
  AssetsMediaContentAssetDownloadsListOkResponse,
  CombinedDownload,
  assetsMediaContentAssetDownloadsList,
  assetsSavedSearchAssetDownloadsList
} from '@goldcast/api/content';
import createStore from './store';
import { showErrorToast } from '@/libs/toast/toast';

type DownloadsStore = {
  [contentId: string]: CombinedDownload[];
};

export const downloads = createStore<DownloadsStore>({});
export const downloadsPopupOpen = createStore<boolean>(false);

export async function loadContentDownloadsList(contentId: string, savedSearchId?: string) {
  try {
    const data = await loadDownloads(contentId, savedSearchId);

    downloads.set(() => {
      return {
        ...downloads.getSnapshot(),
        [savedSearchId || contentId]: data?.results || []
      };
    });
  } catch (error) {
    console.error('Failed to fetch downloads list', error);
    showErrorToast('Whoops! We hit a snag while fetching the downloads list. Please try again.');
  }
}

export async function setDownloadsPopupOpen(open: boolean) {
  downloadsPopupOpen.set(() => open);
}

function loadDownloads(
  contentId: string,
  savedSearchId?: string
): Promise<AssetsMediaContentAssetDownloadsListOkResponse> {
  if (!!savedSearchId) {
    return assetsSavedSearchAssetDownloadsList({
      id: savedSearchId,
      queryParams: {
        limit: 10
      }
    });
  }
  return assetsMediaContentAssetDownloadsList({
    id: contentId,
    queryParams: {
      limit: 10
    }
  });
}
