import React, { memo, useEffect, useState } from 'react';

import { fetchFilmstripIndex } from '../ClipTimeline/ClipTimelineUtils';
import Filmstrip from './Filmstrip';
import Loader from '@/components/atoms/Loader';

function FrameRenderer({
  start,
  end,
  singleFrameWidth,
  sectionWidth,
  projectId,
  contentId
}: {
  start: number;
  end: number;
  singleFrameWidth: number;
  sectionWidth: number;
  projectId: string;
  contentId: string;
}) {
  const [loadingFilmstripFilenames, setLoadingFilmstripFilenames] = useState(true);
  const [filmstripFilenames, setFilmstripFilenames] = useState<string[]>([]);

  useEffect(() => {
    // ! ensure this effect is triggered only once
    fetchFilmstripIndex(projectId, contentId)
      .then(allFilenames => {
        setFilmstripFilenames(allFilenames);
      })
      .finally(() => {
        setLoadingFilmstripFilenames(false);
      });
  }, [projectId, contentId]);

  return (
    <div className="absolute inset-0 z-20 overflow-hidden rounded-md">
      {loadingFilmstripFilenames ? (
        <div className="flex h-full w-full items-center justify-center">
          <Loader />
        </div>
      ) : filmstripFilenames.length === 0 ? (
        <div className="flex h-full w-full items-center justify-center">
          Preview unavailable, but you can still adjust your timeline!
        </div>
      ) : (
        <Filmstrip
          filmstripFilenames={filmstripFilenames}
          singleFrameWidth={singleFrameWidth}
          sectionWidth={sectionWidth}
          start={start}
          end={end}
        />
      )}
    </div>
  );
}

export default memo(FrameRenderer);
