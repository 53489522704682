import { Transition } from '@headlessui/react';
import { useState } from 'react';
import { BrandKitConfigItem } from './types';
import Icon from '@/components/atoms/Icon';

export default function BrandKitItem({ configurationItem }: { configurationItem: BrandKitConfigItem }): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  function toggle() {
    setIsOpen(val => !val);
  }
  return (
    <div className="py-1">
      <button
        className="flex w-full items-center justify-between text-left"
        data-testid="toggle-brand-kit-button"
        onClick={toggle}
      >
        <div>
          <div className="text-lg font-medium">{configurationItem.title}</div>
          <div className="text-xs text-slate-500">{configurationItem.description}</div>
        </div>
        <Icon name={isOpen ? 'IconChevronDown' : 'IconChevronRight'} stroke={1.5} />
      </button>
      <Transition
        appear
        show={isOpen}
        className="w-full transition-all duration-500"
        enterFrom="opacity-0 max-h-0"
        enterTo="opacity-100 max-h-[2000px]"
        leaveFrom="opacity-100 max-h-[2000px]"
        leaveTo="opacity-0 max-h-0"
      >
        {configurationItem.renderer}
      </Transition>
    </div>
  );
}
