import React, { Fragment, useCallback, useMemo } from 'react';
import { IconChevronRight } from '@tabler/icons-react';
import TimeInput from './TimeInput';
import Loader from '@/components/atoms/Loader';
import Button from '@/components/atoms/Button/Button';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { updateStartEndTime } from '@/stores/clip';

export default function ClipDuration({
  setEditDurationSideBarContext,
  isLoadingTranscript
}: {
  setEditDurationSideBarContext: () => void;
  isLoadingTranscript: boolean;
}) {
  const { clipId, clipData, sharedAPIStore } = useClipsContext();

  const videoDuration = useMemo(
    () => sharedAPIStore.mainPlayerRef?.duration || 0,
    [sharedAPIStore.mainPlayerRef?.duration]
  );

  const onStartTimeChange = useCallback(
    (val: number) => {
      updateStartEndTime(clipId, 'start', val);
    },
    [clipId]
  );

  const onEndTimeChange = useCallback(
    (val: number) => {
      updateStartEndTime(clipId, 'end', val);
    },
    [clipId]
  );

  return (
    <>
      <div className="mt-5 font-semibold">Duration</div>
      <div className="mt-1.5 w-full rounded-md rounded-b-none border border-slate-200 px-4 py-3.5">
        <div className="flex w-full items-center justify-between">
          <Fragment>
            <TimeInput
              key={clipId + clipData.asset_metadata.start}
              initialValue={clipData.asset_metadata.start}
              validatorFn={val => val >= clipData.asset_metadata.end || val >= videoDuration}
              title="Start"
              onTimeChange={onStartTimeChange}
            />
            <div className="mx-2 text-sm text-slate-400">→</div>
            <TimeInput
              key={clipId + clipData.asset_metadata.end}
              initialValue={clipData.asset_metadata.end}
              validatorFn={val => val <= clipData.asset_metadata.start || val >= videoDuration}
              title="End"
              onTimeChange={onEndTimeChange}
            />
          </Fragment>
        </div>
      </div>
      <Button
        onClick={setEditDurationSideBarContext}
        disabled={isLoadingTranscript}
        trackingId="edit-duration"
        variation="active"
        className={`mb-1.5 flex w-full items-center !justify-between rounded-md !rounded-t-none border border-t-0 !border-slate-200 !px-4 !py-3.5 text-left font-normal ${
          isLoadingTranscript ? 'cursor-not-allowed text-slate-400' : 'cursor-pointer'
        }`}
      >
        <div className="text-sm">Edit Duration</div>
        <div>{isLoadingTranscript ? <Loader size="small" /> : <IconChevronRight size={21} stroke={1.5} />}</div>
      </Button>
    </>
  );
}
