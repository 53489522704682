import { getFontFamilyStyle } from '../../font';
import { TimelineElement, TimelineElementType } from '../../../types';
import { FPS_24 } from '../../../constants';
import { Clip } from '@/domains/asset';
import { sharedAPI } from '@/stores/sharedAPI/sharedAPI';
import { speakersAnalysisStore } from '@/stores/speakersAnalysis/speakersAnalysis';
import { getSizeConfig } from '@/libs/sharedAPI/sizeConfig/SizeConfigFactory';
import { getSpeakerNameAndTitle } from '@/Pages/Clip/CompositePlayer/SpeakerVideoUtils';
import { getDeletedTimeInRange } from '@/libs/sharedAPI/common';

export const getElementsTimelineForGridUpload = (clip: Clip) => {
  const elementsTimeline: TimelineElement[] = [];
  const clipId = clip.id;

  const sharedAPIStore = sharedAPI.getSnapshot()[clipId];
  const transcriptStore = sharedAPIStore.transcriptStore;

  const speakersAnalysis = speakersAnalysisStore.getSnapshot()[clipId];
  const timeAnalysisArray = speakersAnalysis.time_analysis;

  const fontFamily = getFontFamilyStyle(clipId, clip.asset_metadata.font_location);

  const sizeConfig = getSizeConfig(clip.asset_metadata.size, clip.id, clip.asset_metadata.layout, 1);

  let fromFrame = 0,
    toFrame = 0;
  const modifiedExcludes = clip.asset_metadata?.excludes?.map(exclude => {
    return { start: exclude[0], end: exclude[1] };
  });

  const cumulativeDeletedTimes = speakersAnalysis.times.map((_, index) => {
    return getDeletedTimeInRange(
      // This is always start of the clip to get a cumulative sum
      clip.asset_metadata.start,
      index === speakersAnalysis.times.length - 1 ? clip.asset_metadata.end : speakersAnalysis.times[index + 1],
      modifiedExcludes
    );
  });

  timeAnalysisArray.forEach((timeAnalysis, index) => {
    const visible_face_ids = speakersAnalysis.visible_face_ids;

    const facePositions = timeAnalysis?.face_positions || [];
    const visibleFacePositions = facePositions.filter(facePosition => visible_face_ids.includes(facePosition.face_id));

    toFrame = Math.ceil(
      ((index === speakersAnalysis.times.length - 1 ? clip.asset_metadata.end : speakersAnalysis.times[index + 1]) -
        clip.asset_metadata.start -
        cumulativeDeletedTimes[index]) *
        FPS_24
    );

    if (visibleFacePositions.length === 0) {
      fromFrame = toFrame;
      return;
    }

    visibleFacePositions.forEach((facePosition, facePositionIndex) => {
      const speaker = Object.values(transcriptStore.speakersWithDetails).find(speaker => {
        return speakersAnalysis.speaker_mapping?.[speaker.id]?.includes(facePosition.face_id);
      });
      if (!speaker) return;

      const {
        container: containerStyles,
        name: nameStyles,
        title: titleStyles
      } = sizeConfig.getSpeakerNameTitleStyles({
        fontFamily,
        currentVideoNumber: facePositionIndex + 1,
        totalVideos: visibleFacePositions.length
      });

      const { name, title } = getSpeakerNameAndTitle(speaker);

      elementsTimeline.push({
        fromFrame,
        toFrame,
        style: {
          ...containerStyles
        },
        type: TimelineElementType.CONTAINER,
        elements: [
          {
            fromFrame,
            toFrame,
            style: {
              ...nameStyles
            },
            content: name,
            type: TimelineElementType.TEXT
          },
          {
            fromFrame,
            toFrame,
            style: {
              ...titleStyles
            },
            content: title,
            type: TimelineElementType.TEXT
          }
        ]
      });
    });

    fromFrame = toFrame;
  });

  return elementsTimeline.filter(a => a !== null);
};
