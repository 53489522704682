import { useCallback, useSyncExternalStore } from 'react';
import useAnalytics from './useAnalytics';
import { useAppContext } from '@/context/AppContext/AppContext';
import { orgUsageSummaryState, OrgUsageSummaryState, PlanTypeEnum } from '@/stores/orgUsageSummary';
import { getTotalVideoDuration } from '@/libs/getVideoDuration';
import { showErrorToast } from '@/libs/toast/toast';

export default function useFreeTrialHook(): {
  isFreeTrialEnabled: boolean;
  isFreemiumUser: boolean;
  isPaidUser: boolean;
  videoUploadLeft: number;
  hasUploadedVideos: boolean;
  isUsageSummaryLoading: boolean;
  /**
   * Check if the user has reached the video upload limit and show a paywall if they have. This function has a side effect so that
   * we don't have to repeat the paywall logic in every component.
   */
  checkVideoUploadLimitReached: (videoFiles?: File[]) => Promise<{ value: boolean; reason?: string }>;
  openBlockingPricingPaywall: () => void;
  openNonBlockingPricingPaywall: () => void;
  incrementClipsUsed: (count: number) => void;
  incrementDurationUsed: (duration: number) => void;
  incrementTextSummariesUsed: (count: number) => void;
  notifyUserToUpgrade: () => void;
} {
  const orgUsageSummaryStore = useSyncExternalStore(orgUsageSummaryState.subscribe, orgUsageSummaryState.getSnapshot);
  const { triggerAdminAppToast, adminAppRouter } = useAppContext();
  const isVideoUploadLimitReached =
    orgUsageSummaryStore.usage.videoUpload.used >= orgUsageSummaryStore.usage.videoUpload.total;
  const videoUploadLeft = orgUsageSummaryStore.usage.videoUpload.total - orgUsageSummaryStore.usage.videoUpload.used;
  const hasUploadedVideos = orgUsageSummaryStore.usage.videoUpload.used > 0;
  const isUsageSummaryLoading = orgUsageSummaryStore.loading;
  const { trackUsageCapLimitReached, trackOrgUsage } = useAnalytics();

  const isFreemiumUser = orgUsageSummaryStore.currentPlan === PlanTypeEnum.FREEMIUM;
  const isPaidUser =
    orgUsageSummaryStore.currentPlan === PlanTypeEnum.STARTER_MONTHLY ||
    orgUsageSummaryStore.currentPlan === PlanTypeEnum.STARTER_ANNUALLY;

  const isFreeTrialEnabled = orgUsageSummaryStore.currentPlan === PlanTypeEnum.TRIAL;

  const openBlockingPricingPaywall = () => {
    adminAppRouter.replace({ hash: '#pricing-paywall' });
  };

  const openNonBlockingPricingPaywall = () => {
    adminAppRouter.replace({ hash: '#pricing' });
  };

  const notifyUserToUpgrade = () => {
    if (isPaidUser) {
      showErrorToast(
        'The total duration of the videos you are trying to upload exceeds your limit. Please contact our sales team for additional add-ons.'
      );
    } else {
      triggerAdminAppToast({
        msg: 'The total duration of the videos you are trying to upload exceeds your limit.',
        mode: 'error'
      });
      openNonBlockingPricingPaywall();
    }
  };

  const checkVideoUploadLimitReached = async (videoFiles?: File[]): Promise<{ value: boolean; reason?: string }> => {
    if (!isFreeTrialEnabled && !isFreemiumUser && !isPaidUser) {
      return { value: false };
    }

    if (videoFiles) {
      const totalVideoDuration = await getTotalVideoDuration(videoFiles);

      if (totalVideoDuration > videoUploadLeft) {
        notifyUserToUpgrade();

        return { value: true, reason: 'The total duration of the videos exceeds the limit' };
      }
    }

    if (!isVideoUploadLimitReached) {
      return { value: false };
    }

    triggerAdminAppToast({
      msg: 'You have reached your video upload limit. Please upgrade to continue using Content Lab.',
      mode: 'error'
    });
    openNonBlockingPricingPaywall();

    return { value: true, reason: 'The video upload limit reached' };
  };

  const incrementClipsUsed = (count: number) => {
    orgUsageSummaryState.update((state: OrgUsageSummaryState) => {
      state.usage.clips.used += count;
    });
    trackOrgUsage();
  };

  const incrementTextSummariesUsed = useCallback((count: number) => {
    orgUsageSummaryState.update((state: OrgUsageSummaryState) => {
      state.usage.text.used += count;
    });
    trackOrgUsage();
  }, []);

  const incrementDurationUsed = (duration: number) => {
    orgUsageSummaryState.update((state: OrgUsageSummaryState) => {
      state.usage.videoUpload.used += duration;
      if (state.usage.videoUpload.used >= state.usage.videoUpload.total) {
        trackUsageCapLimitReached('VIDEO_UPLOAD');
      }
    });
  };

  return {
    isFreeTrialEnabled,
    videoUploadLeft,
    hasUploadedVideos,
    isUsageSummaryLoading,
    checkVideoUploadLimitReached,
    openBlockingPricingPaywall,
    openNonBlockingPricingPaywall,
    incrementClipsUsed,
    incrementDurationUsed,
    incrementTextSummariesUsed,
    isFreemiumUser,
    isPaidUser,
    notifyUserToUpgrade
  };
}
