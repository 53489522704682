import { MediaContent, GetRelevantSourcesResponse } from '@goldcast/api/content';
import moment from 'moment';
import { BROADCAST_UNAVAILABLE_REASONS, PREVIEW_IMAGE_SIZES, PreviewImageSize } from './constants';
import { getEnvConfig } from '@/constants';
import { TextType } from '@/domains/asset';
import { SESSION_PREVIEW_STATUS_MAP } from '@/Pages/Sessions/constants';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';

const gradients = [
  'bg-gradient-to-tr from-green-300 via-blue-500 to-purple-600 before:bg-gradient-to-tr before:from-green-300 before:via-blue-500 before:to-purple-600',
  'bg-gradient-to-tr from-pink-300 via-purple-300 to-indigo-400 before:bg-gradient-to-tr before:from-pink-300 before:via-purple-300 before:to-indigo-400',
  'bg-gradient-to-tr from-red-200 via-red-300 to-yellow-200 before:bg-gradient-to-tr before:from-red-200 before:via-red-300 before:to-yellow-200',
  'bg-gradient-to-tr from-pink-400 to-pink-600 before:bg-gradient-to-tr before:from-pink-400 before:to-pink-600',
  'bg-gradient-to-tr from-rose-400 via-fuchsia-500 to-indigo-500 before:bg-gradient-to-tr before:from-pink-400 before:to-pink-600'
];

export const gradientTextAssetMapping: Record<TextType, string> = {
  LINKEDIN: gradients[0],
  EMAIL: gradients[1],
  BLOG: gradients[2],
  TAKEAWAYS: gradients[3],
  CUSTOM: gradients[4]
};

export const getTitleFromMarkdownString = (markdownString: string) => {
  if (!markdownString) return '';

  const match = markdownString.match(/^#+\s+(.*)/m);

  if (match) {
    return match[1];
  } else {
    console.warn('No title found in Markdown string');
    return markdownString?.substring(0, 100).replace(/[#*]/g, '');
  }
};

export const parseTextTitle = (title: string) => {
  return title.replaceAll(new RegExp(/^(#+\s+|\*+\s+)/, 'g'), '');
};

export type TypeOptionsType = {
  label: string;
  value: 'CLIP' | 'TEXT';
};

export const typeOptions: TypeOptionsType[] = [
  {
    label: 'Clip',
    value: 'CLIP'
  },
  {
    label: 'Text',
    value: 'TEXT'
  }
];

export const formatToStandardDate = (date: string) => {
  return moment(date).format('MMMM D, YYYY');
};

export function getBroadcastBackgroundImageUrl(
  content: MediaContent | GetRelevantSourcesResponse,
  size: PreviewImageSize = PreviewImageSize.Small
): string {
  const sizeString = PREVIEW_IMAGE_SIZES[size];
  if (content.media_source_type === 'UPLOAD') {
    return `${getEnvConfig('CLOUDFRONT_ASSET_URL')}${content.project.id}/${
      content.id
    }/recording/subtitles/thumbnail_${sizeString}.png`;
  }
  return `${getEnvConfig('CLOUDFRONT_STATIC_ASSET_URL')}screenshots/reg/${content.project.id}-${sizeString}.png`;
}

export function convertBroadcastReason(reason: string): string {
  return BROADCAST_UNAVAILABLE_REASONS[reason] || '';
}

export function getSessionPreviewDetails(session: MediaContent) {
  const isNewFTUXVideo =
    featureFlagStore.getSnapshot()[FeatureFlagKeys.Use_CL_FTUX_Clip_Templates] && session.media_type === 'VIDEO';

  let progressText = SESSION_PREVIEW_STATUS_MAP[session.batch_status] || 'Unavailable';
  let uploadingInProgress = false;

  if (session.batch_status === 'PROCESSING' || (session.batch_status as any) === 'UPLOADING') {
    uploadingInProgress = true;
  }

  if (
    session.batch_status === 'DONE' &&
    (session.clip_generation_status === 'PROCESSING' ||
      session.clip_generation_status === null ||
      (isNewFTUXVideo && session.ftux_facial_recognition_status === 'PROCESSING')) &&
    session.embedding_generation_status !== null
  ) {
    progressText = SESSION_PREVIEW_STATUS_MAP['GENERATING'];
    uploadingInProgress = true;
  }

  return {
    isContentUploadInProgress: uploadingInProgress,
    contentStatusText: progressText
  };
}

export function isSessionReadyForPreview(session: MediaContent) {
  const isNewFTUXVideo =
    featureFlagStore.getSnapshot()[FeatureFlagKeys.Use_CL_FTUX_Clip_Templates] && session.media_type === 'VIDEO';

  if (session.batch_status !== 'DONE') {
    return false;
  }

  if (isNewFTUXVideo) {
    if (['NOT_APPLICABLE', 'DONE', null].includes(session.ftux_facial_recognition_status)) {
      return true;
    }
  } else {
    if (session.clip_generation_status === 'DONE') {
      return true;
    }
  }

  if (session.clip_generation_status === 'FAILED' || session.clip_generation_status === 'LIMIT_REACHED') {
    return true;
  }

  if (!session.embedding_generation_status && !session.clip_generation_status) {
    return true;
  }

  return false;
}
