import React from 'react';
import { Transition } from '@headlessui/react';
import { ClipPlayerSection } from '../ClipPlayerSections/ClipPlayerSectionsTypes';
import { classnames } from '@/libs/utils';

export default function ProgressBarSection({
  section,
  totalDuration,
  duration,
  currentTime,
  status,
  onSeeked,
  disabled = false
}: {
  section: ClipPlayerSection;
  totalDuration: number;
  duration: number;
  currentTime: number;
  status: 'past' | 'current' | 'next';
  onSeeked?: (time: number) => void;
  disabled?: boolean;
}) {
  const [hoverX, setHoverX] = React.useState(-1);
  // TODO: use memo here and below after making the transition smooth
  const percentage = Math.min((duration / totalDuration) * 100, 100);
  //TODO: revisit this logic
  const progressWidth = status === 'past' ? 100 : status === 'next' ? 0 : Math.min((currentTime / duration) * 100, 100);

  return (
    <button
      style={{ width: percentage + '%' }}
      className={
        'group relative mx-0.5 h-1.5 rounded-full bg-slate-200 ' + (disabled ? 'cursor-not-allowed' : 'cursor-pointer')
      }
      disabled={disabled}
      onPointerMove={({ clientX, currentTarget }) => {
        setHoverX(clientX - currentTarget.getBoundingClientRect().x);
      }}
      onPointerOut={() => {
        setHoverX(-1);
      }}
      onPointerUp={({ clientX, currentTarget }) => {
        if (disabled) return;
        const posX = (clientX - currentTarget.getBoundingClientRect().x) / currentTarget.clientWidth;
        onSeeked?.(posX * duration);
      }}
      data-testid={`progress-bar-section-${section}`}
    >
      <div
        style={{ width: progressWidth + '%' }}
        className={classnames(
          'pointer-events-none absolute top-0 h-1.5 w-0.5 select-none rounded-full bg-slate-400 transition-all duration-75 ease-in-out group-hover:bg-deep-orange'
        )}
        role="progressbar"
      >
        <Transition
          as="div"
          show={hoverX > -1 && status === 'current'}
          className="absolute -right-2 -top-1 h-3 w-3 rounded-full bg-white shadow"
          enter="transition-opacity duration-100 ease-in"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-100 ease-out"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        />
      </div>
    </button>
  );
}
