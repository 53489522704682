import { Transition } from '@headlessui/react';
import { useMemo } from 'react';
import { IconTag } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import IntroOutroVideoPreview from './IntroOutroVideoPreview';
import { SidebarContext } from '@/Pages/Clip/SideBar/SideBarUtils';
import SideBarBreadcrumb from '@/Pages/Clip/SideBar/SideBarBreadcrumb';
import FilesInput from '@/components/atoms/inputs/FilesInput/FilesInput';
import useBrandKitComponent from '@/Pages/BrandKit/components/useBrandKitComponent';
import { getBrandKitUrl } from '@/Pages/BrandKit/components/utils';
import { showErrorToast } from '@/libs/toast/toast';
import { BRAND_KIT_MAX_FILE_SIZE } from '@/Pages/BrandKit/constants';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

const SUPPORTED_TYPES = '.mp4,.mov';

export default function IntroOutroPopover({
  setSideBarContext,
  title
}: {
  setSideBarContext?: (context: SidebarContext) => void;
  title?: string;
}) {
  const REMOVED_BRAND_KIT_NAME = 'placeholder';
  const { clipId, clipData } = useClipsContext();
  const { brandKitStore, clipsBrandKitStore, onFilesUpload, deleteItem } = useBrandKitComponent(
    'intro_outro',
    'Intro / Outro',
    clipData.id
  );

  const brandKitIntroOutro = useMemo(() => {
    return brandKitStore.intro_outro.map(video => getBrandKitUrl(video));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandKitStore.intro_outro]);

  const clipIntroOutro = useMemo(() => {
    const introOutroPreviewList = clipsBrandKitStore[clipId].intro_outro.map(video => {
      return {
        name: video,
        url: getBrandKitUrl(video, clipData.id)
      };
    });
    const currentIntro = clipData.asset_metadata.intro;
    if (shouldAddCurrentIntroOutroToList(currentIntro, introOutroPreviewList)) {
      introOutroPreviewList.push({ url: currentIntro!, name: REMOVED_BRAND_KIT_NAME });
    }
    const currentOutro = clipData.asset_metadata.outro;
    if (shouldAddCurrentIntroOutroToList(currentOutro, introOutroPreviewList)) {
      introOutroPreviewList.push({ url: currentOutro!, name: REMOVED_BRAND_KIT_NAME });
    }
    return introOutroPreviewList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clipsBrandKitStore[clipId].intro_outro, brandKitIntroOutro]);

  function shouldAddCurrentIntroOutroToList(
    currentIntroOutro: string | undefined,
    introOutroPreviewList: { url: string; name: string }[]
  ): boolean {
    return (
      !!currentIntroOutro &&
      currentIntroOutro.includes('brand_kit') &&
      !brandKitIntroOutro.includes(currentIntroOutro) &&
      !introOutroPreviewList.find(video => video.url === currentIntroOutro)
    );
  }

  function uploadFiles(files: File[]) {
    onFilesUpload(files).catch(() =>
      showErrorToast(
        `Oops! Uploading your intro/outro encountered an issue. Please make sure your file is in MP4 format and doesn't exceed ${BRAND_KIT_MAX_FILE_SIZE}MB, then give it another go.`
      )
    );
  }

  return (
    <Transition
      appear
      show={true}
      as={'div'}
      className={
        'h-full grow px-4 py-3 ' + (!title ? 'max-h-[500px] overflow-x-hidden overflow-y-scroll' : 'overflow-hidden')
      }
      enter="transform duration-200 ease-out"
      enterFrom="translate-x-[40rem]"
      enterTo="translate-x-0"
      leave="transform duration-150 ease-in"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-[40rem]"
    >
      {title && <SideBarBreadcrumb setSideBarContext={setSideBarContext!} title={title} />}
      <div className="flex w-full flex-col items-center justify-center !text-slate-500">
        {!clipIntroOutro.length ? (
          <div className="h-36">
            <FilesInput
              onFilesUpload={uploadFiles}
              accept={SUPPORTED_TYPES}
              title="Upload a file"
              label="Click to browse, or drag & drop a file here"
              icon="IconUpload"
            />
          </div>
        ) : (
          <div className="grid w-full grid-cols-2 gap-3">
            {clipIntroOutro.map(video => (
              <IntroOutroVideoPreview
                url={video.url}
                onDelete={video.name === REMOVED_BRAND_KIT_NAME ? undefined : () => deleteItem(video.name)}
                key={video.url}
              />
            ))}
            <div className="aspect-video">
              <FilesInput onFilesUpload={onFilesUpload} accept={SUPPORTED_TYPES} label="" icon="IconUpload" />
            </div>
          </div>
        )}
      </div>
      <div className="mt-5">
        <div className="text-sm font-medium">Brand Intro/Outro</div>
        {!!brandKitStore.intro_outro.length ? (
          <div className="mt-2 grid w-full grid-cols-2 gap-3">
            {brandKitIntroOutro.map(video => (
              <IntroOutroVideoPreview url={video} key={video} />
            ))}
          </div>
        ) : (
          <div className="mt-1.5 flex w-full items-center justify-center space-x-2 rounded-md bg-slate-100 py-5 text-center text-xs text-slate-500">
            <IconTag className="h-4 w-4" />
            <span>
              Upload branded intros and outros in{' '}
              <Link to="/brand-kit" className="underline hover:no-underline">
                Brand Kit
              </Link>
            </span>
          </div>
        )}
      </div>
    </Transition>
  );
}
