import { AdjustedBoxBoundary, SpeakersAnalysis } from './speakersAnalysisTypes';

export const findAdjustedBoxBoundaries = (
  time_analysis: SpeakersAnalysis['time_analysis']
): Record<string, AdjustedBoxBoundary>[] => {
  const adjustedBoxBoundaries = time_analysis.map(t => {
    const face_positions = t.face_positions;

    const boxesAtAnalysisPoint = face_positions.map(face_position => {
      const cropPosition = face_position.crop_position_person || face_position.crop_position_face;
      if (!cropPosition) {
        return null;
      }
      return {
        face_id: face_position.face_id,
        left_x: cropPosition.top_left.x,
        right_x: cropPosition.bottom_right.x,
        top_y: cropPosition.top_left.y,
        bottom_y: cropPosition.bottom_right.y
      };
    });

    return findNearestBoundaries(boxesAtAnalysisPoint);
  });

  return adjustedBoxBoundaries;
};

const findNearestBoundaries = (
  boxes: ({
    face_id: number;
    left_x: number;
    right_x: number;
    top_y: number;
    bottom_y: number;
  } | null)[]
): Record<string, AdjustedBoxBoundary> => {
  // Helper function to check if two boxes overlap vertically
  const hasVerticalOverlap = (box1, box2) => {
    return !(box1.bottom_y <= box2.top_y || box1.top_y >= box2.bottom_y);
  };

  // Helper function to check if two boxes overlap horizontally
  const hasHorizontalOverlap = (box1, box2) => {
    return !(box1.right_x <= box2.left_x || box1.left_x >= box2.right_x);
  };

  const finalFaceIdBoundaryMap = {};

  boxes.forEach(currentBox => {
    if (!currentBox) return;

    let newBoundaries = {
      boundary_left_x: 0, // Default to canvas left
      boundary_right_x: 1, // Default to canvas right
      boundary_top_y: 0, // Default to canvas top
      boundary_bottom_y: 1 // Default to canvas bottom
    };

    // For each direction, find the nearest boundary from other boxes
    boxes.forEach(otherBox => {
      if (!otherBox || otherBox.face_id === currentBox.face_id) return;

      // For left boundary
      if (hasVerticalOverlap(currentBox, otherBox)) {
        if (otherBox.right_x <= currentBox.left_x) {
          // Other box is to the left
          newBoundaries.boundary_left_x = Math.max(newBoundaries.boundary_left_x, otherBox.right_x);
        }
        if (otherBox.left_x >= currentBox.right_x) {
          // Other box is to the right
          newBoundaries.boundary_right_x = Math.min(newBoundaries.boundary_right_x, otherBox.left_x);
        }
      }

      // For top/bottom boundary
      if (hasHorizontalOverlap(currentBox, otherBox)) {
        if (otherBox.bottom_y <= currentBox.top_y) {
          // Other box is above
          newBoundaries.boundary_top_y = Math.max(newBoundaries.boundary_top_y, otherBox.bottom_y);
        }
        if (otherBox.top_y >= currentBox.bottom_y) {
          // Other box is below
          newBoundaries.boundary_bottom_y = Math.min(newBoundaries.boundary_bottom_y, otherBox.top_y);
        }
      }
    });

    finalFaceIdBoundaryMap[currentBox.face_id] = newBoundaries;
  });

  return finalFaceIdBoundaryMap;
};
