import React, { useMemo, useState, useSyncExternalStore } from 'react';
import { useParams } from 'react-router-dom';
import { IconAlertTriangle, IconPlayerPauseFilled, IconPlayerPlayFilled } from '@tabler/icons-react';
import HlsPlayer from './HlsPlayer';
import useTranscriptPlayPause from '../TranscriptPage/useTranscriptPlayPause';
import { player } from '@/stores/player';
import { getTrackSrc } from '@/libs/utils';
import { ClipMetadata } from '@/domains/asset';
import { getVideoManifestUrl } from '@/stores/core';

export default function Player({ clip = {} as ClipMetadata, onEnded = () => {}, ...rest }) {
  const { eventId, broadcastId } = useParams<{ eventId: string; broadcastId: string }>();
  const playerStore = useSyncExternalStore(player.subscribe, player.getSnapshot);
  const [loaded, setLoaded] = useState(false);
  const [isLoadFailed, setIsLoadFailed] = useState(false);
  const { play, pause } = useTranscriptPlayPause();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const url = useMemo(() => getVideoManifestUrl(), [eventId, broadcastId]);
  const trackSrc = useMemo(() => {
    return getTrackSrc(eventId as string, broadcastId as string);
  }, [eventId, broadcastId]);

  function cueChange(videoRef: HTMLVideoElement) {
    const id = Array.from(videoRef?.textTracks[0].activeCues ?? [])[0]?.id;
    player.update(data => ({ ...data, currentSrtIndex: +id }));
  }

  function onPause() {
    player.update(data => ({ ...data, paused: true }));
    if (clip && playerStore.currentTime >= clip.end) {
      onEnded?.();
      return;
    }
  }

  function onVideoLoaded() {
    setLoaded(true);
  }

  function showErrorState() {
    setIsLoadFailed(true);
  }

  function onClick() {
    if (playerStore.paused) {
      play();
    } else {
      pause();
    }
  }

  const isPlaying = useMemo(() => !playerStore.paused, [playerStore.paused]);

  return (
    <div className="group relative h-full w-full overflow-hidden">
      {isLoadFailed ? (
        <div className="flex h-full w-full flex-col items-center justify-center text-slate-400">
          <IconAlertTriangle size={32} stroke={1.5} />
          We hit a bump loading your video. Try a refresh to give it another go.
        </div>
      ) : (
        <>
          <HlsPlayer
            url={url}
            trackSrc={trackSrc}
            {...rest}
            className="absolute h-full w-full"
            currentTime={playerStore.currentTime}
            onCueChange={cueChange}
            onPause={onPause}
            onError={showErrorState}
            onPlaying={() => {
              player.update(data => ({ ...data, paused: false }));
            }}
            onLoadedData={onVideoLoaded}
          />
          {!loaded && (
            <div className="absolute left-0 top-0 h-full w-full animate-pulse rounded-sm bg-slate-200 py-3">
              <span className="sr-only">Loading player...</span>
            </div>
          )}
          <div
            className="absolute top-0 hidden h-full w-full items-center justify-center group-hover:flex"
            onClick={onClick}
          >
            {isPlaying ? (
              <IconPlayerPauseFilled className="cursor-pointer text-white" size={45} />
            ) : (
              <IconPlayerPlayFilled className="cursor-pointer text-white" size={45} />
            )}
          </div>
        </>
      )}
    </div>
  );
}
