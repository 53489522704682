import { roundToNDecimalPlaces } from './utils';
import {
  saveClipChanges,
  trackClipUpdate,
  updateClipCaptionsLoadingState,
  updateClipMetadataWithId
} from '@/stores/clip';

export function resetFullClipChanges(videoAssetId: string, recordingDuration: number) {
  updateClipCaptionsLoadingState(videoAssetId, true);
  const duration = roundToNDecimalPlaces(recordingDuration, 3);
  updateClipMetadataWithId(videoAssetId, {
    intro: undefined,
    outro: undefined,
    deletes: {},
    excludes: [],
    edits: {},
    merges: [],
    start: 0,
    end: duration,
    duration: duration
  });
  saveClipChanges(videoAssetId, false, true, false);
  trackClipUpdate(videoAssetId, 'RESET_FULL_RECORDING_CHANGES');
}
