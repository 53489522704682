import { memo, useState, useSyncExternalStore } from 'react';
import { assetsSavedSearchGenerateAssetsCreate } from '@goldcast/api/content';
import IconButton from '@/components/atoms/Button/IconButton';
import { savedAISearch } from '@/stores/savedAISearch';
import { useAppContext } from '@/context/AppContext/AppContext';
import { showErrorToast } from '@/libs/toast/toast';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';

function GenerateMoreAITextButton({
  refetch,
  generatePost,
  buttonTextContent
}: {
  refetch: () => void;
  generatePost: () => Promise<void>;
  buttonTextContent: string;
}) {
  const [isLoading, setIsLoading] = useState(false);

  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const savedAiSearchStore = useSyncExternalStore(savedAISearch.subscribe, savedAISearch.getSnapshot);
  const isAIVideoSourceSelectEnabled = featureFlags[FeatureFlagKeys.Use_CL_AI_Search_Source_Select];

  const { logger } = useAppContext();

  function generateMore() {
    setIsLoading(true);
    assetsSavedSearchGenerateAssetsCreate({
      id: savedAiSearchStore!.id,
      queryParams: {},
      body: { generation_type: 'SOCIAL' }
    })
      .then(() => {
        refetch();
        EventBus.dispatch(CustomEvents.ReloadAllClips);
      })
      .catch(e => {
        showErrorToast(
          'Whoops! We encountered an issue while attempting to generate more Social Posts. Please try again.'
        );
        logger.error(`Error generating more AI Social Posts: ${e}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const generateMorePost = () => {
    setIsLoading(true);
    generatePost().finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <div className="flex items-center justify-center border-t bg-white px-6 py-3">
      {isLoading ? (
        <IconButton
          icon="IconLoader2"
          content=""
          variation="filled"
          size="full"
          disabled={true}
          iconClassName="animate-spin"
          trackingId="loading-more-ai-search-clips-button"
        />
      ) : (
        <IconButton
          icon="IconSparkles"
          content={buttonTextContent}
          variation="filled"
          disabled={isLoading}
          size="full"
          trackingId="genereate-more-ai-search-clips-button"
          onClick={isAIVideoSourceSelectEnabled ? generateMorePost : generateMore}
        />
      )}
    </div>
  );
}

export default memo(GenerateMoreAITextButton);
