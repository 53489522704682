import { memo } from 'react';
import { IconX } from '@tabler/icons-react';

function FailedOverlay() {
  return (
    <div
      id="clip-vision-analysis-failed-overlay"
      className="absolute inset-0 flex flex-col items-center justify-center rounded-md bg-gray-200 px-1"
    >
      <IconX size={20} />
      <div className="mt-3 font-medium">Failed to process clip</div>
      <div className="mt-1 text-center text-xs text-slate-700">Please switch to a different layout or template</div>
    </div>
  );
}

export default memo(FailedOverlay);
