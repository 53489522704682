import { Fragment, memo } from 'react';
import { IconTriangleInvertedFilled } from '@tabler/icons-react';

function SectionProgressIndicator({ progressPercent }: { progressPercent: number }) {
  return (
    <Fragment>
      <IconTriangleInvertedFilled
        className="absolute top-[-1px] z-30 translate-x-[-7px] translate-y-[-16px]"
        size={15}
        style={{ left: progressPercent + '%' }}
      />

      <div
        className="pointer-events-none absolute top-0 z-30 h-full w-0.5 bg-black"
        style={{ left: progressPercent + '%' }}
      />
    </Fragment>
  );
}

export default memo(SectionProgressIndicator);
