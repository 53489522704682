import { CaptionStyle, CaptionsPlacement } from '@/domains/asset';

export enum CaptionSize {
  Small = 'Small',
  Medium = 'Medium'
}

export const CAPTION_SIZE_DEFAULT_OPTION = {
  value: 1,
  label: CaptionSize.Medium
};

export const STYLE_PREVIEW_TEXT = 'The Quick Brown Fox Jumps Over The Lazy Dog';

export const CAPTION_SIZE_OPTIONS = [
  {
    value: 0.7,
    label: CaptionSize.Small
  },
  CAPTION_SIZE_DEFAULT_OPTION
];

export const CAPTIONS_MIN_WIDTH = {
  PORTRAIT: 76,
  LANDSCAPE: 36,
  SQUARE: 45
};

export const CAPTION_HIGHLIGHT_OPTIONS = [
  {
    value: 'none',
    label: 'None'
  },
  {
    value: 'text',
    label: 'Text Highlight'
  },
  {
    value: 'box',
    label: 'Box Highlight'
  }
];

export const CAPTION_ANIMATION_STYLE_OPTIONS = [
  { label: CaptionStyle.Basic },
  { label: CaptionStyle.Outline },
  { label: CaptionStyle.Background }
];

export const CAPTIONS_PLACEMENT_OPTIONS = [
  {
    value: CaptionsPlacement.Top,
    next: CaptionsPlacement.Middle
  },
  {
    value: CaptionsPlacement.Middle,
    next: CaptionsPlacement.Bottom
  },
  {
    value: CaptionsPlacement.Bottom,
    next: CaptionsPlacement.Top
  }
];
