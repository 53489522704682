import { memo } from 'react';

const Done = () => {
  return (
    <div className="grow overflow-hidden p-1">
      <div className="grid h-full grid-cols-1 gap-4">
        <div className="flex h-full flex-col items-center justify-center rounded-lg p-4">
          <div className="mb-6 flex h-12 w-12 items-center justify-center rounded-full bg-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
            </svg>
          </div>
          <p className="mb-2 text-center text-slate-600">We're just waiting for your video to be processed.</p>
        </div>
      </div>
    </div>
  );
};

export default memo(Done);
