import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import JSConfetti from 'js-confetti';
import { IconCheck, IconX } from '@tabler/icons-react';
import { userPaymentGetCheckoutStatusRetrieve } from '@goldcast/api/content';
import ContentDialog from '@/Pages/Sessions/uiComponents/ContentDialog/ContentDialog';
import Button from '@/components/atoms/Button/Button';
import Loader from '@/components/atoms/Loader';
import { classnames } from '@/libs/utils';
import { useAppContext } from '@/context/AppContext/AppContext';

export default function CheckoutStatusDialog({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const { adminAppRouter } = useAppContext();
  const [paymentStatusLoading, setPaymentStatusLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  const removeSessionId = (location, navigate) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('checkout_session_id');
    navigate(`?${searchParams.toString()}`);
  };

  const paymentStatusDetails = {
    success: {
      icon: <IconCheck size={38} />,
      color: 'bg-[#5449D9]  shadow-[#7B72FF]/30',
      message: 'Success!',
      description: "You've successfully upgraded to the Pro plan. Are you ready?",
      ctaText: 'Let’s Go!',
      action: () => {
        removeSessionId(location, navigate);
        onClose();
      }
    },
    failed: {
      icon: <IconX size={38} />,
      color: 'bg-red-500 shadow-red-300/30',
      message: 'Uh oh!',
      description: 'Something went wrong processing your payment. Please try again.',
      ctaText: 'Try Again',
      action: () => {
        removeSessionId(location, navigate);
        adminAppRouter.replace({ hash: '#pricing' });
        onClose();
      }
    }
  };

  useEffect(() => {
    if (!location.search.includes('checkout_session_id')) {
      return;
    }
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('checkout_session_id') as string;
    let jsConfetti;

    userPaymentGetCheckoutStatusRetrieve({
      queryParams: {
        session_id: sessionId
      }
    })
      .then(response => {
        if (response?.payment_status === 'complete') {
          setPaymentStatus('success');
          jsConfetti = new JSConfetti();
          jsConfetti.addConfetti();
        }

        if (response?.payment_status === 'expired') {
          setPaymentStatus('failed');
        }
        setPaymentStatusLoading(false);
      })
      .catch(() => {
        setPaymentStatusLoading(false);
        setPaymentStatus('failed');
      });

    return () => {
      jsConfetti?.clearCanvas();
    };
  }, []);

  if (paymentStatusLoading) {
    return <Loader />;
  }

  return (
    <ContentDialog isOpen={isOpen} size="medium" setIsOpen={onClose} disableBackdropClose={true}>
      <div className="relative flex items-center py-12 pt-4">
        <div className="pl-18 flex w-full flex-col items-center justify-center px-8 text-center">
          <div
            className={classnames(
              'flex h-24 w-24 items-center justify-center rounded-full text-white shadow-xl',
              paymentStatusDetails[paymentStatus]?.color
            )}
          >
            {paymentStatusDetails[paymentStatus]?.icon}
          </div>
          <div className="mt-5 text-center text-4xl font-semibold tracking-tight">
            {paymentStatusDetails[paymentStatus]?.message}
          </div>
          <div className="mt-3 w-96 text-xl italic leading-tight text-slate-500">
            {paymentStatusDetails[paymentStatus]?.description}
          </div>
          <div className="mt-6">
            <Button
              variation="filled"
              buttonSize="large"
              onClick={paymentStatusDetails[paymentStatus]?.action}
              trackingId="upgrade-success"
            >
              {paymentStatusDetails[paymentStatus]?.ctaText}
            </Button>
          </div>
        </div>
      </div>
    </ContentDialog>
  );
}
