import IconButton from '@/components/atoms/Button/IconButton';

export default function MatchSpeakersCTA({ onClick }: { onClick: () => void }) {
  return (
    <div className="mt-1.5 flex items-center justify-between rounded-md bg-slate-100 p-2">
      <div className="text-xs text-slate-700">Speakers not correctly identified?</div>
      <IconButton
        icon="IconUserEdit"
        content="Identify Speakers"
        onClick={onClick}
        size="small"
        trackingId="match-speakers-button"
      />
    </div>
  );
}
