import { useEffect, useRef, useState, useMemo } from 'react';
import { IconPlayerPauseFilled, IconPlayerPlayFilled, IconMovie } from '@tabler/icons-react';
import { ContentStatesEnum } from './constants';
import HlsPlayerV2 from '@/Pages/Clip/ClipPlayer/HlsPlayerV2';
import { classnames } from '@/libs/utils';
import { formatTime } from '@/libs/core';
import Loader from '@/components/atoms/Loader';
import Waveform from '@/components/atoms/Waveform/Waveform';

export default function ContentVideoPlayer({
  isHlsPlayer,
  isAudioContent,
  videoUrl,
  contentState
}: {
  contentState: ContentStatesEnum;
  isHlsPlayer: boolean;
  isAudioContent: boolean;
  videoUrl: string;
}) {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [isVideoLoading, setIsVideoLoading] = useState(!isHlsPlayer);
  const [isVideoLoadingFailed, setIsLoadFailed] = useState(false);
  const [isHlsPlayerVideoLoaded, setIsHlsPlayerVideoLoaded] = useState(!isHlsPlayer);

  const isContentUploading = useMemo(() => contentState === ContentStatesEnum.Uploading, [contentState]);
  const isContentGenerating = useMemo(() => contentState === ContentStatesEnum.Generating, [contentState]);

  const showHlsPlayer = useMemo(() => {
    return isHlsPlayer && isContentGenerating && !isVideoLoadingFailed;
  }, [isHlsPlayer, isContentGenerating, isVideoLoadingFailed]);

  const progress = useMemo(() => {
    return videoRef.current ? (currentTime / videoRef.current.duration) * 100 : 0;
  }, [currentTime]);

  const showPlayerControls = useMemo(() => {
    return (!isContentUploading && !isHlsPlayer) || showHlsPlayer;
  }, [isContentUploading, isHlsPlayer, showHlsPlayer]);

  const { contentCurrentTime, totalVideoDuration } = useMemo(() => {
    if (isVideoLoading || !isHlsPlayerVideoLoaded) {
      return {
        contentCurrentTime: 0,
        totalVideoDuration: 0
      };
    }
    return {
      contentCurrentTime: videoRef.current ? formatTime(currentTime) : 0,
      totalVideoDuration: videoRef.current ? formatTime(videoRef.current.duration) : 0
    };
  }, [currentTime, isVideoLoading, isHlsPlayerVideoLoaded]);

  function handleLoadedData() {
    setIsVideoLoading(false);
  }

  function onLoadFailed() {
    setIsVideoLoading(false);
    setIsLoadFailed(true);
  }

  function playVideo() {
    if (!!videoRef.current) {
      setIsPlayingVideo(true);
      videoRef.current.play();
    }
  }

  function pauseVideo() {
    if (!!videoRef.current) {
      setIsPlayingVideo(false);
      videoRef.current.pause();
    }
  }

  function updateVideoTime(ev: React.MouseEvent) {
    if (!ev.nativeEvent || !ev.currentTarget || !videoRef.current) return;
    const perc = (ev.nativeEvent.offsetX / ev.currentTarget.clientWidth) * 100;
    videoRef.current.currentTime = (videoRef.current?.duration / 100) * perc;
  }

  const handleHlsPlayerLoaded = () => {
    setIsHlsPlayerVideoLoaded(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(videoRef.current ? videoRef.current?.currentTime : 0);
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [isPlayingVideo]);

  return (
    <div className="flex items-center justify-center px-12">
      <div
        className={classnames('group relative aspect-video w-full max-w-4xl overflow-hidden rounded-2xl bg-slate-200', {
          blur: isContentUploading
        })}
      >
        {isVideoLoading ? (
          <div className="absolute right-[47%] top-[45%]">
            <Loader />
          </div>
        ) : (
          <>
            {showPlayerControls && (
              <div className="absolute bottom-0 z-30 w-full translate-y-1 cursor-pointer bg-gradient-to-t from-black/60 to-black/0 px-3 pb-3 pt-8 opacity-0 transition-all duration-200 ease-in-out group-hover:translate-y-0 group-hover:opacity-100">
                <div className="h-1 w-full bg-white/30" onClick={updateVideoTime}>
                  <div className="h-1 bg-deep-orange" style={{ width: `${progress}%` }}></div>
                </div>
                <div className="mt-2.5 flex items-center space-x-5 px-3">
                  <button className="text-white focus:outline-none focus:ring-0">
                    {isPlayingVideo ? (
                      <IconPlayerPauseFilled size={23} onClick={pauseVideo} />
                    ) : (
                      <IconPlayerPlayFilled size={23} onClick={playVideo} />
                    )}
                  </button>

                  <div className="flex space-x-3 text-xs tabular-nums text-white">
                    <div>{contentCurrentTime}</div>
                    <div className="opacity-50">/</div>
                    <div className="opacity-50">{totalVideoDuration}</div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {isAudioContent && (
          <div className="absolute right-[47%] top-[40%]">
            <Waveform textColor="white" paused={!isPlayingVideo} height={80} />
          </div>
        )}
        {!isHlsPlayer ? (
          <video ref={videoRef} className="w-full" src={videoUrl} onLoadedData={handleLoadedData}></video>
        ) : showHlsPlayer ? (
          <div className="relative h-full w-full overflow-hidden rounded-md">
            <HlsPlayerV2
              videoRef={videoRef}
              onLoaded={handleHlsPlayerLoaded}
              url={videoUrl}
              id="mainPlayer"
              onError={onLoadFailed}
              videoProps={{
                className: 'w-full h-full'
              }}
            />
          </div>
        ) : (
          <div className="absolute right-[47%] top-[45%]">
            <div className="flex aspect-video w-full items-center justify-center rounded-xl bg-slate-200">
              <IconMovie className="h-8 w-8 text-slate-400" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
