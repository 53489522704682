import React from 'react';
import * as filestack from 'filestack-js';
import { getEnvConfig } from '@/constants';

// This file can be improved
export default function FilestackUploader({
  s3Path = getEnvConfig('FILESTACK_BUCKET_PATH'),
  allowManualRetry = true,
  acceptedTypes = ['video/*'],
  errorsTimeout = undefined,
  onUpload,
  children,
  ...rest
}) {
  const options = {
    fromSources: ['local_file_system'],
    storeTo: {
      location: 's3',
      container: getEnvConfig('S3_STATIC_ASSETS_BUCKET'),
      path: s3Path,
      region: getEnvConfig('AWS_STORE_REGION')
    },
    accept: acceptedTypes,
    uploadConfig: {
      retry: 3,
      timeout: 60000
    },
    uploadInBackground: false,
    disableTransformer: true,
    maxFiles: 1,
    minFiles: 1,
    exposeOriginalFile: true,
    disableStorageKey: false,
    maxSize: 50 * 1024 * 1024, // change default size to 50MB, it used for images
    allowManualRetry,
    errorsTimeout,
    onUploadDone: (res: any) => {
      onUpload(res.filesUploaded[0].url);
    }
  };

  const client = filestack.init(getEnvConfig('FILESTACK_API_KEY'));

  const picker = client.picker(options);

  return (
    <div onClick={() => picker.open()} {...rest}>
      {children}
    </div>
  );
}
