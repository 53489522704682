import { memo } from 'react';
import BrandingColorSelect from './BrandingColorSelect/BrandingColorSelect';
import BrandingFontSelect from './BrandingFontSelect/BrandingFontSelect';

const BrandingSelect = () => {
  return (
    <div className="grow p-1">
      <div className="grid h-full grid-cols-2 gap-4">
        <BrandingColorSelect />
        <BrandingFontSelect />
      </div>
    </div>
  );
};

export default memo(BrandingSelect);
