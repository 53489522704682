import { DragHandleProps } from './DragHandleTypes';
import { DivProps } from '@/domains/global';

export const DRAG_HANDLE_WIDTH = 14;

export function getDragHandleClassName(type: DragHandleProps['type']): DivProps['className'] {
  switch (type) {
    case 'start':
      return 'left-0 rounded-l-md';
    case 'end':
      return 'right-0 rounded-r-md';
  }
}
