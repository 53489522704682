export default function ClipListPreviewSkeleton({ count, isCollapsed }: { count: number; isCollapsed: boolean }) {
  return (
    <>
      {Array(count)
        .fill(0)
        .map((_, i) => (
          <div
            key={i}
            className="group flex w-full animate-pulse cursor-pointer items-stretch space-x-4 px-5 py-3 pr-3 text-left"
          >
            <div className="flex aspect-[4/3] w-56 shrink-0 flex-col items-center justify-center rounded-xl bg-slate-200">
              <div className="flex aspect-video w-[70%] items-center justify-center rounded-md bg-slate-300" />
            </div>
            {!isCollapsed ? (
              <div className="flex grow flex-col justify-between pt-0.5">
                <div>
                  <div className="mt-1 h-3 w-[240px] rounded-sm bg-slate-200"></div>
                  <div className="mt-1 h-3 w-[180px] rounded-sm bg-slate-200"></div>
                </div>
                <div className="my-1 h-3 w-10 rounded-sm bg-slate-200"></div>
              </div>
            ) : null}
          </div>
        ))}
    </>
  );
}
