import { BRAND_KIT_ACCEPT_FORMATS } from '../../constants';
import useBrandKitComponent from '../useBrandKitComponent';
import FontPreview from './FontPreview';
import FilesInput from '@/components/atoms/inputs/FilesInput/FilesInput';
import { showErrorToast } from '@/libs/toast/toast';
import styles from '../../BrandKit.module.scss';

export default function Fonts(): JSX.Element {
  const { brandKitStore, onFilesUpload, deleteItem } = useBrandKitComponent('fonts', 'Fonts');

  function uploadFiles(files: File[]) {
    onFilesUpload(files).catch(() =>
      showErrorToast('Uploading font encountered a hiccup. Check for supported font types and upload again.')
    );
  }

  return (
    <div className="relative flex flex-wrap gap-3 py-2">
      <div className="flex aspect-[16/11] w-56 flex-col items-center justify-center !text-slate-500">
        <FilesInput
          onFilesUpload={uploadFiles}
          accept={BRAND_KIT_ACCEPT_FORMATS.fonts}
          label="Add Font"
          multiple={false}
          icon="IconTypography"
          containerClassNames={styles['brand-picker']}
          labelClassNames="text-xs"
          iconProps={{
            size: 20,
            stroke: 1.5
          }}
        />
      </div>
      {brandKitStore.fonts.map(font => (
        <FontPreview key={font} font={font} onDelete={deleteItem} />
      ))}
    </div>
  );
}
