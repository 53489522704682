import React, { useCallback, useEffect, useMemo } from 'react';
import { IconPlayerPauseFilled, IconPlayerPlayFilled } from '@tabler/icons-react';
import { isAnyDialogOpened } from '@/Pages/Sessions/uiComponents/ContentDialog/utils';
import { classnames } from '@/libs/utils';

export default function PlayPause({ paused, onPlay, onPause, disabled = false }) {
  const togglePlay = useCallback(() => {
    paused ? onPlay() : onPause();
  }, [onPause, onPlay, paused]);

  const keyboardInputBlocked = useMemo(() => window.location.pathname.includes('/blog'), []);

  useEffect(() => {
    // Play/pause video on space press
    const handleKeyboardEvent = (ev: KeyboardEvent) => {
      const target = ev.target as HTMLElement;
      if (
        ev.code === 'Space' &&
        !keyboardInputBlocked &&
        target.tagName.toLowerCase() !== 'input' &&
        target.tagName.toLowerCase() !== 'textarea' &&
        target.contentEditable !== 'true' &&
        !isAnyDialogOpened()
      ) {
        ev.preventDefault();
        (ev.target as HTMLButtonElement)?.blur();
        togglePlay();
      }
    };
    window.addEventListener('keydown', handleKeyboardEvent);
    return () => {
      window.removeEventListener('keydown', handleKeyboardEvent);
    };
  }, [togglePlay]);

  return (
    <button
      className={classnames(
        'flex h-10 w-10 shrink-0 items-center justify-center rounded-md hover:bg-slate-100',
        paused && !disabled ? 'text-slate-700' : 'text-slate-500 hover:text-slate-700',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      )}
      title={disabled ? 'Please wait for the video(s) to load' : paused ? 'Play' : 'Pause'}
      disabled={disabled}
      onClick={togglePlay}
      id="main-player-play-pause-button"
      data-testid="main-player-play-pause-button"
    >
      {paused ? <IconPlayerPlayFilled size={28} /> : <IconPlayerPauseFilled size={24} />}
    </button>
  );
}
