export function convertSecondsToHoursAndMinutes(seconds: number): { hours: number; minutes: number } {
  const hours = Math.floor(seconds / 3600);
  const remainingSeconds = seconds % 3600;
  const minutes = Math.round(remainingSeconds / 60);
  return { hours, minutes };
}

export function formatTime(hours, minutes) {
  return hours === 0 ? `${minutes} min` : `${hours} hr ${minutes} min`;
}

export function calculatePeriod(contractStartDateStr: string, durationDays: number = 30) {
  const contractStartDate = new Date(contractStartDateStr);
  const currentDate = new Date();

  let startOfCurrentPeriod = new Date(contractStartDate);
  startOfCurrentPeriod.setFullYear(currentDate.getFullYear());

  startOfCurrentPeriod.setMonth(currentDate.getMonth() - (currentDate.getDate() < contractStartDate.getDate() ? 1 : 0));

  if (startOfCurrentPeriod < contractStartDate) {
    startOfCurrentPeriod = new Date(contractStartDate);
  }

  const endOfCurrentPeriod = new Date(startOfCurrentPeriod);
  endOfCurrentPeriod.setDate(startOfCurrentPeriod.getDate() + durationDays);

  return { startOfCurrentPeriod, endOfCurrentPeriod };
}

export function calculateDaysLeft(contractStartDateStr: string, durationDays: number = 30): number {
  const { endOfCurrentPeriod } = calculatePeriod(contractStartDateStr, durationDays);
  const currentDate = new Date();

  const timeDifference = endOfCurrentPeriod.getTime() - currentDate.getTime();
  return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
}
