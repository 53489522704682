import { Menu, Transition } from '@headlessui/react';
import { IconDotsVertical, IconTrash } from '@tabler/icons-react';
import { Fragment } from 'react';
import { MediaContent, assetsContentUploadDestroy } from '@goldcast/api/content';
import useDialog from '@/components/organisms/useDialog';
import ConfirmationDialog from '@/components/organisms/ConfirmationDialog';
import { useAppContext } from '@/context/AppContext/AppContext';
import { showErrorToast } from '@/libs/toast/toast';
import { classnames } from '@/libs/utils';

export default function SessionMenu({
  session,
  onDelete,
  position = 'bottom'
}: {
  session: MediaContent;
  onDelete: () => void;
  position?: 'bottom' | 'top';
}) {
  const { logger } = useAppContext();
  const menuItems = [
    {
      Icon: IconTrash,
      label: 'Delete',
      action: event => {
        event.stopPropagation();
        openDeleteConfirmationDialog();
      }
    }
  ];
  const {
    isOpen: isDeleteConfirmationOpen,
    openDialog: openDeleteConfirmationDialog,
    closeDialog: closeDeleteConfirmationDialog
  } = useDialog();

  const isCustomUpload = session.media_source_type === 'UPLOAD';

  function deleteRecording() {
    assetsContentUploadDestroy({ id: session.id })
      .then(() => {
        onDelete();
      })
      .catch(error => {
        logger.error(error);
        showErrorToast("Whoops! Deleting the recording didn't go as planned. Check your connection and try again.");
      })
      .finally(() => {
        closeDeleteConfirmationDialog();
      });
  }

  return (
    isCustomUpload && (
      <div className="relative flex w-12 flex-1 justify-end" onClick={e => e.stopPropagation()}>
        <Menu as="div" className="relative ml-auto">
          <Menu.Button
            data-testid="clip-menu-trigger"
            as="div"
            className="group flex h-6 w-6 shrink-0 cursor-pointer items-center justify-center rounded hover:bg-slate-200"
          >
            <IconDotsVertical className="h-5 w-5 text-slate-400 group-hover:text-slate-600" size={16} />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={classnames(
                'absolute right-2 z-50 w-32 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                position === 'top' ? 'bottom-6' : ''
              )}
            >
              {menuItems.map(menuItem => {
                const { Icon: MenuItemIcon, label, action } = menuItem;

                return (
                  <Menu.Item key={label}>
                    <button
                      onClick={action}
                      className="flex w-full cursor-pointer items-center space-x-2.5 truncate border-b px-3 py-2.5 last:border-none hover:bg-slate-100 hover:text-deep-orange-600"
                    >
                      <MenuItemIcon className="h-4 w-4" />
                      <span className="text-xs font-medium">{label}</span>
                    </button>
                  </Menu.Item>
                );
              })}
            </Menu.Items>
          </Transition>
        </Menu>
        <ConfirmationDialog
          isOpen={isDeleteConfirmationOpen}
          onClose={closeDeleteConfirmationDialog}
          onConfirm={deleteRecording}
          title="Delete Recording"
          content="Are you sure you want to permanently remove this recording? This action is irreversible."
          confirmLabel="Yes"
        />
      </div>
    )
  );
}
