import { memo, useCallback, useEffect, useState, useSyncExternalStore } from 'react';
import { IconCheck, IconTypography } from '@tabler/icons-react';
import { Listbox } from '@headlessui/react';
import { FONT_DEFAULT_VALUE } from './constants';
import BrandKitInlineEmptyState from '../EmptyStates/BrandKitInlineEmptyState';
import { clipsBrandKit } from '@/stores/brandKit';
import { updateFontLocation } from '@/stores/clip';
import { getFileNameFromPath, getFileNameWithoutExtension } from '@/libs/file';
import { loadFont } from '@/libs/fonts';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { classnames } from '@/libs/utils';
import Icon from '@/components/atoms/Icon';

function FontSelector({ isInline = false }: { isInline?: boolean }) {
  const { clipId, clipData } = useClipsContext();
  const clipsBrandKitStore = useSyncExternalStore(clipsBrandKit.subscribe, clipsBrandKit.getSnapshot);
  const fontLocation = clipData.asset_metadata.font_location || FONT_DEFAULT_VALUE.url;
  const [isDirectionUp, setIsDirectionUp] = useState(false);

  // If there is selected font that is not part of fontMap - add it to it
  useEffect(() => {
    if (
      !!fontLocation &&
      !Object.keys(clipsBrandKitStore[clipId].fontsMap.predefined).includes(fontLocation) &&
      !Object.keys(clipsBrandKitStore[clipId].fontsMap.brandKit).includes(fontLocation)
    ) {
      const fontName = getFileNameWithoutExtension(getFileNameFromPath(fontLocation));
      loadFont(fontLocation, fontName);
      clipsBrandKit.update(data => {
        const clipBrandKit = data[clipId];
        return {
          ...data,
          [clipId]: {
            ...clipBrandKit,
            fontsMap: {
              ...clipBrandKit.fontsMap,
              predefined: {
                ...clipBrandKit.fontsMap.predefined,
                [fontLocation]: fontName
              }
            }
          }
        };
      });
    }
  }, []);

  const selectedFontName = fontLocation
    ? clipsBrandKitStore[clipId].fontsMap.predefined[fontLocation] ||
      clipsBrandKitStore[clipId].fontsMap.brandKit[fontLocation]
    : FONT_DEFAULT_VALUE.name;

  const onFontChange = useCallback(
    (value: string) => {
      updateFontLocation(clipId, value);
    },
    [clipId]
  );

  function onFontsToggle(e: React.MouseEvent, open: boolean) {
    setIsDirectionUp(isInline && !open && window.innerHeight - e.clientY < 400);
  }

  return (
    <Listbox value={fontLocation} onChange={onFontChange}>
      {({ open }) => (
        <>
          <Listbox.Button
            className={classnames(
              'flex w-full items-center justify-between truncate rounded-lg font-bold',
              isInline ? 'group/button h-full p-2 hover:bg-slate-100' : 'border p-3'
            )}
            onClick={e => onFontsToggle(e, open)}
          >
            <div
              className="grow truncate text-left"
              style={{ fontFamily: selectedFontName }}
              data-testid="font-selector-button"
            >
              {selectedFontName}
            </div>
            <Icon name={open ? 'IconChevronUp' : 'IconChevronDown'} size={16} />
          </Listbox.Button>
          <Listbox.Options
            className={classnames(
              'z-40 mt-1.5 rounded-lg border bg-white shadow',
              isInline ? 'absolute h-80 w-96 overflow-y-auto' : 'absolute w-full',
              isDirectionUp ? 'bottom-11' : ''
            )}
          >
            <div className="p-2">
              {Object.keys(clipsBrandKitStore[clipId].fontsMap.predefined).map(fontUrl => {
                const fontName = clipsBrandKitStore[clipId].fontsMap.predefined[fontUrl];
                return (
                  <Listbox.Option
                    key={fontName}
                    value={fontUrl}
                    className="flex cursor-pointer items-center space-x-2.5 truncate rounded-md p-2 hover:bg-slate-100"
                  >
                    {({ selected }) => (
                      <>
                        <div className="w-4">{selected && <IconCheck className="text-slate-600" size={16} />}</div>

                        <span
                          style={{ fontFamily: fontName }}
                          className="truncate font-bold"
                          data-testid={`font-option-${fontName}`}
                        >
                          {fontName}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                );
              })}
            </div>
            <div className="my-0 flex items-center">
              <div className="h-[1px] w-full bg-slate-200"></div>
              <div className="flex w-full items-center justify-center space-x-0.5 rounded-full border bg-white px-3 py-1 text-center">
                <IconTypography size={16} className="mr-1" />
                <span className="text-xs">Brand Fonts</span>
              </div>
              <div className="h-[1px] w-full bg-slate-200"></div>
            </div>
            <div className="p-2">
              {!!Object.keys(clipsBrandKitStore[clipId].fontsMap.brandKit).length ? (
                Object.keys(clipsBrandKitStore[clipId].fontsMap.brandKit).map(fontUrl => {
                  const fontName = clipsBrandKitStore[clipId].fontsMap.brandKit[fontUrl];
                  return (
                    <Listbox.Option
                      key={fontUrl}
                      value={fontUrl}
                      className="flex cursor-pointer items-center space-x-2.5 rounded-md p-2 hover:bg-slate-100"
                    >
                      {({ selected }) => (
                        <>
                          <div className="w-4">
                            {selected && <IconCheck className="truncate text-slate-600" size={16} />}
                          </div>
                          <span
                            className="truncate"
                            style={{ fontFamily: `"${fontName}"` }}
                            data-testid={`font-option-${fontName}`}
                          >
                            {fontName}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  );
                })
              ) : (
                <BrandKitInlineEmptyState label="fonts" />
              )}
            </div>
          </Listbox.Options>
        </>
      )}
    </Listbox>
  );
}

export default memo(FontSelector);
