import NewTemplateDialog from './NewTemplateDialog';
import IconButton from '@/components/atoms/Button/IconButton';
import useDialog from '@/components/organisms/useDialog';

export default function NewTemplateButton({ className = '' }: { className?: string }) {
  const { isOpen, openDialog, closeDialog } = useDialog();

  return (
    <>
      <IconButton
        icon="IconTemplate"
        buttonClassName={className}
        onClick={openDialog}
        content="Save as Template"
        trackingId="add-brand-kit-template"
      />
      <NewTemplateDialog isOpen={isOpen} closeDialog={closeDialog} />
    </>
  );
}
