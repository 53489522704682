import { CombinedDownload } from '@goldcast/api/content';
import { IconCloudDownload } from '@tabler/icons-react';
import { memo } from 'react';
import DownloadsPopupListItem from './DownloadsPopupListItem';
import Loader from '@/components/atoms/Loader';

const DownloadsPopupTab = ({
  loading,
  downloads,
  emptyStateSubtext
}: {
  loading: boolean;
  downloads: CombinedDownload[];
  emptyStateSubtext: string;
}) => {
  return (
    <div className="h-[14rem] w-full overflow-y-auto overflow-x-hidden">
      {loading ? (
        <div className="flex h-full items-center justify-center">
          <Loader />
        </div>
      ) : downloads.length > 0 ? (
        <>
          {downloads.map(download => (
            <DownloadsPopupListItem key={`${download.id}_${download.status}`} download={download} />
          ))}
        </>
      ) : (
        <div className="flex h-full flex-col items-center justify-center ">
          <IconCloudDownload className="h-6 w-6 text-slate-400" />
          <div className="mt-2 flex flex-col items-center justify-center">
            <div className="text-sm font-medium">No downloads, yet.</div>
            <div className="mt-1 max-w-[12rem] text-center text-sm text-slate-600">{emptyStateSubtext}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(DownloadsPopupTab);
