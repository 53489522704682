export default function PostListSkeletonLoader({ isCollapsed }: { isCollapsed: boolean }) {
  return (
    <>
      {Array(5)
        .fill(0)
        .map((_, i) => (
          <div key={i} className="flex w-full animate-pulse space-x-4 px-5 py-3 pr-3">
            {!isCollapsed ? <div className="h-28 w-28 shrink-0 rounded-xl bg-slate-100"></div> : null}
            <div className="grow py-1">
              <div className="h-2.5 w-2/3 rounded-[3px] bg-slate-100" />
              <div className="mt-4 space-y-1.5">
                <div className="h-2 w-[90%] rounded-[3px] bg-slate-100" />
                <div className="h-2 w-[90%] rounded-[3px] bg-slate-100" />
                <div className="h-2 w-2/3 rounded-[3px] bg-slate-100" />
              </div>
            </div>
          </div>
        ))}
    </>
  );
}
