import { ContentUpload } from '@goldcast/api/content';
import moment from 'moment';
import LabeledInput from '@/components/atoms/LabeledInput';

export default function RecordingDetailsStep({
  recordingDetails,
  onRecordingUpdate,
  hideRecordingDate = false
}: {
  recordingDetails: Partial<ContentUpload>;
  onRecordingUpdate: (field: string, value: string) => void;
  hideRecordingDate?: boolean;
}) {
  const maxDate = moment().format('YYYY-MM-DD');
  return (
    <div>
      <LabeledInput
        classNames="pb-3"
        label="Title"
        isInvalid={!recordingDetails.title}
        isRequired
        data-testid="title-input"
        value={recordingDetails.title}
        onChange={value => onRecordingUpdate('title', value)}
      />
      {!hideRecordingDate && (
        <LabeledInput
          classNames="pb-3"
          label="Recording Date"
          data-testid="recording-date-input"
          type="date"
          isRequired
          isInvalid={!recordingDetails.recording_date}
          max={maxDate}
          value={recordingDetails.recording_date}
          onChange={value => onRecordingUpdate('recording_date', value)}
        />
      )}
    </div>
  );
}
