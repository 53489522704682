import createStore from './store';

export interface AssetRatingStore {
  assetRating: { [assetId: string]: AssetRatingState };
  isAssetRatingDialogOpen: boolean;
  selectedAssetId: string;
}

export interface AssetRatingState {
  ratingId: string;
  thumbsUp: boolean | null;
  feedbackMessage: string;
}

export const assetRatingStore = createStore<AssetRatingStore>({
  assetRating: {},
  isAssetRatingDialogOpen: false,
  selectedAssetId: ''
});
