import { memo, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { POST_TYPES } from './constants';
import { TextAsset } from './types';
import { loadTextAssets } from '@/libs/textContentUtil';
import useAnalytics from '@/hooks/useAnalytics';
import { usePostGenerationContext } from '@/context/PostGenerationContext/PostGenerationContext';
import { showErrorToast } from '@/libs/toast/toast';
import PostsListPage from '.';

function PostsListPageWrapper({ postType }) {
  const { eventId, broadcastId } = useParams<{ eventId: string; broadcastId: string }>();
  const [posts, setPosts] = useState<TextAsset[]>([]);
  const assetType = useMemo(() => (postType === POST_TYPES.SOCIAL ? 'LINKEDIN' : postType), [postType]);
  const { trackTextFetched } = useAnalytics();
  const isGeneratingAssets = usePostGenerationContext();

  function getTextAssets() {
    return loadTextAssets(eventId!, broadcastId!, assetType);
  }

  const { isFetching, refetch } = useQuery({
    queryKey: [broadcastId, eventId, postType],
    queryFn: getTextAssets,
    initialData: [],
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    cacheTime: 0,
    enabled: !isGeneratingAssets,
    onSuccess: data => {
      setPosts(data);
      trackTextFetched({ count: (data || []).length });
    },
    onError: () =>
      showErrorToast(
        'Whoops! Struggling to fetch the generated text right now. Could you check your network connection and give it another whirl?'
      )
  });

  return (
    <PostsListPage
      isFetching={isFetching}
      onRefetch={refetch}
      assetType={assetType}
      postType={postType}
      posts={posts}
      setPosts={setPosts}
      broadcastId={broadcastId}
      eventId={eventId}
    />
  );
}

export default memo(PostsListPageWrapper);
