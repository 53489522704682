import { IconInfoCircle, IconX, IconCheck } from '@tabler/icons-react';
import ContentDialog from '@/Pages/Sessions/uiComponents/ContentDialog/ContentDialog';
import Button from '@/components/atoms/Button/Button';

interface AlertDialogProps {
  heading: string;
  message?: string;
  type?: 'error' | 'success';
  primaryActionTitle: string;
  secondaryActionTitle?: string;
  actionsDisabled?: boolean;
  handlePrimaryActionClick: () => void;
  handleSecondaryActionClick?: () => void;
  isOpen: boolean;
}

export default function AlertDialog({
  heading,
  message = '',
  type,
  primaryActionTitle = '',
  secondaryActionTitle = '',
  actionsDisabled = false,
  handlePrimaryActionClick,
  handleSecondaryActionClick,
  isOpen = false
}: AlertDialogProps) {
  return (
    <ContentDialog
      isOpen={isOpen}
      title=""
      size="small"
      hideCloseIcon={true}
      setIsOpen={handlePrimaryActionClick}
      disableBackdropClose={true}
    >
      <div className="relative flex-1 overflow-y-auto overflow-x-hidden px-4 pb-6">
        <div className="flex items-center">
          {type === 'error' && (
            <div className="rounded-[50%] bg-red-50 p-2">
              <IconX className="h-6 w-6 text-red-500" />
            </div>
          )}
          {type === 'success' && (
            <div className="rounded-[50%] bg-green-50 p-2">
              <IconCheck className="h-6 w-6 text-green-500" />
            </div>
          )}
          {!type && (
            <div className="rounded-[50%] bg-deep-orange-50 p-2">
              <IconInfoCircle className="h-6 w-6 text-deep-orange-500" />
            </div>
          )}
          <div className="pl-4">
            <h5 className="mb-1 text-base font-medium leading-tight text-black lg:text-lg lg:leading-tight">
              {heading}
            </h5>
            <p className="!mb-0 text-sm text-slate-500">{message}</p>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-end border-t p-2">
        {secondaryActionTitle && (
          <Button
            trackingId="alert-dialog-secondary-action"
            variation="outline"
            disabled={actionsDisabled}
            onClick={handleSecondaryActionClick}
          >
            {secondaryActionTitle}
          </Button>
        )}
        {primaryActionTitle && (
          <Button
            trackingId="alert-dialog-primary-action"
            variation="filled"
            className="ml-2"
            disabled={actionsDisabled}
            onClick={handlePrimaryActionClick}
          >
            {primaryActionTitle}
          </Button>
        )}
      </div>
    </ContentDialog>
  );
}
