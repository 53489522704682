import { assetsAssetV2List, assetsTextAssetCreate, assetsTextCreate } from '@goldcast/api/content';
import { isCustomUpload } from './clipContentUtil';
import { core } from '@/stores/core';
import { TextType } from '@/domains/asset';
import { TextAsset, TextGenerateWithMultiModal } from '@/Pages/PostsListPage/types';

export function createTextAsset(
  body: TextGenerateWithMultiModal,
  output: string,
  type: TextType
): Promise<{ id: string }> {
  const content = core.getSnapshot().content;
  if (content?.media_source_type === 'UPLOAD') {
    return assetsTextCreate({
      body: {
        organization: content?.organization as string,
        content_id: content?.id as string,
        title: body.title as string,
        asset_metadata: {
          title: body.title,
          seoKeywords: body.seo_keywords,
          length: body.length,
          output,
          prompt: body.prompt,
          type,
          tone: body.brand_tone,
          is_multimodal: body?.is_multimodal || false,
          clip_assets: body?.clip_assets || []
        }
      }
    });
  } else {
    return assetsTextAssetCreate({
      body: {
        organization: content?.organization as string,
        event: content?.project.id as string,
        event_uuid: content?.project.id as string,
        broadcast: content?.id as string,
        broadcast_uuid: content?.id as string,
        title: body.title,
        asset_metadata: {
          title: body.title,
          seoKeywords: body.seo_keywords,
          length: body.length,
          output,
          prompt: body.prompt,
          type,
          tone: body.brand_tone
        },
        deleted: null,
        deleted_by_cascade: false
      }
    });
  }
}

export function loadTextAssets(eventId: string, broadcastId: string, text_asset_type?: TextType): Promise<TextAsset[]> {
  const isCustom = isCustomUpload();
  return assetsAssetV2List({
    queryParams: {
      contents: broadcastId!,
      projects: isCustom ? undefined : eventId!,
      types: 'TEXT',
      limit: 1000,
      ordering: '-created_at',
      text_asset_type: text_asset_type as TextType
    }
  }).then(({ results }) => results as unknown as TextAsset[]);
}
