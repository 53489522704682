import React, { useSyncExternalStore } from 'react';
import { convertSecondsToHoursAndMinutes, formatTime } from './utils';
import IconButton from '@/components/atoms/Button/IconButton';
import { orgUsageSummaryState } from '@/stores/orgUsageSummary';
import useFreeTrialHook from '@/hooks/useFreeTrialHook';

export default function FreeTrialUsageSummaryCard(): JSX.Element {
  const orgUsageSummaryStore = useSyncExternalStore(orgUsageSummaryState.subscribe, orgUsageSummaryState.getSnapshot);
  const { openNonBlockingPricingPaywall, isFreeTrialEnabled, videoUploadLeft, isFreemiumUser } = useFreeTrialHook();

  if (!isFreeTrialEnabled && !isFreemiumUser) {
    return <></>;
  }

  if (orgUsageSummaryStore.loading || orgUsageSummaryStore.error || !orgUsageSummaryStore.orgId) {
    return <></>;
  }

  const daysLeft = orgUsageSummaryStore.freeTrial.daysLeft;

  let daysLeftText = '';
  if (daysLeft === null) {
    daysLeftText = '';
  } else if (daysLeft >= 0) {
    daysLeftText = `${daysLeft} day${daysLeft > 1 ? 's' : ''} left`;
  } else {
    daysLeftText = 'Ended';
  }

  const { hours, minutes } =
    videoUploadLeft > 0 ? convertSecondsToHoursAndMinutes(videoUploadLeft) : { hours: 0, minutes: 0 };

  const timeLeft = formatTime(hours, minutes);

  return (
    <div className="fixed bottom-6 w-[12rem] rounded-lg border border-slate-200 p-2.5">
      <div className="flex w-full items-center justify-between">
        <div className="inline-flex rounded-md border border-deep-orange-500 bg-deep-orange-50 px-1.5 py-0.5 text-xs font-medium text-deep-orange">
          {isFreemiumUser ? 'Free' : 'Free Trial'}
        </div>
        {!isFreemiumUser && <div className="text-2xs text-slate-400">{daysLeftText}</div>}
      </div>

      <div className="mt-6 flex w-full justify-between text-xs text-slate-700">
        <div className="font-medium">Hrs of video left</div>
        <div className="tabular-nums">{timeLeft}</div>
      </div>
      <IconButton
        icon="IconBolt"
        buttonClassName="w-full mt-3"
        content="Upgrade"
        size="base"
        trackingId="free-trial-usage-summary-card-upgrade-button"
        onClick={openNonBlockingPricingPaywall}
      />
    </div>
  );
}
