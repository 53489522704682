import { Clip } from '@/domains/asset';

export interface ClipTimelineProps {
  duration: number;
  singleFrameWidth: number;
  clipData: Clip;
  sessionStartTime: number;
  sessionEndTime: number;
  disabled: boolean;
  progressPercent: number;
  showProgress: boolean;
  onSeeked: (unitRelativeToStart: number) => void;
}

export interface useExtendedCanvasProps {
  isDragging: boolean;
  sectionRef: React.RefObject<HTMLButtonElement>;
}

export interface useExtendedCanvasReturn {
  extendedCanvasStyles: React.CSSProperties;
  setExtendedCanvasParams: () => void;
}

export enum ChapterType {
  TIMELINE = 'timeline',
  TRANSCRIPT = 'transcript'
}

export interface ChapterActionsMenuProps {
  type: ChapterType;
  chapterStartTime: number;
  chapterEndTime: number;
  chapterId: string;
  onRenameChapter: () => void;
}
