import React from 'react';
import { IconBrandVimeo, IconBrandYoutube, IconBrandZoom, IconLink } from '@tabler/icons-react';
import { CONTENT_FORMATS } from './constants';
import FilesInput from '@/components/atoms/inputs/FilesInput/FilesInput';
import Button from '@/components/atoms/Button/Button';
import Loader from '@/components/atoms/Loader';
import { ReactComponent as WistiaIcon } from '@/assets/icons/wistia.svg';
import { useUploadContent } from '@/hooks/useUploadContent';

export default function NewContentModal({
  title = 'Drop the video you want to repurpose!',
  description = 'Try uploading webinars, product demos, video podcasts, or any other long-form video content.'
}: {
  title?: string;
  description?: string;
}) {
  const { urlInputValue, isImporting, isValidLink, handleUrlInputChange, onFilesUpload, startImport } =
    useUploadContent();

  return (
    <div className="content-lab-root tw-h-full">
      <div className="h-full overflow-y-auto">
        <div className="flex h-full w-full flex-col px-8">
          <div className="mb-4">
            <div className="text-2xl font-semibold">{title}</div>
          </div>
          <p className="mb-5 text-sm text-slate-600">{description}</p>
          <div className="relative flex w-full items-center text-slate-700 focus-within:text-deep-orange-600">
            <IconLink className="absolute left-3" size={20} />
            <input
              type="text"
              data-testid="import-url-input"
              onChange={handleUrlInputChange}
              className="w-full rounded-lg border py-3.5 pl-10 pr-28 text-sm text-black focus:ring-deep-orange-600"
              placeholder="Drop a video link…"
            />
            <Button
              className="absolute right-1 m-1"
              variation="filled"
              trackingId="import-video-button"
              disabled={isImporting || !urlInputValue}
              onClick={startImport}
            >
              {isImporting && <Loader size="xs" />}
              Import Video
            </Button>
          </div>
          <div className="mt-1.5 flex text-xs text-slate-500">
            {isValidLink ? (
              <div className="flex whitespace-nowrap">
                Use a
                <div className="ml-1 flex space-x-1 whitespace-nowrap">
                  <span className="inline-flex gap-1 whitespace-nowrap">
                    <IconBrandYoutube size={16} /> YouTube,
                  </span>
                  <span className="inline-flex gap-1 whitespace-nowrap">
                    <IconBrandZoom size={16} /> Zoom,
                  </span>
                  <span className="inline-flex gap-1 whitespace-nowrap">
                    <WistiaIcon className="h-3.5 w-3 stroke-slate-500" />
                    Wistia, or
                  </span>
                  <span className="inline-flex gap-1 whitespace-nowrap">
                    <IconBrandVimeo size={16} /> Vimeo or direct link to a video file.
                  </span>
                </div>
              </div>
            ) : (
              <span className="text-deep-orange-600">
                Oops! It looks like the URL you entered isn't quite right. Please double-check it and try again.
              </span>
            )}
          </div>
          <div className="mb-1.5 mt-4 flex w-full items-center">
            <div className="h-[1px] grow bg-slate-300"></div>{' '}
            <div className="shrink-0 px-4 text-xs text-slate-500">OR</div>
            <div className="h-[1px] grow bg-slate-300"></div>
          </div>
          <div className="h-full pb-8 pt-3">
            <FilesInput onFilesUpload={onFilesUpload} accept={CONTENT_FORMATS} title="Upload a file" multiple={false} />
          </div>
        </div>
      </div>
    </div>
  );
}
