import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Position, SpeakerWithMonologue } from './ClipTranscriptTypes';
import { getTrimPosition, getTrimmingError } from './ClipTranscriptUtils';
import { updateStartEndTime } from '@/stores/clip';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import { useAppContext } from '@/context/AppContext/AppContext';
import { showErrorToast } from '@/libs/toast/toast';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { Clip } from '@/domains/asset';

export default function useTrimTranscript(
  containerRef: React.RefObject<HTMLDivElement>,
  speakerWithMonologue: SpeakerWithMonologue[],
  clipStart: number,
  clipEnd: number,
  clip?: Clip
) {
  const transcriptStore = useTranscriptContext();
  const { logger } = useAppContext();
  const transcriptTrimmingRef = useRef<boolean>(transcriptStore.trimming);
  const { clipId: contextClipId, clipData: contextClipData, playerStore, clipCaptionsLoading } = useClipsContext();

  const { clipId, clipData } = useMemo(() => {
    return !!clip
      ? {
          clipId: clip.id,
          clipData: clip
        }
      : {
          clipId: contextClipId,
          clipData: contextClipData
        };
  }, [clip, contextClipId, contextClipData]);

  const [highlightStartTime, setHighlightStartTime] = useState<number>(
    speakerWithMonologue[0]?.monologue[0].start_time
  );
  const [highlightEndTime, setHighlightEndTime] = useState<number>(
    speakerWithMonologue[speakerWithMonologue.length - 1]?.monologue[
      speakerWithMonologue[speakerWithMonologue.length - 1].monologue.length - 1
    ].end_time
  );
  const [startPositions, setStartPositions] = useState<Position>();
  const [endPositions, setEndPositions] = useState<Position>();
  const [trimmingError, setTrimmingError] = useState<string | null>(null);

  useEffect(() => {
    return () => {
      transcriptStore.setTrimming?.(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (transcriptStore.trimming) return;
    setHighlightStartTime(speakerWithMonologue[0]?.monologue[0].start_time);
    setHighlightEndTime(
      speakerWithMonologue[speakerWithMonologue.length - 1]?.monologue[
        speakerWithMonologue[speakerWithMonologue.length - 1]?.monologue.length - 1
      ].end_time
    );
  }, [speakerWithMonologue, transcriptStore.trimming]);

  useEffect(() => {
    if (transcriptStore.trimming && !clipCaptionsLoading && clipStart >= 0 && clipStart !== highlightStartTime) {
      setHighlightStartTime(clipStart);
    }
  }, [clipCaptionsLoading, clipStart]);

  useEffect(() => {
    if (transcriptStore.trimming && !clipCaptionsLoading && clipEnd >= 0 && clipEnd !== highlightEndTime) {
      setHighlightEndTime(clipEnd);
    }
  }, [clipCaptionsLoading, clipEnd]);

  useEffect(() => {
    const containerObserver = new ResizeObserver(() => {
      setStartPositions(
        transcriptStore.trimming && containerRef.current
          ? getTrimPosition(transcriptStore.trimming, 'data-word-start-time', highlightStartTime, containerRef.current)
          : {}
      );

      setEndPositions(
        transcriptStore.trimming && containerRef.current
          ? getTrimPosition(
              transcriptStore.trimming,
              'data-word-end-time',
              highlightEndTime,
              containerRef.current,
              true
            )
          : {}
      );
    });

    containerObserver.observe(containerRef.current as Element);

    const transientContainerRef = containerRef.current;
    return () => {
      containerObserver.unobserve(transientContainerRef as Element);
    };
  }, [transcriptStore.trimming, clipStart, highlightStartTime, containerRef, highlightEndTime, clipEnd]);

  const onTrimToggle = useCallback(
    (trimming: boolean) => {
      transcriptStore.setTrimming?.(trimming);
      transcriptTrimmingRef.current = trimming;

      const mainVideoPlayerPlayPauseButton = document.getElementById(
        'main-player-play-pause-button'
      ) as HTMLVideoElement;
      if (mainVideoPlayerPlayPauseButton && !playerStore.paused) {
        mainVideoPlayerPlayPauseButton.click();
      }

      if (!trimming) {
        if (getTrimmingError(transcriptStore.transcript, highlightStartTime, highlightEndTime)) return;
        if (highlightStartTime !== clipData.asset_metadata.start) {
          updateStartEndTime(clipId, 'start', highlightStartTime);
        }
        if (highlightEndTime !== clipData.asset_metadata.end) {
          updateStartEndTime(clipId, 'end', highlightEndTime);
        }
      }
    },
    [transcriptStore, highlightStartTime, highlightEndTime, clipData]
  );

  const saveTrimChanges = useCallback(() => {
    if (trimmingError) {
      showErrorToast(trimmingError);
      return;
    }
    const mainVideoPlayerPlayPauseButton = document.getElementById('main-player-play-pause-button') as HTMLVideoElement;
    if (mainVideoPlayerPlayPauseButton && !playerStore.paused) {
      mainVideoPlayerPlayPauseButton.click();
    }

    if (highlightStartTime !== clipData.asset_metadata.start) {
      updateStartEndTime(clipId, 'start', highlightStartTime);
    }
    if (highlightEndTime !== clipData.asset_metadata.end) {
      updateStartEndTime(clipId, 'end', highlightEndTime);
    }
    onCursorDragEnd();
  }, [transcriptStore, highlightStartTime, highlightEndTime, clipData, trimmingError]);

  const [dragStart, setDragStart] = useState<boolean>(false);
  const [dragEnd, setDragEnd] = useState<boolean>(false);

  const onStartCursorDrag = useCallback(() => {
    setDragStart(true);
  }, []);

  const onEndCursorDrag = useCallback(() => {
    setDragEnd(true);
  }, []);

  const onCursorDragEnd = useCallback(() => {
    setDragStart(false);
    setDragEnd(false);
  }, []);

  const onDragOver = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      try {
        const wordElement = e.target as HTMLElement;

        if (wordElement) {
          const wordStartTime = parseFloat(wordElement.dataset.wordStartTime as string);
          const wordEndTime = parseFloat(wordElement.dataset.wordEndTime as string);

          if (!dragStart && !dragEnd) return;
          if (dragStart && wordStartTime >= highlightEndTime) return;
          if (dragEnd && wordEndTime <= highlightStartTime) return;
          if (dragStart && !Number.isNaN(wordStartTime)) {
            setHighlightStartTime(wordStartTime);
            updateTrimmingError();
          }
          if (dragEnd && !Number.isNaN(wordEndTime)) {
            setHighlightEndTime(wordEndTime);
            updateTrimmingError();
          }
        }
      } catch (e) {
        logger.error(e);
      }
    },
    [dragStart, dragEnd, highlightStartTime, highlightEndTime]
  );

  const updateTrimmingError = () => {
    setTrimmingError(getTrimmingError(transcriptStore.transcript, highlightStartTime, highlightEndTime));
  };

  return {
    transcriptTrimmingRef,
    highlightStartTime,
    highlightEndTime,
    startPositions,
    endPositions,
    trimmingError,
    onTrimToggle,
    onStartCursorDrag,
    onEndCursorDrag,
    onCursorDragEnd,
    onDragOver,
    saveTrimChanges
  };
}
