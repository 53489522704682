export function isLastItemOnPage({
  index,
  pageSize,
  currentPage,
  totalItems
}: {
  index: number;
  pageSize: number;
  totalItems: number;
  currentPage: number;
}): boolean {
  if (pageSize === 0 || totalItems === 0 || index < 0) {
    return false;
  }

  if (pageSize * (currentPage - 1) + (index + 1) === totalItems) {
    return true;
  }

  if ((index + 1) % pageSize === 0) {
    return true;
  }

  return false;
}
