import { toSnakeCase } from './utils';

export function getFileSize(size: number): string {
  const sizeInGb = getFileSizeInGb(size);
  if (sizeInGb > 1) return `${sizeInGb.toFixed(2)}GB`;
  const sizeInMb = sizeInGb * 1024;
  if (sizeInMb > 1) return `${sizeInMb.toFixed(2)}MB`;
  return `${(sizeInMb * 1024).toFixed(2)}KB`;
}

export function getFilesProgress(progressInBytes: number, totalSize: string): string {
  // totalSize is recieved as format 2GB, 2MB, 2KB. Hence, we take last 2 as unit
  const unit = totalSize.substring(totalSize.length - 2);
  return `${getFileSizeInUnit(progressInBytes, unit)} / ${totalSize}`;
}

export function getFileSizeInUnit(size: number, unit: string): string {
  const sizeInGb = getFileSizeInGb(size);
  switch (unit) {
    case 'GB':
      return sizeInGb.toFixed(2);
    case 'MB':
      return getFileSizeInMb(size).toFixed(2);
    default:
      return (sizeInGb * 1024 * 1024).toFixed(2);
  }
}

export function getFileSizeInGb(size: number): number {
  return size / 1024 / 1024 / 1024;
}

export function getFileSizeInMb(size: number): number {
  return size / 1024 / 1024;
}

export function getFileExtension(fileName: string): string {
  return fileName.substring(fileName.lastIndexOf('.'));
}

export function getFileNameWithoutExtension(fileName: string): string {
  return fileName.substring(0, fileName.lastIndexOf('.'));
}

export function getFileNameFromPath(fileName: string): string {
  const start = fileName.lastIndexOf('/') >= 0 ? fileName.lastIndexOf('/') + 1 : 0;
  return fileName.substring(start);
}

// Expecting valid types as comma separated: .mp3, .mp4
export function isValidFileExtension(fileName: string, validTypes: string): boolean {
  const extension = getFileExtension(fileName);
  const supportedExtensions = validTypes.split(',');
  return supportedExtensions.includes(extension);
}

export function isVideoFile(file: File): boolean {
  return file.type.includes('video');
}

export function getFileKey(name: string): string {
  return toSnakeCase(name);
}

export function getFileNameTitleCase(fullName: string): string {
  return fullName
    .substring(0, fullName.lastIndexOf('.'))
    .replace(/[_-]/g, ' ')
    .replace(/\b\w/g, char => char.toUpperCase());
}

export function getParsedFilesList(files: FileList): File[] {
  return [...files].map(f => {
    let blob = f.slice(0, f.size, f.type);
    return new File([blob], `${getFileNameWithoutExtension(f.name)}${getFileExtension(f.name).toLowerCase()}`, {
      type: f.type
    });
  }) as File[];
}
