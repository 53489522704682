import React, { useEffect, useState } from 'react';
import { AvatarProps } from './AvatarTypes';
import { getInitials, getSize } from './AvatarUtils';
import { getColorForString } from '@/libs/utils';

export default function Avatar({
  name,
  size = 'md',
  imageUrl = '',
  className = '',
  style = {},
  imageClassName = 'rounded-full',
  imageRef,
  onImageLoad
}: AvatarProps): JSX.Element {
  const sizeClassName = getSize(size);
  let [showImage, setShowImage] = useState(!!imageUrl);

  useEffect(() => {
    setShowImage(!!imageUrl);
  }, [imageUrl]);

  return (
    <>
      {showImage ? (
        <div className={sizeClassName} style={style}>
          <img
            ref={imageRef}
            src={imageUrl}
            alt={name}
            className={`${imageClassName} h-full w-full object-cover`}
            onLoad={() => onImageLoad?.(imageUrl)}
            onError={() => setShowImage(false)}
          />
        </div>
      ) : (
        <div
          className={`relative overflow-hidden ${className} min-w-fit ${sizeClassName} ${imageClassName} flex items-center justify-center font-medium leading-none tracking-wider text-white`}
          style={{ backgroundColor: getColorForString(name), ...style }}
        >
          <div className="absolute inset-0 bg-gradient-to-br from-transparent to-deep-orange-300" />
          <div className="relative" data-testid="avatar-initials">
            {getInitials(name)}
          </div>
        </div>
      )}
    </>
  );
}
