import React, { Fragment } from 'react';
import { PreviewTemplate } from '../../SideBar/types';
import { useSpeakerSegmentContext } from '@/context/SpeakerSegmentContext/SpeakerSegmentContext';
import useCaptionsStylesHook from '@/components/molecules/useCaptionsStylesHook';

export default function CanvasPreviewCaptions({
  captionHorizontalSpace,
  template
}: {
  captionHorizontalSpace: number;
  template: PreviewTemplate;
}) {
  const speakerSegmentContextData = useSpeakerSegmentContext();
  const words = speakerSegmentContextData?.srtCaptions?.[speakerSegmentContextData.startSegment] || [];

  const { activeWordStyles, inactiveWordStyles } = useCaptionsStylesHook(template);

  return words.map((word, index) => {
    const isActive = index === 0;
    const wordStyles = isActive ? activeWordStyles : inactiveWordStyles;

    return (
      <Fragment key={word.index}>
        <span
          className="px-[1px]"
          style={{
            color: template.magicLayout?.textColor,
            backgroundColor: wordStyles.backgroundColor,
            marginLeft: `${index === 0 ? 0 : captionHorizontalSpace}px`,
            opacity: wordStyles.opacity
          }}
        >
          {word.content.trim()}
        </span>
      </Fragment>
    );
  });
}
