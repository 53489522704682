import { AssetList } from '@goldcast/api/content';
import React, { useCallback, useEffect, useMemo, useState, useSyncExternalStore } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import AssetsTableRows from './AssetsTableRow';
import EventFilter from '../Filters/EventFilter';
import AssetsTableHeaders from './AssetsTableHeaders';
import SessionFilter from '../Filters/SessionFilter';
import TypeFilter from '../Filters/TypeFilter';
import AssetsTableRowSkeleton from './AssetsTableRowSkeleton';
import NoData from './NoData';
import NoMatch from './NoMatch';
import {
  ASSET_TABLE_PAGE_LIMIT,
  AssetsTableQueryParams,
  defaultQueryParams,
  loadAssets,
  parseArrayQueryParam
} from './AssetsTableUtils';
import AssetsSearch from './AssetsSearch';
import ContentPagination from '../uiComponents/ContentPagination/ContentPagination';
import ErrorState from './ErrorState';
import { isLastItemOnPage } from './utils';
import { useUpdateQueryParams } from '@/hooks/url/useUpdateQueryParams';
import { useQueryParams } from '@/hooks/url/useQueryParams';
import { isEmptyObject } from '@/libs/utils';
import Button from '@/components/atoms/Button/Button';
import { useAppContext } from '@/context/AppContext/AppContext';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';

export default function AssetsTable({ setIsOpen }): JSX.Element {
  const {
    adminAppStore: { user: { is_content_lab_standalone } = { is_content_lab_standalone: false } }
  } = useAppContext();
  const [searchInputKey, setSearchInputKey] = useState(Math.random() * 1000);

  const { updateQueryParams, replaceQueryParams } = useUpdateQueryParams<AssetsTableQueryParams>();
  const queryParams = useQueryParams<AssetsTableQueryParams>();

  const { offset, events, broadcasts, types, search } = queryParams;
  const currentPage = useMemo(() => parseInt(offset as unknown as string) + 1, [offset]);

  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const navigate = useNavigate();

  useEffect(() => {
    if (isEmptyObject(queryParams)) {
      replaceQueryParams(defaultQueryParams, {}, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedEvents = useMemo(() => parseArrayQueryParam(events), [events]);
  const selectedSessions = useMemo(() => parseArrayQueryParam(broadcasts), [broadcasts]);
  const selectedTypes = useMemo(() => parseArrayQueryParam(types), [types]);

  const [assets, setAssets] = useState<AssetList[]>([]);

  const [itemsCount, setItemsCount] = useState(0);

  const {
    data,
    isFetching: loading,
    isLoadingError: isFailed,
    refetch
  } = useQuery({
    queryKey: ['assets', queryParams],
    queryFn: () => {
      const { sort_by, desc, ...restQueryParams } = queryParams;
      const sortDescending = desc !== undefined && JSON.parse(desc as unknown as string);
      const ordering = sortDescending ? `-${sort_by}` : sort_by;

      return loadAssets(restQueryParams, ordering, offset);
    }
  });

  useEffect(() => {
    if (loading) return;

    if (data) {
      setAssets(data.results || []);
      setItemsCount(data.count || 0);
    }
  }, [loading, data]);

  const hasFilter = useMemo(() => {
    return selectedEvents.length > 0 || selectedSessions.length > 0 || selectedTypes.length > 0 || search;
  }, [selectedEvents, selectedSessions, selectedTypes, search]);

  const openSessionsDialog = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const onClickNewContentButton = useCallback(() => {
    if (featureFlags[FeatureFlagKeys.Use_CL_FTUX_Clip_Templates]) {
      navigate('/import');
    } else {
      openSessionsDialog();
    }
  }, [openSessionsDialog, featureFlags[FeatureFlagKeys.Use_CL_FTUX_Clip_Templates]]);

  const onIndividualFilterSelect = useCallback(
    (key, options) => {
      updateQueryParams(
        {
          [key]: options,
          offset: 0
        },
        {
          arrayFormat: 'repeat'
        }
      );
    },
    [updateQueryParams]
  );

  const onEventSelect = useCallback(
    options => {
      onIndividualFilterSelect('events', options);
    },
    [onIndividualFilterSelect]
  );

  const onSessionSelect = useCallback(
    options => {
      onIndividualFilterSelect('broadcasts', options);
    },
    [onIndividualFilterSelect]
  );

  const onTypeSelect = useCallback(
    options => {
      onIndividualFilterSelect('types', options);
    },
    [onIndividualFilterSelect]
  );

  const clearIndividualFilter = useCallback(
    key => {
      updateQueryParams({
        [key]: undefined
      });
    },
    [updateQueryParams]
  );

  const clearEvents = useCallback(() => {
    clearIndividualFilter('events');
  }, [clearIndividualFilter]);

  const clearSessions = useCallback(() => {
    clearIndividualFilter('broadcasts');
  }, [clearIndividualFilter]);

  const clearTypes = useCallback(() => {
    clearIndividualFilter('types');
  }, [clearIndividualFilter]);

  const clearAllFilters = useCallback(() => {
    setSearchInputKey(Math.random() * 1000);
    updateQueryParams({
      events: undefined,
      broadcasts: undefined,
      types: undefined,
      search: undefined
    });
  }, []);

  const noData = !loading && assets.length === 0 && !hasFilter;

  return (
    <div className="relative">
      <div className="flex w-full items-center justify-between pl-7 pr-4">
        <div className="text-xl font-medium leading-tight tracking-tight text-black">All Content</div>
        <div className="flex gap-4">
          <Button variation="filled" onClick={onClickNewContentButton} trackingId="new-content-button">
            New content
          </Button>
        </div>
      </div>

      <div className="mt-2 flex w-full items-center justify-between pl-7 pr-4">
        <div className="flex space-x-1.5">
          {!is_content_lab_standalone && (
            <EventFilter selected={selectedEvents} onSelect={onEventSelect} onClear={clearEvents} disabled={noData} />
          )}
          <SessionFilter
            selected={selectedSessions}
            onSelect={onSessionSelect}
            selectedEvents={selectedEvents}
            onClear={clearSessions}
            disabled={noData}
          />
          <TypeFilter selected={selectedTypes} onSelect={onTypeSelect} onClear={clearTypes} disabled={noData} />

          {hasFilter && (
            <Button variation="outline" onClick={clearAllFilters} trackingId="clear-all-filters-button">
              Clear all
            </Button>
          )}
        </div>
        <AssetsSearch searchInputKey={searchInputKey} disabled={noData} />
      </div>

      <div className="mt-3 w-full pl-7 pr-4">
        <AssetsTableHeaders disabled={loading || noData} />
        {loading ? (
          <AssetsTableRowSkeleton />
        ) : isFailed ? (
          <ErrorState />
        ) : assets.length === 0 && hasFilter ? (
          <NoMatch clearAllFilters={clearAllFilters} />
        ) : noData ? (
          <NoData openSessionsDialog={openSessionsDialog} />
        ) : (
          assets.map((asset, index) => (
            <AssetsTableRows
              asset={asset}
              refetch={refetch}
              key={asset.asset_metadata.id}
              isLastPageItem={isLastItemOnPage({
                index,
                pageSize: ASSET_TABLE_PAGE_LIMIT,
                totalItems: itemsCount,
                currentPage
              })}
            />
          ))
        )}
        {itemsCount > 0 && (
          <ContentPagination
            itemsCount={itemsCount}
            itemsPerPageCount={ASSET_TABLE_PAGE_LIMIT}
            currentPage={currentPage}
            onNext={() => {
              updateQueryParams({
                offset: currentPage
              });
            }}
            onPrevious={() => {
              updateQueryParams({
                offset: currentPage - 2
              });
            }}
            goToLastPage={lastPage => {
              updateQueryParams({
                offset: lastPage - 1
              });
            }}
            goToFirstPage={() => {
              updateQueryParams({
                offset: 0
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
