import { useEffect, useMemo, useRef } from 'react';
import { debounce } from '@/libs/utils';

const useDebounce = (callback: (...args) => void, timeout = 300) => {
  const ref = useRef<any>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = (...params) => {
      ref.current?.(...params);
    };

    return debounce(func, timeout);
  }, [timeout]);

  return debouncedCallback;
};

export default useDebounce;
