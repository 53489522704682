import { IconLayoutGridAdd } from '@tabler/icons-react';
import { useMemo } from 'react';
import { TEMPLATE_SIZE_OPTIONS } from './constants';
import NewTemplateButton from './NewTemplateButton';
import { SizeType } from '@/domains/asset';

export default function TemplatesEmptyState({ size, tag }: { size?: SizeType; tag?: string }) {
  const message = useMemo(() => {
    if (!size && !tag) return 'No Saved Templates, yet.';
    const sizeLabel = size ? ' ' + TEMPLATE_SIZE_OPTIONS[size] : '';
    const tagLabel = tag ? ' ' + tag : '';
    return `No${tagLabel}${sizeLabel}, yet.`;
  }, [size, tag]);

  return (
    <div className="flex w-full flex-col items-center justify-center py-24">
      <IconLayoutGridAdd size={24} stroke={2} className="h-6 w-6 text-slate-500" />
      <div className="mt-4 text-sm text-slate-600">{message}</div>
      <div className="mt-2 w-48 text-center text-xs text-slate-500">
        Save your current layout as a template and use it everywhere.
      </div>
      <div className="mt-4">
        <NewTemplateButton className="h-8 px-3 py-2 text-xs" />
      </div>
    </div>
  );
}
