export function getVideoDuration(file: File): Promise<number> {
  return new Promise(resolve => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      resolve(video.duration);
    };
    video.src = URL.createObjectURL(file);
  });
}

export function getTotalVideoDuration(files: File[]): Promise<number> {
  return Promise.all(files.map(getVideoDuration)).then(durations =>
    durations.reduce((acc, duration) => acc + duration, 0)
  );
}
