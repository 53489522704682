export enum CustomEvents {
  TimelineChanged = 'TimelineChanged',
  SpeakersChanged = 'SpeakersChanged',
  OpenContentSettings = 'OpenContentSettings',
  UploadContentDone = 'UploadContentDone',
  OpenSpeakersIdentification = 'OpenSpeakersIdentification',
  ReloadTranscript = 'ReloadTranscript',
  ReloadClip = 'ReloadClip',
  ComputeFrame = 'ComputeFrame',
  ReloadTemplates = 'ReloadTemplates',
  SignOut = 'SignOut',
  TranscriptionStatusUpdated = 'TranscriptionStatusUpdated',
  UploadContentStarted = 'UploadContentStarted',
  ClipGenerationStatusUpdated = 'ClipGenerationStatusUpdated',
  NavigateToContentLabHome = 'NavigateToContentLabHome',
  ReloadAllClips = 'ReloadAllClips',
  OpenEditCaptions = 'OpenEditCaptions',
  ThumbnailGenerated = 'ThumbnailGenerated',
  SocialPostGenerationStatusUpdated = 'SocialPostGenerationStatusUpdated',
  BlogPostGenerationStatusUpdated = 'BlogPostGenerationStatusUpdated',
  EmailPostGenerationStatusUpdated = 'EmailPostGenerationStatusUpdated',
  TakeawayPostGenerationStatusUpdated = 'TakeawayPostGenerationStatusUpdated',
  DownloadTriggerSuccess = 'DownloadTriggerSuccess',
  CloseSharePopup = 'CloseSharePopup',
  OpenCaptionsMenu = 'OpenCaptionsMenu',
  PlaybackRateChange = 'PlaybackRateChange',
  FacialRecognitionStatusUpdated = 'FacialRecognitionStatusUpdated',
  NavigateToContentLabImport = 'NavigateToContentLabImport',
  ClipEnded = 'ClipEnded',
  UpdateChapters = 'UpdateChapters',
  PublishRecordingStatusUpdated = 'PublishRecordingStatusUpdated'
}
