import React from 'react';
import IconButton from '@/components/atoms/Button/IconButton';
import ConfirmationDialog from '@/components/organisms/ConfirmationDialog';
import useDialog from '@/components/organisms/useDialog';
import { classnames } from '@/libs/utils';

export default function ImagePreview({
  imageUrl,
  isSelected,
  onDelete,
  onImageSelected
}: {
  imageUrl: string;
  isSelected: boolean;
  onDelete?: () => void;
  onImageSelected: (url) => void;
}) {
  const {
    isOpen: isDeleteConfirmationOpen,
    openDialog: openDeleteConfirmationDialog,
    closeDialog: closeDeleteConfirmationDialog
  } = useDialog();

  function triggerConfirmationDialog(e: React.MouseEvent) {
    e.stopPropagation();
    openDeleteConfirmationDialog();
  }

  return (
    <div
      key={imageUrl}
      className={classnames(
        'group relative h-14 w-14 cursor-pointer rounded-lg border bg-cover bg-center',
        isSelected ? 'ring-2 ring-deep-orange' : ''
      )}
      onClick={() => onImageSelected(imageUrl)}
      style={{ backgroundImage: `url(${imageUrl})` }}
      data-testid={isSelected ? 'selected-background-image' : 'uploaded-background-image'}
    >
      {onDelete && (
        <div className="m-1 flex justify-end opacity-0 transition-all duration-150 ease-in-out group-hover:opacity-100">
          <IconButton
            icon="IconTrash"
            size="small"
            variation="active"
            disabled={isSelected}
            trackingId="delete-clip-background-button"
            onClick={triggerConfirmationDialog}
          />
        </div>
      )}
      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        onClose={closeDeleteConfirmationDialog}
        onConfirm={onDelete!}
        title="Delete Template Background"
        content="Are you sure you want to remove this Template Background?"
        confirmLabel="Yes"
      />
    </div>
  );
}
