import React from 'react';
import { IconSlash } from '@tabler/icons-react';
import { convertSecondsToTimeString } from '../../SideBar/ClipDuration/utils';
import { handleClipLockedError } from '../../utils';
import PlayPause from '@/components/molecules/PlayPause';
import IconButton from '@/components/atoms/Button/IconButton';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function ClipTimelineActionBar({
  isPaused,
  playCurrentSection,
  pauseCurrentSection,
  currentAbsoluteTime,
  totalDuration,
  disabled
}: {
  isPaused: boolean;
  playCurrentSection: () => void;
  pauseCurrentSection: () => void;
  currentAbsoluteTime: number;
  totalDuration: number;
  disabled: boolean;
}) {
  const { clipData } = useClipsContext();

  function triggerEditCaptions() {
    if (clipData.locked) {
      handleClipLockedError(clipData);
      return;
    }
    EventBus.dispatch(CustomEvents.OpenEditCaptions);
  }

  return (
    <div className="mt-2 flex justify-between px-4">
      <div className="grid w-full grid-cols-3 items-center justify-between space-x-3">
        <div className="flex h-9 cursor-pointer items-center justify-start rounded-sm">
          <IconButton
            icon="IconScissors"
            content="Edit by Transcript"
            variation="text"
            trackingId="timeline-edit-by-transcript-button"
            onClick={triggerEditCaptions}
          />
        </div>
        <div className="flex w-full items-center justify-center">
          {/* <div className="flex h-9 w-16 cursor-pointer items-center justify-center rounded-sm">
            <IconRewindBackward10 className="h-5 w-5 text-slate-500" onClick={skipBack} />
          </div> */}
          <div className="mx-2 flex items-center gap-2">
            <PlayPause
              paused={isPaused}
              onPlay={playCurrentSection}
              onPause={pauseCurrentSection}
              disabled={disabled}
            />
          </div>
          {/* <div className="flex h-9 w-16 cursor-pointer items-center justify-center rounded-sm">
        <IconRewindForward10 className="h-5 w-5 text-slate-500" onClick={skipForward} />
      </div> */}
          <div className="ml-2.5 mt-[1px] text-sm tabular-nums text-slate-600">
            {convertSecondsToTimeString(Math.max(0, currentAbsoluteTime), 'HH:mm:ss')}
            <span className="text-slate-400">
              <IconSlash size={20} stroke={1.5} className="ml-1 inline-flex -translate-y-[1.5px]" />{' '}
              {convertSecondsToTimeString(totalDuration, 'HH:mm:ss')}
            </span>
          </div>
        </div>
        <div className="flex justify-end"></div>
      </div>
    </div>
  );
}
