import { useAppContext } from '@/context/AppContext/AppContext';

export default function useEmailVerifiedHook(): {
  /**
   * Check if the user's email is verified and show a toast if not. This function has a side effect so that
   * we don't have to repeat the toast logic in every component.
   */
  checkEmailVerified: () => boolean;
} {
  const { adminAppStore } = useAppContext();

  const checkEmailVerified = (): boolean => {
    if (adminAppStore?.userCognitoDetails?.attributes?.email_verified) {
      return true;
    }

    adminAppStore.commit?.('org/setShowEmailVerificationDialog', true);

    return false;
  };

  return {
    checkEmailVerified
  };
}
