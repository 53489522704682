import { SizeConfig } from './SizeConfig';
import { CaptionAttributes, PillBoxSizes } from './types';
import { getCanvasTextDimensions } from './util';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { LayoutType, SizeType } from '@/domains/asset';
import { loadFont } from '@/libs/fonts';
import { getSpeakerNameAndTitle } from '@/Pages/Clip/CompositePlayer/SpeakerVideoUtils';
import { getFontByName } from '@/Pages/Clip/SideBar/FontSelector/constants';
import { getCurrentClipFontName } from '@/stores/brandKit';

export class LandscapeSizeConfig extends SizeConfig {
  protected type = 'LANDSCAPE' as SizeType;

  protected VIDEO_SURROUND_PADDING: number = 16;
  protected VIDEO_BOTTOM_PADDING: number = 210;

  protected CAPTIONS_LEFT_MARGIN = 20;
  protected CAPTIONS_RIGHT_MARGIN = 20;
  // This should be same as the SquareSizeConfig bottom margin
  protected CAPTIONS_BOTTOM_MARGIN = 70;
  protected CAPTIONS_FONT_SIZE = 29;

  protected AUDIOGRAM_DIMENSIONS = {
    IMG_DIMENSIONS_RATIO: {
      width: 0.5,
      height: 1
    },
    WAVEFORM_HEIGHT_MULTIPLIER: 0.085,
    WAVEFORM_POSITION: {
      x: 0.92,
      y: 0.48
    },
    SPEAKER_LABEL_WIDTH_MULTIPLIER: 0.375
  };

  constructor(clipId: string, clipLayout: LayoutType, devicePixelRatio?: number) {
    super(1080, 1920, clipId, clipLayout, devicePixelRatio);
  }

  getSizeGridPositions(
    totalVideos: number,
    currentVideoNumber: number,
    target_crop_x: number,
    target_crop_y: number,
    target_crop_width: number,
    target_crop_height: number
  ) {
    const videoSurroundPadding = this.getVideoSurroundPadding();
    target_crop_width = (target_crop_width - videoSurroundPadding) / 2;

    if (totalVideos > 2) {
      target_crop_height = (target_crop_height - videoSurroundPadding) / 2;
    }

    if (currentVideoNumber % 2 === 0) {
      target_crop_x = target_crop_width + videoSurroundPadding * 2;
    }

    if (currentVideoNumber > 2) {
      target_crop_y = target_crop_height + videoSurroundPadding * 2;
    }

    if (totalVideos === 3 && currentVideoNumber === 3) {
      target_crop_x = target_crop_width + videoSurroundPadding * 2 - target_crop_width / 2;
    }

    return { target_crop_x, target_crop_y, target_crop_width, target_crop_height };
  }

  getPillMaxWidth(totalNumber: number) {
    return (
      ((this.getWidth() - this.getVideoSurroundPadding() * 2) * this.PILL_MAX_WIDTH_RATIO) / (totalNumber > 1 ? 2 : 1)
    );
  }

  getPillBoxSizes(
    currentVideoNumber: number,
    totalNumber: number,
    canvasPillWidth: number,
    isTruncated: boolean
  ): PillBoxSizes {
    let newPillX = 0;
    let newPillHeight = 0;
    if (totalNumber === 1) {
      newPillX = 40;
      newPillHeight = 140;
    } else if (totalNumber === 2 && (currentVideoNumber === 1 || currentVideoNumber === 2)) {
      newPillX = 20;
      newPillHeight = 120;
    } else if (totalNumber === 3) {
      newPillX = 18;
      newPillHeight = 110;
    } else if (totalNumber === 4) {
      newPillX = 20;
      newPillHeight = 110;
    }

    const pillHeight = newPillHeight * this.devicePixelRatio;

    return {
      pillX: newPillX * this.devicePixelRatio,
      pillWidth: canvasPillWidth + pillHeight / (isTruncated ? 1.5 : 1),
      pillHeight
    };
  }

  getSpeakerNameTitlePillTextSizes(
    totalNumber: number,
    correctionFactor: number = 1
  ): {
    nameSize: number;
    titleSize: number;
  } {
    let nameSize = 40;
    let titleSize = 32;
    const ratio = nameSize / titleSize;

    if (totalNumber >= 2) {
      nameSize = 32;
    }

    titleSize = nameSize / ratio;

    return {
      nameSize: nameSize * this.devicePixelRatio * correctionFactor,
      titleSize: titleSize * this.devicePixelRatio * correctionFactor
    };
  }

  getAudiogramWaveformAttributes({ height, width }) {
    return {
      top: this.AUDIOGRAM_DIMENSIONS.WAVEFORM_POSITION.y * width,
      left: this.AUDIOGRAM_DIMENSIONS.WAVEFORM_POSITION.x * width,
      waveFormHeight: this.AUDIOGRAM_DIMENSIONS.WAVEFORM_HEIGHT_MULTIPLIER * height
    };
  }

  drawAudiogramSpeakerLabel(
    canvasContext: CanvasRenderingContext2D,
    speaker: SpeakerWithDetails,
    textColor: string
  ): void {
    const { name, title } = getSpeakerNameAndTitle(speaker);

    // Return and do not draw in case none of the fields are available
    if (!name && !title) return;

    const labelWidth = this.getWidth() * this.AUDIOGRAM_DIMENSIONS.SPEAKER_LABEL_WIDTH_MULTIPLIER;

    const fontName = getCurrentClipFontName(this.clipId);

    const nameSize = 92;
    const titleSize = 84;

    const {
      newTitle,
      newName,
      width: maxWidth
    } = getCanvasTextDimensions({
      maxWidth: labelWidth,
      name,
      title,
      nameFont: `${nameSize}px ${fontName || 'Inter'}`,
      titleFont: `${titleSize}px ${fontName || 'Inter'}`,
      canvasHeight: this.getHeight(),
      canvasWidth: this.getWidth()
    });

    const x = 0.5 * this.getWidth() + 0.05 * this.getHeight();
    const nameY = this.AUDIOGRAM_DIMENSIONS.WAVEFORM_POSITION.y * this.getWidth() + nameSize;
    const titleY = nameY + titleSize * 1.2;

    const fontItem = getFontByName(fontName as string);
    const drawContent = () => {
      this.drawText(canvasContext, newName, x, nameY, maxWidth, `${nameSize}px ${fontName || 'Inter'}`, textColor, 1);

      this.drawText(
        canvasContext,
        newTitle,
        x,
        titleY,
        maxWidth,
        `${titleSize}px ${fontName || 'Inter'}`,
        textColor,
        0.6
      );
    };
    if (fontItem) {
      loadFont(fontItem.url, fontItem.name).then(() => {
        drawContent();
      });
    } else {
      drawContent();
    }
  }

  getCaptionsFontSize(): number {
    if (this.clipLayout === 'AUDIOGRAM') {
      return this.CAPTIONS_FONT_SIZE * 1.5;
    }
    return this.CAPTIONS_FONT_SIZE;
  }

  getCaptionAttributes({
    containerHeight,
    containerWidth,
    captionFontSize
  }: {
    containerHeight: number;
    containerWidth: number;
    captionFontSize: number;
  }): CaptionAttributes {
    const top = 0.05 * containerHeight;
    const left = 0.5 * containerWidth + 0.05 * containerHeight;
    const right = 0.05 * containerHeight;
    const fontSize = `${captionFontSize * 1.5}px`;
    const lineHeight = `${(9 * 1.5 * captionFontSize) / 8}px`;

    return { top, left, right, fontSize, lineHeight, rotation: 0, alignment: 7, wrap_style: 1 };
  }

  getFixedStyleAttributes({ totalVideos }: { totalVideos: number }) {
    if (totalVideos === 1) {
      return {
        paddingLeft: '3.5%',
        paddingRight: '3.5%',
        fontSize: 38,
        maxWidth: '72%',
        percentPillHeight: 13,
        percentOffsetLeft: 2
      };
    } else if (totalVideos === 2) {
      return {
        paddingLeft: '3%',
        paddingRight: '3%',
        fontSize: 30,
        maxWidth: '40%',
        percentPillHeight: 11,
        percentOffsetLeft: 1
      };
    }

    // same for 3 & 4
    return {
      paddingLeft: '2.5%',
      paddingRight: '2.5%',
      fontSize: 30,
      maxWidth: '40%',
      percentPillHeight: 10,
      percentOffsetLeft: 1
    };
  }
}
