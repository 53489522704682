import { memo } from 'react';
import Loader from '@/components/atoms/Loader';

function AnalysisOverlay() {
  return (
    <div
      id="clip-vision-analysis-processing-overlay"
      className="absolute inset-0 flex flex-col items-center justify-center rounded-md bg-gray-200 px-1"
    >
      <Loader />
      <div className="mt-3 font-medium">Analyzing video</div>
      <div className="mt-1 text-center text-xs text-slate-700">
        We're analyzing the video to detect speakers. This may take a few moments.
      </div>
    </div>
  );
}

export default memo(AnalysisOverlay);
