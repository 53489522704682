import ContentDialog from '@/Pages/Sessions/uiComponents/ContentDialog/ContentDialog';
import Button from '@/components/atoms/Button/Button';

export default function ConfirmationDialog({
  isOpen,
  title,
  content,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  confirmTrackingId = 'confirmation-dialog-confirm-button',
  cancelTrackingId = 'confirmation-dialog-confirm-button',
  onClose,
  onConfirm
}: {
  isOpen: boolean;
  title: string;
  content: string;
  confirmLabel?: string;
  cancelLabel?: string;
  confirmTrackingId?: string;
  cancelTrackingId?: string;
  onClose: () => void;
  onConfirm: () => void;
}) {
  return (
    <ContentDialog title={title} size="small" isOpen={isOpen} setIsOpen={onClose}>
      <div className="flex flex-col">
        <p className="p-4 text-sm">{content}</p>
        <div className="flex items-center justify-between gap-3 border-t border-t-slate-200 p-2">
          <Button variation="outline" trackingId={cancelTrackingId} onClick={onClose}>
            {cancelLabel}
          </Button>
          <Button variation="filled" trackingId={confirmTrackingId} onClick={onConfirm}>
            {confirmLabel}
          </Button>
        </div>
      </div>
    </ContentDialog>
  );
}
