import { useRef, useState } from 'react';
import Badge from '@/components/atoms/Badge/Badge';
import Button from '@/components/atoms/Button/Button';
import { updateIntroOutro } from '@/stores/clip';
import Loader from '@/components/atoms/Loader';
import IconButton from '@/components/atoms/Button/IconButton';
import useDialog from '@/components/organisms/useDialog';
import ConfirmationDialog from '@/components/organisms/ConfirmationDialog';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function IntroOutroVideoPreview({ url, onDelete }: { url: string; onDelete?: () => void }) {
  const { clipId, clipData } = useClipsContext();
  const [isLoaded, setIsLoaded] = useState(false);
  const isIntro = clipData.asset_metadata.intro === url;
  const isOutro = clipData.asset_metadata.outro === url;
  const videoRef = useRef<HTMLVideoElement>(null);
  const {
    isOpen: isDeleteConfirmationOpen,
    openDialog: openDeleteConfirmationDialog,
    closeDialog: closeDeleteConfirmationDialog
  } = useDialog();

  function playVideoOnHover() {
    if (videoRef.current && videoRef.current.readyState >= HTMLMediaElement.HAVE_CURRENT_DATA) videoRef.current.play();
  }

  function pauseVideo() {
    if (videoRef.current && videoRef.current.readyState >= HTMLMediaElement.HAVE_CURRENT_DATA) videoRef.current.pause();
  }

  function updateIntroOutroValue(key: 'intro' | 'outro', videoUrl: string, shouldAdd: boolean) {
    updateIntroOutro(clipId, key, {
      url: shouldAdd ? videoUrl : undefined,
      duration: shouldAdd && videoRef.current ? videoRef.current.duration : 0
    });
  }

  function onVideoLoaded() {
    setIsLoaded(true);
  }

  return (
    <div
      role="button"
      className="group relative aspect-video h-full overflow-hidden rounded-lg"
      onMouseEnter={playVideoOnHover}
      onMouseLeave={pauseVideo}
    >
      <video className="absolute" ref={videoRef} muted onCanPlay={onVideoLoaded}>
        <source src={url} type="video/mp4" />
      </video>
      {!isLoaded && (
        <div className="absolute inset-1/2">
          <Loader />
        </div>
      )}
      <div className="absolute flex h-full w-full flex-col justify-between rounded-lg p-2">
        <div className="absolute left-0 top-0 z-20 h-full w-full bg-gradient-to-t from-black/40 to-black/0 opacity-0 transition-all duration-150 ease-in-out group-hover:opacity-100"></div>
        <div className="z-30 flex justify-between">
          {(isIntro || isOutro) && (
            <Badge variation="warning">
              {isIntro && isOutro && 'Intro & Outro'}
              {isIntro && !isOutro && 'Intro'}
              {!isIntro && isOutro && 'Outro'}
            </Badge>
          )}
          {!!onDelete && (
            <div className="ml-auto opacity-0 transition-all duration-150 ease-in-out group-hover:opacity-100">
              <IconButton
                icon="IconTrash"
                size="small"
                variation="filled"
                disabled={isIntro || isOutro}
                trackingId="delete-intro-outro-button"
                onClick={openDeleteConfirmationDialog}
              />
            </div>
          )}
        </div>
        <div className="z-30 flex w-full justify-between opacity-0 transition-all duration-150 ease-in-out group-hover:opacity-100">
          <Button
            variation="active"
            buttonSize="small"
            className="h-6"
            trackingId="add-remove-intro-button"
            disabled={!isLoaded && !isIntro}
            onClick={() => updateIntroOutroValue('intro', url, !isIntro)}
          >
            {!isIntro ? 'Add Intro' : 'Remove Intro'}
          </Button>
          <Button
            variation="active"
            buttonSize="small"
            className="h-6"
            disabled={!isLoaded && !isOutro}
            trackingId="add-remove-outro-button"
            onClick={() => updateIntroOutroValue('outro', url, !isOutro)}
          >
            {!isOutro ? 'Add Outro' : 'Remove Outro'}
          </Button>
        </div>
      </div>
      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        onClose={closeDeleteConfirmationDialog}
        onConfirm={onDelete!}
        title="Delete Intro/Outro Video"
        content="Are you sure you want to remove this Intro/Outro Video?"
        confirmLabel="Yes"
      />
    </div>
  );
}
