import { memo, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { assetsAssetV2List } from '@goldcast/api/content';
import { TextAsset } from '../PostsListPage/types';
import { POST_TYPES } from '../PostsListPage/constants';
import PostsListPage from '../PostsListPage';
import useAnalytics from '@/hooks/useAnalytics';
import { savedAISearch } from '@/stores/savedAISearch';
import { showErrorToast } from '@/libs/toast/toast';

function SavedSearchPostsListPageWrapper({ postType }) {
  const { eventId, broadcastId } = useParams<{ eventId: string; broadcastId: string }>();
  const [posts, setPosts] = useState<TextAsset[]>([]);
  const assetType = useMemo(() => (postType === POST_TYPES.SOCIAL ? 'LINKEDIN' : postType), [postType]);
  const { trackTextFetched } = useAnalytics();

  const getTextAssets = useCallback(() => {
    return assetsAssetV2List({
      queryParams: {
        contents: broadcastId!,
        saved_search: savedAISearch.getSnapshot()?.id,
        types: 'TEXT',
        limit: 1000,
        ordering: '-created_at',
        text_asset_type: assetType
      }
    }).then(({ results }) => results as unknown as TextAsset[]);
  }, [assetType, broadcastId]);

  const { isFetching, refetch } = useQuery({
    queryKey: [assetType],
    queryFn: getTextAssets,
    initialData: [],
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    cacheTime: 0,
    onSuccess: data => {
      setPosts(data);
      trackTextFetched({ count: (data || []).length });
    },
    onError: () =>
      showErrorToast(
        'Whoops! Struggling to fetch the generated text right now. Could you check your network connection and give it another whirl?'
      )
  });

  return (
    <PostsListPage
      isFetching={isFetching}
      onRefetch={refetch}
      assetType={assetType}
      postType={postType}
      posts={posts}
      setPosts={setPosts}
      broadcastId={broadcastId}
      eventId={eventId}
    />
  );
}

export default memo(SavedSearchPostsListPageWrapper);
