import { getEnvConfig } from '@/constants';
import { SpeakersAnalysis } from '@/stores/speakersAnalysis/speakersAnalysisTypes';

export function getDisabledStatesAndTitle(speakersAnalysis: SpeakersAnalysis, faceId: number) {
  const oneFaceSelected =
    speakersAnalysis.visible_face_ids.length === 1 && speakersAnalysis.visible_face_ids.includes(faceId);

  const fourFacesSelected =
    speakersAnalysis.visible_face_ids.length === 4 && !speakersAnalysis.visible_face_ids.includes(faceId);

  const isToggleDisabled = oneFaceSelected || fourFacesSelected;

  const toggleDisabledTitle = oneFaceSelected
    ? 'At least one speaker must be visible'
    : fourFacesSelected
    ? 'At most four speakers can be visible'
    : '';

  return {
    isToggleDisabled,
    toggleDisabledTitle
  };
}

export function getFaceLocationUrl(eventId: string, broadcastId: string, clipId: string, faceId: number) {
  return `${getEnvConfig(
    'CUSTOM_UPLOAD_URL'
  )}/${eventId}/${broadcastId}/recording/subtitles/${clipId}/face_id_${faceId}.jpg`;
}
