import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CombinedDownload } from '@goldcast/api/content';
import { IconCheck, IconFileTextAi, IconLoader2, IconVideo, IconWindowMaximize, IconX } from '@tabler/icons-react';
import moment from 'moment';
import Button from '@/components/atoms/Button/Button';
import { downloadURI } from '@/libs/core';

const SESSION_STORAGE_DOWNLOAD_PROGRESS_KEY = 'download-progress';

const DownloadsPopupListItem = ({ download }: { download: CombinedDownload }) => {
  const storedDownloadProgresses = sessionStorage.getItem(SESSION_STORAGE_DOWNLOAD_PROGRESS_KEY) || '{}';
  const storedProgressString = storedDownloadProgresses ? JSON.parse(storedDownloadProgresses)[download.id] : null;

  const [progress, setProgress] = useState<number>(
    download.status === 'DONE' ? 100 : storedProgressString ? parseInt(storedProgressString, 10) : 0
  );

  const timeAgo = useMemo(() => {
    if (!download.updated_at) return;
    return moment(new Date(download.updated_at)).fromNow();
  }, [download.updated_at]);

  const formattedUpdatedAt = useMemo(() => {
    if (!download.updated_at) return;
    return moment(new Date(download.updated_at)).format('MMM D, YYYY [at] h:mm A');
  }, [download.updated_at]);

  useEffect(() => {
    const newProgress = { ...JSON.parse(storedDownloadProgresses), [download.id]: progress };

    if (download.status === 'FAILED' || download.status === 'DONE' || progress === 100) {
      delete newProgress[download.id];
    }

    sessionStorage.setItem(SESSION_STORAGE_DOWNLOAD_PROGRESS_KEY, JSON.stringify(newProgress));
  }, [storedDownloadProgresses, progress, download]);

  useEffect(() => {
    if (download.download_path) return;

    const updateProgress = () => {
      if (download.download_path) {
        setProgress(100);
        return;
      }

      const now = new Date().getTime();
      const total = download.download_estimated_time * 1000;
      const elapsed = now - new Date(download.created_at).getTime();
      const percentage = Math.floor(Math.min((elapsed / total) * 100, 100));

      if (percentage >= 100 && !download.download_path) {
        setProgress(99);
        return;
      }

      setProgress(percentage);
    };

    const interval = setInterval(updateProgress, 1000);

    return () => clearInterval(interval);
  }, [download.created_at, download.download_path, download.download_estimated_time]);

  const onDownloadClick = useCallback(() => {
    if (!download.download_path) return;
    downloadURI(download.download_path);
  }, [download.download_path]);

  const statusConfig = useMemo(() => {
    if (download.status === 'FAILED') {
      return { icon: <IconX className="h-3.5 w-3.5" />, text: 'Failed', suffix: '' };
    }

    if (progress < 100) {
      return { icon: <IconLoader2 className="h-3.5 w-3.5 animate-spin" />, text: 'Processing', suffix: `${progress}%` };
    }

    return { icon: <IconCheck className="h-3.5 w-3.5" />, text: 'Ready to Download', suffix: timeAgo };
  }, [download.status, progress, timeAgo]);

  return (
    <div className="flex w-full items-center space-x-2.5 px-3 py-2 first:rounded-t-xl last:rounded-b-xl hover:bg-slate-50">
      <div className="flex h-14 w-16 shrink-0 items-center justify-center rounded-lg bg-slate-200/60 p-2">
        {download.type === 'CLIP' ? (
          <IconVideo className="h-5 w-5 text-slate-400" />
        ) : (
          <IconFileTextAi className="h-5 w-5 text-slate-400" />
        )}
      </div>
      <div className="grow overflow-hidden">
        <div className="-mt-1 flex items-center space-x-1.5 text-sm">
          <span className="flex-wrap truncate" title={download.file_name ?? download.title}>
            {download.file_name || download.title || 'Untitled'}
          </span>
          {download.post_processed_config?.upscale && (
            <div title="Uses AI Upscaling">
              <IconWindowMaximize className="h-3.5 w-3.5" />
            </div>
          )}
        </div>
        <div className="flex space-x-1 text-2xs text-slate-600">
          <div className="flex items-center space-x-0.5">
            {statusConfig.icon}
            <span className="font-medium">{statusConfig.text}</span>
          </div>
          {statusConfig.suffix && <div>·</div>}
          <div className="truncate" title={formattedUpdatedAt}>
            {statusConfig.suffix}
          </div>
        </div>
        {progress !== 100 && download.status !== 'FAILED' && (
          <div className="w-full pt-2">
            <div className="w-full rounded-full bg-gray-200 dark:bg-gray-700">
              <div
                className="h-1 rounded-full bg-deep-orange p-0.5 text-center text-xs font-medium leading-none text-blue-100 transition-all duration-500 ease-in-out"
                style={{ width: `${progress}%` }}
              />
            </div>
          </div>
        )}
      </div>
      {progress === 100 && (
        <Button onClick={onDownloadClick} trackingId="downloads-popup-download-button" variation="outline">
          Download
        </Button>
      )}
    </div>
  );
};

export default DownloadsPopupListItem;
