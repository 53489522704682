import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { STYLE_PREVIEW_TEXT } from './constants';
import PreviewCaptions from './PreviewCaptions';
import { CaptionStyleOption } from './types';
import { INACTIVE_WORD_OPACITY } from '../../constants';
import { CaptionStyle, ClipMetadata } from '@/domains/asset';
import { getColorWithOpacity } from '@/components/atoms/ColorPicker/utils';
import { Word } from '@/domains/transcript';
import { capitalizeFirstLetter } from '@/Pages/PostsListPage/utils';

function CaptionPreviewStyleOption({
  fontSize,
  clipMetadata,
  styleOption,
  hideDescription = false
}: {
  fontSize: number;
  clipMetadata: ClipMetadata;
  styleOption: CaptionStyleOption;
  hideDescription?: boolean;
}) {
  const [animateIndex, setAnimateIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const words = useMemo(() => {
    return STYLE_PREVIEW_TEXT.split(' ').map((w, index) => {
      return {
        index,
        content: w,
        start_time: index,
        end_time: index + 1
      } as Word;
    });
  }, []);

  useEffect(() => {
    if (!isAnimating) return;
    setAnimateIndex(0);
    const interval = setInterval(() => {
      setAnimateIndex(index => (index + 1) % words.length);
    }, 400);

    return () => {
      clearInterval(interval);
    };
  }, [isAnimating]);

  const backgroundColor = useMemo(() => {
    return styleOption.style === CaptionStyle.Background
      ? getColorWithOpacity(styleOption.backgroundStyleColor || '#000', INACTIVE_WORD_OPACITY)
      : undefined;
  }, [clipMetadata.caption_styles?.background_style_color, styleOption.style]);

  const startAnimating = useCallback(() => {
    setIsAnimating(true);
  }, []);

  const stopAnimating = useCallback(() => {
    setIsAnimating(false);
  }, []);

  const previewClipMetadata = useMemo(() => {
    return {
      ...clipMetadata,
      text_highlight_color: styleOption.textHighlightColor,
      word_highlight_color: styleOption.wordHighlightColor
    };
  }, [clipMetadata, styleOption]);

  const description = useMemo(() => {
    return `${styleOption.label || capitalizeFirstLetter(styleOption.highlight)} Highlight`;
  }, [styleOption.highlight]);

  return (
    <div
      onMouseEnter={startAnimating}
      onMouseLeave={stopAnimating}
      className="max-w-sm bg-clip-content"
      style={{
        fontSize: fontSize
      }}
    >
      {!hideDescription && (
        <div className="text-2xs text-slate-700" style={!!backgroundColor ? {} : { marginBottom: '-8px' }}>
          {description}
        </div>
      )}
      <div
        className="flex items-center"
        style={{
          height: `${fontSize * 2}px`,
          ...(!!backgroundColor
            ? {
                backgroundColor: backgroundColor
              }
            : {})
        }}
      >
        <div className="w-full truncate">
          <PreviewCaptions
            words={words}
            classNames="inline-flex items-center"
            captionHorizontalSpace={fontSize * 0.1875}
            clipMetadata={previewClipMetadata}
            animation={styleOption.style}
            scale={1}
            previewIndex={isAnimating ? animateIndex : 0}
            highlightType={styleOption.highlight}
            textColor={styleOption.textColor}
            backgroundColor={styleOption.backgroundColor}
            fontFamily={styleOption.fontFamily}
          />
        </div>
      </div>
    </div>
  );
}

export default memo(CaptionPreviewStyleOption);
