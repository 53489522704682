import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function useSavedSearch() {
  const location = useLocation();

  const isSavedSearchPage = useMemo(() => {
    return location.pathname.includes('/search');
  }, [location.pathname]);

  return {
    isSavedSearchPage
  };
}
