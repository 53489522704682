import { useParams } from 'react-router-dom';
import { IconSparkles, IconWindowMaximize } from '@tabler/icons-react';
import { useMemo, useState } from 'react';
import UpscaledVideoPreview from './UpscaledVideoPreview';
import Toggle from '@/components/atoms/Toggle';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function UpscaledVideoToggle({ onUpscaleChange }: { onUpscaleChange: (val) => void }) {
  const { broadcastId, eventId } = useParams<{ eventId: string; broadcastId: string }>();
  const [shouldUpscaleVideo, setShouldUpscaleVideo] = useState(false);
  const { clipData } = useClipsContext();

  const durationNote = useMemo(() => {
    if (!clipData.asset_metadata?.duration) return 'Download will take a few minutes longer.';
    return `Download will take about ${Math.ceil((clipData.asset_metadata.duration * 20) / 60)} minutes.`;
  }, [clipData]);

  function toggle(val: boolean) {
    setShouldUpscaleVideo(val);
    onUpscaleChange(val);
  }

  return (
    <div className="mt-3 flex w-full flex-col items-center justify-between rounded-lg bg-gradient-to-tr from-slate-700 to-slate-400 p-1.5 text-sm">
      <div className="flex space-x-1.5 py-3 italic text-white">
        <span>Content Lab Video AI</span>
        <IconSparkles size={18} stroke={1.5} />
      </div>
      <div className="group relative h-full w-full divide-y rounded-lg bg-white">
        <div className="flex w-full items-center justify-center rounded-t-lg bg-gradient-to-br from-slate-50 to-slate-200 py-5">
          <div className="w-56">
            <UpscaledVideoPreview eventId={eventId!} broadcastId={broadcastId!} />
          </div>
        </div>
        <div className="flex items-center justify-between p-1.5 pr-3">
          <div className="flex items-center space-x-1">
            <div className="flex h-12 w-12 items-center justify-center rounded-lg">
              <IconWindowMaximize size={27} stroke={1.25} className="text-slate-400" />
            </div>
            <div>
              <div className="font-medium">Upscale Video</div>
              <div className="mt-0.5 text-xs leading-tight text-slate-600">
                <p>Enhance the video quality with AI.</p>
                <p>{durationNote}</p>
              </div>
            </div>
          </div>
          <div className="col-span-2 flex justify-end py-1.5">
            <Toggle on={shouldUpscaleVideo} onToggle={toggle} />
          </div>
        </div>
      </div>
    </div>
  );
}
