import { useState, useEffect, memo, useCallback } from 'react';
import { MAX_PROFILE_NAME_LENGTH, VoiceProfileSteps } from './constants';
import ContentDialog from '@/Pages/Sessions/uiComponents/ContentDialog/ContentDialog';
import Toggle from '@/components/atoms/Toggle';
import Button from '@/components/atoms/Button/Button';
import useVoiceProfile from '@/hooks/useVoiceProfile';
import {
  updateVoiceProfile,
  loadVoiceProfileList,
  resetSelectedVoiceProfile,
  updateActiveStep
} from '@/stores/voiceProfile';
import { showErrorToast } from '@/libs/toast/toast';
import { useAppContext } from '@/context/AppContext/AppContext';

function SaveVoiceProfile({ isOpen, closeDialog }: { isOpen: boolean; closeDialog: () => void }): JSX.Element {
  const [profileName, setProfileName] = useState('');
  const [isDefaultProfile, setIsDefaultProfile] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const { selectedVoiceProfile } = useVoiceProfile();
  const { logger } = useAppContext();

  useEffect(() => {
    setProfileName(selectedVoiceProfile?.name || '');
    setIsDefaultProfile(selectedVoiceProfile?.default || false);
  }, [selectedVoiceProfile]);

  const onDefaultProfileToggle = useCallback(() => {
    setIsDefaultProfile(!isDefaultProfile);
  }, [isDefaultProfile]);

  const handleClose = useCallback(() => {
    resetSelectedVoiceProfile();
    closeDialog();
  }, [closeDialog]);

  const handlePrevious = useCallback(() => {
    updateActiveStep(VoiceProfileSteps.TuneVoiceProfile);
  }, []);

  const handleNext = useCallback(() => {
    if (!selectedVoiceProfile) {
      handleClose();
      return;
    }

    const { name, default: isDefault } = selectedVoiceProfile;

    if (profileName.trim().length > MAX_PROFILE_NAME_LENGTH) {
      showErrorToast(`Profile name should be less than ${MAX_PROFILE_NAME_LENGTH} characters`);
      return;
    }

    if (!profileName.trim()) {
      showErrorToast('Profile name is required');
      return;
    }

    const payload = {
      ...(profileName !== name && { name: profileName }),
      ...(isDefaultProfile !== isDefault && { default: isDefaultProfile })
    };

    if (Object.keys(payload).length === 0) {
      handleClose();
      return;
    }
    setIsSaving(true);
    updateVoiceProfile({
      id: selectedVoiceProfile?.id,
      payload
    })
      .then(() => {
        loadVoiceProfileList();
        handleClose();
      })
      .catch(err => {
        const error = JSON.parse(err);
        showErrorToast(error?.non_field_errors?.[0] || 'Failed to update voice profile');
        logger.error('Failed to update voice profile', err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  }, [profileName, isDefaultProfile, selectedVoiceProfile, handleClose, logger]);

  return (
    <ContentDialog
      isOpen={isOpen}
      size="large"
      setIsOpen={handleClose}
      disableBackdropClose={true}
      panelClassNames="text-md"
    >
      <>
        <div className="absolute top-0 z-50 flex items-center justify-between pl-5 pt-5">
          <div className="text-2xl font-semibold">Save your Profile</div>
        </div>
        <div className="mt-7 px-5">
          <div>Save your tone of voice profile to use for future content creation.</div>
          <div className="mt-5 w-full">
            <div>
              <input
                type="text"
                className="w-full rounded-lg border px-4 py-3.5 text-sm text-black placeholder:text-slate-500 focus:border-black focus:ring-black"
                value={profileName}
                onInput={e => setProfileName(e.currentTarget.value)}
              />
            </div>

            <div className="mt-5 flex items-center justify-between font-medium">
              <label className="text-sm">Set as default voice profile</label>
              <div className="rounded-md text-right focus:outline-none focus:ring-2 focus:ring-slate-950">
                <Toggle on={isDefaultProfile} onToggle={onDefaultProfileToggle} />
              </div>
            </div>
          </div>
        </div>
        <div className="flex px-5 py-4">
          <div className="flex w-full justify-between pt-5">
            <Button
              buttonSize="large"
              variation="outline"
              onClick={handlePrevious}
              className="h-10 rounded-lg px-6 py-2 text-sm"
              trackingId="save-voice-profile-previous-click"
            >
              Previous
            </Button>
            <Button
              className="h-10 rounded-lg px-6 py-2 text-sm"
              buttonSize="large"
              variation="filled"
              disabled={isSaving}
              trackingId="save-voice-profile-next-click"
              onClick={handleNext}
            >
              Save
            </Button>
          </div>
        </div>
      </>
    </ContentDialog>
  );
}

export default memo(SaveVoiceProfile);
