import React, { memo, useEffect, useState } from 'react';

import { fetchFilmstripIndex } from './ClipTimelineUtils';
import Filmstrip from './Filmstrip';
import Loader from '@/components/atoms/Loader';

function FrameRenderer({
  start,
  end,
  frameOffsetWhenDragging,
  shouldExpand,
  singleFrameWidth,
  sectionWidth,
  showBorderY,
  movingStartForFilmstrip,
  projectId,
  contentId
}: {
  start: number;
  end: number;
  frameOffsetWhenDragging: number;
  shouldExpand: boolean;
  singleFrameWidth: number;
  sectionWidth?: number;
  showBorderY?: boolean;
  movingStartForFilmstrip: number;
  projectId: string;
  contentId: string;
}) {
  const [loadingFilmstripFilenames, setLoadingFilmstripFilenames] = useState(true);
  const [filmstripFilenames, setFilmstripFilenames] = useState<string[]>([]);

  useEffect(() => {
    // ! ensure this effect is triggered only once
    fetchFilmstripIndex(projectId, contentId)
      .then(allFilenames => {
        setFilmstripFilenames(allFilenames);
      })
      .finally(() => {
        setLoadingFilmstripFilenames(false);
      });
  }, [projectId, contentId]);

  return (
    <div className={'absolute inset-0 z-20 overflow-hidden ' + (shouldExpand ? 'bg-white' : 'rounded-md')}>
      {loadingFilmstripFilenames ? (
        <div className="flex h-full w-full items-center justify-center">
          <Loader />
        </div>
      ) : filmstripFilenames.length === 0 ? (
        <div
          className={`flex h-full w-full items-center justify-center ` + (showBorderY ? 'border-y-2 border-black' : '')}
        >
          Preview unavailable, but you can still adjust your timeline!
        </div>
      ) : (
        <Filmstrip
          filmstripFilenames={filmstripFilenames}
          shouldExpand={shouldExpand}
          singleFrameWidth={singleFrameWidth}
          sectionWidth={sectionWidth}
          start={start}
          movingStartForFilmstrip={movingStartForFilmstrip}
          frameOffsetWhenDragging={frameOffsetWhenDragging}
          end={end}
          showBorderY={showBorderY}
        />
      )}
    </div>
  );
}

export default memo(FrameRenderer);
