import { useNavigate } from 'react-router-dom';

export default function useHistory() {
  const navigate = useNavigate();

  const push = (path: string) => {
    navigate(path, { replace: false });
  };

  const replace = (path: string) => {
    navigate(path, { replace: true });
  };

  return {
    push,
    replace
  };
}
