import createStore from './store';

// #region Prompt Overrides Store
export const promptOverrides = createStore<{
  [key: string]: string;
}>({});

export function addPromptOverride(key: string, value: string) {
  promptOverrides.update(overrides => ({
    ...overrides,
    [key]: value
  }));
}

export function resetPromptOverrides() {
  promptOverrides.set(() => ({}));
}
// #endregion Prompt Overrides Store

// #region Internal Metadata Store
export const internalMetadata = createStore<{
  [key: string]: string;
}>({});

export function setInternalMetadata(metadata: { [key: string]: string }) {
  internalMetadata.set(() => metadata);
}

export function resetInternalMetadata() {
  internalMetadata.set(() => ({}));
}
// #endregion Internal Metadata Store
