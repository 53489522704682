import { assetsSavedSearchDestroy, SavedSearches } from '@goldcast/api/content';
import { memo, useCallback, useMemo } from 'react';
import { IconX } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '@/libs/core';
import ConfirmationDialog from '@/components/organisms/ConfirmationDialog';
import useDialog from '@/components/organisms/useDialog';
import { useAppContext } from '@/context/AppContext/AppContext';
import { showErrorToast } from '@/libs/toast/toast';
import { capitalizeFirstLetter } from '@/Pages/PostsListPage/utils';

function SavedSearchDropdownItem({
  savedSearch,
  onDelete
}: {
  savedSearch: SavedSearches;
  onDelete: (id: string) => void;
}) {
  const navigate = useNavigate();
  const { logger } = useAppContext();
  const {
    isOpen: isDeleteConfirmationOpen,
    openDialog: openDeleteConfirmationDialog,
    closeDialog: closeDeleteConfirmationDialog
  } = useDialog();

  function deleteSavedSearch() {
    assetsSavedSearchDestroy({ id: savedSearch.id })
      .then(() => {
        onDelete(savedSearch.id);
      })
      .catch(err => {
        logger.error(`Error deleting Saved Search with id ${savedSearch.id}. Stack trace: ${err}`);
        showErrorToast("Whoops! Deleting the Saved Search didn't go as planned. Check your connection and try again.");
      });
  }

  const openSavedSearch = useCallback(() => {
    navigate(`/search/${savedSearch.id}/clips`);
  }, [navigate]);

  const formattedCreatedBy = useMemo(() => {
    return !!savedSearch.created_by
      ? `${savedSearch.created_by.first_name} ${savedSearch.created_by.last_name}`
      : 'Anonymous';
  }, [savedSearch.created_by]);

  const formattedCreatedAt = useMemo(() => {
    return formatDate(savedSearch.created_at as string);
  }, [savedSearch.created_at]);

  const formattedDescription = useMemo(() => {
    let description = '';
    if (!!formattedCreatedBy.trim()) description += `by ${formattedCreatedBy} `;
    if (!!formattedCreatedAt.trim()) description += `on ${formattedCreatedAt}`;
    return capitalizeFirstLetter(description);
  }, [formattedCreatedBy, formattedCreatedAt]);

  return (
    <div
      className="group flex cursor-pointer items-center gap-4 rounded-lg hover:bg-slate-100"
      title={savedSearch.title}
    >
      <button
        type="button"
        key={savedSearch.id}
        className="z-10 flex w-full items-center gap-2 px-2 py-2.5 pl-3 text-left text-sm"
        onClick={openSavedSearch}
      >
        <div className="leading-normal">
          <p className="mb-0 line-clamp-2">{savedSearch.title}</p>
          <p className="text-2xs text-slate-500">{formattedDescription}</p>
        </div>
      </button>
      <button
        type="button"
        data-test-id="delete-saved-search-button"
        onClick={openDeleteConfirmationDialog}
        className="invisible px-4 py-1 text-xs text-slate-500 hover:text-slate-700 group-hover:visible"
      >
        <IconX size={16} />
      </button>
      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        onClose={closeDeleteConfirmationDialog}
        onConfirm={deleteSavedSearch}
        title="Delete Saved Search?"
        content="Are you sure you want to delete this saved search?"
        confirmLabel="Delete"
      />
    </div>
  );
}

export default memo(SavedSearchDropdownItem);
