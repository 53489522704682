import React, { MouseEvent } from 'react';
import './ButtonGroup.css';
import { getRoundedValue, sizes } from './ButtonUtils';
import { classnames } from '@/libs/utils';

export default function IconButtonGroup({
  size = 'base',
  variation,
  buttons = []
}: {
  size?: keyof typeof sizes;
  variation?: 'filled' | 'outline' | 'list' | 'warn' | 'inline';
  buttons: {
    content: string;
    action: (e: MouseEvent<HTMLButtonElement>) => void;
    icon: any;
    disabled?: boolean;
    description?: string;
    trackingId: string;
  }[];
}) {
  return (
    <div
      className={`inline-flex overflow-hidden
        ${size === 'base' ? 'h-8 rounded-lg text-xs' : 'h-6 rounded-md text-2xs'}
        ${variation === 'filled' ? 'btn-group-filled' : ''}
        ${variation === 'outline' ? 'btn-group-outline' : ''}
        ${variation === 'list' ? 'btn-group-list' : ''}
        ${variation === 'warn' ? 'btn-group-warn' : ''}
        ${variation === 'inline' ? 'divide-x-0' : 'divide-x'}
    `}
    >
      {buttons.map((button, i) => {
        const { icon: Icon, content, disabled } = button;

        return (
          <button
            disabled={disabled}
            className={classnames(
              `flex items-center justify-center font-medium focus:outline-none focus:ring-inset focus:ring-deep-orange-600 ${
                i === 0 ? getRoundedValue(size, 'left') : i === buttons.length - 1 ? getRoundedValue(size, 'right') : ''
              }`,
              size === 'base' ? 'px-3 py-2' : 'px-2',
              variation === 'filled' ? 'btn-group-btn-filled' : '',
              variation === 'outline' ? 'btn-group-btn-outline' : '',
              variation === 'list' ? 'btn-group-btn-list' : '',
              variation === 'inline' ? 'btn-group-btn-list' : '',
              variation === 'warn' ? 'btn-group-btn-warn' : ''
            )}
            title={button.description}
            onPointerUp={e => {
              e.preventDefault();
              e.stopPropagation();
              if (!button.disabled) button.action(e);
            }}
            key={i}
            data-testid={button.trackingId}
          >
            {content !== '' ? (
              <>
                <Icon size={`${size === 'base' ? '20' : '16'}`} className="-ml-1 mr-1" />
                {content}
              </>
            ) : (
              <Icon size={`${size === 'base' ? '20' : '16'}`} className="-mx-1" />
            )}
          </button>
        );
      })}
    </div>
  );
}
