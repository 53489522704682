import { ClipAssetMetadata } from '@/stores/postStore';

export function extractClipObjectFromText(text: string): ClipAssetMetadata | null {
  if (!text?.includes('::clipPlayer')) {
    return null;
  }
  const clip = JSON.parse(text.replace('::clipPlayer', ''));

  return {
    clip_asset_ids: clip.clip_asset_ids,
    currentClipIndex: clip.currentClipIndex,
    activeClipIndex: clip.activeClipIndex,
    ...(clip.next_clip_info && { next_clip_info: clip.next_clip_info })
  };
}

export function createPostTextFromClipObject(newClipObject: ClipAssetMetadata): string {
  const formatValue = (value: any): string => {
    if (Array.isArray(value)) {
      return `[${value.map(v => formatValue(v)).join(', ')}]`;
    } else if (typeof value === 'string') {
      return `"${value}"`;
    } else {
      return `${value}`;
    }
  };

  const newClipString = Object.entries(newClipObject)
    .map(([key, value]) => `"${key}": ${formatValue(value)}`)
    .join(',');

  return `::clipPlayer{${newClipString}}`;
}
