import { useState } from 'react';

export default function useElementHoveredHook() {
  const [isHovered, setIsHovered] = useState(false);

  const handleOnMouseEnter = () => {
    setIsHovered(true);
  };

  const handleOnMouseLeave = () => {
    setIsHovered(false);
  };

  return { isHovered, handleOnMouseEnter, handleOnMouseLeave };
}
