import {
  createContext,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useParams } from 'react-router-dom';
import {
  assetsMediaContentConfigCreate,
  assetsMediaContentConfigList,
  assetsMediaContentConfigPartialUpdate,
  BrandKitTemplateResponse,
  brandTemplateList,
  BrandTemplateListAPIQueryParams,
  MediaContentConfig
} from '@goldcast/api/content';
import { DEFAULT_COLOR } from './constants';
import { PreviewTemplate } from '../Clip/SideBar/types';
import { ContentStatesEnum } from '../GenerateContent/constants';
import { FTUXConfig, getLocalFtuxConfig, storeLocalFtuxConfig } from './localFtuxConfig.util';
import { INTERIM_THUMBNAIL_PREVIEW_WORDS } from './VideoImportDialog/TemplateSelect/constants';
import { brandKit, loadBrandKitData } from '@/stores/brandKit';
import usePreviousEffect from '@/hooks/usePreviousEffect';
import useDebounce from '@/hooks/useDebounce';
import useContentUploadHook from '@/hooks/useContentUploadHook';
import useAnalytics from '@/hooks/useAnalytics';

interface VideoImportFilters {
  title: string | undefined;
  size: string | undefined;
}

interface VideoImportContextData {
  selectedColor: string;
  selectedFontUrl: string;
  selectedBrandTemplateIds: { preset: string[]; saved: string[] };
  brandTemplates: { preset: BrandKitTemplateResponse[]; saved: BrandKitTemplateResponse[] };
  brandTemplatePreviews: {
    preset: PreviewTemplate[];
    saved: PreviewTemplate[];
    presetsLoading: boolean;
    savedLoading: boolean;
  };
  mediaContentConfigRef: MutableRefObject<MediaContentConfig | null | undefined>;
  filters: VideoImportFilters;
  navigationDisabled: boolean;
  setSelectedColor: (color: string) => void;
  setSelectedFontUrl: (fontUrl: string) => void;
  setSelectedPresetTemplateIds: Dispatch<SetStateAction<string[]>>;
  setSelectedSavedTemplateIds: Dispatch<SetStateAction<string[]>>;
  storeMediaContentConfig: () => void;
  setFilters: Dispatch<SetStateAction<VideoImportFilters>>;
  setNavigationDisabled: Dispatch<SetStateAction<boolean>>;
}

const VideoImportContext = createContext<VideoImportContextData>({
  selectedColor: '',
  selectedFontUrl: '',
  selectedBrandTemplateIds: { preset: [], saved: [] },
  brandTemplates: { preset: [], saved: [] },
  brandTemplatePreviews: { preset: [], saved: [], presetsLoading: false, savedLoading: false },
  mediaContentConfigRef: { current: null },
  filters: { title: undefined, size: undefined },
  navigationDisabled: false,
  setSelectedColor: () => {},
  setSelectedFontUrl: () => {},
  setSelectedPresetTemplateIds: () => {},
  setSelectedSavedTemplateIds: () => {},
  storeMediaContentConfig: () => {},
  setFilters: () => {},
  setNavigationDisabled: () => {}
});

export function VideoImportContextProvider({ children }: { children: React.ReactNode }) {
  const { broadcastId } = useParams<{ broadcastId: string }>();

  const localFtuxConfig = getLocalFtuxConfig(broadcastId);

  const [selectedColor, setSelectedColor] = useState<string>(localFtuxConfig?.color || DEFAULT_COLOR);
  const [selectedFontUrl, setSelectedFontUrl] = useState<string>(localFtuxConfig?.font_location || '');
  const [selectedPresetTemplateIds, setSelectedPresetTemplateIds] = useState<string[]>(
    localFtuxConfig?.templates
      ?.filter((t: BrandKitTemplateResponse) => !t.organization)
      ?.map((t: BrandKitTemplateResponse) => t.id) || []
  );
  const [selectedSavedTemplateIds, setSelectedSavedTemplateIds] = useState<string[]>(
    localFtuxConfig?.templates
      ?.filter((t: BrandKitTemplateResponse) => !!t.organization)
      ?.map((t: BrandKitTemplateResponse) => t.id) || []
  );
  const [navigationDisabled, setNavigationDisabled] = useState<boolean>(false);

  const selectedBrandTemplateIds = useMemo(() => {
    return { preset: selectedPresetTemplateIds, saved: selectedSavedTemplateIds };
  }, [selectedPresetTemplateIds, selectedSavedTemplateIds]);

  const [presetBrandTemplates, setPresetBrandTemplates] = useState<BrandKitTemplateResponse[]>([]);
  const [savedBrandTemplates, setSavedBrandTemplates] = useState<BrandKitTemplateResponse[]>([]);

  const [presetBrandTemplatePreviews, setPresetBrandTemplatePreviews] = useState<PreviewTemplate[]>([]);
  const [savedBrandTemplatePreviews, setSavedBrandTemplatePreviews] = useState<PreviewTemplate[]>([]);

  const [presetTemplatesLoading, setPresetTemplatesLoading] = useState<boolean>(false);
  const [savedTemplatesLoading, setSavedTemplatesLoading] = useState<boolean>(false);

  const [filters, setFilters] = useState<VideoImportFilters>({ title: undefined, size: undefined });

  const mediaContentConfigRef = useRef<MediaContentConfig | null | undefined>(null);

  const { getContentUploadById } = useContentUploadHook();
  const contentUpload = getContentUploadById(broadcastId);
  const { contentState, av_type } = contentUpload;

  const { trackVideoImportCustomizations } = useAnalytics();

  const fetchBrandTemplates = useCallback(
    ({ has_organization, limit }: { has_organization: boolean; limit: number }) => {
      if (has_organization) {
        setPresetTemplatesLoading(true);
      } else {
        setSavedTemplatesLoading(true);
      }

      brandTemplateList({
        queryParams: {
          has_organization,
          limit,
          layout: av_type === 'AUDIO' ? 'AUDIOGRAM' : undefined,
          title: filters.title,
          size: filters.size as BrandTemplateListAPIQueryParams['size']
        }
      })
        .then(response => {
          const previews: PreviewTemplate[] = (response.results || [])
            .map((template: BrandKitTemplateResponse) => {
              const templateFont = template.config.captions?.[0]?.style.regular_style.font_location;
              const templateBgColor = template.config.background_color;

              return {
                id: template.id,
                captions: INTERIM_THUMBNAIL_PREVIEW_WORDS,
                layout: template.layout,
                size: template.size,
                title: template.title || '',
                position: template.config.position !== undefined ? template.config.position : 0.5,
                font_location: has_organization && selectedFontUrl ? selectedFontUrl : templateFont,
                magicLayout: {
                  backgroundColor: has_organization && selectedColor ? selectedColor : templateBgColor,
                  textColor: template.config.captions?.[0]?.style.regular_style.font_color,
                  showSpeakerLabels: !!template.config.show_speaker_labels,
                  backgroundImage: template.config.background_image
                },
                isUnavailable: false,
                message: '',
                reason: '',
                isProcessing: false,
                subtitle: template.config.subtitle,
                isDefault: !template.organization,
                tags: template.tags,
                highlight_type: template.config.captions?.[0]?.style.highlight_style?.highlight_type,
                word_highlight_color: template.config.captions?.[0]?.style.highlight_style?.background_color,
                text_highlight_color: template.config.captions?.[0]?.style.highlight_style?.font_color,
                createdAt: template.created_at,
                default: template.config.default,
                caption_positions: template.config.caption_positions,
                caption_styles: template.config.caption_styles,
                hide_borders:
                  template.config.hide_borders !== undefined ? template.config.hide_borders : !template.config.subtitle
              };
            })
            .sort((a, b) => (a.position || 0) - (b.position || 0));

          if (has_organization) {
            setPresetBrandTemplates(response.results || []);
            setPresetBrandTemplatePreviews(previews);
          } else {
            setSavedBrandTemplates(response.results || []);
            setSavedBrandTemplatePreviews(previews);
          }
        })
        .finally(() => {
          if (has_organization) {
            setPresetTemplatesLoading(false);
          } else {
            setSavedTemplatesLoading(false);
          }
        });
    },
    [av_type, filters.size, filters.title]
  );

  useEffect(() => {
    fetchBrandTemplates({ has_organization: true, limit: 100 });
    fetchBrandTemplates({ has_organization: false, limit: 100 });
  }, [fetchBrandTemplates]);

  useEffect(() => {
    if (localFtuxConfig) {
      return;
    }

    const brandKitSnapshot = brandKit.getSnapshot();

    if (brandKitSnapshot.fontsMap && brandKitSnapshot.colors.length) {
      setSelectedFontUrl(Object.keys(brandKitSnapshot.fontsMap)[0]);
      setSelectedColor(brandKitSnapshot.colors[0]);
      return;
    }

    loadBrandKitData().then(() => {
      const brandKitSnapshot = brandKit.getSnapshot();
      if (brandKitSnapshot.fontsMap) {
        setSelectedFontUrl(Object.keys(brandKitSnapshot.fontsMap)[0]);
      }

      if (brandKitSnapshot.colors.length) {
        setSelectedColor(brandKitSnapshot.colors[0]);
      }
    });
  }, [localFtuxConfig]);

  useEffect(() => {
    if (!broadcastId) {
      return;
    }

    assetsMediaContentConfigList({ queryParams: { upload: broadcastId, limit: 1 } }).then(response => {
      const mediaContentConfig = response.results?.[0];

      if (!mediaContentConfig) {
        storeMediaContentConfig();
        return;
      }

      mediaContentConfigRef.current = mediaContentConfig;

      if (!mediaContentConfig.ftux_config) {
        return;
      }

      if (mediaContentConfig.ftux_config.color) {
        setSelectedColor(mediaContentConfig.ftux_config.color);
      }

      if (mediaContentConfig.ftux_config.font_location) {
        setSelectedFontUrl(mediaContentConfig.ftux_config.font_location);
      }

      if (mediaContentConfig.ftux_config.templates) {
        setSelectedPresetTemplateIds(
          mediaContentConfig.ftux_config.templates
            ?.filter((t: BrandKitTemplateResponse) => !t.organization)
            ?.map((t: BrandKitTemplateResponse) => t.id)
        );
        setSelectedSavedTemplateIds(
          mediaContentConfig.ftux_config.templates
            ?.filter((t: BrandKitTemplateResponse) => !!t.organization)
            ?.map((t: BrandKitTemplateResponse) => t.id)
        );
      }
    });
  }, [
    broadcastId
    // ! Do not add storeMediaContentConfig here
  ]);

  const applyAnyChangesToPreviews = useCallback(
    (newFtuxConfig: FTUXConfig) => {
      if (!newFtuxConfig.color && !newFtuxConfig.font_location) {
        return;
      }

      setPresetBrandTemplatePreviews(
        presetBrandTemplatePreviews.map(p => ({
          ...p,
          magicLayout: { ...p.magicLayout, backgroundColor: newFtuxConfig.color },
          font_location: newFtuxConfig.font_location
        })) as PreviewTemplate[]
      );
    },
    [presetBrandTemplatePreviews]
  );

  const storeMediaContentConfig = useCallback(() => {
    if (!broadcastId) {
      return;
    }

    const newFtuxConfig: FTUXConfig = {
      color: selectedColor,
      font_location: selectedFontUrl || undefined,
      templates: [...presetBrandTemplates, ...savedBrandTemplates].filter(
        t => selectedBrandTemplateIds.preset.includes(t.id) || selectedBrandTemplateIds.saved.includes(t.id)
      )
    };

    storeLocalFtuxConfig(broadcastId, newFtuxConfig);
    applyAnyChangesToPreviews(newFtuxConfig);

    if (contentState !== ContentStatesEnum.Processing) {
      return;
    }

    if (!mediaContentConfigRef.current) {
      assetsMediaContentConfigCreate({
        body: {
          upload: broadcastId,
          ftux_config: newFtuxConfig
        } as unknown as MediaContentConfig // @kashish TODO: Fix type once API is updated
      })
        .then(res => {
          mediaContentConfigRef.current = res;
        })
        .catch(error => {
          console.error('Error storing media content config', error);
        });
      return;
    }

    assetsMediaContentConfigPartialUpdate({
      id: mediaContentConfigRef.current.id,
      body: { ftux_config: newFtuxConfig }
    })
      .then(response => {
        mediaContentConfigRef.current = response;
      })
      .catch(error => {
        console.error('Error storing media content config', error);
      });
  }, [
    applyAnyChangesToPreviews,
    broadcastId,
    contentState,
    presetBrandTemplates,
    savedBrandTemplates,
    selectedBrandTemplateIds,
    selectedColor,
    selectedFontUrl
  ]);

  const debouncedStoreMediaContentConfig = useDebounce(storeMediaContentConfig, 1000);
  const debouncedTrackVideoImportCustomizations = useDebounce(trackVideoImportCustomizations, 1000);

  usePreviousEffect({ selectedColor, selectedFontUrl, selectedBrandTemplateIds }, (prev, current) => {
    if (prev?.selectedColor !== current.selectedColor) {
      debouncedStoreMediaContentConfig();
      debouncedTrackVideoImportCustomizations('ColorChange');
      return;
    }

    if (prev?.selectedFontUrl !== current.selectedFontUrl) {
      debouncedStoreMediaContentConfig();
      debouncedTrackVideoImportCustomizations('FontChange');
      return;
    }

    if (prev?.selectedBrandTemplateIds !== current.selectedBrandTemplateIds) {
      debouncedStoreMediaContentConfig();
      debouncedTrackVideoImportCustomizations('TemplateSelect');
      return;
    }
  });

  const videoImportContextProviderValue: VideoImportContextData = useMemo(() => {
    return {
      selectedColor,
      selectedFontUrl,
      selectedBrandTemplateIds,
      brandTemplates: { preset: presetBrandTemplates, saved: savedBrandTemplates },
      brandTemplatePreviews: {
        preset: presetBrandTemplatePreviews,
        saved: savedBrandTemplatePreviews,
        presetsLoading: presetTemplatesLoading,
        savedLoading: savedTemplatesLoading
      },
      mediaContentConfigRef: mediaContentConfigRef,
      filters,
      navigationDisabled,
      setSelectedColor,
      setSelectedFontUrl,
      setSelectedPresetTemplateIds,
      setSelectedSavedTemplateIds,
      storeMediaContentConfig,
      setFilters,
      setNavigationDisabled
    };
  }, [
    selectedColor,
    selectedFontUrl,
    selectedBrandTemplateIds,
    presetBrandTemplates,
    savedBrandTemplates,
    presetBrandTemplatePreviews,
    savedBrandTemplatePreviews,
    presetTemplatesLoading,
    savedTemplatesLoading,
    filters,
    navigationDisabled,
    storeMediaContentConfig,
    setFilters,
    setNavigationDisabled
  ]);

  return <VideoImportContext.Provider value={videoImportContextProviderValue}>{children}</VideoImportContext.Provider>;
}

export function useVideoImportContext() {
  return useContext(VideoImportContext);
}
