import { BrandKitTemplateResponse } from '@goldcast/api/content';

const SESSION_STORAGE_FTUX_CONFIG_KEY = 'ftux_configs';

export type FTUXConfig = {
  color?: string;
  font_location?: string;
  templates?: BrandKitTemplateResponse[];
};

export const getLocalFtuxConfig = (contentId?: string): FTUXConfig | undefined => {
  if (!contentId) {
    return undefined;
  }

  return JSON.parse(sessionStorage.getItem(SESSION_STORAGE_FTUX_CONFIG_KEY) || '{}')[contentId || ''] as FTUXConfig;
};

export const storeLocalFtuxConfig = (contentId: string, config: FTUXConfig) => {
  const localFtuxConfig = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_FTUX_CONFIG_KEY) || '{}');
  localFtuxConfig[contentId] = config;
  sessionStorage.setItem(SESSION_STORAGE_FTUX_CONFIG_KEY, JSON.stringify(localFtuxConfig));
};
