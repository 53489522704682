import NewTemplateModal from './NewTemplateModal';
import ContentDialog from '@/Pages/Sessions/uiComponents/ContentDialog/ContentDialog';

export default function NewTemplateDialog({
  isOpen,
  closeDialog,
  onSave
}: {
  isOpen: boolean;
  closeDialog: () => void;
  onSave?: () => void;
}) {
  function closeOnSave() {
    closeDialog();
    onSave?.();
  }

  return (
    <ContentDialog
      isOpen={isOpen}
      setIsOpen={closeDialog}
      title="Save as Template"
      size="small"
      panelClassNames="max-h-fit"
    >
      <NewTemplateModal onSave={closeOnSave} />
    </ContentDialog>
  );
}
