import React, { useEffect } from 'react';
import ProgressBar from './ProgressBar';
import {
  ClipPlayerSection,
  useIntroRefReturn,
  useMainSectionRefReturn,
  useOutroRefReturn
} from '../ClipPlayerSections/ClipPlayerSectionsTypes';
import useClipTranscriptPlayPause from './useClipTranscriptPlayPause';
import PlaybackRateButton from '../PlaybackRateButton';
import PlayPause from '@/components/molecules/PlayPause';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';

export default function ClipPlayerControls({
  clipMetadata,
  sections,
  currentSection,
  setCurrentSectionAndPlay,
  outroPlayerProps,
  mainPlayerProps,
  introPlayerProps,
  disabled
}: {
  clipMetadata: any;
  sections: ClipPlayerSection[];
  currentSection: ClipPlayerSection;
  setCurrentSectionAndPlay: any;
  outroPlayerProps: useOutroRefReturn;
  mainPlayerProps: useMainSectionRefReturn;
  introPlayerProps: useIntroRefReturn;
  disabled: boolean;
}) {
  const { isPaused, playCurrentSection, pauseCurrentSection } = useClipTranscriptPlayPause({
    currentSection,
    outroPlayerProps,
    mainPlayerProps,
    introPlayerProps
  });

  function onSeeked(index: number, time: number) {
    setCurrentSectionAndPlay(sections[index], !isPaused, time);
  }

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.OpenSpeakersIdentification, pauseCurrentSection);
    return () => {
      EventBus.off(CustomEvents.OpenSpeakersIdentification, eventListener);
    };
  }, [currentSection]);

  return (
    <div className="flex w-full items-center justify-between bg-white px-3 py-2">
      <div />
      <div className="mx-auto flex w-full max-w-screen-md items-center gap-0.5">
        <PlayPause paused={isPaused} onPlay={playCurrentSection} onPause={pauseCurrentSection} disabled={disabled} />
        <ProgressBar
          sections={sections}
          currentSection={currentSection}
          clipMetadata={clipMetadata}
          outroPlayerProps={outroPlayerProps}
          mainPlayerProps={mainPlayerProps}
          introPlayerProps={introPlayerProps}
          onSeeked={onSeeked}
          disabled={disabled}
        />
      </div>
      <PlaybackRateButton />
    </div>
  );
}
