import React, { useState } from 'react';
import { EditableInput } from 'react-color/lib/components/common/EditableInput';
import { IconSwitchVertical } from '@tabler/icons-react';
import * as color from 'react-color/lib/helpers/color';
import { ColorResult } from './types';

export const ColorPickerFields = ({ onChange, hex, rgb, hsl }) => {
  const [view, setView] = useState('hex');
  const toggleViews = () => {
    switch (view) {
      case 'hex':
        setView('rgb');
        break;
      case 'rgb':
        setView('hsl');
        break;
      default:
        setView('hex');
        break;
    }
  };

  const handleChange = (data: ColorResult) => {
    // handle case when data is {hex: "#12345"}
    if (data.hex && color.isValidHex(data.hex)) {
      onChange(data.hex);
      // handle case when data is "#12345"
    } else if (color.isValidHex(data)) {
      onChange(data);
    } else if (data.r || data.g || data.b) {
      handleRgbChange(data);
    } else if (data.h || data.s || data.l) {
      handleHslChange(data);
    }
  };

  function handleRgbChange(data: ColorResult) {
    onChange({
      r: data.r || rgb.r,
      g: data.g || rgb.g,
      b: data.b || rgb.b
    });
  }

  function handleHslChange(data: ColorResult) {
    // Remove any occurances of '%'.
    if (typeof data.s === 'string' && data.s.includes('%')) {
      data.s = data.s.replace('%', '');
    }
    if (typeof data.l === 'string' && data.l.includes('%')) {
      data.l = data.l.replace('%', '');
    }

    // We store HSL as a unit interval so we need to override the 1 input to 0.01
    if (data.s === 1) {
      data.s = 0.01;
    } else if (data.l === 1) {
      data.l = 0.01;
    }

    onChange({
      h: data.h || hsl.h,
      s: Number(data.s !== undefined ? data.s : hsl.s),
      l: Number(data.l !== undefined ? data.l : hsl.l)
    });
  }

  const inputStyles = {
    input: {
      fontSize: '11px',
      color: '#333',
      width: '100%',
      borderRadius: '2px',
      border: 'none',
      boxShadow: 'inset 0 0 0 1px #dadada',
      height: '30px',
      textAlign: 'left',
      padding: '5px'
    },
    label: {
      textTransform: 'uppercase',
      fontSize: '11px',
      lineHeight: '11px',
      color: '#969696',
      textAlign: 'center',
      display: 'block',
      marginTop: '12px'
    }
  };

  return (
    <div className="flexbox-fix flex pt-[16px]">
      {view === 'hex' && (
        <div className="flexbox-fix ml-[-6px] flex flex-1">
          <div className="w-full pl-[6px]">
            <EditableInput
              style={{ input: inputStyles.input, label: inputStyles.label }}
              value={hex}
              onChange={handleChange}
            />
          </div>
        </div>
      )}
      {view === 'rgb' && (
        <div className="flexbox-fix ml-[-6px] flex flex-1">
          <div className="w-full pl-[6px]">
            <EditableInput
              style={{ input: inputStyles.input, label: inputStyles.label }}
              label="r"
              value={rgb.r}
              onChange={handleChange}
            />
          </div>
          <div className="w-full pl-[6px]">
            <EditableInput
              style={{ input: inputStyles.input, label: inputStyles.label }}
              label="g"
              value={rgb.g}
              onChange={handleChange}
            />
          </div>
          <div className="w-full pl-[6px]">
            <EditableInput
              style={{ input: inputStyles.input, label: inputStyles.label }}
              label="b"
              value={rgb.b}
              onChange={handleChange}
            />
          </div>
        </div>
      )}
      {view === 'hsl' && (
        <div className="flexbox-fix ml-[-6px] flex flex-1">
          <div className="w-full pl-[6px]">
            <EditableInput
              style={{ input: inputStyles.input, label: inputStyles.label }}
              label="h"
              value={Math.round(hsl.h)}
              onChange={handleChange}
            />
          </div>
          <div className="w-full pl-[6px]">
            <EditableInput
              style={{ input: inputStyles.input, label: inputStyles.label }}
              label="s"
              value={`${Math.round(hsl.s * 100)}%`}
              onChange={handleChange}
            />
          </div>
          <div className="w-full pl-[6px]">
            <EditableInput
              style={{ input: inputStyles.input, label: inputStyles.label }}
              label="l"
              value={`${Math.round(hsl.l * 100)}%`}
              onChange={handleChange}
            />
          </div>
        </div>
      )}
      <div className="relative cursor-pointer pl-[5px]" onClick={toggleViews}>
        <IconSwitchVertical
          className="border-1 h-[30px] w-[24px] rounded-md border-solid border-transparent bg-transparent text-slate-500 hover:bg-slate-100"
          data-testid="vertical-icon-switch-view"
        />
      </div>
    </div>
  );
};
