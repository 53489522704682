import { Link } from 'react-router-dom';
import { IconLink } from '@tabler/icons-react';
import { TextAsset } from '../PostsListPage/types';
import { getUriFromTextAsset } from '../PostsListPage/utils';
import ContentDialog from '../Sessions/uiComponents/ContentDialog/ContentDialog';
import useSavedSearch from '@/hooks/useSavedSearch';

export type ClipDeletionError = {
  clipId: string;
  message?: string;
  textAssets?: TextAsset[];
} | null;

export default function ClipDeletionErrorDialog({
  isOpen,
  onClose,
  error
}: {
  isOpen: boolean;
  onClose: () => void;
  error?: ClipDeletionError;
}) {
  const { isSavedSearchPage } = useSavedSearch();

  return (
    <ContentDialog title="Failed to delete clip" size="small" isOpen={isOpen} setIsOpen={onClose}>
      <div className="p-4">
        {error?.textAssets?.length ? (
          <div className="flex flex-col">
            <div>This clip is being referenced in below post(s) and needs to be removed before deleting the clip.</div>
            <div className="ml-1 mt-2">
              {error.textAssets.map(textAsset => (
                <Link
                  key={textAsset.id}
                  to={`/${getUriFromTextAsset(textAsset, error.clipId, isSavedSearchPage)}`}
                  className="mt-1 flex flex-row items-start gap-2 hover:text-deep-orange-500"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconLink className="mt-[5px] h-4 w-4" />
                  {textAsset.title}
                </Link>
              ))}
            </div>
          </div>
        ) : (
          <div>Something went wrong on our end.</div>
        )}
      </div>
    </ContentDialog>
  );
}
