import React from 'react';
import * as TablerExport from '@tabler/icons-react';

export type IconName = Exclude<keyof typeof TablerExport, 'createReactComponent'>;

export interface CustomIconProps extends Omit<TablerExport.IconProps, 'name'> {
  name: IconName;
}

const Icon: React.FC<CustomIconProps> = ({ name, ...restProps }) => {
  const SelectedIcon = TablerExport.icons[name];

  return <SelectedIcon {...restProps} />;
};

export default Icon;
