import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BackgroundTransitionTemplateMock } from './types';
import MockTemplateVideoRenderer from './MockTemplateVideoRenderer';
import MockTemplateTextRenderer from './MockTemplateTextRenderer';
import MockTemplateImageRenderer from './MockTemplateImageRenderer';
import MockTemplateCaptionsRenderer from './MockTemplateCaptionsRenderer';
import { useVideoImportContext } from '../VideoImportContext';

function MockTemplateBackgroundTransitionItem({
  templateMock,
  currentTime
}: {
  templateMock: BackgroundTransitionTemplateMock;
  currentTime: number;
}) {
  const containerRef = useRef<HTMLButtonElement>(null);
  const { selectedColor } = useVideoImportContext();

  const [scale, setScale] = useState(0);

  const backgroundStyles = useMemo(() => {
    return {
      width: `${templateMock.timeline.width * scale}px`,
      height: `${templateMock.timeline.height * scale}px`,
      borderRadius: `${30 * scale}px`,
      ...(templateMock.timeline.background.type === 'image' && {
        backgroundImage: `url(${templateMock.timeline.background.value})`
      }),
      ...(templateMock.timeline.background.type === 'color' && {
        backgroundColor: selectedColor
      }),
      ...(templateMock.timeline.background.type === 'gradient' && {
        background: templateMock.timeline.background.value
      })
    } as React.CSSProperties;
  }, [scale, selectedColor]);

  const elements = useMemo(() => {
    return templateMock.timeline.segments?.[0]?.elements || [];
  }, []);

  const clipStyles = useMemo(() => {
    if (!containerRef.current) return {};

    const { offsetWidth, offsetHeight } = containerRef.current;

    return {
      clipPath: `inset(0 ${offsetWidth * 5 - templateMock.timeline.width * scale}px ${
        offsetHeight * 5 - templateMock.timeline.height * scale
      }px 0 round ${30 * scale}px)`,
      width: `${offsetWidth * 5}px`,
      height: `${offsetHeight * 5}px`
    };
  }, [scale]);

  const fitRectIntoContainer = useCallback(() => {
    if (!containerRef.current) return;
    const { width, height } = containerRef.current?.getBoundingClientRect();
    const { width: canvasWidth, height: canvasHeight } = templateMock.timeline;
    const scaleX = width / canvasWidth;
    const scaleY = height / canvasHeight;
    setScale(Math.min(scaleX, scaleY));
  }, []);

  useEffect(() => {
    fitRectIntoContainer();
    window.addEventListener('resize', fitRectIntoContainer);
    return () => {
      window.removeEventListener('resize', fitRectIntoContainer);
    };
  }, [fitRectIntoContainer]);

  return (
    <button
      className="relative flex h-full w-full cursor-default select-none items-center justify-center"
      ref={containerRef}
    >
      <div className="relative bg-cover bg-center" style={backgroundStyles}>
        {elements.map((element, index) => (
          <div key={index} className="absolute rounded-xl" style={clipStyles}>
            {element.type === 'VIDEO' && <MockTemplateVideoRenderer element={element} scale={scale} />}
            {element.type === 'CAPTIONS' && (
              <MockTemplateCaptionsRenderer element={element} scale={scale} currentTime={currentTime} />
            )}
            {element.type === 'IMAGE' && <MockTemplateImageRenderer element={element} scale={scale} />}
            {element.type === 'TEXT' && <MockTemplateTextRenderer element={element} scale={scale} />}
          </div>
        ))}
      </div>
    </button>
  );
}

export default memo(MockTemplateBackgroundTransitionItem);
