import { memo, useMemo } from 'react';
import { BackgroundTransitionTemplateMock } from './types';

function MockTemplateTextRenderer({
  element,
  scale
}: {
  element: BackgroundTransitionTemplateMock['timeline']['segments'][0]['elements'][0];
  scale: number;
}) {
  const backgroundClasses = useMemo(() => {
    if (element.style === 'Background') {
      return 'bg-black/60 rounded-[2.5px] px-[5px] py-[1px]';
    }
  }, [element.style]);

  const styles = useMemo(() => {
    const elementWidth = element.width || 0;
    const elementHeight = element.height || 0;
    const elementX = element.x || 0;
    const elementY = element.y || 0;
    const elementRotation = element.rotation || 0;
    const elementFontFamily = element.fontFamily || '';
    const elementFontSize = element.fontSize || 0;
    const elementTextColor = element.textColor || '';
    const elementFontWeight = element.fontWeight || '';

    return {
      width: `${elementWidth * scale}px`,
      height: `${elementHeight * scale}px`,
      top: `${elementY * scale}px`,
      left: `${elementX * scale}px`,
      transform: `rotate(${elementRotation}deg)`,
      fontFamily: elementFontFamily,
      fontSize: `${elementFontSize * scale}px`,
      color: elementTextColor,
      fontWeight: elementFontWeight,
      ...(element.style === 'Shadow' && {
        textShadow: '0.1rem 0.1rem 0 #000'
      })
    } as React.CSSProperties;
  }, [element, scale]);

  return (
    <div className={`${backgroundClasses} ${element.alignment} absolute flex`} style={styles}>
      {element.content}
    </div>
  );
}

export default memo(MockTemplateTextRenderer);
