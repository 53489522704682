import React, { useMemo } from 'react';
import { AssetList } from '@goldcast/api/content';
import { getClipThumbnailUrl } from './ClipPreviewUtils';
import { isAudioContent } from '@/stores/core';
import Icon from '@/components/atoms/Icon';

export default function AssetTableClipThumbnail({ asset }: { asset: AssetList }) {
  const isAudio = isAudioContent();
  const [hasThumbnail, setHasThumbnail] = React.useState(true);

  const thumbnailUrl = useMemo(() => {
    return `${getClipThumbnailUrl(asset)}?${Math.random()}`;
  }, [asset]);

  return (
    <div className="flex aspect-[4/3] items-center justify-center rounded-lg border border-transparent bg-slate-100 group-hover:border-slate-200">
      <div
        className={`flex items-center justify-center overflow-hidden rounded-lg border border-slate-200 bg-cover bg-center bg-no-repeat shadow-lg ${
          asset.asset_metadata.size === 'SQUARE'
            ? 'aspect-square w-[62%]'
            : asset.asset_metadata.size === 'PORTRAIT'
            ? 'aspect-[9/16] w-[35%]'
            : 'aspect-video w-[85%]'
        }`}
      >
        <div>
          {hasThumbnail ? (
            <img className="mx-auto" src={thumbnailUrl} alt="Preview" onError={() => setHasThumbnail(false)} />
          ) : (
            <div className="flex h-6 w-6 items-center justify-center rounded-full border border-slate-300">
              <Icon
                name={isAudio ? 'IconHeadphones' : 'IconVideo'}
                size={13}
                stroke={2}
                className="text-slate-400/70"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
