import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import type { IStringifyOptions } from 'qs';

import { useQueryParams } from './useQueryParams';
import useHistory from './useHistory';

export interface UseUpdateQueryParamsReturn<T> {
  updateQueryParams(values: T, options?: IStringifyOptions, replaceHistory?: boolean): void;
  replaceQueryParams(values: T, options?: IStringifyOptions, replaceHistory?: boolean): void;
}

export function useUpdateQueryParams<T = Record<string, string>>(): UseUpdateQueryParamsReturn<T> {
  const { pathname } = useLocation();
  const { push, replace } = useHistory();
  const queryParams = useQueryParams<T>();

  // queryParams, pathname are stored in refs so that
  // updateQueryParams/replaceQueryParams can be memoized without changing too often
  const ref = useRef({ queryParams, pathname });
  useEffect(() => {
    ref.current = {
      queryParams,
      pathname
    };
  }, [queryParams, pathname]);

  const updateQueryParams = useCallback(
    (values: T, options?: IStringifyOptions, replaceHistory?: boolean): void => {
      const path = `${ref.current.pathname}?${qs.stringify({ ...ref.current.queryParams, ...values }, options)}`;
      replaceHistory ? replace(path) : push(path);
    },
    [push, replace]
  );

  const replaceQueryParams = useCallback(
    (values: T, options?: IStringifyOptions, replaceHistory?: boolean): void => {
      const path = `${ref.current.pathname}?${qs.stringify(values, options)}`;
      replaceHistory ? replace(path) : push(path);
    },
    [push, replace]
  );

  return {
    updateQueryParams,
    replaceQueryParams
  };
}
