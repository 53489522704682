import { memo, useCallback, useEffect, useState, useSyncExternalStore } from 'react';
import { useNavigate } from 'react-router-dom';
import { SidebarContext } from '../Clip/SideBar/SideBarUtils';
import { Clip } from '@/domains/asset';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { TranscriptContextProvider } from '@/context/TranscriptContext/TranscriptContext';
import ClipTranscript from '@/components/molecules/Transcript/ClipTranscript/ClipTranscript';
import Loader from '@/components/atoms/Loader';
import { clipCaptionsLoader } from '@/stores/clip';

function ClipEditTranscript({
  clip,
  eventId,
  broadcastId,
  isClipsListCollapsed,
  toggleClipsListCollapse,
  setIsClipsListHidden
}: {
  clip: Clip;
  eventId?: string;
  broadcastId?: string;
  isClipsListCollapsed: boolean;
  toggleClipsListCollapse: () => void;
  setIsClipsListHidden: (val: boolean) => void;
}) {
  const navigate = useNavigate();
  const [isEditCaptionsVisible, setIsEditCaptionsVisible] = useState(false);
  const [isLoadingTranscript, setIsLoadingTranscript] = useState(true);
  const [sideBarContext, setSideBarContext] = useState(SidebarContext.EditCaptions);
  const clipCaptionsLoaderState = useSyncExternalStore(clipCaptionsLoader.subscribe, clipCaptionsLoader.getSnapshot);
  const clipCaptionsLoading = clipCaptionsLoaderState[clip.id] || false;

  const showEditCaptions = useCallback(() => {
    setIsEditCaptionsVisible(true);
  }, []);

  const updateSideBarContext = useCallback((context: SidebarContext) => {
    if (context === SidebarContext.Main) {
      setIsEditCaptionsVisible(false);
      setSideBarContext(SidebarContext.EditCaptions);
    } else {
      setSideBarContext(context);
    }
  }, []);

  useEffect(() => {
    setIsClipsListHidden(isEditCaptionsVisible);
  }, [isEditCaptionsVisible]);

  useEffect(() => {
    if (isClipsListCollapsed && isEditCaptionsVisible) {
      toggleClipsListCollapse();
    }
  }, [isClipsListCollapsed, isEditCaptionsVisible]);

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.OpenEditCaptions, showEditCaptions);
    return () => {
      EventBus.off(CustomEvents.OpenEditCaptions, eventListener);
    };
  }, []);

  const handleTranscriptError = (err: any) => {
    navigate(`/error/${err?.statusCode}`, { replace: true });
  };

  return (
    <TranscriptContextProvider
      broadcastId={broadcastId}
      eventId={eventId}
      onErrorCallback={handleTranscriptError}
      clip_start_time={clip.asset_metadata.start}
      clip_end_time={clip.asset_metadata.end}
      onLoadCompleted={() => setIsLoadingTranscript(false)}
    >
      {!isLoadingTranscript && isEditCaptionsVisible ? (
        <>
          {(isLoadingTranscript || clipCaptionsLoading) && (
            <div className="absolute z-[70] flex h-full w-full items-center justify-center bg-white/70">
              <Loader />
            </div>
          )}
          <div className="w-full bg-white">
            <ClipTranscript
              setSideBarContext={updateSideBarContext}
              sideBarContext={sideBarContext}
              title="Edit Captions"
              isEasyClipCustomizer={true}
              clip={clip}
            />
          </div>
        </>
      ) : (
        <div className="absolute inset-1/2">
          <Loader />
        </div>
      )}
    </TranscriptContextProvider>
  );
}

export default memo(ClipEditTranscript);
