import React, { Fragment } from 'react';

export default function SessionTableSkeleton() {
  return (
    <Fragment>
      {Array.from({ length: 5 }).map((_, i) => (
        <div
          className="flex w-full animate-pulse items-center space-x-4 rounded-lg p-2"
          key={'session-table-skeleton' + i}
        >
          <div className="aspect-video w-32 shrink-0 overflow-hidden rounded-lg bg-slate-100"></div>
          <div className="grow truncate">
            <div className="mb-1.5 h-3 w-1/4 truncate rounded bg-slate-100"></div>
            <div className="h-2 w-32 rounded bg-slate-100"></div>
          </div>
        </div>
      ))}
    </Fragment>
  );
}
