import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import {
  drawSpeakerNameTitlePillForGridRecording,
  drawSpeakerNameTitlePillForSpeakerRecording,
  drawSpeakerNameTitlePillForUpload
} from './StaticCanvasUtils';
import { getFacePositionsForGrid } from './CanvasPlayerUtils';
import EmptyProfilePicturePlaceHolder from '../MagicLayout/Audiogram/EmptyProfilePicturePlaceHolder';
import { ClipMetadata, LayoutType } from '@/domains/asset';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import { getSizeConfig } from '@/libs/sharedAPI/sizeConfig/SizeConfigFactory';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import Waveform from '@/components/atoms/Waveform/Waveform';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function StaticCanvas({
  mainPlayer,
  secondaryPlayers,
  currentSpeaker,
  captionContainerSizes,
  clipLayout,
  isUsingRemotionForSpeakerLabels
}: {
  mainPlayer: React.MutableRefObject<HTMLVideoElement | null>;
  secondaryPlayers: React.RefObject<HTMLVideoElement>[];
  currentSpeaker: SpeakerWithDetails;
  captionContainerSizes: { width: number; height: number };
  clipLayout: LayoutType;
  isUsingRemotionForSpeakerLabels: boolean;
}) {
  const staticCanvasRef = useRef<HTMLCanvasElement>(null);
  const [timeAnalysisIndex, setTimeAnalysisIndex] = useState<number>(-1);

  const { clipId, clipData, speakersAnalysis, sharedAPIStore, playerStore } = useClipsContext();
  const clipMetadata = clipData.asset_metadata as ClipMetadata;
  const showCaption = !!clipMetadata.subtitle;

  const clipSize = clipMetadata.size;
  const showSpeakerLabels = clipMetadata.magicLayout?.showSpeakerLabels && clipLayout !== 'DEFAULT';

  // config used for static canvas
  const staticSizeConfig = useMemo(
    () => getSizeConfig(clipSize, clipId, clipLayout, 2),
    [clipSize, clipId, clipLayout]
  );
  const { height: audiogramImageHeight, width: audiogramImageWidth } =
    staticSizeConfig.getAudiogramImageDimensionsRatio();

  const transcriptStore = useTranscriptContext();

  useEffect(() => {
    const staticCanvas = staticCanvasRef.current;
    if (!staticCanvas) return;

    const { width: staticCanvasWidth, height: staticCanvasHeight } = staticSizeConfig.getHeightAndWidth();

    staticCanvas.width = staticCanvasWidth;
    staticCanvas.height = staticCanvasHeight;
  }, [staticSizeConfig]);

  useEffect(() => {
    setTimeAnalysisIndex(speakersAnalysis.times.findLastIndex(t => t <= playerStore.currentTime));
  }, [speakersAnalysis.times, playerStore.currentTime]);

  useEffect(() => {
    if (!currentSpeaker) return;

    const staticCanvasContext = staticCanvasRef.current?.getContext('2d');
    if (!staticCanvasContext) return;

    staticCanvasContext.clearRect(0, 0, staticSizeConfig.getWidth(), staticSizeConfig.getHeight());

    if (clipLayout === 'AUDIOGRAM') {
      const image = sharedAPIStore.speakerImageMap?.[currentSpeaker.key]?.imageRef;
      if (image) {
        staticSizeConfig.drawAudiogramImage(staticCanvasContext, image);
      }
      staticSizeConfig.drawAudiogramSpeakerLabel(
        staticCanvasContext,
        currentSpeaker,
        clipMetadata.magicLayout?.textColor!
      );
      return;
    }

    if (showSpeakerLabels) {
      if (clipLayout === 'SPEAKER') {
        if (timeAnalysisIndex === -1) {
          drawSpeakerNameTitlePillForSpeakerRecording(
            staticCanvasContext,
            secondaryPlayers,
            currentSpeaker,
            showCaption,
            staticSizeConfig,
            clipLayout,
            clipSize,
            clipData,
            transcriptStore
          );
          return;
        } else {
          // TODO: @AshwinBhatkal - CR-2067 - Remove with following ticket with new speaker flow
          if (!speakersAnalysis.time_analysis[timeAnalysisIndex]) return;

          let facePositions = speakersAnalysis.time_analysis[timeAnalysisIndex].face_positions;

          if (facePositions.length > 1) {
            facePositions = facePositions.filter(facePosition =>
              speakersAnalysis.speaker_mapping[currentSpeaker.id]?.includes(facePosition.face_id)
            );
          }
          drawSpeakerNameTitlePillForUpload(
            staticCanvasContext,
            mainPlayer,
            showCaption,
            staticSizeConfig,
            clipData,
            transcriptStore,
            facePositions
          );
          return;
        }
      } else if (clipLayout === 'GRID') {
        if (timeAnalysisIndex === -1) {
          drawSpeakerNameTitlePillForGridRecording(
            staticCanvasContext,
            secondaryPlayers,
            showCaption,
            staticSizeConfig,
            clipLayout,
            clipSize,
            clipData,
            clipData.asset_metadata.visible_speakers
          );
          return;
        } else {
          // TODO: @AshwinBhatkal - CR-2067 - Remove with following ticket with new speaker flow
          if (!speakersAnalysis.time_analysis[timeAnalysisIndex]) return;

          drawSpeakerNameTitlePillForUpload(
            staticCanvasContext,
            mainPlayer,
            showCaption,
            staticSizeConfig,
            clipData,
            transcriptStore,
            getFacePositionsForGrid(timeAnalysisIndex, speakersAnalysis)
          );
          return;
        }
      }
    }
  }, [
    timeAnalysisIndex,
    currentSpeaker,
    clipLayout,
    clipSize,
    showSpeakerLabels,
    showCaption,
    speakersAnalysis,
    transcriptStore.speakersWithDetails,
    clipData.asset_metadata.magicLayout?.textColor,
    clipData.asset_metadata.magicLayout?.backgroundColor,
    clipData.asset_metadata.visible_speakers,
    clipData.asset_metadata.font_location,
    clipData.asset_metadata.hide_borders,
    sharedAPIStore.speakerImageMap
  ]);

  const { top, left, waveFormHeight } = staticSizeConfig.getAudiogramWaveformAttributes({
    height: captionContainerSizes.height,
    width: captionContainerSizes.width
  });

  return (
    <Fragment>
      {!isUsingRemotionForSpeakerLabels && (
        <canvas
          id="static-canvas"
          ref={staticCanvasRef}
          className={`absolute top-0 h-full w-full ${
            showSpeakerLabels || clipLayout === 'AUDIOGRAM' ? 'block' : 'hidden'
          }`}
        />
      )}
      {clipLayout === 'AUDIOGRAM' ? (
        <Fragment>
          {!currentSpeaker?.profile_picture_url && (
            <div
              className="z-[1] bg-cover bg-center"
              style={{
                width: audiogramImageWidth * 100 + '%',
                height: audiogramImageHeight * 100 + '%'
              }}
              data-testid="speaker-image"
            >
              <EmptyProfilePicturePlaceHolder speaker={currentSpeaker} />
            </div>
          )}
          <div
            className="absolute"
            style={{
              top,
              left
            }}
          >
            {clipMetadata.magicLayout?.textColor && (
              <Waveform
                textColor={clipMetadata.magicLayout.textColor}
                paused={playerStore.paused}
                height={waveFormHeight}
              />
            )}
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
}
