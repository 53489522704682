import React from 'react';

export default function EventSessionFilterSkeleton(): React.ReactNode {
  return Array.from({ length: 3 }).map((_, i) => {
    return (
      <div className="flex animate-pulse space-x-2 rounded p-1.5 px-3" key={'event-session-filter-skeleton' + i}>
        <input type="checkbox" disabled className="mt-0.5 h-4 w-4 rounded border-slate-100 bg-slate-100" />
        <div className="grow">
          <div className="h-3.5 w-full rounded-sm bg-slate-200"></div>
          <div className="mt-1.5 h-2.5 w-1/4 rounded-sm bg-slate-200"></div>
        </div>
      </div>
    );
  });
}
