export default function BrandKitSkeleton(): JSX.Element {
  return (
    <>
      {Array(4)
        .fill(0)
        .map((_, i) => (
          <div
            className="z-50 flex aspect-[16/11] h-[3.25rem] w-full animate-pulse flex-col items-center justify-center !rounded-md bg-slate-200 py-1 text-slate-500"
            key={i}
          >
            <button className="flex w-full items-center justify-between text-left"></button>
          </div>
        ))}
    </>
  );
}
