import { FontItem } from './types';
import featureFlagStore from '@/stores/featureFlagStore';
import { getEnvConfig } from '@/constants';
import { FeatureFlagKeys } from '@/services/featureFlag';
import { currentUser } from '@/stores/user';

export const FONT_DEFAULT_VALUE = {
  name: 'Inter',
  url: getFontFullPath('Inter-Medium.ttf'),
  scale: 122
};

const FONTS_DEFAULT: FontItem[] = [
  FONT_DEFAULT_VALUE,
  {
    name: 'Playfair Display',
    url: getFontFullPath('PlayfairDisplay-Bold.ttf'),
    scaleInPx: 3,
    scale: 143
  },
  {
    name: 'Montagu Slab',
    url: getFontFullPath('MontaguSlab_24pt-Bold.ttf'),
    scaleInPx: 4,
    scale: 146
  },
  {
    name: 'Bangers',
    url: getFontFullPath('Bangers-Regular.ttf'),
    scaleInPx: 13,
    isOverlayFont: true,
    scale: 178
  },
  {
    name: 'Space Mono',
    url: getFontFullPath('SpaceMono-Bold.ttf'),
    scale: 151,
    isOverlayFont: true
  },
  {
    name: 'Source Code Pro',
    url: getFontFullPath('SourceCodePro-Bold.ttf'),
    scale: 155,
    isOverlayFont: true
  },
  {
    name: 'Rubik',
    url: getFontFullPath('Rubik-Bold.ttf'),
    scale: 156,
    isOverlayFont: true
  },
  {
    name: 'Roboto',
    url: getFontFullPath('Roboto-Medium.ttf'),
    scale: 122,
    isOverlayFont: true
  },
  {
    name: 'Raleway',
    url: getFontFullPath('Raleway-Bold.ttf'),
    scale: 141,
    isOverlayFont: true
  },
  {
    name: 'Poppins',
    url: getFontFullPath('Poppins-Bold.ttf'),
    scale: 180,
    isOverlayFont: true
  },
  {
    name: 'Merriweather',
    url: getFontFullPath('Merriweather-Bold.ttf'),
    scaleInPx: 3,
    scale: 136,
    isOverlayFont: true
  },
  {
    name: 'Nunito',
    url: getFontFullPath('Nunito-Bold.ttf'),
    scaleInPx: 3,
    scale: 139,
    isOverlayFont: true
  },
  {
    name: 'Montserrat',
    url: getFontFullPath('Montserrat-ExtraBold.ttf'),
    scaleInPx: 4,
    scale: 139,
    isOverlayFont: true
  },
  {
    name: 'Alegreya',
    url: getFontFullPath('Alegreya-Bold.ttf'),
    scaleInPx: 6,
    scale: 149,
    isOverlayFont: true
  },
  {
    name: 'Lato',
    url: getFontFullPath('Lato-Bold.ttf'),
    scale: 121,
    isOverlayFont: true
  },
  {
    name: 'Open Sans',
    url: getFontFullPath('OpenSans-Bold.ttf'),
    scaleInPx: 4,
    scale: 146,
    isOverlayFont: true
  },
  {
    name: 'Pacifico',
    url: getFontFullPath('Pacifico-Regular.ttf'),
    scale: 199,
    scaleInPx: 15
  },
  {
    name: 'Patrick Hand',
    url: getFontFullPath('PatrickHand-Regular.ttf'),
    scale: 138,
    scaleInPx: 3
  },
  {
    name: 'Manrope',
    url: getFontFullPath('Manrope-ExtraBold.ttf'),
    scale: 138,
    scaleInPx: 3
  },
  {
    name: 'Oswald',
    url: getFontFullPath('Oswald-Bold.ttf'),
    scale: 173,
    scaleInPx: 9
  },
  {
    name: 'Roboto Mono',
    url: getFontFullPath('RobotoMono-Bold.ttf'),
    scale: 135,
    scaleInPx: 1
  },
  {
    name: 'Monoton',
    url: getFontFullPath('Monoton-Regular.ttf'),
    scale: 159,
    scaleInPx: 7
  },
  {
    name: 'League Script',
    url: getFontFullPath('LeagueScript-Regular.ttf'),
    size: 'xs',
    scale: 113,
    scaleInPx: -1
  }
];

function getFonts() {
  if (!currentUser.getSnapshot()) return FONTS_DEFAULT;
  const featureFlags = featureFlagStore.getSnapshot();
  return featureFlags[FeatureFlagKeys.Use_CL_Captions_Overlay] === false
    ? FONTS_DEFAULT.filter(f => !f.isOverlayFont)
    : FONTS_DEFAULT;
}

export const fontsMap = () => {
  return getFonts().reduce((acc, el) => {
    return {
      ...acc,
      [el.url]: el.name
    };
  }, {});
};

export function getFontByLocation(location?: string): FontItem | undefined {
  if (!location) return;
  return getFonts().find(f => f.url === location);
}

export function getFontByName(name?: string): FontItem | undefined {
  if (!name) return;
  return getFonts().find(f => f.name === name);
}

function getFontFullPath(fontName: string): string {
  return `${getEnvConfig('CLOUDFRONT_STATIC_ASSET_URL')}content-lab/fonts/${fontName}`;
}
