import { getBrandKitUrl } from '../utils';
import IconButton from '@/components/atoms/Button/IconButton';
import { getFileNameFromPath } from '@/libs/file';
import useDialog from '@/components/organisms/useDialog';
import ConfirmationDialog from '@/components/organisms/ConfirmationDialog';

export default function IntroOutroPreview({
  videoUrl,
  onDelete
}: {
  videoUrl: string;
  onDelete: (videoUrl: string) => void;
}) {
  const {
    isOpen: isDeleteConfirmationOpen,
    openDialog: openDeleteConfirmationDialog,
    closeDialog: closeDeleteConfirmationDialog
  } = useDialog();
  return (
    <div className="flex aspect-[16/11] w-56 flex-col items-center justify-center overflow-hidden rounded-lg border border-slate-200">
      <video className="w-full overflow-hidden object-cover" src={getBrandKitUrl(videoUrl)} />
      <div className="flex w-full items-center justify-between space-x-1.5 px-2.5">
        <div className="grow items-center truncate py-3 text-xs">{getFileNameFromPath(videoUrl)}</div>
        <div className="shrink-0">
          <IconButton
            icon="IconTrash"
            size="small"
            variation="text"
            onClick={openDeleteConfirmationDialog}
            trackingId="delete-intro-outro-button"
          />
        </div>
      </div>
      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        onClose={closeDeleteConfirmationDialog}
        onConfirm={() => onDelete(videoUrl)}
        title="Delete Brand Intro/Outro?"
        content="You're about to remove a brand intro/outro you've chosen. Don't worry, this won't change any of the intro/outro's you've already used around the platform."
        confirmLabel="Yes"
      />
    </div>
  );
}
