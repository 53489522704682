import useCaptionsStylesHook from '@/components/molecules/useCaptionsStylesHook';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function AudiogramCaptions({
  width,
  fontFamilyStyle,
  sizeMultiplier,
  textColor,
  fontSizeCorrectionFactor,
  ...rest
}) {
  const { clipData } = useClipsContext();
  const { index, isActive, text } = rest;

  const { activeWordStyles, inactiveWordStyles } = useCaptionsStylesHook(clipData.asset_metadata);

  const wordStyles = isActive ? activeWordStyles : inactiveWordStyles;
  return (
    <div
      data-word-id={index}
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className="word relative z-10 inline"
      style={{
        fontSize: width * fontSizeCorrectionFactor * sizeMultiplier + 'px',
        backgroundColor: wordStyles.backgroundColor,
        color: textColor,
        opacity: wordStyles.opacity,
        ...fontFamilyStyle
      }}
    >
      {text}
    </div>
  );
}
