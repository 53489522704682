import { Downloads } from '@goldcast/api/content';
import { useMemo } from 'react';
import BrandingToggle from '../BrandingToggle';
import { TextAsset } from '@/Pages/PostsListPage/types';
import ApiButton from '@/components/atoms/ApiButton/ApiButton';
import useDownloadAsset from '@/hooks/useDownloadAsset';
import { DOWNLOADING_LABELS } from '@/Pages/Clip/constants';
import { POST_TYPES } from '@/Pages/PostsListPage/constants';

const DOWNLOAD_SUCCESS_STATE_DURATION = 5000;

export default function PostShareDownloadTab({ post }: { post: TextAsset | null }) {
  const { isDownloadProcessed, onDownloadTextAsset, updateDownloadState } = useDownloadAsset({
    type: 'TEXT',
    textAsset: post
  });

  const isFailedDownloadStatus = (downloadsData: Downloads) => {
    return downloadsData.status === 'FAILED';
  };

  const isMultimodal = useMemo(() => {
    const { multi_modal_enabled } = post?.content || {};
    const { type, clip_assets } = post?.asset_metadata || { clip_assets: [] };

    return multi_modal_enabled && (type === POST_TYPES.BLOG || clip_assets?.length > 0);
  }, [post]);

  const showBrandingToggle = useMemo(() => {
    const { type } = post?.asset_metadata || {};

    return type === POST_TYPES.BLOG || type === 'LINKEDIN';
  }, []);

  return (
    <div className="mt-2 p-2">
      <div className="mb-5 grid grid-cols-3 items-center gap-y-2.5 px-1.5 text-sm">
        <div>Format</div>
        <div className="col-span-2">
          <div className="w-full rounded-md py-2 text-right focus:outline-none focus:ring-2 focus:ring-slate-950">
            {isMultimodal ? 'DOC + MP4' : 'DOC'}
          </div>
        </div>
        {showBrandingToggle && <BrandingToggle />}
      </div>
      <ApiButton
        action={onDownloadTextAsset}
        title="Start Download"
        successMessage={!isDownloadProcessed ? 'Processing Started' : 'Success'}
        variation="filled"
        trackingId="download-button"
        buttonClassNames="rounded-lg h-12 text-white mt-3"
        buttonSize="large"
        failMessage="Download failed"
        fullWidth
        isFailedFn={isFailedDownloadStatus}
        inProgressLabels={DOWNLOADING_LABELS}
        onStatusChanged={updateDownloadState}
        completedStateDuration={DOWNLOAD_SUCCESS_STATE_DURATION}
      />
    </div>
  );
}
