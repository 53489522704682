import { IconTypography } from '@tabler/icons-react';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Listbox } from '@headlessui/react';
import { useVideoImportContext } from '../../../VideoImportContext';
import BrandingFontSelectList from './BrandingFontSelectList';
import { FONT_DEFAULT_VALUE, fontsMap } from '@/Pages/Clip/SideBar/FontSelector/constants';
import { classnames } from '@/libs/utils';
import Icon from '@/components/atoms/Icon';
import { BRAND_KIT_ACCEPT_FORMATS } from '@/Pages/BrandKit/constants';
import FilesInput from '@/components/atoms/inputs/FilesInput/FilesInput';
import useBrandKitComponent from '@/Pages/BrandKit/components/useBrandKitComponent';
import { loadFont } from '@/libs/fonts';

const BrandingFontSelect = () => {
  const { setSelectedFontUrl, selectedFontUrl, setNavigationDisabled } = useVideoImportContext();
  const { brandKitStore, onFilesUpload } = useBrandKitComponent('fonts', 'Fonts');

  const [isSelectingFont, setIsSelectingFont] = useState(false);
  const [uploadedFontName, setUploadedFontName] = useState('');

  const toggleIsSelectingFont = useCallback(() => {
    setIsSelectingFont(prev => !prev);
  }, []);

  const predefinedFonts = useMemo(() => {
    return fontsMap();
  }, []);

  const selectedFontName = useMemo(() => {
    return predefinedFonts[selectedFontUrl] || brandKitStore.fontsMap?.[selectedFontUrl] || FONT_DEFAULT_VALUE.name;
  }, [predefinedFonts, selectedFontUrl, brandKitStore.fontsMap]);

  const onFilesUploadOverride = useCallback(
    (files: File[]) => {
      setNavigationDisabled(true);
      onFilesUpload(files)
        .then(() => {
          setUploadedFontName(files[0].name);
        })
        .finally(() => {
          setNavigationDisabled(false);
        });
    },
    [onFilesUpload, setNavigationDisabled]
  );

  useEffect(() => {
    if (selectedFontUrl) {
      setIsSelectingFont(true);
      loadFont(selectedFontUrl, selectedFontName);
    }
  }, [selectedFontUrl, selectedFontName]);

  useEffect(() => {
    if (!uploadedFontName || !brandKitStore.fontsMap) {
      return;
    }

    Object.keys(brandKitStore.fontsMap)?.forEach(fontUrl => {
      if (fontUrl.includes(uploadedFontName)) {
        setSelectedFontUrl(fontUrl);
        loadFont(fontUrl, brandKitStore.fontsMap[fontUrl]);
        setUploadedFontName('');
      }
    });
  }, [uploadedFontName, setSelectedFontUrl, brandKitStore.fontsMap]);

  if (!isSelectingFont) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-4">
        <button
          onClick={toggleIsSelectingFont}
          data-testid="branding-font-select"
          className="flex h-full w-full flex-col items-center justify-center rounded-lg ring-2 ring-slate-200 transition-all duration-150 ease-in-out hover:bg-white focus:ring-2 focus:ring-black"
        >
          <IconTypography size={24} />
          <span className="mt-5 block select-none truncate text-sm font-medium">Brand Font</span>
        </button>
      </div>
    );
  }

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4">
      <Listbox value={selectedFontUrl} onChange={setSelectedFontUrl}>
        {({ open }) => (
          <div className="relative w-full">
            <Listbox.Button
              className={classnames(
                'flex h-full w-full items-center justify-center rounded-lg bg-white font-bold ring-2 ring-slate-200'
              )}
            >
              <div className="flex w-full items-center justify-between p-4 px-7 text-left text-lg focus:outline-none">
                <div
                  className="grow truncate text-left"
                  style={{ fontFamily: selectedFontName }}
                  data-testid="font-selector-button"
                >
                  {selectedFontName}
                </div>
                <Icon name={open ? 'IconChevronUp' : 'IconChevronDown'} className="text-slate-400" size={16} />
              </div>
            </Listbox.Button>
            <Listbox.Options className="absolute z-10 mt-6 max-h-56 w-full overflow-auto rounded-md border border-slate-200 bg-white shadow-lg focus:outline-none">
              <BrandingFontSelectList fontsMap={predefinedFonts} />
              {brandKitStore.fonts.length > 0 && (
                <>
                  <div className="my-0 flex items-center">
                    <div className="h-[1px] w-full bg-slate-200"></div>
                    <div className="flex w-full items-center justify-center space-x-0.5 rounded-full border bg-white px-3 py-1 text-center">
                      <IconTypography size={16} className="mr-1" />
                      <span className="text-xs">Brand Fonts</span>
                    </div>
                    <div className="h-[1px] w-full bg-slate-200"></div>
                  </div>
                  <BrandingFontSelectList fontsMap={brandKitStore.fontsMap} />
                </>
              )}
            </Listbox.Options>
          </div>
        )}
      </Listbox>
      <div className="my-1 flex w-full items-center">
        <div className="h-[1px] grow bg-slate-300" />
        <div className="shrink-0 px-4 text-xs text-slate-500">OR</div>
        <div className="h-[1px] grow bg-slate-300" />
      </div>
      <div className="h-[55%] w-full">
        <FilesInput
          onFilesUpload={onFilesUploadOverride}
          accept={BRAND_KIT_ACCEPT_FORMATS.fonts}
          title="Upload a custom font"
          label="Click to browse, or drag & drop a font file here"
          multiple={false}
          icon="IconCloudUpload"
          rootClassNames="w-full h-full group shrink-0 flex flex-col justify-center items-center ring-2 rounded-lg ring-slate-200 focus:ring-2 focus:ring-primary hover:bg-white transition-all duration-150 ease-in-out"
          containerClassNames="border-none w-full h-full"
          labelClassNames="text-sm"
          iconClassNames="text-black"
          iconProps={{ size: 24, stroke: 2 }}
        />
      </div>
    </div>
  );
};

export default memo(BrandingFontSelect);
