import moment from 'moment';

export function formatTime(secs: number): string {
  let formattedTime = '0:00';

  if (secs <= 0) {
    return formattedTime;
  }

  switch (true) {
    case secs < 3600:
      formattedTime = moment.utc(secs * 1000).format('mm:ss');
      break;
    case secs < 86400:
      formattedTime = moment.utc(secs * 1000).format('HH:mm:ss');
      break;
  }

  return formattedTime;
}

export function formatDuration(secs) {
  return moment.utc(secs * 1000).format('HH:mm:ss');
}

export function downloadURI(uri: string) {
  const link = document.createElement('a');
  link.setAttribute('download', '');
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function formatDate(date?: string): string {
  if (!date) return '';
  const dateObj = moment(date);
  if (dateObj.year() === moment().year()) {
    return dateObj.format('MMM D');
  }
  return moment(date).format('MMM D, YYYY');
}
