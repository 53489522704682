import React, { useCallback, useEffect, useRef } from 'react';
import Hls from 'hls.js';
import { player } from '@/stores/player';
import { showErrorToast } from '@/libs/toast/toast';
import { useAppContext } from '@/context/AppContext/AppContext';

export default function HlsPlayer({
  url,
  trackSrc = '',
  currentTime = 0,
  onCueChange = _ref => {},
  onError = () => {},
  ...rest
}) {
  const { logger } = useAppContext();
  const videoRef = useRef<HTMLVideoElement>(null);

  const cueChange = useCallback(() => {
    onCueChange?.(videoRef.current);
  }, []);

  useEffect(() => {
    let hls: Hls;
    if (Hls.isSupported() && videoRef.current) {
      hls = new Hls();
      hls.loadSource(url);
      hls.attachMedia(videoRef.current);
      hls.on('hlsError' as any, (_, data) => {
        // don't error out for non-fatal error (like segment load)
        // If it's not fatal, hls would retry it
        // Check if manifest - don't block UI if fragment load failed
        if (data?.fatal && data?.details === Hls.ErrorDetails.MANIFEST_LOAD_ERROR) onError();
        else if (data?.fatal && data?.details === Hls.ErrorDetails.FRAG_LOAD_ERROR)
          showErrorToast(`We hit a snag loading part of your video. Let's try refreshing the page to get everything back in sync.
        `);
        logger.warn('HLS Error', JSON.stringify(data));
      });
      hls.subtitleDisplay = false;
      hls.once('hlsMediaAttached' as any, () => {
        videoRef.current!.currentTime = currentTime;
      });
      videoRef.current.textTracks[0].mode = 'hidden';
      videoRef.current.textTracks[0].addEventListener('cuechange', cueChange);

      player.update(data => ({ ...data, video: videoRef.current }));
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, []);

  return (
    <video
      crossOrigin="anonymous"
      {...rest}
      ref={videoRef}
      id="mainVideoPlayer"
      onDurationChange={() => {
        if (videoRef.current) {
          player.update(data => ({ ...data, duration: videoRef.current!.duration }));
        }
      }}
      onTimeUpdate={() => {
        if (videoRef.current) {
          player.update(data => ({ ...data, currentTime: videoRef.current!.currentTime }));
        }
      }}
    >
      <track default={true} kind="metadata" srcLang="en" src={trackSrc} />
    </video>
  );
}
