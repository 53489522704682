import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import type { LayoutType, SizeType } from '@/domains/asset';

export function getTruncatedTitle(speaker: SpeakerWithDetails, layout: LayoutType, size: SizeType): string {
  const limit: number | undefined = TITLE_LIMITS[layout]?.[size];
  const title = getFormattedTitle(speaker);
  return limit ? getTruncatedValue(limit, title) : title;
}

export function getTruncatedName(name: string, layout: LayoutType, size: SizeType): string {
  const limit: number | undefined = NAME_LIMITS[layout]?.[size];
  return limit ? getTruncatedValue(limit, name) : name;
}

export function getSpeakerNameAndTitle(speaker): { name: string; title: string } {
  return {
    title: getFormattedTitle(speaker),
    name: `${speaker.first_name} ${speaker.last_name}`
  };
}

export function getFormattedTitle(speaker: SpeakerWithDetails): string {
  const atContent = !!speaker.title && !!speaker.company ? ' at' : '';
  return `${speaker.title || ''}${atContent} ${speaker.company || ''}`.trim();
}

function getTruncatedValue(limit: number, value: string): string {
  return value && value.length > limit ? `${value.substring(0, limit)}...` : value;
}

type TitleLimits = Record<'AUDIOGRAM', Record<SizeType, number>>;

// TODO @dusangc: We can remove limits after Audiogram is moved to Shared API
export const NAME_LIMITS: TitleLimits = {
  AUDIOGRAM: {
    LANDSCAPE: 25,
    PORTRAIT: 30,
    SQUARE: 50
  }
};

export const TITLE_LIMITS: TitleLimits = {
  AUDIOGRAM: {
    LANDSCAPE: 25,
    PORTRAIT: 30,
    SQUARE: 50
  }
};
