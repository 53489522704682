export const SESSION_PROCESSING_DOWNLOADS_KEY = 'session_processing_downloads';

export function storeDownloadIdForSession(downloadId: string) {
  const sessionProcessingDownloads = JSON.parse(sessionStorage.getItem(SESSION_PROCESSING_DOWNLOADS_KEY) || '[]');
  sessionProcessingDownloads.push(downloadId);
  sessionStorage.setItem(SESSION_PROCESSING_DOWNLOADS_KEY, JSON.stringify(sessionProcessingDownloads));
}

export function shouldAutoDownload(downloadId: string) {
  const sessionProcessingDownloads = JSON.parse(sessionStorage.getItem(SESSION_PROCESSING_DOWNLOADS_KEY) || '[]');
  return sessionProcessingDownloads.includes(downloadId);
}

export function documentTitleNotification() {
  if (!document.hidden) {
    return;
  }

  const title = document.title;
  document.title = `Download started! | ${title}`;
  window.addEventListener('focus', () => {
    document.title = title;
  });
}
