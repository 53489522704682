import { IconLoader2 } from '@tabler/icons-react';
import React, { MouseEvent, useCallback, useRef, useMemo, useSyncExternalStore } from 'react';
import { MediaContent } from '@goldcast/api/content';
import {
  convertBroadcastReason,
  formatToStandardDate,
  getSessionPreviewDetails,
  isSessionReadyForPreview
} from '../utils';
import SessionThumbnail from '../uiComponents/SessionThumbnail';
import SessionMenu from '../RecentEvents/SessionMenu';
import Icon from '@/components/atoms/Icon';
import { ApplicationMode, getEnvConfig } from '@/constants';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';

export default function SessionTable({
  session,
  onDeleteSession
}: {
  session: MediaContent;
  onDeleteSession: () => void;
}) {
  const tooltipRef = useRef<HTMLDivElement>(null);

  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const isEditFullRecordingEnabled = featureFlags[FeatureFlagKeys.Use_CL_Edit_Full_Recording];

  const isProcessed = useMemo(() => {
    return isSessionReadyForPreview(session);
  }, [session]);

  const { isContentUploadInProgress, contentStatusText } = useMemo(() => {
    return getSessionPreviewDetails(session);
  }, [session]);

  const onEventClick = useCallback((event, broadcast) => {
    if (!isProcessed) return;

    window.location.href = [
      ...(getEnvConfig('MODE') === ApplicationMode.Production ? [window.location.origin] : []),
      'content',
      event,
      broadcast,
      isEditFullRecordingEnabled ? 'recordings' : 'clips'
    ].join('/');
  }, []);

  const onMouseMove = (e: MouseEvent) => {
    const sessionsModal = document.querySelector('[data-testid="content-dialog-panel"]');

    const parentContainer = sessionsModal?.getBoundingClientRect();

    if (!tooltipRef.current || !parentContainer || !session.reason) return;

    tooltipRef.current.style.top = e.clientY - parentContainer.top + 'px';
    tooltipRef.current.style.left = e.clientX - parentContainer.left + 'px';
  };

  return (
    <div
      className={`group flex w-full items-center space-x-4 rounded-lg p-2 hover:bg-slate-100/5 ${
        isProcessed || isContentUploadInProgress ? 'cursor-pointer' : 'cursor-not-allowed'
      }`}
      data-testid="session-container"
      onMouseMove={onMouseMove}
      onClick={() => onEventClick(session.project.id, session.id)}
    >
      {isProcessed ? (
        <div
          className="flex aspect-video w-32 shrink-0 items-center justify-center overflow-hidden rounded-lg bg-slate-100 bg-cover bg-center"
          style={{
            backgroundColor: session.project.theme_obj?.event_background
          }}
        >
          <SessionThumbnail iconSize={18} session={session} />
        </div>
      ) : (
        <div className="mb-1.5 flex aspect-video w-32 shrink-0 items-center justify-center rounded-lg border border-slate-200 bg-slate-100">
          <div className="flex flex-col items-center text-slate-500">
            {session.batch_status === 'FAILED' ? (
              <div className="flex h-7 w-5 items-center justify-center">
                <Icon
                  name={session.reason === 'LANGUAGE_NOT_SUPPORTED' ? 'IconLanguageOff' : 'IconSquareRoundedX'}
                  size={20}
                  stroke={1.5}
                />
              </div>
            ) : (
              <div className="relative">
                <IconLoader2 size={18} className="animate-spin" />
              </div>
            )}
            <div className="mt-1 text-center text-xs leading-tight">{contentStatusText}</div>
          </div>
        </div>
      )}
      <div className="grow truncate leading-tight">
        <div className="truncate tracking-tight">{session.title}</div>
        <div className="text-xs text-slate-500">{formatToStandardDate(session.end_time)}</div>
      </div>
      <SessionMenu session={session} onDelete={onDeleteSession} />
      {session.reason && (
        <div
          className="z-40 hidden group-hover:fixed group-hover:inline-block group-hover:rounded group-hover:bg-black group-hover:p-1 group-hover:text-2xs group-hover:leading-tight group-hover:text-white"
          ref={tooltipRef}
        >
          {convertBroadcastReason(session.reason)}
        </div>
      )}
    </div>
  );
}
