import { CSSProperties } from 'react';
import { ClipMetadata } from '@/domains/asset';
import { AdjustedBoxBoundary, FacePositions } from '@/stores/speakersAnalysis/speakersAnalysisTypes';

export interface DrawPositions {
  source_x: number;
  source_y: number;
  source_width: number;
  source_height: number;
  target_x: number;
  target_y: number;
  target_width: number;
  target_height: number;
}

export interface DrawVideoOptions {
  videoWidth: number;
  videoHeight: number;
  showCaption: boolean;
  source_crop_x_offset?: number;
  source_crop_y_offset?: number;
  currentVideoNumber?: number;
  totalVideos?: number;
  centreCropOnly?: boolean;
  clipMetadata?: ClipMetadata;
  optionsForNewSpeakerMapping?: {
    originalVideoWidth: number;
    originalVideoHeight: number;
    allPositions: Omit<FacePositions, 'face_id'>;
  };
  shouldUseDefaultLayoutValues?: boolean;
  adjustedBoxBoundary?: AdjustedBoxBoundary;
}

export interface TextWidthCalculationProps {
  maxWidth: number;
  name: string;
  nameFont: string;
  title: string;
  titleFont: string;
  canvasWidth: number;
  canvasHeight: number;
}

export interface TextWidthCalculationResult {
  width: number;
  nameHeight?: number;
  titleHeight?: number;
  newTitle: string;
  newName: string;
  isTruncated: boolean;
}

export interface PillBoxSizes {
  pillWidth: number;
  pillHeight: number;
  pillX: number;
}

export interface CaptionAttributes {
  top: number;
  left: number;
  right: number;
  fontSize: string;
  lineHeight: string;
  rotation: number;
  wrap_style: 0 | 1 | 2 | 3;
  alignment: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
}

export interface CropPositions {
  source_crop_x: number;
  source_crop_y: number;
  source_crop_width: number;
  source_crop_height: number;
  target_crop_x: number;
  target_crop_y: number;
  target_crop_width: number;
  target_crop_height: number;
}

/**
 * Represents which attribute is to be occupied by the video bounding box,
 * width or height, when being placed inside a container on the canvas.
 *
 * percentageOfMainDimensionToOccupy is the percent of attribute to occupy
 */
export enum OCCUPANCY_VARIATION {
  'OCCUPY_WIDTH',
  'OCCUPY_HEIGHT'
}

export interface SpeakerNameTitleParams {
  fontFamily?: string;
  totalVideos: number;
  currentVideoNumber: number;
}

export interface SpeakerNameTitleStyles {
  container: CSSProperties;
  name: CSSProperties;
  title: CSSProperties;
}
