import Icon from '@/components/atoms/Icon';
import useFilesDragNDrop from '@/components/atoms/inputs/FilesInput/useFilesDragNDrop';

export default function UploadFileButton({ openSessionsDialog }: { openSessionsDialog: () => void }) {
  function onAddNewRecording() {
    openSessionsDialog();
  }

  function onFilesUpload(files: File[]) {
    if (files.length) {
      window.openFilePickerOnDialogLoad = true;
      window.filesPickedForUpload = files;
      onAddNewRecording();
    }
  }

  const { isDragging, uploadFiles, handleDragStart, handleDragStop } = useFilesDragNDrop({
    onFilesUpload
  });

  return (
    <div
      className="group relative aspect-[4/3] w-[23rem] shrink-0 truncate"
      onClick={onAddNewRecording}
      onDrop={uploadFiles}
      onDragOver={handleDragStart}
      onDragStart={handleDragStart}
      onDragLeave={handleDragStop}
      data-testid="import-file-button"
    >
      <div className="group relative flex h-full cursor-pointer items-center justify-center overflow-hidden !rounded-xl border !border-slate-200/80 bg-slate-200/50 transition-colors duration-150 ease-in-out hover:bg-slate-200">
        <div className="relative z-20 flex flex-col items-center">
          <Icon
            name="IconCircleXFilled"
            className="rotate-45 text-slate-400 transition-colors duration-150 ease-in-out group-hover:text-slate-700"
            size={38}
          />
          <div className="mt-1.5 text-sm text-slate-500 transition-colors duration-150 ease-in-out group-hover:text-slate-700">
            Import File
          </div>
        </div>
        {isDragging && (
          <div className="absolute z-30 flex h-full w-full items-center justify-center !rounded-xl bg-deep-orange">
            <span className="text-xl font-medium">Drop it like it‘s hot</span>
          </div>
        )}
      </div>
    </div>
  );
}
