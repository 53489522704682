import isequal from 'lodash.isequal';
import { currentClip, updateClipInList, updateClipWithId } from '../clip';
import createStore from '../store';
import { defaultSharedAPIStore } from './constants';
import { speakersAnalysisStore } from '../speakersAnalysis/speakersAnalysis';
import featureFlagStore from '../featureFlagStore';
import { Clip, ClipMetadata } from '@/domains/asset';
import { getDefaultLayoutCanvasData } from '@/libs/sharedAPI/default';
import { getActiveSpeakerLayoutCanvasData } from '@/libs/sharedAPI/activeSpeaker';
import { updateClip } from '@/libs/clipContentUtil';
import { TranscriptContextData } from '@/context/TranscriptContext/TranscriptContextTypes';
import { SpeakerSegmentContextType } from '@/context/SpeakerSegmentContext/SpeakerSegmentContextTypes';
import { getSpeakerGridLayoutCanvasData } from '@/libs/sharedAPI/speakerGrid';
import { getAudiogramLayoutCanvasData } from '@/libs/sharedAPI/audiogram';
import { isEmptyObject } from '@/libs/utils';
import { getRemotionConfig } from '@/App/remotion/libs';
import { FeatureFlagKeys } from '@/services/featureFlag';

export interface SharedAPIStore {
  transcriptStore: TranscriptContextData;
  speakerSegmentStore: SpeakerSegmentContextType;
  mainPlayerRef: HTMLVideoElement | null;
  introPlayer: HTMLVideoElement | null;
  outroPlayer: HTMLVideoElement | null;
  speakerMap: Record<
    string,
    {
      videoRef: HTMLVideoElement;
    }
  >;
  speakerImageMap: Record<
    string,
    {
      imageRef: HTMLImageElement;
    }
  >;
}

export const sharedAPI = createStore<Record<string, SharedAPIStore>>({});

export function initSharedAPI(clipId: string) {
  sharedAPI.update(data => ({ ...data, [clipId]: defaultSharedAPIStore }));
}

export function updateSharedAPIWithId(clipId: string, data: Partial<SharedAPIStore>) {
  sharedAPI.update(store => ({ ...store, [clipId]: { ...store[clipId], ...data } }));
}

export function updateSpeakerImageMap(clipId: string, speakerKey: string, imageRef: HTMLImageElement) {
  sharedAPI.update(data => ({
    ...data,
    [clipId]: {
      ...data[clipId],
      speakerImageMap: {
        ...data[clipId].speakerImageMap,
        [speakerKey]: {
          ...data[clipId].speakerImageMap[speakerKey],
          imageRef
        }
      }
    }
  }));
}

export function updateSpeakerVideoMap(clipId: string, speakerKey: string, videoRef: HTMLVideoElement) {
  sharedAPI.update(data => ({
    ...data,
    [clipId]: {
      ...data[clipId],
      speakerMap: {
        ...data[clipId].speakerMap,
        [speakerKey]: {
          ...data[clipId].speakerMap[speakerKey],
          videoRef
        }
      }
    }
  }));
}

export function updateClipConfig(clipId, clip?: Clip) {
  if (!clip) {
    clip = currentClip.getSnapshot()[clipId];
  }
  let finalClip = { ...clip };

  const clipLayout = finalClip.asset_metadata.layout;

  const isSpeakerLayout = clipLayout === 'SPEAKER' || clipLayout === 'GRID';
  const speakersAnalysis = speakersAnalysisStore.getSnapshot()[clipId];

  const isAnalysisAccurate = isSpeakerLayout ? !!speakersAnalysis.accurate : undefined;
  const isAutoActiveMappingOverridden = isSpeakerLayout
    ? !isequal(speakersAnalysis.speaker_mapping, speakersAnalysis.active_speaker_mapping)
    : undefined;
  const isAutoActiveSpeakerIdentified = isSpeakerLayout
    ? !!speakersAnalysis.active_speaker_mapping && !isEmptyObject(speakersAnalysis.active_speaker_mapping)
    : undefined;

  try {
    let canvasJSON: ClipMetadata['config'] = null;
    const sharedAPIStoreForClip = sharedAPI.getSnapshot()[clipId];
    const commonParams = { clip: finalClip, sharedAPIStoreForClip };

    // TODO: @AshwinBhatkal Remove this with removal of Use_CL_Facial_Rec_Increment_AB

    if (clipLayout === 'DEFAULT') {
      canvasJSON = getDefaultLayoutCanvasData(commonParams);
    } else if (clipLayout === 'SPEAKER') {
      canvasJSON = { ...getActiveSpeakerLayoutCanvasData(commonParams), isAnalysisAccurate };
    } else if (clipLayout === 'GRID') {
      canvasJSON = { ...getSpeakerGridLayoutCanvasData(commonParams), isAnalysisAccurate };
    } else if (clipLayout === 'AUDIOGRAM') {
      canvasJSON = getAudiogramLayoutCanvasData(commonParams);
    }

    // This update should contain clip param and not the current state of currentClip
    finalClip = {
      ...finalClip,
      asset_metadata: {
        ...finalClip.asset_metadata,
        isAutoActiveMappingOverridden,
        isAutoActiveSpeakerIdentified,
        isAnalysisAccurate,
        config: canvasJSON
      }
    };
  } catch (e) {
    window.logger?.error('Error while updating clip config', e);
  }

  const useClipPlayerV2 = featureFlagStore.getSnapshot()[FeatureFlagKeys.Use_CL_Clip_Player_V2];
  const useSpeakerLabelsForRecordings =
    featureFlagStore.getSnapshot()[FeatureFlagKeys.Use_CL_Speaker_Labels_Recordings];

  const isGoldcastRecording = finalClip?.content?.media_source_type === 'RECORDING';
  const isUpload = finalClip?.content?.media_source_type === 'UPLOAD';

  if ((isUpload && useClipPlayerV2) || (isGoldcastRecording && useSpeakerLabelsForRecordings)) {
    try {
      const remotionConfig = getRemotionConfig(clipId);

      finalClip = {
        ...finalClip,
        asset_metadata: {
          ...finalClip.asset_metadata,
          remotionConfig
        }
      };
    } catch (e) {
      window.logger?.error('Error while updating elements timeline', e);
    }
  }

  updateClipWithId(clipId, finalClip, true);
  updateClipInList(finalClip);

  return updateClip(currentClip.getSnapshot()[clipId]);
}
