import { useEffect, useMemo, useSyncExternalStore } from 'react';
import { Transition } from '@headlessui/react';
import FaceSpeakerToggler from './FaceToggler';
import { useActiveSpeakerMatchCondition } from '../../useActiveSpeakerMatchCondition';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import Toggle from '@/components/atoms/Toggle';
import { classnames } from '@/libs/utils';
import { toggleSpeakerLabels } from '@/stores/clip';
import { core } from '@/stores/core';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import MatchSpeakersCTA from '@/Pages/Clip/SideBar/MatchSpeakersCTA';
import { FeatureFlagKeys } from '@/services/featureFlag';
import featureFlagStore from '@/stores/featureFlagStore';

export default function SpeakersConfiguration() {
  const { clipId, clipData, speakersAnalysis, layoutStatus } = useClipsContext();

  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);

  function triggerSpeakerIdentification(params = {}) {
    EventBus.dispatch(CustomEvents.OpenSpeakersIdentification, params);
  }

  const transcriptStore = useTranscriptContext();

  const areAllUnidentified = useMemo(() => {
    const speakerMappingFaceIds = Object.values(speakersAnalysis.speaker_mapping);
    const isActiveSpeakerOrGrid = ['SPEAKER', 'GRID'].includes(clipData.asset_metadata.layout);

    return (
      (coreStore.content?.media_source_type === 'UPLOAD' ? !speakersAnalysis.loading : true) &&
      isActiveSpeakerOrGrid &&
      (!speakerMappingFaceIds.length ||
        transcriptStore.usedSpeakers.every(speaker => {
          return !speakersAnalysis.speaker_mapping[speaker.id]?.length;
        }))
    );
  }, [
    coreStore.content?.media_source_type,
    speakersAnalysis.loading,
    speakersAnalysis.speaker_mapping,
    clipData.asset_metadata.layout,
    transcriptStore.usedSpeakers
  ]);
  useEffect(() => {
    if (coreStore.content?.media_source_type === 'UPLOAD' && areAllUnidentified) {
      toggleSpeakerLabels(clipId, false, false);
    }
  }, [areAllUnidentified, coreStore.content?.media_source_type]);

  function showSpeakerLabels() {
    toggleSpeakerLabels(clipId, true);
    triggerClipReload();
  }

  function triggerClipReload() {
    // Reload if UNAVAILABLE to get fresh status
    if (clipData.layout_status.GRID.status === 'UNAVAILABLE' || clipData.layout_status.SPEAKER.status === 'UNAVAILABLE')
      EventBus.dispatch(CustomEvents.ReloadClip, {});
  }

  const { isNoFaceAtEveryAnalysisPoint, shouldBehaveLikeActiveSpeaker, containsUnidentifiedSpeakers } =
    useActiveSpeakerMatchCondition();

  function onSpeakerLabelsToggle(value: boolean) {
    const isUpload = coreStore.content?.media_source_type === 'UPLOAD';
    const shouldTriggerSpeakerIdentification =
      value && isUpload && (areAllUnidentified || (shouldBehaveLikeActiveSpeaker && containsUnidentifiedSpeakers));

    if (shouldTriggerSpeakerIdentification) {
      triggerSpeakerIdentification({
        postIdentifyCallback: showSpeakerLabels,
        isInlineIdentification: true,
        layout: shouldBehaveLikeActiveSpeaker ? 'SPEAKER' : clipData.asset_metadata.layout
      });
    } else {
      toggleSpeakerLabels(clipId, value);
    }
  }

  const shouldShowSpeakers = useMemo(() => {
    const isSpeakerLayout = clipData.asset_metadata.layout === 'SPEAKER' || clipData.asset_metadata.layout === 'GRID';
    const isCustomUpload = coreStore.content?.media_source_type === 'UPLOAD';
    const isFacialRecABIncrement = featureFlagStore.getSnapshot()[FeatureFlagKeys.Use_CL_Facial_Rec_Increment_AB];

    if (!isSpeakerLayout) return false;

    if (!isFacialRecABIncrement) return true;

    if (isCustomUpload) {
      return layoutStatus === 'DONE' && !isNoFaceAtEveryAnalysisPoint;
    }

    return layoutStatus === 'DONE';
  }, [clipData.asset_metadata.layout, layoutStatus, isNoFaceAtEveryAnalysisPoint]);

  return (
    <Transition
      show={shouldShowSpeakers}
      enter="transition transform duration-300 ease-out"
      enterFrom="-translate-y-full opacity-0"
      enterTo="translate-y-0 opacity-100"
      leave="transition transform duration-300 ease-in"
      leaveFrom="translate-y-0 opacity-100"
      leaveTo="-translate-y-full opacity-0"
    >
      <div className="border-y py-3">
        <div className="flex flex-col px-1">
          {coreStore.content?.media_source_type === 'UPLOAD' && (
            <div className="mx-2">
              <MatchSpeakersCTA
                onClick={() =>
                  triggerSpeakerIdentification({
                    postIdentifyCallback: triggerClipReload,
                    isInlineIdentification: true,
                    layout: shouldBehaveLikeActiveSpeaker ? 'SPEAKER' : clipData.asset_metadata.layout
                  })
                }
              />
            </div>
          )}
          {clipData.asset_metadata.layout === 'GRID' && <FaceSpeakerToggler />}
          <div
            className={classnames('mt-1 flex justify-between rounded-md px-4 py-3.5', {
              'mt-4': clipData.asset_metadata.layout === 'GRID'
            })}
          >
            <div className="text-sm">Show Speaker Labels</div>
            <div>
              <Toggle on={!!clipData.asset_metadata.magicLayout?.showSpeakerLabels} onToggle={onSpeakerLabelsToggle} />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}
