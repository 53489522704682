import { useSyncExternalStore } from 'react';
import { assetsMediaContentRetrieve, ContentUpload, MediaContent } from '@goldcast/api/content';
import {
  contentUploadState,
  ContentUploadState,
  ContentUploadListItem,
  ContentUploadError
} from '@/stores/contentUploadStore';
import { ContentStatesEnum, ContentError } from '@/Pages/GenerateContent/constants';
import { PUSHER_STATE_MESSAGES, PUSHER_STATES } from '@/context/PusherContext/PusherContextConstants';
import { getContentUploadedState } from '@/Pages/GenerateContent/utils';
import { showErrorToast } from '@/libs/toast/toast';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';

export default function useContentUploadHook() {
  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const ContentUploadStore = useSyncExternalStore(contentUploadState.subscribe, contentUploadState.getSnapshot);

  const setUploadContent = (contentDetails: ContentUploadListItem) => {
    contentUploadState.update((state: ContentUploadState) => {
      contentDetails.totalPercentageUploaded = 0;
      state.contentUploadList[contentDetails.contentId] = {
        ...state.contentUploadList[contentDetails.contentId],
        ...contentDetails
      };
    });
  };

  const initUploadedContent = ({
    mediaContent,
    uploadData: content
  }: {
    mediaContent: MediaContent;
    uploadData: ContentUpload;
  }) => {
    const { contentState, error } = getContentUploadedState(mediaContent);

    const contentDetails: ContentUploadListItem = {
      projectId: content.project_id ?? '',
      contentId: content.id ?? '',
      contentUrl: content?.import_url,
      title: content.title ?? '',
      av_type: content.av_type as string,
      contentState: contentState,
      error: error,
      videoProcessingTime: mediaContent.video_processing_estimated_time,
      contentUploadedTime: mediaContent.end_time,
      clipGenerationEstimatedTime: mediaContent.clip_generation_estimated_time
    };
    setUploadContent(contentDetails);
    return contentDetails;
  };

  const handleImportUrlContent = (contentDetails: ContentUploadListItem) => {
    setUploadContent(contentDetails);
    getUploadedMediaContent(contentDetails.contentId);
  };

  const getContentUploadById = (contentId: string | undefined) => {
    const contentUploaded = contentId && ContentUploadStore.contentUploadList[contentId];
    if (contentUploaded) {
      return ContentUploadStore.contentUploadList[contentId];
    }
    return {
      projectId: '',
      contentId: '',
      contentState: ContentStatesEnum.Uploading,
      file: undefined,
      av_type: '',
      title: '',
      totalPercentageUploaded: 0
    };
  };

  const getUploadedMediaContent = async (contentId: string) => {
    const mediaContent = await assetsMediaContentRetrieve({ id: contentId });
    const contentUploaded = ContentUploadStore.contentUploadList[contentId];

    contentUploadState.update((state: ContentUploadState) => {
      state.contentUploadList[contentId].contentUploadedTime = mediaContent.end_time;
      state.contentUploadList[contentId].videoProcessingTime = mediaContent.video_processing_estimated_time;
      state.contentUploadList[contentId].clipGenerationEstimatedTime = mediaContent.clip_generation_estimated_time;
    });
    return contentUploaded;
  };

  const updateContentState = (newState: ContentStatesEnum, contentId: string) => {
    contentUploadState.update((state: ContentUploadState) => {
      state.contentUploadList[contentId].contentState = newState;
    });
  };

  const updateTotalPercentageUploaded = (totalPercentageUploaded: number, contentId: string) => {
    contentUploadState.update((state: ContentUploadState) => {
      state.contentUploadList[contentId].totalPercentageUploaded = totalPercentageUploaded;
    });
  };

  const handleContentUploadError = (error: ContentUploadError, contentId: string) => {
    contentUploadState.update((state: ContentUploadState) => {
      state.contentUploadList[contentId].error = error;
    });
  };

  const handleConfettiState = ({ showConfetti, contentId }) => {
    contentUploadState.update((state: ContentUploadState) => {
      state.contentUploadList[contentId].showConfetti = showConfetti;
    });
  };

  const removeContentUploadEntry = (contentId: string) => {
    contentUploadState.update((state: ContentUploadState) => {
      delete state.contentUploadList[contentId];
    });
  };

  const changeContentUploadStatus = (data, type: ContentStatesEnum, contentId: string) => {
    if (data.upload_id !== contentId) {
      return;
    }

    const generationDoneState =
      data.media_type === 'AUDIO'
        ? PUSHER_STATES.CLIP_GENERATION_DONE
        : featureFlags[FeatureFlagKeys.Use_CL_FTUX_Clip_Templates]
        ? PUSHER_STATES.FTUX_CLIPS_FACIAL_RECOGNITION_DONE
        : PUSHER_STATES.CLIP_GENERATION_DONE;

    if (data.state === generationDoneState || data.state === PUSHER_STATES.TRANSCRIPTION_DONE) {
      let currentState = ContentStatesEnum.GeneratingCompleted;
      if (type === ContentStatesEnum.Processing) {
        currentState = ContentStatesEnum.Generating;
      } else if (type === ContentStatesEnum.Generating) {
        currentState = ContentStatesEnum.GeneratingCompleted;
      } else if (type === ContentStatesEnum.GeneratingCompleted) {
        currentState = ContentStatesEnum.ContentReadyForPreview;
      }

      updateContentState(currentState, contentId);
      return;
    }

    if (data.state === PUSHER_STATES.CLIP_GENERATION_LIMIT_REACHED) {
      showErrorToast(PUSHER_STATE_MESSAGES[data.state]);
      updateContentState(ContentStatesEnum.ContentReadyForPreview, contentId);
    }

    const errorType =
      type === ContentStatesEnum.Processing ? ContentError.PROCESSING_FAILED : ContentError.GENERATING_FAILED;
    handleContentUploadError(
      {
        type: errorType,
        message: PUSHER_STATE_MESSAGES[data.state]
      },
      contentId
    );
  };

  return {
    updateContentState,
    setUploadContent,
    getContentUploadById,
    updateTotalPercentageUploaded,
    initUploadedContent,
    handleContentUploadError,
    removeContentUploadEntry,
    changeContentUploadStatus,
    getUploadedMediaContent,
    handleImportUrlContent,
    handleConfettiState,
    contentUploadList: ContentUploadStore.contentUploadList
  };
}
