import React, { memo } from 'react';
import { Tooltip, ITooltip } from 'react-tooltip';
import { ExternalTooltipV2Props } from './atoms/TooltipV2/TooltipV2Types';
import { classnames } from '@/libs/utils';

const TooltipV2 = ({
  tooltipElement,
  tooltipContent,
  tooltipId,
  size = 'xxsmall',
  ...tooltipProps
}: ITooltip &
  ExternalTooltipV2Props & {
    tooltipElement: React.ReactNode;
    size?: 'xxsmall' | 'xsmall';
  }) => {
  return (
    <div style={{ display: 'inline-block' }}>
      <div data-tooltip-id={tooltipId}>{tooltipElement}</div>

      <Tooltip
        id={tooltipId}
        {...tooltipProps}
        className={classnames(
          'max-w-[250px] bg-black px-1.5 py-1 leading-tight text-white',
          size === 'xxsmall' ? 'text-2xs' : 'text-xs'
        )}
      >
        {tooltipContent}
      </Tooltip>
    </div>
  );
};

export default memo(TooltipV2);
