import { useState, memo, useCallback } from 'react';
import { ToneRuleListProps } from './types';
import IconButton from '@/components/atoms/Button/IconButton';

const toneRuleConfig = {
  traits: {
    placeholder: 'Try "Casual", "Funny", "Supportive" …'
  },
  writingRules: {
    placeholder: 'Try "Use active voice", "Write in second person" …'
  }
};

const ToneRuleList = ({ category, items, onAddItem, onRemoveItem, onUpdateItem }: ToneRuleListProps): JSX.Element => {
  const [newItemText, setNewItemText] = useState<string>('');

  const handleAddItem = useCallback(() => {
    if (newItemText.trim() !== '') {
      onAddItem(newItemText, category);
      setNewItemText('');
    }
  }, [newItemText, category, onAddItem]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleAddItem();
      }
    },
    [handleAddItem]
  );

  return (
    <>
      <div className="mb-2 mt-4 flex items-center space-x-2">
        <input
          type="text"
          value={newItemText}
          onKeyDown={handleKeyDown}
          onChange={e => setNewItemText(e.target.value)}
          className="grow rounded-md border p-2 text-sm focus:border-black focus:ring-black"
          placeholder={toneRuleConfig[category].placeholder}
        />
        <IconButton
          buttonClassName="px-6 py-2 h-10"
          icon="IconPlus"
          onClick={handleAddItem}
          trackingId={`add-new-${category}-click`}
        />
      </div>

      {items.map((item, index) => (
        <div key={index} className="relative mb-2 flex items-center">
          <input
            type="text"
            value={item}
            onChange={e => onUpdateItem(index, e.target.value, category)}
            className="grow rounded-md border p-2 pr-8 text-sm focus:border-black focus:ring-black"
          />
          <IconButton
            buttonClassName="!border-0 h-8 px-3 py-2 text-xs rounded-lg absolute right-1"
            icon="IconTrash"
            onClick={() => onRemoveItem(index, category)}
            trackingId={`delete-${category}-click`}
          />
        </div>
      ))}
    </>
  );
};

export default memo(ToneRuleList);
