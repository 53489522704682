import { IconCategory2 } from '@tabler/icons-react';
import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { HeadingMap, NavigationLabelMap } from './constants';

function GenerateContentEmptyState() {
  const location = useLocation();
  const pathName = location.pathname.split('/')[3];
  const navigationLabel = NavigationLabelMap[pathName] || 'posts';
  const heading = HeadingMap[pathName] || 'Posts';

  return (
    <div className="absolute flex h-full w-[32rem] overflow-visible py-2 transition-all duration-[400ms] ease-in-out">
      <div className="absolute bottom-0 h-[69vh] w-full bg-gradient-to-t from-slate-950/[0.13] to-slate-950/0 blur"></div>
      <div className="z-20 flex w-full shrink-0 flex-col items-center justify-center overflow-hidden rounded-2xl border !border-slate-100 bg-white shadow-xl shadow-slate-200/10 transition-all duration-[400ms]">
        <div className="absolute top-2 z-20 flex h-14 w-full items-center justify-between rounded-t-xl bg-white/90 p-4 px-5 backdrop-blur transition-all duration-150 ease-in-out">
          <div className="flex items-center space-x-2">
            <span className="text-lg font-medium">{heading}</span>
          </div>
        </div>
        <div className="flex w-48 flex-col items-center justify-center">
          <IconCategory2 className="h-8 w-8 text-slate-300" />
          <div>
            <div className="mt-1 text-center text-slate-400">Almost there!</div>
            <div className="mt-2.5 text-center text-sm leading-tight text-slate-400">
              Your {navigationLabel} will be ready in a few moments.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(GenerateContentEmptyState);
