import { useEffect, useMemo, useRef } from 'react';
import { throttle } from '@/libs/utils';

const useThrottle = (callback: (...args: any[]) => void, limit = 300) => {
  const ref = useRef<any>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const throttledCallback = useMemo(() => {
    const func = (...params) => {
      ref.current?.(...params);
    };

    return throttle(func, limit);
  }, [limit]);

  return throttledCallback;
};

export default useThrottle;
