import { DRAG_HANDLE_WIDTH } from '../../ClipTimeline/DragHandle/DragHandleUtils';
import { Clip, ClipDeletes } from '@/domains/asset';

export function getNewStartTimePosition(framesRect: DOMRect, currentEndDragHandleX: number, e: MouseEvent): number {
  const maxLeft = framesRect.width - currentEndDragHandleX - DRAG_HANDLE_WIDTH * 2;
  let newPosition = e.clientX - framesRect.left;
  if (newPosition <= 0) newPosition = 0;
  if (newPosition >= maxLeft) newPosition = maxLeft;
  return newPosition;
}

export function getNewEndTimePosition(framesRect: DOMRect, currentStartDragHandleX: number, e: MouseEvent): number {
  const maxRight = framesRect.width - currentStartDragHandleX - DRAG_HANDLE_WIDTH * 2;
  let newPosition = framesRect.right - e.clientX;
  if (newPosition <= 0) newPosition = 0;
  if (newPosition >= maxRight) {
    newPosition = maxRight;
  }
  return newPosition;
}

/**
 * Creates a new set of "deletes" for the clip metadata when trimming the clip's start.
 *
 * It removes existing deletes that start before the new start time and adds a new delete
 * segment from the beginning of the clip (0) to the new start time.
 *
 * @param {number} newStartTime - The updated start time for the clip trim.
 * @param {Clip} clipData - The clip data object containing metadata, including current delete ranges.
 * @returns {ClipDeletes | null} - The updated `deletes` object, or `null` if no changes are required.
 *
 * @remarks
 * - If the `newStartTime` is `0` and the clip's current start time is already `0`, the function returns `null`.
 * - Deletes with a start time greater than the `newStartTime` are preserved.
 * - A new delete is added from `0` to the `newStartTime`.
 *
 * @example
 * // Example input:
 * const clipData = {
 *   asset_metadata: {
 *     start: 10,
 *     deletes: {
 *       20: { bounds: [20, 30], next_start_time: 30, prev_end_time: 20 },
 *       40: { bounds: [40, 50], next_start_time: 50, prev_end_time: 40 },
 *     }
 *   }
 * };
 * const newStartTime = 15;
 *
 * const result = getNewTrimFromStartDeletes(newStartTime, clipData);
 *
 * // Example output:
 * // {
 * //   0: { bounds: [0, 15], next_start_time: 15, prev_end_time: 0 },
 * //   20: { bounds: [20, 30], next_start_time: 30, prev_end_time: 20 },
 * //   40: { bounds: [40, 50], next_start_time: 50, prev_end_time: 40 }
 * // }
 */
export function getNewTrimFromStartDeletes(newStartTime: number, clipData: Clip): ClipDeletes | null {
  if (newStartTime === 0 && clipData.asset_metadata.start === 0) return null;
  const keepingDeletes = Object.values(clipData.asset_metadata.deletes || {}).reduce((acc, el) => {
    const deleteStart = el.bounds[0];
    if (deleteStart > newStartTime) {
      acc[deleteStart] = el;
    }
    return acc;
  }, {});
  return {
    0: {
      bounds: [0, newStartTime] as [number, number],
      next_start_time: newStartTime,
      prev_end_time: 0
    },
    ...keepingDeletes
  };
}

/**
 * Creates a new set of "deletes" for the clip metadata when trimming the clip's end.
 *
 * It keeps existing deletes that occur before the new end time and appends a new
 * delete segment from the new end time to the full recording's duration.
 *
 * @param {number} newEndTime - The updated end time for the clip trim.
 * @param {Clip} clipData - The clip data object containing metadata, including current delete ranges.
 * @param {number} fullRecordingDuration - The total duration of the full recording.
 * @returns {ClipDeletes | null} - The updated `deletes` object, or `null` if no changes are necessary.
 *
 * @remarks
 * - If the `newEndTime` equals the `fullRecordingDuration` and the clip's current end time already matches,
 *   the function returns `null` as no modifications are needed.
 * - Deletes with an end time earlier than the `newEndTime` are preserved.
 * - A new delete is added from the `newEndTime` to the `fullRecordingDuration`.
 *
 * @example
 * // Example input:
 * const clipData = {
 *   asset_metadata: {
 *     end: 120,
 *     deletes: {
 *       10: { bounds: [10, 20], next_start_time: 20, prev_end_time: 10 },
 *       40: { bounds: [40, 80], next_start_time: 80, prev_end_time: 40 },
 *     }
 *   }
 * };
 * const newEndTime = 90;
 * const fullRecordingDuration = 120;
 *
 * const result = getNewTrimFromEndDeletes(newEndTime, clipData, fullRecordingDuration);
 *
 * // Example output:
 * // {
 * //   10: { bounds: [10, 20], next_start_time: 20, prev_end_time: 10 },
 * //   40: { bounds: [40, 80], next_start_time: 80, prev_end_time: 40 },
 * //   90: { bounds: [90, 120], next_start_time: 120, prev_end_time: 90 }
 * // }
 */
export function getNewTrimFromEndDeletes(
  newEndTime: number,
  clipData: Clip,
  fullRecordingDuration: number
): ClipDeletes | null {
  if (newEndTime === fullRecordingDuration && clipData.asset_metadata.end === fullRecordingDuration) return null;
  const keepingDeletes = Object.values(clipData.asset_metadata.deletes || {}).reduce((acc, el) => {
    const [deleteStart, deleteEnd] = el.bounds;
    if (deleteEnd < newEndTime) {
      acc[deleteStart] = el;
    }
    return acc;
  }, {});
  return {
    ...keepingDeletes,
    [newEndTime]: {
      bounds: [newEndTime, fullRecordingDuration] as [number, number],
      next_start_time: fullRecordingDuration,
      prev_end_time: newEndTime
    }
  };
}
