import { memo } from 'react';
import { IconCloudCheck, IconLoader2 } from '@tabler/icons-react';
import moment from 'moment';
import { classnames } from '@/libs/utils';
import useElementHoveredHook from '@/hooks/useElementHoveredHook';

let title = 'Updating...';

const PostUpdateSpinner = memo(function PostUpdateSpinner({
  updatedAt,
  isUpdating
}: {
  updatedAt: string;
  isUpdating: boolean;
}) {
  const { isHovered, handleOnMouseEnter, handleOnMouseLeave } = useElementHoveredHook();

  if (isHovered && !isUpdating) {
    title = `Updated ${moment(new Date(updatedAt)).fromNow()}`;
  } else if (isHovered && isUpdating) {
    title = 'Updating...';
  }

  return (
    <div
      className={classnames('text-slate-700', { 'animate-spin': isUpdating })}
      title={title}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      {isUpdating ? <IconLoader2 size={16} /> : <IconCloudCheck size={16} />}
    </div>
  );
});

export default PostUpdateSpinner;
