import React from 'react';
import ContentUpload from './VideoImportDialog/ContentUpload';
import BrandingSelect from './VideoImportDialog/BrandingSelect/BrandingSelect';
import TemplateSelect from './VideoImportDialog/TemplateSelect/TemplateSelect';
import Done from './VideoImportDialog/Done';

type VideoImportStep = {
  id: 'content-upload' | 'branding-select' | 'template-select' | 'done';
  index: number;
  component: React.FC<{ onNext: () => void }>;
  title: string;
  description: string;
};

export const DEFAULT_COLOR = '#5449D9';

// @kashish TODO - Remove this once Use_CL_FTUX_Phase2 FF is released
export const CUSTOMIZATIONS_DISABLED_VIDEO_IMPORT_STEPS: VideoImportStep[] = [
  {
    id: 'content-upload',
    index: 0,
    component: ContentUpload,
    title: 'Import Your Video',
    description:
      'Get weeks worth of content out of your webinars, product demos, video podcasts, or any other long-form video content.'
  },
  {
    id: 'done',
    index: 1,
    component: Done,
    title: 'That was easy!',
    description: ''
  }
];

export const VIDEO_IMPORT_STEPS: VideoImportStep[] = [
  {
    id: 'content-upload',
    index: 0,
    component: ContentUpload,
    title: 'Import Your Video',
    description:
      'Get weeks worth of content out of your webinars, product demos, video podcasts, or any other long-form video content.'
  },
  {
    id: 'branding-select',
    index: 1,
    component: BrandingSelect,
    title: 'Branding',
    description: 'Add a splash of branding to your clips. You can always edit your brand settings in Brand Kit later.'
  },
  {
    id: 'template-select',
    index: 2,
    component: TemplateSelect,
    title: 'Templates',
    description: 'Select templates you want to use for your clips. You can always edit these later.'
  },
  {
    id: 'done',
    index: 3,
    component: Done,
    title: 'That was easy!',
    description: ''
  }
];
