import { memo } from 'react';

function CaptionsMenuTooltip({ content }: { content: string }) {
  return (
    <div className="pointer-events-none fixed flex w-max -translate-y-9 items-center justify-center rounded bg-black/80 p-1.5 px-2.5 text-xs font-normal text-white opacity-0 transition-all delay-100 duration-150 ease-in-out group-hover/button:-translate-y-10 group-hover/button:opacity-100">
      <span>{content}</span>
    </div>
  );
}

export default memo(CaptionsMenuTooltip);
