import { useEffect, useMemo, useRef, useState } from 'react';
import { getVideoManifestUrl } from '@/stores/core';
import HlsPlayerV2 from '@/Pages/Clip/ClipPlayer/HlsPlayerV2';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function UpscaledVideoPreview({ eventId, broadcastId }: { eventId: string; broadcastId: string }) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const url = useMemo(() => getVideoManifestUrl(), [eventId, broadcastId]);
  const containerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [mouseX, setMouseX] = useState(0);
  const { clipData } = useClipsContext();

  useEffect(() => {
    const containerRect = containerRef.current?.getBoundingClientRect();
    if (!!containerRect) setMouseX(containerRect.width / 2);
  }, []);

  function handlePointerMove(e: React.PointerEvent) {
    const containerRect = containerRef.current?.getBoundingClientRect();
    if (!!containerRect) {
      setMouseX(e.clientX - containerRect.left - containerRect.width);
    }
  }

  function updateVideoTime() {
    if (videoRef.current) {
      videoRef.current.currentTime = clipData.asset_metadata.start;
    }
  }

  return (
    <div
      onPointerMove={handlePointerMove}
      ref={containerRef}
      className="relative aspect-video w-full cursor-ew-resize overflow-hidden rounded-lg shadow-xl"
    >
      <div
        className="absolute h-full w-full origin-left border-r-[1.5px] border-white backdrop-blur-[1px]"
        style={{
          transform: `translate(${mouseX}px, -18.5%) rotate(6deg)`
        }}
      ></div>
      <HlsPlayerV2 url={url} videoRef={videoRef} onMediaAttached={updateVideoTime} />
    </div>
  );
}
