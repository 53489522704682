import { useSyncExternalStore, useState } from 'react';
import { assetsChaptersPartialUpdate, ChapterResponse } from '@goldcast/api/content';
import { ClipDeletes, ClipMetadata, SourceType } from '@/domains/asset';
import useAnalytics from '@/hooks/useAnalytics';
import useFreeTrialHook from '@/hooks/useFreeTrialHook';
import { createContentClip } from '@/libs/clipContentUtil';
import { showErrorToast } from '@/libs/toast/toast';
import { prependAllClips, setClipGeneratingCount } from '@/stores/clip';
import { core } from '@/stores/core';
import { addUnseenClipIds } from '@/stores/unseenClips';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { useAppContext } from '@/context/AppContext/AppContext';

export default function useTranscriptPage() {
  const [selectedChapter, setSelectedChapter] = useState<ChapterResponse | null>(null);

  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);
  const transcriptStore = useTranscriptContext();

  const { incrementClipsUsed } = useFreeTrialHook();
  const { trackContentCreated } = useAnalytics();
  const { logger } = useAppContext();

  async function saveClip(
    { start, end, deletes }: Pick<ClipMetadata, 'start' | 'end' | 'deletes'>,
    source: SourceType
  ) {
    try {
      const newClip = await createContentClip(start, end, source, deletes);
      prependAllClips([{ ...newClip, isRemoved: false } as any]);
      addUnseenClipIds([newClip.id]);
      if (!coreStore.content?.is_sample_upload) {
        incrementClipsUsed(1);
      }
      trackContentCreated({
        asset: newClip,
        state: 'Created',
        source: 'Manual'
      });
      return newClip;
    } catch (err: any) {
      trackContentCreated({
        source: 'Manual',
        state: 'Failed',
        failureReason: err
      });
      showErrorToast("Creating the clip didn't quite work out. Double-check the time range and give it another shot.");
    }
  }

  const createClip = (start, end, { deletes }: { deletes: ClipDeletes } = { deletes: {} }) => {
    setClipGeneratingCount(1);
    return saveClip(
      {
        start,
        end,
        deletes
      },
      'USER'
    ).then(_newClip => {
      setClipGeneratingCount(0);
    });
  };

  const renameChapter = (chapterId: string, title: string) => {
    return assetsChaptersPartialUpdate({
      id: chapterId,
      body: {
        title
      }
    })
      .then(() => {
        const updatedChapters = transcriptStore?.chapterData?.map(chapter =>
          chapter.id === chapterId ? { ...chapter, title } : chapter
        );
        EventBus.dispatch(CustomEvents.UpdateChapters, updatedChapters);
      })
      .catch(err => {
        showErrorToast('Failed to rename chapter. Please try again in few minutes. ');
        logger.error('Failed to rename chapter', err);
      });
  };

  return {
    createClip,
    saveClip,
    renameChapter,
    selectedChapter,
    setSelectedChapter,
    isAudio: coreStore.content?.media_type === 'AUDIO'
  };
}
