import { classnames } from '@/libs/utils';

export type TooltipPosition = 'left' | 'right';

export default function Tooltip({ content, position = 'left' }: { content: string; position?: TooltipPosition }) {
  const isPositionLeft = position === 'left';
  return (
    <div
      className={classnames(
        'absolute bottom-6 z-50 hidden whitespace-nowrap rounded bg-black p-1 text-2xs leading-tight text-white group-hover:block',
        isPositionLeft ? 'right-0' : 'left-0'
      )}
    >
      <span>{content}</span>
      <div
        className={classnames(
          'absolute -bottom-0.5 h-1.5 w-1.5 rotate-45 bg-black',
          isPositionLeft ? 'right-3' : 'left-3'
        )}
      ></div>
    </div>
  );
}
