import { useState } from 'react';
import usePostShareHook from './usePostShareHook';
import SharePopup, { SharePopupTab } from '../SharePopup';
import PostShareDownloadTab from './PostShareDownloadTab';
import PostShareLinkTab from './PostShareLinkTab';
import { useAppContext } from '@/context/AppContext/AppContext';
import { TextAsset } from '@/Pages/PostsListPage/types';

export default function PostSharePopup({
  selectedPost,
  onPostShared
}: {
  selectedPost: TextAsset | null;
  onPostShared: () => void;
}) {
  const { adminAppStore } = useAppContext();
  const { setPostAsShared } = usePostShareHook(selectedPost);

  const tabs: SharePopupTab[] = [
    {
      key: 'post-share-link-tab',
      title: 'Link',
      tabComponent: <PostShareLinkTab post={selectedPost} />
    },
    {
      key: 'post-share-download-tab',
      title: 'Download',
      tabComponent: <PostShareDownloadTab post={selectedPost} />
    }
  ];

  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const beforeOpen = () => {
    adminAppStore.dispatch?.('user/showForceChangePasswordDialogIfRequired');
    setCurrentTab(tabs[0]);
    setPostAsShared()?.then(() => onPostShared());
  };

  return <SharePopup currentTab={currentTab} tabs={tabs} beforeOpen={beforeOpen} setCurrentTab={setCurrentTab} />;
}
