import { memo, useMemo } from 'react';
import { convertSecondsToTimeString } from '../../SideBar/ClipDuration/utils';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

function ClipSpeakersTimelineDuration() {
  const { clipData } = useClipsContext();

  const durationToDisplay = useMemo(
    () => convertSecondsToTimeString(clipData.asset_metadata?.duration || 0, 'HH:mm:ss'),
    [clipData.asset_metadata?.duration]
  );
  return (
    <div className="pointer-events-none relative mt-1 h-4 w-full">
      <div
        className={`sticky z-[55] ml-0.5 w-fit shrink-0 select-none text-[10px] font-medium uppercase tracking-wider`}
      >
        <div className="absolute left-4 z-40 rounded bg-black px-1 py-0.5 text-xs font-medium tabular-nums text-white">
          {durationToDisplay}
        </div>
      </div>
    </div>
  );
}

export default memo(ClipSpeakersTimelineDuration);
