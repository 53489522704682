import React from 'react';
import { IconAtom } from '@tabler/icons-react';
import { useAppContext } from '@/context/AppContext/AppContext';

export default function NoData({ openSessionsDialog }) {
  const {
    adminAppStore: { user: { is_content_lab_standalone } = { is_content_lab_standalone: false } }
  } = useAppContext();

  return (
    <div className="flex w-full items-center justify-center py-32">
      <div className="flex flex-col items-center">
        <IconAtom size={32} stroke={1.5} className="text-slate-400" />
        <div className="mt-2 max-w-[11rem] text-center text-sm leading-tight text-slate-400">
          {is_content_lab_standalone
            ? 'Start creating content by uploading a recording.'
            : 'Start creating content by selecting a recent event.'}
        </div>
        <button
          className="mt-3 rounded-lg border border-slate-200 px-3 py-2 text-xs font-medium"
          onClick={openSessionsDialog}
        >
          {is_content_lab_standalone ? 'Upload recording' : 'New content'}
        </button>
      </div>
    </div>
  );
}
