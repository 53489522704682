import React, { Fragment, useCallback, useRef, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { IconX } from '@tabler/icons-react';
import { DIALOG_SIZE, DIALOG_TITLE_SIZE, DIALOG_TITLE_SIZE_CLASS, DIALOG_WIDTH } from './ContentDialogUtils';
import { propagateCustomEvent } from './utils';
import { classnames } from '@/libs/utils';

export default function ContentDialog({
  isOpen,
  setIsOpen,
  size = 'medium',
  title,
  titleSize = 'small',
  children,
  disableBackdropClose = false,
  isHidden = false,
  hideCloseIcon = false,
  panelClassNames = ''
}: React.PropsWithChildren<{
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  title?: string;
  titleSize?: DIALOG_TITLE_SIZE;
  size?: DIALOG_SIZE;
  disableBackdropClose?: boolean;
  isHidden?: boolean;
  hideCloseIcon?: boolean;
  panelClassNames?: string;
}>) {
  const dialogRef = useRef<HTMLDivElement>(null);

  const closeDialog = useCallback(() => {
    setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleBackdropClick() {
    if (!disableBackdropClose) closeDialog();
  }

  const handleCTAClick = useCallback((event: MouseEvent) => {
    propagateCustomEvent(event, dialogRef);
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleCTAClick, true);
    }

    return () => {
      document.removeEventListener('click', handleCTAClick, true);
    };
  }, [isOpen, handleCTAClick]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="tw-relative tw-z-50 content-lab-root" onClose={handleBackdropClick}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-white/90" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="transform duration-300 ease-in-out"
              enterFrom="translate-y-5 opacity-0"
              enterTo="translate-y-0 opacity-100"
              leave="transform duration-300 ease-in-out"
              leaveFrom="translate-y-0 opacity-100"
              leaveTo="translate-y-5 opacity-0"
            >
              <Dialog.Panel
                style={{
                  visibility: isHidden ? 'hidden' : 'visible'
                }}
                ref={dialogRef}
                className={classnames(
                  'max-h-[75vh] w-full overflow-y-auto !rounded-xl border border-slate-200 bg-white shadow-xl transition-all',
                  panelClassNames,
                  DIALOG_WIDTH[size]
                )}
                data-testid="content-dialog-panel"
              >
                <Dialog.Title
                  className={classnames(
                    'sticky top-0 z-30 flex w-full items-center justify-between rounded-t-xl bg-white text-xs font-medium',
                    { 'border-b': !!title }
                  )}
                >
                  <div className={DIALOG_TITLE_SIZE_CLASS[titleSize]}>{title}</div>
                  {!hideCloseIcon && (
                    <div onClick={closeDialog} className="p-3" data-testid="close-dialog-icon">
                      <IconX size={16} stroke={1.9} className="cursor-pointer" />
                    </div>
                  )}
                </Dialog.Title>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
