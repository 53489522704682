import { memo, useCallback, useEffect, useMemo, useState, useSyncExternalStore } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { CUSTOMIZATIONS_DISABLED_VIDEO_IMPORT_STEPS, VIDEO_IMPORT_STEPS } from '../constants';
import { useVideoImportContext } from '../VideoImportContext';
import { classnames } from '@/libs/utils';
import Button from '@/components/atoms/Button/Button';
import useContentUploadHook from '@/hooks/useContentUploadHook';
import { ContentStatesEnum } from '@/Pages/GenerateContent/constants';
import { initContent, USER_NOT_ADMIN_OR_IN_ORG_ERROR_MESSAGE } from '@/stores/core';
import { useAppContext } from '@/context/AppContext/AppContext';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';

const VideoImportDialog = () => {
  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const videoImportSteps = useMemo(() => {
    if (featureFlags[FeatureFlagKeys.Use_CL_FTUX_Phase2]) {
      return VIDEO_IMPORT_STEPS;
    }
    return CUSTOMIZATIONS_DISABLED_VIDEO_IMPORT_STEPS;
  }, [featureFlags]);

  const [loading, setLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(videoImportSteps[0]);

  const { broadcastId, eventId } = useParams<{ eventId: string; broadcastId: string }>();
  const navigate = useNavigate();
  const { logger } = useAppContext();
  const { setFilters, navigationDisabled } = useVideoImportContext();

  const { getContentUploadById, contentUploadList, initUploadedContent } = useContentUploadHook();
  const contentUpload = getContentUploadById(broadcastId);
  const { contentState } = contentUpload;

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.UploadContentStarted, ({ projectId, contentId }) => {
      navigate(`/import/${projectId}/${contentId}`, { replace: true });
      setCurrentStep(videoImportSteps[1]);
    });
    return () => {
      EventBus.off(CustomEvents.UploadContentStarted, eventListener);
    };
  }, []);

  useEffect(() => {
    const isFreshImport = !broadcastId || !eventId;

    if (isFreshImport) {
      setLoading(false);
      return;
    }

    const isInvalidImport =
      !contentUpload.projectId ||
      !contentUpload.contentId ||
      contentUpload.projectId !== eventId ||
      contentUpload.contentId !== broadcastId;

    if (isInvalidImport) {
      setCurrentStep(videoImportSteps[0]);
      setLoading(false);
      return;
    }

    if (contentState === ContentStatesEnum.Generating) {
      setCurrentStep(videoImportSteps[videoImportSteps.length - 1]);
    } else if (contentState === ContentStatesEnum.ContentReadyForPreview) {
      navigate(`/${eventId}/${broadcastId}/clips`, { replace: true });
    } else if (currentStep.id === 'content-upload') {
      setCurrentStep(videoImportSteps[1]);
    }

    setLoading(false);
  }, [broadcastId, eventId, contentState, contentUpload, currentStep.id, videoImportSteps]);

  useEffect(() => {
    if (!broadcastId || !eventId || contentUploadList[broadcastId]) return;

    initContent(broadcastId)
      .then(res => {
        const contentDetails = initUploadedContent(res);
        if (contentDetails.contentState === ContentStatesEnum.ContentReadyForPreview) {
          navigate(`/${eventId}/${broadcastId}/clips`, { replace: true });
        } else {
          // Handle the case where the user may have already submitted customizations
          setCurrentStep(videoImportSteps[1]);
        }
      })
      .catch(err => {
        const isUserNotInOrgOrNotAdmin =
          err.statusCode === 404 && err.message === USER_NOT_ADMIN_OR_IN_ORG_ERROR_MESSAGE;
        const isAbortError = err.name === 'AbortError';

        if (!(isUserNotInOrgOrNotAdmin || isAbortError)) {
          logger.error('Failed to fetch uploaded content', err);
        }
        navigate('/');
      })
      .finally(() => {
        setLoading(false);
      });
    return;
  }, [broadcastId, eventId, contentUploadList, videoImportSteps]);

  const onSkip = useCallback(() => {
    if (currentStep.id === 'branding-select') {
      setCurrentStep(videoImportSteps[videoImportSteps.length - 1]);
    }
  }, [currentStep, videoImportSteps]);

  const onNext = useCallback(() => {
    if (currentStep.index === videoImportSteps.length - 1) return;
    setCurrentStep(prev => videoImportSteps[prev.index + 1]);
    setFilters({ size: undefined, title: undefined });
  }, [currentStep.index, setFilters, videoImportSteps]);

  const onPrev = useCallback(() => {
    if (currentStep.index === 0) return;
    setCurrentStep(prev => videoImportSteps[prev.index - 1]);
    setFilters({ size: undefined, title: undefined });
  }, [currentStep.index, setFilters, videoImportSteps]);

  const showPreviousButton = useMemo(() => {
    if (!featureFlags[FeatureFlagKeys.Use_CL_FTUX_Phase2]) {
      return false;
    }

    if (currentStep.id === 'template-select') {
      return true;
    }

    if (
      currentStep.id === 'done' &&
      [ContentStatesEnum.Uploading, ContentStatesEnum.Processing].includes(contentState)
    ) {
      return true;
    }

    return false;
  }, [currentStep, contentState, featureFlags]);

  return (
    <div
      className={classnames({
        'relative flex h-full w-full items-center justify-center overflow-hidden': true,
        'bg-[rgba(250,250,252,0.92)] backdrop-blur-[20px] backdrop-saturate-[180%]':
          currentStep.id === 'template-select'
      })}
    >
      <div
        className={classnames({
          'bottom-1/2 translate-y-1/2': currentStep.id === 'content-upload',
          'bottom-0 translate-y-[-20%]': currentStep.id === 'branding-select' || currentStep.id === 'done',
          'bottom-0 translate-y-0': currentStep.id === 'template-select',
          'pointer-events-auto absolute transition-all duration-[750ms] ease-in-out': true
        })}
      >
        <Transition
          show={!loading}
          appear
          enter="transition-all duration-750"
          enterFrom="opacity-0 translate-y-4"
          enterTo="opacity-100 translate-y-0"
        >
          <div
            className={classnames({
              'h-[33rem] w-[50rem] rounded-2xl border-slate-200': ['done', 'content-upload'].includes(currentStep.id),
              'h-[80vh] max-h-[100vh] w-[100vw] rounded-t-2xl border-transparent': currentStep.id === 'template-select',
              'h-[29.5rem] w-[50rem] rounded-2xl border-slate-200': currentStep.id === 'branding-select',
              'relative z-20 flex translate-y-2 flex-col border bg-[rgba(250,250,252,0.92)] p-6 pb-4 backdrop-blur-[20px] backdrop-saturate-[180%] transition-all duration-[750ms] ease-in-out':
                true
            })}
          >
            <div className="mb-4 flex items-center justify-between">
              <div className="flex w-1/3 items-center space-x-2">
                <h2 className="text-2xl font-semibold">{currentStep.title}</h2>
              </div>
              {featureFlags[FeatureFlagKeys.Use_CL_FTUX_Phase2] && (
                <>
                  <div className="flex w-1/3 justify-center">
                    {currentStep.id !== 'done' && (
                      <p className="inline-flex text-sm tabular-nums text-slate-500">
                        {currentStep.index + 1} / {videoImportSteps.length}
                      </p>
                    )}
                  </div>
                  <div className="flex w-1/3 justify-end">
                    {currentStep.id !== 'done' && (
                      <div className="h-2 w-32 overflow-hidden rounded-full bg-slate-200">
                        <div
                          className="h-full rounded-full bg-slate-800 transition-all duration-200 ease-in-out"
                          style={{ width: `${((currentStep.index + 1) / videoImportSteps.length) * 100}%` }}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            <p className="mb-5 text-sm text-slate-600">{currentStep.description}</p>
            {currentStep.index + 1 <= videoImportSteps.length && (
              <>
                <div
                  className={classnames({
                    'overflow-y-scroll rounded-2xl bg-white': currentStep.id === 'template-select',
                    'p-1': currentStep.id !== 'template-select',
                    'flex grow flex-col': true
                  })}
                >
                  <currentStep.component onNext={onNext} />
                </div>
                {currentStep.id !== 'content-upload' && (
                  <div
                    className={classnames({
                      'border-t border-t-slate-300': currentStep.id === 'template-select',
                      'flex justify-between pt-5': true
                    })}
                  >
                    {showPreviousButton ? (
                      <Button
                        trackingId="back-button"
                        onClick={onPrev}
                        variation="outline"
                        buttonSize="large"
                        className="bg-white"
                        title="Previous"
                        disabled={navigationDisabled}
                      >
                        Previous
                      </Button>
                    ) : (
                      <div />
                    )}
                    <div className="flex gap-2">
                      {currentStep.id === 'branding-select' && (
                        <Button
                          trackingId="skip-button"
                          onClick={onSkip}
                          variation="outline"
                          buttonSize="large"
                          className="bg-white"
                          title="Skip"
                          disabled={navigationDisabled}
                        >
                          Skip
                        </Button>
                      )}
                      {currentStep.id !== 'done' ? (
                        <Button
                          trackingId="next-button"
                          onClick={onNext}
                          variation="filled"
                          buttonSize="large"
                          title="Next"
                          disabled={navigationDisabled}
                        >
                          Next
                        </Button>
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default memo(VideoImportDialog);
