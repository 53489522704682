import React from 'react';

export default function RecentEventsSkeleton() {
  return Array.from({ length: 5 }).map((_, i) => (
    <div
      className="h-[17.25rem] w-[23rem] shrink-0 animate-pulse !rounded-xl bg-slate-200/50 pb-1"
      key={'recent-events-skeleton' + i}
    ></div>
  ));
}
