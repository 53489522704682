import { getClipPlayerElementId } from '../Clip/ClipPlayer/ClipPlayerUtils';
import { POST_TO_NAV_MAP } from './constants';
import { TextAsset } from './types';

export async function copyTextToClipboard(text: string, logger) {
  try {
    await navigator.clipboard.writeText(text);
    executeCopyCommand(text);
  } catch (err) {
    logger.error('Failed to copy: ', err);
  }
}

function executeCopyCommand(text: string) {
  const el = document.createElement('div');
  el.innerText = text;
  el.classList.add('hidden');
  document.body.appendChild(el);
  const range = document.createRange();
  range.selectNode(el);
  window.getSelection()?.removeAllRanges();
  window.getSelection()?.addRange(range);
  document.execCommand('copy');
  window.getSelection()?.removeAllRanges();
  document.body.removeChild(el);
}

export function sendEmail(body: string) {
  const match = body.match(/Subject:\s*(.+)/);
  const subject = match ? match[1] : '';

  let remainingBody = body;
  if (subject) {
    remainingBody = body.substring(body.indexOf(subject[0]) + subject.length);
  }

  document.location =
    'mailto:abc@xyz.com?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(remainingBody.trim());
}

export function capitalizeFirstLetter(word: string | undefined) {
  if (!word) return '';
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function countWords(text: string) {
  return text?.trim().split(/\s+/).length || 0;
}

/**
 * @param textAsset TextAsset object
 * @param clipId Pass clipId if you want to scroll to a specific clip in the post
 * @returns
 */
export function getUriFromTextAsset(textAsset: TextAsset, clipId?: string, isSavedSearchPage?: boolean) {
  const projectId = textAsset.content?.project?.id;
  const contentId = textAsset.content?.id;
  const assetId = textAsset.id;

  let uri = `${projectId}/${contentId}/${POST_TO_NAV_MAP[textAsset.asset_metadata.type]}?activePostId=${assetId}`;

  if (isSavedSearchPage) {
    uri = `search/${textAsset.saved_search?.id}/${
      POST_TO_NAV_MAP[textAsset.asset_metadata.type]
    }?activePostId=${assetId}`;
  }

  if (clipId) {
    uri += `#${getClipPlayerElementId(clipId)}`;
  }

  return uri;
}
