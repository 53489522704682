import { getElementsTimelineForSpeakerRecording } from './activeSpeakerForRecording';
import { getElementsTimelineForSpeakerUpload } from './activeSpeakerForUpload';
import { Clip } from '@/domains/asset';
import { isCustomUpload } from '@/libs/clipContentUtil';

export const getElementsTimelineForSpeaker = (clip: Clip) => {
  if (isCustomUpload()) {
    return getElementsTimelineForSpeakerUpload(clip);
  } else {
    return getElementsTimelineForSpeakerRecording(clip);
  }
};
