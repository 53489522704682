import React, { useEffect, useState } from 'react';
import classes from './ProgressiveImage.module.scss';

interface ProgressiveImageProps {
  placeholderSrc: string;
  src: string;
  alt?: string;
}

export default function ProgressiveImage({ placeholderSrc, src, alt = '' }: ProgressiveImageProps): JSX.Element {
  const [imgSrc, setImgSrc] = useState<string>(placeholderSrc || src);
  const [imgFailed, setImgFailed] = useState(false);
  const [imgPlaceholderFailed, setImgPlaceholderFailed] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
    };
    img.onerror = () => {
      setImgFailed(true);
    };
  }, [src]);

  const customClass = placeholderSrc && imgSrc === placeholderSrc ? classes['loading'] : classes['loaded'];
  return (
    <img
      src={imgSrc}
      alt={alt}
      loading="lazy"
      className={`h-full w-full ${customClass} ${imgFailed && imgPlaceholderFailed ? 'invisible' : ''}`}
      onError={() => setImgPlaceholderFailed(true)}
    />
  );
}
