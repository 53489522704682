import { useEffect, useMemo, useRef, useState, useSyncExternalStore } from 'react';
import { IconInfoCircle, IconVideo } from '@tabler/icons-react';
import AudiogramPreview from '../AudiogramPreview';
import { DEFAULT_TILE_CURSOR, PROCESSING_CURSOR, UNAVAILABLE_CURSOR } from '../../MagicLayout/constants';
import { PreviewTemplate } from '../types';
import CanvasPreview from '../../CanvasPlayer/CanvasPreview/CanvasPreview';
import TemplateMenu from './TemplateMenu';
import IntroOutroPlayer from '../../ClipPlayer/IntroOutroPlayer';
import { useActiveSpeakerMatchCondition } from '../../ClipPlayer/ClipCustomizer/useActiveSpeakerMatchCondition';
import { classnames } from '@/libs/utils';
import { useSpeakerSegmentContext } from '@/context/SpeakerSegmentContext/SpeakerSegmentContext';
import { applyTemplate, updateClipWithId } from '@/stores/clip';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { isCustomUpload } from '@/libs/clipContentUtil';
import ConfirmationDialog from '@/components/organisms/ConfirmationDialog';
import useDialog from '@/components/organisms/useDialog';
import Tooltip, { TooltipPosition } from '@/components/atoms/Button/Tooltip';
import Marquee from '@/components/atoms/Marquee/Marquee';
import { core } from '@/stores/core';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';

export default function TemplatePreview({
  template,
  classNames = '',
  canSelect = true,
  predefinedTooltipPosition = 'left',
  isSpeakerIdentificationMandatory = false
}: {
  template: PreviewTemplate;
  classNames?: string;
  canSelect?: boolean;
  predefinedTooltipPosition?: TooltipPosition;
  isSpeakerIdentificationMandatory?: boolean;
}) {
  const { clipId, clipData, sharedAPIStore } = useClipsContext();
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);
  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const speakerSegmentContextData = useSpeakerSegmentContext();
  const [isMarqueeActive, setIsMarqueeActive] = useState(false);

  const titleContainerRef = useRef<HTMLDivElement>(null);
  const disabledBadge = useRef<HTMLDivElement>(null);

  const [tooltipPosition, setTooltipPosition] = useState<TooltipPosition>('left');

  useEffect(() => {
    if (!!titleContainerRef.current && !predefinedTooltipPosition) {
      setTooltipPosition(titleContainerRef.current.getBoundingClientRect().left > 50 ? 'left' : 'right');
    }
  }, [titleContainerRef, predefinedTooltipPosition]);

  const currentSpeaker = useMemo(() => {
    return speakerSegmentContextData?.segmentSpeaker[speakerSegmentContextData?.startSegment];
  }, [speakerSegmentContextData]);

  const secondaryPlayers = useMemo(() => {
    return Object.values(sharedAPIStore.speakerMap).map(speaker => ({ current: speaker.videoRef }));
  }, [sharedAPIStore.speakerMap]);

  const isActiveSpeakerPlayable = useMemo(() => {
    if (template.isUnavailable && template.reason === 'MORE_THAN_ONE_SPEAKER_DETECTED') {
      return true;
    }

    return template.layout === 'SPEAKER' && isSpeakerIdentificationMandatory;
  }, [template.isUnavailable, template.reason, template.layout, isSpeakerIdentificationMandatory]);

  const isNewABTypeLayoutProcessing = useMemo(
    () =>
      featureFlags[FeatureFlagKeys.Use_CL_Facial_Rec_Increment_AB] &&
      coreStore.content?.media_source_type === 'UPLOAD' &&
      template.isProcessing,
    [template.isProcessing, featureFlags, coreStore]
  );

  const isDisabled = useMemo(() => {
    if (isNewABTypeLayoutProcessing) {
      return false;
    }

    if (template.isProcessing) {
      return true;
    }

    return template.isUnavailable && template.reason !== 'MORE_THAN_ONE_SPEAKER_DETECTED';
  }, [isNewABTypeLayoutProcessing, template.isProcessing, template.isUnavailable, template.reason]);

  const mainPlayerRef = useMemo(() => {
    return { current: sharedAPIStore.mainPlayerRef };
  }, [sharedAPIStore.mainPlayerRef]);

  const { shouldBehaveLikeActiveSpeaker } = useActiveSpeakerMatchCondition();

  function openSpeakersIdentificationDialog() {
    EventBus.dispatch(CustomEvents.OpenSpeakersIdentification, {
      postIdentifyCallback: dispatchReloadTemplates,
      layout: shouldBehaveLikeActiveSpeaker ? 'SPEAKER' : template.layout,
      isInlineIdentification: true
    });
  }

  function dispatchReloadTemplates() {
    updateClipWithId(clipId, {
      layout_status: {
        ...clipData.layout_status,
        SPEAKER: {
          status: 'DONE',
          reason: '',
          accurate: clipData.layout_status.SPEAKER.accurate
        }
      }
    });

    EventBus.dispatch(CustomEvents.ReloadTemplates, { template });
  }

  const isUpload = isCustomUpload();
  const {
    isOpen: isActiveSpeakerIdentificationRequiredDialogOpen,
    openDialog: openActiveSpeakerIdentificationRequiredDialog,
    closeDialog: closeActiveSpeakerIdentificationRequiredDialog
  } = useDialog();

  const shouldHighlightTemplatePreview = useMemo(() => {
    const isClipEdited = clipData.asset_metadata.is_edited;
    if (isClipEdited) {
      return false;
    }

    const template_id = clipData.asset_metadata.template_id;
    if (!template_id && template.default === coreStore.content?.media_type) {
      return true;
    }

    return template_id === template.id;
  }, [clipData, template.id]);

  function onTemplatePreviewClick() {
    if (!(canSelect && !isDisabled)) return;

    identifySpeakerOrApplyTemplate();
  }

  function identifySpeakerOrApplyTemplate() {
    if (template.layout === 'SPEAKER' && isCustomUpload() && isActiveSpeakerPlayable) {
      openActiveSpeakerIdentificationRequiredDialog();
    } else {
      applyTemplate(clipId, template);
    }
  }

  return (
    <div onMouseEnter={() => setIsMarqueeActive(true)} onMouseLeave={() => setIsMarqueeActive(false)}>
      <div
        className={classnames(
          'relative flex aspect-[4/3] w-full shrink-0 items-center justify-center rounded-lg border-2 border-transparent bg-slate-100 text-left',
          template.size === 'PORTRAIT' ? 'p-5' : 'p-8',
          canSelect ? 'cursor-pointer' : '',
          classNames,
          shouldHighlightTemplatePreview ? 'ring-2 ring-deep-orange' : ''
        )}
        data-testid="template-preview-container"
        onClick={onTemplatePreviewClick}
      >
        <div
          className={classnames('relative flex items-center justify-center rounded-md', {
            'aspect-video w-full': template.size === 'LANDSCAPE',
            'aspect-square h-full': template.size === 'SQUARE',
            'aspect-[9/16] h-full': template.size === 'PORTRAIT'
          })}
        >
          {isDisabled || isActiveSpeakerPlayable ? (
            <div
              className={classnames('flex h-full w-full items-center justify-center', {
                [PROCESSING_CURSOR]: !isNewABTypeLayoutProcessing && template.isProcessing,
                [UNAVAILABLE_CURSOR]: template.isUnavailable,
                [DEFAULT_TILE_CURSOR]: isActiveSpeakerPlayable
              })}
            >
              <div className="flex h-full w-full items-center justify-center rounded">
                <IconVideo size={32} stroke={1.5} className="text-slate-500" />
              </div>
            </div>
          ) : template.intro ? (
            <IntroOutroPlayer
              videoProps={{
                src: template.intro
              }}
              type="intro"
              parentClassName="h-full"
            />
          ) : template.layout === 'AUDIOGRAM' && currentSpeaker ? (
            <AudiogramPreview clipTemplate={template} currentSpeaker={currentSpeaker} />
          ) : template.layout === 'DEFAULT' && !!mainPlayerRef.current ? (
            <CanvasPreview template={template} mainPlayer={mainPlayerRef} secondaryPlayers={[]} />
          ) : ['SPEAKER', 'GRID'].includes(template.layout) &&
            !!mainPlayerRef.current &&
            (isUpload || !!secondaryPlayers.length) ? (
            <CanvasPreview
              template={{ ...clipData.asset_metadata, ...template }}
              mainPlayer={mainPlayerRef}
              secondaryPlayers={secondaryPlayers}
              currentSpeaker={currentSpeaker}
            />
          ) : (
            <></>
          )}
        </div>
        {!template.isDefault && canSelect && <TemplateMenu template={template} />}
      </div>
      <div className="flex items-center justify-between text-xs">
        <div
          className={classnames('top-1 mt-1.5 line-clamp-1 w-full text-xs', { 'text-slate-600': isDisabled })}
          ref={titleContainerRef}
        >
          <Marquee content={template.title} isActive={isMarqueeActive} offset={disabledBadge.current?.offsetWidth} />
        </div>
        {isDisabled && (
          <div
            className="group relative ml-0.5 flex cursor-help items-center space-x-0.5 rounded bg-slate-300 px-1 py-0.5 text-xs text-slate-600"
            ref={disabledBadge}
          >
            <IconInfoCircle size={16} />
            <span>{template.isUnavailable ? 'Unavailable' : 'Processing'}</span>
            {template.isUnavailable && (
              <Tooltip content={template.message || ''} position={predefinedTooltipPosition || tooltipPosition} />
            )}
          </div>
        )}
      </div>
      <ConfirmationDialog
        isOpen={isActiveSpeakerIdentificationRequiredDialogOpen}
        onClose={closeActiveSpeakerIdentificationRequiredDialog}
        onConfirm={openSpeakersIdentificationDialog}
        title="Identify speakers to use this template"
        content={'Match speakers to faces in the video to use the Active Speaker template.'}
        confirmLabel="Next"
      />
    </div>
  );
}
