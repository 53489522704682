import React, { useSyncExternalStore } from 'react';
import ProgressRing from './ProgressRing';
import { orgUsageSummaryState } from '@/stores/orgUsageSummary';
import useFreeTrialHook from '@/hooks/useFreeTrialHook';
import { classnames } from '@/libs/utils';

export default function FreeTrialUpgradeNag({ buttonOnlyMode = true }): JSX.Element {
  const orgUsageSummaryStore = useSyncExternalStore(orgUsageSummaryState.subscribe, orgUsageSummaryState.getSnapshot);
  const { openNonBlockingPricingPaywall, isFreeTrialEnabled, videoUploadLeft, isFreemiumUser, isPaidUser } =
    useFreeTrialHook();

  const showUpgradeButton = isFreeTrialEnabled || isFreemiumUser;

  if (!isFreeTrialEnabled && !isFreemiumUser && !isPaidUser) {
    return <></>;
  }

  if (orgUsageSummaryStore.loading || orgUsageSummaryStore.error || !orgUsageSummaryStore.orgId) {
    return <></>;
  }

  const totalVideoUpload = orgUsageSummaryStore.usage.videoUpload.total;

  return (
    <div className="content-lab-root">
      <div className="flex flex-row gap-1.5">
        {!buttonOnlyMode && <ProgressRing videoUploadLeft={videoUploadLeft} totalVideoUpload={totalVideoUpload} />}
        {showUpgradeButton && (
          <button
            className={classnames(
              'group flex items-center space-x-2 rounded-lg bg-[#7B72FF] font-medium text-white hover:bg-[#5449D9]',
              {
                'mr-2 h-10 pl-3.5 pr-3 text-[0.825rem]': buttonOnlyMode,
                'mr-3 h-8 pl-2.5 pr-1 text-xs': !buttonOnlyMode
              }
            )}
            data-testid="free-trial-upgrade-nag-upgrade-button"
            onClick={openNonBlockingPricingPaywall}
          >
            Upgrade
            <div className="ml-2 flex h-6 w-6 shrink-0 scale-[0.95] items-center justify-center rounded-lg bg-[#5449D9] text-white group-hover:bg-[#1B279B]">
              <svg
                className="h-4 w-4"
                width="100%"
                height="100%"
                viewBox="0 0 24 24"
                strokeWidth="0.5"
                stroke="currentColor"
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M13 3l0 7l6 0l-8 11l0 -7l-6 0l8 -11"></path>
              </svg>
            </div>
          </button>
        )}
      </div>
    </div>
  );
}
