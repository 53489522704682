import { memo } from 'react';
import { LayoutPopupOption } from './constants';
import IconButton from '@/components/atoms/Button/IconButton';
import { classnames } from '@/libs/utils';
import Tooltip, { TooltipPosition } from '@/components/atoms/Button/Tooltip';

function ClipCustomizerLayoutIconButton({
  optionDetails,
  trackingId,
  isActive,
  isDisabled = false,
  disabledReason,
  onClick,
  tooltipPosition = 'left'
}: {
  optionDetails: LayoutPopupOption;
  trackingId: string;
  isActive: boolean;
  isDisabled?: boolean;
  disabledReason?: string;
  onClick: () => void;
  tooltipPosition?: TooltipPosition;
}) {
  return (
    <div className="group relative">
      <IconButton
        icon={optionDetails.icon}
        content={optionDetails.label}
        variation="checkbox"
        trackingId={trackingId}
        disabled={isDisabled}
        buttonClassName={classnames(
          'relative flex items-center justify-center space-x-1 !rounded-full border px-3 py-1.5 text-sm font-medium focus:!ring-black',
          isActive ? 'border-transparent bg-slate-100 ring-2 ring-black' : 'border-slate-200'
        )}
        onClick={onClick}
      />
      {isDisabled && disabledReason && (
        <div className="relative bottom-4">
          <Tooltip content={disabledReason} position={tooltipPosition} />
        </div>
      )}
    </div>
  );
}

export default memo(ClipCustomizerLayoutIconButton);
