import AssetsTable from './AssetsTable/AssetsTable';
import RecentEvents from './RecentEvents/RecentEvents';
import { useAppContext } from '@/context/AppContext/AppContext';

export default function Sessions(): JSX.Element {
  const { adminAppStore } = useAppContext();

  function openNewContentDialog() {
    adminAppStore.commit?.('contentStudio/showNewContentDialog');
  }

  return (
    <div className="flex grow flex-col overflow-hidden bg-slate-50">
      <RecentEvents setIsOpen={openNewContentDialog} />
      <AssetsTable setIsOpen={openNewContentDialog} />
    </div>
  );
}
