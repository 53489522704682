import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';
import { CustomizerMenuType } from './constants';
import ClipCustomizerCaptionsPopup from './ClipCustomizerCaptionsPopup';
import ClipCustomizerTemplatesPopup from './ClipCustomizerTemplatesPopup';
import ClipCustomizerLayoutPopup from './Layout/ClipCustomizerLayoutPopup';
import ClipCustomizerBackgroundPopup from './ClipCustomizerBackgroundPopup';
import ActionButtons, { ActionButton } from '@/components/atoms/ActionButtons/ActionButtons';
import { isAnyDialogOpened } from '@/Pages/Sessions/uiComponents/ContentDialog/utils';

function ClipCustomizerMenu() {
  const [isMenuPopupOpened, setIsMenuPopupOpened] = useState<CustomizerMenuType | null>(null);

  const openPopupMenu = useCallback(
    (menuType: CustomizerMenuType) => {
      isMenuPopupOpened !== menuType ? setIsMenuPopupOpened(menuType) : closePopup();
    },
    [isMenuPopupOpened]
  );

  const buttons: ActionButton[] = useMemo(() => {
    return [
      {
        action: () => openPopupMenu(CustomizerMenuType.Templates),
        icon: 'IconTemplate',
        label: 'Templates',
        trackingId: 'edit-clip-templates-button',
        disabled: false,
        isActive: isMenuPopupOpened === CustomizerMenuType.Templates
      },
      {
        action: () => openPopupMenu(CustomizerMenuType.Layout),
        icon: 'IconLayoutDashboard',
        label: 'Layout',
        trackingId: 'edit-clip-layout-button',
        disabled: false,
        isActive: isMenuPopupOpened === CustomizerMenuType.Layout
      },
      {
        action: () => openPopupMenu(CustomizerMenuType.Captions),
        icon: 'IconAlignBoxCenterBottom',
        label: 'Captions',
        trackingId: 'edit-clip-captions-button',
        disabled: false,
        isActive: isMenuPopupOpened === CustomizerMenuType.Captions
      },
      {
        action: () => openPopupMenu(CustomizerMenuType.Background),
        icon: 'IconPhoto',
        label: 'Background',
        trackingId: 'edit-clip-background-button',
        disabled: false,
        isActive: isMenuPopupOpened === CustomizerMenuType.Background
      }
    ];
  }, [openPopupMenu, isMenuPopupOpened]);

  const popupMenu = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isMenuPopupOpened) return;
    function handleOutsideClick(e: MouseEvent) {
      if (isAnyDialogOpened()) return;
      if (!popupMenu.current?.contains(e.target as Node)) {
        closePopup();
      }
    }
    document.addEventListener('pointerdown', handleOutsideClick);
    return () => {
      document.removeEventListener('pointerdown', handleOutsideClick);
    };
  }, [isMenuPopupOpened]);

  function onOverlayClick(e: React.MouseEvent) {
    e.stopPropagation();
    closePopup();
  }

  const closePopup = useCallback(() => {
    setIsMenuPopupOpened(null);
  }, []);

  return (
    <div className="absolute right-12 z-50 flex h-auto items-center self-center pl-8" ref={popupMenu}>
      {isMenuPopupOpened && <div className="fixed right-0 top-0 h-full w-full" onClick={onOverlayClick} />}
      <ActionButtons show={true} buttons={buttons} labelPosition="left" size="large" />
      <Transition
        show={!!isMenuPopupOpened}
        className="relative"
        enter="transition duration-150 ease-out"
        enterFrom="transform translate-x-2 scale-[0.98] opacity-0"
        enterTo="transform translate-x-0 scale-1 opacity-100"
        leave="transition duration-100 ease-out"
        leaveFrom="transform translate-x-0 scale-1 opacity-100"
        leaveTo="transform translate-x-2 scale-[0.98] opacity-0"
      >
        {isMenuPopupOpened === CustomizerMenuType.Captions && (
          <ClipCustomizerCaptionsPopup onEditCaptions={closePopup} />
        )}
        {isMenuPopupOpened === CustomizerMenuType.Templates && <ClipCustomizerTemplatesPopup />}
        {isMenuPopupOpened === CustomizerMenuType.Layout && <ClipCustomizerLayoutPopup />}
        {isMenuPopupOpened === CustomizerMenuType.Background && <ClipCustomizerBackgroundPopup />}
      </Transition>
    </div>
  );
}

export default memo(ClipCustomizerMenu);
