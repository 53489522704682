import { useCallback, useMemo } from 'react';
import HlsPlayerV2 from './HlsPlayerV2';
import { getTrackSrc } from '@/libs/utils';
import { getVideoManifestUrl } from '@/stores/core';
import { updateSharedAPIWithId } from '@/stores/sharedAPI/sharedAPI';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function MainPlayer({
  id,
  videoRef,
  onLoadFailed,
  isHidden = false,
  broadcastId,
  eventId
}: {
  id: string;
  videoRef: React.RefObject<HTMLVideoElement>;
  onLoadFailed: () => void;
  isHidden?: boolean;
  broadcastId: string;
  eventId: string;
}) {
  const url = useMemo(() => getVideoManifestUrl(broadcastId, eventId), [broadcastId, eventId]);
  const trackSrc = useMemo(() => getTrackSrc(eventId!, broadcastId!), [eventId, broadcastId]);

  const { clipId, clipData } = useClipsContext();

  const onMediaAttached = useCallback(() => {
    videoRef.current!.currentTime = clipData.asset_metadata.start;
  }, [videoRef, clipData]);

  const onLoadedData = useCallback(() => {
    if (!videoRef.current) return;

    updateSharedAPIWithId(clipId, { mainPlayerRef: videoRef.current });
  }, [videoRef]);

  return (
    <div className="relative h-full w-full overflow-hidden rounded-md">
      <HlsPlayerV2
        videoRef={videoRef}
        url={url}
        trackSrc={trackSrc}
        id={id}
        onError={onLoadFailed}
        onMediaAttached={onMediaAttached}
        videoProps={{
          onLoadedData,
          className: isHidden ? 'hidden' : 'w-full h-full'
        }}
      />
    </div>
  );
}
