import React, { useEffect } from 'react';
import SearchExpandableInput from '../SearchExpandableInput';
import MultiSelect from './MultiSelect';

interface PopoverPanelProps {
  selected: any[];
  onSelect: (option: any) => void;
  searchable?: boolean;
  searchValue?: string;
  setSearchValue?: (value: string) => void;
  onPopoverOpen?: () => void;
  options: { label: string; value: any }[];
  loading?: boolean;
  Loader?: React.ReactNode;
  itemRenderer?: (value: any) => React.ReactNode;
}

export default function PopoverPanel({
  searchable,
  searchValue,
  setSearchValue,
  onPopoverOpen,
  options,
  selected,
  onSelect,
  loading,
  Loader,
  itemRenderer
}: PopoverPanelProps) {
  useEffect(() => {
    onPopoverOpen?.();
  }, [onPopoverOpen]);

  return (
    <>
      {searchable && (
        <div className="relative flex items-center p-2">
          <SearchExpandableInput searchTerm={searchValue} onUpdate={setSearchValue} isAlwaysExpanded />
        </div>
      )}
      <div className="m-0.5 h-full max-h-60 space-y-1 overflow-y-auto text-sm">
        {loading ? (
          Loader || <div className="flex h-20 w-full items-center justify-center"> Loading... </div>
        ) : (
          <MultiSelect
            options={options}
            selected={selected}
            onSelect={onSelect}
            itemRenderer={itemRenderer}
            searchValue={searchValue}
          />
        )}
      </div>
    </>
  );
}
