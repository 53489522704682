import React, { useState, useEffect } from 'react';
import { userSpeakerPartialUpdate } from '@goldcast/api/content';
import { useParams } from 'react-router-dom';
import EditableSpeakerRow from './EditableSpeakerRow';
import SpeakersListSkeleton from './SpeakersListSkeleton';
import Button from '../Button/Button';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { getUnusedSpeakers } from '@/context/TranscriptContext/TranscriptContextUtils';
import { showErrorToast } from '@/libs/toast/toast';
import { useAppContext } from '@/context/AppContext/AppContext';

export default function SpeakersList({
  speakerId,
  openIdentifySpeakersDialog
}: {
  speakerId: string | null;
  openIdentifySpeakersDialog: () => void;
}) {
  const { clipId } = useParams<{ clipId: string }>();

  const [speakers, setSpeakers] = useState<SpeakerWithDetails[]>([]);
  const [unusedSpeakers, setUnusedSpeakers] = useState<SpeakerWithDetails[]>([]);
  const [editingSpeakerId, setEditingSpeakerId] = useState<string | null>(speakerId);
  const [isLoadingSpeakers, setIsLoadingSpeakers] = useState(true);

  const transcriptStore = useTranscriptContext();
  const { logger } = useAppContext();

  useEffect(() => {
    setIsLoadingSpeakers(false);
    initMergeSpeakers();
  }, [transcriptStore.speakersWithDetails]);

  function initMergeSpeakers() {
    setSpeakers([...transcriptStore.usedSpeakers]);
    setUnusedSpeakers(getUnusedSpeakers(transcriptStore.speakersWithDetails));
  }

  const updateSpaker = (index: number) => (speaker: SpeakerWithDetails) => {
    setSpeakers(s => {
      const speakers = [...s];
      speakers[index] = speaker;
      return speakers;
    });

    emitSpeakerUpdate(speaker);
  };

  const emitSpeakerUpdate = async (speaker: SpeakerWithDetails) => {
    const { first_name, last_name, title, company, profile_picture_url, pronouns } = speaker;
    try {
      await userSpeakerPartialUpdate({
        id: speaker.id,
        body: { first_name, last_name, title, company, profile_picture_url, pronouns }
      });
    } catch (error) {
      logger.error(error);
      showErrorToast('Looks like there was a hiccup updating user info. Double-check your changes and retry.');
    }
    if (transcriptStore)
      EventBus.dispatch(CustomEvents.SpeakersChanged, {
        updatedSpeakers: {
          ...transcriptStore?.speakersWithDetails,
          [speaker.key]: {
            ...transcriptStore.speakersWithDetails[speaker.id],
            ...speaker
          }
        }
      });
  };

  return isLoadingSpeakers ? (
    <SpeakersListSkeleton count={speakers.length + unusedSpeakers.length} />
  ) : (
    <div className="space-y-0 pt-2">
      <div className="pb-2">
        <div className="mb-1 flex items-center justify-between">
          <div className="text-xs">Speakers</div>
          {!clipId && (
            <Button
              buttonSize="small"
              variation="filled"
              title="Play a clip of each speaker to recognize and label them."
              trackingId="identify-speakers-button"
              onClick={openIdentifySpeakersDialog}
            >
              Play Clips to Identify Speakers
            </Button>
          )}
        </div>
        {speakers.map((speaker, index) => (
          <EditableSpeakerRow
            speaker={speaker}
            key={speaker.key}
            allSpeakers={Object.values(transcriptStore.speakersWithDetails)}
            editingSpeakerId={editingSpeakerId}
            canMerge={true}
            setEditingSpeakerId={setEditingSpeakerId}
            onUpdate={updateSpaker(index)}
          />
        ))}
      </div>
      {!!unusedSpeakers.length && (
        <div className="mt-6 pb-2">
          <div className="mb-1 block px-1 text-xs">Inactive</div>
          {unusedSpeakers.map((speaker, index) => (
            <EditableSpeakerRow
              speaker={speaker}
              key={speaker.key}
              allSpeakers={speakers}
              editingSpeakerId={editingSpeakerId}
              canDelete={true}
              setEditingSpeakerId={setEditingSpeakerId}
              onUpdate={updateSpaker(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
}
