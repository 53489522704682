import { BRAND_KIT_ACCEPT_FORMATS } from '../../constants';
import useBrandKitComponent from '../useBrandKitComponent';
import TemplateBackgroundPreview from './TemplateBackgroundPreview';
import FilesInput from '@/components/atoms/inputs/FilesInput/FilesInput';
import { showErrorToast } from '@/libs/toast/toast';
import styles from '../../BrandKit.module.scss';

export default function TemplateBackground(): JSX.Element {
  const { brandKitStore, onFilesUpload, deleteItem } = useBrandKitComponent('background', 'Template Background images');

  function uploadFiles(files: File[]) {
    onFilesUpload(files).catch(() =>
      showErrorToast("Hmm, your background couldn't upload. Check the file size and try again.")
    );
  }

  return (
    <div className="relative flex flex-wrap gap-3 py-2">
      <div className="flex aspect-[16/11] w-56 flex-col items-center justify-center !text-slate-500">
        <FilesInput
          onFilesUpload={uploadFiles}
          accept={BRAND_KIT_ACCEPT_FORMATS.background}
          label="Add Template Background"
          icon="IconTexture"
          containerClassNames={styles['brand-picker']}
          labelClassNames="text-xs"
          iconProps={{
            size: 20,
            stroke: 1.5
          }}
        />
      </div>
      {brandKitStore.background.map(background => (
        <TemplateBackgroundPreview key={background} imageUrl={background} onDelete={deleteItem} />
      ))}
    </div>
  );
}
