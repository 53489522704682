import { Clip } from '@/domains/asset';
import { roundToNDecimalPlaces } from '@/libs/utils';
import { currentClip } from '@/stores/clip';

export function getMainPlayerTime(
  mainPlayerVideo: HTMLVideoElement | null,
  mainSectionCurrentTime: number,
  clipStart: number,
  clipId: string
): number {
  if (!mainPlayerVideo) return roundToNDecimalPlaces(mainSectionCurrentTime - clipStart, 3);
  const deletedSecondsInClip = getDeletedSecondsBeforeTime(mainPlayerVideo.currentTime, clipId);
  if (deletedSecondsInClip === 0) return roundToNDecimalPlaces(mainPlayerVideo.currentTime - clipStart, 3);
  // Progress bar time should be main player - deleted seconds - start of the clip
  const time = mainPlayerVideo.currentTime - deletedSecondsInClip - clipStart;
  // timeOffset is to prevent progress bar jumps when it reaches to deleted time, since we are
  // updating player time to the next non-delete time.
  const timeOffset =
    deletedSecondsInClip + time === mainSectionCurrentTime ? mainPlayerVideo.currentTime - mainSectionCurrentTime : 0;
  return roundToNDecimalPlaces(time - timeOffset, 3);
}

/**
 * Return count of deleted seconds from the clip before specific time
 * @param time before we want to get deleted seconds
 * @param clip to get deleted seconds from
 * @returns count of deleted seconds in clip before {time}
 */
export function getDeletedSecondsBeforeTime(time: number, clipId: string, clip?: Clip): number {
  if (!clip) {
    clip = currentClip.getSnapshot()[clipId];
  }

  if (!clip?.asset_metadata?.excludes) return 0;

  return clip.asset_metadata.excludes.reduce((totalTime, [start, end]) => {
    if (start < time) {
      totalTime += end - start;
    }
    return totalTime;
  }, 0);
}
