import { Fragment, memo } from 'react';
import { Transition } from '@headlessui/react';
import IconButton from '@/components/atoms/Button/IconButton';

function ContentSettingsWrapper({ children, onClose }: { children: JSX.Element; onClose: () => void }) {
  return (
    <>
      <Transition
        appear
        show
        as={Fragment}
        enter="transition ease duration-500 transform"
        enterFrom="opacity-0 translate-x-20"
        enterTo="opacity-100 translate-x-0"
        leave="transition ease duration-500 transform"
        leaveFrom="opacity-100 translate-x-0"
        leaveTo="opacity-0 translate-x-20"
      >
        <div className="fixed inset-0 z-[999] overflow-hidden bg-white/90">
          <div className="fixed inset-0 m-2 ml-auto flex max-w-xl flex-col overflow-hidden rounded-md border bg-white shadow-[0_0_5px_5px_rgba(0,0,0,0.05)]">
            <div className="flex items-center justify-between border-b bg-white pb-2 pl-5 pr-1.5 pt-1.5">
              <p>Settings</p>
              <IconButton icon="IconX" variation="text" size="large" onClick={onClose} trackingId="close-button" />
            </div>
            {children}
          </div>
        </div>
      </Transition>
    </>
  );
}

export default memo(ContentSettingsWrapper);
