import { AssetsListAPIQueryParams } from '@goldcast/api/content';
import React from 'react';
import SearchExpandableInput from '../uiComponents/SearchExpandableInput';
import { useUpdateQueryParams } from '@/hooks/url/useUpdateQueryParams';
import { useQueryParams } from '@/hooks/url/useQueryParams';

export default function AssetsSearch({ searchInputKey, disabled }) {
  const { updateQueryParams } = useUpdateQueryParams<AssetsListAPIQueryParams>();
  const { search } = useQueryParams<AssetsListAPIQueryParams>({});

  const onSearchTermUpdate = value => {
    updateQueryParams({
      search: value ? value : undefined
    });
  };

  return (
    <SearchExpandableInput searchTerm={search} onUpdate={onSearchTermUpdate} key={searchInputKey} disabled={disabled} />
  );
}
