import { useCallback, useState } from 'react';
import { BRAND_KIT_INITIAL_COLOR } from '../../constants';
import useBrandKitComponent from '../useBrandKitComponent';
import ColorPreview from './ColorPreview';
import { ColorPicker } from '@/components/atoms/ColorPicker/ColorPicker';
import Icon from '@/components/atoms/Icon';
import { storeBrandKitChanges, updateIndividualBrandKitConfig } from '@/stores/brandKit';
import { classnames } from '@/libs/utils';
import { showErrorToast } from '@/libs/toast/toast';
import classes from './Colors.module.scss';

export default function Colors(): JSX.Element {
  const { brandKitStore } = useBrandKitComponent('colors', 'Colors');
  const [isEditing, setIsEditing] = useState(false);
  const [currentColor, setCurrentColor] = useState(BRAND_KIT_INITIAL_COLOR);

  const updateCurrentColor = useCallback(
    hex => {
      setCurrentColor(hex);
      if (isEditing) {
        updateIndividualBrandKitConfig('colors', [
          ...brandKitStore.colors.slice(0, brandKitStore.colors.length - 1),
          hex
        ]);
      } else {
        updateIndividualBrandKitConfig('colors', [...brandKitStore.colors, hex]);
        setIsEditing(true);
      }
    },
    [brandKitStore.colors, isEditing]
  );

  // Delete by index to handle same values
  function deleteColor(index: number) {
    updateIndividualBrandKitConfig('colors', [
      ...brandKitStore.colors.slice(0, index),
      ...brandKitStore.colors.slice(index + 1)
    ]);
    storeBrandKitChanges();
  }

  function storeColor() {
    setIsEditing(false);
    storeBrandKitChanges().catch(() => {
      showErrorToast("Storing this color didn't go as planned. Make sure it's in a valid format and try again.");
    });
  }

  return (
    <div className="relative flex flex-wrap gap-3 py-2">
      <div className="flex aspect-[16/11] w-56 flex-col items-center justify-center text-slate-500">
        <ColorPicker
          onChange={updateCurrentColor}
          hex={currentColor}
          onClose={storeColor}
          pickerClassNames="top-4"
          classNames="rounded-lg bg-white"
        >
          <div
            className={`relative flex h-full flex-col items-center justify-center rounded-lg ${classes['colors-container']}`}
          >
            <Icon
              name="IconColorSwatch"
              size={25}
              stroke={1.5}
              className={classnames('text-slate-500', classes['colors-icon'])}
            />

            <p className="mt-1 w-32 text-center text-xs">Add Color</p>
          </div>
        </ColorPicker>
      </div>
      {brandKitStore.colors.map((color, index) => (
        <ColorPreview key={index} hex={color} onDelete={() => deleteColor(index)} />
      ))}
    </div>
  );
}
