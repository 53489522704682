import Colors from './components/Colors/Colors';
import Fonts from './components/Fonts/Fonts';
import IntroOutro from './components/IntroOutro/IntroOutro';
import TemplateBackground from './components/TemplateBackground/TemplateBackground';
import BrandTone from './components/BrandTone/BrandTone';
import { BrandKitConfigItem } from './types';

export const BRAND_KIT_DEFAULT_CONFIGURATION: Record<string, BrandKitConfigItem> = {
  brand_tone: {
    title: 'Voice Profile',
    description: 'Customize our AI to to match your style and tone',
    renderer: <BrandTone />
  },
  colors: {
    title: 'Color',
    description: 'Add your brand colors and use them in your clips',
    renderer: <Colors />
  },
  fonts: {
    title: 'Font',
    description: 'Add custom fonts and use them in captions and speaker labels',
    renderer: <Fonts />
  },
  background: {
    title: 'Template Background',
    description: 'Use a custom template background to brand your clips',
    renderer: <TemplateBackground />
  },
  intro_outro: {
    title: 'Intro/Outro Video',
    description: 'Add short, branded intro or outro videos to use with your clips',
    renderer: <IntroOutro />
  }
};

export const BRAND_KIT_INITIAL_COLOR = '#ffffff';

export const BRAND_KIT_ACCEPT_FORMATS = {
  fonts: '.otf,.ttf',
  background: '.jpeg,.png',
  introOutro: '.mp4,.mov'
};

export const BRAND_KIT_PATH = 'content-lab/filestack/brand_kit';
export const BRAND_KIT_MAX_FILES = 10;
export const BRAND_KIT_MAX_FILE_SIZE = 50; // max size set to 50MB

export const FONT_VALIDATION_ERROR_TTF = 'This file might be corrupted, please update the file and try again.';
export const FONT_VALIDATION_ERROR_OTF =
  'This font isn’t supported. Convert your font to TTF and upload again. If it’s still not working, the file might be corrupted.';
