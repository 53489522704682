import React, { createContext, useContext, useEffect, useState } from 'react';
import { defaultState } from './AppContextUtils';
import { AppContextState } from './AppContextTypes';
import { initializeFetchers } from '@/services/fetch';

const AppContext = createContext<AppContextState>(defaultState);

export function AppContextProvider({ children, ...props }) {
  const [initializingFetchers, setInitializingFetchers] = useState(true);
  const { logger, analytics, commit, dispatch, getters, adminAppRouter, triggerAdminAppToast, ...restProps } = props;

  const adminAppStore = {
    commit,
    dispatch,
    getters,
    user: props.getters?.['user/getCurrentUser'],
    userCognitoDetails: props.getters?.['user/getCurrentUserCognitoDetails'],
    currentOrg: props.getters?.['org/getCurrentOrg']
  };

  useEffect(() => {
    try {
      initializeFetchers(adminAppStore);
      setInitializingFetchers(false);
    } catch (e) {
      console.warn('Fetchers may already be initialized : ', e);
    }

    // Redirect to admin app if user is on content.goldcast.io
    if (document.location.href.includes('content.goldcast.io')) {
      document.location.href = 'https://admin.goldcast.io/content';
      return;
    }
  }, []);

  if (initializingFetchers) {
    return null;
  }

  return (
    <AppContext.Provider
      value={{
        logger,
        analytics: {
          ...analytics,
          track: (...args) => {
            if (analytics?.track) {
              analytics.track(...args);
            }

            if (logger?.track) {
              logger.track(...args);
            }
          }
        },
        adminAppRouter,
        triggerAdminAppToast,
        adminAppStore
      }}
    >
      {React.cloneElement(children, {
        ...restProps
      })}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
