import React from 'react';
import { ReactComponent as GoldcastMark } from '@/assets/logo/goldcast-mark.svg';

export interface ErrorContainerProps {
  errorMessage: string;
  errorDisplayCode?: string | number;
  children?: React.ReactNode;
}

export default function ErrorContainer({ errorMessage, errorDisplayCode, children }: ErrorContainerProps): JSX.Element {
  return (
    <div className="flex h-[calc(100vh-4.25rem)] w-[100vw] items-center justify-center">
      <div className="absolute left-[20%] top-[25%] aspect-square w-[8vw] rounded-full bg-deep-orange blur-[8vw]" />
      <div className="absolute bottom-[25%] right-[20%] aspect-square w-[8vw] rounded-full bg-indigo-700 blur-[8vw]" />
      <div className="z-20 w-96 space-y-4 rounded-2xl border border-slate-200 bg-white p-8 pb-7">
        <div className="flex w-full items-center space-x-2">
          <GoldcastMark className="h-7 w-7" />
          {errorDisplayCode && <h1 className="border-l border-l-slate-300 pl-3 text-2xl">{errorDisplayCode}</h1>}
        </div>
        <div>
          <p>{errorMessage}</p>
        </div>
        {children}
      </div>
    </div>
  );
}
