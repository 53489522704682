import React, { useMemo, useState, useSyncExternalStore } from 'react';
import { useCaptionContainerRef } from '../../ClipPlayer/useCaptionContainerRef';
import PreviewSpeakerVideoCanvas from './PreviewSpeakerVideoCanvas';
import CanvasPreviewCaptions from './CanvasPreviewCaptions';
import StaticCanvasPreview from './StaticCanvasPreview';
import CanvasPlayerDraggableCaptions from '../CanvasPlayerDraggableCaptions/CanvasPlayerDraggableCaptions';
import { CAPTION_HORIZONTAL_SPACE_FACTOR } from '../constants';
import { PreviewTemplate } from '../../SideBar/types';
import { getSizeConfig } from '@/libs/sharedAPI/sizeConfig/SizeConfigFactory';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { getFontSize } from '@/libs/sharedAPI/common';

export default function CanvasPreview({
  template,
  mainPlayer,
  secondaryPlayers,
  currentSpeaker
}: {
  template: PreviewTemplate;
  mainPlayer: React.MutableRefObject<HTMLVideoElement | null>;
  secondaryPlayers: React.RefObject<HTMLVideoElement>[];
  currentSpeaker?: SpeakerWithDetails;
}) {
  const { clipId } = useClipsContext();
  const { clipSize, showCaption, clipLayout } = useMemo(() => {
    const clipSize = template.size;
    const showCaption = !!template.subtitle;
    const clipLayout = template.layout;
    return { clipSize, showCaption, clipLayout };
  }, [template.size, template.subtitle]);

  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const isCaptionsOverlayDisabled = featureFlags[FeatureFlagKeys.Use_CL_Captions_Overlay] === false;

  const showSpeakerLabels = template.magicLayout?.showSpeakerLabels && template.layout !== 'DEFAULT';

  // config used for video canvas
  const videoSizeConfig = useMemo(
    () => getSizeConfig(clipSize, clipId, clipLayout, 0.5),
    [clipSize, clipId, clipLayout]
  );

  const [captionContainerSizes, setCaptionContainerSizes] = useState({
    width: 0,
    height: 0
  });
  const { captionContainerRef } = useCaptionContainerRef(setCaptionContainerSizes, template);

  const [captionFontSize, captionHorizontalSpace, captionsHeight] = useMemo(() => {
    const scale = template.caption_styles?.scale || 1;
    const fontSize = getFontSize({ playerHeight: captionContainerSizes.height, scale, clipSize });

    return [
      fontSize,
      fontSize * CAPTION_HORIZONTAL_SPACE_FACTOR,
      ((videoSizeConfig.getVideoBottomPadding() - videoSizeConfig.getVideoSurroundPadding()) /
        videoSizeConfig.getHeight()) *
        captionContainerSizes.height
    ];
  }, [videoSizeConfig, captionContainerSizes]);

  const backgroundStyle = useMemo(() => {
    if (!template.magicLayout?.backgroundImage)
      return {
        backgroundColor: template.magicLayout?.backgroundColor
      };
    return { backgroundImage: `url(${template.magicLayout?.backgroundImage})` };
  }, [template.magicLayout?.backgroundImage, template.magicLayout?.backgroundColor]);

  const visibleSecondaryPlayers = useMemo(
    () =>
      secondaryPlayers
        .filter(player => player.current)
        .sort((a, b) => {
          if (!a.current || !b.current) return 0;
          return a.current.id.localeCompare(b.current.id);
        }),
    [secondaryPlayers]
  );

  return (
    <div
      className="absolute top-0 h-full w-full overflow-hidden rounded-md bg-cover bg-center"
      style={{
        ...backgroundStyle
      }}
      id="player-container-preview"
    >
      <div className="relative grid h-full items-start " data-testid="composite-player" ref={captionContainerRef}>
        {/* Canvas for rendering the video elements on screen */}

        <PreviewSpeakerVideoCanvas
          mainPlayer={mainPlayer}
          clipMetadata={template}
          currentSpeaker={currentSpeaker}
          secondaryPlayers={secondaryPlayers}
        />

        {showSpeakerLabels && (
          <StaticCanvasPreview
            mainPlayer={mainPlayer}
            secondaryPlayers={visibleSecondaryPlayers}
            currentSpeaker={currentSpeaker}
            clipMetadata={template}
          />
        )}

        {!isCaptionsOverlayDisabled && !!captionsHeight && (
          <CanvasPlayerDraggableCaptions
            captionFontSize={captionFontSize}
            clipMetadata={template}
            isPreview={true}
            clipId={clipId}
            isDisabled={true}
          />
        )}

        {showCaption && captionHorizontalSpace >= 0 && template.layout !== 'AUDIOGRAM' && isCaptionsOverlayDisabled && (
          <div className="absolute bottom-0 w-full">
            <div
              className="mx-auto flex h-full w-full max-w-[90%] items-center justify-center"
              style={{
                fontSize: `${captionFontSize}px`,
                height: `${captionsHeight}px`
              }}
            >
              <div
                className="flex flex-wrap justify-center whitespace-normal text-center"
                style={{ lineHeight: `${(5 * captionFontSize) / 4}px` }}
              >
                <CanvasPreviewCaptions captionHorizontalSpace={captionHorizontalSpace} template={template} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
