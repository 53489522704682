import { Slide, ToastOptions, TypeOptions, toast } from 'react-toastify';
import { IconAlertTriangle, IconCircleCheckFilled, IconExclamationCircle } from '@tabler/icons-react';
import { ActionToast } from './types';

export const COMMON_TOAST_PARAMS: Partial<ToastOptions> = {
  autoClose: 6000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  transition: Slide,
  position: toast.POSITION.TOP_CENTER
};

export const PROGRESS_TOAST_PARAMS: Partial<ToastOptions> = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: false,
  closeButton: false,
  transition: Slide
};

export const ACTION_TOAST_CONFIG: Record<TypeOptions, ActionToast> = {
  success: {
    fn: toast.success,
    icon: <IconCircleCheckFilled size={24} className="tw-text-green-500" />
  },
  error: {
    fn: toast.error,
    icon: <IconExclamationCircle size={24} className="tw-text-rose-500" />
  },
  warning: {
    fn: toast.warning,
    icon: <IconAlertTriangle size={24} className="tw-text-deep-orange-400" />
  },
  info: {
    fn: toast.info
  },
  default: {
    fn: toast
  }
};
