import { IconCategory2 } from '@tabler/icons-react';
import { memo } from 'react';
import { POST_TYPE_LABELS } from '../constants';

function PostAssetGenerationEmptyState({ postType }: { postType: string }) {
  return (
    <div className="flex h-full grow items-center justify-center overflow-y-auto border-r border-r-slate-200 px-10 pb-16 pt-8">
      <div className="flex w-48 flex-col items-center justify-center">
        <IconCategory2 className="h-8 w-8 text-slate-300" />
        <div>
          <div className="mt-1 text-center text-slate-400">Almost there!</div>
          <div className="mt-2.5 text-center text-sm leading-tight text-slate-400">
            Your {POST_TYPE_LABELS[postType]?.toLowerCase()} will be ready in a few moments.
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(PostAssetGenerationEmptyState);
