import { memo } from 'react';
import { CLIP_MAX_DURATION_ERROR, CLIP_MIN_DURATION_ERROR } from './constants';
import Button from '@/components/atoms/Button/Button';
import { classnames } from '@/libs/utils';

function CreateClipPopup({ createClipPopup, createClipWrapper, transcriptSelection, onCreate, isCreatingClip }) {
  return (
    <div
      ref={createClipPopup}
      className="invisible absolute right-0 z-50 mt-2 w-56 origin-top-right select-none rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-[0.05] focus:outline-none"
    >
      <div className="flex w-full items-center justify-between space-x-1.5 p-2">
        <div className="flex grow items-center justify-center text-center">
          <div className="text-xs text-slate-500">{transcriptSelection?.duration}</div>
        </div>
        <div ref={createClipWrapper}>
          <Button
            variation="filled"
            trackingId="create-clip-button"
            onClick={onCreate}
            className={classnames({ '!cursor-progress': isCreatingClip })}
            disabled={
              transcriptSelection?.duration === CLIP_MIN_DURATION_ERROR ||
              transcriptSelection?.duration === CLIP_MAX_DURATION_ERROR ||
              isCreatingClip
            }
          >
            {isCreatingClip ? 'Creating...' : 'Create Clip'}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default memo(CreateClipPopup);
