import React, { createContext, useState, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { MediaContent } from '@goldcast/api/content';
import { PostGenerationState, PostAutoGenerationProviderProps, StatusListener } from './PostGenerationContextTypes';
import EventBus from '@/libs/eventBus/eventBus';
import { POST_TYPES, PostLabelMap } from '@/Pages/PostsListPage/constants';
import { CustomEvents } from '@/libs/eventBus/constants';
import { usePusherContext } from '@/context/PusherContext/PusherContext';
import { PUSHER_STATES } from '@/context/PusherContext/PusherContextConstants';
import { core } from '@/stores/core';

const defaultState: PostGenerationState = {
  isGeneratingBlogAssets: true,
  isGeneratingSocialAssets: true,
  isGeneratingEmailAssets: true,
  isGeneratingTakeAwayAssets: true
};

const PostGenerationContext = createContext<boolean>(true);

const statusListeners: StatusListener[] = [
  {
    event: CustomEvents.SocialPostGenerationStatusUpdated,
    stateKey: 'isGeneratingSocialAssets',
    doneState: PUSHER_STATES.SOCIAL_POST_GENERATION_DONE
  },
  {
    event: CustomEvents.BlogPostGenerationStatusUpdated,
    stateKey: 'isGeneratingBlogAssets',
    doneState: PUSHER_STATES.BLOG_POST_GENERATION_DONE
  },
  {
    event: CustomEvents.EmailPostGenerationStatusUpdated,
    stateKey: 'isGeneratingEmailAssets',
    doneState: PUSHER_STATES.EMAIL_POST_GENERATION_DONE
  },
  {
    event: CustomEvents.TakeawayPostGenerationStatusUpdated,
    stateKey: 'isGeneratingTakeAwayAssets',
    doneState: PUSHER_STATES.TAKEAWAY_POST_GENERATION_DONE
  }
];

export const PostAutoGenerateProvider: React.FC<PostAutoGenerationProviderProps> = ({ children }) => {
  const [postGenerationState, setPostGenerationState] = useState<PostGenerationState>(defaultState);
  const [postType, setPostType] = useState<string>('');

  const location = useLocation();
  const { isPusherConnected } = usePusherContext();
  const content = core.getSnapshot().content;

  const updateState = (type: string, statusKey: keyof MediaContent) => {
    if (content?.[statusKey] !== 'PROCESSING') {
      setPostGenerationState(prevState => ({
        ...prevState,
        [`isGenerating${type}Assets`]: false
      }));
    }
  };

  useEffect(() => {
    const pathName = location.pathname.split('/')[3];
    const postLabel = PostLabelMap[pathName];
    if (postLabel) {
      setPostType(postLabel);
    }
  }, [location.pathname]);

  useEffect(() => {
    switch (postType) {
      case POST_TYPES.SOCIAL:
        updateState('Social', 'social_post_generation_status');
        break;
      case POST_TYPES.BLOG:
        updateState('Blog', 'blog_post_generation_status');
        break;
      case POST_TYPES.EMAIL:
        updateState('Email', 'email_post_generation_status');
        break;
      case POST_TYPES.TAKEAWAYS:
        updateState('TakeAway', 'takeaway_post_generation_status');
        break;
      default:
        break;
    }
  }, [postType, content]);

  useEffect(() => {
    if (!isPusherConnected) {
      return;
    }

    statusListeners.forEach(({ event, stateKey, doneState }) => {
      const listener = (data: { state: string }) => {
        if (data && data.state === PUSHER_STATES.BLOG_POST_GENERATION_DONE) {
          EventBus.dispatch(CustomEvents.ReloadAllClips);
        }
        setPostGenerationState(prevState => ({
          ...prevState,
          [stateKey]: data.state !== doneState
        }));
      };
      EventBus.on(event, listener);
      return () => EventBus.off(event, listener);
    });
  }, [isPusherConnected]);

  const isGeneratingAssets = useMemo(() => {
    if (!postType) return true;

    switch (postType) {
      case POST_TYPES.SOCIAL:
        return postGenerationState.isGeneratingSocialAssets;
      case POST_TYPES.BLOG:
        return postGenerationState.isGeneratingBlogAssets;
      case POST_TYPES.EMAIL:
        return postGenerationState.isGeneratingEmailAssets;
      case POST_TYPES.TAKEAWAYS:
        return postGenerationState.isGeneratingTakeAwayAssets;
      default:
        return false;
    }
  }, [postGenerationState, postType]);

  return <PostGenerationContext.Provider value={isGeneratingAssets}>{children}</PostGenerationContext.Provider>;
};

export const usePostGenerationContext = (): boolean => {
  return useContext(PostGenerationContext);
};
