import { useState, useCallback, useEffect, useRef, useSyncExternalStore } from 'react';
import * as filestack from 'filestack-js';
import moment from 'moment';
import {
  AssetsContentUploadCreateRequestBody,
  MediaContentConfig,
  assetsContentUploadCreate,
  assetsMediaContentConfigCreate
} from '@goldcast/api/content';
import { useNavigate, useParams } from 'react-router-dom';
import { FilestackUploadToken } from '../GenerateContent/types';
import { getRemainingProcessingTimeInSec } from '../GenerateContent/utils';
import { ASSETS_FILE_PATH, ContentError, ContentErrorTypeMap, ContentStatesEnum } from '../GenerateContent/constants';
import ContentToast from '../GenerateContent/ContentToast';
import { useVideoImportContext } from './VideoImportContext';
import { getLocalFtuxConfig } from './localFtuxConfig.util';
import { getFileKey } from '@/libs/file';
import { getEnvConfig } from '@/constants';
import { currentUser } from '@/stores/user';
import { classnames } from '@/libs/utils';
import useContentUploadHook from '@/hooks/useContentUploadHook';
import useFreeTrialHook from '@/hooks/useFreeTrialHook';
import { CustomEvents } from '@/libs/eventBus/constants';
import { useAppContext } from '@/context/AppContext/AppContext';
import { usePusherContext } from '@/context/PusherContext/PusherContext';
import { getContentStoreConfig, handleBeforeUnload } from '@/Pages/Sessions/NewContentModal/util';
import EventBus from '@/libs/eventBus/eventBus';
import AlertDialog from '@/components/organisms/AlertDialog';
import useAnalytics from '@/hooks/useAnalytics';
import { disableFirstTimeUser, firstTimeUserStore } from '@/stores/firstTimeUserStore';

const SESSION_STORAGE_PROCESSING_COMPLETE_TIMES = 'processingCompleteTimes';

export default function VideoImportProcessingPage() {
  const { broadcastId } = useParams<{ broadcastId: string }>();
  const [currentProcessingTime, setCurrentProcessingTime] = useState<number | null>(null);
  const isFirstTimeUser = useSyncExternalStore(firstTimeUserStore.subscribe, firstTimeUserStore.getSnapshot);

  const {
    getContentUploadById,
    updateContentState,
    updateTotalPercentageUploaded,
    handleContentUploadError,
    removeContentUploadEntry,
    changeContentUploadStatus,
    getUploadedMediaContent,
    handleConfettiState
  } = useContentUploadHook();
  const { incrementDurationUsed } = useFreeTrialHook();
  const { logger } = useAppContext();
  const navigate = useNavigate();
  const filesIds = useRef({});
  const { trackVideoUpload } = useAnalytics();

  const uploadToken = useRef<FilestackUploadToken>({});
  const isUploadCancelled = useRef<boolean>(false);

  const contentUpload = getContentUploadById(broadcastId);
  const {
    contentState,
    file: selectedFile,
    projectId,
    contentId,
    duration,
    totalPercentageUploaded,
    error
  } = contentUpload;
  const { isPusherConnected } = usePusherContext();
  const { mediaContentConfigRef } = useVideoImportContext();

  const client = filestack.init(getEnvConfig('FILESTACK_API_KEY'));

  const trackFilestackError = useCallback(
    filestackError =>
      trackVideoUpload({
        uploadType: 'Upload',
        status: 'Failed',
        failureReason: `${filestackError?.message || ''} ${filestackError?.details?.data?.error}`
      }),
    [trackVideoUpload]
  );

  useEffect(() => {
    client.on('upload.error', trackFilestackError);

    return () => {
      client.off('upload.error', trackFilestackError);
    };
  }, [client, trackFilestackError]);

  const UPLOAD_CONFIG = {
    retry: 3,
    timeout: 60000,
    onProgress: (progress: { totalPercent: number }) => {
      updateTotalPercentageUploaded(progress.totalPercent, contentId);
    }
  };

  const cancelUpload = () => {
    if (window.confirm('Are you sure? Aborting upload will discard all changes added during this upload.')) {
      isUploadCancelled.current = true;
      uploadToken.current.cancel?.();
      updateContentState(ContentStatesEnum.Cancelled, contentId);
    }
  };

  const generateUuids = () => {
    if (!selectedFile) return;
    filesIds.current[getFileKey(selectedFile.name)] = {
      ...filesIds.current[getFileKey(selectedFile.name)],
      projectId,
      contentId,
      duration
    };
  };

  const uploadFileContentUsingFileStack = () => {
    if (!selectedFile) return;
    uploadToken.current = {};
    return client.upload(
      selectedFile,
      UPLOAD_CONFIG,
      getContentStoreConfig(ASSETS_FILE_PATH, filesIds.current),
      uploadToken.current
    );
  };

  const storeFileDetails = () => {
    return assetsContentUploadCreate({
      body: {
        id: contentId,
        project_id: projectId,
        broadcast_type: 'SIMULIVE',
        title: contentUpload.title,
        media_source_type: 'UPLOAD',
        av_type: contentUpload.av_type,
        recording_date: moment().format('YYYY-MM-DD'),
        organization: currentUser.getSnapshot()?.organization as string,
        duration: duration ? duration.toString() : '0'
      } as AssetsContentUploadCreateRequestBody
    })
      .then(contentUploadCreateRes => {
        assetsMediaContentConfigCreate({
          body: {
            upload: broadcastId,
            ftux_config: getLocalFtuxConfig(contentId) || {}
          } as unknown as MediaContentConfig // @kashish TODO: Fix type once API is updated
        }).then(res => {
          mediaContentConfigRef.current = res;
        });
        return contentUploadCreateRes;
      })
      .catch(() => {
        trackVideoUpload({
          uploadType: 'Upload',
          status: 'Failed',
          failureReason: 'Error upload to API'
        });
        throw new Error('Error upload to API');
      });
  };

  const onUploadFailed = (reason: string) => {
    logger.error(`Failed to upload files. Stack: ${reason}`);
    handleContentUploadError(
      {
        type: ContentError.UPLOAD_FAILED,
        message: reason
      },
      contentId
    );
  };

  const uploadContent = useCallback(async () => {
    generateUuids();
    const originalOnBeforUnload = window.onbeforeunload;
    try {
      window.onbeforeunload = handleBeforeUnload;
      trackVideoUpload({
        uploadType: 'Upload',
        status: 'Started'
      });
      const uploadedContent = await uploadFileContentUsingFileStack();
      if (uploadedContent.status !== 'Stored') {
        onUploadFailed('Failed to upload to Filestack');
      } else {
        const response = await storeFileDetails();
        incrementDurationUsed(Number(response.duration));
        window.onbeforeunload = originalOnBeforUnload;
        trackVideoUpload({
          uploadType: 'Upload',
          status: 'Completed'
        });
        await getUploadedMediaContent(contentId);
        updateContentState(ContentStatesEnum.Processing, contentId);
      }
    } catch (error: any) {
      window.onbeforeunload = originalOnBeforUnload;
      if (isUploadCancelled.current) {
        isUploadCancelled.current = false;
      } else {
        onUploadFailed(
          error.message ||
            'Oops! Something went wrong with the upload. Please try again. If the issue persists, contact support for assistance'
        );
      }
    }
  }, []);

  useEffect(() => {
    if (
      contentState === ContentStatesEnum.Processing &&
      contentUpload.videoProcessingTime &&
      contentUpload.contentUploadedTime
    ) {
      const uploadedTime = new Date(contentUpload.contentUploadedTime);
      const remainingSeconds = Number(contentUpload.videoProcessingTime);
      let processingTimeInterval = setInterval(() => {
        const remainingProcessingTime = getRemainingProcessingTimeInSec(uploadedTime, remainingSeconds);
        setCurrentProcessingTime(remainingProcessingTime);
      }, 1000);

      return () => {
        clearInterval(processingTimeInterval);
      };
    }
  }, [contentUpload.videoProcessingTime, contentUpload.contentUploadedTime, contentState]);

  useEffect(() => {
    if (!broadcastId) {
      return;
    }

    if (contentState === ContentStatesEnum.Generating && contentUpload.clipGenerationEstimatedTime) {
      setCurrentProcessingTime(null);

      const processingCompleteTimes = JSON.parse(
        sessionStorage.getItem(SESSION_STORAGE_PROCESSING_COMPLETE_TIMES) || '{}'
      );
      const storedProcessingCompleteTime = processingCompleteTimes[broadcastId];
      let processingCompleteTime = new Date();

      if (storedProcessingCompleteTime) {
        processingCompleteTime = new Date(storedProcessingCompleteTime);
      } else {
        processingCompleteTimes[broadcastId] = processingCompleteTime;
        sessionStorage.setItem(SESSION_STORAGE_PROCESSING_COMPLETE_TIMES, JSON.stringify(processingCompleteTimes));
      }

      const remainingSeconds = Number(contentUpload.clipGenerationEstimatedTime);
      let processingTimeInterval = setInterval(() => {
        const remainingProcessingTime = getRemainingProcessingTimeInSec(processingCompleteTime, remainingSeconds);
        setCurrentProcessingTime(remainingProcessingTime);
      }, 1000);

      return () => {
        clearInterval(processingTimeInterval);
      };
    }
  }, [contentUpload.clipGenerationEstimatedTime, contentState, broadcastId]);

  useEffect(() => {
    if (contentState === ContentStatesEnum.Cancelled) {
      navigate('/');
      return;
    }

    if (contentState === ContentStatesEnum.Uploading && selectedFile && totalPercentageUploaded === 0) {
      uploadContent();
    }
  }, [contentState, uploadContent, selectedFile, navigate, isPusherConnected, totalPercentageUploaded]);

  useEffect(() => {
    if (!isPusherConnected) {
      return;
    }
    const contentProcessingListener = EventBus.on(CustomEvents.TranscriptionStatusUpdated, data =>
      changeContentUploadStatus(data, ContentStatesEnum.Processing, contentId)
    );
    const clipGenerationListener = EventBus.on(CustomEvents.ClipGenerationStatusUpdated, data =>
      changeContentUploadStatus(data, ContentStatesEnum.Generating, contentId)
    );
    const routeChangeListener = EventBus.on(CustomEvents.NavigateToContentLabHome, () => navigate('/'));
    return () => {
      if (contentProcessingListener) {
        EventBus.off(CustomEvents.TranscriptionStatusUpdated, contentProcessingListener);
      }
      if (clipGenerationListener) {
        EventBus.off(CustomEvents.ClipGenerationStatusUpdated, clipGenerationListener);
      }
      if (routeChangeListener) {
        EventBus.off(CustomEvents.NavigateToContentLabHome, routeChangeListener);
      }
    };
  }, [isPusherConnected, changeContentUploadStatus, contentId, navigate]);

  useEffect(() => {
    if (contentState !== ContentStatesEnum.Uploading && isFirstTimeUser) {
      disableFirstTimeUser();
    }
  }, [contentState, isFirstTimeUser]);

  useEffect(() => {
    if (contentState !== ContentStatesEnum.GeneratingCompleted) return;
    handleConfettiState({ showConfetti: true, contentId });
    updateContentState(ContentStatesEnum.ContentReadyForPreview, contentId);
  }, [contentState, projectId, contentId, navigate]);

  const handleErrorConfirmation = () => {
    removeContentUploadEntry(contentId);
    navigate('/');
  };

  return (
    <>
      <div className="pointer-events-none flex h-full w-full overflow-hidden bg-slate-100">
        <div
          className={classnames(
            'relative mt-1 flex h-full grow flex-col overflow-hidden transition-all duration-[400ms] ease-in-out',
            { 'pl-[2rem]': !isFirstTimeUser }
          )}
        >
          <div
            className={classnames({
              'w-full py-3': true,
              'pt-6': contentState !== ContentStatesEnum.Uploading
            })}
          >
            <ContentToast
              type={ContentStatesEnum.Uploading}
              title="Uploading"
              totalPercentageUploaded={totalPercentageUploaded}
              message="Your upload will continue smoothly even if you navigate away from this page."
              onCancel={cancelUpload}
              isVisible={contentState === ContentStatesEnum.Uploading}
              activeState={contentState}
            />
            <ContentToast
              type={ContentStatesEnum.Processing}
              title="Processing & Analyzing"
              message="We're transcribing your video and extracting key insights."
              isVisible={[ContentStatesEnum.Uploading, ContentStatesEnum.Processing].includes(contentState)}
              activeState={contentState}
              currentProcessingTime={currentProcessingTime}
            />
            <div
              className={classnames(
                ' flex -translate-y-12 justify-center',
                contentState === ContentStatesEnum.Uploading
                  ? 'scale-[0.94]'
                  : contentState === ContentStatesEnum.Processing
                  ? 'scale-[0.97]'
                  : 'pt-6'
              )}
            >
              <ContentToast
                type={ContentStatesEnum.Generating}
                title="Generating Assets"
                message="Almost there! We're flexing our AI muscles to create engaging assets for you."
                isVisible={[
                  ContentStatesEnum.Uploading,
                  ContentStatesEnum.Processing,
                  ContentStatesEnum.Generating
                ].includes(contentState)}
                activeState={contentState}
                currentProcessingTime={currentProcessingTime}
              />
              <ContentToast
                type={ContentStatesEnum.ContentReadyForPreview}
                title="Clips Ready"
                message="Your Clips are ready for preview. We'll wait for you to finish up."
                isVisible={ContentStatesEnum.ContentReadyForPreview === contentState}
                activeState={contentState}
              />
            </div>
          </div>
        </div>
      </div>
      <AlertDialog
        isOpen={error?.type ? true : false}
        heading={(error && ContentErrorTypeMap[error.type]) ?? 'Error'}
        message={error?.message}
        type="error"
        primaryActionTitle="Back to Home"
        handlePrimaryActionClick={handleErrorConfirmation}
      />
    </>
  );
}
