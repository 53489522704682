import { DivProps } from '@/domains/global';

export type DIALOG_SIZE = 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall';

export const DIALOG_WIDTH: Record<DIALOG_SIZE, string> = {
  xlarge: 'max-w-4xl',
  large: 'max-w-2xl',
  medium: 'max-w-xl',
  small: 'max-w-md',
  xsmall: 'max-w-sm'
};

export type DIALOG_TITLE_SIZE = 'large' | 'medium' | 'small';

export const DIALOG_TITLE_SIZE_CLASS: Record<DIALOG_TITLE_SIZE, DivProps['className']> = {
  large: 'text-lg pt-4 pb-3 pl-4',
  medium: 'text-base pt-4 pb-3 pl-4',
  small: 'text-sm p-3'
};
