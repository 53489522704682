import IconButton from '../Button/IconButton';
import LabeledInput from '../LabeledInput';
import { AvatarUpload } from './AvatarUpload';
import InlineSelect from '../InlineSelect';
import { PronounOptions } from './SpeakersListUtils';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import useDebounce from '@/hooks/useDebounce';

export default function EditSpeakerForm({
  speaker,
  isMergeEnabled,
  onMergeClick,
  onChange
}: {
  speaker: SpeakerWithDetails;
  isMergeEnabled: boolean;
  onMergeClick: () => void;
  onChange: (key: keyof SpeakerWithDetails, value: string | null) => void;
}) {
  const debouncedOnChange = useDebounce(onChange, 1000);

  return (
    <div className="w-full">
      <div slot="editing" className="flex w-full items-center gap-2">
        <div className="w-full space-y-2">
          <AvatarUpload speaker={speaker} />
          <div className="flex items-center gap-2">
            <LabeledInput
              label="First Name"
              classNames="w-full"
              data-testid={`first-name-input-${speaker.id}`}
              value={speaker.first_name}
              onBlur={e => onChange('first_name', e.target.value)}
              onChange={value => debouncedOnChange('first_name', value)}
            />
            <LabeledInput
              label="Last Name"
              classNames="w-full"
              data-testid={`last-name-input-${speaker.id}`}
              value={speaker.last_name}
              onBlur={e => onChange('last_name', e.target.value)}
              onChange={value => debouncedOnChange('last_name', value)}
            />
          </div>
          <div className="flex items-center gap-2">
            <LabeledInput
              label="Title"
              classNames="w-full"
              data-testid={`title-input-${speaker.id}`}
              value={speaker.title}
              onBlur={e => onChange('title', e.target.value)}
              onChange={value => debouncedOnChange('title', value)}
            />
            <LabeledInput
              label="Company"
              classNames="w-full"
              data-testid={`company-input-${speaker.id}`}
              value={speaker.company}
              onBlur={e => onChange('company', e.target.value)}
              onChange={value => debouncedOnChange('company', value)}
            />
          </div>
          <div className="flex items-center gap-2">
            <InlineSelect
              label="Preferred Pronouns"
              classNames="w-full"
              value={speaker.pronouns}
              options={PronounOptions}
              onChange={value => debouncedOnChange('pronouns', value)}
              data-testid={`pronouns-input-${speaker.id}`}
              tooltipId={`pronouns-tooltip-${speaker.id}`}
              tooltipContent="We use this to help inform our AI which pronouns to use when generating text content."
            />
          </div>
        </div>
      </div>

      <div className="mt-4 flex w-full items-center justify-between space-x-2">
        {isMergeEnabled && (
          <div>
            <IconButton
              icon="IconArrowMerge"
              variation="outline"
              size="small"
              content="Merge and replace speaker"
              onClick={onMergeClick}
              trackingId="merge-button"
            />
          </div>
        )}
      </div>
    </div>
  );
}
