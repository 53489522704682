import { IconColorPicker } from '@tabler/icons-react';
import { memo, useSyncExternalStore } from 'react';
import { useVideoImportContext } from '../../../VideoImportContext';
import { ColorPicker } from '@/components/atoms/ColorPicker/ColorPicker';
import { brandKit } from '@/stores/brandKit';

const BrandingColorSelect = () => {
  const { setSelectedColor, selectedColor } = useVideoImportContext();

  const brandKitStore = useSyncExternalStore(brandKit.subscribe, brandKit.getSnapshot);

  return (
    <div className="relative h-full w-full">
      <ColorPicker
        hex={selectedColor}
        defaultColors={brandKitStore.colors}
        defaultColorsLabel="Brand Colors"
        pickerClassNames="right-4 top-[-16px] rounded-xl border-none"
        classNames="overflow-hidden rounded-lg !ring-2 ring-slate-200 !border-none"
        openClassNames="!ring-black"
        onChange={setSelectedColor}
      >
        <div className="relative flex h-full w-full flex-col items-center justify-center overflow-hidden text-white">
          <IconColorPicker size={24} />
          <h2 className="mt-5 select-none text-sm font-medium leading-tight">Primary Color</h2>
        </div>
      </ColorPicker>
    </div>
  );
};

export default memo(BrandingColorSelect);
