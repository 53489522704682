import { useMemo } from 'react';
import { updateClipWithId } from '@/stores/clip';
import { updateClip } from '@/libs/clipContentUtil';
import { showErrorToast } from '@/libs/toast/toast';
import useAnalytics from '@/hooks/useAnalytics';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function useClipShareHook(): {
  setCurrentClipAsShared: () => void;
  clipShareLink: string;
} {
  const { trackContentShare } = useAnalytics();

  const { clipId, clipData } = useClipsContext();

  const clipShareLink = useMemo(() => {
    return `${window.location.origin}/shared/clip/${clipData.id}`;
  }, [clipData.id]);

  function trackOpenPopup(isSuccess: boolean = true) {
    trackContentShare({
      contentType: 'CLIP',
      source: 'EditorPage',
      shareType: 'OpenPopup',
      isSuccess,
      asset: clipData
    });
  }

  function setCurrentClipAsShared() {
    if (clipData.shared) return;

    updateClipWithId(clipId, { shared: true });
    updateClip({ ...clipData, shared: true })
      .then(() => trackOpenPopup())
      .catch(() => {
        trackOpenPopup(false);
        showErrorToast('Something went wrong while sharing the clip. Please try again later.');
      });
  }

  return {
    setCurrentClipAsShared,
    clipShareLink
  };
}
