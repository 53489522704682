import IconButton from '@/components/atoms/Button/IconButton';

const PlayableClipOverlay = ({ playCurrentSection }: { playCurrentSection: () => void }) => {
  return (
    <div
      id="clip-vision-analysis-failed-overlay"
      className="absolute inset-0 z-10 flex flex-col items-center justify-center rounded-md bg-gray-200 px-1"
    >
      <IconButton
        icon={'IconPlayerPlay'}
        size={'large'}
        trackingId="playable-clip-overlay-button"
        onClick={playCurrentSection}
      />
      <div className="mt-3 text-center font-medium">Press Play to continue.</div>
    </div>
  );
};

export default PlayableClipOverlay;
