import { Disclosure } from '@headlessui/react';
import { IconChevronDown } from '@tabler/icons-react';
import { memo } from 'react';

function AIDebugToolsInternalMetadata({
  metadata
}: {
  metadata: {
    [key: string]: string;
  };
}) {
  return (
    <div className="w-full border-b-2 py-2">
      <div className="flex w-full justify-center p-2 font-mono text-xs font-semibold">CURRENT INTERNALS</div>
      <div className="w-full p-2">
        {Object.entries(metadata).map(([key, value]) => (
          <Disclosure key={key}>
            <Disclosure.Button className="flex w-full items-center justify-between border bg-white px-2 py-1 font-mono text-sm font-medium">
              {key}
              <IconChevronDown className="h-4 w-4" />
            </Disclosure.Button>
            <Disclosure.Panel className="border-x border-b bg-white p-2 text-xs text-slate-900">
              {value}
            </Disclosure.Panel>
          </Disclosure>
        ))}
      </div>
    </div>
  );
}

export default memo(AIDebugToolsInternalMetadata);
