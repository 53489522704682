import { Speaker as SpeakerModel } from '@goldcast/api/content';

export type Speaker = Required<{
  [K in keyof SpeakerModel]: NonNullable<SpeakerModel[K]>;
}>;

export enum Pronouns {
  SHE = 'she',
  HE = 'he',
  THEY = 'they'
}
