import { FaceRecognitionStatusEnum, LayoutEnum } from '@goldcast/api/content';
import { FeatureFlagKeys } from '@/services/featureFlag';
import featureFlagStore from '@/stores/featureFlagStore';
import { Clip, LayoutStatus } from '@/domains/asset';

export function getLayoutAvailabilityStatus(
  clipLayout: LayoutEnum,
  layoutStatus: Clip['layout_status']
): FaceRecognitionStatusEnum | null {
  if (!(clipLayout === 'SPEAKER' || clipLayout === 'GRID')) {
    return null;
  }

  const featureFlags = featureFlagStore.getSnapshot();

  if (!featureFlags[FeatureFlagKeys.Use_CL_Facial_Rec_Increment_AB]) {
    return null;
  }

  const shouldUseAccurateOnly = featureFlags[FeatureFlagKeys.Use_CL_Fast_Accurate];
  const speakerLayoutStatusInfo = layoutStatus[clipLayout] as LayoutStatus;

  if (speakerLayoutStatusInfo.status === 'DONE') {
    if (shouldUseAccurateOnly && speakerLayoutStatusInfo.accurate === false) {
      return 'PROCESSING';
    }

    return 'DONE';
  }

  if (speakerLayoutStatusInfo.status === 'PROCESSING' || speakerLayoutStatusInfo.status === 'FAILED') {
    return speakerLayoutStatusInfo.status;
  }

  return null;
}
