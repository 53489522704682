import { IconArrowBackUp, IconArrowForwardUp } from '@tabler/icons-react';
import { useCallback, useSyncExternalStore } from 'react';
import IconButtonGroup from '../atoms/Button/IconButtonGroup';
import { clipHistory, redoChange, undoChange } from '@/stores/clipHistory';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function ClipUndoRedo({ isInline = false }: { isInline?: boolean }) {
  const { clipId, clipCaptionsLoading } = useClipsContext();
  const clipHistoryStore = useSyncExternalStore(clipHistory.subscribe, clipHistory.getSnapshot);
  const clipHistoryForClip = clipHistoryStore[clipId];

  const onUndoChange = useCallback(() => {
    undoChange(clipId);
  }, [clipId]);

  const onRedoChange = useCallback(() => {
    redoChange(clipId);
  }, [clipId]);

  return (
    <IconButtonGroup
      variation={isInline ? 'inline' : 'list'}
      buttons={[
        {
          content: '',
          description: 'Undo change',
          icon: IconArrowBackUp,
          action: onUndoChange,
          disabled: clipCaptionsLoading || clipHistoryForClip.index === 0,
          trackingId: 'transcript-undo-button'
        },
        {
          content: '',
          description: 'Redo change',
          icon: IconArrowForwardUp,
          action: onRedoChange,
          disabled: clipCaptionsLoading || clipHistoryForClip.index === clipHistoryForClip.states.length - 1,
          trackingId: 'transcript-redo-button'
        }
      ]}
    />
  );
}
