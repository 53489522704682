import { Popover, Transition } from '@headlessui/react';
import { IconColorPicker, IconPalette } from '@tabler/icons-react';
import React, { memo, useCallback, useMemo, useSyncExternalStore } from 'react';
import CaptionsMenuTooltip from './CaptionsMenuTooltip';
import { Clip } from '@/domains/asset';
import { ColorPicker } from '@/components/atoms/ColorPicker/ColorPicker';
import BrandKitInlineEmptyState from '@/Pages/Clip/SideBar/EmptyStates/BrandKitInlineEmptyState';
import { brandKit } from '@/stores/brandKit';
import {
  updateCaptionStyles,
  updateHighlightColor,
  updateKeyInMagicLayout,
  updateTextHighlightColor
} from '@/stores/clip';

function CaptionsColorsMenu({
  clip,
  onMenuOpened,
  isDirectionUp = false
}: {
  clip: Clip;
  onMenuOpened: (e: React.MouseEvent) => void;
  isDirectionUp: boolean;
}) {
  const brandKitStore = useSyncExternalStore(brandKit.subscribe, brandKit.getSnapshot);
  const onTextColorChanged = useCallback(
    (value?: string) => {
      updateKeyInMagicLayout(clip.id, 'textColor', value);
    },
    [clip.id]
  );

  const onHighlightColorChanged = useCallback(
    (value?: string) => {
      updateHighlightColor(clip.id, value);
    },
    [clip.id]
  );

  const onBackgroundStyleColorChange = useCallback((value?: string) => {
    updateCaptionStyles(clip.id, { background_style_color: value });
  }, []);

  const onTextHighlightColorChange = useCallback((value?: string) => {
    updateTextHighlightColor(clip.id, value);
  }, []);

  const isBackgroundStyle = useMemo(() => {
    return clip.asset_metadata.caption_styles?.animation === 'Background';
  }, [clip.asset_metadata.caption_styles?.animation]);

  const isBoxHighlight = useMemo(() => {
    return clip.asset_metadata.highlight_type === 'box';
  }, [clip.asset_metadata.highlight_type]);

  const isNoneHighlight = useMemo(() => {
    return clip.asset_metadata.highlight_type === 'none';
  }, [clip.asset_metadata.highlight_type]);

  const pickerClassNames = useMemo(() => {
    return `right-11 ${isDirectionUp ? 'bottom-[-3rem]' : 'top-[-3rem]'}`;
  }, [isDirectionUp]);

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button as="div" className="flex h-10 w-10 items-center justify-center rounded-md hover:bg-slate-100">
            <button
              type="button"
              data-testid="captions-inline-colors-button"
              className="group/button flex w-10 cursor-pointer items-center justify-center rounded-md"
              onClick={onMenuOpened}
            >
              <IconPalette size={20} />
              <CaptionsMenuTooltip content="Colors" />
            </button>
          </Popover.Button>
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Popover.Panel className="flex justify-start">
              <div className="absolute right-0 mt-1.5 flex w-48 flex-col items-start justify-start divide-y rounded-xl border border-slate-200 bg-white py-1 text-sm shadow-lg">
                <div className="flex w-full items-center justify-between px-3 py-1.5">
                  <span>Main</span>
                  <div className="relative h-8 w-8">
                    <ColorPicker
                      classNames="rounded-2xl flex justify-center items-center"
                      pickerClassNames={pickerClassNames}
                      defaultColors={brandKitStore.colors}
                      defaultColorsLabel="Brand Colors"
                      onChange={onTextColorChanged}
                      hex={clip.asset_metadata.magicLayout?.textColor as string}
                    >
                      <IconColorPicker size={16} stroke={2} />
                      <BrandKitInlineEmptyState label="colors" />
                    </ColorPicker>
                  </div>
                </div>
                {isBoxHighlight && (
                  <div className="flex w-full items-center justify-between px-3 py-1.5">
                    <span>Highlight</span>
                    <div className="relative h-8 w-8">
                      <ColorPicker
                        classNames="rounded-2xl flex justify-center items-center"
                        pickerClassNames={pickerClassNames}
                        defaultColors={brandKitStore.colors}
                        defaultColorsLabel="Brand Colors"
                        onChange={onHighlightColorChanged}
                        hex={clip.asset_metadata.word_highlight_color || '#000000'}
                      >
                        <IconColorPicker size={16} stroke={2} />
                        <BrandKitInlineEmptyState label="colors" />
                      </ColorPicker>
                    </div>
                  </div>
                )}
                {isBackgroundStyle && (
                  <div className="flex w-full items-center justify-between px-3 py-1.5">
                    <span>Background</span>
                    <div className="relative h-8 w-8">
                      <ColorPicker
                        classNames="rounded-2xl flex justify-center items-center"
                        pickerClassNames={pickerClassNames}
                        defaultColors={brandKitStore.colors}
                        defaultColorsLabel="Brand Colors"
                        onChange={onBackgroundStyleColorChange}
                        hex={clip.asset_metadata.caption_styles?.background_style_color || '#000000'}
                      >
                        <IconColorPicker size={16} stroke={2} />
                        <BrandKitInlineEmptyState label="colors" />
                      </ColorPicker>
                    </div>
                  </div>
                )}
                {!isNoneHighlight && (
                  <div className="flex w-full items-center justify-between px-3 py-1.5">
                    <span>Highlight Text</span>
                    <div className="relative h-8 w-8">
                      <ColorPicker
                        classNames="rounded-2xl flex justify-center items-center"
                        pickerClassNames={pickerClassNames}
                        defaultColors={brandKitStore.colors}
                        defaultColorsLabel="Brand Colors"
                        onChange={onTextHighlightColorChange}
                        hex={
                          clip.asset_metadata.text_highlight_color ||
                          clip.asset_metadata.magicLayout?.textColor ||
                          '#ffffff'
                        }
                      >
                        <IconColorPicker size={16} stroke={2} />
                        <BrandKitInlineEmptyState label="colors" />
                      </ColorPicker>
                    </div>
                  </div>
                )}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

export default memo(CaptionsColorsMenu);
