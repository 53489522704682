import { Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { brandTemplateTemplateTagsRetrieve } from '@goldcast/api/content';
import TemplatesList from '../Templates/TemplatesList';
import { TEMPLATE_SIZE_OPTIONS } from './constants';
import SideBarBreadcrumb from '../SideBarBreadcrumb';
import { ALL_TEMPLATES_LIMIT, DEFAULT_TEMPLATE_TAG } from '../../constants';
import { SizeType } from '@/domains/asset';
import { classnames } from '@/libs/utils';
import { CustomEvents } from '@/libs/eventBus/constants';
import EventBus from '@/libs/eventBus/eventBus';

export default function TemplatesSideBar({ setSideBarContext, title }) {
  const [selectedSize, setSelectedSize] = useState<SizeType | undefined>();
  const [templatesTags, setTemplatesTags] = useState<string[]>([]);
  const [selectedTag, setSelectedTag] = useState<string | undefined>();

  useEffect(() => {
    loadTemplatesTags();
    const eventListener = EventBus.on(CustomEvents.ReloadTemplates, loadTemplatesTags);
    return () => {
      EventBus.off(CustomEvents.ReloadTemplates, eventListener);
    };
  }, []);

  function loadTemplatesTags() {
    brandTemplateTemplateTagsRetrieve({}).then(res => {
      const tags = res.tags || [];
      // We should have default tag available to show zero state
      if (!tags.includes(DEFAULT_TEMPLATE_TAG)) {
        tags.splice(1, 0, DEFAULT_TEMPLATE_TAG);
      }
      setTemplatesTags(tags);
    });
  }

  function toggleTag(tag: string) {
    setSelectedTag(t => (t === tag ? undefined : tag));
  }

  return (
    <Transition
      appear
      show={true}
      as={'div'}
      className={'flex h-full flex-col overflow-hidden'}
      enter="transform duration-200 ease-out"
      enterFrom="translate-x-[40rem]"
      enterTo="translate-x-0"
      leave="transform duration-150 ease-in"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-[40rem]"
    >
      <div className="border-b">
        <div className="px-4 pt-3">
          <SideBarBreadcrumb setSideBarContext={setSideBarContext} title={title} />
        </div>
        <div className="ml-4 flex items-center space-x-2 pb-3 pt-1.5">
          <select
            className="h-7 w-24 cursor-pointer truncate rounded-full border pl-2 pr-5 text-xs focus:!border-deep-orange-600 focus:outline-0 focus:ring-0"
            onChange={e => setSelectedSize((e.currentTarget.value as SizeType) || undefined)}
          >
            <option value="">All Sizes</option>
            {Object.keys(TEMPLATE_SIZE_OPTIONS).map(option => {
              return (
                <option key={option} value={option}>
                  {TEMPLATE_SIZE_OPTIONS[option]}
                </option>
              );
            })}
          </select>
          <div className="relative overflow-hidden">
            <div className="no-scrollbar relative flex space-x-1.5 overflow-scroll border-l pl-2">
              {templatesTags.map(tag => {
                return (
                  <button
                    key={tag}
                    className={classnames(
                      'shrink-0 rounded-full border border-slate-200 px-3 py-1 text-xs text-gray-700',
                      {
                        'border-deep-orange bg-deep-orange-50 text-deep-orange': tag === selectedTag
                      }
                    )}
                    onClick={() => toggleTag(tag)}
                  >
                    {tag}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="grow overflow-y-scroll px-3 py-4">
        <TemplatesList size={selectedSize} tags={selectedTag} limit={ALL_TEMPLATES_LIMIT} />
      </div>
    </Transition>
  );
}
