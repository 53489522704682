import { IconArrowUpRight, IconWorld } from '@tabler/icons-react';
import { TextAsset } from '@/Pages/PostsListPage/types';
import { copyTextToClipboard } from '@/Pages/PostsListPage/utils';
import Button from '@/components/atoms/Button/Button';
import { useAppContext } from '@/context/AppContext/AppContext';
import { showSuccessToast } from '@/libs/toast/toast';
import useAnalytics from '@/hooks/useAnalytics';

export default function PostShareLinkTab({ post }: { post: TextAsset | null }) {
  const { logger } = useAppContext();
  const { trackContentShare } = useAnalytics();

  const postShareLink = `${window.location.origin}/shared/post/${post?.id}`;

  const handleOpenShareLink = () => {
    window.open(postShareLink, '_blank');
  };

  const handleCopyShareLink = () => {
    copyTextToClipboard(postShareLink, logger);
    trackContentShare({
      contentType: post?.asset_metadata?.type || '',
      source: 'EditorPage',
      shareType: 'CopyURL',
      asset: post
    });
    showSuccessToast('Link copied to clipboard!');
  };

  return (
    <div className="mt-2 p-2">
      <div className="flex w-full items-center space-x-2 px-1.5">
        <div className="w-6">
          <IconWorld className="h-6 w-6" />
        </div>
        <div className="w-full text-xs font-medium leading-tight">
          Public Access
          <div className="text-xs font-normal">Everyone with the link can view</div>
        </div>
        <Button
          trackingId="open-share-link-button"
          buttonSize="small"
          variation="outline"
          className="!px-1.5"
          onClick={handleOpenShareLink}
        >
          <IconArrowUpRight className="h-[18px] w-[18px]" />
        </Button>
      </div>
      <input className="mt-3 w-full rounded-lg border p-2.5 text-sm text-slate-500" disabled value={postShareLink} />
      <Button
        buttonSize="large"
        variation="filled"
        className="mt-2 h-12 w-full rounded-lg"
        trackingId="copy-share-link-button"
        onClick={handleCopyShareLink}
      >
        Copy Link
      </Button>
    </div>
  );
}
