import { useEffect, useMemo, useState } from 'react';
import Button from '../Button/Button';
import Icon, { IconName } from '../Icon';
import Loader from '../Loader';
import { ButtonSize, ButtonVariation } from '../Button/ButtonTypes';
import { ApiButtonStatus } from './ApiButtonTypes';
import { getButtonVariationForApiStatus, getGenerationIcon } from './ApiButtonUtils';
import { classnames } from '@/libs/utils';

const GENERATION_LABELS_TRANSITION_TIMEOUT = 1800;

export default function ApiButton({
  disabled = false,
  title,
  successMessage,
  failMessage = '',
  disabledTooltip = '',
  icon = null,
  variation = 'outline',
  inProgressLabels,
  trackingId,
  buttonSize,
  fullWidth = false,
  buttonClassNames = '',
  completedStateDuration = 2000,
  action,
  onStatusChanged,
  isFailedFn
}: {
  disabled?: boolean;
  title: string;
  successMessage: string;
  failMessage?: string;
  disabledTooltip?: string;
  icon?: IconName | null;
  variation?: ButtonVariation;
  inProgressLabels: string[];
  trackingId: string;
  buttonSize?: ButtonSize;
  fullWidth?: boolean;
  buttonClassNames?: string;
  completedStateDuration?: number;
  action: () => Promise<any>;
  onStatusChanged?: (status: ApiButtonStatus) => void;
  isFailedFn?: (response: any) => boolean;
}) {
  const [status, setStatus] = useState<ApiButtonStatus>(ApiButtonStatus.IDLE);
  const [buttonLabel, setButtonLabel] = useState(title);

  function executeAction() {
    if ([ApiButtonStatus.COMPLETED, ApiButtonStatus.FAILED].includes(status)) return;
    setStatus(ApiButtonStatus.IN_PROGRESS);
    action()
      .then(onActionCompleted)
      .catch(() => {
        setStatus(ApiButtonStatus.IDLE);
      });
  }

  function onActionCompleted(response) {
    if (isFailedFn && isFailedFn(response)) {
      setStatus(ApiButtonStatus.FAILED);
    } else {
      setStatus(ApiButtonStatus.COMPLETED);
    }

    setTimeout(() => {
      setStatus(ApiButtonStatus.IDLE);
    }, completedStateDuration);
  }

  useEffect(() => {
    onStatusChanged?.(status);
    let generateButtonInterval;
    const currentLabel = getButtonLabel();
    if (status === ApiButtonStatus.IN_PROGRESS) {
      setButtonLabel(currentLabel[0]);
      generateButtonInterval = setInterval(() => {
        setButtonLabel(prevLabel => {
          const index = inProgressLabels.indexOf(prevLabel);
          return inProgressLabels[(index + 1) % inProgressLabels.length];
        });
      }, GENERATION_LABELS_TRANSITION_TIMEOUT);
    } else {
      setButtonLabel(currentLabel as string);
    }

    return () => clearInterval(generateButtonInterval);
  }, [status]);

  const buttonIcon = useMemo(() => {
    return getGenerationIcon(status, icon);
  }, [status]);

  const buttonVariation = useMemo(() => {
    return getButtonVariationForApiStatus(status, variation);
  }, [status]);

  function getButtonLabel(): string | string[] {
    switch (status) {
      case ApiButtonStatus.IN_PROGRESS:
        return inProgressLabels;
      case ApiButtonStatus.COMPLETED:
        return successMessage;
      case ApiButtonStatus.FAILED:
        return failMessage;
      default:
        return title;
    }
  }

  return (
    <Button
      onClick={executeAction}
      variation={buttonVariation}
      className={classnames(
        'transition-width border border-slate-200 duration-500',
        {
          'w-40': buttonLabel !== title,
          'w-full': fullWidth
        },
        buttonClassNames
      )}
      disabled={status === ApiButtonStatus.IN_PROGRESS || disabled}
      title={disabled && disabledTooltip ? disabledTooltip : ''}
      buttonSize={buttonSize}
      trackingId={trackingId}
    >
      {status === ApiButtonStatus.IN_PROGRESS && <Loader size="xs" />}
      {buttonIcon && <Icon name={buttonIcon} className="h-4 w-4"></Icon>}
      <div className={status !== ApiButtonStatus.IN_PROGRESS ? 'px-2' : ''}>{buttonLabel}</div>
    </Button>
  );
}
