import { useState } from 'react';
import { getParsedFilesList, isValidFileExtension } from '@/libs/file';
import { showWarningToast } from '@/libs/toast/toast';
import { CONTENT_FORMATS } from '@/Pages/Sessions/NewContentModal/constants';

export default function useFilesDragNDrop({
  onFilesUpload,
  uploadedFiles = [],
  accept = CONTENT_FORMATS
}: {
  onFilesUpload: (files: File[]) => void;
  uploadedFiles?: File[];
  accept?: string;
}) {
  const [isDragging, setIsDragging] = useState(false);

  function handleFilesToUpload(filesToUpload: File[]) {
    const newFiles = filesToUpload.filter(file => !uploadedFiles.find(f => f.name === file.name));
    onFilesUpload(newFiles);
  }

  function getFilesFromDrop(ev: React.DragEvent): File[] {
    if (!ev.dataTransfer.files || !ev.dataTransfer.files.length) return [];
    return getValidatedFiles(ev.dataTransfer.files);
  }

  function getFilesFromInput(ev: React.ChangeEvent): File[] {
    const inputElement = ev.currentTarget as HTMLInputElement;
    if (!inputElement.files || !inputElement.files.length) return [];
    return getValidatedFiles(inputElement.files);
  }

  function getValidatedFiles(fileList: FileList): File[] {
    const files = getParsedFilesList(fileList);
    const filesToUpload = files.filter(file => {
      return isValidFileExtension(file.name, accept);
    });
    if (filesToUpload.length < files.length) {
      showWarningToast(`Supported file formats are: ${accept.replaceAll('.', '').toUpperCase().split(', ')}`);
    }
    return filesToUpload;
  }

  function uploadFiles(ev: React.DragEvent | React.ChangeEvent) {
    ev.preventDefault();
    setIsDragging(false);
    let filesToUpload: File[] = [];
    if (ev.type === 'drop') {
      filesToUpload = getFilesFromDrop(ev as React.DragEvent);
    } else if (ev.currentTarget instanceof HTMLInputElement) {
      filesToUpload = getFilesFromInput(ev as React.ChangeEvent);
    }
    if (filesToUpload.length) {
      handleFilesToUpload(filesToUpload);
    }
  }

  function handleDragStart(e: React.DragEvent) {
    e.preventDefault();
    setIsDragging(true);
  }

  function handleDragStop() {
    setIsDragging(false);
  }

  return {
    isDragging,
    handleFilesToUpload,
    uploadFiles,
    handleDragStart,
    handleDragStop
  };
}
