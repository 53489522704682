import { memo, useMemo } from 'react';
import { BackgroundTransitionTemplateMock } from './types';
import { transitionTemplateMockCaptions } from './constants';
import { classnames } from '@/libs/utils';

function MockTemplateCaptionsRenderer({
  element,
  scale,
  currentTime
}: {
  element: BackgroundTransitionTemplateMock['timeline']['segments'][0]['elements'][0];
  scale: number;
  currentTime: number;
}) {
  const currentSlide = useMemo(() => {
    return transitionTemplateMockCaptions.find(slide => slide.start <= currentTime && slide.end >= currentTime);
  }, [currentTime]);

  const currentWord = useMemo(() => {
    if (currentSlide) {
      return currentSlide.words.find(word => word.start <= currentTime && word.end >= currentTime);
    }
  }, [currentSlide, currentTime]);

  const containerStyles = useMemo(() => {
    const elementWidth = element.width || 0;
    const elementHeight = element.height || 0;
    const elementX = element.x || 0;
    const elementY = element.y || 0;
    const elementRotation = element.rotation || 0;

    return {
      width: `${elementWidth * scale}px`,
      height: `${elementHeight * scale}px`,
      top: `${elementY * scale}px`,
      left: `${elementX * scale}px`,
      transform: `rotate(${elementRotation}deg)`
    } as React.CSSProperties;
  }, [element, scale]);

  const backgroundClasses = useMemo(() => {
    if (element.style === 'Background') {
      return 'bg-black/60 rounded-[2.5px] px-[5px] py-[1px]';
    }
  }, [element.style]);

  const highlightClasses = useMemo(() => {
    if (element.animation === 'scale') {
      return 'inline-flex scale-[1.1]';
    } else if (element.animation === 'box') {
      return 'inline-flex rounded-[0.1rem]';
    } else if (element.animation === 'highlight') {
      return 'inline-flex highlight';
    } else {
      return 'inline-flex';
    }
  }, [element.animation]);

  const styleClasses = useMemo(() => {
    if (element.style === 'Outline') {
      return {
        textShadow: `-${7 * scale}px -${7 * scale}px 0 #000, 0 -${7 * scale}px 0 #000, ${7 * scale}px -${
          7 * scale
        }px 0 #000, ${7 * scale}px 0 0 #000, ${7 * scale}px ${7 * scale}px 0 #000, 0 ${7 * scale}px 0 #000, -${
          7 * scale
        }px ${7 * scale}px 0 #000, -${7 * scale}px 0 0 #000`
      } as React.CSSProperties;
    } else if (element.style === 'Shadow') {
      return {
        textShadow: `0.1rem 0.1rem 0 #000`
      } as React.CSSProperties;
    } else {
      return {};
    }
  }, [element.style, scale]);

  const highlightStyle = useMemo(() => {
    if (element.animation === 'scale') {
      return {
        ...styleClasses,
        fontFamily: element.fontFamily,
        fontSize: `${(element.fontSize || 0) * scale * 1.5}px`,
        padding: `0 ${0.5 * scale}rem`,
        color: element.highlightColor
      };
    } else if (element.animation === 'box') {
      return {
        ...styleClasses,
        fontFamily: element.fontFamily,
        fontSize: `${(element.fontSize || 0) * scale * 1.5}px`,
        padding: `0 ${0.5 * scale}rem`,
        backgroundColor: element.highlightColor,
        color: element.textColor
      };
    } else if (element.animation === 'highlight') {
      return {
        ...styleClasses,
        fontFamily: element.fontFamily,
        fontSize: `${(element.fontSize || 0) * scale * 1.5}px`,
        padding: `0 ${0.5 * scale}rem`,
        color: element.highlightColor
      };
    } else {
      return {};
    }
  }, [element, scale, styleClasses]);

  const textStyle = useMemo(() => {
    return {
      ...styleClasses,
      fontFamily: element.fontFamily,
      fontSize: `${(element.fontSize || 0) * scale * 1.5}px`,
      padding: `0 ${0.5 * scale}rem`,
      color: element.textColor
    };
  }, [element, scale, styleClasses]);

  return (
    <div className={`absolute flex ${element.verticalAlignment || ''} z-30`} style={containerStyles}>
      <div className={`${element.alignment || ''} flex items-center`}>
        <div className={`${backgroundClasses} ${element.alignment || ''} relative flex flex-wrap items-center`}>
          {currentSlide?.words?.map(word => (
            <span
              key={word.start}
              className={classnames(
                element.letterCase ? element.letterCase : '',
                highlightClasses,
                'font-bold transition-all duration-[100ms] ease-[cubic-bezier(.76,2.08,.71,.98)]'
              )}
              style={currentWord?.start === word.start ? highlightStyle : textStyle}
            >
              {word.punctuated_word}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default memo(MockTemplateCaptionsRenderer);
