import React, { useCallback, useSyncExternalStore } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IconArrowLeft } from '@tabler/icons-react';
import NewTemplateButton from '../SideBar/TemplatesSideBar/NewTemplateButton';
import Breadcrumbs from '@/components/atoms/Breadcrumbs/Breadcrumbs';
import { saveClipChanges, updateClipWithId } from '@/stores/clip';
import { core } from '@/stores/core';
import ClipUndoRedo from '@/components/molecules/ClipUndoRedo';
import FreeTrialUpgradeNag from '@/components/molecules/FreeTrial/FreeTrialUpgradeNag';
import ClipSharePopup from '@/components/molecules/SharePopup/ClipSharePopup/ClipSharePopup';
import IconButton from '@/components/atoms/Button/IconButton';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { useAppContext } from '@/context/AppContext/AppContext';
import DownloadsPopup from '@/components/molecules/DownloadsPopup/DownloadsPopup';
import ContentSettings from '@/components/molecules/ContentSettings';

export default function PageHeader() {
  const navigate = useNavigate();
  const location = useLocation();
  const { eventId, broadcastId } = useParams<{ eventId: string; broadcastId: string; clipId: string }>();
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);
  const { clipId, clipData } = useClipsContext();
  const { adminAppRouter } = useAppContext();

  function onEditInputBlur(title: string) {
    updateClipWithId(clipId, { title });

    saveClipChanges(clipId, false, true, clipData.asset_metadata.is_edited);
    return Promise.resolve();
  }

  const openContentSettings = () => {
    EventBus.dispatch(CustomEvents.OpenContentSettings, { speakerId: null });
  };

  const handleNavigate = useCallback(() => {
    const contentLabLoadedFrom = sessionStorage.getItem('contentLabLoadedFrom');

    if (contentLabLoadedFrom && location.key === 'default') {
      adminAppRouter.replace({ path: contentLabLoadedFrom });
    } else if (location.key === 'default') {
      navigate(`/${clipData.content.project.id}/${clipData.content.id}/clips?activeClipId=${clipId}`);
    } else {
      navigate(-1);
    }
  }, [clipData.content.project.id, clipData.content.id, clipId, location.key]);

  return (
    <div className="flex h-14 w-full items-center justify-between border-b border-b-slate-200 px-4">
      <div className="flex items-center space-x-4">
        <button
          onClick={handleNavigate}
          data-testid="clip-edit-page-back-button"
          className="relative flex h-14 items-center justify-center border-r border-r-slate-200 p-6 pr-[39px]"
        >
          <IconArrowLeft size={21} stroke={1.5} className="absolute flex h-6 w-6 items-center justify-center" />
        </button>
        <Breadcrumbs
          links={[
            {
              url: `/${eventId}/${broadcastId}/clips`,
              title: coreStore.content?.title || ''
            },
            {
              url: `/${eventId}/${broadcastId}/clips/${clipId}`,
              title: clipData.title,
              isEdit: true
            }
          ]}
          onEditInputBlur={onEditInputBlur}
        />
      </div>

      <div className="flex items-center space-x-2">
        <FreeTrialUpgradeNag />
        {broadcastId && <DownloadsPopup contentId={broadcastId} />}
        <ClipUndoRedo />
        <NewTemplateButton className="h-8 px-3 py-2 text-xs" />
        <ClipSharePopup />
        <IconButton
          icon="IconSettings"
          buttonClassName="border border-slate-300 rounded p-1 w-9 h-9"
          onClick={openContentSettings}
          trackingId="session-config-trigger"
        />
      </div>
      <ContentSettings />
    </div>
  );
}
