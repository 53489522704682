import React, { KeyboardEvent } from 'react';
import { updateClipWithId } from '@/stores/clip';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

interface EditableBreadcrumbProps {
  title: string;
  onEditInputBlur: ((title: string) => Promise<void>) | undefined;
}

export default function EditableBreadcrumb({ title, onEditInputBlur }: EditableBreadcrumbProps): JSX.Element {
  const [titleValue, setTitleValue] = React.useState(title);
  const [isEditTitle, setIsEditTitle] = React.useState(false);
  const [error, setError] = React.useState(false);
  const ref = React.useRef<HTMLInputElement>(null);
  const { clipId } = useClipsContext();

  const onEditClick = React.useCallback(() => {
    ref.current?.focus();
  }, []);

  const saveTitle = React.useCallback(() => {
    if (titleValue === '') {
      setError(true);
      onEditClick();
      return;
    }

    if (error) {
      setError(false);
    }

    if (titleValue === title) return;
    onEditInputBlur?.(titleValue)
      .then(() => {
        updateClipWithId(clipId, { title: titleValue });
        setIsEditTitle(false);
      })
      .catch(() => {
        setError(true);
        onEditClick();
      });
  }, [title, error, titleValue]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      saveTitle();
      ref.current?.blur();
    }
  };

  return (
    <div className="flex w-[20rem] items-center">
      <input
        type="text"
        className={`w-full truncate rounded border-solid text-sm text-black focus:ring-0 ${
          error ? 'min-w-[15rem] focus:border-red-500' : 'focus:border-deep-orange-600'
        } p-1.5 ${isEditTitle ? '' : 'border-transparent hover:bg-slate-100'}`}
        value={titleValue}
        onChange={e => setTitleValue(e.target.value)}
        onKeyDown={handleKeyDown}
        ref={ref}
        onFocus={() => setIsEditTitle(true)}
        onBlur={saveTitle}
      />
    </div>
  );
}
