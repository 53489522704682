import { Fragment, memo, useMemo } from 'react';
import { getCaptionStyles } from '../utils';
import useCaptionsStylesHook from '@/components/molecules/useCaptionsStylesHook';
import { CaptionStyle, ClipMetadata, HighlightType } from '@/domains/asset';
import { Word } from '@/domains/transcript';
import { getFontByLocation } from '@/Pages/Clip/SideBar/FontSelector/constants';
import { classnames } from '@/libs/utils';

function PreviewCaptions({
  words,
  clipMetadata,
  captionHorizontalSpace,
  animation,
  scale = 1,
  highlightType = 'none',
  classNames = '',
  previewIndex = 0,
  textColor,
  backgroundColor,
  fontFamily
}: {
  words: Word[];
  clipMetadata: ClipMetadata;
  captionHorizontalSpace: number;
  animation?: CaptionStyle;
  scale?: number;
  highlightType?: HighlightType;
  isPreview?: boolean;
  classNames?: string;
  previewIndex?: number;
  textColor: string;
  backgroundColor?: string;
  fontFamily?: string;
}) {
  const { activeWordStyles, inactiveWordStyles } = useCaptionsStylesHook(clipMetadata, highlightType, backgroundColor);

  const captionsStyles = useMemo(() => {
    return getCaptionStyles(animation, scale, getFontByLocation(clipMetadata.font_location));
  }, [animation, scale, clipMetadata.font_location]);

  const activeTextColor = useMemo(() => {
    return highlightType !== 'none' && !!clipMetadata.text_highlight_color
      ? clipMetadata.text_highlight_color
      : textColor;
  }, [textColor, highlightType, clipMetadata.text_highlight_color]);

  return words.map((word, index) => {
    const isActive = index === previewIndex;
    const wordStyles = isActive ? activeWordStyles : inactiveWordStyles;
    const animationStyles = isActive && highlightType === 'box' ? {} : captionsStyles;
    if (highlightType === 'text') {
      wordStyles.opacity = !isActive ? 0.6 : 1;
    }

    return (
      <Fragment key={word.index}>
        <span
          className={classnames('bg-clip-content', classNames)}
          style={{
            color: isActive ? activeTextColor : textColor,
            backgroundColor: wordStyles.backgroundColor,
            opacity: wordStyles.opacity,
            paddingLeft: `${captionHorizontalSpace}px`,
            ...animationStyles,
            ...(!!fontFamily ? { fontFamily } : {})
          }}
        >
          {word.content.trim()}
        </span>
      </Fragment>
    );
  });
}

export default memo(PreviewCaptions);
