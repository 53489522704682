import { useEffect, useMemo, useSyncExternalStore } from 'react';
import FaceSpeakerToggler from '../FaceSpeakerToggler/FaceSpeakerToggler';
import MatchSpeakersCTA from './MatchSpeakersCTA';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import Toggle from '@/components/atoms/Toggle';
import { classnames } from '@/libs/utils';
import { toggleSpeakerLabels } from '@/stores/clip';
import { core } from '@/stores/core';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

// TODO @dusangc: Remove this component with Easy Clip Customize FF cleanup
export default function SpeakersConfiguration() {
  const { clipId, clipData, speakersAnalysis } = useClipsContext();

  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);

  function triggerSpeakerIdentification(params = {}) {
    EventBus.dispatch(CustomEvents.OpenSpeakersIdentification, params);
  }

  const transcriptStore = useTranscriptContext();

  const { areAllUnidentified } = useMemo(() => {
    const speakerMappingFaceIds = Object.values(speakersAnalysis.speaker_mapping);
    const isActiveSpeakerOrGrid = ['SPEAKER', 'GRID'].includes(clipData.asset_metadata.layout);

    const areAllUnidentified =
      (coreStore.content?.media_source_type === 'UPLOAD' ? !speakersAnalysis.loading : true) &&
      isActiveSpeakerOrGrid &&
      (!speakerMappingFaceIds.length ||
        transcriptStore.usedSpeakers.every(speaker => {
          return !speakersAnalysis.speaker_mapping[speaker.id]?.length;
        }));

    const containsUnidentifiedSpeakers =
      (coreStore.content?.media_source_type === 'UPLOAD' ? !speakersAnalysis.loading : true) &&
      isActiveSpeakerOrGrid &&
      (!speakerMappingFaceIds.length ||
        transcriptStore.usedSpeakers.some(speaker => {
          return !speakersAnalysis.speaker_mapping[speaker.id]?.length;
        }));

    return { areAllUnidentified, containsUnidentifiedSpeakers };
  }, [
    coreStore.content?.media_source_type,
    speakersAnalysis.loading,
    speakersAnalysis.speaker_mapping,
    clipData.asset_metadata.layout,
    transcriptStore.usedSpeakers
  ]);

  useEffect(() => {
    if (coreStore.content?.media_source_type === 'UPLOAD' && areAllUnidentified) {
      toggleSpeakerLabels(clipId, false, false);
    }
  }, [areAllUnidentified, coreStore.content?.media_source_type]);

  function showSpeakerLabels() {
    toggleSpeakerLabels(clipId, true);
    triggerClipReload();
  }

  function triggerClipReload() {
    // Reload if UNAVAILABLE to get fresh status
    if (clipData.layout_status.GRID.status === 'UNAVAILABLE' || clipData.layout_status.SPEAKER.status === 'UNAVAILABLE')
      EventBus.dispatch(CustomEvents.ReloadClip, {});
  }

  function onSpeakerLabelsToggle(value: boolean) {
    if (areAllUnidentified && value && coreStore.content?.media_source_type === 'UPLOAD') {
      triggerSpeakerIdentification({ postIdentifyCallback: showSpeakerLabels, isInlineIdentification: true });
    } else {
      toggleSpeakerLabels(clipId, value);
    }
  }

  return (
    <>
      <div className="mt-5 font-semibold">Speaker</div>
      {coreStore.content?.media_source_type === 'UPLOAD' && (
        <MatchSpeakersCTA
          onClick={() =>
            triggerSpeakerIdentification({
              postIdentifyCallback: triggerClipReload,
              isInlineIdentification: true,
              layout: clipData.asset_metadata.layout
            })
          }
        />
      )}
      {clipData.asset_metadata.layout === 'GRID' && <FaceSpeakerToggler />}
      <div
        className={classnames('mt-1 flex items-center justify-between rounded-md border border-slate-200 px-4 py-3.5', {
          'mt-4': clipData.asset_metadata.layout === 'GRID'
        })}
      >
        <div className="text-sm">Show Speaker Labels</div>
        <div>
          <Toggle on={!!clipData.asset_metadata.magicLayout?.showSpeakerLabels} onToggle={onSpeakerLabelsToggle} />
        </div>
      </div>
    </>
  );
}
