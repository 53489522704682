import { memo, useMemo, useSyncExternalStore } from 'react';
import PageHeader from './PageHeader/PageHeader';
import ClipPlayer from './ClipPlayer/ClipPlayer';
import SideBar from './SideBar/SideBar';
import { TIMELINE_DISPLAY_TYPE } from './ClipPlayer/ClipTimelineV2/ClipTimelineTypes';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';

const ClipLayout: React.FC = memo(() => {
  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const showNewTimeline = useMemo(
    () => featureFlags[FeatureFlagKeys.Use_CL_New_Timeline],
    [featureFlags[FeatureFlagKeys.Use_CL_New_Timeline]]
  );

  return (
    <div className="fixed inset-0 z-40 grid h-full w-full grid-cols-[1fr] grid-rows-[max-content,auto] bg-white">
      <PageHeader />
      <div className="flex h-full w-full overflow-hidden">
        <SideBar />

        <ClipPlayer
          showNewTimeline={showNewTimeline}
          timelineDisplayType={TIMELINE_DISPLAY_TYPE.FILMSTRIP}
          inlineEditEnabled={true}
          playerClasses="lg:p-16"
        />
      </div>
    </div>
  );
});

export default ClipLayout;
