import { IconAtom, IconCategory2 } from '@tabler/icons-react';
import React from 'react';

export default function NoData({ noMatch = false }) {
  const Icon = noMatch ? IconCategory2 : IconAtom;

  return (
    <div className="flex w-full items-center justify-center py-12">
      <div className="flex flex-col items-center">
        <Icon size={32} stroke={1.5} className="text-slate-400" />
        <div className="mt-2 max-w-[10rem] text-center text-sm leading-tight text-slate-400">
          {noMatch ? 'No results found matching your criteria.' : 'No data available'}
        </div>
      </div>
    </div>
  );
}
