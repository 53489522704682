import React, { useMemo, Fragment } from 'react';
import { useSpeakerSegmentContext } from '@/context/SpeakerSegmentContext/SpeakerSegmentContext';
import { getCurrentClipFontName } from '@/stores/brandKit';
import useCaptionsStylesHook from '@/components/molecules/useCaptionsStylesHook';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function CanvasPlayerCaptions({ captionHorizontalSpace }: { captionHorizontalSpace: number }) {
  const { clipId, clipData, playerStore } = useClipsContext();

  const speakerSegmentContextData = useSpeakerSegmentContext();
  const words = useMemo(() => {
    return speakerSegmentContextData?.srtCaptions?.[playerStore.currentSrtIndex] || [];
  }, [playerStore.currentSrtIndex, speakerSegmentContextData?.srtCaptions]);

  const textColor = useMemo(
    () => clipData.asset_metadata?.magicLayout?.textColor,
    [clipData.asset_metadata?.magicLayout?.textColor]
  );

  const fontFamilyStyle = useMemo(() => {
    const fontName = getCurrentClipFontName(clipId);
    if (fontName) return { fontFamily: fontName };
    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clipData.asset_metadata.font_location]);

  const { activeWordStyles, inactiveWordStyles } = useCaptionsStylesHook(clipData.asset_metadata);

  return words.map((word, index) => {
    const isActive = word.start_time <= playerStore.currentTime && word.end_time >= playerStore.currentTime;
    const wordStyles = isActive ? activeWordStyles : inactiveWordStyles;

    return (
      <Fragment key={word.index}>
        <span
          className="px-[1px]"
          style={{
            color: textColor,
            backgroundColor: wordStyles.backgroundColor,
            opacity: wordStyles.opacity,
            marginLeft: `${index === 0 ? 0 : captionHorizontalSpace}px`,
            ...fontFamilyStyle
          }}
        >
          {word.content.trim()}
        </span>
      </Fragment>
    );
  });
}
