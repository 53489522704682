import React from 'react';
import { isIosBrowser } from './ClipPlayerUtils';

export default function IntroOutroPlayer({
  videoRef,
  videoProps,
  parentClassName,
  type,
  ...props
}: {
  videoRef?: React.RefObject<HTMLVideoElement>;
  videoProps: React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>;
  type: 'intro' | 'outro';
  parentClassName?: string;
  style?: React.CSSProperties;
}) {
  const isIos = isIosBrowser();

  return (
    <div className={parentClassName} {...props} key={videoProps.src}>
      <video
        crossOrigin="anonymous"
        className="h-full w-full overflow-hidden rounded object-cover object-center shadow-lg"
        playsInline={isIos}
        autoPlay={isIos}
        controls={false}
        ref={videoRef}
        {...videoProps}
        onLoadedData={e => {
          if (!videoRef?.current) return;

          if (videoProps.onLoadedData) {
            videoProps.onLoadedData(e);
          }
        }}
      />
    </div>
  );
}
