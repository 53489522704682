import { memo, useRef, useMemo, useEffect } from 'react';
import { BackgroundTransitionTemplateMock } from './types';
import { PREVIEW_VIDEO_ID } from './constants';
import { useAppContext } from '@/context/AppContext/AppContext';

function MockTemplateVideoRenderer({
  element,
  scale
}: {
  element: BackgroundTransitionTemplateMock['timeline']['segments'][0]['elements'][0];
  scale: number;
}) {
  const { logger } = useAppContext();

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const styles = useMemo(() => {
    const elementWidth = element.width || 0;
    const elementHeight = element.height || 0;
    const elementX = element.x || 0;
    const elementY = element.y || 0;
    const elementRotation = element.rotation || 0;
    const elementCropX = element.cropX || 0;
    const elementCropY = element.cropY || 0;
    const elementCropWidth = element.cropWidth || 0;
    const elementCropHeight = element.cropHeight || 0;
    const elementBorderRadius = element.borderRadius || 0;

    return {
      width: `${elementWidth * scale}px`,
      height: `${elementHeight * scale}px`,
      top: `${elementY * scale}px`,
      left: `${elementX * scale}px`,
      transform: `rotate(${elementRotation}deg)`,
      clipPath: `inset(${elementCropY * scale}px ${
        elementWidth * scale - (elementCropX * scale + elementCropWidth * scale)
      }px ${elementHeight * scale - (elementCropY * scale + elementCropHeight * scale)}px ${elementCropX * scale}px ${
        elementBorderRadius ? `round ${elementBorderRadius * scale}px` : ''
      })`,
      borderRadius: `${elementBorderRadius * scale}px`
    };
  }, [element, scale]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const video = document.getElementById(PREVIEW_VIDEO_ID) as HTMLVideoElement;
    if (!video) return;

    let animationFrameId: number;

    const drawFrame = () => {
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      animationFrameId = requestAnimationFrame(drawFrame);
    };

    video.play().catch(err => logger.warn('Video autoplay failed:', err));

    const startDrawing = () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
      animationFrameId = requestAnimationFrame(drawFrame);
    };

    if (video.readyState >= HTMLMediaElement.HAVE_FUTURE_DATA) {
      startDrawing();
    } else {
      video.addEventListener('canplay', startDrawing);
    }

    return () => {
      video.removeEventListener('canplay', startDrawing);
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="absolute inset-0 object-fill"
      style={styles}
      width={element.width}
      height={element.height}
    />
  );
}

export default memo(MockTemplateVideoRenderer);
