export const HOMEPAGE_SUB_ROUTES = [
  {
    title: 'Home',
    url: '/'
  },
  {
    title: 'Brand Kit',
    url: '/brand-kit'
  }
];
