import { PREVIEW_SPEAKER_IMAGE } from '../../MockTemplateBackgroundTransition/constants';
import { Pronouns } from '@/domains/speaker';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { WordType } from '@/domains/transcript';

export const TEMPLATE_SELECT_MAX_COUNT = 5;

export const TEMPLATE_SELECT_PREVIEW_FIRST_SPEAKER: SpeakerWithDetails = {
  first_name: 'Devin',
  last_name: 'Reed',
  color: 'text-indigo-700',
  bgColor: 'bg-indigo-700',
  id: '1',
  face_ids: [],
  company: '',
  title: '',
  parent_id: null,
  profile_picture_url: PREVIEW_SPEAKER_IMAGE,
  pronouns: Pronouns.HE,
  speakingSlots: {
    '0.16': 15.335
  },
  video: {
    start: 0,
    url: 'https://www.example.com'
  },
  key: 'spk_0'
};

export const TEMPLATE_SELECT_PREVIEW_CLIP_ID = 'template-select-preview-clip-id';

export const TEMPLATE_SELECT_PREVIEW_MOCK_CLIP = {
  content: {
    media_source_type: 'UPLOAD'
  },
  first_frame_analysis: {
    fps: 30,
    clip_id: TEMPLATE_SELECT_PREVIEW_CLIP_ID,
    accurate: false,
    face_ids: [0, 1],
    true_end: 193055.01,
    true_start: 158665,
    time_analysis: [
      {
        time: '0:00:00.0',
        frame_number: 0,
        face_positions: [
          {
            face_id: 0,
            spk_label: '',
            crop_position: {
              top_left: {
                x: 0.1109,
                y: 0.3
              },
              bottom_right: {
                x: 0.4297,
                y: 0.7343
              }
            },
            crop_position_face: {
              top_left: {
                x: 0.2083,
                y: 0.3426
              },
              bottom_right: {
                x: 0.3115,
                y: 0.6111
              }
            },
            crop_position_person: {
              top_left: {
                x: 0.1109,
                y: 0.3
              },
              bottom_right: {
                x: 0.4297,
                y: 0.7343
              }
            }
          },
          {
            face_id: 1,
            spk_label: '',
            crop_position: {
              top_left: {
                x: 0.6016,
                y: 0.2806
              },
              bottom_right: {
                x: 0.9411,
                y: 0.7454
              }
            },
            crop_position_face: {
              top_left: {
                x: 0.7188,
                y: 0.3269
              },
              bottom_right: {
                x: 0.8161,
                y: 0.575
              }
            },
            crop_position_person: {
              top_left: {
                x: 0.6016,
                y: 0.2806
              },
              bottom_right: {
                x: 0.9411,
                y: 0.7454
              }
            }
          }
        ]
      }
    ],
    speaker_mapping: {
      '1': [1]
    },
    analysis_version: 'v0.7',
    face_id_overrides: {
      '0': [0],
      '1': [1]
    },
    new_face_id_overrides: {},
    active_speaker_mapping: {},
    manual_speaker_mapping: {}
  },
  layout_status: {
    GRID: { status: 'DONE', accurate: false },
    SPEAKER: { status: 'DONE', accurate: false }
  },
  broadcast: 'mock-broadcast-id',
  broadcast_data: {
    title: 'Mock Broadcast'
  },
  id: TEMPLATE_SELECT_PREVIEW_CLIP_ID
};

export const INTERIM_THUMBNAIL_PREVIEW_WORDS = [
  {
    start_time: 16,
    end_time: 18,
    content: 'Your',
    type: WordType.Pronunciation,
    speaker_label: 'sp_1',
    srt_index: 1,
    index: 0,
    hidden: false
  },
  {
    start_time: 20,
    end_time: 25,
    content: 'captions',
    type: WordType.Pronunciation,
    speaker_label: 'sp_1',
    srt_index: 1,
    index: 1,
    hidden: false
  },
  {
    start_time: 25,
    end_time: 30,
    content: 'go',
    type: WordType.Pronunciation,
    speaker_label: 'sp_1',
    srt_index: 1,
    index: 2,
    hidden: false
  },
  {
    start_time: 30,
    end_time: 35,
    content: 'here',
    type: WordType.Pronunciation,
    speaker_label: 'sp_1',
    srt_index: 1,
    index: 3,
    hidden: false
  }
];

export const PREVIEW_SPEAKER_STORE = {
  face_ids: [0, 1],
  time_analysis: [
    {
      time: '0:00:00',
      frame_number: 0,
      face_positions: [
        {
          face_id: 0,
          spk_label: '21497cbb-bef8-4c2e-9cad-31779f78c74a',
          crop_position: {
            top_left: {
              x: 0.0578,
              y: 0.188
            },
            bottom_right: {
              x: 0.1646,
              y: 0.3454
            }
          },
          crop_position_person: {
            top_left: {
              x: 0.0578,
              y: 0.188
            },
            bottom_right: {
              x: 0.1646,
              y: 0.3454
            }
          },
          crop_position_face: {
            top_left: {
              x: 0.0906,
              y: 0.213
            },
            bottom_right: {
              x: 0.125,
              y: 0.2972
            }
          }
        },
        {
          face_id: 1,
          spk_label: '6b00bbc9-cf85-4b93-b67e-ddeed66847c9',
          crop_position: {
            top_left: {
              x: 0.0458,
              y: 0.0417
            },
            bottom_right: {
              x: 0.1453,
              y: 0.1806
            }
          },
          crop_position_person: {
            top_left: {
              x: 0.0458,
              y: 0.0417
            },
            bottom_right: {
              x: 0.1453,
              y: 0.1806
            }
          },
          crop_position_face: {
            top_left: {
              x: 0.076,
              y: 0.0565
            },
            bottom_right: {
              x: 0.1156,
              y: 0.1426
            }
          }
        }
      ]
    }
  ] as any,
  times: [1030.3351],
  visible_face_ids: [0, 1],
  speaker_mapping: {
    '21497cbb-bef8-4c2e-9cad-31779f78c74a': [0],
    '6b00bbc9-cf85-4b93-b67e-ddeed66847c9': [1]
  },
  loading: false,
  adjustedBoxBoundaries: [
    {
      '0': {
        boundary_left_x: 0,
        boundary_right_x: 1,
        boundary_top_y: 0.1806,
        boundary_bottom_y: 1
      },
      '1': {
        boundary_left_x: 0,
        boundary_right_x: 1,
        boundary_top_y: 0,
        boundary_bottom_y: 0.188
      }
    }
  ],
  accurate: true
};

export const PREVIEW_TRANSCRIPT_SPEAKERS_WITH_DETAILS = {
  spk_1: {
    id: '21497cbb-bef8-4c2e-9cad-31779f78c74a',
    upload: '1ef8ab3a-d5cb-6d91-83b2-6e1fe9b64caf',
    broadcast: null,
    first_name: 'Speaker 1',
    last_name: '',
    company: '',
    title: '',
    label: 'spk_1',
    profile_picture_url: '',
    face_ids: [],
    parent_id: null,
    created_at: '2024-10-15T05:13:02.874977Z',
    updated_at: '2024-10-15T05:13:02.875430Z',
    pronouns: null as any,
    key: 'spk_1',
    color: 'text-teal-500',
    bgColor: 'bg-teal-500',
    speakingSlots: {
      '8.559999': 9.92,
      '30.39': 50.615,
      '171.61': 253.44,
      '282.6': 283.64,
      '467.95502': 593.605,
      '629.115': 631.70996,
      '706.21497': 774.78,
      '797.615': 931.015,
      '959.755': 985.45496,
      '996.11005': 999.47003,
      '1046.77': 1078.3099,
      '1088.005': 1140.255,
      '1201.05': 1203.45,
      '1261.925': 1325.255,
      '1339.26': 1428.09,
      '1473.9701': 1497.755,
      '1525.255': 1526.6951,
      '1543.855': 1550.575,
      '1648.5': 1656.565,
      '1661.205': 1691.295,
      '1708.82': 1710.0399
    },
    video: {} as any
  },
  spk_0: {
    id: '6b00bbc9-cf85-4b93-b67e-ddeed66847c9',
    upload: '1ef8ab3a-d5cb-6d91-83b2-6e1fe9b64caf',
    broadcast: null,
    first_name: 'Speaker 0',
    last_name: '',
    company: '',
    title: '',
    label: 'spk_0',
    profile_picture_url: '',
    face_ids: [],
    parent_id: null,
    created_at: '2024-10-15T05:13:02.946616Z',
    updated_at: '2024-10-15T05:13:02.946986Z',
    pronouns: null as any,
    key: 'spk_0',
    color: 'text-rose-400',
    bgColor: 'bg-rose-400',
    speakingSlots: {
      '6.48': 8.559999,
      '9.92': 30.39,
      '50.615': 171.61,
      '253.44': 282.6,
      '283.64': 467.95502,
      '593.605': 629.115,
      '631.70996': 706.21497,
      '774.78': 797.615,
      '931.015': 959.755,
      '985.45496': 996.11005,
      '999.47003': 1046.77,
      '1078.3099': 1088.005,
      '1140.255': 1201.05,
      '1203.45': 1261.925,
      '1325.255': 1339.26,
      '1428.09': 1473.51,
      '1497.755': 1525.255,
      '1526.6951': 1537.31,
      '1550.575': 1648.5,
      '1656.565': 1660.505,
      '1691.295': 1708.82
    },
    video: {} as any
  }
};
