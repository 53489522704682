import { TimelineElement } from '../../types';
import { getElementsTimelineForSpeaker } from './activeSpeaker/activeSpeaker';
import { getElementsTimelineForGrid } from './speakerGrid/speakerGrid';
import { Clip } from '@/domains/asset';

export function getElementsTimeline(clip: Clip): TimelineElement[] {
  let elementsTimeline: TimelineElement[] = [];

  const clipLayout = clip.asset_metadata.layout;
  const showSpeakerLabels = !!clip.asset_metadata.magicLayout?.showSpeakerLabels;

  if (clipLayout === 'DEFAULT' || clipLayout === 'AUDIOGRAM' || !showSpeakerLabels) {
    return elementsTimeline;
  }

  if (clipLayout === 'GRID') {
    const newElements: TimelineElement[] = getElementsTimelineForGrid(clip);
    elementsTimeline = elementsTimeline.concat(newElements);
  } else if (clipLayout === 'SPEAKER') {
    const newElements: TimelineElement[] = getElementsTimelineForSpeaker(clip);
    elementsTimeline = elementsTimeline.concat(newElements);
  }

  return elementsTimeline;
}
