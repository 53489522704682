import { memo, useSyncExternalStore } from 'react';
import { classnames } from '@/libs/utils';
import { speakersAnalysisStore } from '@/stores/speakersAnalysis/speakersAnalysis';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { getFaceLocationUrl } from '@/Pages/Clip/FaceSpeakerToggler/FaceSpeakerTogglerUtils';
import { core } from '@/stores/core';

function FacesGrid({
  speakerInfo,
  onSpeakerUpdate,
  clipId
}: {
  speakerInfo: SpeakerWithDetails;
  onSpeakerUpdate: (key: string, value: number[]) => void;
  clipId: string;
}) {
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);
  const speakersAnalysis = useSyncExternalStore(speakersAnalysisStore.subscribe, speakersAnalysisStore.getSnapshot);
  const faceIds = speakersAnalysis[clipId].face_ids;
  const speakerInfoFaceIds = speakerInfo.face_ids || [];

  const handleFaceIdToggle = faceId => {
    onSpeakerUpdate(
      'face_ids',
      speakerInfoFaceIds?.length > 0 && speakerInfoFaceIds.includes(faceId)
        ? speakerInfoFaceIds.filter((id: number) => id !== faceId)
        : [...speakerInfoFaceIds, faceId]
    );
  };

  return (
    faceIds?.length > 0 && (
      <div className="border-b p-2 pb-4">
        <div className="mt-4 text-sm font-medium leading-tight">Select the speaker‘s face</div>
        <div className="mt-3 grid grid-cols-7 gap-3">
          {faceIds.map((faceId: number) => (
            <img
              key={faceId}
              src={getFaceLocationUrl(coreStore.content!.project_id, coreStore.content!.id, clipId!, faceId)}
              alt={faceId.toString()}
              className={classnames(
                'aspect-square w-full cursor-pointer rounded-full border-2 border-slate-200 bg-cover bg-center',
                {
                  '!border-deep-orange-600': speakerInfoFaceIds?.includes(faceId),
                  'opacity-50': speakerInfoFaceIds?.length > 0 && !speakerInfoFaceIds?.includes(faceId)
                }
              )}
              onClick={() => handleFaceIdToggle(faceId)}
            />
          ))}
        </div>
      </div>
    )
  );
}

export default memo(FacesGrid);
