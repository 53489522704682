import React from 'react';

export default function AssetsTableRowSkeleton() {
  return Array.from({ length: 10 }).map((_, i) => (
    <div
      className="group flex w-full animate-pulse items-center justify-between space-x-4 border-b p-2"
      key={'assets-table-row-skeleton' + i}
    >
      <div className="w-[7.25rem] shrink-0">
        <div className="flex aspect-[4/3] items-center justify-center rounded-lg border border-transparent bg-slate-100"></div>
      </div>
      <div className="w-2/12 grow truncate">
        <div className="h-3 w-1/4 rounded bg-slate-100" />
        <div className="mt-1.5 h-2 w-32 bg-slate-100" />
      </div>
      <div className="w-24">
        <div className="flex items-center space-x-2.5">
          <div className="inline-flex text-xs">
            <div className="flex w-14 items-center justify-center space-x-1 rounded-full bg-slate-100 px-2 py-1 pr-2.5 text-slate-100">
              XX
            </div>
          </div>
        </div>
      </div>
      <div className="w-1/12">
        <div className="h-3.5 w-2/3 rounded bg-slate-100"></div>
      </div>
      <div className="relative flex w-12 justify-end"></div>
    </div>
  ));
}
