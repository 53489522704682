import { memo, useCallback, useState, useSyncExternalStore } from 'react';
import { assetsSavedSearchPartialUpdate } from '@goldcast/api/content';
import SpeakersList from '../../../atoms/SpeakersList/SpeakersList';
import SavedSearchSettingsActions from './SavedSearchSettingsActions';
import ContentSettingsWrapper from '../ContentSettingsWrapper';
import { openIdentifySpeakersDialog } from '../utils';
import RecordingDetailsStep from '@/Pages/TranscriptPage/SetupContent/steps/RecordingDetailsStep';
import { savedAISearch } from '@/stores/savedAISearch';

function SavedSearchSettings({
  onClose,
  selectedSpeakerId
}: {
  onClose: () => void;
  selectedSpeakerId: string | null;
}) {
  const savedAISearchStore = useSyncExternalStore(savedAISearch.subscribe, savedAISearch.getSnapshot);
  const [title, setTitle] = useState(savedAISearchStore?.title);

  const saveChanges = useCallback(() => {
    // Save details on close, if changed
    if (savedAISearchStore?.id && !!title) {
      assetsSavedSearchPartialUpdate({
        id: savedAISearchStore.id,
        body: {
          ...savedAISearchStore,
          title
        }
      }).then(() => {
        savedAISearch.update(val => ({
          ...val,
          title
        }));
      });
    }

    onClose();
  }, [savedAISearchStore, title]);

  function onRecordingUpdate(field: string, newValue: string) {
    if (field === 'title') setTitle(newValue);
  }

  return (
    <ContentSettingsWrapper onClose={saveChanges}>
      <div className="overflow-x-hidden py-5">
        <div className="px-5">
          {savedAISearchStore?.id && (
            <RecordingDetailsStep
              recordingDetails={{ title: savedAISearchStore?.title }}
              onRecordingUpdate={onRecordingUpdate}
              hideRecordingDate={true}
            />
          )}
          <SpeakersList speakerId={selectedSpeakerId} openIdentifySpeakersDialog={openIdentifySpeakersDialog} />
        </div>
        <SavedSearchSettingsActions />
      </div>
    </ContentSettingsWrapper>
  );
}

export default memo(SavedSearchSettings);
