import type { ButtonSize, ButtonVariation } from './ButtonTypes';
import { classnames } from '@/libs/utils';
import './Button.css';

export default function Button({
  variation = 'outline',
  buttonSize = 'base',
  disabled = false,
  onClick,
  children,
  className = '',
  title = '',
  trackingId,
  ...props
}: {
  variation?: ButtonVariation;
  buttonSize?: ButtonSize;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  children: React.ReactNode;
  className?: string;
  title?: string;
  trackingId: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
}) {
  return (
    <button
      {...props}
      disabled={disabled}
      onClick={onClick}
      data-testid={trackingId}
      className={classnames(
        'flex items-center justify-center font-medium focus:outline-none focus:ring-deep-orange-600 disabled:cursor-not-allowed disabled:shadow-none',
        buttonSize === 'base'
          ? 'rounded-lg px-3 py-2 text-xs'
          : buttonSize === 'large'
          ? 'rounded-lg px-4 py-2.5 text-sm'
          : buttonSize === 'full'
          ? 'h-12 w-full rounded-lg px-6 py-2 text-sm'
          : 'rounded-md px-2 py-1.5 text-2xs',
        variation === 'filled' ? 'btn-filled' : '',
        variation === 'outline' ? 'btn-outline' : '',
        variation === 'warn' ? 'btn-warn' : '',
        variation === 'text' ? 'btn-text' : '',
        variation === 'list' ? 'btn-list' : '',
        variation === 'success' ? 'btn-success' : '',
        variation === 'active' ? 'btn-active' : '',
        variation === 'checkbox' ? 'btn-checkbox' : '',
        className
      )}
      title={title}
    >
      {children}
    </button>
  );
}
