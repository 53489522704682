import { useMemo, useRef, useState } from 'react';
import { userSpeakerMergeCreate } from '@goldcast/api/content';
import InlineSelect, { Option } from '../InlineSelect';
import Button from '../Button/Button';
import Loader from '../Loader';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { core } from '@/stores/core';
import { useAppContext } from '@/context/AppContext/AppContext';
import { setNewCacheSuffix } from '@/context/TranscriptContext/TranscriptContextUtils';

export default function MergeSpeakersForm({
  allSpeakers,
  editingSpeakerId,
  onCloseForm
}: {
  allSpeakers: SpeakerWithDetails[];
  editingSpeakerId: string | null;
  onCloseForm: () => void;
}) {
  const [isErrorState, setIsErrorState] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const remainingSpeakers: Option[] = useMemo(() => {
    return allSpeakers
      .filter(s => s.id !== editingSpeakerId)
      .map(s => ({
        label: `${s.first_name} ${s.last_name}`,
        value: s.id
      }));
  }, [allSpeakers, editingSpeakerId]);

  const selectedSpeaker = useRef<null | string>(remainingSpeakers[0].value);
  const { logger } = useAppContext();

  function saveSpeakersMerge() {
    if (selectedSpeaker.current) {
      setIsSaving(true);
      userSpeakerMergeCreate({
        id: selectedSpeaker.current,
        body: { child_speaker: editingSpeakerId as string }
      })
        .then(() => {
          onCloseForm();
          EventBus.dispatch(CustomEvents.ReloadTranscript);
          EventBus.dispatch(CustomEvents.ReloadClip, { showLoader: true });
          core.update(data => ({ ...data, content: { ...data.content, allow_reset_speakers: true } }));

          setNewCacheSuffix(Math.random().toString());
        })
        .catch(error => {
          logger.error(error);
          setIsErrorState(true);
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  }

  function setSelectedSpeaker(val: string | null) {
    selectedSpeaker.current = val;
  }

  return (
    <div className="w-full p-0.5">
      <div>
        <label>
          <div className="mb-1 text-xs text-slate-600">Select a speaker</div>
        </label>
        <div className="!mt-1">
          <InlineSelect
            value={remainingSpeakers[0].value}
            options={remainingSpeakers}
            onChange={setSelectedSpeaker}
            data-testid="merge-speakers-select"
          />
        </div>
      </div>
      <div className="mt-1.5 text-xs text-slate-500">
        This will merge and replace the current speaker with the selected speaker. Use this feature when we incorrectly
        identified multiple speakers as different individuals, but they are actually the same person.
      </div>
      {isErrorState && (
        <div className="mt-1.5 text-xs text-deep-orange-600">
          Merging speakers didn't quite work. Try to refresh and give it another go.
        </div>
      )}

      <div className="mt-4 flex w-full items-center justify-end space-x-2">
        <Button variation="text" buttonSize="small" trackingId="cancel-button" onClick={onCloseForm}>
          Cancel
        </Button>
        <Button
          variation="filled"
          buttonSize="small"
          trackingId="save-button"
          onClick={saveSpeakersMerge}
          disabled={isSaving}
        >
          {isSaving && <Loader size="xs" />}
          Save
        </Button>
      </div>
    </div>
  );
}
