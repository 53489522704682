import React from 'react';
import { IconArrowDown, IconArrowUp } from '@tabler/icons-react';
import { AssetsTableQueryParams } from './AssetsTableUtils';
import { useUpdateQueryParams } from '@/hooks/url/useUpdateQueryParams';
import { useQueryParams } from '@/hooks/url/useQueryParams';

export default function AssetsTableHeaders({ disabled }) {
  const queryParams = useQueryParams<AssetsTableQueryParams>({});
  const { updateQueryParams } = useUpdateQueryParams<AssetsTableQueryParams>();

  const { sort_by: sortBy, desc } = queryParams;

  // need to do this because of shortcoming of qs lib to convert string boolean to boolean
  const sortDescending = desc !== undefined && JSON.parse(desc as unknown as string);

  const onHeaderClick = (key: 'title' | 'created_at') => {
    if (sortBy !== key) {
      updateQueryParams({
        sort_by: key,
        desc: true
      });
    } else {
      updateQueryParams({
        desc: !sortDescending
      });
    }
  };

  return (
    <div className="z-10 flex w-full items-center justify-between space-x-4 border-b border-b-slate-200 px-2 pb-1 pt-2 text-xs font-medium">
      <button
        className="group flex w-[7.25rem] items-center space-x-0.5"
        onClick={() => onHeaderClick('title')}
        disabled={disabled}
      >
        <div>Title</div>
        {sortBy === 'title' && <SortIcon sortDescending={sortDescending} />}
      </button>
      <div className="grow"></div>
      <div className="w-24">Type</div>
      <button
        className="group flex w-1/12 items-center"
        onClick={() => onHeaderClick('created_at')}
        disabled={disabled}
      >
        <div className="min-w-[80px]">Created at</div>
        {sortBy === 'created_at' && <SortIcon sortDescending={sortDescending} />}
      </button>
      <div className="flex w-12 cursor-pointer justify-end" />
    </div>
  );
}

function SortIcon({ sortDescending }) {
  return (
    <div className="flex h-7 w-7 shrink-0 items-center justify-center rounded-full group-hover:bg-slate-200">
      {sortDescending ? <IconArrowDown size={16} stroke={1.8} /> : <IconArrowUp size={16} stroke={1.8} />}
    </div>
  );
}
