import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AssetList } from '@goldcast/api/content';
import { Clip } from '@/domains/asset';

export default function useClipNavigate() {
  const navigate = useNavigate();

  const navigateToClip = useCallback(
    (clipData: Clip | AssetList, { downloadIntent }: { downloadIntent: boolean }) => {
      if (clipData.saved_search) {
        navigate(`/search/${clipData.saved_search.id}/clips?activeClipId=${clipData.id}`, {
          state: { downloadIntent }
        });
        return;
      }
      navigate(`/${clipData.content!.project.id}/${clipData.content!.id}/clips?activeClipId=${clipData.id}`, {
        state: { downloadIntent }
      });
    },
    [navigate]
  );

  return {
    navigateToClip
  };
}
