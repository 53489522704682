import React from 'react';
import EmptyProfilePicturePlaceHolder from './EmptyProfilePicturePlaceHolder';
import AudiogramImage from './AudiogramImage';
import { AudiogramCommonProps } from './types';
import AudiogramCaptions from './AudiogramCaptions';
import Waveform from '@/components/atoms/Waveform/Waveform';
import Captions from '@/components/molecules/Captions';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function Portrait({
  height,
  width,
  sizeMultiplier = 1,
  backgroundColor,
  textColor,
  activeSpeaker,
  truncatedName,
  truncatedTitle,
  fontFamilyStyle,
  isTemplatePreview,
  backgroundImage
}: AudiogramCommonProps) {
  const { playerStore } = useClipsContext();

  const backgroundStyle = !backgroundImage ? { backgroundColor } : { backgroundImage: `url(${backgroundImage})` };

  return (
    <div
      className="absolute left-0 top-0 flex h-full w-full flex-col overflow-hidden rounded bg-cover bg-center"
      style={{ color: textColor, ...backgroundStyle }}
    >
      <div
        className="bg-cover bg-center"
        data-testid="speaker-image"
        style={{
          height: height * 0.695 + 'px'
        }}
      >
        {activeSpeaker.id && !activeSpeaker.profile_picture_url ? (
          <EmptyProfilePicturePlaceHolder previewOnly={isTemplatePreview} />
        ) : (
          <AudiogramImage activeSpeaker={activeSpeaker} />
        )}
      </div>

      <div
        className="flex grow flex-col justify-between"
        style={{
          padding:
            width * (36 / 720) * sizeMultiplier +
            'px ' +
            width * 0.055 * sizeMultiplier +
            'px ' +
            width * 0.055 * sizeMultiplier +
            'px ' +
            width * 0.055 * sizeMultiplier +
            'px',
          height: height * 0.305 + 'px'
        }}
      >
        <div style={{ lineHeight: isTemplatePreview ? '0px' : width * 0.08 + 'px', textAlign: 'left' }}>
          <Captions previewOnly={isTemplatePreview}>
            <AudiogramCaptions
              width={width}
              fontFamilyStyle={fontFamilyStyle}
              sizeMultiplier={sizeMultiplier}
              textColor={textColor}
              fontSizeCorrectionFactor={isTemplatePreview ? 0.09 : 0.06}
            />
          </Captions>
        </div>
        <div className="flex items-center justify-between">
          <div className="w-full">
            <div
              className="w-full text-left"
              style={{
                fontSize: width * (28 / 720) * sizeMultiplier + 'px',
                lineHeight: width * 0.06 * sizeMultiplier + 'px',
                ...fontFamilyStyle
              }}
            >
              {truncatedName}
            </div>
            <div
              className="w-full text-left"
              style={{ fontSize: width * (28 / 720) * sizeMultiplier + 'px', ...fontFamilyStyle }}
            >
              {truncatedTitle}
            </div>
          </div>
          {textColor && <Waveform textColor={textColor} paused={playerStore.paused} height={height * 0.055} />}
        </div>
      </div>
    </div>
  );
}
