import { getElementsTimelineForGridRecording } from './speakerGridForRecording';
import { getElementsTimelineForGridUpload } from './speakerGridForUpload';
import { Clip } from '@/domains/asset';
import { isCustomUpload } from '@/libs/clipContentUtil';

export const getElementsTimelineForGrid = (clip: Clip) => {
  if (isCustomUpload()) {
    return getElementsTimelineForGridUpload(clip);
  } else {
    return getElementsTimelineForGridRecording(clip);
  }
};
