import { TimelineElement, TimelineElementType } from '../../../types';
import { FPS_24 } from '../../../constants';
import { getFontFamilyStyle } from '../../font';
import { Clip } from '@/domains/asset';
import { sharedAPI } from '@/stores/sharedAPI/sharedAPI';
import { getSpeakerNameAndTitle } from '@/Pages/Clip/CompositePlayer/SpeakerVideoUtils';
import { getSizeConfig } from '@/libs/sharedAPI/sizeConfig/SizeConfigFactory';

export const getElementsTimelineForSpeakerRecording = (clip: Clip) => {
  const elementsTimeline: TimelineElement[] = [];
  const clipId = clip.id;

  const sharedAPIStore = sharedAPI.getSnapshot()[clipId];
  const captions = sharedAPIStore.speakerSegmentStore.captions;

  const fontFamily = getFontFamilyStyle(clipId, clip.asset_metadata.font_location);

  const sizeConfig = getSizeConfig(clip.asset_metadata.size, clip.id, clip.asset_metadata.layout, 1);

  const transcriptStore = sharedAPIStore.transcriptStore;

  if (captions.length) {
    let currentSpeaker = '';
    const accumulatedWords: any[] = [];
    let fromFrame = 0;
    let toFrame = 0;

    let countArray: string[] = [];

    // {} is used for the last processing
    [...captions, {} as any]?.forEach(word => {
      if (word.speaker_label !== currentSpeaker) {
        if (accumulatedWords.length > 0) {
          toFrame = Math.ceil(
            (word?.processed_end_time ?? accumulatedWords[accumulatedWords.length - 1].processed_end_time) * FPS_24
          );

          const speakerDetails = transcriptStore.speakersWithDetails[accumulatedWords[0].speaker_label];

          const { name, title } = getSpeakerNameAndTitle(speakerDetails);

          const {
            container: containerStyles,
            name: nameStyles,
            title: titleStyles
          } = sizeConfig.getSpeakerNameTitleStyles({
            fontFamily,
            currentVideoNumber: 1,
            totalVideos: 1
          });

          let existingElementIndex = countArray.indexOf(name + title);

          if (existingElementIndex !== -1 && elementsTimeline[existingElementIndex].toFrame === fromFrame) {
            elementsTimeline[existingElementIndex].toFrame = toFrame;
          } else {
            if (existingElementIndex !== -1) {
              const lastElement = elementsTimeline[existingElementIndex];
              if (lastElement.toFrame < fromFrame) {
                existingElementIndex = -1;
              }
            }

            if (existingElementIndex === -1) {
              countArray.push(name + title);
              elementsTimeline.push({
                fromFrame,
                toFrame,
                style: {
                  ...containerStyles
                },
                type: TimelineElementType.CONTAINER,
                elements: [
                  {
                    fromFrame,
                    toFrame,
                    style: {
                      ...nameStyles
                    },
                    content: name,
                    type: TimelineElementType.TEXT
                  },
                  {
                    fromFrame,
                    toFrame,
                    style: {
                      ...titleStyles
                    },
                    content: title,
                    type: TimelineElementType.TEXT
                  }
                ]
              });
            }
          }

          accumulatedWords.splice(0, accumulatedWords.length);
          fromFrame = toFrame;
        }
        currentSpeaker = word.speaker_label;
      }
      accumulatedWords.push(word);
    });
  }

  return elementsTimeline.filter(a => a !== null);
};
