import React, { useEffect, useMemo, useSyncExternalStore } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { assetsSavedSearchRetrieve } from '@goldcast/api/content';
import {
  IconPlayerPlay,
  IconPlayerPlayFilled,
  IconThumbUp,
  IconThumbUpFilled,
  IconArticle,
  IconArticleFilled
} from '@tabler/icons-react';
import SessionDetailLayout from '../SessionDetail/SessionDetailLayout';
import { NavItemType } from '../SessionDetail/components/NavItem';
import { clearSavedAISearch, setSavedAISearch } from '@/stores/savedAISearch';
import { useAppContext } from '@/context/AppContext/AppContext';
import { showErrorToast } from '@/libs/toast/toast';
import { unseenClipIds } from '@/stores/unseenClips';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';

export default function SavedSearchDetailLayout({
  isContentUploading,
  isLoading
}: {
  isContentUploading?: boolean | undefined;
  isLoading: boolean;
}) {
  const unseenClipsStore = useSyncExternalStore(unseenClipIds.subscribe, unseenClipIds.getSnapshot);
  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const isAiSocialPostsEnabled = featureFlags[FeatureFlagKeys.Use_CL_AI_Social_Posts];
  const isAiBlogPostsEnabled = featureFlags[FeatureFlagKeys.Use_CL_AI_Blog_Posts];
  const { id } = useParams<{ id: string }>();
  const { logger } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    assetsSavedSearchRetrieve({ id: id! })
      .then(res => setSavedAISearch(res))
      .catch(err => {
        logger.error(`Error loading Saved Search with id ${id}. Stack trace: ${err}`);
        showErrorToast(`Whoops! We hit a snag while fetching the saved search. Please try again.`);
        navigate(-1);
      });

    return () => {
      clearSavedAISearch();
    };
  }, [id]);

  const nav: NavItemType[] = useMemo(() => {
    return [
      {
        icon: IconPlayerPlay,
        filledIcon: IconPlayerPlayFilled,
        label: 'Clips',
        href: `/search/${id}/clips`,
        unseenCount: unseenClipsStore.length,
        visible: true
      },
      {
        icon: IconThumbUp,
        filledIcon: IconThumbUpFilled,
        label: 'Social',
        href: `/search/${id}/social`,
        visible: isAiSocialPostsEnabled
      },
      {
        icon: IconArticle,
        filledIcon: IconArticleFilled,
        label: 'Blog',
        href: `/search/${id}/blog`,
        visible: isAiBlogPostsEnabled
      }
    ];
  }, [id, isAiSocialPostsEnabled, unseenClipsStore.length]);

  return <SessionDetailLayout isContentUploading={isContentUploading} isLoading={isLoading} navMenu={nav} />;
}
