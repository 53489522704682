import { useMemo } from 'react';
import ProgressBarSection from './ProgressBarSection';
import {
  ClipPlayerSection,
  useIntroRefReturn,
  useMainSectionRefReturn,
  useOutroRefReturn
} from '../ClipPlayerSections/ClipPlayerSectionsTypes';
import { getMainPlayerTime } from './utils';
import { formatTime } from '@/libs/core';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function ProgressBar({
  sections,
  currentSection,
  clipMetadata,
  outroPlayerProps,
  mainPlayerProps,
  introPlayerProps,
  onSeeked,
  disabled
}: {
  sections: ClipPlayerSection[];
  currentSection: ClipPlayerSection;
  clipMetadata: any;
  outroPlayerProps: useOutroRefReturn;
  mainPlayerProps: useMainSectionRefReturn;
  introPlayerProps: useIntroRefReturn;
  onSeeked: (index: number, time: number) => void;
  disabled?: boolean;
}) {
  const { clipId } = useClipsContext();
  const { outroDuration, outroCurrentTime } = outroPlayerProps;
  const { mainSectionDuration, mainSectionCurrentTime, mainSectionRef } = mainPlayerProps;
  const { introDuration, introCurrentTime } = introPlayerProps;

  const currentSectionIndex = useMemo(() => {
    return sections.indexOf(currentSection);
  }, [currentSection, sections]);

  const [totalDuration, durations] = useMemo(() => {
    const totalDuration = clipMetadata.duration + (introDuration || 0) + (outroDuration || 0);
    const durations = {
      intro: clipMetadata.intro ? introDuration : 0,
      main: clipMetadata.duration,
      outro: clipMetadata.outro ? outroDuration : 0
    };
    return [totalDuration, durations];
  }, [clipMetadata.duration, clipMetadata.intro, clipMetadata.outro, introDuration, outroDuration]);

  const currentTimes = useMemo(() => {
    return {
      intro: introCurrentTime,
      main: getMainPlayerTime(mainSectionRef.current, mainSectionCurrentTime, clipMetadata.start, clipId),
      outro: outroCurrentTime
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clipMetadata.start,
    introCurrentTime,
    mainSectionCurrentTime,
    outroCurrentTime,
    mainSectionRef,
    mainSectionRef.current
  ]);

  const currentAbsoluteTime = useMemo(() => {
    if (currentSection === 'intro') {
      return introCurrentTime;
    } else if (currentSection === 'main') {
      return currentTimes.main + durations.intro;
    } else {
      return durations.main + durations.intro + outroCurrentTime;
    }
  }, [
    mainSectionCurrentTime,
    introCurrentTime,
    outroCurrentTime,
    currentSection,
    clipMetadata,
    mainSectionDuration,
    introDuration,
    currentTimes
  ]);

  return (
    <div className="w-full">
      <div className="flex w-full items-center justify-between px-0.5 tabular-nums leading-none">
        <span className="w-[38px] select-none text-center text-[10px] text-slate-400">
          {formatTime(currentAbsoluteTime)}
        </span>
        <div className="flex w-full">
          {sections.map((section, index) => (
            <ProgressBarSection
              key={section}
              section={section}
              duration={durations[section]}
              totalDuration={totalDuration}
              currentTime={currentTimes[section]}
              status={currentSectionIndex > index ? 'past' : currentSectionIndex < index ? 'next' : 'current'}
              onSeeked={time => onSeeked(index, time)}
              disabled={disabled}
            />
          ))}
        </div>
        <span className="w-[38px] select-none text-center text-[10px] text-slate-400">{formatTime(totalDuration)}</span>
      </div>
    </div>
  );
}
