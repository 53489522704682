import { Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DurationDisplay from './DurationDisplay';
import DragHandle from './DragHandle/DragHandle';
import useExtendedCanvas from '../ClipTimeline/hooks/useExtendedCanvas';
import { DRAG_HANDLE_WIDTH } from './DragHandle/DragHandleUtils';
import FrameRenderer from './FrameRenderer';
import SectionProgressIndicator from './SectionProgressIndicator';
import SectionTimeIndicator from './SectionTimeIndicator';
import useTimelineSectionHover from './hooks/useTimelineSectionHover';
import DragOverlay from './DragOverlay';
import { getBounds, getNewTimeAfterMove, getPositionForDuration } from './ClipTimelineUtils';
import { ClipTimelineProps } from './ClipTimelineTypes';
import { INFINITE_SCROLL_RATE } from './ClipTimelineConstants';
import { classnames } from '@/libs/utils';
import { updateStartEndTime } from '@/stores/clip';
import { CLIP_MAX_DURATION } from '@/components/molecules/Transcript/constants';

const ClipTimelineSection: React.FC<ClipTimelineProps> = ({
  duration,
  singleFrameWidth,
  clipData,
  sessionStartTime,
  sessionEndTime,
  disabled,
  progressPercent,
  showProgress,
  onSeeked
}) => {
  /* #region useRef declarations */
  const sectionRef = useRef<HTMLButtonElement>(null);
  const currentDragPosition = useRef<number>(0);
  const dragDirection = useRef<'left' | 'right' | null>(null);
  const dragHandleType = useRef<'start' | 'end' | null>(null);
  const infiniteScrollIntervalId = useRef<ReturnType<typeof setInterval> | null>(null);
  const maxSectionWidth = useRef<number>(0);
  const widthDraggedWithInfiniteScroll = useRef<number>(0);
  /* #endregion */

  /* #region useState declarations */
  const [customWidth, setCustomWidth] = useState<number>(0);

  const [durationToDisplay, setDurationToDisplay] = useState(duration);

  const [showDragHandles, setShowDragHandles] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  // These x positions are to limit the drag handles to the bounds of the clip timeline
  const [startDragHandleX, setStartDragHandleX] = useState(0);
  const [endDragHandleX, setEndDragHandleX] = useState(0);

  // These x positions are to store the actual pixels moved
  const [positionStartDragX, setPositionStartDragX] = useState(0);
  const [positionEndDragX, setPositionEndDragX] = useState(0);

  // Need a separate state to store the moving start and end times when dragging infinitely
  const [frameOffsetWhenDragging, setFrameOffsetWhenDragging] = useState(0);

  const [movingStartForFilmstrip, setMovingStartForFilmstrip] = useState(clipData.asset_metadata.start);
  /* #endregion */

  /* #region variables saved with useMemo */
  const sectionClassName = useMemo(
    () =>
      classnames('group relative box-content h-20 min-h-0 w-full rounded-lg border-2 border-black', {
        // z-50 required for covering duration of other sections
        'z-50 border-white': isDragging
      }),
    [isDragging]
  );

  const movingStart = useMemo(() => {
    if (!isDragging) {
      return clipData.asset_metadata.start;
    }

    return getNewTimeAfterMove(clipData.asset_metadata.start, positionStartDragX, singleFrameWidth);
  }, [clipData.asset_metadata.start, isDragging, positionStartDragX, singleFrameWidth]);

  const movingEnd = useMemo(() => {
    if (!isDragging) {
      return clipData.asset_metadata.end;
    }

    return getNewTimeAfterMove(clipData.asset_metadata.end, positionEndDragX, singleFrameWidth);
  }, [clipData.asset_metadata.end, isDragging, positionEndDragX, singleFrameWidth]);

  const startOverlayWidth: number = useMemo(() => {
    if (!isDragging) return 0;

    const { clipTimelineBounds, sectionBounds } = getBounds(sectionRef.current);

    if (!clipTimelineBounds || !sectionBounds) return 0;

    const { left: clipTimelineLeftBound } = clipTimelineBounds;

    const { left: sectionLeftBound } = sectionBounds;

    // if the end drag handle is clicked and the start drag handle is not inside the main clip timeline bounds
    if (dragHandleType.current === 'end' && sectionLeftBound + startDragHandleX < clipTimelineLeftBound) {
      return 0;
    }

    return sectionLeftBound - clipTimelineLeftBound + startDragHandleX;
  }, [isDragging, startDragHandleX]);

  const endOverlayWidth: number = useMemo(() => {
    if (!isDragging) return 0;

    const { clipTimelineBounds, sectionBounds } = getBounds(sectionRef.current);
    if (!clipTimelineBounds || !sectionBounds) return 0;

    const { right: clipTimelineRightBound } = clipTimelineBounds;

    const { right: sectionRightBound } = sectionBounds;

    // if the start drag handle is clicked and the end drag handle is not inside the main clip timeline bounds
    // It is -endDragHandleX because the endDragHandleX is relative the right attribute
    if (dragHandleType.current === 'start' && sectionRightBound - endDragHandleX > clipTimelineRightBound) {
      return 0;
    }

    return clipTimelineRightBound - sectionRightBound + endDragHandleX;
  }, [isDragging, endDragHandleX]);

  /* #endregion */

  /* #region custom hooks */
  const { extendedCanvasStyles, setExtendedCanvasParams } = useExtendedCanvas({ isDragging, sectionRef });

  const { unitRelativeToStart, onMoveOverSection, onPointerUpInSection } = useTimelineSectionHover({
    sectionRef,
    disabled: isDragging,
    onSeeked
  });

  /* #endregion */

  const updateDragHandlePositions = useCallback(
    (pointerClientX: number) => {
      if (infiniteScrollIntervalId.current) return;

      const { clipTimelineBounds, sectionBounds } = getBounds(sectionRef.current);

      // get the bounds of the section in question
      if (!sectionBounds || !clipTimelineBounds) return;

      const { left: clipTimelineLeftBound, right: clipTimelineRightBound } = clipTimelineBounds;
      const { left: sectionLeftBound, right: sectionRightBound } = sectionBounds;

      const minimumWidth = 5 * singleFrameWidth;

      if (dragHandleType.current === 'start') {
        const maxRightBound = clipTimelineRightBound - DRAG_HANDLE_WIDTH;
        const maxLeftBound = clipTimelineLeftBound;

        const newStartDragHandleX =
          pointerClientX < maxLeftBound
            ? maxLeftBound - sectionLeftBound
            : pointerClientX > maxRightBound
            ? maxRightBound - sectionLeftBound
            : pointerClientX - sectionLeftBound;

        if (sectionRightBound - endDragHandleX - (sectionLeftBound + newStartDragHandleX) < minimumWidth) {
          return;
        }

        setStartDragHandleX(newStartDragHandleX);
        return;
      }

      if (dragHandleType.current === 'end') {
        const maxRightBound = clipTimelineRightBound;
        const maxLeftBound = clipTimelineLeftBound + DRAG_HANDLE_WIDTH;

        const newEndDragHandleX =
          pointerClientX > maxRightBound
            ? sectionRightBound - maxRightBound
            : pointerClientX < maxLeftBound
            ? sectionRightBound - maxLeftBound
            : sectionRightBound - pointerClientX;

        if (sectionRightBound - newEndDragHandleX - (sectionLeftBound + startDragHandleX) < minimumWidth) {
          return;
        }

        setEndDragHandleX(newEndDragHandleX);
        return;
      }
    },
    [startDragHandleX, endDragHandleX, singleFrameWidth]
  );

  const getUpdatedDuration = useCallback(
    (newPositionStartDragX: number, newPositionEndDragX: number) => {
      const pixelsMoved = dragHandleType.current === 'start' ? newPositionStartDragX : newPositionEndDragX;
      const pixelTime = pixelsMoved / singleFrameWidth;

      const timeDuration = dragHandleType.current === 'start' ? duration - pixelTime : duration + pixelTime;

      if (timeDuration < 5 || timeDuration > CLIP_MAX_DURATION) return;

      if (dragHandleType.current === 'start') {
        const timeDiff = timeDuration - duration;
        if (timeDuration > duration && timeDiff >= clipData.asset_metadata.start - sessionStartTime) {
          return;
        }
      }

      if (dragHandleType.current === 'end') {
        const timeDiff = timeDuration - duration;
        if (timeDuration > duration && timeDiff >= sessionEndTime - clipData.asset_metadata.end) {
          return;
        }
      }

      return timeDuration;
    },
    [
      singleFrameWidth,
      duration,
      clipData.asset_metadata.start,
      clipData.asset_metadata.end,
      sessionStartTime,
      sessionEndTime
    ]
  );

  /* #region infinite scroll callbacks */
  const startInfiniteScroll = useCallback(() => {
    if (!infiniteScrollIntervalId.current) {
      widthDraggedWithInfiniteScroll.current = 0;
      infiniteScrollIntervalId.current = setInterval(() => {
        if (dragHandleType.current === 'start') {
          setPositionStartDragX(prev => {
            const newValue =
              dragDirection.current === 'left' ? prev - INFINITE_SCROLL_RATE : prev + INFINITE_SCROLL_RATE;
            if (getUpdatedDuration(newValue, positionEndDragX)) {
              return newValue;
            }

            return prev;
          });
        } else if (dragHandleType.current === 'end') {
          setPositionEndDragX(prev => {
            const newValue =
              dragDirection.current === 'left' ? prev - INFINITE_SCROLL_RATE : prev + INFINITE_SCROLL_RATE;
            if (getUpdatedDuration(positionStartDragX, newValue)) {
              return newValue;
            }

            return prev;
          });
        }

        setFrameOffsetWhenDragging(prev =>
          dragDirection.current === 'left' ? prev + INFINITE_SCROLL_RATE : prev - INFINITE_SCROLL_RATE
        );
      });
    }
  }, [getUpdatedDuration, positionStartDragX, positionEndDragX]);

  const stopInfiniteScroll = useCallback(() => {
    if (infiniteScrollIntervalId.current) {
      widthDraggedWithInfiniteScroll.current =
        dragHandleType.current === 'start' ? positionStartDragX : positionEndDragX;
      clearInterval(infiniteScrollIntervalId.current);
      infiniteScrollIntervalId.current = null;
    }
  }, [positionStartDragX, positionEndDragX]);
  /* #endregion */

  /* #region pointer callbacks */
  const onPointerEnterSection = useCallback(() => {
    setShowDragHandles(true);
  }, []);

  const onPointerLeaveSection = useCallback(() => {
    if (isDragging) return;
    setShowDragHandles(false);
  }, [isDragging]);

  const onStartTimeDragHandleClick = useCallback(() => {
    dragHandleType.current = 'start';
    setExtendedCanvasParams();

    setMovingStartForFilmstrip(clipData.asset_metadata.start);

    setEndDragHandleX(getPositionForDuration(sectionRef.current, durationToDisplay, singleFrameWidth));
    setIsDragging(true);
  }, [setExtendedCanvasParams, durationToDisplay, singleFrameWidth, clipData.asset_metadata.start]);

  const onEndTimeDragHandleClick = useCallback(() => {
    dragHandleType.current = 'end';
    setExtendedCanvasParams();

    const { sectionBounds } = getBounds(sectionRef.current);
    if (!sectionBounds) return;
    setMovingStartForFilmstrip(
      clipData.asset_metadata.end - (sectionBounds.right - sectionBounds.left) / singleFrameWidth
    );

    setStartDragHandleX(getPositionForDuration(sectionRef.current, durationToDisplay, singleFrameWidth));
    setIsDragging(true);
  }, [setExtendedCanvasParams, durationToDisplay, singleFrameWidth, clipData.asset_metadata.end]);

  const onPointerMove = useCallback(
    e => {
      if (!isDragging) return;

      const { clipTimelineBounds, sectionBounds } = getBounds(sectionRef.current);

      // get the bounds of the section in question
      if (!sectionBounds || !clipTimelineBounds) return;

      const { left: clipTimelineLeftBound, right: clipTimelineRightBound } = clipTimelineBounds;
      const { left: sectionLeftBound, right: sectionRightBound } = sectionBounds;

      const pointerClientX = e.clientX;

      updateDragHandlePositions(pointerClientX);

      if (
        (dragHandleType.current === 'start' &&
          (pointerClientX < clipTimelineLeftBound || pointerClientX > clipTimelineRightBound)) ||
        (dragHandleType.current === 'end' &&
          (pointerClientX < clipTimelineLeftBound || pointerClientX > clipTimelineRightBound))
      ) {
        startInfiniteScroll();
        return;
      } else {
        stopInfiniteScroll();
      }

      if (pointerClientX < currentDragPosition.current) {
        dragDirection.current = 'left';
      } else if (pointerClientX > currentDragPosition.current) {
        dragDirection.current = 'right';
      }

      currentDragPosition.current = pointerClientX;

      let newPositionStartDragX = positionStartDragX;
      if (dragHandleType.current === 'start') {
        const offset = widthDraggedWithInfiniteScroll.current
          ? dragDirection.current === 'left'
            ? widthDraggedWithInfiniteScroll.current + sectionLeftBound - clipTimelineLeftBound
            : widthDraggedWithInfiniteScroll.current
          : 0;

        newPositionStartDragX = pointerClientX - sectionLeftBound + offset;
      }

      let newPositionEndDragX = positionEndDragX;
      if (dragHandleType.current === 'end') {
        const offset = widthDraggedWithInfiniteScroll.current
          ? dragDirection.current === 'right'
            ? widthDraggedWithInfiniteScroll.current + clipTimelineRightBound - sectionRightBound
            : widthDraggedWithInfiniteScroll.current
          : 0;

        newPositionEndDragX = pointerClientX - sectionRightBound + offset;
      }

      const duration = getUpdatedDuration(newPositionStartDragX, newPositionEndDragX);

      if (duration) {
        setDurationToDisplay(duration);

        if (dragHandleType.current === 'start') {
          setPositionStartDragX(newPositionStartDragX);
        }

        if (dragHandleType.current === 'end') {
          setPositionEndDragX(newPositionEndDragX);
        }
      }
    },
    [
      isDragging,
      positionStartDragX,
      positionEndDragX,
      startInfiniteScroll,
      stopInfiniteScroll,
      updateDragHandlePositions,
      getUpdatedDuration
    ]
  );

  const onPointerUp = useCallback(
    event => {
      dragHandleType.current = null;
      setStartDragHandleX(0);
      setEndDragHandleX(0);
      setPositionStartDragX(0);
      setPositionEndDragX(0);

      stopInfiniteScroll();
      widthDraggedWithInfiniteScroll.current = 0;

      if (positionStartDragX !== 0) {
        updateStartEndTime(
          clipData.id,
          'start',
          getNewTimeAfterMove(clipData.asset_metadata.start, positionStartDragX, singleFrameWidth)
        );
      }

      if (positionEndDragX !== 0) {
        updateStartEndTime(
          clipData.id,
          'end',
          getNewTimeAfterMove(clipData.asset_metadata.end, positionEndDragX, singleFrameWidth)
        );
      }

      setIsDragging(false);
      const { sectionBounds } = getBounds(sectionRef.current);
      if (sectionBounds) {
        const { clientX, clientY } = event;
        // if e.clientX and e.clientY are within the bounds of the section, then we don't want to hide the drag handles
        if (
          clientX >= sectionBounds.left &&
          clientX <= sectionBounds.right &&
          clientY >= sectionBounds.top &&
          clientY <= sectionBounds.bottom
        ) {
          setShowDragHandles(true);
        } else {
          setShowDragHandles(false);
        }
      }
    },
    [
      clipData.id,
      clipData.asset_metadata.start,
      clipData.asset_metadata.end,
      singleFrameWidth,
      positionStartDragX,
      positionEndDragX,
      stopInfiniteScroll
    ]
  );
  /* #endregion */

  /* #region useEffects */
  useEffect(() => {
    document.addEventListener('pointermove', onPointerMove);
    document.addEventListener('pointerup', onPointerUp);

    return () => {
      document.removeEventListener('pointermove', onPointerMove);
      document.removeEventListener('pointerup', onPointerUp);
    };
  }, [isDragging, onPointerUp, onPointerMove]);

  useEffect(() => {
    if (positionStartDragX === 0) return;

    const { clipTimelineBounds, sectionBounds } = getBounds(sectionRef.current);

    if (!clipTimelineBounds || !sectionBounds) return;

    const newDuration = getUpdatedDuration(positionStartDragX, positionEndDragX);
    if (!newDuration) return;

    if (startDragHandleX <= clipTimelineBounds.left - sectionBounds.left && dragDirection.current === 'left') {
      setEndDragHandleX(
        getPositionForDuration(sectionRef.current, newDuration, singleFrameWidth) +
          sectionBounds.left -
          clipTimelineBounds.left
      );
    }
  }, [positionStartDragX]);

  useEffect(() => {
    if (positionEndDragX === 0) return;

    const { clipTimelineBounds, sectionBounds } = getBounds(sectionRef.current);

    if (!clipTimelineBounds || !sectionBounds) return;

    const newDuration = getUpdatedDuration(positionStartDragX, positionEndDragX);
    if (!newDuration) return;

    if (endDragHandleX <= sectionBounds.right - clipTimelineBounds.right && dragDirection.current === 'right') {
      setStartDragHandleX(
        getPositionForDuration(sectionRef.current, newDuration, singleFrameWidth) +
          clipTimelineBounds.right -
          sectionBounds.right
      );
    }
  }, [positionEndDragX]);

  useEffect(() => {
    if (!sectionRef.current) return;

    if (!maxSectionWidth.current) {
      maxSectionWidth.current = sectionRef.current.clientWidth;
    }

    if (duration * singleFrameWidth < maxSectionWidth.current) {
      setCustomWidth(duration * singleFrameWidth);
    } else {
      setCustomWidth(0);
    }

    if (duration !== durationToDisplay) {
      setDurationToDisplay(duration);
    }

    setStartDragHandleX(0);
    setEndDragHandleX(0);
    setPositionStartDragX(0);
    setPositionEndDragX(0);

    const { clipTimelineBounds, sectionBounds } = getBounds(sectionRef.current);
    if (!clipTimelineBounds || !sectionBounds) return;

    setFrameOffsetWhenDragging(sectionBounds.left - clipTimelineBounds.left);
  }, [duration, singleFrameWidth]);
  /* #endregion */

  return (
    <button
      ref={sectionRef}
      className={sectionClassName}
      onPointerEnter={onPointerEnterSection}
      onPointerLeave={onPointerLeaveSection}
      onPointerMove={onMoveOverSection}
      onPointerUp={onPointerUpInSection}
      disabled={disabled}
      style={{
        ...(customWidth ? { width: customWidth + 'px' } : {})
      }}
    >
      {showDragHandles && (
        <Fragment>
          <DragHandle type="start" onPointerDown={onStartTimeDragHandleClick} positionX={startDragHandleX} />
          <DragHandle type="end" onPointerDown={onEndTimeDragHandleClick} positionX={endDragHandleX} />
        </Fragment>
      )}

      <DurationDisplay
        duration={isDragging ? (dragHandleType.current === 'start' ? movingStart : movingEnd) : durationToDisplay}
        shouldFloatOnTop={isDragging}
        floatX={dragHandleType.current}
      />

      <div className="absolute h-full" style={extendedCanvasStyles}>
        <DragOverlay type="start" overlayWidth={startOverlayWidth} />
        <FrameRenderer
          start={movingStart}
          end={movingEnd}
          shouldExpand={isDragging}
          sectionWidth={customWidth || sectionRef.current?.clientWidth}
          singleFrameWidth={singleFrameWidth}
          showBorderY={isDragging}
          frameOffsetWhenDragging={frameOffsetWhenDragging}
          movingStartForFilmstrip={movingStartForFilmstrip}
          projectId={clipData.content.project.id}
          contentId={clipData.content.id}
        />
        <DragOverlay type="end" overlayWidth={endOverlayWidth} />
      </div>

      {!isDragging && showProgress && <SectionProgressIndicator progressPercent={progressPercent} />}

      {!isDragging && <SectionTimeIndicator progressPercent={unitRelativeToStart * 100} />}
    </button>
  );
};

export default memo(ClipTimelineSection);
