import { useEffect, useState, useCallback, memo, useImperativeHandle, forwardRef, useMemo } from 'react';
import '@blocknote/core/fonts/inter.css';
import '@blocknote/mantine/style.css';
import { BlockNoteSchema, defaultBlockSpecs } from '@blocknote/core';
import { history } from '@tiptap/pm/history';
import { FormattingToolbar, FormattingToolbarController, useCreateBlockNote } from '@blocknote/react';
import { BlockNoteView } from '@blocknote/mantine';
import '@tiptap/extension-history';
import './TextEditor.scss';
import { TextEditorRef, TextEditorProps } from './types';
import { convertToMarkdown } from './utils';
import Button from '@/components/atoms/Button/Button';
import { clipPlayerBlock } from '@/Pages/PostsListPage/components/ClipPlayerBlock';
import { addNewClipBlock } from '@/Pages/PostsListPage/components/AddNewClipBlock';
import { titleBlock } from '@/Pages/PostsListPage/components/EditorTitleBlock';
import usePost from '@/hooks/usePost';
import { useAppContext } from '@/context/AppContext/AppContext';

const blockNoteSchema = BlockNoteSchema.create({
  blockSpecs: {
    ...defaultBlockSpecs,
    titleBlock: titleBlock,
    clipPlayer: clipPlayerBlock,
    addClip: addNewClipBlock
  }
});

const TextEditor = forwardRef<TextEditorRef, TextEditorProps>(
  ({ text, onChange, readonly, isMultimodalBlogPost, title }, ref) => {
    const [markdown, _] = useState<string>(text);
    const [titleText] = useState<string>(title || '');

    const [isEditorReady, setIsEditorReady] = useState(false);
    const [isGeneratingClip, setIsGeneratingClip] = useState(false);
    const [isTitleSelected, setIsTitleSelected] = useState(false);
    const { textToClipGeneration, textToClipSavedSearchGeneration } = usePost();
    const { logger } = useAppContext();

    const isSavedSearchPage = useMemo(() => window.location.pathname.includes('/search'), [window.location.pathname]);

    const editor = useCreateBlockNote({
      schema: blockNoteSchema
    });

    useImperativeHandle(ref, () => ({
      undo: () => {
        if (editor) {
          const command: any = editor._tiptapEditor.commands;
          command?.undo();
        }
      },
      redo: () => {
        if (editor) {
          const command: any = editor._tiptapEditor.commands;
          command?.redo();
        }
      }
    }));
    const generateClipFromText = async () => {
      const selectedText = editor.getSelectedText();
      const selectedBlock = editor.getSelection();
      if (!selectedBlock) return;
      try {
        setIsGeneratingClip(true);
        const clipPlayerText = isSavedSearchPage
          ? await textToClipSavedSearchGeneration(selectedText)
          : await textToClipGeneration(selectedText);

        const newBlock = {
          type: 'clipPlayer',
          content: [
            {
              type: 'text',
              text: clipPlayerText,
              styles: {}
            }
          ]
        } as any;
        setIsGeneratingClip(false);
        editor.insertBlocks([{ ...newBlock }], selectedBlock?.blocks?.[0].id, 'after');
      } catch (error) {
        logger.error('Error generating clip from text selection', error);
        setIsGeneratingClip(false);
      }
    };

    const updateDocumentWithAddClip = useCallback(
      document => {
        document.forEach((currentBlock, index: number) => {
          const previousBlock = document[index - 1];
          const nextBlock = document[index + 1];

          if (
            currentBlock.type === 'addClip' &&
            (!(previousBlock && previousBlock.type === 'paragraph') ||
              !(nextBlock && (nextBlock.type === 'paragraph' || nextBlock.type === 'heading')))
          ) {
            editor.removeBlocks([currentBlock.id]);
          }

          if (
            currentBlock.type === 'paragraph' &&
            nextBlock &&
            (nextBlock.type === 'paragraph' || nextBlock.type === 'heading') &&
            nextBlock.type !== 'addClip'
          ) {
            if (!currentBlock?.content[0]?.text) {
              return;
            }
            editor.insertBlocks([{ type: 'addClip' }], currentBlock.id, 'after');
          }
        });
      },
      [editor]
    );

    const handleEditorChange = useCallback(async () => {
      if (!onChange) return;

      if (!isEditorReady) {
        setIsEditorReady(true);
        return;
      }
      if (isMultimodalBlogPost) {
        updateDocumentWithAddClip(editor.document);
      }
      const markdownFromBlocks = convertToMarkdown(editor.document);

      onChange(markdownFromBlocks);
    }, [editor, onChange, isEditorReady]);

    useEffect(() => {
      async function loadInitialHTML() {
        const blocks = await editor.tryParseMarkdownToBlocks(markdown);
        const transformedBlocks = blocks
          .map(block => {
            if (
              block.content &&
              block?.content[0]?.text?.startsWith('::clipPlayer') &&
              block?.content[0]?.text?.endsWith('}')
            ) {
              block.type = 'clipPlayer';
            }
            return block;
          })
          .filter(block => block.type !== 'image');

        editor.replaceBlocks(editor.document, transformedBlocks);
        if (isMultimodalBlogPost) {
          updateDocumentWithAddClip(editor.document);
        }
        const titleBlock = {
          type: 'titleBlock',
          content: [
            {
              type: 'text',
              text: titleText,
              styles: {}
            }
          ]
        } as any;
        editor.insertBlocks([{ ...titleBlock }], transformedBlocks[0].id, 'before');
        editor._tiptapEditor.unregisterPlugin('history');
        editor._tiptapEditor.registerPlugin(history());
      }

      if (markdown) {
        loadInitialHTML();
      }
    }, [editor, markdown]);

    const handleTextSelection = () => {
      const selectedBlock = editor.getSelection()?.blocks[0];
      setIsTitleSelected(selectedBlock?.type === 'titleBlock');
    };

    return (
      <div>
        <BlockNoteView
          editor={editor}
          editable={!readonly}
          sideMenu={false}
          formattingToolbar={false}
          slashMenu={false}
          onChange={handleEditorChange}
          onSelectionChange={handleTextSelection}
          theme={'light'}
        >
          {isMultimodalBlogPost && !isTitleSelected ? (
            <FormattingToolbarController
              formattingToolbar={() => (
                <FormattingToolbar>
                  <Button
                    className="m-2"
                    variation="filled"
                    trackingId="create-clip-button"
                    onClick={generateClipFromText}
                    disabled={isGeneratingClip}
                  >
                    {isGeneratingClip ? ' Generating Clip...' : 'Generate and Add Clip'}
                  </Button>
                </FormattingToolbar>
              )}
            />
          ) : null}
        </BlockNoteView>
      </div>
    );
  }
);

export default memo(TextEditor);
