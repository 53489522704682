import { useEffect, useSyncExternalStore } from 'react';
import { player } from '@/stores/player';
import { userPreferencesStore } from '@/stores/userPreferencesStore';

export default function usePlayerControls() {
  const { isTimelineCollapsed } = useSyncExternalStore(
    userPreferencesStore.subscribe,
    userPreferencesStore.getSnapshot
  );

  useEffect(() => {
    return () => {
      player.update(data => ({ ...data, currentTime: 0, paused: true }));
    };
  }, []);

  return {
    isTimelineCollapsed
  };
}
