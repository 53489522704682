import createStore from './store';

/**
 * A store that manages the state of editing the full recording timeline.
 *
 * - `isDragging`: A boolean that indicates if the user is actively dragging a handle.
 *
 * Functions:
 * - `startDraggingFullRecoringTimeline`: Sets `isDragging` to `true` when a drag operation starts.
 * - `stopDraggingFullRecoringTimeline`: Sets `isDragging` to `false` when the drag operation ends.
 */

export interface EditFullRecordingStore {
  isDragging: boolean;
}

export const editFullRecording = createStore<EditFullRecordingStore>({ isDragging: false });

export function startDraggingFullRecoringTimeline() {
  editFullRecording.update(state => ({ ...state, isDragging: true }));
}

export function stopDraggingFullRecoringTimeline() {
  editFullRecording.update(state => ({ ...state, isDragging: false }));
}
