import createStore from './store';
import { Clip } from '@/domains/asset';

export const unseenClipIds = createStore<string[]>([]);

/**
 * Initialize the unseen clip ids store with the value from local storage.
 * Remove any unseen clip ids that are not in the list of all clips.
 */
export function initUnseenClipIds(allClips: Clip[]) {
  const unseenClipIdsFromLocalStorage = localStorage.getItem('unseenClipIds');

  if (!unseenClipIdsFromLocalStorage) {
    return;
  }

  const filteredUnseenClipIds = unseenClipIdsFromLocalStorage
    .split(',')
    .filter(id => allClips.some(clip => clip.id === id));

  unseenClipIds.set(() => filteredUnseenClipIds || []);
}

/**
 * Add clip ids to the list of unseen clip ids
 * @param clipIds Clip ids to add
 */
export function addUnseenClipIds(clipIds: string[]) {
  unseenClipIds.update(ids => {
    const newIds = clipIds.filter(id => !ids.includes(id));
    return [...ids, ...newIds];
  });

  localStorage.setItem('unseenClipIds', unseenClipIds.getSnapshot().join(','));
}

/**
 * Remove a clip id from the list of unseen clip ids
 * @param clipId Clip id to remove
 */
export function removeUnseenClipId(clipId: string) {
  if (!unseenClipIds.getSnapshot().includes(clipId)) {
    return;
  }

  unseenClipIds.update(ids => ids.filter(id => id !== clipId));
  localStorage.setItem('unseenClipIds', unseenClipIds.getSnapshot().join(','));
}

/**
 * Clear all unseen clip ids
 */
export function clearUnseenClipIds() {
  unseenClipIds.set(() => []);
  localStorage.setItem('unseenClipIds', '');
}
