import { memo, useCallback, useMemo, useRef } from 'react';
import useTimelineSectionHover from '../ClipTimeline/hooks/useTimelineSectionHover';
import DurationDisplay from '../ClipTimeline/DurationDisplay';
import { LANDSCAPE_WIDTH_IN_PX } from '../ClipTimeline/ClipTimelineConstants';
import SectionProgressIndicator from '../ClipTimeline/SectionProgressIndicator';
import SectionTimeIndicator from '../ClipTimeline/SectionTimeIndicator';
import IconButton from '@/components/atoms/Button/IconButton';

function VideoSection({
  section,
  removeSection,
  progressPercent,
  showProgress,
  onSeeked,
  duration
}: {
  section: 'intro' | 'outro';
  removeSection: (section: 'intro' | 'outro') => void;
  progressPercent: number;
  showProgress: boolean;
  onSeeked: (unitRelativeToStart: number) => void;
  duration: number;
}) {
  const sectionRef = useRef<HTMLDivElement>(null);

  const { unitRelativeToStart, onMoveOverSection, onPointerUpInSection } = useTimelineSectionHover({
    sectionRef,
    disabled: false,
    onSeeked
  });

  const onDeleteClick = useCallback(
    event => {
      event.stopPropagation();

      removeSection(section);
    },
    [section, removeSection]
  );

  const sectionWidth = useMemo(() => Math.round((120 * duration) / 5), [duration]);
  const shouldShowDuration = useMemo(() => sectionWidth >= LANDSCAPE_WIDTH_IN_PX, [sectionWidth]);

  return (
    <div
      className="group relative box-content flex h-20 cursor-pointer items-center justify-center !rounded-lg border-2 border-black bg-slate-100"
      style={{
        minWidth: sectionWidth + 'px',
        width: sectionWidth + 'px'
      }}
      onPointerMove={onMoveOverSection}
      onPointerUp={onPointerUpInSection}
      ref={sectionRef}
    >
      {shouldShowDuration && (
        <div className="absolute top-0 h-full w-full">
          <DurationDisplay duration={duration} shouldFloatOnTop={false} floatX={null} durationFormat={'mm:ss.SS'} />
        </div>
      )}

      <IconButton
        buttonClassName="absolute invisible group-hover:visible top-1 right-1 aspect-square h-7 w-7 z-40"
        icon="IconTrash"
        size="small"
        trackingId="section-delete-button"
        variation="filled"
        onClick={onDeleteClick}
      />

      {showProgress && <SectionProgressIndicator progressPercent={progressPercent} />}

      <SectionTimeIndicator progressPercent={unitRelativeToStart * 100} />
    </div>
  );
}

export default memo(VideoSection);
