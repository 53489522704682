import { Listbox } from '@headlessui/react';
import { IconCheck } from '@tabler/icons-react';
import { memo } from 'react';
import CaptionsMenuTooltip from './CaptionsMenuTooltip';
import Icon, { IconName } from '@/components/atoms/Icon';
import { classnames } from '@/libs/utils';

function CaptionsMenuDropdown({
  onChange,
  options,
  selectedValue,
  selectedLabel,
  tooltip,
  position = 'right',
  icon
}: {
  onChange: (val) => void;
  options: { value?: any; label: string }[];
  selectedValue: any;
  selectedLabel?: string;
  tooltip: string;
  position?: 'left' | 'right';
  icon?: IconName;
}) {
  return (
    <Listbox value={selectedValue} onChange={onChange}>
      {({ open }) => (
        <>
          <Listbox.Button className="group/button flex h-full w-full items-center justify-center truncate rounded-lg p-2 text-sm hover:bg-slate-100">
            {icon ? (
              <Icon name={icon} size={20} />
            ) : (
              <>
                <div className="grow truncate text-left">{selectedLabel || selectedValue}</div>
                <Icon name={open ? 'IconChevronUp' : 'IconChevronDown'} size={16} />
              </>
            )}
            <CaptionsMenuTooltip content={tooltip} />
          </Listbox.Button>
          <Listbox.Options
            className={classnames('fixed mt-1.5 w-48 flex-none rounded-lg border bg-white shadow', {
              'right-16': position === 'left'
            })}
          >
            <div className="p-2">
              {options.map(option => {
                return (
                  <Listbox.Option
                    key={option.value || option.label}
                    value={option.value || option.label}
                    className={({ selected }) =>
                      `flex cursor-pointer items-center justify-between truncate rounded-md p-2 text-sm hover:bg-slate-100 ${
                        selected ? 'bg-slate-100' : ''
                      }`
                    }
                  >
                    {({ selected }) => (
                      <>
                        <span className="truncate">{option.label}</span>
                        <div className="w-4">{selected && <IconCheck className="text-slate-600" size={16} />}</div>
                      </>
                    )}
                  </Listbox.Option>
                );
              })}
            </div>
          </Listbox.Options>
        </>
      )}
    </Listbox>
  );
}

export default memo(CaptionsMenuDropdown);
