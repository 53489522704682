import createStore from './store';

interface UserPreferences {
  isListCollapsed: boolean;
  isTimelineCollapsed: boolean;
}

export const userPreferencesStore = createStore<UserPreferences>({
  isListCollapsed: false,
  isTimelineCollapsed: false
});

export function updateUserPreferenceStore(key: keyof UserPreferences, value: UserPreferences[keyof UserPreferences]) {
  userPreferencesStore.update((prevState: UserPreferences) => ({
    ...prevState,
    [key]: value
  }));
}

export function toggleListCollapse() {
  updateUserPreferenceStore('isListCollapsed', !userPreferencesStore.getSnapshot().isListCollapsed);
}

export function toggleTimelineCollapse() {
  updateUserPreferenceStore('isTimelineCollapsed', !userPreferencesStore.getSnapshot().isTimelineCollapsed);
}
