import { getFontFamilyStyle } from '../../font';
import { TimelineElement, TimelineElementType } from '../../../types';
import { Clip } from '@/domains/asset';
import { sharedAPI } from '@/stores/sharedAPI/sharedAPI';
import { speakersAnalysisStore } from '@/stores/speakersAnalysis/speakersAnalysis';
import { getSizeConfig } from '@/libs/sharedAPI/sizeConfig/SizeConfigFactory';
import { getSpeakerNameAndTitle } from '@/Pages/Clip/CompositePlayer/SpeakerVideoUtils';
import { getSectionEndTimesAfterExcludesForUpload } from '@/libs/sharedAPI/common';
import { getFinalTimeAnalysisArray } from '@/libs/sharedAPI/activeSpeaker';
import { FPS_24 } from '@/App/remotion/constants';

export const getElementsTimelineForSpeakerUpload = (clip: Clip) => {
  const elementsTimeline: TimelineElement[] = [];
  const clipId = clip.id;

  const sharedAPIStore = sharedAPI.getSnapshot()[clipId];
  const transcriptStore = sharedAPIStore.transcriptStore;

  const speakersAnalysis = speakersAnalysisStore.getSnapshot()[clipId];

  const fontFamily = getFontFamilyStyle(clipId, clip.asset_metadata.font_location);

  const sizeConfig = getSizeConfig(clip.asset_metadata.size, clip.id, clip.asset_metadata.layout, 1);

  let fromFrame: number = 0;
  let toFrame: number = 0;

  const modifiedExcludes = clip.asset_metadata?.excludes?.map(exclude => {
    return { start: exclude[0], end: exclude[1] };
  });

  const { newTimeAnalysis, times } = getFinalTimeAnalysisArray(clip, sharedAPIStore);

  if (newTimeAnalysis.length) {
    const sectionEndTimes = getSectionEndTimesAfterExcludesForUpload(times, newTimeAnalysis, modifiedExcludes, 0, clip);

    // required to void sections after deletes
    sectionEndTimes.forEach((time, index) => {
      if (time === sectionEndTimes[index - 1]) {
        delete sectionEndTimes[index];
        delete newTimeAnalysis[index];
      }
    });

    // run through time analysis to get the face positions at the said times
    newTimeAnalysis.forEach((analysisPoint, index) => {
      let facePositions = analysisPoint.face_positions;

      if (facePositions.length > 1) {
        facePositions = facePositions.filter(facePosition =>
          speakersAnalysis.speaker_mapping?.[analysisPoint?.speakerId]?.includes(facePosition.face_id)
        );
      }

      toFrame = Math.ceil(sectionEndTimes[index] * FPS_24);

      if (facePositions.length === 0) {
        fromFrame = toFrame;
        return;
      }

      // for each visible face, add a video
      facePositions.forEach((facePosition, facePositionIndex) => {
        const cropPosition = facePosition.crop_position || facePosition.crop_position_face;

        if (!cropPosition) {
          return;
        }

        const speaker = Object.values(transcriptStore.speakersWithDetails).find(speaker =>
          speakersAnalysis.speaker_mapping[speaker.id]?.includes(facePosition.face_id)
        );

        if (!speaker) return;

        const {
          container: containerStyles,
          name: nameStyles,
          title: titleStyles
        } = sizeConfig.getSpeakerNameTitleStyles({
          fontFamily,
          currentVideoNumber: facePositionIndex + 1,
          totalVideos: 1
        });

        const { name, title } = getSpeakerNameAndTitle(speaker);

        elementsTimeline.push({
          fromFrame,
          toFrame,
          style: {
            ...containerStyles
          },
          type: TimelineElementType.CONTAINER,
          elements: [
            {
              fromFrame,
              toFrame,
              style: {
                ...nameStyles
              },
              content: name,
              type: TimelineElementType.TEXT
            },
            {
              fromFrame,
              toFrame,
              style: {
                ...titleStyles
              },
              content: title,
              type: TimelineElementType.TEXT
            }
          ]
        });
      });
      fromFrame = toFrame;
    });
  }

  return elementsTimeline.filter(a => a !== null);
};
