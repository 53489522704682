import { useEffect, useRef } from 'react';
import Icon, { CustomIconProps, IconName } from '../../Icon';
import useFilesDragNDrop from './useFilesDragNDrop';
import { classnames } from '@/libs/utils';
import classes from './FilesInput.module.scss';

export default function FilesInput({
  onFilesUpload,
  uploadedFiles = [],
  label = 'Click to browse, or drag & drop a file here',
  icon = 'IconCloudUpload',
  multiple = true,
  accept,
  rootClassNames = '',
  containerClassNames = '',
  labelClassNames = '',
  iconClassNames = '',
  title = '',
  iconProps = {}
}: {
  onFilesUpload: (files: File[]) => void;
  uploadedFiles?: File[];
  label?: string;
  icon?: IconName;
  accept: string;
  multiple?: boolean;
  rootClassNames?: string;
  containerClassNames?: string;
  labelClassNames?: string;
  iconClassNames?: string;
  title?: string;
  iconProps?: Omit<CustomIconProps, 'name'>;
}) {
  const fileInput = useRef<HTMLInputElement>(null);

  const { isDragging, handleFilesToUpload, uploadFiles, handleDragStart, handleDragStop } = useFilesDragNDrop({
    onFilesUpload,
    uploadedFiles,
    accept
  });

  useEffect(() => {
    if (window.openFilePickerOnDialogLoad) {
      window.openFilePickerOnDialogLoad = false;

      if (window.filesPickedForUpload?.length) {
        handleFilesToUpload(window.filesPickedForUpload);
        window.filesPickedForUpload = [];
      } else {
        fileInput.current?.click();
      }
    }
  }, []);

  return (
    <div
      className={classnames('h-full w-full transition-all duration-300 ease-in-out', rootClassNames)}
      onClick={() => fileInput.current?.click()}
      onDrop={uploadFiles}
      onDragOver={handleDragStart}
      onDragStart={handleDragStart}
      onDragLeave={handleDragStop}
      data-testid="files-container"
    >
      <div
        className={classnames(
          'relative flex h-full flex-col items-center justify-center !rounded-xl border border-slate-300 p-4 transition-all duration-150 ease-in-out hover:cursor-pointer hover:transition-all hover:duration-150 hover:ease-in-out',
          containerClassNames || classes['upload-container'],
          isDragging ? '!border-transparent' : ''
        )}
      >
        {isDragging && (
          <div className="absolute z-10 flex h-full w-full items-center justify-center !rounded-xl bg-deep-orange">
            <span className="text-xl font-medium">Drop it like it‘s hot</span>
          </div>
        )}
        <Icon
          name={icon}
          size={28}
          stroke={1.25}
          className={classnames('text-slate-500', classes['upload-icon'], iconClassNames)}
          {...iconProps}
        />
        <input
          type="file"
          accept={accept}
          className="invisible h-0"
          ref={fileInput}
          onChange={uploadFiles}
          multiple={multiple}
          data-testid="files-input"
        />
        {!!title && <div className="mt-3.5 text-sm">{title}</div>}
        <p
          className={classnames(
            'mt-2 max-w-[10rem] text-center text-sm leading-tight text-slate-500 transition-all duration-150 ease-in-out',
            isDragging ? 'opacity-20' : '',
            labelClassNames
          )}
        >
          {label}
        </p>
      </div>
    </div>
  );
}
