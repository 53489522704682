import { SetupContentStep } from './types';

export const PLAYER_SKIP_DURATION = 10;
export const AI_GENERATED_CLIPS_LIMIT = 30;

export const GENERATING_LABELS = ['Summoning magic', 'Calculating bits', 'Hunting unicorns', 'Brewing coffee'];

export const GENERATE_DEFAULT_LIMIT = 5;

export const RECORDING_DETAILS_STEP: SetupContentStep = {
  title: 'Recording details',
  key: 0
};
