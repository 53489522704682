import { Link } from 'react-router-dom';

export default function BrandKitInlineEmptyState({ label }: { label: string }) {
  return (
    <div className="w-full py-3 text-center text-xs text-slate-500">
      Setup {label} in{' '}
      <Link to={'/brand-kit'} className="underline hover:no-underline" data-testid="empty-brand-kit-link">
        Brand Kit
      </Link>
    </div>
  );
}
