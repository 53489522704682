import { SidebarContext } from './SideBarUtils';
import IconButton from '@/components/atoms/Button/IconButton';

export default function SideBarBreadcrumb({
  title,
  setSideBarContext
}: {
  title: string;
  infoElement?: React.JSX.Element;
  showCaptionsToggle?: boolean;
  setSideBarContext: (context: SidebarContext) => void;
}) {
  return (
    <>
      <div className="flex cursor-pointer items-center justify-between space-x-2 font-semibold">
        <IconButton
          icon="IconChevronLeft"
          iconClassName="h-5 w-5"
          variation="text"
          size="large"
          content={title}
          buttonClassName="!px-0"
          onClick={() => setSideBarContext(SidebarContext.Main)}
          trackingId="sidebar-breadcrumb-back"
        />
      </div>
    </>
  );
}
