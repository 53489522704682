import FaceToggler from './FaceToggler';
import SpeakerToggler from './SpeakerToggler';
import { isCustomUpload } from '@/libs/clipContentUtil';

// TODO @dusangc: Remove component with clip customizer FF cleanup
export default function FaceSpeakerToggler() {
  const isUpload = isCustomUpload();

  return <div className="flex flex-col">{isUpload ? <FaceToggler /> : <SpeakerToggler />}</div>;
}
