import moment from 'moment';

const TIME_STRING_FORMAT = 'HH:mm:ss.SSS';
export const TIME_INPUT_MASK = '**:99:69.999';

export function convertSecondsToTimeString(numberInSeconds: number, format = TIME_STRING_FORMAT) {
  return moment.utc(numberInSeconds * 1000).format(format);
}

export function convertTimeStringToSeconds(time: string) {
  return moment.duration(time).asSeconds();
}
