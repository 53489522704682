import { defaultProps } from '@blocknote/core';
import { createReactBlockSpec } from '@blocknote/react';
import PostPlayerWrapper from '@/Pages/PostsListPage/components/PostPlayerWrapper';
import ErrorBoundaryForComponent from '@/App/ErrorBoundary/ErrorBoundaryForComponent';

export const clipPlayerBlock = createReactBlockSpec(
  {
    type: 'clipPlayer',
    propSchema: {
      textAlignment: defaultProps.textAlignment,
      textColor: defaultProps.textColor
    },
    content: 'inline'
  },
  {
    render: (props: any) => {
      const postText = props?.block.content[0]?.text;
      const blockId = props?.block.id;
      let previousBlockText = '';
      props.editor.forEachBlock(blockNode => {
        if (blockNode.id === blockId) {
          return false;
        }
        previousBlockText = blockNode?.content?.[0]?.text ?? '';
        return true;
      });

      const onRemoveClip = () => {
        props.editor.removeBlocks([props.block.id]);
      };

      const onUpdate = (updatedPostText: string) => {
        props.editor.updateBlock(props.block.id, {
          content: [{ ...(props.block.content[0] || {}), text: updatedPostText }]
        });
      };

      return (
        <ErrorBoundaryForComponent customError="Failed to load clip! Please try again after some time.">
          <>
            <div className="hidden w-full" ref={props.contentRef}></div>
            <div
              className="box-border min-h-[552px] w-full border !border-slate-100 bg-slate-100 shadow-sm"
              contentEditable="false"
              suppressContentEditableWarning={true}
            >
              <PostPlayerWrapper
                blockId={blockId}
                postText={postText}
                onRemoveClip={onRemoveClip}
                onUpdatedPostText={onUpdate}
                previousBlockText={previousBlockText}
              />
            </div>
          </>
        </ErrorBoundaryForComponent>
      );
    }
  }
);
