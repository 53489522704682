import { memo, useMemo, useState } from 'react';
import { useCaptionContainerRef } from '../ClipPlayer/useCaptionContainerRef';
import PreviewSpeakerVideoCanvas from '../CanvasPlayer/CanvasPreview/PreviewSpeakerVideoCanvas';
import StaticCanvasPreview from '../CanvasPlayer/CanvasPreview/StaticCanvasPreview';
import CanvasPlayerDraggableCaptions from '../CanvasPlayer/CanvasPlayerDraggableCaptions/CanvasPlayerDraggableCaptions';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { Clip } from '@/domains/asset';
import { getFontSize } from '@/libs/sharedAPI/common';

function ClipInterimThumbnailCanvasPreview({
  clip,
  clipThumbnailRef,
  firstSpeaker
}: {
  clip: Clip;
  clipThumbnailRef: React.RefObject<HTMLImageElement>;
  firstSpeaker: SpeakerWithDetails;
}) {
  const backgroundStyle = useMemo(() => {
    if (!clip.asset_metadata.magicLayout?.backgroundImage)
      return {
        backgroundColor: clip.asset_metadata.magicLayout?.backgroundColor
      };
    return { backgroundImage: `url(${clip.asset_metadata.magicLayout?.backgroundImage})` };
  }, [clip.asset_metadata.magicLayout?.backgroundImage, clip.asset_metadata.magicLayout?.backgroundColor]);

  const showSpeakerLabels =
    clip.asset_metadata.magicLayout?.showSpeakerLabels && clip.asset_metadata.layout !== 'DEFAULT';

  const [captionContainerSizes, setCaptionContainerSizes] = useState({
    width: 0,
    height: 0
  });
  const { captionContainerRef } = useCaptionContainerRef(setCaptionContainerSizes, clip.asset_metadata);

  // @kashish TODO - Refactor and move this to a util. Try to combine this with size config based usage in CanvasPlayer and CanvasPreview
  const [captionFontSize, captionsHeight] = useMemo(() => {
    let VIDEO_BOTTOM_PADDING = 0;
    let VIDEO_SURROUND_PADDING = 0;
    const HEIGHT = 1080;

    if (clip.asset_metadata.size === 'LANDSCAPE') {
      VIDEO_BOTTOM_PADDING = 210;
      VIDEO_SURROUND_PADDING = 16;
    } else if (clip.asset_metadata.size === 'PORTRAIT') {
      VIDEO_BOTTOM_PADDING = 380;
      VIDEO_SURROUND_PADDING = 20;

      if (clip.asset_metadata.layout === 'DEFAULT') {
        VIDEO_BOTTOM_PADDING = 800;
      }
    } else if (clip.asset_metadata.size === 'SQUARE') {
      VIDEO_BOTTOM_PADDING = 210;
      VIDEO_SURROUND_PADDING = 16;

      if (clip.asset_metadata.layout === 'DEFAULT') {
        VIDEO_BOTTOM_PADDING = 320;
      }
    }

    const multiplier = 1.4;
    const fontSize = getFontSize({
      clipSize: clip.asset_metadata.size,
      playerHeight: captionContainerSizes.height,
      scale: multiplier
    });
    const videoBottomPadding = VIDEO_BOTTOM_PADDING * multiplier;
    const videoSurroundPadding = VIDEO_SURROUND_PADDING * multiplier;
    const height = HEIGHT * multiplier;

    return [fontSize, ((videoBottomPadding - videoSurroundPadding) / height) * captionContainerSizes.height];
  }, [captionContainerSizes]);

  return (
    <div
      className="absolute top-0 h-full w-full overflow-hidden rounded-md bg-cover bg-center"
      style={{ ...backgroundStyle }}
      id="player-container-preview"
    >
      <div className="relative grid h-full items-start" data-testid="composite-player" ref={captionContainerRef}>
        <PreviewSpeakerVideoCanvas
          mainPlayer={clipThumbnailRef}
          clipMetadata={clip.asset_metadata}
          currentSpeaker={firstSpeaker}
          secondaryPlayers={[]}
        />
        {showSpeakerLabels && (
          <StaticCanvasPreview
            mainPlayer={clipThumbnailRef}
            secondaryPlayers={[]}
            currentSpeaker={firstSpeaker}
            clipMetadata={clip.asset_metadata}
          />
        )}
        {!!captionsHeight && (
          <CanvasPlayerDraggableCaptions
            captionFontSize={captionFontSize}
            clipMetadata={clip.asset_metadata}
            isPreview={true}
            clipId={clip.id}
            isDisabled={true}
          />
        )}
      </div>
    </div>
  );
}

export default memo(ClipInterimThumbnailCanvasPreview);
