import { getBrandKitUrl } from '../utils';
import { getFileNameFromPath } from '@/libs/file';
import IconButton from '@/components/atoms/Button/IconButton';
import useDialog from '@/components/organisms/useDialog';
import ConfirmationDialog from '@/components/organisms/ConfirmationDialog';

export default function TemplateBackgroundPreview({
  imageUrl,
  onDelete
}: {
  imageUrl: string;
  onDelete: (imageUrl) => void;
}) {
  const {
    isOpen: isDeleteConfirmationOpen,
    openDialog: openDeleteConfirmationDialog,
    closeDialog: closeDeleteConfirmationDialog
  } = useDialog();
  return (
    <div className="flex aspect-[16/11] w-56 flex-col items-center justify-center overflow-hidden rounded-lg border border-slate-200">
      <div
        className="w-full grow bg-cover bg-center"
        style={{ backgroundImage: `url(${getBrandKitUrl(imageUrl)}` }}
      ></div>
      <div className="flex w-full items-center justify-between space-x-1.5 px-2.5">
        <div className="grow items-center truncate py-3 text-xs">{getFileNameFromPath(imageUrl)}</div>
        <div className="shrink-0">
          <IconButton
            icon="IconTrash"
            size="small"
            variation="text"
            onClick={openDeleteConfirmationDialog}
            trackingId="delete-video-background-button"
          />
        </div>
      </div>
      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        onClose={closeDeleteConfirmationDialog}
        onConfirm={() => onDelete(imageUrl)}
        title="Delete Brand Template Background?"
        content="You're about to remove a brand template background you've chosen. Don't worry, this won't change any of the template background's you've already used around the platform."
        confirmLabel="Yes"
      />
    </div>
  );
}
