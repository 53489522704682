import { IconTemplate } from '@tabler/icons-react';
import { memo } from 'react';
import Button from '@/components/atoms/Button/Button';

const TemplateSelectEmptyState = ({
  title,
  description,
  actionText,
  onActionClick
}: {
  title: string;
  description: string;
  actionText: string;
  onActionClick: () => void;
}) => {
  return (
    <div className="col-span-4 flex h-full items-center justify-center py-12">
      <div className="flex flex-col items-center justify-center p-8 text-center">
        <IconTemplate className="mb-4 h-6 w-6 text-slate-400" />
        <h3 className="mb-2 text-lg font-semibold text-slate-700">{title}</h3>
        <p className="mb-6 max-w-md text-slate-500">{description}</p>
        <Button
          trackingId="template-select-empty-state-action-button"
          variation="filled"
          buttonSize="large"
          onClick={onActionClick}
        >
          {actionText}
        </Button>
      </div>
    </div>
  );
};

export default memo(TemplateSelectEmptyState);
