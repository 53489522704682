import { UserWorkspaceResponse, User } from '@goldcast/api/backend';
import createStore from './store';

export const currentUser = createStore<User | undefined>(undefined);

export function initUser(adminAppStore): Promise<UserWorkspaceResponse> {
  const adminAppUser = adminAppStore.getters?.['user/getCurrentUser'];
  const accessToken = adminAppStore.getters?.['user/getJwtAccessToken'];
  if (!!adminAppUser && accessToken) {
    const user = JSON.parse(JSON.stringify(adminAppUser));
    currentUser.set(() => user);
    window.accessToken = accessToken;
    return Promise.resolve(user);
  } else {
    return Promise.reject(`User doesn't exist`);
  }
}
