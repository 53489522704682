import { SearchParagraphWord, SearchResult } from '../types';

/**
 * Finds and returns all word occurrences containing the specified editing word content
 * from an array of search results (paragraphs) in order to replace all occurrences
 *
 * @param {SearchResult[]} searchParagraphs - An array of search results where each result
 * contains words within a paragraph.
 * @param {string} editingWordContent - The new content to replace in all occurrences
 *
 * @returns {SearchParagraphWord[]} An array of `SearchParagraphWord` objects, where each
 * object corresponds to a word from the search results containing the `editingWordContent`.
 */
export function getAllEditingWordOccurrences(
  searchParagraphs: SearchResult[],
  editingWordContent: string
): SearchParagraphWord[] {
  return searchParagraphs.reduce((acc: SearchParagraphWord[], el) => {
    const word = el.words.find(w => w.content.includes(editingWordContent));
    if (word) acc.push(word);
    return acc;
  }, []);
}
