import {
  initialize,
  Event as SDKEvent,
  Target,
  Result,
  VariationValue,
  Evaluation
} from '@harnessio/ff-javascript-client-sdk';

export interface FeatureFlag {
  value: boolean;
  changeHandler?: (_value: boolean) => void | undefined;
}
export interface FeatureFlags {
  [key: string]: FeatureFlag;
}

let client: Result;

function sanitizeNameString(inputString: string): string {
  const pattern: RegExp = /^[A-Za-z0-9.@_-]*$/;
  if (pattern.test(inputString)) {
    return inputString;
  }
  const cleanedString: string = inputString.replace(/[^A-Za-z0-9.@_-]/g, '');
  return cleanedString;
}

function sanitizeIdentifierString(inputString: string): string {
  const pattern: RegExp = /^[\\p{L}\\d .@_-]*$/;
  if (pattern.test(inputString)) {
    return inputString;
  }
  const cleanedString: string = inputString.replace(/^[\\p{L}\\d .@_-]*$/g, '');
  return cleanedString;
}

export const init = (
  apiKey: string,
  target: Target,
  featureFlags: Record<string, FeatureFlag>,
  {
    ready = (_flags: Record<string, boolean>) => {},
    change = (_changeInfo: Evaluation) => {},
    errored = (_error: unknown) => {},
    errorAuth = (_error: unknown) => {}
  },
  options: {
    enableStreaming?: boolean;
  } = {
    enableStreaming: true
  }
) => {
  if (client) {
    console.log('Feature flags already initialized');
    client.off(SDKEvent.READY, onReady);
    client.off(SDKEvent.CHANGED, onChange);
    client.off(SDKEvent.ERROR_AUTH, onErrorAuth);
    client.off(SDKEvent.ERROR, onError);
    client.close();
  }

  // Sanitize target
  target.identifier = sanitizeIdentifierString(target.identifier);
  if (target?.name) {
    target.name = sanitizeNameString(target?.name);
  }

  // Initialize the SDK
  client = initialize(apiKey, target, {
    cache: true,
    debug: true,
    streamEnabled: options?.enableStreaming
  });

  const setFlags = (flags: Record<string, boolean>) => {
    Object.keys(flags).forEach(flag => {
      updateFlag(flag);
    });
  };

  const updateFlag = (flag: string) => {
    if (Object.keys(featureFlags).includes(flag)) {
      const value = client.variation(flag, featureFlags[flag].value) as boolean;
      if (featureFlags[flag].value !== value) {
        featureFlags[flag].value = value;
        featureFlags[flag].changeHandler?.(value);
      }
    }
  };

  function onReady(flags: Record<string, VariationValue>) {
    console.log('READY', flags);
    ready(flags as Record<string, boolean>);
    setFlags(flags as Record<string, boolean>);
  }

  function onChange(changeInfo: Evaluation) {
    console.log('CHANGED', changeInfo.flag, changeInfo.value);
    change(changeInfo);
    updateFlag(changeInfo.flag);
  }

  function onError(error: unknown) {
    console.log('ERROR', error);
    errored(error);
  }

  function onErrorAuth(error: unknown) {
    console.log('ERROR AUTH', error);
    errorAuth(error);
    //now call all features flag handle change with default value
    Object.keys(featureFlags).forEach(flag => {
      featureFlags[flag].changeHandler?.(featureFlags[flag].value);
    });
  }

  // Listen for events
  // Event happens when connection to server is established
  client.on(SDKEvent.READY, onReady);

  // Event happens when a changed event is pushed
  client.on(SDKEvent.CHANGED, onChange);

  client.on(SDKEvent.ERROR_AUTH, onErrorAuth);

  client.on(SDKEvent.ERROR, onError);
};
