import { Popover } from '@headlessui/react';
import { IconTexture } from '@tabler/icons-react';
import { useMemo } from 'react';
import ImagePreview from './ImagePreview';
import FilesInput from '@/components/atoms/inputs/FilesInput/FilesInput';

export default function ImagePicker({
  defaultImagesUrls = [],
  defaultImagesLabel,
  selectedImage,
  uploadedImagesUrls = [],
  children = <></>, // empty state for default images
  onDelete,
  onImagesUploaded,
  onImageSelected
}: {
  defaultImagesUrls: string[];
  defaultImagesLabel: string;
  selectedImage?: string | undefined;
  uploadedImagesUrls: string[];
  children?: React.ReactNode;
  onImagesUploaded: (files: File[]) => any;
  onDelete: (imageUrl) => void;
  onImageSelected: (url: string) => void;
}) {
  const uploadedImages = useMemo(() => {
    if (!!selectedImage && !uploadedImagesUrls.includes(selectedImage) && !defaultImagesUrls.includes(selectedImage)) {
      // If selected image is not in the lists of images - show it in uploaded list
      return [...uploadedImagesUrls, selectedImage];
    }
    return uploadedImagesUrls;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedImagesUrls]);

  return (
    <Popover className="relative h-full w-full">
      <div className="flex h-full w-full items-center justify-center">
        <Popover.Button className="flex h-full w-full items-center justify-center overflow-hidden rounded-full border border-slate-300 bg-slate-100 focus:outline-none focus:ring-0">
          {selectedImage ? (
            <div className="h-full w-full bg-cover bg-center" style={{ backgroundImage: `url(${selectedImage})` }} />
          ) : (
            <div className="h-full w-[1px] rotate-45 bg-slate-300" />
          )}
        </Popover.Button>
      </div>
      <Popover.Panel className="absolute right-0 top-9 z-30 w-64 overflow-hidden rounded-lg border border-slate-200 bg-white shadow-lg">
        <div className="flex items-center justify-center p-2" onClick={e => e.stopPropagation()}>
          {!uploadedImages.length ? (
            <FilesInput
              onFilesUpload={onImagesUploaded}
              accept={'.jpeg,.png'}
              label="Upload a file"
              multiple={false}
              icon="IconUpload"
            />
          ) : (
            <div className="flex w-full flex-wrap gap-1">
              {uploadedImages.map(image => {
                return (
                  <ImagePreview
                    key={image}
                    imageUrl={image}
                    isSelected={selectedImage === image}
                    onDelete={() => onDelete(image)}
                    onImageSelected={onImageSelected}
                  />
                );
              })}
              <div className="h-14 w-14">
                <FilesInput
                  onFilesUpload={onImagesUploaded}
                  accept={'.jpeg,.png'}
                  label=""
                  multiple={false}
                  icon="IconUpload"
                  containerClassNames="!p-3"
                />
              </div>
            </div>
          )}
        </div>
        <div className="relative mt-4 flex items-center justify-center border-t pb-1.5 pl-2 pt-2.5">
          <div className="absolute -top-3.5 flex items-center space-x-0.5 rounded-full border bg-white px-3 py-1">
            <IconTexture size={16} />
            <span className="text-xs">{defaultImagesLabel}</span>
          </div>
          <div className="mt-2 flex max-h-28 w-full flex-wrap gap-1 overflow-y-scroll">
            {defaultImagesUrls.length
              ? defaultImagesUrls.map(image => {
                  return (
                    <ImagePreview
                      key={image}
                      imageUrl={image}
                      isSelected={selectedImage === image}
                      onImageSelected={onImageSelected}
                    />
                  );
                })
              : children}
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  );
}
