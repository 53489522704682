import { memo, useMemo, useRef } from 'react';
import {
  getGreaterMultipleOfN,
  getLesserMultipleOfN,
  roundToNearestHalf,
  roundToNearestHigherHalf,
  roundToNearestLowerHalf
} from './ClipTimelineUtils';
import {
  FRAME_HEIGHT_IN_PX,
  LANDSCAPE_WIDTH_IN_PX,
  NUMBER_OF_COLUMNS_IN_FILMSTRIP,
  NUMBER_OF_ROWS_IN_FILMSTRIP
} from './ClipTimelineConstants';
import Loader from '@/components/atoms/Loader';

const Filmstrip = ({
  filmstripFilenames,
  shouldExpand,
  singleFrameWidth,
  start,
  end,
  frameOffsetWhenDragging,
  sectionWidth,
  showBorderY,
  movingStartForFilmstrip
}: {
  filmstripFilenames: string[];
  shouldExpand: boolean;
  singleFrameWidth: number;
  start: number;
  movingStartForFilmstrip: number;
  end: number;
  frameOffsetWhenDragging: number;
  sectionWidth?: number;
  showBorderY?: boolean;
}) => {
  const frameCounterRef = useRef(0);

  const greatestPossibleStart = useMemo(() => {
    return getLesserMultipleOfN(start, 15);
  }, [start]);

  const lowestPossibleEnd = useMemo(() => {
    return getGreaterMultipleOfN(end, 15);
  }, [end]);

  const filmstripImagePathsToLoad = useMemo(() => {
    return filmstripFilenames
      .map((imagePath, index) => ({
        imagePath,
        index
      }))
      .filter((_, index) => {
        const time = index * 15;

        return time >= greatestPossibleStart && time <= lowestPossibleEnd;
      });
    // do not include filmstripFilenames here, include everything else
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [greatestPossibleStart, lowestPossibleEnd]);

  const getFramesFromImage = ({
    imagePath,
    index,
    timeFrames,
    frameCount
  }: {
    imagePath: string;
    index: number;
    timeFrames: number;
    frameCount: number;
  }) => {
    const frames: {
      imagePath: string;
      row: number;
      col: number;
      left: number;
      time: number;
    }[] = [];

    const startValue = index * 15;

    const actualStartForFrame = roundToNearestLowerHalf(
      shouldExpand
        ? movingStartForFilmstrip -
            Math.ceil(frameOffsetWhenDragging !== 101 ? frameOffsetWhenDragging / singleFrameWidth : 0)
        : start
    );

    // 4s is equal to 4 frames. Want 2 frames on either side
    const startWhenExpanded = roundToNearestLowerHalf(actualStartForFrame - 4);

    for (let row = 0; row < NUMBER_OF_ROWS_IN_FILMSTRIP; row++) {
      for (let col = 0; col < NUMBER_OF_COLUMNS_IN_FILMSTRIP; col++) {
        if (frameCounterRef.current === 0) continue;

        const relativeValue = 0.5 * col + 2.5 * row;
        const absoluteValue = startValue + relativeValue;

        if (!shouldExpand) {
          const diff =
            roundToNearestHigherHalf(
              actualStartForFrame + timeFrames * roundToNearestLowerHalf(frameCount - frameCounterRef.current)
            ) === roundToNearestHigherHalf(absoluteValue);

          if (diff) {
            frames.push({
              imagePath,
              row,
              col,
              time: absoluteValue,
              left: 0
            });

            frameCounterRef.current -= 1;
          }
        } else {
          if (absoluteValue < startWhenExpanded) continue;

          // diff is true when diff between absoluteValue and start is a multiple of 1
          const diff = roundToNearestHalf(absoluteValue - actualStartForFrame);

          if (diff % 1 === 0) {
            frames.push({
              imagePath,
              row,
              col,
              time: absoluteValue,
              left:
                diff * singleFrameWidth +
                (frameOffsetWhenDragging > 101 ? frameOffsetWhenDragging % singleFrameWidth : frameOffsetWhenDragging)
            });
          }
        }
      }
    }
    return frames;
  };

  const frames = useMemo(() => {
    let availableFrames: number = 0;
    let maxAllowedFrames: number = 5;

    const maxWidth = document.getElementById('timeline-main-boundary')?.clientWidth;

    if (maxWidth) {
      // +8 to account for when scrolling
      maxAllowedFrames = Math.ceil(maxWidth / singleFrameWidth) + 8;
    }

    if (sectionWidth) {
      availableFrames = Math.ceil(sectionWidth / singleFrameWidth);
    }

    const duration = end - start;
    const frameCount = shouldExpand ? maxAllowedFrames : availableFrames;
    const timeFrames = shouldExpand ? 1 : duration / frameCount;
    frameCounterRef.current = frameCount;

    return filmstripImagePathsToLoad.flatMap(obj => getFramesFromImage({ ...obj, timeFrames, frameCount }));
  }, [filmstripImagePathsToLoad, end, start, singleFrameWidth, sectionWidth, shouldExpand]);

  return (
    <div className={'no-scrollbar flex h-full overflow-hidden ' + (showBorderY ? 'border-y-2 border-black ' : '')}>
      <div className={'flex ' + (shouldExpand ? 'relative' : '')}>
        {frames.map(frame => (
          <div
            className={
              'flex h-full items-center justify-center overflow-hidden ' + (shouldExpand ? 'absolute' : 'relative')
            }
            style={{ width: singleFrameWidth + 'px', left: frame.left + 'px' }}
            key={frame.time}
          >
            <div className="absolute inset-0 flex items-center justify-center bg-black opacity-20">
              <Loader size="small" />
            </div>
            <div
              style={{
                position: 'absolute',
                height: FRAME_HEIGHT_IN_PX + 'px',
                width: LANDSCAPE_WIDTH_IN_PX + 'px',
                backgroundSize: `${LANDSCAPE_WIDTH_IN_PX * NUMBER_OF_COLUMNS_IN_FILMSTRIP}px ${
                  FRAME_HEIGHT_IN_PX * NUMBER_OF_ROWS_IN_FILMSTRIP
                }px`,
                backgroundImage: `url(${frame.imagePath})`,
                backgroundPosition: `-${frame.col * LANDSCAPE_WIDTH_IN_PX}px -${frame.row * FRAME_HEIGHT_IN_PX}px`,
                backgroundRepeat: 'no-repeat'
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(Filmstrip);
