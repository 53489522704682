import { useEffect, useMemo } from 'react';
import {
  ClipPlayerSection,
  useIntroRefReturn,
  useMainSectionRefReturn,
  useOutroRefReturn
} from '../ClipPlayerSections/ClipPlayerSectionsTypes';
import useAnalytics from '@/hooks/useAnalytics';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function useClipTranscriptPlayPause({
  currentSection,
  outroPlayerProps,
  mainPlayerProps,
  introPlayerProps
}: {
  currentSection: ClipPlayerSection;
  outroPlayerProps: useOutroRefReturn;
  mainPlayerProps: useMainSectionRefReturn;
  introPlayerProps: useIntroRefReturn;
}) {
  const { isOutroPaused, playOutroSection, pauseOutroSection } = outroPlayerProps;
  const { isMainSectionPaused, playMainSection, pauseMainSection } = mainPlayerProps;
  const { isIntroPaused, playIntroSection, pauseIntroSection } = introPlayerProps;

  const { layoutStatus } = useClipsContext();

  const { trackContentLabUsage } = useAnalytics();

  const isPaused = useMemo(() => {
    return (
      (currentSection === 'outro' ? isOutroPaused : false) ||
      (currentSection === 'main' ? isMainSectionPaused : false) ||
      (currentSection === 'intro' ? isIntroPaused : false)
    );
  }, [currentSection, isIntroPaused, isOutroPaused, isMainSectionPaused]);

  const isPlayPauseDisabledForLayout = useMemo(
    () => layoutStatus === 'PROCESSING' || layoutStatus === 'FAILED',
    [layoutStatus]
  );

  const playCurrentSection = () => {
    if (isPlayPauseDisabledForLayout) return;

    trackContentLabUsage({ isPlayClip: true });

    if (currentSection === 'intro') {
      playIntroSection();
      if (!isMainSectionPaused) {
        pauseMainSection();
      }
      if (!isOutroPaused) {
        pauseOutroSection();
      }
    } else if (currentSection === 'main') {
      playMainSection();
      if (!isIntroPaused) {
        pauseIntroSection();
      }
      if (!isOutroPaused) {
        pauseOutroSection();
      }
    } else if (currentSection === 'outro') {
      playOutroSection();
      if (!isIntroPaused) {
        pauseIntroSection();
      }
      if (!isMainSectionPaused) {
        pauseMainSection();
      }
    }
  };

  const pauseCurrentSection = () => {
    trackContentLabUsage({ isPlayClip: false });

    if (currentSection === 'intro') {
      pauseIntroSection();
    } else if (currentSection === 'main') {
      pauseMainSection();
    } else if (currentSection === 'outro') {
      pauseOutroSection();
    }
  };

  useEffect(() => {
    if (isPlayPauseDisabledForLayout) {
      pauseCurrentSection();
    }
  }, [layoutStatus]);

  return {
    isPaused,
    playCurrentSection,
    pauseCurrentSection
  };
}
