import { defaultProps } from '@blocknote/core';
import { createReactBlockSpec } from '@blocknote/react';

export const titleBlock = createReactBlockSpec(
  {
    type: 'titleBlock',
    propSchema: {
      textAlignment: defaultProps.textAlignment,
      textColor: defaultProps.textColor
    },
    content: 'inline'
  },
  {
    render: (props: any) => {
      return (
        <div className="mb-6 w-full max-w-3xl text-3xl font-semibold focus:outline-none " ref={props.contentRef} />
      );
    }
  }
);
