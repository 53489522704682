import React, { useEffect, useMemo, useState } from 'react';
import { IconEdit } from '@tabler/icons-react';
import { SpeakerRowDetails } from './types';
import { classnames } from '@/libs/utils';
import { getInitials } from '@/components/atoms/Avatar/AvatarUtils';

export default function SpeakerRow({
  speakerDetails,
  wordId,
  handleEditSpeaker
}: {
  wordId?: number;
  speakerDetails: SpeakerRowDetails;
  index: number;
  handleEditSpeaker: () => void;
}) {
  let [showImage, setShowImage] = useState(!!speakerDetails.speaker.profile_picture_url);

  useEffect(() => {
    setShowImage(!!speakerDetails.speaker.profile_picture_url);
  }, [speakerDetails.speaker.profile_picture_url]);

  const name = useMemo(() => {
    return `${speakerDetails.speaker.first_name ?? ''} ${speakerDetails.speaker.last_name ?? ''}`;
  }, [speakerDetails.speaker.first_name, speakerDetails.speaker.last_name]);

  const bgColor = useMemo(() => {
    return speakerDetails.speaker.bgColor;
  }, [speakerDetails.speaker.bgColor]);

  return (
    <button
      data-word-id={wordId}
      title={name}
      className={classnames(
        'group top-20 mt-1 flex h-11 w-11 shrink-0 items-center justify-center overflow-hidden rounded-full bg-cover bg-center text-sm text-white',
        {
          'bg-indigo-700': !bgColor,
          [bgColor]: bgColor
        }
      )}
      onClick={handleEditSpeaker}
    >
      <div className="block select-none group-hover:hidden">
        {showImage ? (
          <img
            src={speakerDetails.speaker.profile_picture_url}
            alt={name}
            className="h-full w-full rounded-full object-cover"
            onError={() => setShowImage(false)}
          />
        ) : (
          getInitials(name)
        )}
      </div>
      <div className="hidden group-hover:block">
        <IconEdit />
      </div>
    </button>
  );
}
