import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { SetupContentStep } from '../types';
import IdentifySpeakerStep from './steps/IdentifySpeakerStep';
import { isStepInvalid } from './utils';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import Button from '@/components/atoms/Button/Button';
import Loader from '@/components/atoms/Loader';

export default function SetupContentModal({
  steps,
  speakerValues,
  isInlineIdentification = false,
  setSpeakerValues,
  onSave,
  setDialogTitle,
  isSaving
}: {
  isInlineIdentification?: boolean;
  steps: SetupContentStep[];
  speakerValues: Record<string, SpeakerWithDetails>;
  setSpeakerValues: Dispatch<SetStateAction<Record<string, SpeakerWithDetails>>>;
  onSave: () => void;
  setDialogTitle: (string) => void;
  isSaving: boolean;
}) {
  const [currentStep, setCurrentStep] = useState<SetupContentStep>(steps[0]);
  const [isInvalidStep, setIsInvalidStep] = useState(false);

  const confirmButtonLabel = useMemo(() => {
    return currentStep.showSaveButton ? 'Save' : 'Next';
  }, [currentStep]);

  function goToStep(index: number) {
    const newStep = steps[index];
    setDialogTitle(newStep.title);
    setCurrentStep(newStep);
  }

  function goBack() {
    goToStep(currentStep.key - 1);
  }

  function goToNextStep() {
    setIsInvalidStep(false);
    goToStep(currentStep.key + 1);
  }

  function onSpeakerUpdate(value: SpeakerWithDetails) {
    setSpeakerValues(values => {
      values[currentStep.key] = value;
      return values;
    });
  }

  function confirm() {
    if (currentStep.speakerData && isStepInvalid(speakerValues[currentStep.key] || {})) {
      setIsInvalidStep(true);
      return;
    }
    currentStep.showSaveButton ? onSave() : goToNextStep();
  }

  return (
    <div>
      <div className="w-full border-b border-b-slate-200 px-3 py-3.5">
        {currentStep &&
          steps.map(step => {
            return (
              step.speakerData && (
                <IdentifySpeakerStep
                  key={step.key}
                  isActive={step.key === currentStep.key}
                  speaker={step.speakerData}
                  isInvalid={isInvalidStep}
                  isInlineIdentification={isInlineIdentification}
                  onUpdate={onSpeakerUpdate}
                />
              )
            );
          })}
      </div>
      <div className="flex w-full items-center justify-start space-x-2 p-2">
        {currentStep.key !== 0 && (
          <Button onClick={goBack} variation="outline" trackingId="back-button" buttonSize="large">
            Back
          </Button>
        )}
        <Button
          onClick={confirm}
          variation="filled"
          className="!ml-auto"
          trackingId="confirm-button"
          disabled={isSaving}
          buttonSize="large"
        >
          {isSaving && <Loader size="xs" />}
          {confirmButtonLabel}
        </Button>
      </div>
    </div>
  );
}
