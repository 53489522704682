export default function TranscriptSkeleton() {
  return (
    <div>
      <span className="sr-only">Loading...</span>
      {Array(2)
        .fill(0)
        .map((_, i) => (
          <div role="status" className="max-w-full animate-pulse px-6" key={i}>
            <div className="mb-4 h-2.5 w-48 rounded-sm bg-gray-200"></div>
            <div className="mb-2.5 h-3 max-w-[360px] rounded-sm bg-gray-200"></div>
            <div className="mb-2.5 h-3 rounded-sm bg-gray-200"></div>
            <div className="mb-2.5 h-3 max-w-[330px] rounded-sm bg-gray-200"></div>
            <div className="mb-2.5 h-3 max-w-[300px] rounded-sm bg-gray-200"></div>
            <div className="mb-3 h-3 max-w-[360px] rounded-sm bg-gray-200"></div>
            <div className="mb-2.5 h-3 rounded-sm bg-gray-200"></div>
            <div className="mb-2.5 h-3 max-w-[330px] rounded-sm bg-gray-200"></div>
            <div className="mb-2.5 h-3 max-w-[300px] rounded-sm bg-gray-200"></div>
            <div className="mb-2.5 h-3 max-w-[360px] rounded-sm bg-gray-200"></div>
            <div className="mb-2.5 h-3 rounded-sm bg-gray-200"></div>
            <div className="mb-2.5 h-3 max-w-[330px] rounded-sm bg-gray-200"></div>
            <div className="mb-2.5 h-3 max-w-[300px] rounded-sm bg-gray-200"></div>
            <div className="mb-3 h-3 max-w-[360px] rounded-sm bg-gray-200"></div>
            <div className="mb-2.5 h-3 rounded-sm bg-gray-200"></div>
            <div className="mb-2.5 h-3 max-w-[330px] rounded-sm bg-gray-200"></div>
          </div>
        ))}
    </div>
  );
}
