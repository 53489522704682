import { memo, useMemo } from 'react';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { preventDefault } from '@/libs/utils';
import { DeletedRange } from '@/stores/clip';

function ClipSpeakersTimelineDeletes({ fullRecordingDuration }: { fullRecordingDuration: number }) {
  const { clipData } = useClipsContext();

  const deletesToDisplay: DeletedRange[] = useMemo(() => {
    const a = Object.values(clipData?.asset_metadata?.deletes || {})
      // Filter start/end deletes to not show triming as deletes
      .filter(d => d.bounds[0] !== 0 && d.bounds[0] !== clipData.asset_metadata.end);
    return a;
  }, [clipData.asset_metadata?.deletes, clipData.asset_metadata.end]);

  return deletesToDisplay.map(item => {
    const [deleteStartTime, deleteEndTime] = item.bounds;
    return (
      <button
        disabled
        onClick={preventDefault}
        className="absolute top-0 z-10 h-full bg-slate-300/70 backdrop-blur-sm"
        style={{
          width: ((deleteEndTime - deleteStartTime) / fullRecordingDuration) * 100 + '%',
          left: (+deleteStartTime / fullRecordingDuration) * 100 + '%'
        }}
        key={deleteStartTime}
      />
    );
  });
}

export default memo(ClipSpeakersTimelineDeletes);
