import React, { useEffect } from 'react';
import { useNavigate, useParams, useRouteError } from 'react-router-dom';
import Button from '../../components/atoms/Button/Button';
import { getErrorDisplayCodeAndMessage } from './utils';
import { RouteError } from './types';
import ErrorContainer from './ErrorContainer';
import { useAppContext } from '@/context/AppContext/AppContext';

export default function ErrorBoundary(): JSX.Element {
  const navigate = useNavigate();
  const { logger } = useAppContext();

  // In case of manual error, we will get the error code from the URL. See App.tsx
  const { code } = useParams<{ code: string }>();

  // This is for default router errors. Mainly 404
  const error = useRouteError();

  const { errorDisplayCode, errorMessage } = getErrorDisplayCodeAndMessage(
    code || ((error as RouteError).status || 'DEFAULT').toString(),
    !code
  );

  useEffect(() => {
    if (!code) {
      logger.error(errorDisplayCode, errorMessage);
    }
  }, [code, errorDisplayCode, errorMessage]);

  function openZendeskChat() {
    if (window.zE) {
      window.zE('webWidget', 'show');
      window.zE('webWidget', 'open');
    }
  }

  function navigateToHome() {
    navigate('/');
  }

  return (
    <ErrorContainer errorMessage={errorMessage} errorDisplayCode={errorDisplayCode}>
      <div className="flex space-x-2">
        <Button variation="filled" trackingId="take-me-home-button" onClick={navigateToHome}>
          Take Me Home
        </Button>
        <Button trackingId="contact-support-button" onClick={openZendeskChat}>
          Contact Support
        </Button>
      </div>
    </ErrorContainer>
  );
}
