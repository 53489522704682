import { useEffect, useRef } from 'react';
import { PreviewTemplate } from '../SideBar/types';
import { ClipMetadata } from '@/domains/asset';

export function useCaptionContainerRef(
  setCaptionContainerSizes: React.Dispatch<
    React.SetStateAction<{
      width: number;
      height: number;
    }>
  >,
  clipMetadata: ClipMetadata | PreviewTemplate
) {
  const captionContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scale = clipMetadata.caption_styles?.scale || 1;
    const captionContainerResizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        setCaptionContainerSizes({
          height: entry.contentRect.height,
          width: entry.contentRect.width
        });
      }
    });

    if (captionContainerRef.current) {
      setCaptionContainerSizes({
        height: captionContainerRef.current.getBoundingClientRect().height * scale,
        width: captionContainerRef.current.getBoundingClientRect().width * scale
      });

      captionContainerResizeObserver.observe(captionContainerRef.current);
    }

    const transientCaptionContainerRef = captionContainerRef.current;
    return () => {
      if (transientCaptionContainerRef) {
        captionContainerResizeObserver.unobserve(transientCaptionContainerRef);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captionContainerRef, clipMetadata]);

  return { captionContainerRef };
}
