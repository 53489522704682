import { useCallback, useEffect, useState, useSyncExternalStore } from 'react';
import moment from 'moment';
import { useQuery } from 'react-query';
import { listEvents } from '@goldcast/api/backend';
import EventSessionFilterSkeleton from './EventSessionFilterSkeleton';
import MultiSelectDropdown from '../uiComponents/MultiSelectDropdown/MultiSelectDropdown';
import { MultiSelectDropdownOption } from '../uiComponents/MultiSelectDropdown/types';
import { currentUser } from '@/stores/user';

export default function EventFilter({ selected, onClear, onSelect, disabled = false }) {
  const [options, setOptions] = useState<MultiSelectDropdownOption[]>([]);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const user = useSyncExternalStore(currentUser.subscribe, currentUser.getSnapshot);

  const { data, isFetching: loading } = useQuery({
    queryKey: ['events-filter', searchValue],
    queryFn: () =>
      listEvents({
        queryParams: {
          organization: user!.organization as string,
          offset: 0,
          limit: 30,
          end_time_lte: moment().toISOString(),
          ordering: '-start_time',
          title: searchValue
        }
      }),
    enabled: isPopoverOpen
  });

  useEffect(() => {
    if (!loading) {
      if (data?.count && data?.results) {
        setOptions(
          data.results.map(item => ({
            label: item.title,
            value: item.id,
            // TODO: remove this once backend type is fixed
            start_time: (item as any).start_time
          }))
        );
      } else {
        setOptions([]);
      }
    }
  }, [data, loading]);

  const onPopoverOpen = useCallback(() => {
    setIsPopoverOpen(true);
  }, []);

  return (
    <MultiSelectDropdown
      label={'Event'}
      selected={selected}
      options={options}
      searchable
      onClear={onClear}
      onSelect={onSelect}
      onPopoverOpen={onPopoverOpen}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      loading={loading}
      Loader={<EventSessionFilterSkeleton />}
      itemRenderer={option => {
        return (
          option.start_time && (
            <div className="text-xs text-slate-500">
              {option.start_time ? moment(option.start_time).format('MMM D, YYYY') : ''}
            </div>
          )
        );
      }}
      disabled={disabled}
    />
  );
}
