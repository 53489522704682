import React from 'react';
import './Waveform.css';

interface WaveformProps {
  textColor: string;
  paused?: boolean;
  height: number;
}

export default function Waveform({ textColor, paused = false, height }: WaveformProps): JSX.Element {
  return (
    <div className="flex h-full items-center justify-center" style={{ height: `${height}px`, width: `${height}px` }}>
      <svg
        id="wave"
        style={{ fill: `${textColor}`, height: `${height}px`, width: `${height}px` }}
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 38.05 38.05"
      >
        <title>Audio Wave</title>
        <path
          id="Line_1"
          style={{ ...(!paused ? {} : { animation: 'pulse' }) }}
          data-name="Line 1"
          d="M0.91,15L0.78,15A1,1,0,0,0,0,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H0.91Z"
        />
        <path
          id="Line_2"
          style={{ ...(!paused ? {} : { animation: 'pulse' }) }}
          data-name="Line 2"
          d="M6.91,9L6.78,9A1,1,0,0,0,6,10V28a1,1,0,1,0,2,0s0,0,0,0V10A1,1,0,0,0,7,9H6.91Z"
        />
        <path
          id="Line_3"
          style={{ ...(!paused ? {} : { animation: 'pulse' }) }}
          data-name="Line 3"
          d="M12.91,0L12.78,0A1,1,0,0,0,12,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H12.91Z"
        />
        <path
          id="Line_4"
          style={{ ...(!paused ? {} : { animation: 'pulse' }) }}
          data-name="Line 4"
          d="M18.91,10l-0.12,0A1,1,0,0,0,18,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H18.91Z"
        />
        <path
          id="Line_5"
          style={{ ...(!paused ? {} : { animation: 'pulse' }) }}
          data-name="Line 5"
          d="M24.91,15l-0.12,0A1,1,0,0,0,24,16v6a1,1,0,0,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H24.91Z"
        />
        <path
          id="Line_6"
          style={{ ...(!paused ? {} : { animation: 'pulse' }) }}
          data-name="Line 6"
          d="M30.91,10l-0.12,0A1,1,0,0,0,30,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H30.91Z"
        />
      </svg>
    </div>
  );
}
