import { memo, useCallback, useMemo } from 'react';
import { getVoiceProfileLimitMessage } from './utils';
import ContentDialog from '@/Pages/Sessions/uiComponents/ContentDialog/ContentDialog';
import Button from '@/components/atoms/Button/Button';
import { useAppContext } from '@/context/AppContext/AppContext';
import useAnalytics from '@/hooks/useAnalytics';
import { resetSelectedVoiceProfile } from '@/stores/voiceProfile';
import { showSuccessToast } from '@/libs/toast/toast';

function VoiceProfileLimit({ isOpen, closeDialog }: { isOpen: boolean; closeDialog: () => void }): JSX.Element {
  const { adminAppStore } = useAppContext();
  const { trackVoiceProfileLimitReached } = useAnalytics();

  const voiceProfileCount = adminAppStore.getters?.['orgPricingConfig/getVoiceProfileCount'];
  const voiceProfileLimitMessage = useMemo(() => getVoiceProfileLimitMessage(voiceProfileCount), [voiceProfileCount]);

  const handleContactSales = useCallback(() => {
    trackVoiceProfileLimitReached();
    resetSelectedVoiceProfile();
    showSuccessToast('We have received your request. Our team will get in touch with you soon.');
    closeDialog();
  }, []);

  return (
    <ContentDialog
      isOpen={isOpen}
      size="medium"
      setIsOpen={closeDialog}
      disableBackdropClose={true}
      hideCloseIcon={true}
      panelClassNames="text-md"
    >
      <div className="absolute top-0 z-50 flex items-center justify-between pl-5 pt-5">
        <div className="text-2xl font-semibold">Voice Profile Limit Reached</div>
      </div>
      <p className="mt-10 px-5">{voiceProfileLimitMessage}</p>
      <div className="flex justify-end gap-2 px-5 py-4">
        <Button
          buttonSize="large"
          variation="outline"
          onClick={handleContactSales}
          className="h-10 rounded-lg px-6 py-2 text-sm"
          trackingId="voice-profile-limit-contact-sales-click"
        >
          Contact Sales
        </Button>
        <Button trackingId="voice-profile-limit-ok-click" buttonSize="large" variation="filled" onClick={closeDialog}>
          Ok
        </Button>
      </div>
    </ContentDialog>
  );
}

export default memo(VoiceProfileLimit);
