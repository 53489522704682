import React from 'react';

interface ToggleProps {
  on: boolean;
  disabled?: boolean;
  onToggle: (on: boolean) => void;
  title?: string;
}

export default function Toggle({ on, disabled = false, onToggle, title = '' }: ToggleProps): JSX.Element {
  return (
    <button
      className={`h-6 w-11 ${
        on && !disabled ? 'bg-black' : 'bg-slate-300'
      } relative rounded-full p-0.5 transition-all disabled:cursor-not-allowed disabled:bg-slate-200`}
      onClick={() => onToggle(!on)}
      data-testid="content-studio-toggle"
      disabled={disabled}
      role="checkbox"
      aria-checked={on}
      title={title}
    >
      <div
        className={`absolute top-0.5 h-5 w-5 rounded-full bg-white ${
          on ? 'left-[1.33rem]' : 'left-0.5'
        } transition-all`}
      />
    </button>
  );
}
