export enum WordType {
  Pronunciation = 'pronunciation',
  Punctuation = 'punctuation'
}

export interface Word {
  start_time: number;
  // TODO: Make required after shared api integration
  processed_start_time?: number;
  end_time: number;
  // TODO: Make required after shared api integration
  processed_end_time?: number;
  content: string;
  type: WordType;
  speaker_label: string;
  srt_index: number;
  index: number;
  hidden?: boolean;
  is_deleted?: boolean;
}
