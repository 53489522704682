import { useState } from 'react';
import LabeledInput from '@/components/atoms/LabeledInput';
import Button from '@/components/atoms/Button/Button';

export default function RenameTitleModal({ existingTitle, onSubmit, onClose }) {
  const [title, setTitle] = useState(existingTitle);

  function save(title: string) {
    if (!title) return;
    onSubmit(title);
  }

  return (
    <div>
      <div className="w-full border-b border-b-slate-200 px-3 py-3.5">
        <LabeledInput value={title} onChange={setTitle} />
      </div>
      <div className="flex w-full items-center justify-end space-x-2 p-2">
        <Button variation="outline" onClick={onClose} trackingId="cancel-button">
          Cancel
        </Button>
        <Button onClick={() => save(title)} variation="filled" trackingId="save-button">
          Save
        </Button>
      </div>
    </div>
  );
}
