import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';

export function doesSpeakerSpeakInClip(
  speakingSlots: SpeakerWithDetails['speakingSlots'],
  clipStart: number,
  clipEnd: number
) {
  return Object.entries(speakingSlots).some(([slotStart, slotEnd]) => {
    return (
      // if speaker speaking slot starts before clip start and ends anywhere after clip start, then include the speaker
      (+slotStart < clipStart && slotEnd > clipStart) ||
      // if speaker speaking slot starts anytime after clip start but before clip end , then include the speaker
      (+slotStart >= clipStart && +slotStart <= clipEnd)
    );
  });
}

export function getListOfVisibleSpeakers(
  speakers: Record<string, SpeakerWithDetails>,
  clipStart: number,
  clipEnd: number,
  ignoreUrlCheck?: boolean,
  ignoreListeningSpeakers?: boolean
): SpeakerWithDetails[] {
  const speakerArray = Object.values(speakers);
  let visibleSpeakers: SpeakerWithDetails[] = [];
  const listeningSpeakers: SpeakerWithDetails[] = [];

  for (const speaker of speakerArray) {
    /**  This speaker video url check is to handle cases when the number of speakers in speaker list does
     * not match the number of speakers in the transcript mapping, hence causing missing urls */
    if (!speaker.video.url && !ignoreUrlCheck) continue;
    if (doesSpeakerSpeakInClip(speaker.speakingSlots, clipStart, clipEnd)) {
      // This will contain all speakers that have spoken in the clip duration
      visibleSpeakers.push(speaker);
    } else {
      // This will contain the listening speakers who are on stage and not speaking
      listeningSpeakers.push(speaker);
    }
  }

  if (!ignoreListeningSpeakers) {
    // This will ensure the speaking speakers are always first and the listening speakers come after
    visibleSpeakers.push(...listeningSpeakers);
  }

  return visibleSpeakers.sort((a, b) => a.key.localeCompare(b.key));
}
