import React from 'react';
import Button from './Button';
import Icon, { IconName } from '../Icon';
import { ButtonSize, ButtonVariation } from './ButtonTypes';
import { classnames } from '@/libs/utils';

export default function IconButton({
  icon,
  iconClassName = '',
  content,
  variation = 'outline',
  size: buttonSize = 'base',
  disabled = false,
  onClick,
  buttonClassName = '',
  title = '',
  trackingId,
  ...props
}: {
  icon: IconName;
  iconClassName?: string;
  variation?: ButtonVariation;
  content?: string;
  size?: ButtonSize;
  disabled?: boolean;
  active?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  buttonClassName?: string;
  title?: string;
  trackingId: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
}) {
  return (
    <Button
      variation={variation}
      disabled={disabled}
      buttonSize={buttonSize}
      onClick={onClick}
      className={buttonClassName}
      title={title}
      trackingId={trackingId}
      {...props}
    >
      {content ? (
        <>
          <Icon
            name={icon}
            size={buttonSize === 'base' ? 20 : buttonSize === 'large' ? 24 : buttonSize === 'full' ? 21 : 16}
            className={classnames('-ml-1 mr-1', iconClassName)}
          />
          {content}
        </>
      ) : (
        <Icon
          name={icon}
          size={buttonSize === 'base' ? 20 : buttonSize === 'large' ? 24 : 16}
          className={classnames(buttonSize === 'base' ? '-mx-[7px]' : '-mx-[5px]', iconClassName)}
        />
      )}
    </Button>
  );
}
