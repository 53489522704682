import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useQuery } from 'react-query';
import MultiSelectDropdown from '../uiComponents/MultiSelectDropdown/MultiSelectDropdown';
import EventSessionFilterSkeleton from './EventSessionFilterSkeleton';
import { MultiSelectDropdownOption } from '../uiComponents/MultiSelectDropdown/types';
import useMediaContent from '../useMediaContent';
import { currentUser } from '@/stores/user';
import { useAppContext } from '@/context/AppContext/AppContext';

export default function SessionFilter({
  selected,
  disabled,
  selectedEvents = [],
  onSelect,
  onClear
}: {
  selected: string[];
  onClear: () => void;
  onSelect: (option: any) => void;
  disabled: boolean;
  selectedEvents?: string[];
}) {
  const [options, setOptions] = useState<MultiSelectDropdownOption[]>([]);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const { mediaContent, fetchMediaContentList } = useMediaContent();

  const { isFetching: loading } = useQuery({
    queryKey: ['sessions-filter', searchValue, selectedEvents],
    queryFn: () =>
      fetchMediaContentList({
        organization: currentUser.getSnapshot()?.organization as string,
        limit: 30,
        selectedEvents,
        searchTerm: searchValue
      }),
    enabled: isPopoverOpen
  });

  useEffect(() => {
    selectSessionsFromSelectedEvents();
    setOptions(
      (mediaContent || []).map(item => ({
        label: item.title,
        value: item.id,
        start_time: item.end_time
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaContent]);

  // When event filters are changed, we should keep selected only sessions from selected events
  function selectSessionsFromSelectedEvents() {
    const newSelected = selectedEvents.length ? selected.filter(s => mediaContent.some(mc => mc.id === s)) : [];
    onSelect(newSelected);
  }

  const onPopoverOpen = useCallback(() => {
    setIsPopoverOpen(true);
  }, []);

  const {
    adminAppStore: { user: { is_content_lab_standalone } = { is_content_lab_standalone: false } }
  } = useAppContext();

  return (
    <MultiSelectDropdown
      label={is_content_lab_standalone ? 'Recording' : 'Session'}
      selected={selected}
      options={options}
      searchable
      onClear={onClear}
      onSelect={onSelect}
      onPopoverOpen={onPopoverOpen}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      loading={loading}
      Loader={<EventSessionFilterSkeleton />}
      itemRenderer={option => {
        return (
          option.start_time && (
            <div className="text-xs text-slate-500">
              {option.start_time ? moment(option.start_time).format('MMM D, YYYY') : ''}
            </div>
          )
        );
      }}
      disabled={disabled}
    />
  );
}
