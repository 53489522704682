import { SpeakersAnalysis } from '@/stores/speakersAnalysis/speakersAnalysisTypes';

/**
 * This function gives values of crop_x, crop_y, crop_width, crop_height
 * to crop the original image to fit the target dimensions
 */
export function getCentreCropValues(
  originalWidth,
  originalHeight,
  targetWidth,
  targetHeight
): {
  crop_x: number;
  crop_y: number;
  crop_width: number;
  crop_height: number;
} {
  const aspectRatio = originalWidth / originalHeight;
  const targetAspectRatio = targetWidth / targetHeight;

  let crop_x = 0;
  let crop_y = 0;
  let crop_width = originalWidth;
  let crop_height = originalHeight;

  if (aspectRatio > targetAspectRatio) {
    crop_width = originalHeight * targetAspectRatio;
    crop_height = originalHeight;
  } else {
    crop_width = originalWidth;
    crop_height = originalWidth / targetAspectRatio;
  }

  crop_x = (originalWidth - crop_width) / 2;
  crop_y = (originalHeight - crop_height) / 2;

  // We need to round of the values here for 3 reasons
  // 1. Better performance on the canvas. Float values are slower to render because of sub-pixel rendering
  // 3. BE asks for integer values :P
  return {
    crop_x: Math.round(crop_x),
    crop_y: Math.round(crop_y),
    crop_width: Math.round(crop_width),
    crop_height: Math.round(crop_height)
  };
}

export function getFacePositionsForGrid(index: number, speakersAnalysis: SpeakersAnalysis) {
  return speakersAnalysis.time_analysis[index].face_positions.filter(facePosition =>
    speakersAnalysis.visible_face_ids.includes(facePosition.face_id)
  );
}
