import { Sequence } from 'remotion';
import { TimelineElement, TimelineElementType } from '../types';
import ContainerElement from './ContainerElement';
import TextElement from './TextElement';

const MainTimelineElement = ({ element, drift }: { element: TimelineElement; drift: number }) => {
  const { fromFrame, toFrame, type } = element;
  const durationInFrames = Math.round(toFrame - fromFrame);

  if (type === TimelineElementType.CONTAINER) {
    return (
      <Sequence from={Math.floor(fromFrame + drift)} durationInFrames={durationInFrames}>
        <ContainerElement element={element} />
      </Sequence>
    );
  } else if (type === TimelineElementType.TEXT) {
    return (
      <Sequence from={Math.floor(fromFrame + drift)} durationInFrames={durationInFrames}>
        <TextElement element={element} />
      </Sequence>
    );
  }

  return null;
};

export default MainTimelineElement;
