import { createRef, useEffect, useState } from 'react';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import { getSpeakerImage } from '@/libs/utils';
import { updateSpeakerImageMap } from '@/stores/sharedAPI/sharedAPI';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function CompositePlayerImages({
  speakerImageRefs,
  setSpeakerImageRefs
}: {
  speakerImageRefs: React.RefObject<HTMLImageElement>[];
  setSpeakerImageRefs: React.Dispatch<React.SetStateAction<React.RefObject<HTMLImageElement>[]>>;
}) {
  const [readyToRender, setReadyToRender] = useState(false);
  const transcriptStore = useTranscriptContext();
  const { clipId } = useClipsContext();
  const speakers = transcriptStore.usedSpeakers;

  useEffect(() => {
    if (speakerImageRefs.length !== speakers.length && speakers.length > 0) {
      const imageRefs: React.RefObject<HTMLImageElement>[] = [];
      speakers.forEach(() => {
        const newRef = createRef<HTMLImageElement>();
        imageRefs.push(newRef);
      });
      setSpeakerImageRefs(imageRefs);
      setReadyToRender(true);
    }
  }, [speakerImageRefs, speakers]);

  return (
    readyToRender &&
    speakers.map((speaker, i) => {
      function onLoaded() {
        if (speakerImageRefs[i].current) {
          updateSpeakerImageMap(clipId, speaker.key, speakerImageRefs[i].current!);
        }
      }

      return (
        <div key={i} className="absolute top-0">
          <img
            ref={speakerImageRefs[i]}
            className="invisible z-[-10] h-2 w-2 object-cover"
            src={getSpeakerImage(speaker.profile_picture_url)}
            alt={speaker.first_name}
            onLoad={onLoaded}
          />
        </div>
      );
    })
  );
}
