import { memo, useCallback, useState, useSyncExternalStore } from 'react';
import { assetsContentUploadPartialUpdate } from '@goldcast/api/content';
import SpeakersList from '../../../atoms/SpeakersList/SpeakersList';
import SessionSettingsActions from './SessionSettingsActions';
import ContentSettingsWrapper from '../ContentSettingsWrapper';
import { openIdentifySpeakersDialog } from '../utils';
import RecordingDetailsStep from '@/Pages/TranscriptPage/SetupContent/steps/RecordingDetailsStep';
import { core } from '@/stores/core';
import { RecordingDetails } from '@/Pages/TranscriptPage/types';
import { isEmptyObject } from '@/libs/utils';

function SessionSettings({ onClose, selectedSpeakerId }: { onClose: () => void; selectedSpeakerId: string | null }) {
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);
  const [updatedRecordingDetails, setUpdatedRecordingDetails] = useState<RecordingDetails>({});

  const saveChanges = useCallback(() => {
    // Save details on close, if changed
    if (coreStore.uploadData && !isEmptyObject(updatedRecordingDetails)) {
      const newUploadData = {
        ...coreStore.uploadData,
        title: updatedRecordingDetails.title?.trim().length ? updatedRecordingDetails.title : coreStore.content?.title,
        recording_date: updatedRecordingDetails.recording_date || coreStore.uploadData.recording_date
      };
      assetsContentUploadPartialUpdate({
        id: coreStore.uploadData.id,
        body: newUploadData
      }).then(() => {
        core.update(data => ({
          ...data,
          content: {
            ...data.content,
            title: newUploadData.title
          },
          uploadData: newUploadData
        }));
      });
    }

    onClose();
  }, [coreStore.content?.title, coreStore.uploadData, updatedRecordingDetails]);

  function onRecordingUpdate(field: string, newValue: string) {
    setUpdatedRecordingDetails(value => {
      return {
        ...value,
        [field]: newValue
      };
    });
  }

  return (
    <ContentSettingsWrapper onClose={saveChanges}>
      <div className="overflow-x-hidden py-5">
        <div className="px-5">
          {coreStore.uploadData && (
            <RecordingDetailsStep recordingDetails={coreStore.uploadData} onRecordingUpdate={onRecordingUpdate} />
          )}
          <SpeakersList speakerId={selectedSpeakerId} openIdentifySpeakersDialog={openIdentifySpeakersDialog} />
        </div>
        <SessionSettingsActions />
      </div>
    </ContentSettingsWrapper>
  );
}

export default memo(SessionSettings);
