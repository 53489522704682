import { useCallback, useState } from 'react';
import { IconPlus } from '@tabler/icons-react';
import { defaultProps } from '@blocknote/core';
import { createReactBlockSpec } from '@blocknote/react';
import usePost from '@/hooks/usePost';
import { useAppContext } from '@/context/AppContext/AppContext';
import { classnames } from '@/libs/utils';
import { showErrorToast } from '@/libs/toast/toast';
import useSavedSearch from '@/hooks/useSavedSearch';
import ErrorBoundaryForComponent from '@/App/ErrorBoundary/ErrorBoundaryForComponent';

const AddNewClip = ({ editor, block }) => {
  const [isGeneratingClip, setIsGeneratingClip] = useState(false);
  const { textToClipGeneration, textToClipSavedSearchGeneration } = usePost();
  const { isSavedSearchPage } = useSavedSearch();

  const { logger } = useAppContext();

  const handleAddClip = useCallback(async () => {
    let selectedText = '';
    editor.forEachBlock(blockNode => {
      if (blockNode.id === block.id) {
        return false;
      }
      selectedText = blockNode.content[0]?.text;
      return true;
    });
    if (!selectedText) {
      showErrorToast('No text found to generate clip');
      return;
    }
    try {
      setIsGeneratingClip(true);
      const clipPlayerText = isSavedSearchPage
        ? await textToClipSavedSearchGeneration(selectedText)
        : await textToClipGeneration(selectedText);

      const newBlock = {
        type: 'clipPlayer',
        content: [
          {
            type: 'text',
            text: clipPlayerText,
            styles: {}
          }
        ]
      };
      setIsGeneratingClip(false);
      editor.replaceBlocks([block.id], [{ ...newBlock }]);
    } catch (error) {
      logger.error('Error generating clip from add clip', error);
      setIsGeneratingClip(false);
    }
  }, [editor, block, textToClipGeneration, logger]);

  return (
    <div className="w-full" contentEditable="false" suppressContentEditableWarning={true}>
      <button
        className={classnames('group flex w-full items-center justify-center', {
          'cursor-default': isGeneratingClip
        })}
        disabled={isGeneratingClip}
        onClick={handleAddClip}
      >
        <div
          className={classnames(
            'flex w-full items-center space-x-3 transition-all duration-150 ease-in-out group-hover:opacity-100',
            {
              'opacity-100': isGeneratingClip,
              'opacity-0': !isGeneratingClip
            }
          )}
        >
          <div className="h-[2px] w-full bg-slate-400"></div>
          <div className="group/addButton relative flex shrink-0 items-center justify-center space-x-1 rounded-full border-2 border-slate-400 px-3 py-1 hover:bg-slate-100">
            <IconPlus className="h-4 w-4" />
            <span className="text-xs font-medium text-slate-500">
              {isGeneratingClip ? 'Generating Clip ...' : 'Add Clip'}
            </span>
            <div className="max-w-48 pointer-events-none absolute flex w-max -translate-y-9 items-center justify-center rounded bg-black/80 p-1.5 px-2.5 text-xs text-white opacity-0 transition-all delay-100 duration-150 ease-in-out group-hover/addButton:-translate-y-10 group-hover/addButton:opacity-100">
              <span>
                Generates a clip based on <br />
                the previous paragraph
              </span>
            </div>
          </div>
          <div className="h-[2px] w-full bg-slate-400"></div>
        </div>
      </button>
    </div>
  );
};

export const addNewClipBlock = createReactBlockSpec(
  {
    type: 'addClip',
    propSchema: {
      textAlignment: defaultProps.textAlignment,
      textColor: defaultProps.textColor
    },
    content: 'inline'
  },
  {
    render: props => {
      const { editor, block } = props;
      return (
        <ErrorBoundaryForComponent>
          <>
            <div className="hidden w-full" contentEditable="false" ref={props.contentRef}></div>
            <AddNewClip editor={editor} block={block} />
          </>
        </ErrorBoundaryForComponent>
      );
    }
  }
);
