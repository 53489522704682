import { memo } from 'react';
import { TextTimelineElement } from '../types';

const TextElement = ({ element }: { element: TextTimelineElement }) => {
  const { content, style } = element as TextTimelineElement;

  return <div style={{ ...style }}>{content}</div>;
};

export default memo(TextElement);
