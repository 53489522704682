import {
  ClipAssetResponse,
  Downloads,
  MultimodalDownloads,
  assetsClipRetrieve,
  assetsDownloadRetrieve
} from '@goldcast/api/content';
import { IconCloudDownload } from '@tabler/icons-react';
import useAnalytics from './useAnalytics';
import { showActionToast, showErrorToast } from '@/libs/toast/toast';
import { downloadURI } from '@/libs/core';
import { TextAsset } from '@/Pages/PostsListPage/types';
import { downloadsPopupOpen } from '@/stores/downloadsStore';
import { documentTitleNotification, shouldAutoDownload } from '@/libs/downloadSessionUtil';

const DOWNLOAD_TOAST_DURATION = 12000;

type TextAssetDownloadSocketData = {
  id: string;
  download_path: string;
  upload_id: string;
  broadcast_id: string | null;
  title: string;
  multi_modal_enabled: boolean;
  zip_file_size: number; // in bytes
};

export default function useDownloadToasts() {
  const { trackDownloadContent } = useAnalytics();

  function startClipDownload(url: string, clip: ClipAssetResponse, isAutoDownload: boolean, isUpscaled: boolean) {
    trackDownloadContent({
      clip,
      assetType: 'CLIP',
      state: isAutoDownload ? 'Autodownload' : 'ToastButtonClick',
      isUpscaled
    });
    downloadURI(url);
  }

  async function showClipDownloadToast(downloadData: Downloads, isSuccess: boolean, isUpscaled: boolean) {
    const clip = await assetsClipRetrieve({
      id: downloadData.asset
    });

    isSuccess
      ? showClipDownloadSuccessToast(downloadData, clip, isUpscaled)
      : showClipDownloadFailedToast(downloadData, clip, isUpscaled);
  }

  async function showClipDownloadSuccessToast(downloadData: Downloads, clip: ClipAssetResponse, isUpscaled: boolean) {
    if (!downloadData.download_url) {
      return;
    }

    if (shouldAutoDownload(downloadData.id)) {
      startClipDownload(downloadData.download_url, clip, true, isUpscaled);

      documentTitleNotification();

      if (!downloadsPopupOpen.getSnapshot()) {
        showActionToast('success', {
          title: 'Download started',
          content: (
            <div>
              Your clip "<span className="tw-italic">{downloadData.config?.filename}</span>" is being downloaded.
            </div>
          ),
          duration: DOWNLOAD_TOAST_DURATION
        });
      }

      return;
    }

    if (!downloadsPopupOpen.getSnapshot()) {
      showActionToast('success', {
        title: 'Your clip is ready!',
        content: (
          <div>
            <span className="tw-italic">{downloadData.config?.filename}</span> is ready for download.
          </div>
        ),
        actionButtonContent: <IconCloudDownload size={16} />,
        actionButtonCb: () => startClipDownload(downloadData.download_url, clip, false, isUpscaled),
        duration: DOWNLOAD_TOAST_DURATION
      });
    }
  }

  function showClipDownloadFailedToast(downloadData: Downloads, clip: ClipAssetResponse, isUpscaled: boolean) {
    trackDownloadContent({ clip, assetType: 'CLIP', state: 'Failed', isUpscaled });
    showErrorToast(`${downloadData.config?.filename || 'Video'} failed to download, please try again`);
  }

  function showClipDownloadToastFromId(id: string, isSuccess: boolean = true) {
    if (!!id) {
      assetsDownloadRetrieve({ id }).then(res =>
        showClipDownloadToast(res, isSuccess, !!res.post_processed_config?.upscale)
      );
    }
  }

  function showTextAssetDownloadFailedToast(downloadData: MultimodalDownloads, textAsset: TextAsset) {
    trackDownloadContent({ textAsset, assetType: 'TEXT', state: 'Failed' });
    showErrorToast(`${textAsset.title} failed to download, please try again`);
  }

  function showTextAssetDownloadToast(data: TextAssetDownloadSocketData) {
    if (!data.download_path) {
      return;
    }

    if (shouldAutoDownload(data.id)) {
      trackDownloadContent({
        textAsset: {
          title: data.title
        } as TextAsset,
        assetType: 'TEXT',
        state: 'Autodownload'
      });
      downloadURI(data.download_path);
      documentTitleNotification();
    }

    if (!downloadsPopupOpen.getSnapshot()) {
      showActionToast('success', {
        title: 'Your post is ready!',
        content: (
          <div>
            <span className="tw-italic">{data.title}</span> is ready for download.
          </div>
        ),
        actionButtonContent: <IconCloudDownload size={16} />,
        actionButtonCb: () => {
          trackDownloadContent({
            textAsset: {
              title: data.title,
              content: {
                multi_modal_enabled: data.multi_modal_enabled
              }
            } as any,
            assetType: 'TEXT',
            state: 'ToastButtonClick'
          });
          downloadURI(data.download_path);
        },
        duration: DOWNLOAD_TOAST_DURATION
      });
    }
  }

  function showTextAssetSocketFailureToast(data: TextAssetDownloadSocketData) {
    if (!downloadsPopupOpen.getSnapshot()) {
      showErrorToast(`Failed to download the post "${data.title}", please try again or contact support for help.`);
    }
  }

  return {
    showClipDownloadToastFromId,
    showClipDownloadFailedToast,
    showTextAssetDownloadFailedToast,
    showTextAssetDownloadToast,
    showTextAssetSocketFailureToast
  };
}
