import { useState, useSyncExternalStore, useMemo, memo, useCallback } from 'react';
import { assetsChaptersGenerateCreate, ChapterResponse } from '@goldcast/api/content';
import ChapterActionsMenu from './ChapterActionsMenu';
import { ChapterType } from './ClipTimelineTypes';
import { CHAPTER_TOOLTIP_THRESHOLD } from './ClipTimelineConstants';
import { core } from '@/stores/core';
import IconButton from '@/components/atoms/Button/IconButton';
import { loadChapters } from '@/context/TranscriptContext/TranscriptContextUtils';
import { useAppContext } from '@/context/AppContext/AppContext';
import { CustomEvents } from '@/libs/eventBus/constants';
import EventBus from '@/libs/eventBus/eventBus';
import poll from '@/libs/polling';
import { showErrorToast, showSuccessToast } from '@/libs/toast/toast';
import useTranscriptPage from '@/Pages/TranscriptPage/useTranscriptPage';
import { promptOverrides } from '@/stores/debugToolsStores';

const ChaptersView = ({
  chapters,
  totalDuration,
  zoom
}: {
  chapters: ChapterResponse[];
  totalDuration: number;
  zoom: number;
}) => {
  const [isGeneratingChapters, setIsGeneratingChapters] = useState(false);
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);
  const promptOverridesStore = useSyncExternalStore(promptOverrides.subscribe, promptOverrides.getSnapshot);
  const broadcastId = useMemo(() => coreStore.content?.id, [coreStore.content]);
  const { logger } = useAppContext();
  const { renameChapter, selectedChapter, setSelectedChapter } = useTranscriptPage();

  const generateChapters = async () => {
    if (!broadcastId) return;
    try {
      setIsGeneratingChapters(true);
      await assetsChaptersGenerateCreate({
        body: { content_id: broadcastId, generator_type: promptOverridesStore['TEXT_GENERATION_LLM_MODEL'] ?? 'SIEVE' }
      } as any);
      startPollingChapters(broadcastId);
    } catch (error) {
      logger.error('Error generating chapters', error);
    }
  };

  const startPollingChapters = (broadcastId: string) => {
    poll({
      pollFunction: async () => {
        const chapters = await loadChapters(broadcastId);
        return chapters && chapters.length > 0 ? chapters : null;
      },
      onSuccess: chapters => {
        EventBus.dispatch(CustomEvents.UpdateChapters, chapters);
        setIsGeneratingChapters(false);
        showSuccessToast('Chapters generated successfully');
      },
      onError: error => {
        logger.error('Error while polling chapters:', error);
        showErrorToast('Error while fetching chapters');
      }
    });
  };

  const handleRenameChapter = (chapter: ChapterResponse) => {
    setSelectedChapter(chapter);
  };

  const handleRenameSubmit = useCallback(async () => {
    if (!selectedChapter) return;

    await renameChapter(selectedChapter.id, selectedChapter.title);
    setSelectedChapter(null);
  }, [selectedChapter]);

  if (!chapters) return null;

  return (
    <div className=" h-6 w-full">
      {chapters.length === 0 && (
        <IconButton
          trackingId="generate-chapter"
          icon={isGeneratingChapters ? 'IconLoader2' : 'IconSparkles'}
          iconClassName={isGeneratingChapters ? 'animate-spin' : ''}
          size="small"
          disabled={isGeneratingChapters}
          buttonClassName="h-6 bg-slate-100 btn-text"
          onClick={generateChapters}
          content={isGeneratingChapters ? 'Generating Chapters' : 'Generate Chapters'}
        />
      )}
      {chapters.length > 0 &&
        chapters.map((chapter: ChapterResponse, index: number) => {
          const currentStartTime = chapter?.start_time;
          const nextStartTime = chapters[index + 1]?.start_time || totalDuration;
          const widthPercentage = ((nextStartTime - currentStartTime) / totalDuration) * 100;

          return (
            <div
              key={chapter.id}
              className="group absolute flex h-6 items-center rounded-md pr-8 hover:bg-slate-100"
              style={{
                left: `${(currentStartTime / totalDuration) * 100}%`,
                width: `${widthPercentage}%`
              }}
            >
              <div className="absolute -left-1 z-20 h-full w-2"></div>
              <div className="absolute -left-[1px] h-3 w-[1.5px] shrink-0 rounded bg-black"></div>
              {widthPercentage * zoom < CHAPTER_TOOLTIP_THRESHOLD ? (
                <div
                  className="max-w-64 pointer-events-none absolute bottom-6 z-50 flex w-max translate-y-1 items-center justify-center rounded bg-slate-900 p-1 px-2 text-2xs text-white opacity-0 group-hover:translate-y-0 group-hover:opacity-100"
                  style={{
                    transform: 'translateX(-30%)'
                  }}
                >
                  <div className="absolute -bottom-1 h-2 w-2 rotate-45 bg-slate-900"></div>
                  <span>{chapter.title}</span>
                </div>
              ) : (
                <div className="absolute right-1 z-50">
                  <div className="flex h-[18px] w-[18px] items-center justify-center rounded pt-[3px] opacity-0 hover:bg-slate-200 group-hover:opacity-100">
                    <ChapterActionsMenu
                      chapterId={chapter.id}
                      type={ChapterType.TIMELINE}
                      chapterStartTime={currentStartTime}
                      chapterEndTime={nextStartTime}
                      onRenameChapter={() => handleRenameChapter(chapter)}
                    />
                  </div>
                </div>
              )}
              <div className="group flex h-full grow translate-x-1 cursor-pointer items-center truncate text-2xs">
                {selectedChapter?.id === chapter.id ? (
                  <input
                    className="w-full min-w-0 select-text truncate whitespace-nowrap rounded text-left text-2xs focus:bg-white focus:px-1 focus:outline-none"
                    value={selectedChapter.title}
                    onChange={e => setSelectedChapter({ ...selectedChapter, title: e.target.value })}
                    onBlur={handleRenameSubmit}
                    data-testid="timeline-chapter-rename-input"
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        handleRenameSubmit();
                      }
                    }}
                    autoFocus
                  />
                ) : (
                  <button className="w-full min-w-0 select-none truncate whitespace-nowrap text-left">
                    {chapter.title}
                  </button>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default memo(ChaptersView);
