import { ButtonVariation } from '../Button/ButtonTypes';
import { IconName } from '../Icon';
import { ApiButtonStatus } from './ApiButtonTypes';

export function getGenerationIcon(status: ApiButtonStatus, defaultIcon: IconName | null): IconName | null {
  switch (status) {
    case ApiButtonStatus.IN_PROGRESS:
      return null;
    case ApiButtonStatus.COMPLETED:
      return 'IconCheck';
    case ApiButtonStatus.FAILED:
      return 'IconExclamationCircle';
    default:
      return defaultIcon;
  }
}

export function getButtonVariationForApiStatus(
  status: ApiButtonStatus,
  defaultVariation: ButtonVariation
): ButtonVariation {
  switch (status) {
    case ApiButtonStatus.COMPLETED:
      return 'success';
    case ApiButtonStatus.FAILED:
      return 'warn';
    default:
      return defaultVariation;
  }
}
