import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { v6 as uuidv6 } from 'uuid';
import { assetsContentUploadUrlImportCreate } from '@goldcast/api/content';
import { getFilesValidationErrors } from '../Pages/Sessions/NewContentModal/util';
import { REGEX_URL } from '../Pages/Sessions/NewContentModal/constants';
import useFreeTrialHook from '@/hooks/useFreeTrialHook';
import { showErrorToast } from '@/libs/toast/toast';
import { useAppContext } from '@/context/AppContext/AppContext';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { currentUser } from '@/stores/user';
import { loadOrgUsageSummary } from '@/stores/orgUsageSummary';
import { ContentStatesEnum } from '@/Pages/GenerateContent/constants';
import useContentUploadHook from '@/hooks/useContentUploadHook';
import { getFileNameTitleCase, isVideoFile } from '@/libs/file';
import { getTotalVideoDuration } from '@/libs/getVideoDuration';
import { roundToNDecimalPlaces } from '@/libs/utils';
import useAnalytics from '@/hooks/useAnalytics';

export function useUploadContent() {
  const { checkVideoUploadLimitReached, notifyUserToUpgrade } = useFreeTrialHook();
  const { adminAppStore, logger } = useAppContext();
  const { handleImportUrlContent, setUploadContent } = useContentUploadHook();
  const { trackVideoUpload } = useAnalytics();

  const [urlInputValue, setUrlInputValue] = useState('');
  const [isValidLink, setIsValidLink] = useState(true);

  async function onFilesUpload(files: File[]) {
    const { value, reason } = await checkVideoUploadLimitReached(files);
    if (value) {
      trackVideoUpload({
        uploadType: 'Upload',
        status: 'Disabled',
        url: urlInputValue,
        failureReason: reason
      });
      return;
    }
    const isFileInvalid = handleFilesValidation(files);
    if (!isFileInvalid) {
      handleContentUpload(files);
    }
  }

  const handleFilesValidation = useCallback((files: File[]) => {
    const fileName = files[0].name;
    const { files: fileError, global } = getFilesValidationErrors(files);
    const error = fileError[fileName] || (global && global[0]);
    if (error) {
      showErrorToast(error);
      trackVideoUpload({
        uploadType: 'Upload',
        status: 'Disabled',
        url: urlInputValue,
        failureReason: `${error}`
      });
      return true;
    }
    return false;
  }, []);

  const handleContentUpload = async (filesToUpload: File[]) => {
    const duration = await getTotalVideoDuration(filesToUpload);
    const contentDetails = {
      projectId: uuidv6({ msecs: new Date().getTime() }),
      contentId: uuidv6({ msecs: new Date().getTime() }),
      duration: roundToNDecimalPlaces(duration, 3),
      file: filesToUpload[0],
      title: getFileNameTitleCase(filesToUpload[0].name),
      av_type: isVideoFile(filesToUpload[0]) ? 'VIDEO' : 'AUDIO',
      contentState: ContentStatesEnum.Uploading
    };
    setUploadContent(contentDetails);
    trackVideoUpload({
      uploadType: 'Upload',
      status: 'Started'
    });
    adminAppStore.commit?.('contentStudio/closeNewContentDialog');
    EventBus.dispatch(CustomEvents.UploadContentStarted, {
      projectId: contentDetails.projectId,
      contentId: contentDetails.contentId
    });
  };

  function handleUrlInputChange(event: React.ChangeEvent) {
    const value = ((event.target as HTMLInputElement).value || '').trim();
    setUrlInputValue(value);
  }

  const { mutate: importFromURL, isLoading: isImporting } = useMutation(assetsContentUploadUrlImportCreate, {
    onError: showImportError,
    onSuccess: data => {
      trackVideoUpload({
        uploadType: 'Import',
        status: 'Completed',
        url: urlInputValue
      });
      handleImportUrlContent({
        projectId: data.project_id ?? '',
        contentId: data.id,
        contentState: ContentStatesEnum.Processing,
        contentUrl: data?.import_url,
        title: data.title ?? '',
        av_type: data.av_type ?? ''
      });
      EventBus.dispatch(CustomEvents.UploadContentStarted, {
        projectId: data.project_id,
        contentId: data.id
      });
      loadOrgUsageSummary(currentUser.getSnapshot()?.organization as string, false);
      adminAppStore.commit?.('contentStudio/closeNewContentDialog');
      return;
    }
  });

  function showImportError(err) {
    trackVideoUpload({
      uploadType: 'Import',
      status: 'Failed',
      url: urlInputValue,
      failureReason: err
    });
    try {
      const parsed = JSON.parse(err as string);
      if (parsed && Array.isArray(parsed) && parsed.length > 0 && !!parsed[0]) {
        if (parsed[0].includes('Total content duration usage exceeded')) {
          notifyUserToUpgrade();
          return;
        }
        showErrorToast(parsed[0]);
      }
    } catch (e) {
      logger.error('Failed parsing import error: ', e);
      showErrorToast(
        `We can't import this video due to issues like an incorrect URL, privacy settings, multiple streams, or technical limitations. Please check and try again.`
      );
    }
  }

  const startImport = useCallback(() => {
    const isValid = !!urlInputValue.match(REGEX_URL);
    setIsValidLink(isValid);
    if (isValid) {
      importFromURL({
        body: {
          url: urlInputValue
        }
      });
    }
  }, [urlInputValue]);

  return {
    urlInputValue,
    isValidLink,
    isImporting,
    handleUrlInputChange,
    startImport,
    onFilesUpload
  };
}
