import { memo } from 'react';
import Button from '@/components/atoms/Button/Button';

function NoResultsDialog({
  searchTerm,
  onClose,
  onUpload
}: {
  searchTerm: string;
  onClose: () => void;
  onUpload: () => void;
}) {
  return (
    <div>
      <div className="flex items-center space-x-5 p-5 py-4 leading-tight">
        <div className="grow pb-5 pt-0.5">
          <h1 className="font-medium">
            We searched your content library but couldn’t find a match for "{searchTerm}".
          </h1>
          <div className="mt-1.5 w-full text-sm leading-tight text-slate-600">
            You can try a different search or upload more content and try again.
          </div>
        </div>
      </div>
      <div className="flex w-full justify-end space-x-2 border-t border-slate-200 px-5 pb-3 pt-2.5">
        <Button trackingId="no-results-upload-content-button" className="h-10 px-6 py-2" onClick={onUpload}>
          Upload Content
        </Button>
        <Button trackingId="no-results-ok-button" variation="filled" className="h-10 px-6 py-2" onClick={onClose}>
          OK
        </Button>
      </div>
    </div>
  );
}

export default memo(NoResultsDialog);
