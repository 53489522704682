import { memo, useEffect, useSyncExternalStore } from 'react';
import { useNavigate } from 'react-router-dom';
import { assetsClipCreateFullLengthCreate } from '@goldcast/api/content';
import ClipPlayer from '../Clip/ClipPlayer/ClipPlayer';
import ClipPlayerWrapper from '../Clip/ClipPlayer/ClipPlayerWrapper';
import { TIMELINE_DISPLAY_TYPE, TRANSCRIPT_DISPLAY_TYPE } from '../Clip/ClipPlayer/ClipTimelineV2/ClipTimelineTypes';
import { core } from '@/stores/core';
import { showErrorToast } from '@/libs/toast/toast';
import { useAppContext } from '@/context/AppContext/AppContext';

function RecordingsPage() {
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);
  const navigate = useNavigate();
  const { logger } = useAppContext();

  useEffect(() => {
    if (!coreStore.content) return;
    if (!coreStore.content.full_length_asset_id) {
      assetsClipCreateFullLengthCreate({ body: { content_id: coreStore.content.id } })
        .then(res => {
          core.update(data => ({
            ...data,
            content: { ...data.content, full_length_asset_id: res.id }
          }));
        })
        .catch(() => {
          showErrorToast('Something went wrong loading full recording. Please try again.');
          logger.error(`Error creating full lenght clip for ${coreStore.content!.id}`);
          navigate(`/${coreStore.content!.project.id}/${coreStore.content!.id}/clips`);
        });
    }
  }, [coreStore.content, navigate]);

  const handleTranscriptError = (err: any) => {
    navigate(`/error/${err?.statusCode}`, { replace: true });
  };

  return (
    <div className="flex h-full overflow-hidden">
      <div className="flex grow flex-col overflow-hidden transition-all duration-[400ms] ease-in-out">
        <div className="relative ml-1 flex h-full grow flex-col">
          {coreStore.content?.full_length_asset_id && (
            <ClipPlayerWrapper
              clipId={coreStore.content.full_length_asset_id}
              handleTranscriptError={handleTranscriptError}
            >
              <div className="h-full">
                <ClipPlayer
                  playerClasses="lg:p-16"
                  inlineEditEnabled={false}
                  showNewTimeline={true}
                  isFullRecordingEdit={true}
                  timelineDisplayType={TIMELINE_DISPLAY_TYPE.SPEAKERS}
                  showClipHeader={true}
                  transcriptDisplayType={TRANSCRIPT_DISPLAY_TYPE.FULL}
                />
              </div>
            </ClipPlayerWrapper>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(RecordingsPage);
