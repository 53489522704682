import { IconAlertTriangle } from '@tabler/icons-react';

export default function PlayerErrorState() {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center text-slate-400">
      <IconAlertTriangle size={32} stroke={1.5} />
      We're having difficulty loading this video. Ensure it's properly encoded and available, then try again.
    </div>
  );
}
