import { memo, useCallback, useSyncExternalStore } from 'react';
import { useNavigate } from 'react-router-dom';
import { assetsSavedSearchDestroy } from '@goldcast/api/content';
import useDialog from '@/components/organisms/useDialog';
import Button from '@/components/atoms/Button/Button';
import ConfirmationDialog from '@/components/organisms/ConfirmationDialog';
import { showErrorToast } from '@/libs/toast/toast';
import { useAppContext } from '@/context/AppContext/AppContext';
import { savedAISearch } from '@/stores/savedAISearch';

function SavedSearchSettingsActions() {
  const savedAISearchStore = useSyncExternalStore(savedAISearch.subscribe, savedAISearch.getSnapshot);
  const {
    isOpen: isDeleteConfirmationOpen,
    openDialog: openDeleteConfirmationDialog,
    closeDialog: closeDeleteConfirmationDialog
  } = useDialog();

  const navigate = useNavigate();
  const { logger } = useAppContext();

  const deleteSavedSearch = useCallback(() => {
    assetsSavedSearchDestroy({ id: savedAISearchStore!.id })
      .then(() => {
        navigate('/');
      })
      .catch(err => {
        logger.error(`Error deleting Saved Search with id ${savedAISearchStore!.id}. Stack trace: ${err}`);
        showErrorToast("Whoops! Deleting the Saved Search didn't go as planned. Check your connection and try again.");
      });
  }, [logger, navigate, savedAISearchStore]);

  return (
    <>
      {!!savedAISearchStore?.id && (
        <div className="flex flex-row items-center border-t border-t-slate-200 p-5 text-xs">
          <span>Delete Search and all associated data</span>
          <Button
            variation="warn"
            buttonSize="small"
            className="ml-auto"
            trackingId="delete-saved-search-button"
            onClick={openDeleteConfirmationDialog}
          >
            Delete Search
          </Button>
        </div>
      )}
      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        onClose={closeDeleteConfirmationDialog}
        onConfirm={deleteSavedSearch}
        title="Delete Search"
        content="Are you sure you want to permanently remove this Search? This action is irreversible."
        confirmLabel="Yes"
      />
    </>
  );
}

export default memo(SavedSearchSettingsActions);
