import { Transition } from '@headlessui/react';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import SpeakerPreview from './SpeakerPreview';
import MergeSpeakersForm from './MergeSpeakersForm';
import EditSpeakerForm from './EditSpeakerForm';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';

export default function EditableSpeakerRow({
  speaker,
  editingSpeakerId,
  allSpeakers,
  canDelete = false,
  canMerge = false,
  onUpdate,
  setEditingSpeakerId
}: {
  speaker: SpeakerWithDetails;
  allSpeakers: SpeakerWithDetails[];
  editingSpeakerId: string | null;
  canDelete?: boolean;
  canMerge?: boolean;
  setEditingSpeakerId: Dispatch<SetStateAction<string | null>>;
  onUpdate: (speaker: SpeakerWithDetails) => void;
}) {
  const editableSpeakerRowRef = useRef<HTMLDivElement>(null);
  const isEditing = editingSpeakerId === speaker.id;
  const [isMerging, setIsMerging] = useState(false);

  useEffect(() => {
    if (isEditing && editableSpeakerRowRef.current) {
      editableSpeakerRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [isEditing]);

  const startSpeakerEdit = useCallback(() => {
    setEditingSpeakerId(speaker.id);
  }, [setEditingSpeakerId, speaker.id]);

  const onChange = useCallback(
    (key: keyof SpeakerWithDetails, value: string | null) => {
      onUpdate({ ...speaker, [key]: value });
    },
    [onUpdate, speaker]
  );

  const openMergeForm = useCallback(() => {
    setIsMerging(true);
  }, []);

  const closeMergeForm = useCallback(() => {
    setIsMerging(false);
  }, []);

  return (
    <div className="group relative flex flex-col items-center justify-between" ref={editableSpeakerRowRef}>
      <SpeakerPreview
        speaker={speaker}
        isEditing={isEditing}
        canDelete={canDelete}
        onEditStart={startSpeakerEdit}
      ></SpeakerPreview>
      <Transition
        appear
        show={isEditing && !isMerging}
        className="w-full overflow-hidden transition-all duration-500"
        enterFrom="transform opacity-0 max-h-0"
        enterTo="transform opacity-100 max-h-96"
        leaveFrom="transform opacity-100 max-h-96"
        leaveTo="transform opacity-0 max-h-0"
      >
        <EditSpeakerForm
          speaker={speaker}
          onChange={onChange}
          onMergeClick={openMergeForm}
          isMergeEnabled={canMerge && allSpeakers.length > 1}
        />
      </Transition>
      <Transition
        appear
        show={isMerging && isEditing}
        className="w-full overflow-hidden pb-4 pt-1 transition-all duration-500"
        enterFrom="transform opacity-0 max-h-0"
        enterTo="transform opacity-100 max-h-96"
        leaveFrom="transform opacity-100 max-h-96"
        leaveTo="transform opacity-0 max-h-0"
      >
        <MergeSpeakersForm allSpeakers={allSpeakers} editingSpeakerId={editingSpeakerId} onCloseForm={closeMergeForm} />
      </Transition>
    </div>
  );
}
